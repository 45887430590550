import React from 'react';
import { FormattedMessage } from 'react-intl';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

type Props = {
  assetUserAttatchments: Array<string>;
};

export default function ContextInfoBox({ assetUserAttatchments }: Props) {
  return (
    <SDSLabel
      labelTitle={
        <FormattedMessage
          id="asset-has-attatchments-title"
          description="Message to let the user know that the asset has a user attatchments"
          defaultMessage="Context"
        />
      }
      withoutDefaultMargin
    >
      <AlertBox type={AlertType.INFO}>
        {assetUserAttatchments?.includes('hasMentionedUsername') && (
          <div>
            <FormattedMessage
              id="asset-has-attatchments-username-warning"
              description="Message to let the user know that the asset has a username attatchment added by the user"
              defaultMessage="@Mention attatched"
            />
          </div>
        )}
        {assetUserAttatchments?.includes('hasTopicAttached') && (
          <div>
            <FormattedMessage
              id="asset-has-attatchments-topic-warning"
              description="Message to let the user know that the asset has a topic attatchment added by the user"
              defaultMessage="Topics attatched"
            />
          </div>
        )}
        {assetUserAttatchments?.includes('hasMusicTrackAttached') && (
          <div>
            <FormattedMessage
              id="asset-has-attatchments-music-warning"
              description="Message to let the user know that the asset has a music attatchment added by the user"
              defaultMessage="Music track attached"
            />
          </div>
        )}
      </AlertBox>
    </SDSLabel>
  );
}
