import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { removeLocalBottomSnap as removeLocalBottomSnapAction } from 'state/editor/actions/editorActions';

import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';

import type { SnapId } from 'types/common';
import { ExtractDispatchProps } from 'types/redux';

type ExternalProps = {
  snapId: SnapId;
  onConfirm: () => void;
  onCancel: () => void;
  visible: boolean;
};

const mapDispatchToProps = {
  removeLocalBottomSnap: removeLocalBottomSnapAction,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps;

export function LoadLocalContentModal(props: Props) {
  const { snapId, onConfirm, onCancel, removeLocalBottomSnap } = props;

  const onLoadLocalContentConfirm = useCallback(() => {
    incrementCounter('LoadLocalArticle.yes');
    onConfirm();
    removeLocalBottomSnap(snapId);
  }, [snapId, onConfirm, removeLocalBottomSnap]);

  const onLoadLocalContentCancel = useCallback(() => {
    incrementCounter('LoadLocalArticle.discard');
    onCancel();
    removeLocalBottomSnap(snapId);
  }, [snapId, onCancel, removeLocalBottomSnap]);

  return (
    <SDSDialog
      onOk={onLoadLocalContentConfirm}
      onCancel={onLoadLocalContentCancel}
      okText={
        <FormattedMessage
          id="yes-button"
          defaultMessage="Yes"
          description="Text indicating to user to select yes on a given question"
        />
      }
      cancelText={<FormattedMessage id="discard-button" defaultMessage="Discard" description="Discard button" />}
      title={
        <FormattedMessage
          id="article-stored-locally"
          defaultMessage="Unsaved local changes"
          description="Title for article stored locally"
        />
      }
      visible={props.visible}
      isBodyCentered
      data-test="LoadLocalContentModal.SDSDialog"
    >
      <>
        <FormattedMessage
          id="unsaved-local-changes-line-1"
          defaultMessage="You have unsaved local changes."
          description="Asking user if they want to load previous version of the article that was not saved"
        />
        <br />
        <FormattedMessage
          id="unsaved-local-changes-line-2"
          defaultMessage="Do you want to load them?"
          description="Asking user if they want to load previous version of the article that was not saved"
        />
      </>
    </SDSDialog>
  );
}

export default intlConnect(null, mapDispatchToProps)(LoadLocalContentModal);
