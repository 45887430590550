import { get } from 'lodash';
import React from 'react';

import {
  isSnapStatusIncomplete,
  isSnapStatusNonBlockingWarning,
  isSnapStatusWithError,
  isSnapStatusIncompleteOrWithError,
} from 'state/buildStatus/schema/buildStatusHelpers';
import { getEditionBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { isPublishingEnabled } from 'state/publishers/selectors/publishersSelectors';
import { goToContact } from 'state/router/actions/routerActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import type { StoryStatusEnum } from 'config/constants';
import { StoryStatus } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './StoryLevelAdvancedMessage.scss';

import { SnapProblem } from 'types/build';
import type { BuildStatusType } from 'types/build';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

type StateProps = {
  storyId?: number;
  activePublisherId: PublisherID | undefined | null;
  storyStatusMessage?: string;
  publisherStatusMessage?: string;
  buildStatus?: BuildStatusType;
  openSnapEditor: typeof publisherStoryEditorModeActions.openSnapEditor;
  publishingEnabled: boolean;
  editionBuildStatus: StoryStatusEnum;
};

const mapStateToProps = (state: State) => {
  const storyId = publisherStoryEditorSelectors.getActiveEditionId(state);
  const editionBuildStatus = storyId ? getEditionBuildStatus(state)(storyId) : null;

  return {
    storyId,
    activePublisherId: userSelectors.getActivePublisherId(state),
    storyStatusMessage: publisherStoryEditorSelectors.getStoryStatusMessageForStoryId(state)({ storyId }),
    publisherStatusMessage: publisherStoryEditorSelectors.getPublisherStatusMessageForStoryId(state)({ storyId }),
    buildStatus: publisherStoryEditorSelectors.getFirstBuildStatusWithErrorOrIncompleteForStory(state)(storyId),
    publishingEnabled: isPublishingEnabled(state),
    editionBuildStatus,
  };
};

const mapDispatchToProps = {
  openSnapEditor: publisherStoryEditorModeActions.openSnapEditor,
  goToContact,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;

export class StoryLevelAdvancedMessage extends React.Component<Props & DispatchProps> {
  goToIssue = (): void => {
    if (this.props.storyStatusMessage) {
      if (this.isStoryLevelMessageClickable()) {
        this.props.openSnapEditor({
          publisherId: this.props.activePublisherId,
          editionId: this.props.storyId,
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          snapId: get(this.props.buildStatus, 'snapId'),
          overwriteHistory: false,
        });
      }
      return;
    }

    if (this.isPublisherLevelMessageExistentAndClickable()) {
      this.props.goToContact();
    }
  };

  isStoryLevelMessageClickable = () => {
    const { buildStatus } = this.props;
    const clickableBuildStatus = buildStatus && buildStatus.status !== SnapProblem.CONTENT_DELETED;
    return (
      clickableBuildStatus &&
      (isSnapStatusIncompleteOrWithError(this.props.buildStatus) ||
        isSnapStatusNonBlockingWarning(this.props.buildStatus))
    );
  };

  isPublisherLevelMessageExistentAndClickable = () => {
    return this.props.publisherStatusMessage && !this.props.publishingEnabled;
  };

  renderDotStatusIcon = () => {
    if (
      !isSnapStatusIncompleteOrWithError(this.props.buildStatus) &&
      !this.isPublisherLevelMessageExistentAndClickable()
    ) {
      return null;
    }
    const isBuilding = isSnapStatusIncomplete(this.props.buildStatus);
    const hasError = isSnapStatusWithError(this.props.buildStatus);

    function renderIcon() {
      if (isBuilding) {
        return <DotStatus status={DotStatusState.INCOMPLETE} />;
      }
      if (hasError) {
        return <DotStatus status={DotStatusState.ERROR} />;
      }
      return <DotStatus status={DotStatusState.INFO} />;
    }

    return <div className={style.dotStatusIcon}>{renderIcon()}</div>;
  };

  renderPlainButton = (
    showStoryStatusMessage: boolean,
    hasPriorityEditionBuildStatus: boolean,
    clickable: boolean | string | undefined
  ) => {
    return (
      <SDSButton
        type={ButtonType.WHITE_ON_GREY}
        disabled={!clickable}
        onClick={this.goToIssue}
        data-test="publisherStoryEditor.storyLevel.advancedMessage.button"
      >
        {!hasPriorityEditionBuildStatus && this.renderDotStatusIcon()}
        {showStoryStatusMessage ? this.props.storyStatusMessage : this.props.publisherStatusMessage}
      </SDSButton>
    );
  };

  renderButtonWithTooltip = (
    showStoryStatusMessage: boolean,
    hasPriorityEditionBuildStatus: boolean,
    clickable: boolean | string | undefined
  ) => {
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.BOTTOM}
        title={
          <HelpCenterLink
            destination={HelpCenterDestination.PUBLISHING_DISABLED}
            data-test="StoryLevelAdvancedMessage.helpCenterLink.glossary"
          />
        }
        id="publishing-disabled-tooltip"
      >
        {this.renderPlainButton(showStoryStatusMessage, hasPriorityEditionBuildStatus, clickable)}
      </SDSTooltip>
    );
  };

  render() {
    const showStoryStatusMessage = !!this.props.storyStatusMessage;
    if (!showStoryStatusMessage && !this.props.publisherStatusMessage) {
      return null;
    }

    const hasPriorityEditionBuildStatus =
      this.props.editionBuildStatus === StoryStatus.SHOT_DETECTION_FAILED ||
      this.props.editionBuildStatus === StoryStatus.SHOT_DETECTION_IN_PROGRESS ||
      this.props.editionBuildStatus === StoryStatus.GENERATING_SUBTITLES;

    const clickable =
      (showStoryStatusMessage && this.isStoryLevelMessageClickable() && !hasPriorityEditionBuildStatus) ||
      (!showStoryStatusMessage && this.isPublisherLevelMessageExistentAndClickable());

    // if the story is complete and publishing is disabled
    const hasTooltip =
      !showStoryStatusMessage &&
      !isSnapStatusIncompleteOrWithError(this.props.buildStatus) &&
      !this.props.publishingEnabled;

    return (
      <>
        {hasTooltip
          ? this.renderButtonWithTooltip(showStoryStatusMessage, hasPriorityEditionBuildStatus, clickable)
          : this.renderPlainButton(showStoryStatusMessage, hasPriorityEditionBuildStatus, clickable)}
      </>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryLevelAdvancedMessage);
