import React from 'react';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

const MILLISECONDS_IN_A_MINUTE = 1000 * 60;
const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * 60;

const pad = (number: any) => {
  return number < 10 ? `0${number}` : number.toString();
};

const internalFormatTime = (positive: any, hours: any, minutes: any, seconds: any) => {
  const hoursString = pad(hours);
  const minutesString = pad(minutes);
  const secondsString = pad(seconds);

  let sign = '';
  if (hours > 0 || minutes > 0 || seconds > 0) {
    sign = positive ? '+' : '-';
  }

  return `(${sign}${hoursString}:${minutesString}:${secondsString})`;
};

type OwnRelativeTimeProps = {
  baseTime: number;
  refreshIntervalMs?: number;
  className?: string;
  notifyWhenSwitchToPositive?: (...args: any[]) => any;
  formatTimeFunc?: (...args: any[]) => any;
};

type RelativeTimeState = any;

type RelativeTimeProps = OwnRelativeTimeProps & typeof RelativeTime.defaultProps;

export class RelativeTime extends React.Component<RelativeTimeProps, RelativeTimeState> {
  static defaultProps = {
    formatTimeFunc: internalFormatTime,
  };

  interval: any;

  state = {
    currentTimeDelta: 0,
    positive: true,
    timestampOffset: 0,
  };

  UNSAFE_componentWillMount() {
    this.refreshTimeDelta();
    if (this.props.refreshIntervalMs) {
      this.interval = setInterval(this.refreshTimeDelta, this.props.refreshIntervalMs);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.baseTime !== nextProps.baseTime) {
      this.recalculateTimeDelta(nextProps.baseTime);
    }
  }

  shouldComponentUpdate(nextProps: RelativeTimeProps, nextState: RelativeTimeState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  refreshTimeDelta = () => {
    this.recalculateTimeDelta(this.props.baseTime);
  };

  recalculateTimeDelta = (baseTime: any) => {
    // Getting rid of milliseconds or rounding will get weird when the time flips sign
    const now = Math.floor(Date.now() / 1000) * 1000 + this.state.timestampOffset;
    const roundedBaseTime = Math.floor(baseTime / 1000) * 1000;

    let shouldNotifyWhenSwitchToPositive = false;

    // Needs to use '>' instead of '>=' because we lose sub-second precision due to above rounding
    if (now > roundedBaseTime) {
      if (!this.state.positive && this.props.notifyWhenSwitchToPositive) {
        // Delaying calling notifyWhenSwitchToPositive because it can trigger the unmounting of this component
        // synchronously (apparently react can do that) and then the 'setState' below would trigger a warning.
        shouldNotifyWhenSwitchToPositive = true;
      }

      this.setState({
        currentTimeDelta: now - roundedBaseTime,
        positive: true,
      });
    } else {
      this.setState({
        currentTimeDelta: roundedBaseTime - now,
        positive: false,
      });
    }

    if (shouldNotifyWhenSwitchToPositive) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.notifyWhenSwitchToPositive();
    }
  };

  formatTime = () => {
    return this.props.formatTimeFunc(
      this.state.positive,
      Math.floor(this.state.currentTimeDelta / MILLISECONDS_IN_AN_HOUR),
      Math.floor(this.state.currentTimeDelta / MILLISECONDS_IN_A_MINUTE) % 60,
      Math.floor(this.state.currentTimeDelta / 1000) % 60
    );
  };

  render() {
    return <div className={this.props.className}>{this.formatTime()}</div>;
  }
}

export default RelativeTime;
