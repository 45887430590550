import React from 'react';

import { DEFAULT_LOGO_COLOR } from 'config/constants';
import * as assetUtils from 'utils/media/assetUtils';

import style from './TileLogoPreview.scss';

type Props = {
  id?: number | string;
  image?: string;
  tileId?: number | string;
  color?: string;
};

export default class TileLogoPreview extends React.Component<Props> {
  renderLogoContent = () => {
    const { image, id } = this.props;
    const imgSrc = image || assetUtils.getImagePreviewUrl(id);

    return <img src={imgSrc!} alt="Logo" crossOrigin="use-credentials" className={style.publisherIcon} />;
  };

  renderBackGroundImage() {
    const { color, tileId } = this.props;
    const imgSrc = assetUtils.getImagePreviewUrl(tileId);

    if (tileId) {
      return (
        <img src={imgSrc!} alt="BackgroundImage" crossOrigin="use-credentials" className={style.backgroundImage} />
      );
    }
    return <div className={style.backgroundImage} style={{ backgroundColor: color || DEFAULT_LOGO_COLOR }} />;
  }

  render() {
    return (
      <div className={style.contentContainer}>
        {this.renderBackGroundImage()}
        <div className={style.wrapContent}>{this.renderLogoContent()}</div>
      </div>
    );
  }
}
