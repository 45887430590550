import classNames from 'classnames';
import * as React from 'react';

import styles from './Marker.scss';
// These enum values match the style names in the scss file.
type Color = 'green' | 'grey' | 'blue' | 'transparent' | 'lightGrey';
export type Props = {
  color: Color;
  style?: any;
  className?: string;
  overlay?: boolean;
};
const Marker = React.forwardRef(({ color, style, className, overlay }: Props, ref) => {
  const updatedClassName = classNames(className, styles.marker, styles[color], { [styles.overlay]: overlay });
  // @ts-expect-error ts-migrate(2322) FIXME: Type '((instance: unknown) => void) | MutableRefOb... Remove this comment to see the full error message
  return <div className={updatedClassName} style={style} ref={ref} />;
});
(Marker as any).markerWidth = styles.markerWidth;
export default Marker;
