import { emojiIndex } from 'emoji-mart';
import { memoize } from 'lodash';

import { assertArg, assertState } from 'utils/assertionUtils';
/**
 * If we want to show custom emojis on the same tab, we need to use custom category.
 * Emoji mart support custom emojis: https://github.com/missive/emoji-mart#custom-emojis
 * In order to simplify this, we just expect list of strings as list of emojis and we try to find them
 * in the emojiIndex.
 * @type {Function}
 */
export const createCustomProps = memoize(settings => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(settings).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(settings.customEmojis).is.object();
  const custom = settings.customEmojis
    .map((emoji: any) => {
      let emojiObject = emojiIndex.emojis[emoji];
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(emojiObject).is.object();
      // Fixing react warning, when object contains multiple emoji objects (for skin options) grab the first.
      if (Object.prototype.hasOwnProperty.call(emojiObject, '1')) {
        // @ts-expect-error Property may not exist on object ( we check this above )
        emojiObject = emojiObject[1];
      }
      if (!(emojiObject as any).short_names) {
        // Fixing the react warning, when the object doesn't have the short_names
        (emojiObject as any).short_names = [emoji];
      }
      if (!(emojiObject as any).imageUrl) {
        // Fixing the react warning, when the object doesn't have the imageUrl
        (emojiObject as any).imageUrl = '[NO URL RENDER NATIVE]';
      }
      if (!(emojiObject as any).name) {
        // Fixing the react warning, when the object doesn't have the name
        (emojiObject as any).name = [emoji];
      }
      return emojiObject;
    })
    .filter(Boolean);
  return {
    custom,
    i18n: {
      categories: {
        custom: settings.headline,
      },
    },
    include: ['custom'],
    className: settings.className,
  };
});
/**
 * Emoji mart doesn't support Formatted Message object. So we need to pass the headline as string.
 * This function is just for optimization of the config object because the config will be memoized.
 * @type {Function}
 */
export const mergeConfigAndHeadline = memoize((customConfig, headline) => {
  return {
    ...customConfig,
    headline,
  };
});
