import moment from 'moment';
import React from 'react';

import {
  getSingleAssetPlayerCurrentTime,
  getSingleAssetPlayerDuration,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';

import { intlConnect } from 'utils/connectUtils';
import { getTimeString } from 'utils/singleAssetStoryEditorUtils';

import style from './VideoPlayerTimeLabel.scss';

import type { EditionID } from 'types/editions';
import type { State as ReduxState } from 'types/rootState';
import { TimelineSnap } from 'types/singleAssetStoryEditor';

type OwnProps = {
  storyId: EditionID;
  snap?: TimelineSnap;
};

type StateProps = {
  currentTime: number;
  duration: number;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  return {
    currentTime: getSingleAssetPlayerCurrentTime(state)(ownProps.storyId),
    duration: getSingleAssetPlayerDuration(state)(ownProps.storyId),
  };
};

export const VideoPlayerTimeLabel = (props: Props) => {
  let currentTimeString = '0:00';
  let totalDurationString = '0:00';

  if (props.duration > 0) {
    currentTimeString = moment.utc(props.currentTime || 0).format('m:ss');
    totalDurationString = moment.utc(props.duration || 0).format('m:ss');
  }

  return props.snap ? (
    <div className={style.snapTime} data-test="VideoPlayerTimeLabel.snapTime">
      {getTimeString(props.snap.startTimeMs, props.snap.startTimeMs + props.snap.durationMs)}
    </div>
  ) : (
    <div className={style.time} data-test="VideoPlayerTimeLabel.videoTime">
      {`${currentTimeString} / ${totalDurationString}`}
    </div>
  );
};

export default intlConnect(mapStateToProps, null)(React.memo(VideoPlayerTimeLabel));
