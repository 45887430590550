import React, { memo } from 'react';

import MediaAnnotationsPanelView from 'views/common/components/MediaAnnotations/MediaAnnotationsPanelView/MediaAnnotationsPanelView';

import style from './CouldVisionLabels.scss';

import { CloudVisionContentAnnotation } from 'types/assets';

type Props = {
  cloudVisionContentAnnotations: CloudVisionContentAnnotation[];
};

export function CouldVisionLabelItem({ label, score }: CloudVisionContentAnnotation) {
  return (
    <div className={style.contentAnnotation}>
      <div className={style.label}>{label}</div>
      <div>{Math.round(score * 100)}%</div>
    </div>
  );
}

function CouldVisionLabels({ cloudVisionContentAnnotations }: Props) {
  return (
    <MediaAnnotationsPanelView header={'Cloud Vision Labels'}>
      {cloudVisionContentAnnotations.map(annotation => (
        <CouldVisionLabelItem key={annotation.label} {...annotation} />
      ))}
    </MediaAnnotationsPanelView>
  );
}

export default memo(CouldVisionLabels);
