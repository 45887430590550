import { reduce } from 'lodash';
import * as React from 'react';
import type { ReactNode } from 'react';

import { audienceToLongString, shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import { getSegmentAudience } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import { getSegmentReduxIdFromEditionAndSegmentId } from 'state/segments/schema/segmentEntityHelpers';

import type { SegmentNode } from '../StoryCarousel/StoryCarouselTypes';

import { SegmentCategorisationEligibility } from 'config/constants';
import type { SegmentCategorisationEligibilityEnum } from 'config/constants';
import { segmentCategorization, segmentCatigorizationDisabled } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './SegmentCarouselHeader.scss';

import type { EditionID } from 'types/editionID';
import type { AudienceEnum } from 'types/moderation';
import type { SegmentID } from 'types/segments';

const mapStateToProps = (state: State, ownProps: any) => {
  const segmentId = getSegmentReduxIdFromEditionAndSegmentId(ownProps.editionId, ownProps.segment.id);
  return {
    segmentId,
    segmentEligibleForCategorisation: editionsSelectors.isSegmentEligibleForCategorisation(state)(segmentId),
    segmentCategorisationEligibility: editionsSelectors.getSegmentCategorisationEligibility(state)(segmentId),
    segmentAudienceList: getSegmentAudience(state)(segmentId),
  };
};

const mapDispatchToProps = {};

type Props = {
  segment: SegmentNode;
  segmentId: SegmentID;
  editionId: EditionID | undefined | null;
  segmentNumber: number | undefined | null;
  isPlaceholder: boolean;
  segmentAudienceList: Array<AudienceEnum>;
  segmentEligibleForCategorisation: boolean;
  segmentCategorisationEligibility: SegmentCategorisationEligibilityEnum;
};

const CatEligibilityToMessageId = {
  [SegmentCategorisationEligibility.TOO_SHORT]: 'segment-eligibility-too-short',
  [SegmentCategorisationEligibility.AD_AFTER_LAST_SNAP]: 'segment-eligibility-ad-after-last-snap',
  [SegmentCategorisationEligibility.ELIGIBLE]: 'segment-eligibility-eligible',
  [SegmentCategorisationEligibility.DISABLED]: 'stand-alone-segment-disabled',
};

export class SegmentCarouselHeader extends React.Component<Props> {
  getModerationMessage = (): ReactNode | undefined | null => {
    // Moderation information is only relevant for stand alone segments
    if (
      !shouldShowModeration(this.props.segmentAudienceList) ||
      this.props.segmentCategorisationEligibility !== SegmentCategorisationEligibility.ELIGIBLE
    ) {
      return null;
    }

    return reduce(
      this.props.segmentAudienceList,
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      (acc, audienceItem) => {
        return (
          <>
            {acc}. {audienceToLongString(audienceItem)}
          </>
        );
      },
      ''
    );
  };

  renderTooltip = (children: ReactNode) => {
    const eligibility = this.props.segmentCategorisationEligibility;
    const messageId = CatEligibilityToMessageId[eligibility];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const warningMessage = getMessageFromId(messageId);

    const tooltip = (
      <>
        {warningMessage}
        {this.getModerationMessage()}
      </>
    );

    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={tooltip}
        delayShow={250}
        id={this.props.segmentId}
      >
        {children}
      </SDSTooltip>
    );
  };

  render() {
    const { isPlaceholder, segmentNumber, segmentEligibleForCategorisation } = this.props;

    if (!segmentNumber || isPlaceholder) {
      return <div className={style.header} />;
    }

    return this.renderTooltip(
      <div className={style.header} data-test="segmentCarouselHeader">
        <div data-test="segmentCarouselHeaderNumber">{getMessageFromId('segment-number', { segmentNumber })}</div>
        <Icon
          inlineIcon={segmentEligibleForCategorisation ? segmentCategorization : segmentCatigorizationDisabled}
          className={segmentEligibleForCategorisation ? style.categorisationIcon : style.categorisationDisabledIcon}
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SegmentCarouselHeader);
