// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getTopSnapIdFromBottomSnapId } from 'state/snaps/schema/snapEntityHelpers';

import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import MediaLibraryButton from 'views/common/components/MediaLibraryButton/MediaLibraryButton';

export const mapStateToProps = (state: State) => {
  const activeComponent = componentsSelectors.getActiveComponent(state);
  const editionId = editorSelectors.getActiveEditionId(state);
  const isSaving = editorSelectors.isSaving(state);
  const activePublisherDetails = getActivePublisherDetails(state);
  return {
    isSaving,
    editionId,
    activeComponent,
    publisherId: activePublisherDetails?.id,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & typeof VideoLibraryPanel.defaultProps;

export class VideoLibraryPanel extends React.Component<Props> {
  static defaultProps = {
    minPages: 1,
    imagesPerPage: 9,
    isReadOnly: false,
    title: (
      <FormattedMessage id="video-library-panel-title" description="Video Library Name" defaultMessage="Library" />
    ),
    isAutoSave: false,
  };

  renderFields() {
    const { activeComponent, editionId } = this.props;
    if (!activeComponent) {
      return [];
    }

    const attachmentId = extractSnapIdFromComponentId(activeComponent?.componentId);

    return [
      {
        predicate: true,
        key: 'mediaLibraryButton',
        control: (
          <MediaLibraryButton
            editionId={editionId}
            publisherId={this.props.publisherId}
            snapId={getTopSnapIdFromBottomSnapId(attachmentId)}
            attachmentId={attachmentId}
            buttonText={getMessageFromId('video-library-panel-choose-from-media-library')}
          />
        ),
      },
    ];
  }

  render() {
    return (
      <PropertyPanel
        fields={this.renderFields()}
        title={this.props.title}
        isReadOnly={this.props.isSaving}
        titleCaseLabels
        horizontal
      />
    );
  }
}

export default intlConnect(mapStateToProps, null)(VideoLibraryPanel);
