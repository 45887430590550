import React from 'react';

import {
  getTileAudience,
  getTileModerationAppealStatus,
  getTileViolations,
} from 'state/buildStatus/selectors/buildStatusSelectors';
import { isTileViolationAppealEnabled } from 'state/features/selectors/featuresSelectors';
import { tileViolationAppealPrompt } from 'state/moderation/actions/moderationActions';

import { intlConnect } from 'utils/connectUtils';

import ModerationPanel, {
  ModerationItem,
} from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/ModerationPanel/ModerationPanel';

import { ModerationAppealStatus } from 'types/build';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';
import { TileID } from 'types/tiles';

type OwnProps = {
  tileId: TileID;
};

const mapStateToProps = (state: State, props: OwnProps) => {
  const { tileId } = props;
  return {
    audience: getTileAudience(state)(tileId),
    moderationViolations: getTileViolations(state)(tileId),
    moderationAppealStatus: getTileModerationAppealStatus(state)(tileId),
    isAppealEnabled: isTileViolationAppealEnabled(state),
  };
};

const mapDispatchToProps = {
  violationAppealPrompt: tileViolationAppealPrompt,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

export function TileModerationPanel({
  audience,
  moderationViolations,
  moderationAppealStatus,
  isAppealEnabled,
  violationAppealPrompt,
}: Props) {
  const isAppealAvailable = moderationAppealStatus === ModerationAppealStatus.AVAILABLE;
  return (
    <ModerationPanel
      moderationItem={ModerationItem.TILE}
      audienceList={audience}
      violationList={moderationViolations}
      isAppealEnabled={isAppealEnabled}
      isAppealAvailable={isAppealAvailable}
      // @ts-ignore: FIX ME: 'TrimFunction<() => (dispatch: Dispatch, getState: GetState) => Promise<unknown>>' is not assignable to type '() => Promise<void>
      onAppeal={violationAppealPrompt}
    />
  );
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TileModerationPanel);
