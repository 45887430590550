import React, { memo, useCallback } from 'react';

import Icon from 'views/common/components/Icon/Icon';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/plus.svg.inline' or its ... Remove this comment to see the full error message
import plusIcon from 'icons/plus.svg.inline';

import style from './AddSubtitlesFragmentButton.scss';

type Props = {
  index: number;
  onClick: (a: number) => void;
};

function AddSubtitlesFragmentButton({ index, onClick }: Props) {
  const handleOnClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);
  return (
    <div className={style.root} onClick={handleOnClick}>
      <div className={style.horizontalLine} data-test="addSubtitleFragmentButton" />
      <Icon inlineIcon={plusIcon} className={style.icon} />
      <div className={style.horizontalLine} />
    </div>
  );
}

export default memo(AddSubtitlesFragmentButton);
