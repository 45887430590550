// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import invariant from 'invariant';
import type { ChangeEvent } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import { getTileAudience } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import { isTileLogoOffOptionDisabled } from 'state/features/selectors/featuresSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { createTileContainer, getTileIdOrNull } from 'state/tiles/schema/tilesEntityHelpers';
import { getTileId } from 'state/tiles/schema/tilesIdUtils';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';

import { EMPTY_ARRAY, LocalStorage, UploadPurpose } from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { buildComponentIdForSnapId, buildComponentIdForTile } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { localStorage } from 'utils/localStorageUtils';
import type { LogoPositionEnum } from 'utils/logoConfig';
import {
  ENABLED_LOGO_POSITION_OPTIONS_IMAGE,
  LOGO_POSITION_OFF,
  LOGO_POSITION_OPTIONS_IMAGE,
  LogoPosition,
} from 'utils/logoConfig';
import { ModalType } from 'utils/modalConfig';

import CameoInput from 'views/common/components/CameoInput/CameoInput';
import Icon from 'views/common/components/Icon/Icon';
import LineView from 'views/common/components/LineView/LineView';
import SDSDropdown, { DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput, { InputType } from 'views/common/components/SDSInput/SDSInput';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import CropTile from 'views/editor/components/CropTile/CropTile';
import PanelTextValidationField from 'views/editor/components/PanelTextValidationField/PanelTextValidationField';
import TileLogoPicker from 'views/editor/components/TileLogoPicker/TileLogoPicker';
import TileModerationPanel from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/TileModerationPanel/TileModerationPanel';
import TileSelectorControl from 'views/editor/containers/TileSelectorControl/TileSelectorControl';
import { OneTimeConfirmationModalOptions } from 'views/modals/components/OneTimeConfirmationModal/OneTimeConfirmationModal';

import style from './TilePropertyPanel.scss';

import { ExtractDispatchProps } from 'types/redux';
import type { CameoTile, Tile, TileID, TileShape } from 'types/tiles';

type TileComponent = {
  tile: Tile;
};

type ExternalProps = {
  component: TileComponent;
  hideTileSelector?: boolean;
  hideLogoPosition?: boolean;
};

function validateCameoId(info: string) {
  return info.length >= 4;
}

export const mapStateToProps = (state: State, props: ExternalProps) => {
  const activeEditionId = editorSelectors.getActiveEditionId(state);
  const activeTopsnapId = editorSelectors.getActiveWholeSnapId(state);
  const activeSegmentId = editorSelectors.getActiveSegmentReduxId(state);
  const activePublisherId = publishersSelectors.getActivePublisherId(state);
  const { tile } = props.component;
  const componentId = !activeTopsnapId ? '' : buildComponentIdForSnapId(activeTopsnapId);
  const activeTileUploadCount = mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
    componentId,
    UploadPurpose.TILE_IMAGE
  );
  const isTileLockedForEdit = Boolean(
    activeTopsnapId &&
      activeEditionId &&
      editorSelectors.isTileLockedForEdit(state)({ snapId: activeTopsnapId, editionId: activeEditionId })
  );
  const tileId = tile && getTileId(tile);
  return {
    activeTileUploadCount,
    activeEditionId,
    activeTopsnapId,
    activeSegmentId,
    activePublisherId,
    tile,
    tileId,
    pendingTile: tileId ? editorSelectors.getPendingTileById(state)(parseInt(tileId, 10)) : null,
    tileLogos: tilesSelectors.getTileLogosByPublisherId(state)(activePublisherId),
    isTileLockedForEdit,
    isEditionReadOnly: editorSelectors.isReadOnly(state),
    isBitmojiContentEnabled: featuresSelectors.isBitmojiContentEnabled(state),
    isCameosContentEnabled: featuresSelectors.isCameosContentEnabled(state),
    isTileHeadlineValidationEnabled: featuresSelectors.isTileHeadlineValidationEnabled(state),
    isHeadlineVisibilityPickerEnabled: featuresSelectors.isTileHeadlineVisibilityPickerEnabled(state),
    tileAudienceList: tileId ? getTileAudience(state)(tileId) : [],
    isTileLogoOffOptionDisabled: isTileLogoOffOptionDisabled(state),
  };
};
const mapDispatchToProps = {
  disableLogoOnTile: editorActions.disableLogoOnTile,
  discardTileState: editorActions.discardTileState,
  setTileCroppingAndSave: editorActions.setTileCroppingAndSave,
  showModal: modalsActions.showModal,
  updateTileEditorState: editorActions.updateTileEditorState,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;

export class TilePropertyPanel extends React.Component<Props> {
  componentWillUnmount() {
    const tileId: TileID | undefined | null = getTileIdOrNull(this.props.tile);
    if (tileId) {
      // Clean up the editor state after editing.
      this.props.discardTileState(tileId);
    }
  }

  onHeadlineModalConfirm = (dontShowAgain: string, isHeadlineEnabled: boolean) => {
    localStorage.setItem(LocalStorage.DISMISS_HEADLINE_SAVE_CONFIRM_MODAL, dontShowAgain);
    this.saveTileProperties({
      headline: '',
      isHeadlineEnabled,
    });
  };

  onTileLogoPositionChanged = (position: LogoPositionEnum | typeof LOGO_POSITION_OFF) => {
    if (position === LOGO_POSITION_OFF) {
      this.hideLogoWithModal();
      return;
    }
    const { tile } = this.props;
    if (tile.logoPosition === position && tile.isLogoEnabled) {
      return;
    }
    const isHeadlineAllowed = position !== LogoPosition.BOTTOM;
    this.enableLogo({
      logoPosition: position,
      headline: isHeadlineAllowed ? tile.headline : '',
      isHeadlineEnabled: isHeadlineAllowed ? tile.isHeadlineEnabled : false,
    });
  };

  onHideLogoModalConfirm = (dontShowAgain: string) => {
    localStorage.setItem(LocalStorage.DISMISS_LOGO_SAVE_CONFIRM_MODAL, dontShowAgain);
    const {
      tile,
      activeTopsnapId,
      activeEditionId,
      activeSegmentId,
      activePublisherId,
      disableLogoOnTile,
    } = this.props;
    const tileContainer = createTileContainer(activeEditionId, activeTopsnapId, activeSegmentId);
    invariant(activePublisherId, 'activePublisherId');
    invariant(activeEditionId, 'activeEditionId');
    disableLogoOnTile(tileContainer, tile, activePublisherId, activeEditionId);
  };

  getSelectedLogoPosition() {
    const { tile } = this.props;
    if (!tile.isLogoEnabled || !(this.props.tileLogos.length > 0)) {
      return LOGO_POSITION_OFF;
    }
    return tile.logoPosition || LogoPosition.TOP;
  }

  enableLogo(additionalTileProperties: Partial<Tile>) {
    const { tile } = this.props;
    // If you set the logo properties to null, they'll be reset to defaults.
    const logoImageAssetId = tile.isLogoEnabled ? tile.logoImageAssetId : undefined;
    const logoReadStateOverlayColor = tile.logoReadStateOverlayColor ? tile.logoReadStateOverlayColor : null;
    const newProps: Partial<Tile> = {
      logoImageAssetId,
      logoReadStateOverlayColor,
      isLogoEnabled: true,
      ...additionalTileProperties,
    };
    this.saveTileProperties(newProps);
  }

  saveTileProperties = (newProperties: TileShape) => {
    const { activeTopsnapId, activeEditionId, activePublisherId, setTileCroppingAndSave, activeSegmentId } = this.props;
    const tile: Partial<Tile> = { ...this.props.tile, ...newProperties };
    setTileCroppingAndSave(
      createTileContainer(activeEditionId, activeTopsnapId, activeSegmentId),
      tile,
      activePublisherId
    );
  };

  handleDataSave = (
    value: boolean,
    oldValue: boolean,
    skipModal: string,
    onConfirm: (dontShowAgain: string, value: boolean) => void,
    body: JSX.Element
  ) => {
    if (value === oldValue) {
      // No point in saving if the value selected is the same as previously
      return;
    }
    if (skipModal === 'true' || value) {
      onConfirm(skipModal, value);
    } else {
      const options: OneTimeConfirmationModalOptions = {
        visible: true,
        onConfirm: dontShowAgain => {
          onConfirm(dontShowAgain.toString(), value);
        },
        body,
        isBodyCentered: true,
      };
      this.props.showModal(ModalType.ONE_TIME_CONFIRMATION, 'TileSettings', options);
    }
  };

  handleBitmojiTemplateIdChanged = (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    const newProps: Partial<Tile> = { bitmojiTileTemplateId: value };
    this.saveTileProperties(newProps);
  };

  handleCameoIdChange = (value: string | number) => {
    if (validateCameoId(`${value}`)) {
      const data: Partial<CameoTile> = {
        cameoId: value,
        revision: this.props.tile?.cameoTile?.revision || 1,
      };
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<CameoTile>' is not assignable to typ... Remove this comment to see the full error message
      const newProps: Partial<Tile> = { cameoTile: data };
      this.saveTileProperties(newProps);
    }
  };

  handleCameoRevisionChange = (value: string | number) => {
    if (+value > 0) {
      const data: Partial<CameoTile> = {
        cameoId: this.props.tile?.cameoTile?.cameoId,
        revision: +value,
      };
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<CameoTile>' is not assignable to typ... Remove this comment to see the full error message
      const newProps: Partial<Tile> = { cameoTile: data };
      this.saveTileProperties(newProps);
    }
  };

  hideLogoWithModal = () => {
    const isLogoEnabled = false;
    this.handleDataSave(
      isLogoEnabled,
      this.props.tile.isLogoEnabled,
      localStorage.getItem(LocalStorage.DISMISS_LOGO_SAVE_CONFIRM_MODAL),
      this.onHideLogoModalConfirm,
      <FormattedMessage
        id="tile-logo-warning-hide-modal"
        defaultMessage="Your publisher logo must be present in your uploaded media. Are you sure you want to hide the tile logo?"
        description="Modal text explaining that logo should be in media and asking if the user wants to hide their tile logo"
      />
    );
  };

  handleHeadlineSave = (isHeadlineEnabledStr: string) => {
    const isHeadlineEnabled = isHeadlineEnabledStr === 'true';
    this.handleDataSave(
      isHeadlineEnabled,
      this.props.tile.isHeadlineEnabled,
      localStorage.getItem(LocalStorage.DISMISS_HEADLINE_SAVE_CONFIRM_MODAL),
      this.onHeadlineModalConfirm,
      <FormattedMessage
        id="tile-headline-hide-modal"
        defaultMessage="Are you sure you want to hide the tile headline?"
        description="Modal text asking if the user wants to hide their tile headline"
      />
    );
  };

  renderLabelWithTooltip = () => {
    return (
      <div className={style.labelContainer}>
        <FormattedMessage
          id="bitmoji-tile-template-id"
          description="Form to enter Bitmoji template ID"
          defaultMessage="Bitmoji Template ID"
        />
        <SDSTooltip
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.TOP}
          title={
            <FormattedMessage
              id="bitmoji-tile-template-id-tooltip"
              description="Tooltip explaining the template ID field for Bitmoji tiles"
              defaultMessage="Template ID used for Bitmoji Tile images"
            />
          }
          id="lens-url-info-tooltip"
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    );
  };

  renderCameoIdLabelWithTooltip = () => {
    return (
      <div className={style.labelContainer}>
        <FormattedMessage id="cameo-tile-id" description="Form to enter Cameo ID" defaultMessage="Cameo ID" />
        <SDSTooltip
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.TOP}
          title={
            <FormattedMessage
              id="cameo-tile-id-tooltip"
              description="Tooltip explaining the cameo ID field for cameo tiles"
              defaultMessage="Cameo ID used for Cameo Tile images"
            />
          }
          id="cameo-id-tooltip"
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    );
  };

  renderCameoRevisionLabelWithTooltip = () => {
    return (
      <div className={style.labelContainer}>
        <FormattedMessage
          id="cameo-tile-revision"
          description="Form to enter Cameo Revision"
          defaultMessage="Cameo Revision"
        />
        <SDSTooltip
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.TOP}
          title={
            <FormattedMessage
              id="cameo-tile-revision-tooltip"
              description="Tooltip explaining the cameo Revision field for cameo tiles"
              defaultMessage="Cameo Revision used for Cameo Tile images"
            />
          }
          id="cameo-Revision-tooltip"
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    );
  };

  renderFields() {
    const {
      tile,
      tileId,
      pendingTile,
      activeTopsnapId,
      isTileLockedForEdit,
      isEditionReadOnly,
      activeTileUploadCount,
      isBitmojiContentEnabled,
      isCameosContentEnabled,
      isHeadlineVisibilityPickerEnabled,
      tileAudienceList,
      hideTileSelector,
      hideLogoPosition,
    } = this.props;
    if (activeTileUploadCount <= 0 && activeTopsnapId && tile) {
      if (tile.baseImageAssetId) {
        const headlineTitle = (
          <FormattedMessage id="headline-title" description="Title for headline toggle" defaultMessage="Headline" />
        );
        const headlineTooltip = (
          <FormattedMessage
            id="headline-tooltip"
            description="Tooltip explaining what the headline toggle means"
            defaultMessage="Show and hide the headline on your tile"
          />
        );
        const componentId = buildComponentIdForTile(tile);
        const logoPosition = tile.logoPosition || LogoPosition.TOP;
        const hasTileLogos = this.props.tileLogos.length > 0;
        const showModeration = shouldShowModeration(tileAudienceList);
        const tileLogoPositionOptions = this.props.isTileLogoOffOptionDisabled
          ? ENABLED_LOGO_POSITION_OPTIONS_IMAGE
          : LOGO_POSITION_OPTIONS_IMAGE;
        const onOffOptions = [
          {
            value: 'true',
            label: <FormattedMessage id="drop-down-on" description="Drop down message On." defaultMessage="On" />,
          },
          {
            value: 'false',
            label: (
              <FormattedMessage
                id="tile-property-drop-down-off"
                description="Drop down message Off."
                defaultMessage="Off"
              />
            ),
          },
        ];
        return [
          {
            predicate: !hideTileSelector,
            key: 'tiles-divider',
            // @ts-expect-error ts-migrate(2786) FIXME: 'LineView' cannot be used as a JSX component.
            control: <LineView className={style.divider} />,
          },
          {
            predicate: showModeration,
            key: 'tile-moderation',
            control: tileId && <TileModerationPanel tileId={tileId} />,
          },
          {
            predicate: showModeration,
            key: 'tile-moderation-divider',
            // @ts-expect-error ts-migrate(2786) FIXME: 'LineView' cannot be used as a JSX component.
            control: <LineView className={style.divider} data-test="tile-moderation-divider" />,
          },
          {
            predicate: this.props.isTileHeadlineValidationEnabled,
            key: 'headlineValidationField',
            renderOutsideLabel: true,
            blockStyle: true,
            control: (
              <PanelTextValidationField
                label={
                  <FormattedMessage
                    id="tile-property-panel-headline-validation-label-new"
                    description="Label to form field showing tile headline validation"
                    defaultMessage="Headline Validation"
                  />
                }
                labelTooltipText={
                  <FormattedMessage
                    id="tile-property-panel-headline-validation-label-tooltip-text"
                    description="Label tooltip info to form field showing tile headline validation"
                    defaultMessage="Tile Headline; Limited to 3 lines"
                  />
                }
                text={tile.headline}
                textValidationResult={tile.headlineTextValidationResult}
              />
            ),
          },
          {
            label: headlineTitle,
            predicate: isHeadlineVisibilityPickerEnabled,
            key: 'headline',
            blockStyle: true,
            renderOutsideLabel: true,
            control: (
              <div className={style.dropdownContainer}>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <SDSTooltip placement={TooltipPosition.TOP} title={headlineTooltip} id="tile-settings-option">
                  <SDSDropdown
                    disableClear
                    value={String(tile.isHeadlineEnabled)}
                    onChange={this.handleHeadlineSave}
                    disabled={logoPosition === LogoPosition.BOTTOM}
                    data-test={'tilePropertyPanel-SidePanelDropdown-headlinePosition'}
                    type={DropdownType.GREY}
                  >
                    {createSDSDropdownOptions(onOffOptions)}
                  </SDSDropdown>
                </SDSTooltip>
              </div>
            ),
          },
          {
            predicate: Boolean(!hideLogoPosition),
            label: (
              <FormattedMessage
                id="logo-position-dropdown-message"
                description="position of a publishers logo on a tile"
                defaultMessage="Logo"
              />
            ),
            key: 'logo-position',
            renderOutsideLabel: true,
            control: (
              <div className={style.dropdownContainer}>
                <SDSDropdown
                  disableClear
                  value={this.getSelectedLogoPosition()}
                  onChange={this.onTileLogoPositionChanged}
                  disabled={isEditionReadOnly || !hasTileLogos}
                  data-test="tilePropertyPanel-SidePanelDropdown-logoPosition"
                  type={DropdownType.GREY}
                >
                  {createSDSDropdownOptions(tileLogoPositionOptions)}
                </SDSDropdown>
              </div>
            ),
          },
          {
            predicate: tile.isLogoEnabled && hasTileLogos,
            label: <FormattedMessage id="options" description="Tile logo style picker" defaultMessage="Options" />,
            key: 'options',
            control: (
              <TileLogoPicker
                tile={tile}
                pendingTile={pendingTile}
                locked={isTileLockedForEdit}
                isEditionReadOnly={isEditionReadOnly}
              />
            ),
          },
          {
            label: getMessageFromId('cropping'),
            key: 'cropping',
            renderOutsideLabel: true,
            blockStyle: true,
            control: <CropTile key={componentId} data-test="richSnapEditor.tileEditor.cropTile" />,
          },
          {
            predicate: isBitmojiContentEnabled,
            key: 'templateId',
            control: (
              <div className={style.templateIdInputContainer}>
                <SDSInput
                  labelTitle={this.renderLabelWithTooltip()}
                  data-test="editor.tilePropertyPanel.templateId.input"
                  value={tile.bitmojiTileTemplateId || ''}
                  disabled={this.props.isTileLockedForEdit}
                  onChange={this.handleBitmojiTemplateIdChanged}
                />
              </div>
            ),
          },
          {
            predicate: isCameosContentEnabled,
            key: 'cameoId',
            control: (
              <div className={style.templateIdInputContainer}>
                <CameoInput
                  labelTitle={this.renderCameoIdLabelWithTooltip()}
                  type={InputType.NUMBER}
                  data-test="editor.tilePropertyPanel.cameoId.input"
                  value={tile?.cameoTile?.cameoId || ''}
                  disabled={this.props.isTileLockedForEdit}
                  onChange={this.handleCameoIdChange}
                />
              </div>
            ),
          },
          {
            predicate: isCameosContentEnabled,
            key: 'cameoRevision',
            control: (
              <div className={style.templateIdInputContainer}>
                <CameoInput
                  labelTitle={this.renderCameoRevisionLabelWithTooltip()}
                  type={InputType.NUMBER}
                  data-test="editor.tilePropertyPanel.cameoRevision.input"
                  value={tile?.cameoTile?.revision || ''}
                  disabled={this.props.isTileLockedForEdit}
                  onChange={this.handleCameoRevisionChange}
                />
              </div>
            ),
          },
        ];
      }
    }
    return EMPTY_ARRAY;
  }

  renderTileSelector() {
    if (this.props.hideTileSelector) {
      return null;
    }
    return <TileSelectorControl />;
  }

  render() {
    return (
      <div className={style.tilePropertyPanel} data-test="tilePropertyPanel">
        {this.renderTileSelector()}
        <PropertyPanel fields={this.renderFields()} horizontal isReadOnly={this.props.isTileLockedForEdit} />
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(TilePropertyPanel);
