import classnames from 'classnames';
import React, { memo, useCallback } from 'react';

import type { SubtitleTimestamp } from '../../subtitleParser';
import TimestampEditor from '../TimestampEditor/TimestampEditor';

import style from './SubtitlesTimer.scss';

type Props = {
  timestamp: SubtitleTimestamp;
  disabled?: boolean;
  onChange: (a: SubtitleTimestamp) => void;
  prevMax: number;
  nextMin: number;
};

const TIMESTAMPS_SEPARATOR = '\u00a0-\u00a0';

function SubtitleTimer({ timestamp, disabled, onChange, prevMax, nextMin }: Props) {
  const handleStartTimeChange = useCallback(
    value => {
      onChange({
        ...timestamp,
        startTimeMs: value,
      });
    },
    [timestamp, onChange]
  );

  const handleEndTimeChange = useCallback(
    value => {
      onChange({
        ...timestamp,
        endTimeMs: value,
      });
    },
    [timestamp, onChange]
  );

  const isValid = timestamp.startTimeMs < timestamp.endTimeMs;

  return (
    <span className={classnames(style.fragmentTimestamp, { [style.enabled]: !disabled })} data-test="subtitleTimer">
      <TimestampEditor
        durationMs={timestamp.startTimeMs}
        disabled={disabled}
        onChange={handleStartTimeChange}
        isValid={isValid}
        min={prevMax}
        max={timestamp.endTimeMs}
      />
      <span>{TIMESTAMPS_SEPARATOR}</span>
      <TimestampEditor
        durationMs={timestamp.endTimeMs}
        disabled={disabled}
        isValid={isValid}
        onChange={handleEndTimeChange}
        min={timestamp.startTimeMs}
        max={nextMin}
      />
    </span>
  );
}

export default memo(SubtitleTimer);
