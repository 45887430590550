import FileReader from './utils/FileReaderUtil';

const IMAGE_FILE_TYPES = new Set(['image/jpeg', 'image/png', 'image/gif']);

export default class SCDragAndDrop {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCDragAndDrop';
  }

  static define(registry: any, elementCreator: any, readFile = FileReader.readAsDataURL) {
    registry.registerPlugin(SCDragAndDrop, {
      init(editor: any) {
        editor.on('drop', (event: any) => this._handleDropEvent(editor, event));
      },

      _handleDropEvent(editor: any, event: any) {
        const dataTransfer = event.data && event.data.dataTransfer;

        if (dataTransfer) {
          const numFiles = dataTransfer.getFilesCount();

          for (let i = 0; i < numFiles; ++i) {
            const file = dataTransfer.getFile(i);

            if (IMAGE_FILE_TYPES.has(file.type)) {
              this._readAndInsertImage(editor, file);
            }
          }
        }
      },

      _readAndInsertImage(editor: any, file: any) {
        readFile(file).then(result => {
          elementCreator.createAndInsert(editor, 'img', {
            src: result,
          });
        });
      },
    });
  }
}
