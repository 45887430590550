import invariant from 'invariant';
import { get, compact, memoize } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';

import * as attachmentsSelectors from 'state/attachments/selectors/attachmentsSelectors';
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import * as componentsActions from 'state/editor/actions/componentsActions';
import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { RichSnapActiveComponentType, TileButtonRenderType } from 'config/constants';
import type { TileButtonRenderTypeEnum, RichSnapActiveComponentTypeEnum } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSTabs from 'views/common/components/SDSTabs/SDSTabs';
import type { TabHeader } from 'views/common/components/SDSTabs/SDSTabs';
import { getAllAttachmentOptions } from 'views/editor/richEditorConfig';

import style from './RichSnapTabs.scss';
import {
  TabKey,
  TILE_INCOMPLETE_STATUSES,
  TILE_ERROR_STATUSES,
  SNAP_INCOMPLETE_STATUSES,
  SNAP_ERROR_STATUSES,
  ATTACHMENT_ERROR_STATUSES,
} from './RichSnapTabsConfig';

import type { BuildStatusType, ExtendedBuildStatus } from 'types/build';
import type {
  RichSnapComponent,
  RichSnapComponentId,
  RichSnapComponentSnap,
  RichSnapComponentTile,
} from 'types/components';
import { Claim } from 'types/permissions';
import type { Publisher } from 'types/publishers';
import type { State } from 'types/rootState';
import type { TopSnap } from 'types/snaps';
import { TileFlavor } from 'types/tiles';

export const mapStateToProps = (state: State) => {
  const topsnapId = editorSelectors.getActiveWholeSnapId(state);
  const storyId = editorSelectors.getActiveEditionId(state);
  return {
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    isReadOnly: editorSelectors.isReadOnly(state),
    buildStatus: topsnapId ? buildStatusSelectors.getDiscoverSnapBuildStatus(state)(topsnapId) : null,
    hasAddedPublisherDetails: publishersSelectors.activePublisherHasAddedRequiredDetails(state),
    storyBuildStatus: buildStatusSelectors.getEditionBuildStatusById(state)(storyId),
    activeComponentType: componentsSelectors.getActiveComponentType(state),
    activeComponent: componentsSelectors.getActiveComponent(state),
    topsnap: topsnapId ? snapsSelectors.getSnapById(state)(topsnapId) : null,
    componentsOfTypeSnap: componentsSelectors.getComponentsOfTypeSnap(state),
    componentsOfTypeTile: componentsSelectors.getComponentsOfTypeTile(state),
    tileButtonRenderType: componentsSelectors.getTileButtonRenderType(state),
    unreadAttachmentId: editorSelectors.getUnreadAttachmentId(state),
    numAttachmentOptions: attachmentsSelectors.getEnabledAttachmentOptions(state)(getAllAttachmentOptions()).length,
    isAttachmentViewer: userSelectors.hasClaimForActivePublisher(state, Claim.ATTACHMENT_VIEWER),
  };
};
const mapDispatchToProps = {
  clearUnreadAttachment: editorActions.clearUnreadAttachment,
  goToAttachment: routerActions.goToAttachment,
  showNewInteractionPlaceholder: editorActions.showNewInteractionPlaceholder,
  hideNewInteractionPlaceholder: editorActions.hideNewInteractionPlaceholder,
  editTileById: editorActions.editTileById,
  setActive: componentsActions.setActive,
  openPublisherDetailsModal: publishersActions.openPublisherDetailsModal,
};
type StateProps = {
  activePublisher: Publisher;
  isReadOnly: boolean;
  hasAddedPublisherDetails: boolean;
  activeComponent: RichSnapComponent;
  activeComponentType: RichSnapActiveComponentTypeEnum;
  buildStatus: BuildStatusType | undefined | null;
  storyBuildStatus: BuildStatusType;
  topsnap: TopSnap | undefined | null;
  componentsOfTypeSnap: RichSnapComponentSnap[];
  componentsOfTypeTile: RichSnapComponentTile[];
  tileButtonRenderType: TileButtonRenderTypeEnum;
  unreadAttachmentId: number | undefined | null;
  numAttachmentOptions: number;
  isAttachmentViewer: boolean;
  isDynamicEditionsPublisher: boolean;
};
type DispatchProps = {
  clearUnreadAttachment: typeof editorActions.clearUnreadAttachment;
  goToAttachment: typeof routerActions.goToAttachment;
  showNewInteractionPlaceholder: typeof editorActions.showNewInteractionPlaceholder;
  hideNewInteractionPlaceholder: typeof editorActions.hideNewInteractionPlaceholder;
  editTileById: typeof editorActions.editTileById;
  setActive: typeof componentsActions.setActive;
  openPublisherDetailsModal: typeof publishersActions.openPublisherDetailsModal;
};
type Props = StateProps & DispatchProps;
function findDiscoverFeedRectangularTileComponent(tiles: RichSnapComponentTile[]): RichSnapComponentTile | undefined {
  return (
    tiles.find(tile => tile.tile.tileFlavor === TileFlavor.DISCOVER_FEED_RECTANGULAR) ||
    tiles.find(tile => tile.tile.tileFlavor === TileFlavor.UNKNOWN_UNSET)
  );
}
export class RichSnapTabs extends React.Component<Props> {
  isSelected = (snapComponent?: RichSnapComponent | null) => {
    if (!snapComponent) {
      return false;
    }
    return get(this, ['props', 'activeComponent', 'componentId']) === snapComponent.componentId;
  };

  getActiveComponentTabKey = () => {
    const { activeComponentType } = this.props;

    switch (activeComponentType) {
      case RichSnapActiveComponentType.SNAP:
        return TabKey.TOPSNAP;
      case RichSnapActiveComponentType.TILE:
        return TabKey.TILE;
      case RichSnapActiveComponentType.ATTACHMENT:
        return TabKey.ATTACHMENT;
      default:
        return TabKey.TOPSNAP;
    }
  };

  editTile = () => {
    if (!this.props.hasAddedPublisherDetails) {
      (this.props.openPublisherDetailsModal('DraftStoryBar') as any).catch(() => {});
      return;
    }
    const tiles = this.props.componentsOfTypeTile;
    const tile = findDiscoverFeedRectangularTileComponent(tiles);
    this.editTileById(tile?.componentId);
  };

  editTileById = (componentId?: RichSnapComponentId | null, placeholderComponentId?: string) => {
    this.props.editTileById(componentId, placeholderComponentId);
  };

  shouldRenderAttachmentButton = () => {
    return this.hasAtLeastOneAttachmentOption() || (this.hasAttachment() && this.props.isAttachmentViewer);
  };

  hasAttachment = () => {
    const { topsnap } = this.props;
    const hasTopsnap = Boolean(topsnap);
    const topsnapAsTopsnap = snapEntityHelpers.asTopSnap(topsnap);
    if (hasTopsnap) {
      invariant(topsnapAsTopsnap, 'topsnap is not a topsnap');
    }
    return topsnapAsTopsnap && snapEntityHelpers.hasBottomSnap(topsnapAsTopsnap);
  };

  handleTileClicked = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-select');
    this.editTile();
  };

  handleSnapClicked = () => {
    const topsnapComponent = this.props.componentsOfTypeSnap[0];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'RichSnapComponent | undefined' i... Remove this comment to see the full error message
    this.props.setActive(topsnapComponent);
  };

  handleAttachmentClicked = () => {
    const bottomSnapComponent = this.props.componentsOfTypeSnap[1];
    if (!bottomSnapComponent) {
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'component-add-placeholder');
      this.props.showNewInteractionPlaceholder();
    } else {
      this.props.clearUnreadAttachment();
      this.props.setActive(bottomSnapComponent);
    }
  };

  hasAtLeastOneAttachmentOption() {
    return this.props.numAttachmentOptions > 0;
  }

  renderAlertIcon = (
    isSelected: boolean,
    incompleteStatuses: ExtendedBuildStatus[],
    errorStatuses: ExtendedBuildStatus[],
    component?: RichSnapComponent | null
  ): ReactNode => {
    const { buildStatus, storyBuildStatus } = this.props;
    const snapId = get(component, ['snap', 'id']);
    const extendedStatus = get(buildStatus, 'extendedStatus');
    const storyStatus = get(storyBuildStatus, 'editionStatus');

    function render(status: DotStatusState) {
      return (
        <div className={style.dotStatusIcon}>
          <DotStatus status={status} />
        </div>
      );
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ExtendedBuildStatus | undefined'... Remove this comment to see the full error message
    if (errorStatuses.includes(extendedStatus) || errorStatuses.includes(storyStatus)) {
      return render(DotStatusState.ERROR);
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ExtendedBuildStatus | undefined'... Remove this comment to see the full error message
    if (incompleteStatuses.includes(extendedStatus)) {
      return render(DotStatusState.INCOMPLETE);
    }
    if (snapId === this.props.unreadAttachmentId) {
      return render(DotStatusState.INCOMPLETE);
    }
    return null;
  };

  getActiveTabs(tabs: TabHeader[]): TabHeader[] {
    // return an array of just the tabs that are active
    return tabs.filter(tab => tab.isActive);
  }

  onTabChange = memoize(tabs => (tabIndex: any) => {
    const tabName = tabs[tabIndex].tabKey;
    switch (tabName) {
      case TabKey.TILE:
        this.handleTileClicked();
        break;
      case TabKey.TOPSNAP:
      default:
        this.handleSnapClicked();
        break;
      case TabKey.ATTACHMENT:
        this.handleAttachmentClicked();
        break;
    }
  });

  render() {
    const { componentsOfTypeSnap, tileButtonRenderType, isReadOnly, topsnap } = this.props;
    const activeKey = this.getActiveComponentTabKey();
    const tileSelected = activeKey === TabKey.TILE;
    const topSnapSelected = activeKey === TabKey.TOPSNAP;
    const bottomSnapSelected = activeKey === TabKey.ATTACHMENT;
    const hasTopsnap = Boolean(topsnap);
    const topsnapAsTopsnap = snapEntityHelpers.asTopSnap(topsnap);
    if (hasTopsnap) {
      invariant(topsnapAsTopsnap, 'topsnap is not a topsnap');
    }
    const isSubscribeSnap = hasTopsnap && topsnapAsTopsnap && snapEntityHelpers.isSubscribeSnap(topsnapAsTopsnap);
    const isReadOnlyWithoutAttachment =
      hasTopsnap && isReadOnly && topsnapAsTopsnap && !snapEntityHelpers.hasBottomSnap(topsnapAsTopsnap);
    const attachmentDisabled = !hasTopsnap || isSubscribeSnap || isReadOnlyWithoutAttachment;
    const tabs = compact([
      {
        tabKey: TabKey.TILE,
        text: getMessageFromId('tile'),
        isActive: hasTopsnap && tileButtonRenderType !== TileButtonRenderType.NOT_ALLOWED,
        dotStatus: this.renderAlertIcon(tileSelected, TILE_INCOMPLETE_STATUSES, TILE_ERROR_STATUSES),
      },
      {
        tabKey: TabKey.TOPSNAP,
        text: getMessageFromId('snap'),
        isActive: true,
        dotStatus: this.renderAlertIcon(
          topSnapSelected,
          SNAP_INCOMPLETE_STATUSES,
          SNAP_ERROR_STATUSES,
          componentsOfTypeSnap[0]
        ),
      },
      {
        tabKey: TabKey.ATTACHMENT,
        text: getMessageFromId('attachment'),
        isActive: !!this.shouldRenderAttachmentButton() && !attachmentDisabled,
        dotStatus: this.renderAlertIcon(bottomSnapSelected, [], ATTACHMENT_ERROR_STATUSES, componentsOfTypeSnap[1]),
      },
    ]);
    const activeTabs = this.getActiveTabs(tabs);
    return (
      <div className={style.tabsContainer}>
        {activeTabs.length > 1 ? (
          <SDSTabs
            selectedTab={activeTabs.findIndex(tab => tab.tabKey === activeKey)}
            handleTabSelected={this.onTabChange(activeTabs)}
            tabs={activeTabs}
          />
        ) : null}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(RichSnapTabs);
