// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { setEditorConfigProperties } from 'state/editor/actions/editorActions';
import { getActiveComponentId } from 'state/editor/selectors/componentsSelectors';
import {
  getActiveTopsnap,
  getActiveBottomsnap,
  isReadOnly,
  getActiveEditionId,
} from 'state/editor/selectors/editorSelectors';
import { isLongformAttachmentLivestreamingEnabled } from 'state/features/selectors/featuresSelectors';
import { getActiveUploadCountsForComponentIdByPurpose } from 'state/media/selectors/mediaSelectors';
import { getActivePublisherDefaultSubtitlesLanguage } from 'state/publishers/selectors/publishersSelectors';
import { updateProperties, commitData } from 'state/stages/actions/stagesActions';
import {
  deleteLongformSubtitles,
  fetchVideoInformation,
  flagVideoSubtitleProcessing,
  pollUntilVideoHasSubtitles,
} from 'state/videoLibrary/actions/videoLibraryActions';
import {
  getVideoSubtitlesByVideoId,
  subtitleProcessingStatusByVideoId,
} from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { UploadPurpose, DropzoneType } from 'config/constants';
import { ExtractDispatchProps } from 'src/types/redux';
import { State } from 'src/types/rootState';
import { URL_PREFIX_REGEX } from 'utils/apis/apiUtils';
import { blurOnEnterKey } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';
import { isValidUri } from 'utils/uriUtils';

import FileUploadDeleteButton from 'views/common/components/FileUploadDeleteButton/FileUploadDeleteButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';

import style from './LongformVideoPanel.scss';

import { SnapId } from 'types/common';
import { LongformVideoSnap } from 'types/snaps';

registerIntlMessage({
  intlMessage: (
    <FormattedMessage id="manifest-url-placeholder" description="Manifest URL" defaultMessage="Insert m3u8 here" />
  ),
  params: [],
});
export const mapStateToProps = (state: State, props: any) => {
  const topsnap = getActiveTopsnap(state);
  const bottomSnap = getActiveBottomsnap(state) as LongformVideoSnap;
  const activeComponentId = getActiveComponentId(state);
  const activeSubtitleUploadCount = getActiveUploadCountsForComponentIdByPurpose(state)(
    activeComponentId,
    UploadPurpose.SUBTITLE
  );
  const assetId = get(props, ['component', 'snap', 'longformVideoAssetId']);
  const subtitleInformation = getVideoSubtitlesByVideoId(state)(assetId);
  const subtitlesAreProcessing = subtitleProcessingStatusByVideoId(state)(assetId);
  const isLongformVideoLivestreamingEnabled = isLongformAttachmentLivestreamingEnabled(state);
  const manifestUrl = bottomSnap && bottomSnap.manifestUrl;
  return {
    assetId,
    topsnapId: topsnap && topsnap.id,
    bottomSnapId: bottomSnap && bottomSnap.id,
    activeSubtitleUploadCount,
    subtitleInformation,
    subtitlesAreProcessing,
    isLongformVideoLivestreamingEnabled,
    isReadOnly: isReadOnly(state),
    editionId: getActiveEditionId(state),
    defaultSubtitlesLanguage: getActivePublisherDefaultSubtitlesLanguage(state),
    manifestUrl,
  };
};
const mapDispatchToProps = {
  deleteLongformSubtitles,
  fetchVideoInformation,
  flagVideoSubtitleProcessing,
  pollUntilVideoHasSubtitles,
  setEditorConfigProperties,
  updateLongformVideo: updateProperties,
  saveLongformVideo: commitData,
};
const ProcessingLabel = () => (
  <span className={style.processingMessage}>
    <FormattedMessage
      id="subtitles-processing"
      description="Message shown when subtitles are processing"
      defaultMessage="Processing..."
    />
  </span>
);

type OwnProps = {
  topsnapId: SnapId;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps & typeof LongformVideoPanel.defaultProps;
export class LongformVideoPanel extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  state = {
    validationErrorMessage: '',
    manifestUrl: this.props.manifestUrl || '',
  };

  static defaultProps = {
    isReadOnly: false,
    title: <FormattedMessage id="longformvideo-general-panel-title" description="General" defaultMessage="General" />,
  };

  onSubtitleDelete = () => {
    const { assetId, bottomSnapId, defaultSubtitlesLanguage } = this.props;
    grapheneUtils.incrementCounter('subtitles', {
      click: 'longform_delete',
      language: defaultSubtitlesLanguage,
    });
    // using the default language, because multi language subtitles are not supported yet.
    (this.props as any)
      .deleteLongformSubtitles(bottomSnapId, assetId, defaultSubtitlesLanguage)
      .then(() => (this.props as any).fetchVideoInformation({ assetId }));
  };

  onSubtitleUploadComplete = () => {
    const { assetId } = this.props;
    // After a subtitle field is uploaded, there's a short processing time on the
    // Brightcove side before the subtitle track starts appearing in the response.
    // To account for this we mark the video as processing and then periodically
    // reload the video data until the subtitles start coming through.
    (this.props as any).flagVideoSubtitleProcessing({ assetId });
    (this.props as any).pollUntilVideoHasSubtitles({ assetId });
    grapheneUtils.incrementCounter('subtitles', {
      click: 'longform_uploaded',
      language: this.props.defaultSubtitlesLanguage,
    });
  };

  handleUrlChanged = (event: any) => {
    const manifestUrl = get(event, ['target', 'value']);
    this.setState({ manifestUrl: manifestUrl.trim() });
  };

  updateAndSave(manifestUrl: any) {
    (this.props as any).updateLongformVideo(this.props.bottomSnapId, {
      manifestUrl,
    });
    (this.props as any).saveLongformVideo(this.props.bottomSnapId);
  }

  manifestUrlChanged(manifestUrl: any) {
    return (this.props.manifestUrl || '') !== manifestUrl;
  }

  reportUpdateMetric() {
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'longform-video-panel-manifest-url-changed', {
      source: 'LongformVideoPanel',
      properties: { manifestUrl: '[REDACTED]' },
    });
  }

  sanitiseUrl(manifestUrl: any) {
    if (manifestUrl && !URL_PREFIX_REGEX.test(manifestUrl.toLowerCase())) {
      return `https://${manifestUrl}`;
    }
    return manifestUrl;
  }

  saveManifestUrl(manifestUrl: any) {
    this.reportUpdateMetric();
    this.updateAndSave(manifestUrl);
  }

  validateAndSave(inputValue: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const validInput = !inputValue || (isValidUri(inputValue) && inputValue.endsWith('.m3u8'));
    const validationErrorMessage = validInput ? null : 'Must be valid URL and have valid .m3u8 extension';
    const manifestUrl = this.sanitiseUrl(inputValue);
    if (validInput && this.manifestUrlChanged(manifestUrl)) {
      this.saveManifestUrl(manifestUrl);
    }
    this.setState({ manifestUrl, validationErrorMessage });
  }

  handleURLBlurred = () => {
    const { manifestUrl } = this.state;
    this.validateAndSave(manifestUrl);
  };

  renderFields() {
    const fields = [];
    if (this.props.assetId) {
      let control;
      if (this.props.subtitlesAreProcessing) {
        // Using a Component avoids generating a React warning because we add the property isReadOnly later on.
        control = <ProcessingLabel />;
      } else {
        control = (
          <FileUploadDeleteButton
            snapId={this.props.topsnapId}
            editionId={this.props.editionId}
            onDelete={this.onSubtitleDelete}
            purpose={UploadPurpose.SUBTITLE}
            dropzoneType={DropzoneType.SUBTITLE}
            isReadOnly={this.props.isReadOnly}
            fileInfo={this.props.subtitleInformation}
            assetId={this.props.assetId}
            uploadCount={this.props.activeSubtitleUploadCount}
            onUploadComplete={this.onSubtitleUploadComplete}
            data-test="longFormVideo"
            subtitlesLanguage={this.props.defaultSubtitlesLanguage}
          />
        );
      }
      fields.push({
        label: (
          <FormattedMessage id="subtitles" description="subtitles & captions" defaultMessage="Subtitles & Caption" />
        ),
        key: 'subtitlesField',
        renderOutsideLabel: true,
        control,
      });
    }
    fields.push({
      predicate: (this.props as any).isLongformVideoLivestreamingEnabled && !this.props.assetId,
      key: 'manifestURLField',
      control: (
        <SDSInput
          labelTitle={
            <FormattedMessage id="manifestUrl" description="Input for Manifest URL" defaultMessage="Manual Input" />
          }
          onChange={this.handleUrlChanged}
          onBlur={this.handleURLBlurred}
          placeholder={getLocalisedMessageFromId(this.context, 'manifest-url-placeholder')}
          disabled={this.props.isReadOnly}
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
          onKeyDown={blurOnEnterKey}
          errorMessage={this.state.validationErrorMessage}
          value={this.state.manifestUrl}
          data-test="longformVideoPanel.hlsUrlInputBox"
        />
      ),
    });
    return fields;
  }

  render() {
    const fields = this.renderFields();
    if (fields.length > 0) {
      return (
        <PropertyPanel title={this.props.title} isReadOnly={this.props.isReadOnly} fields={fields} showBottomLine />
      );
    }
    return null;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(LongformVideoPanel);
