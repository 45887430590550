// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import ExplanationPage from '@snapchat/web-attachments/lib/polls/markup/components/ExplanationPage/ExplanationPage';
import classNames from 'classnames';
import * as React from 'react';
import type { ReactNode } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ContentEditable from 'react-contenteditable';
import { defineMessages, intlShape } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'stri... Remove this comment to see the full error message
import striptags from 'striptags';

import { UploadPurpose, DropzoneType } from 'config/constants';

import ImageMediaUploader from 'views/editor/containers/ImageMediaUploader/ImageMediaUploader';

import style from './EditableExplanationPage.scss';

import { assertAssetId } from 'types/assets';
import type { AssetID, ExplanationPageModel } from 'types/polls';

export const formattedMessages = defineMessages({
  descriptionPlaceholderText: {
    id: 'editable-explanation-option-description-placeholder',
    description: 'Placeholder text for the optional choice description',
    defaultMessage: 'Additional explanation',
  },
});

export default class EditableExplanationPage extends ExplanationPage {
  static propTypes = {
    ...ExplanationPage.propTypes,
  };

  static contextTypes = {
    intl: intlShape,
  };

  handleOnUploadComplete = ({ assetId }: { assetId: AssetID }) => {
    assertAssetId(assetId);
    this.explanationPropertyChanged({
      imageAssetId: assetId,
    });
  };

  handleDescriptionChanged = (description?: string | null) => {
    if (typeof description !== 'string') {
      return;
    }

    this.explanationPropertyChanged({
      description,
    });
  };

  explanationPropertyChanged(changes: ExplanationPageModel) {
    // @ts-expect-error
    if (!this.props.explanationPropertyChanged) {
      return;
    }

    // @ts-expect-error
    if (this.props.isReadOnly) {
      return;
    }

    // @ts-expect-error
    this.props.explanationPropertyChanged(changes);
  }

  renderImageOverlayItem(imageAssetId?: AssetID | null): ReactNode {
    const isReplace = Boolean(imageAssetId);
    return (
      <ImageMediaUploader
        key="imageMediaUploader"
        // @ts-expect-error
        {...this.props}
        isReplace={isReplace}
        purpose={UploadPurpose.POLL_IMAGE}
        dropzoneType={DropzoneType.POLL_IMAGE}
        // @ts-expect-error
        customValidationOptions={this.props.customValidationOptions}
        onUploadComplete={this.handleOnUploadComplete}
        data-test="mediaUploader"
      />
    );
  }

  getAdditionalClasses(): string | undefined | null {
    return null;
  }

  renderDescriptionElement(description?: string | null): ReactNode {
    // @ts-expect-error
    if (this.props.isReadOnly) {
      return super.renderDescriptionElement(description);
    }

    // @ts-expect-error
    const placeholderMessage = this.context.intl.formatMessage(formattedMessages.descriptionPlaceholderText);
    // @ts-expect-error
    const classes = classNames(this.getStyle('description'), style.optional);
    return (
      <ContentEditable
        // @ts-expect-error
        key={JSON.stringify(this.props.styles.description)}
        // @ts-expect-error
        style={this.props.styles.description}
        className={classes}
        html={description || ''}
        disabled={false}
        placeholder={placeholderMessage}
        onBlur={(evt: any) => this.handleDescriptionChanged(striptags(evt.target.textContent))} // eslint-disable-line
        data-test="editor.ExplanationPage.additionalTextInput"
      />
    );
  }
}
