import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as articleActions from 'state/article/actions/articleActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as tilesActions from 'state/tiles/actions/tilesActions';

import { ErrorType } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromIntlMessage } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './StatusMessage.scss';
import { ERROR_MESSAGE, INFO_MESSAGE } from './StatusMessageConstants';

export const mapStateToProps = (state: State) => {
  const statusMessage = editorSelectors.getStatusMessage(state);
  if (_.isEmpty(statusMessage)) {
    return {
      statusMessage,
    };
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'errorType' does not exist on type '{}'.
  const { errorType, infoType, intlError } = statusMessage;
  const type = errorType || infoType;
  const isError = Boolean(errorType);
  return {
    isError,
    type,
    intlError,
    statusMessage,
  };
};
const mapDispatchToProps = {
  setSnapPropertiesAndSave: snapsActions.setSnapPropertiesAndSave,
  setTileMediaPropertiesAndSave: tilesActions.setTileMediaPropertiesAndSave,
  saveArticle: articleActions.saveArticle,
};

type StatusMessageProps = {
  statusMessage?: any;
  type?: string;
  isError?: boolean;
  intlError?: any;
};
export class StatusMessage extends React.Component<StatusMessageProps> {
  getRetryHandler() {
    const { statusMessage, type } = this.props;
    switch (type) {
      // TODO Support retries for media uploading
      case ErrorType.SAVE_SNAP_MEDIA: {
        const { snapId, properties } = statusMessage;
        return () => (this.props as any).setSnapPropertiesAndSave({ snapId }, properties, ErrorType.SAVE_SNAP_MEDIA);
      }
      case ErrorType.SAVE_TILE_MEDIA: {
        const { editionId, properties } = statusMessage;
        return () =>
          (this.props as any).setTileMediaPropertiesAndSave({ editionId }, properties, ErrorType.SAVE_TILE_MEDIA);
      }
      case ErrorType.ARTICLE_SAVE: {
        const { ids, fields } = statusMessage;
        return () => (this.props as any).saveArticle(ids, fields);
      }
      default:
        return null;
    }
  }

  optionallyRenderRetryButton(onClick: any) {
    if (_.isFunction(onClick)) {
      return (
        <div className={style.retryButtonContainer}>
          <SDSButton
            type={ButtonType.RED_ON_WHITE}
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            onClick={this.getRetryHandler()}
            data-test="editor.statusMessage.retry.button"
          >
            <FormattedMessage id="retry-button" description="Retry button" defaultMessage="Retry" />
          </SDSButton>
        </div>
      );
    }
    return null;
  }

  renderMessage({ isError, type, intlError }: any) {
    if (intlError) {
      const message = getMessageFromIntlMessage(intlError);
      return <span className={style.errorMessage}>{message}</span>;
    }
    return type ? (
      <span className={isError ? style.errorMessage : (style as any).infoMessage}>
        {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {isError ? ERROR_MESSAGE[type] : INFO_MESSAGE[type]}
      </span>
    ) : null;
  }

  render() {
    if (!this.props.statusMessage || _.isEmpty(this.props.statusMessage)) {
      return null;
    }
    return (
      <div className={style.root} data-test="richSnapEditor.statusMessage">
        {this.renderMessage(this.props)}
        {this.optionallyRenderRetryButton(this.getRetryHandler())}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StatusMessage);
