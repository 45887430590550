import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './SaveCancelButtons.scss';

type OwnProps = {
  onCancel?: (...args: any[]) => any;
  onSave?: (...args: any[]) => any;
  bsSize?: string;
  isReadOnly?: boolean;
  btnAlign?: 'left' | 'center' | 'right';
};

type State = any;

type Props = OwnProps & typeof SaveCancelButtons.defaultProps;

export class SaveCancelButtons extends React.Component<Props, State> {
  static defaultProps = {
    btnAlign: 'center',
  };

  static alignToStyle = {
    left: 'leftAlign',
    center: 'centerAlign',
    right: 'rightAlign',
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  render() {
    const alignStyleName = SaveCancelButtons.alignToStyle[this.props.btnAlign];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const containerStyle = classNames(style.confirmSave, style[alignStyleName]);

    return (
      <div className={containerStyle}>
        <SDSButton
          type={ButtonType.SECONDARY}
          onClick={this.props.onCancel}
          disabled={this.props.isReadOnly}
          data-test="common.saveCancel.cancel.button"
        >
          {getMessageFromId('discard-button')}
        </SDSButton>
        <div className={style.btnSpacer} />
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.props.onSave}
          disabled={this.props.isReadOnly}
          data-test="common.saveCancel.save.button"
        >
          <FormattedMessage id="save-button" description="Save button" defaultMessage="Save" />
        </SDSButton>
      </div>
    );
  }
}

export default SaveCancelButtons;
