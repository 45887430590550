import * as React from 'react';

import { getExternalMediaId } from 'state/asset/selectors/assetSelectors';
import { getAuthType } from 'state/auth/selectors/authSelectors';
import { getVideoInfoForStory } from 'state/singleAssetStory/selectors/singleAssetStorySelectors';

import { AuthType, CrossOrigin } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getPlayingSnapOrShot } from 'utils/singleAssetStoryEditorUtils';
import { snapPublisherFrameThumbnailImage } from 'utils/snapPublisherUtils';

import Marker from 'views/singleAssetStoryEditor/containers/Marker/Marker';
import TimelineThumbnail from 'views/singleAssetStoryEditor/containers/TimelineThumbnail/TimelineThumbnail';

import style from './TimelineHoverDecoration.scss';

import { EditionID } from 'types/editionID';
import type { State } from 'types/rootState';
import { ExtendedShot } from 'types/singleAssetStoryEditor';

// We require this component to we wrapped in ReactCursorPosition and these are the props it passes to this component
type ReactCursorPositionProps = {
  detectedEnvironment: {
    isMouseDetected: boolean;
    isTouchDetected: boolean;
  };
  elementDimensions: {
    width: number;
    height: number;
  };
  isActive: boolean;
  isPositionOutside: boolean;
  position: {
    x: number;
    y: number;
  };
};

type StateProps = {
  externalMediaId: string;
  isSnapSSO: boolean;
};

type OwnProps = {
  activeStoryId: EditionID;
  shots: ExtendedShot[];
  videoDuration: number;
  showMarker: boolean;
};

const MAX_RETRIES = 3;
const RETRY_INTERVAL_MS = 5000;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const authType = getAuthType(state);
  const isSnapSSO = authType === AuthType.SNAPCHAT;
  const transcodedMediaId = getVideoInfoForStory(state)(ownProps.activeStoryId).transcodedMediaId;
  const externalMediaId = transcodedMediaId && getExternalMediaId(state)(transcodedMediaId);

  return {
    externalMediaId,
    isSnapSSO,
  } as StateProps;
};

export type Props = OwnProps & StateProps & ReactCursorPositionProps;

export class TimelineHoverDecoration extends React.Component<Props> {
  private images: (HTMLImageElement | undefined)[];

  private imageRetryInterval: ReturnType<typeof setInterval> | null;

  private retryCounter: number;

  constructor(props: Props) {
    super(props);
    this.images = [];
    this.imageRetryInterval = null;
    this.retryCounter = 0;
  }

  componentDidMount() {
    if (this.props.externalMediaId && this.props.isSnapSSO) {
      this.testImageLoad();
    }
  }

  componentDidUpdate(prevProps: Props) {
    // we can load all images on every poll because we will just hit the cache for images loaded before
    const newSnapsHaveBeenGenerated = this.props.shots.length !== prevProps.shots.length;

    if (
      (newSnapsHaveBeenGenerated || !prevProps.externalMediaId) &&
      this.props.isSnapSSO &&
      this.props.externalMediaId
    ) {
      this.testImageLoad();
    }
  }

  componentWillUnmount() {
    if (this.imageRetryInterval) {
      clearInterval(this.imageRetryInterval);
    }
  }

  // attempt to fetch one thumbnail image from the video to see if we are authed/thumbnail service is working
  // before we fire off loads of requests
  testImageLoad = () => {
    const shots = this.props.shots;
    const frameNumber = shots[shots.length - 1]?.startFrame;

    if (frameNumber) {
      const authTestImage = new Image();
      authTestImage.onerror = this.onImageError;
      authTestImage.onload = this.onImageLoad;
      authTestImage.crossOrigin = CrossOrigin.USE_CREDENTIALS;
      authTestImage.src = snapPublisherFrameThumbnailImage(this.props.externalMediaId, frameNumber);
    }
  };

  // test image fetch failed so set a 5sec interval to retry. We need this because it can take a few seconds to auth
  onImageError = () => {
    if (!this.imageRetryInterval) {
      this.imageRetryInterval = setInterval(this.retryLoadImage, RETRY_INTERVAL_MS);
    }
  };

  // Retry test image fetch 3 times
  retryLoadImage = () => {
    if (this.retryCounter < MAX_RETRIES) {
      const shots = this.props.shots;
      const frameNumber = shots[shots.length - 1]?.startFrame;

      if (frameNumber) {
        const authTestImage = new Image();
        authTestImage.onload = this.onImageLoad;
        authTestImage.crossOrigin = CrossOrigin.USE_CREDENTIALS;
        authTestImage.src = snapPublisherFrameThumbnailImage(this.props.externalMediaId, frameNumber);
        this.retryCounter++;
      }
    }
  };

  // Test image fetch was successful, so we must be authed - fetch all images for story
  onImageLoad = () => {
    if (this.imageRetryInterval) {
      clearInterval(this.imageRetryInterval);
    }
    this.images = this.props.shots.map(shot => {
      const url = `${snapPublisherFrameThumbnailImage(this.props.externalMediaId, shot.startFrame)}`;
      const image = new Image();
      image.src = url;
      image.crossOrigin = CrossOrigin.USE_CREDENTIALS;
      image.onerror = this.onBatchImageError;

      return image;
    });
  };

  // When fetching all images, some may fail, if they do set the image to null so it isn't displayed
  onBatchImageError = (e: any) => {
    this.images[this.images.findIndex(image => image?.src === e.currentTarget.src)] = undefined;
  };

  render() {
    const { isActive, position, elementDimensions, videoDuration } = this.props;

    if (!isActive) {
      return null;
    }

    // react cursor position wraps scroll div which is larger than timeline - limit the area where we show tooltip
    if (position.y < 0 || position.y > 21) {
      // cursor is outside of timeline area
      return null;
    }

    // sometimes position.x is negative resulting in a negative distanceThroughVideo
    // so clamp the varaible to 0.0
    const distanceThroughVideo = Math.max(0.0, position.x / elementDimensions.width);

    const timeThroughVideo = videoDuration * distanceThroughVideo;
    const shot = getPlayingSnapOrShot(timeThroughVideo, this.props.shots);

    return (
      <div className={style.root}>
        <TimelineThumbnail
          image={shot ? this.images[shot.index] : undefined}
          data-test="TimelineHoverDecoration.TimelineThumbnail"
          time={timeThroughVideo}
          xPosition={position.x}
        />
        {this.props.showMarker && (
          <Marker data-test="TimelineHoverDecoration.Marker" color="grey" overlay style={{ left: position.x }} />
        )}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(TimelineHoverDecoration);
