import React from 'react';
import type { ChangeEvent } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { setSnapPropertiesAndSave } from 'state/snaps/actions/snapsActions';

import { blurOnEnterKey } from 'utils/browserUtils';
import * as gaUtils from 'utils/gaUtils';
import { getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSInput from 'views/common/components/SDSInput/SDSInput';

import type { Context, SnapId } from 'types/common';

type Props = {
  isReadOnly: boolean;
  snapId: SnapId;
  notes?: string;
  setSnapPropertiesAndSave: typeof setSnapPropertiesAndSave;
};

type InternalState = {
  notesValue: string;
};

export default class NotesControl extends React.Component<Props, InternalState> {
  static contextTypes: Context = {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'IntlShape' is not assignable to type 'IntlCo... Remove this comment to see the full error message
    intl: intlShape,
  };

  constructor(props: Props) {
    super(props);
    this.state = { notesValue: this.props.notes || '' };
  }

  handleNotesBlur = (event: ChangeEvent<EventTarget>) => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'snap-change-title', {
      source: 'TopsnapPanel',
      properties: {
        // Snap names may contain sensitive information and so should not be sent to Google Analytics
        name: '[REDACTED]',
      },
    });

    if ((this.props.notes || '') !== this.state.notesValue) {
      this.props.setSnapPropertiesAndSave({ snapId: this.props.snapId }, { name: this.state.notesValue });
    }
  };

  handleNotesOnChange = (event: ChangeEvent<EventTarget>) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
    const { value } = event.target;
    this.setState({ notesValue: value });
  };

  render() {
    return (
      <SDSInput
        labelTitle={<FormattedMessage id="snap-notes-label" description="Snap notes label" defaultMessage="Notes" />}
        value={this.state.notesValue}
        placeholder={getLocalisedMessageFromId(this.context, 'notes-placeholder')}
        onBlur={this.handleNotesBlur}
        disabled={this.props.isReadOnly}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        onKeyDown={blurOnEnterKey}
        onChange={this.handleNotesOnChange}
        data-test="topsnapPanel.notesInput"
      />
    );
  }
}
