import { get } from 'lodash';
import type { Dispatch } from 'redux';

import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { isBottomSnap } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import { RichSnapComponentType } from 'config/constants';
import * as gaUtils from 'utils/gaUtils';

import type { RichSnapComponent, RichSnapComponentSnap } from 'types/components';
import type { Edition } from 'types/editions';
import type { GetState } from 'types/redux';

function isSelected(snapComponent?: RichSnapComponent | null) {
  if (!snapComponent) {
    return false;
  }
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return get(this, ['props', 'activeComponent', 'componentId']) === snapComponent.componentId;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'componentType' implicitly has an 'any' ... Remove this comment to see the full error message
function isTileComponent(componentType) {
  return (
    componentType === RichSnapComponentType.TILE_PLACEHOLDER ||
    componentType === RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER ||
    componentType === RichSnapComponentType.TILE
  );
}

export function setActive(snapComponent: RichSnapComponentSnap) {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const state = getState();
    const activeComponent = componentsSelectors.getActiveComponent(state);
    const topsnapId = editorSelectors.getActiveWholeSnapId(state);
    const topSnap = topsnapId ? snapsSelectors.getSnapById(state)(topsnapId) : null;
    const activePublisherDetails = publishersSelectors.getActivePublisherDetails(state);
    const edition: Edition | undefined | null = editorSelectors.getActiveEdition(state);
    const snap = snapComponent.snap;

    if (isSelected(snapComponent)) {
      return Promise.resolve();
    }

    if (!edition) {
      return Promise.resolve();
    }

    const { componentType } = snapComponent;
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'component-select', {
      componentType,
      snapType: get(snapComponent, ['snap', 'type']),
    });

    /*
      The interaction between setting the active component and changing the URL is a little tricky, here's a table
      of the possible navigations and what should happen - should we activate the new component, do we need to change
      the url, and is this interaction handled elsewhere (navigation to tiles is handled in editTile and editTileById)
      navigate from   navigate to   activate?   change url?   handled elsewhere?
      --------------------------------------------------------------------------
      tile            bottomsnap    no          yes           no
      topsnap         bottomsnap    no          yes           no
      bottomsnap      topsnap       no          yes           no
      bottomsnap      tile          yes         yes           yes
      topsnap         tile          yes         no            yes
      tile            topsnap       yes         no            no
      new attachment  topsnap       yes         no            no
      new attachment  tile          yes         no            yes
    */

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const currentComponentIsTile = isTileComponent(activeComponent.componentType);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const currentComponentIsTopsnap = activeComponent.snap && activeComponent.snap.id === get(topSnap, 'id');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const currentComponentIsBottomSnap = activeComponent.snap && isBottomSnap(activeComponent.snap);
    const addingNewInteraction = !currentComponentIsTile && !currentComponentIsTopsnap && !currentComponentIsBottomSnap;

    const activatingTopsnap = topSnap && snap.id === topSnap.id;
    const activatingBottomsnap = !activatingTopsnap;

    const tileToBottomSnap = currentComponentIsTile && activatingBottomsnap;
    const topsnapToBottomSnap = currentComponentIsTopsnap && activatingBottomsnap;

    const bottomSnapToTopsnap = currentComponentIsBottomSnap && activatingTopsnap;
    const tileToTopsnap = currentComponentIsTile && activatingTopsnap;

    const addingNewAttachmentToTopsnap = addingNewInteraction && activatingTopsnap;

    // topsnap to tile and bottom snap to tile is not handled here, this is handled in editTile and editTileById
    if (bottomSnapToTopsnap) {
      // @ts-expect-error ts-migrate(2739) FIXME: Type 'AnyAction' is missing the following properti... Remove this comment to see the full error message
      return dispatch(
        // @ts-expect-error ts-migrate(2739) FIXME: Type 'AnyAction' is missing the following properti... Remove this comment to see the full error message
        publisherStoryEditorModeActions.openSnapEditor({
          publisherId: activePublisherDetails?.id,
          editionId: edition.id,
          snapId: snap.id,
        })
      );
    }
    if (tileToBottomSnap || topsnapToBottomSnap) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'AnyAction' is not assignable to type 'Promis... Remove this comment to see the full error message
      return dispatch(
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'AnyAction' is not assignable to type 'Promis... Remove this comment to see the full error message
        routerActions.goToAttachment({
          publisherId: activePublisherDetails?.id,
          editionId: edition.id,
          snapId: get(topSnap, 'id'),
          attachmentId: snap.id,
          attachmentType: snap.type,
        })
      );
    }
    if (tileToTopsnap || addingNewAttachmentToTopsnap) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'AnyAction' is not assignable to type 'Promis... Remove this comment to see the full error message
      return dispatch(editorActions.setActiveComponentId(snapComponent.componentId));
    }

    return Promise.resolve();
  };
}
