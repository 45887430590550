// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import { get } from 'lodash';

import { EMPTY_ARRAY, PollScreen } from 'config/constants';

import type { PollType, PollScreenEnum, Question } from 'types/polls';

type Features = {
  isPollsTitleEditable: boolean | undefined | null;
};

const POLL_TYPE_TO_POLL_SCREEN: {
  [k in PollType]: PollScreenEnum[];
} = {
  [POLL_TYPE.FACTUAL_QUESTION]: [PollScreen.UNANSWERED],
};

// Temporary map to start supporting editable title.
const POLL_TYPE_TO_POLL_SCREEN_V2: {
  [k in PollType]: PollScreenEnum[];
} = {
  [POLL_TYPE.FACTUAL_QUESTION]: [PollScreen.UNANSWERED, PollScreen.RESPONDED],
  [POLL_TYPE.POLL]: [PollScreen.UNANSWERED, PollScreen.RESPONDED, PollScreen.ENDED],
  [POLL_TYPE.VOTE]: [PollScreen.UNANSWERED, PollScreen.RESPONDED, PollScreen.ENDED],
  [POLL_TYPE.OPEN_QUESTION]: [PollScreen.UNANSWERED],
};

// Only for Trivia Quiz with explanation page turned on
const TRIVIA_QUIZ_WITH_EXPLANATION_SCREEN = [
  // @ts-expect-error ts-migrate(2461) FIXME: Type 'string[] | undefined' is not an array type.
  ...POLL_TYPE_TO_POLL_SCREEN[POLL_TYPE.FACTUAL_QUESTION],
  PollScreen.EXPLANATION,
];

// Only for Trivia Quiz with explanation page turned on
const TRIVIA_QUIZ_WITH_EXPLANATION_SCREEN_V2 = [
  // @ts-expect-error ts-migrate(2461) FIXME: Type 'string[] | undefined' is not an array type.
  ...POLL_TYPE_TO_POLL_SCREEN_V2[POLL_TYPE.FACTUAL_QUESTION],
  PollScreen.EXPLANATION,
];

export function getPollScreens(
  question: Question,
  features: Features = { isPollsTitleEditable: false }
): Array<PollScreenEnum> {
  const { pollType } = question;
  const showExplanationScreen = get(question, ['editorProperties', 'showExplanationScreen']);

  if (pollType === POLL_TYPE.FACTUAL_QUESTION && showExplanationScreen) {
    return features.isPollsTitleEditable ? TRIVIA_QUIZ_WITH_EXPLANATION_SCREEN_V2 : TRIVIA_QUIZ_WITH_EXPLANATION_SCREEN;
  }

  if (features.isPollsTitleEditable) {
    return POLL_TYPE_TO_POLL_SCREEN_V2[pollType] || EMPTY_ARRAY;
  }

  return POLL_TYPE_TO_POLL_SCREEN[pollType] || EMPTY_ARRAY;
}
