import React from 'react';

import { CrossOrigin } from 'config/constants';
import { getImagePreviewUrl } from 'utils/media/assetUtils';

import Icon from 'views/common/components/Icon/Icon';

import style from './SubscribeOverlay.scss';

type Props = {
  overlayImageAssetId: string;
};

export function SubscribeOverlay(props: Props) {
  const overlayMediaSrc = getImagePreviewUrl(props.overlayImageAssetId);
  const overlayComponent = overlayMediaSrc && (
    <Icon img={overlayMediaSrc} crossOrigin={CrossOrigin.USE_CREDENTIALS} alt="Snap overlay" className={style.canvas} />
  );
  return (
    <div className={style.root} data-test="richSnapEditor.topsnapEditor.overlay">
      {overlayComponent}
    </div>
  );
}

export default SubscribeOverlay;
