import classNames from 'classnames';
import _ from 'lodash';
import log from 'loglevel';
import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { reorderSnaps } from 'state/scs/actions/storyActions';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AddSnapRuler } from '../../components/AddSnapRuler/AddSnapRuler';
import DraggableSegmentCarouselItem from '../DraggableSegmentCarouselItem/DraggableSegmentCarouselItem';
import StoryCarouselDragLayer from '../StoryCarouselDragLayer/StoryCarouselDragLayer';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';

import { StoryAnimationManager, AnimationTypes } from './StoryAnimationManager';
import style from './StoryCarousel.scss';
import type { SegmentNode, SnapNode, DomElement } from './StoryCarouselTypes';
import StoryChangeManager from './StoryChangeManager';

import type { BuildStatusType } from 'types/build';
import type { Edition } from 'types/editions';
import { Publisher } from 'types/publishers';
import { SnapType } from 'types/snaps';
import type { Snap } from 'types/snaps';

type StateProps = {
  activePublisherId: number;
  isActiveEditionReadOnly: boolean;
  isEditionSegmentsEnabled: boolean;
  buildStatuses: (id: string) => BuildStatusType | undefined | null;
  isCuratedLayerEnabled: boolean;
  isAddingSnapToEdition: boolean;
  publisher: Publisher | null;
};
const mapStateToProps = (state: State): StateProps => ({
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
  activePublisherId: userSelectors.getActivePublisherId(state),
  isActiveEditionReadOnly: publisherStoryEditorSelectors.getActiveEditionIsReadOnly(state),
  isEditionSegmentsEnabled: featuresSelectors.isEditionSegmentsEnabled(state),
  buildStatuses: buildStatusSelectors.getDiscoverSnapBuildStatus(state),
  isCuratedLayerEnabled:
    featuresSelectors.isCuratedLayerEnabled(state) || featuresSelectors.isAdvancedCurationEnabled(state),
  publisher: getActivePublisherDetails(state),
});

type DispatchProps = {
  addNewSnapToEdition: (a: any) => any;
  addMultipleSnapsWithMediaToEdition: typeof publisherStoryEditorActions.addMultipleSnapsWithMediaToEdition;
  addMediaToStory: typeof mediaLibraryActions.addMediaToStory;
  openSnapEditor: typeof publisherStoryEditorModeActions.openSnapEditor;
  reorderSnaps: typeof reorderSnaps;
};
const mapDispatchToProps: DispatchProps = {
  addMultipleSnapsWithMediaToEdition: publisherStoryEditorActions.addMultipleSnapsWithMediaToEdition,
  addMediaToStory: mediaLibraryActions.addMediaToStory,
  addNewSnapToEdition: publisherStoryEditorActions.addNewSnapToEdition,
  openSnapEditor: publisherStoryEditorModeActions.openSnapEditor,
  reorderSnaps,
};
type OwnProps = {
  activeEdition: Edition;
  activeEditionSnaps: Snap[];
  className: string | undefined | null;
  onClick: () => void;
};
type Props = OwnProps & DispatchProps & StateProps;
type OwnState = {
  isDragging: boolean;
  pendingEditionUpdate: boolean;
  addingSnap: boolean;
};
export class StoryCarousel extends React.Component<Props, OwnState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'changeManager' has no initializer and is... Remove this comment to see the full error message
  changeManager: StoryChangeManager;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'animationManager' has no initializer and... Remove this comment to see the full error message
  // eslint-disable-line react/sort-comp
  animationManager: StoryAnimationManager;

  state: OwnState = {
    isDragging: false,
    pendingEditionUpdate: false,
    addingSnap: false,
  };

  UNSAFE_componentWillMount() {
    this.changeManager = StoryChangeManager.create();
    this.changeManager.setEdition(this.props.activeEdition, this.props.activeEditionSnaps);
    this.animationManager = StoryAnimationManager.create(this.onAnimationComplete);
    this.animationManager.setSegmentNodes(this.changeManager.getSegmentNodes(), {
      disableAnimation: true,
      animationType: AnimationTypes.UPDATE,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !_.isEqual(this.props.activeEditionSnaps, nextProps.activeEditionSnaps) ||
      !_.isEqual(this.props.activeEdition.segments, nextProps.activeEdition.segments)
    ) {
      if (this.canUpdateEdition()) {
        this.updateEdition(nextProps);
      } else {
        this.setState({ pendingEditionUpdate: true });
      }
    }
  }

  onAnimationComplete = () => {
    if (this.state.pendingEditionUpdate) {
      if (this.canUpdateEdition()) {
        this.updateEdition(this.props);
      }
    }
  };

  // Need to receive a callback, otherwise the caller has no way of knowing if the call was throttled or not
  setItemIndex = (oldIndex: number[], newIndex: number[], callback: (a: number[]) => void) => {
    const actualNewIndex: number[] = this.changeManager.moveToIndex(oldIndex, newIndex);
    if (!_.isEqual(actualNewIndex, oldIndex)) {
      this.animationManager.setSegmentNodes(this.changeManager.getSegmentNodes(), {
        disableAnimation: false,
        animationType: AnimationTypes.DRAG,
      });
      // Trigger re-render
      this.setState({});
    }
    callback(actualNewIndex);
  };

  canUpdateEdition() {
    return !this.animationManager.hasPendingAnimation() && !this.state.isDragging;
  }

  canDrag = () => {
    return !this.animationManager.hasPendingAnimation() && !this.isReadOnly();
  };

  saveSegmentDomElement = (segment: SegmentNode, element: DomElement) => {
    this.animationManager.setSegmentElement(segment, element);
  };

  saveSnapDomElement = (snap: SnapNode, element: DomElement) => {
    this.animationManager.setSnapElement(snap, element);
  };

  updateEdition(props: Props) {
    this.changeManager.updateEdition(props.activeEdition, props.activeEditionSnaps);
    this.animationManager.setSegmentNodes(this.changeManager.getSegmentNodes(), {
      disableAnimation: false,
      animationType: AnimationTypes.UPDATE,
    });
    this.setState({ pendingEditionUpdate: false });
  }

  beginDrag = () => {
    this.setState({ isDragging: true });
    this.changeManager.startMovement();
  };

  onDragSuccess = () => {
    // May have pending edition update as these are disabled while dragging is in progress
    if (this.state.pendingEditionUpdate && !this.animationManager.hasPendingAnimation()) {
      this.updateEdition(this.props);
    }
    this.setState({ isDragging: false });
  };

  onDragFail = () => {
    // If edition save failed we must rollback. We do this by re-setting the current
    // edition (or scheduling the re-setting). If the edition has pending updates these
    // will be applied instead.
    if (!this.animationManager.hasPendingAnimation()) {
      this.updateEdition(this.props);
    } else {
      this.setState({ pendingEditionUpdate: true });
    }
    this.setState({ isDragging: false });
  };

  endDrag = () => {
    this.animationManager.finishAllAnimations();
    const editionDiff = this.changeManager.buildEditionDiff();
    const reorderOperation = this.changeManager.calculateReorderOperation();
    this.changeManager.endMovement();
    // Only saves the new edition order if it did not change
    if (Object.keys(editionDiff).length > 0) {
      gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'reorder-snaps');
      (this.props.reorderSnaps(
        { editionId: this.props.activeEdition.id, operation: reorderOperation },
        editionDiff
      ) as any)
        .then(this.onDragSuccess)
        .catch(this.onDragFail);
    } else {
      this.onDragSuccess();
    }
  };

  convertSingleSnapSegment = (segmentNumber: number, toReal: boolean) => {
    this.changeManager.convertSingleSnapSegment(segmentNumber, toReal);
    this.animationManager.setSegmentNodes(this.changeManager.getSegmentNodes(), {
      disableAnimation: false,
      animationType: AnimationTypes.DRAG,
    });
    this.animationManager.finishAllAnimations();
    const editionDiff = this.changeManager.buildEditionDiff();
    const reorderOperation = this.changeManager.calculateReorderOperation();
    this.changeManager.resetMovement();
    this.props.reorderSnaps({ editionId: this.props.activeEdition.id, operation: reorderOperation }, editionDiff);
    // Trigger re-render
    this.setState({});
  };

  addSnap = (index: number): void => {
    // Shouldn't happen as the addruler should not even show
    // but if it did it could be disastrous.
    if (this.state.addingSnap) {
      log.error('Trying to add snap while another one is being added, ignoring');
      return;
    }
    if (this.props.isAddingSnapToEdition) {
      log.info('Import snaps to edition is happening, ignoring');
      return;
    }
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'create-topsnap');
    this.setState({ addingSnap: true });
    const setAddingSnapFalseFn = () => {
      this.setState({ addingSnap: false });
    };
    this.props
      .addNewSnapToEdition({
        editionId: this.props.activeEdition.id,
        index,
        snapType: SnapType.IMAGE,
      })
      .then(setAddingSnapFalseFn)
      .catch(setAddingSnapFalseFn);
  };

  isReadOnly() {
    return this.props.isActiveEditionReadOnly || this.state.addingSnap || false;
  }

  getOnDropFileAddSnapRule = (initialIndex: number) => {
    return (files?: Blob[] | null, mediaLibraryItems?: any | null) => {
      if (files) {
        this.props.addMultipleSnapsWithMediaToEdition({
          editionId: this.props.activeEdition.id,
          snapType: SnapType.IMAGE,
          initialIndex,
          files,
        });
      }
      if (mediaLibraryItems) {
        incrementCounterByPublisher(this.props.publisher, GrafanaMetrics.MEDIA_V2, {
          type: 'mediaUploadDrawerMediaItems',
          filter: 'UploadToIndex',
        });
        this.props.addMediaToStory({
          snaps: mediaLibraryItems,
          editionId: this.props.activeEdition.id,
          initialIndex,
          isCuratedLayerEnabled: this.props.isCuratedLayerEnabled,
        });
      }
    };
  };

  renderSnaps() {
    const segmentNodes = this.changeManager.getSegmentNodes();
    let segmentNumber = 1;
    const segmentsToRender = segmentNodes.map(segment => {
      // We pass 'canDrag' as an object that contains the real flag. That avoids a re-render
      // and gets applied immediately by the DragSource. Note that this does not make the component
      // non-pure because 'canDrag' is not used for rendering.
      return {
        element: (
          <CSSTransition
            timeout={{
              enter: this.animationManager.getMaxAnimationDuration(),
              exit: this.animationManager.getMaxAnimationDuration(),
            }}
            key={segment.key}
            classNames="none"
          >
            <DraggableSegmentCarouselItem
              isEditionSegmentsEnabled={this.props.isEditionSegmentsEnabled}
              segment={segment}
              segmentNumber={segment.isVirtualSegment ? null : segmentNumber++}
              setItemIndex={this.setItemIndex}
              endDrag={this.endDrag}
              beginDrag={this.beginDrag}
              canDrag={this.canDrag}
              isReadOnly={this.isReadOnly()}
              isAnySnapBeingDragged={this.state.isDragging}
              saveSegmentDomElement={this.saveSegmentDomElement}
              saveSnapDomElement={this.saveSnapDomElement}
              animationDuration={this.animationManager.getMaxAnimationDuration()}
              edition={this.props.activeEdition}
              addSnap={this.addSnap}
              /* @ts-expect-error ts-migrate(2322) FIXME: Type '(id: string) => BuildStatusType | null | und... Remove this comment to see the full error message */
              buildStatuses={this.props.buildStatuses}
              convertSingleSnapSegment={this.convertSingleSnapSegment}
            />
          </CSSTransition>
        ),
        id: segment.id,
        numberOfSnaps: segment.snapNodes.length,
      };
    });
    if (this.isReadOnly()) {
      return _.map(segmentsToRender, a => a.element);
    }
    const components = [];
    let snapIndex = 0;
    segmentsToRender.forEach((segment, index) => {
      const addSnapCallback = this.addSnap.bind(this, snapIndex);
      components.push(
        <CSSTransition
          timeout={{
            enter: this.animationManager.getMaxAnimationDuration(),
            exit: this.animationManager.getMaxAnimationDuration(),
          }}
          key={`snapruler-${segment.id}`}
          classNames="none"
        >
          <AddSnapRuler
            showDropzone={index > 0}
            onDropFile={this.getOnDropFileAddSnapRule(snapIndex)}
            onClick={addSnapCallback} // eslint-disable-line react/jsx-no-bind
            className={style.addRuler}
          />
        </CSSTransition>
      );
      components.push(segment.element);
      // Tracks the snap index up to the last segment so we can calculate the add index
      snapIndex += segment.numberOfSnaps;
    });
    // Add 'add ruler' past last snap only if it is not a subscribe snap
    if (segmentNodes.length > 0) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const lastSnap = segmentNodes[segmentNodes.length - 1].snapNodes[0].snap;
      if (!lastSnap || !snapEntityHelpers.isSubscribeSnap(lastSnap)) {
        const addSnapCallback = this.addSnap.bind(this, snapIndex);
        components.push(
          <CSSTransition
            timeout={{
              enter: this.animationManager.getMaxAnimationDuration(),
              exit: this.animationManager.getMaxAnimationDuration(),
            }}
            key="snapruler-final"
            classNames="none"
          >
            <AddSnapRuler
              onClick={addSnapCallback} // eslint-disable-line react/jsx-no-bind
              className={style.addRuler}
            />
          </CSSTransition>
        );
      }
    }
    return components;
  }

  render() {
    // Using CSSTransitionGroup not for the transition themselves, but because it
    // delays the removal of elements by the timeout amount so the animation manager
    // has time to play the animations
    return (
      <div
        className={classNames(this.props.className, style.parentContainer)}
        onClick={this.props.onClick}
        data-test="storySnapList.mainDiv"
      >
        <StoryCarouselDragLayer />
        <TransitionGroup
          className={classNames(style.container, {
            [style.dragging]: this.state.isDragging,
          })}
        >
          {this.renderSnaps()}
        </TransitionGroup>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryCarousel);
