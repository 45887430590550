import invariant from 'invariant';
import React from 'react';

import { getAssetById } from 'state/asset/selectors/assetSelectors';
import { isGeneratingSubtitles } from 'state/buildStatus/schema/buildStatusHelpers';
import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { getActiveTopsnap, getActiveEditionId } from 'state/editor/selectors/editorSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { getEditionSnaps } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as subtitlesActions from 'state/subtitles/actions/subtitlesActions';
import * as subtitlesSelectors from 'state/subtitles/selectors/subtitlesSelectors';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import MultiLanguageSubtitlesManager from '../MultiLanguageSubtitlesManager/MultiLanguageSubtitlesManager';

import { DropzoneType, UploadPurpose } from 'config/constants';
import type { SubtitleTrack } from 'constants/subtitles';
import { ellipsis } from 'icons/SDS/allIcons';
import { buildComponentIdForSnapId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SubtitlesPreview from 'views/common/components/SubtitlesPreview/SubtitlesPreview';
import SubtitlesLanguageDropdownForAsset from 'views/common/components/SubtitlesPreview/components/SubtitlesLanguagesDropdown/SubtitlesLanguageDropdownForAsset';
import SubtitlesOptionsPopover from 'views/publisherStoryEditor/containers/SubtitlesOptionsPopover/SubtitlesOptionsPopover';

import type { AssetID } from 'types/assets';
import type { BuildStatusType } from 'types/build';
import type { SnapId } from 'types/common';
import type { State } from 'types/rootState';
import { TopSnap, VideoSnap } from 'types/snaps';

type StateProps = {
  topsnapId: SnapId | null;
  videoAssetId: AssetID;
  subtitlesFileName: string;
  snapBuildStatus: BuildStatusType | undefined | null;
  isReadOnly: boolean;
  subtitlesTrack: SubtitleTrack | undefined | null;
  hasSubtitles: boolean;
  rawSubtitles: string | undefined | null;
  subtitlesPreviewEnabled: boolean;
};

type DispatchProps = {
  setSubtitlesPreview: typeof subtitlesActions.setSubtitlesPreview;
  deleteTopsnapSubtitles: typeof videoLibraryActions.deleteTopsnapSubtitles;
};

type Props = StateProps & DispatchProps;

function mapStateToProps(state: State): StateProps {
  const topsnap = getActiveTopsnap(state);
  const topsnapId = topsnap?.id || null;
  const videoAssetId = (topsnap as VideoSnap)?.videoAssetId;
  const storyId = getActiveEditionId(state);
  const snaps = getEditionSnaps(state)(storyId) as TopSnap[];
  const snapIndex = snaps.findIndex(snap => snap?.id === topsnapId);
  const paddedIndex = String(snapIndex + 1).padStart(2, '0');
  const subtitlesFileName = `Story_${String(storyId)}_Snap_${paddedIndex}.vtt`;
  const isReadOnly = editorSelectors.isReadOnly(state);
  const videoAsset = getAssetById(state)(videoAssetId);
  const subtitlesTrack = subtitlesSelectors.getSubtitlesByActiveLanguageAndVideoAssetId(state)(videoAssetId);
  const rawSubtitles = subtitlesTrack
    ? subtitlesSelectors.getSubtitlesTrackContentById(state)(subtitlesTrack.assetId)
    : undefined;
  const subtitlesPreviewEnabled = subtitlesSelectors.getSubtitlesVisibility(state);

  return {
    topsnapId,
    videoAssetId,
    subtitlesFileName,
    snapBuildStatus: topsnapId ? getDiscoverSnapBuildStatus(state)(topsnapId) : null,
    isReadOnly,
    subtitlesTrack,
    hasSubtitles: Boolean(!videoAsset || subtitlesTrack),
    rawSubtitles,
    subtitlesPreviewEnabled,
  };
}
const mapDispatchToProps = {
  setSubtitlesPreview: subtitlesActions.setSubtitlesPreview,
  deleteTopsnapSubtitles: videoLibraryActions.deleteTopsnapSubtitles,
};

export class TopSnapSubtitlesPreview extends React.PureComponent<Props> {
  onDelete = () => {
    const { deleteTopsnapSubtitles, topsnapId, videoAssetId, subtitlesTrack } = this.props;
    const languageToDelete = subtitlesTrack?.language;
    invariant(languageToDelete, 'Track does not have the language defined');

    grapheneUtils.incrementCounter('subtitles', {
      click: 'delete_topsnap_subtitles',
      language: languageToDelete,
    });

    deleteTopsnapSubtitles(topsnapId, videoAssetId, languageToDelete);
  };

  onChangePreview = () => {
    this.props.setSubtitlesPreview(!this.props.subtitlesPreviewEnabled);
  };

  renderSubtitleStoryControls = () => {
    return (
      <MultiLanguageSubtitlesManager
        purpose={UploadPurpose.TOPSNAP_SUBTITLES}
        dropzoneType={DropzoneType.TOPSNAP_SUBTITLES}
        isReadOnly={this.props.isReadOnly}
        dataTestPrefix="TopSnapSubtitlesPreview"
        hasSubtitles={this.props.hasSubtitles}
        showInfo={false}
        subtitlesDropdown={this.renderSubtitleTrackDropdown()}
        optionsPopover={this.renderSubtitlesPopover()}
        snapId={this.props.topsnapId}
        videoAssetId={this.props.videoAssetId}
      />
    );
  };

  renderSubtitlesPopover() {
    if (!this.props.hasSubtitles) {
      return null;
    }

    return (
      <SubtitlesOptionsPopover
        videoAssetId={this.props.videoAssetId}
        subtitlesFileName={this.props.subtitlesFileName}
        rawSubtitles={this.props.rawSubtitles}
        subtitlesUrl={this.props.subtitlesTrack ? this.props.subtitlesTrack.source : ''}
        subtitlesPreviewEnabled={this.props.subtitlesPreviewEnabled}
        onDelete={this.onDelete}
        onChangePreview={this.onChangePreview}
        isReadOnly={this.props.isReadOnly}
        data-test="TopSnapSubtitlesPreview.subtitlesOptionsPopover"
      >
        <SDSButton
          type={ButtonType.WHITE}
          shape={ButtonShape.CIRCLE}
          inlineIcon={ellipsis}
          data-test="TopSnapSubtitlesPreview.subtitlesStoryConfigButton"
        />
      </SubtitlesOptionsPopover>
    );
  }

  renderSubtitleTrackDropdown = () => {
    return <SubtitlesLanguageDropdownForAsset videoAssetId={this.props.videoAssetId} />;
  };

  render() {
    const { topsnapId, videoAssetId, subtitlesFileName, snapBuildStatus } = this.props;

    if (!topsnapId || !videoAssetId) {
      return null;
    }
    const isWaitingForSubtitles = isGeneratingSubtitles(snapBuildStatus);

    return (
      <div>
        {this.renderSubtitleStoryControls()}
        <SubtitlesPreview
          key={videoAssetId}
          videoAssetId={videoAssetId}
          subtitlesFileName={subtitlesFileName}
          isWaitingForSubtitles={isWaitingForSubtitles}
          topsnapId={topsnapId}
          uploadParams={{
            snapId: topsnapId,
            purpose: UploadPurpose.TOPSNAP_SUBTITLES,
            componentId: buildComponentIdForSnapId(topsnapId),
          }}
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TopSnapSubtitlesPreview);
