import classNames from 'classnames';
import React from 'react';

import { CrossOrigin } from 'config/constants';
import { TileFormatConfig, TileCropType, TileFormat } from 'config/tileConfig';
import * as intlMessages from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import TilePreview from 'views/editor/containers/TilePreview/TilePreview';
import cheetahImgBackground from 'views/editor/containers/TilePreviewEditor/icons/cheetah.png';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './TileBigPreview.scss';

import { AssetType } from 'types/assets';

const CropGuideEnabled = [TileCropType.CHEETAH];
const getTileFormat = (key: TileCropType) => {
  // all of tile formats follow this convention.
  // if it's ever needed, add more logic here
  const generatedKey = `PRE_${key}` as TileFormat;
  return TileFormatConfig[generatedKey];
};
export const nonRoundCornerTypes = [TileCropType.COLLAPSED, TileCropType.HORIZONTAL];
type OwnTileBigPreviewProps = {
  activeTypeId: TileCropType;
  tile?: any;
  primaryCrop?: any; // TODO: PropTypes.oneOf(Object.values(TileCropType))
  isInteractive?: boolean;
  dummyHeadline?: string;
  dummyLogo?: string;
  newTileDefaultProperties?: any;
};
type TileBigPreviewState = any;
type TileBigPreviewProps = OwnTileBigPreviewProps & typeof TileBigPreview.defaultProps;
export class TileBigPreview extends React.Component<TileBigPreviewProps, TileBigPreviewState> {
  static defaultProps = {
    isInteractive: true,
  };

  state = {
    showCropArea: false,
    showVideoThumbnail: false,
  };

  shouldComponentUpdate(nextProps: TileBigPreviewProps, nextState: TileBigPreviewState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  handleCropToggleClick = () => {
    this.setState((prevState: TileBigPreviewState) => ({
      showCropArea: !prevState.showCropArea,
    }));
  };

  handleVideoThumbnailClick = () => {
    this.setState((prevState: TileBigPreviewState) => ({
      showVideoThumbnail: !prevState.showVideoThumbnail,
    }));
  };

  renderTilePreview(tileFormat: any, activeTypeId: any) {
    const { tile, isInteractive, dummyHeadline, dummyLogo, newTileDefaultProperties } = this.props;
    return (
      <TilePreview
        format={tileFormat}
        tile={tile}
        roundCorners={!nonRoundCornerTypes.includes(activeTypeId)}
        isEditable={isInteractive}
        forceImagePreview={this.state.showVideoThumbnail}
        dummyHeadline={dummyHeadline}
        dummyLogo={dummyLogo}
        newTileDefaultProperties={newTileDefaultProperties}
      />
    );
  }

  renderBigView(imgsrc: any) {
    return (
      <div className={style.rootClass}>
        <div className={(style as any).snapTileContainer}>
          <img className={style.image} crossOrigin={CrossOrigin.USE_CREDENTIALS} src={imgsrc} alt={imgsrc} />
        </div>
      </div>
    );
  }

  renderCropArea() {
    if (!this.state.showCropArea) {
      return null;
    }
    return <div className={style.cropArea}>{intlMessages.getMessageFromId('cropped-area')}</div>;
  }

  renderInteractiveOptions() {
    const { activeTypeId, isInteractive } = this.props;
    return (
      <div className={style.interactiveOptions} data-test="tileBigPreview.interactiveOption">
        {isInteractive && this.renderShowVideoThumbnail(activeTypeId)}
        {isInteractive && this.renderCropGuideToggle(activeTypeId)}
      </div>
    );
  }

  renderCropGuideToggle(activeTypeId: any) {
    const { tile } = this.props;
    if (!tile || !tile.baseImageAssetId || !CropGuideEnabled.includes(activeTypeId)) {
      return null;
    }
    const buttonText = this.state.showCropArea
      ? intlMessages.getMessageFromId('hide-crop-guide')
      : intlMessages.getMessageFromId('show-crop-guide');
    return (
      <SDSButton type={ButtonType.SECONDARY} onClick={this.handleCropToggleClick}>
        {buttonText}
      </SDSButton>
    );
  }

  renderShowVideoThumbnail(activeTypeId: any) {
    const { tile } = this.props;
    if (!tile || !tile.baseImageAssetId || tile.type !== AssetType.VIDEO) {
      return null;
    }
    const buttonText = this.state.showVideoThumbnail
      ? intlMessages.getMessageFromId('hide-video-thumbnail')
      : intlMessages.getMessageFromId('show-video-thumbnail');
    return (
      <SDSTooltip
        id="videoThumbnailTooltip"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={intlMessages.getMessageFromId('video-thumbnail-tooltip')}
      >
        <div className={style.videoThumbnailContainer}>
          <SDSButton
            type={ButtonType.SECONDARY}
            onClick={this.handleVideoThumbnailClick}
            data-test="editor.tilePreview.videoThumbnail.button"
          >
            {buttonText}
          </SDSButton>
        </div>
      </SDSTooltip>
    );
  }

  render() {
    const { activeTypeId } = this.props;
    const tileFormat = getTileFormat(activeTypeId);
    return (
      <div className={style.snapTileWrapper}>
        <div className={style.previewWrapper}>
          <div className={classNames(style.cheetahPos, style.tileLayer, { 'cropped-guide': this.state.showCropArea })}>
            {this.renderTilePreview(tileFormat, activeTypeId)}
            {this.renderCropArea()}
          </div>

          <div className={style.snapTilePreview}>{this.renderBigView(cheetahImgBackground)}</div>
          {this.renderInteractiveOptions()}
        </div>
      </div>
    );
  }
}
export default TileBigPreview;
