import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import {
  isSingleAssetStoryEditorEnabled,
  isSnapPublisherIngestionEnabled,
} from 'state/features/selectors/featuresSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as previewsSelectors from 'state/previews/selectors/previewsSelectors';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { topsnapHasMedia, getAssetId, isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as subtitlesSelectors from 'state/subtitles/selectors/subtitlesSelectors';

import { UploadFormat, UploadPurpose, DropzoneType, EDITOR_MODES } from 'config/constants';
import type { CropPositionEnum } from 'config/constants';
import { upload, link, pencil } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { getImagePreviewUrl, getVideoPreviewUrl } from 'utils/media/assetUtils';

import CropPreview from 'views/common/components/CropPreview/CropPreview';
import MediaLibraryButton from 'views/common/components/MediaLibraryButton/MediaLibraryButton';
import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';
import TopsnapNavigation from 'views/common/components/TopsnapNavigation/TopsnapNavigation';
import TopsnapPreview from 'views/common/components/TopsnapPreview/TopsnapPreview';
import SubscribeOverlay from 'views/editor/components/SubscribeOverlay/SubscribeOverlay';
import TopsnapEditorRow from 'views/editor/components/TopsnapEditorRow/TopsnapEditorRow';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';
import { getSubtitlesPreviewUrl } from 'views/subtitles/state/selectors/subtitlesEditorSelectors';

import style from './TopsnapEditor.scss';

import type { AssetID } from 'types/assets';
import type { BuildStatusType } from 'types/build';
import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import { SnapType } from 'types/snaps';
import type { TopSnap } from 'types/snaps';

const mapStateToProps = (state: State, props: any) => {
  const id = extractSnapIdFromComponentId(props.snapComponentId);
  const activeTopsnapUploadCount =
    mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(props.snapComponentId, UploadPurpose.TOP_SNAP) +
    mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
      props.snapComponentId,
      UploadPurpose.TOPSNAP_SUBTITLES
    );
  const editionId = editorSelectors.getActiveEditionId(state);
  const videoAssetId = snapsSelectors.getVideoAssetIdBySnapId(state)(id);
  const subtitlesSrc = videoAssetId ? getSubtitlesPreviewUrl(state)(videoAssetId) : null;
  return {
    topsnap: snapsSelectors.getSnapById(state)(id),
    snapStatus: buildStatusSelectors.getDiscoverSnapBuildStatus(state)(id),
    subtitlesSrc,
    // TODO: uncomment in the next PR.
    // subtitlesPreviewUrl: getSubtitlesEditorUrl(state)(activeSubtitlesTrack && activeSubtitlesTrack.assetId),
    subtitlesPreviewEnabled: subtitlesSelectors.getSubtitlesVisibility(state),
    isLoading: snapsSelectors.isLoadingById(state)(id),
    isSaving: snapsSelectors.isSavingById(state)(id),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ snapId: any; editionId: number... Remove this comment to see the full error message
    isReadOnly: id ? editorSelectors.isLocked(state)({ snapId: id, editionId }) : true,
    activeTopsnapUploadCount,
    hoverCropPosition: editorSelectors.getHoverCropPosition(state),
    editionId,
    publisherId: publishersSelectors.getActivePublisherId(state),
    editionIsReadOnly: editionsSelectors.editionIsReadOnly(state)(editionId),
    isSnapPublisherIngestionEnabled: isSnapPublisherIngestionEnabled(state),
    hasPreview: Boolean(get(previewsSelectors.getSnapPreviews(state), id)),
    isSingleAssetStoryEditorEnabled: isSingleAssetStoryEditorEnabled(state),
  };
};
const mapDispatchToProps = {
  setEditorMode: publisherStoryEditorModeActions.setEditorMode,
};
type StateProps = {
  topsnap: TopSnap | undefined;
  snapStatus: BuildStatusType | undefined | null;
  subtitlesSrc: string | undefined | null;
  // TODO: Uncomment in the next PR
  // subtitlesPreviewUrl: ?string,
  subtitlesPreviewEnabled: boolean;
  isLoading: boolean | undefined | null;
  isSaving: boolean | undefined | null;
  isReadOnly: boolean;
  activeTopsnapUploadCount: number;
  hoverCropPosition: CropPositionEnum;
  editionId: EditionID;
  publisherId: PublisherID;
  editionIsReadOnly: boolean;
  isSnapPublisherIngestionEnabled: boolean;
  hasPreview: boolean;
  isAttachmentVOperaLayoutEnabled: boolean;
  isSingleAssetStoryEditorEnabled: boolean;
};
type DispatchProps = {
  setEditorMode: typeof publisherStoryEditorModeActions.setEditorMode;
};
type ReceivedProps = {
  showNavigationControls: boolean;
  enableDynamicSizing: boolean;
};

type Props = StateProps & DispatchProps & ReceivedProps;
export class TopsnapEditor extends React.Component<Props> {
  onSnapPublisherCreate = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    this.props.setEditorMode(EDITOR_MODES.SNAP_PUB);
  };

  onSnapPublisherCreateAdvanced = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    this.props.setEditorMode(EDITOR_MODES.SNAP_PUB, '', { advancedMode: true });
  };

  onWebImport = () => {
    if (this.props.isSnapPublisherIngestionEnabled) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      this.props.setEditorMode(EDITOR_MODES.WEB_IMPORT);
    }
  };

  getMediaSrc(assetId?: AssetID | null) {
    const { topsnap, hasPreview } = this.props;
    if (!hasPreview || !topsnap || !assetId) {
      return null;
    }
    const type = get(this.props, ['snapStatus', 'topType'], topsnap.type);
    const getPreview = type === SnapType.VIDEO ? getVideoPreviewUrl : getImagePreviewUrl;
    return getPreview(assetId);
  }

  renderSpinner() {
    if (this.props.activeTopsnapUploadCount > 0) {
      return (
        <div className={(style as any).spinnerContainer}>
          <Spinner
            withBox
            loading={this.props.activeTopsnapUploadCount}
            message={SpinnerLabels.UPLOADING}
            data-test="richSnapEditor.topsnapEditor.spinner"
            className={style.spinner}
          />
        </div>
      );
    }
    let loadingCount = 0;
    if (!this.props.topsnap) {
      loadingCount += 1;
    }
    if (this.props.isSaving) {
      loadingCount += 1;
    }
    return (
      <div className={(style as any).spinnerContainer}>
        <Spinner
          withBox
          loading={loadingCount}
          message={SpinnerLabels.SAVING}
          data-test="richSnapEditor.topsnapEditor.spinner"
          className={style.spinner}
        />
      </div>
    );
  }

  renderTopsnapCreation() {
    const { topsnap, editionId, publisherId, editionIsReadOnly } = this.props;
    const showMediaLibraryButton = topsnap && !isSubscribeSnap(topsnap);
    const topsnapHasMediaCheck = topsnap ? topsnapHasMedia(topsnap) : false;
    return (
      <div className={style.newTopsnap}>
        {this.props.showNavigationControls ? <TopsnapNavigation /> : null}
        <div className={style.newTopsnapHeader} data-test="topsnapEditor.header">
          {getMessageFromId('topsnap-creation-header')}
        </div>
        <div className={style.createSnapOptions}>
          <MediaUploader
            snapId={get(this.props.topsnap, 'id')}
            editionId={this.props.editionId}
            uploadFormat={UploadFormat.ONE_FILE}
            purpose={UploadPurpose.TOP_SNAP}
            dropzoneType={DropzoneType.TOPSNAP_CLICK_UPLOAD}
            isReadOnly={this.props.isReadOnly}
          >
            <div className={style.absoluteUploadArea} />
            <TopsnapEditorRow
              rowDescription={getMessageFromId('topsnap-creation-upload-media')}
              icon={upload}
              data-test="editor.topsnapEditor.row.uploadMedia"
            />
          </MediaUploader>
          {!topsnapHasMediaCheck && !editionIsReadOnly && (
            <TopsnapEditorRow
              rowDescription={getMessageFromId('topsnap-creation-customize-a-template')}
              handleClick={this.onSnapPublisherCreate}
              icon={pencil}
              data-test="editor.topsnapEditor.row.designs"
            />
          )}
          {!topsnapHasMediaCheck && !editionIsReadOnly && !this.props.isSingleAssetStoryEditorEnabled && (
            <TopsnapEditorRow
              rowDescription={getMessageFromId('topsnap-creation-design-from-scratch')}
              handleClick={this.onSnapPublisherCreateAdvanced}
              icon={pencil}
              data-test="editor.topsnapEditor.row.designsAdvanced"
            />
          )}
          {showMediaLibraryButton && (
            <MediaLibraryButton
              editionId={editionId}
              publisherId={publisherId}
              snapId={get(this.props.topsnap, 'id')}
              buttonText={getMessageFromId('topsnap-creation-media-library')}
              data-test="topsnapEditor.media-library.button"
              displayAsRow // without this the media button will look like a secondary (grey) button
            />
          )}
          {this.props.isSnapPublisherIngestionEnabled ? (
            <TopsnapEditorRow
              rowDescription={getMessageFromId('topsnap-creation-web-import')}
              handleClick={this.onWebImport}
              icon={link}
              data-test="editor.topsnapEditor.row.webImport"
            />
          ) : null}
        </div>
      </div>
    );
  }

  renderTopsnap() {
    const {
      topsnap,
      showNavigationControls,
      subtitlesSrc,
      // TODO: Uncomment in the next PR.
      // subtitlesPreviewUrl,
      subtitlesPreviewEnabled,
    } = this.props;
    if (!topsnap) {
      return null;
    }
    const assetId = getAssetId(topsnap);
    if (!assetId && topsnap.type !== SnapType.UNKNOWN) {
      return this.renderTopsnapCreation();
    }
    const mediaSrc = this.getMediaSrc(assetId);
    let overlayMediaSrc = null;
    if (topsnap.overlayImageAssetId) {
      overlayMediaSrc = getImagePreviewUrl(topsnap.overlayImageAssetId);
    }
    const subscribeSnap = topsnap ? isSubscribeSnap(topsnap) : false;
    const previewProps = {
      className: style.topsnapPreview,
      type: topsnap.type,
      src: mediaSrc,
      // TODO: Uncomment in the next PR.
      // subtitlesSrc: subtitlesPreviewUrl || subtitlesSrc,
      subtitlesSrc,
      subtitlesPreviewEnabled,
      topsnap,
      snapStatus: this.props.snapStatus && this.props.snapStatus.status,
      showCreateSnapPubButton: true,
      ...(!subscribeSnap ? { overlayMediaSrc } : {}),
    };
    // Adding the key to the subscribe overlay. More info: https://jira.sc-corp.net/browse/PUB-7456
    const overlay =
      subscribeSnap && this.props.topsnap?.overlayImageAssetId ? (
        <SubscribeOverlay key="subscribe-overlay" overlayImageAssetId={this.props.topsnap?.overlayImageAssetId} />
      ) : null;
    return (
      <TopsnapPreview {...previewProps} showNavigationControls={showNavigationControls}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        {overlay}
      </TopsnapPreview>
    );
  }

  renderCropPreview() {
    const { topsnap } = this.props;
    if (topsnap && topsnapHasMedia(topsnap) && this.props.hoverCropPosition) {
      return <CropPreview cropPosition={this.props.hoverCropPosition} />;
    }
    return null;
  }

  render() {
    return (
      <div className={style.root} data-test="richSnapEditor.topsnapEditor.wrapper">
        {this.renderSpinner()}
        <div className={style.topsnapWrapper}>
          <div className={classNames(style.previewWrapper, { [style.dynamicSizing]: this.props.enableDynamicSizing })}>
            {this.renderTopsnap()}
            {this.renderCropPreview()}
          </div>
        </div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(TopsnapEditor);
