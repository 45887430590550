import classNames from 'classnames';
import React from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/expand.svg.inline' or it... Remove this comment to see the full error message
import expandIcon from 'icons/expand.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/spectacles.svg.inline' o... Remove this comment to see the full error message
import spectaclesIcon from 'icons/spectacles.svg.inline';

import style from './SpectaclesPreview.scss';

const RAD_FACTOR = 180 / Math.PI;

type Props = {
  src: string;
};

type State = {
  isRotating: boolean;
  isExpanded: boolean;
  rotationAngle: number;
};

export default class SpectaclesPreview extends React.PureComponent<Props, State> {
  state = {
    isRotating: false,
    isExpanded: false,
    rotationAngle: 0,
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.state.isRotating) {
      this.updateRotationAngle(event.clientX, event.clientY);
    }
  };

  onBeginRotate = (event: Event) => {
    this.setState({
      isRotating: true,
    });
  };

  onEndRotate = (event: Event) => {
    this.setState({
      isRotating: false,
    });
  };

  onExpandVideoClick = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  onCircleRef = (element?: Element | null) => {
    if (element) {
      this.circleRef = element;
    }
  };

  getVideoStyle() {
    return { transform: `translate(-50%,-50%) rotate(${-1 * this.state.rotationAngle}deg)` };
  }

  getIconStyle() {
    return { transform: `rotate(${-1 * this.state.rotationAngle}deg)` };
  }

  getVideoContainerStyle() {
    return { transform: `translate(-50%,-50%) rotate(${this.state.rotationAngle}deg)` };
  }

  getIconContainerStyle() {
    return { transform: `translate(-50%,-100%) rotate(${this.state.rotationAngle}deg)` };
  }

  circleRef: Element | undefined | null = null;

  updateRotationAngle(mousePosX: number, mousePosY: number) {
    if (!this.circleRef) {
      return;
    }

    const rect = this.circleRef.getClientRects()[0];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const center = [rect.left + rect.width / 2, rect.top + rect.height / 2];

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const positionDifference = [mousePosX - center[0], mousePosY - center[1]];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    const angleRadians = Math.atan2(positionDifference[1], positionDifference[0]);
    let rotationAngle = angleRadians * RAD_FACTOR + 90;
    if (rotationAngle > 180 && rotationAngle < 270) {
      rotationAngle -= 360;
    }
    this.setState({ rotationAngle });
  }

  render() {
    return (
      <div
        className={style.root}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: MouseEvent) => void' is not assignab... Remove this comment to see the full error message
        onMouseMove={this.onMouseMove}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: Event) => void' is not assignable to... Remove this comment to see the full error message
        onMouseUp={this.onEndRotate}
      >
        <div className={style.iconContainer} style={this.getIconContainerStyle()}>
          <div
            className={classNames(style.rotateContainer, {
              [style.hidden]: this.state.isExpanded,
              [style.focused]: this.state.isRotating,
            })}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: Event) => void' is not assignable to... Remove this comment to see the full error message
            onMouseDown={this.onBeginRotate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: Event) => void' is not assignable to... Remove this comment to see the full error message
            onMouseUp={this.onEndRotate}
          >
            <InlineSVG
              className={classNames(style.rotateIcon, {
                [style.idle]: !this.state.isRotating,
                [style.focused]: this.state.isRotating,
              })}
              style={this.getIconStyle()}
              src={spectaclesIcon}
              element="span"
            />
          </div>
          <div
            className={classNames(style.expandContainer, {
              [style.focused]: this.state.isExpanded,
              [style.idle]: !this.state.isExpanded,
            })}
            style={this.getIconStyle()}
          >
            <InlineSVG className={style.expandIcon} src={expandIcon} element="span" onClick={this.onExpandVideoClick} />
          </div>
        </div>
        <div
          className={classNames(
            {
              [style.shown]: !this.state.isExpanded,
              [style.focused]: this.state.isRotating,
            },
            style.circle
          )}
          ref={this.onCircleRef}
        >
          <div
            className={classNames(
              {
                [style.expanded]: this.state.isExpanded,
                [style.collapsed]: !this.state.isExpanded,
                [style.rotating]: this.state.isRotating,
              },
              style.videoContainer
            )}
            style={this.getVideoContainerStyle()}
          >
            <video className={style.video} src={this.props.src} style={this.getVideoStyle()} autoPlay loop muted />
          </div>
        </div>
      </div>
    );
  }
}
