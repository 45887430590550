// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import OutcomePage from '@snapchat/web-attachments/lib/polls/markup/components/OutcomePage/OutcomePage';

import EditableExplanationPage from 'views/editor/components/ExplanationPage/editable/EditableExplanationPage';

export default class EditableOutcomePage extends OutcomePage {
  getOutcomeComponent(): {
    new (...args: any): EditableExplanationPage;
  } {
    return EditableExplanationPage;
  }
}
