import url from 'url';

import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isWebUri } from 'valid-url';

import { saveOriginalArticleUrl } from 'state/article/actions/articleActions';
import * as articleSelectors from 'state/article/selectors/articleSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { isOriginalArticleUrlEnabled } from 'state/features/selectors/featuresSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import { getIngestingArticleBySnapId } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getSnapById } from 'state/snaps/selectors/snapsSelectors';

import { LocalStorage } from 'config/constants';
import { State } from 'src/types/rootState';
import { ArticleSnap } from 'src/types/snaps';
import { blurOnEnterKey } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { localStorage } from 'utils/localStorageUtils';
import { ModalType } from 'utils/modalConfig';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import PanelBase from 'views/common/components/PanelBase/PanelBase';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SharedToolbar from 'views/editor/containers/SharedToolbar/SharedToolbar';
import { OneTimeConfirmationModalOptions } from 'views/modals/components/OneTimeConfirmationModal/OneTimeConfirmationModal';

import style from './ArticlePanel.scss';

import { ExtractDispatchProps } from 'types/redux';

export const mapStateToProps = (state: State, props: OwnProps) => {
  const snapId = props.component.snap.id;
  return {
    isReadOnly: editorSelectors.isReadOnly(state),
    isArticleUnsaved: articleSelectors.isUnsaved(state),
    activePublisher: getActivePublisherDetails(state),
    isOriginalArticleUrlEnabled: isOriginalArticleUrlEnabled(state),
    snap: getSnapById(state)(snapId) as ArticleSnap,
    isIngestingUrl: getIngestingArticleBySnapId(state)(snapId),
  };
};

const mapDispatchToProps = {
  ingestUrlForArticleSnap: publisherStoryEditorActions.ingestUrlForArticleSnap,
  saveOriginalArticleUrl,
  showModal: modalsActions.showModal,
};

const URL_ERROR_MIN_LENGTH = 3;

type OwnProps = {
  component: any;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type ArticlePanelProps = OwnProps & StateProps & DispatchProps;

type ArticlePanelState = any;
export class ArticlePanel extends React.Component<ArticlePanelProps, ArticlePanelState> {
  constructor(props: ArticlePanelProps) {
    super(props);
    this.state = {
      urlToIngest: '',
      originalUrl: props.snap?.originalUrl || '',
      isInvalidOriginalUrl: false,
      isInvalidUrl: false,
      showUnsavedChangesModal: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<ArticlePanelProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.snap?.originalUrl !== this.props.snap?.originalUrl) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ originalUrl: this.props.snap?.originalUrl || '' });
    }
  }

  onIngestUrlClick = () => {
    if (isWebUri(this.state.urlToIngest)) {
      this.ingestUrl();
    } else if (this.state.urlToIngest.length >= URL_ERROR_MIN_LENGTH) {
      this.setState({ isInvalidUrl: true });
    }
  };

  onOriginalUrlClick = () => {
    if (
      isWebUri(this.state.originalUrl) ||
      // We should allow the user to remove the original url. Do not keep on saving if the original url is already empty
      (this.state.originalUrl.length === 0 && Boolean(this.props.snap?.originalUrl))
    ) {
      this.props.saveOriginalArticleUrl(this.props.component.snap.id, this.state.originalUrl);
    } else if (this.state.originalUrl.length >= URL_ERROR_MIN_LENGTH) {
      this.setState({ isInvalidOriginalUrl: true });
    }
  };

  onIngestionModalConfirm = (dontShowAgain: any) => {
    localStorage.setItem(LocalStorage.DISMISS_ARTICLE_INGESTION_CONFIRM_MODAL, dontShowAgain);
    if (this.props.isArticleUnsaved) {
      this.showUnsavedChangesModal();
    } else {
      this.triggerIngestUrlAction();
    }
  };

  showUnsavedChangesModal = () => {
    this.setState({ showUnsavedChangesModal: true });
  };

  hideUnsavedChangesModal = () => {
    this.setState({ showUnsavedChangesModal: false });
  };

  triggerIngestUrlAction = () => {
    this.hideUnsavedChangesModal();
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-ingest', {
      publisher: get(this.props.activePublisher, ['name']),
      host: url.parse(this.state.urlToIngest).host,
    });
    if (this.props.activePublisher?.id) {
      this.props
        .ingestUrlForArticleSnap({
          snapId: this.props.component.snap.id,
          url: this.state.urlToIngest,
          publisherId: this.props.activePublisher.id,
        })
        .catch(() => {
          gaUtils.logGAEvent(gaUtils.GAQoSMetrics.GENERAL, 'article-ingestion-error');
        });
    }
  };

  ingestUrl = () => {
    const dismissTermsAndConditionsModal = localStorage.getItem(LocalStorage.DISMISS_ARTICLE_INGESTION_CONFIRM_MODAL);

    const options: OneTimeConfirmationModalOptions = {
      onConfirm: this.onIngestionModalConfirm,
      title: (
        <FormattedMessage
          id="terms-and-conditions-title"
          defaultMessage="Terms & Conditions"
          description="Terms and conditions modal title"
        />
      ),
      body: (
        <FormattedMessage
          id="article-ingestion-terms"
          defaultMessage="All imported material is content that complies with our agreements and guidelines."
          description="Article ingestion terms and conditions"
        />
      ),
      disableCancel: false,
      visible: true,
      isBodyCentered: true,
    };

    if (dismissTermsAndConditionsModal === 'true') {
      this.onIngestionModalConfirm(true);
    } else {
      this.props.showModal(ModalType.ONE_TIME_CONFIRMATION, 'ArticleIngestionT&C', options);
    }
  };

  changeUrlToIngest = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({
      urlToIngest: value,
      isInvalidUrl: false,
    });
  };

  changeOriginalUrl = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({
      originalUrl: value,
      isInvalidOriginalUrl: false,
    });
  };

  renderUnsavedChangesModal() {
    return (
      <SDSDialog visible onCancel={this.hideUnsavedChangesModal} onOk={this.triggerIngestUrlAction}>
        <FormattedMessage
          id="article-unsaved-changes"
          defaultMessage="You have unsaved changes that will be lost. Continue?"
          description="confirmation message that the user will lose unsaved changes if he continues"
        />
      </SDSDialog>
    );
  }

  render() {
    return (
      <PanelBase>
        <div className={style.titleContainer}>
          <h5 className={classNames(style.title, style.importStyle)}>
            <FormattedMessage
              id="import-label"
              defaultMessage="Import"
              description="Import label for the url importing input form"
            />
          </h5>
          <HelpCenterLink
            destination={HelpCenterDestination.ARTICLES}
            data-test="ArticlePanel.helpCenterLink.articles"
          />
        </div>
        <div className={style.ingestUrlContainer}>
          <SDSInput
            labelTitle={
              <FormattedMessage
                id="url-label"
                defaultMessage="Url:"
                description="Url label for the url importing input form"
              />
            }
            value={this.state.urlToIngest}
            onBlur={this.onIngestUrlClick}
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            onKeyDown={blurOnEnterKey}
            onChange={this.changeUrlToIngest}
            errorMessage={this.state.isInvalidUrl ? intlMessages.getMessageFromId('invalid-url-error') : null}
            disabled={this.props.isReadOnly}
            data-test="editor.articlePanel.url.input"
          />
          {this.props.isOriginalArticleUrlEnabled && (
            <SDSInput
              labelTitle={
                <FormattedMessage
                  id="original-url-label"
                  defaultMessage="Original Url:"
                  description="Url label for the url importing input form"
                />
              }
              value={this.state.originalUrl}
              onBlur={this.onOriginalUrlClick}
              /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
              onKeyDown={blurOnEnterKey}
              onChange={this.changeOriginalUrl}
              errorMessage={this.state.isInvalidOriginalUrl ? intlMessages.getMessageFromId('invalid-url-error') : null}
              disabled={this.props.isReadOnly || this.props.isIngestingUrl}
              data-test="editor.articlePanel.originalUrl.input"
            />
          )}
        </div>
        <div>
          <h5 className={style.title}>
            <FormattedMessage
              id="style-label"
              defaultMessage="Style"
              description="Style label for the article style toolbar"
            />
          </h5>
          <SharedToolbar isReadOnly={this.props.isReadOnly} />
          {this.state.showUnsavedChangesModal && this.renderUnsavedChangesModal()}
        </div>
      </PanelBase>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(ArticlePanel);
