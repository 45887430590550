import type {
  ExplanationPageStyles,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/web-attachments/lib/polls/markup/components/ExplanationPage/ExplanationPage';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import TitleWithGridlayout from '@snapchat/web-attachments/lib/polls/markup/components/TitleWithGridLayout/TitleWithGridLayout';
import React from 'react';
import type { ReactNode } from 'react';

import { PollScreen } from 'config/constants';

import LineLimitedContentEditable from 'views/common/components/LineLimitedContentEditable/LineLimitedContentEditable';
import EditableExplanationPage from 'views/editor/components/ExplanationPage/editable/EditableExplanationPage';
import EditableImageOption from 'views/editor/components/PollLayouts/Components/GridLayout/options/EditableImageOption/EditableImageOption';

import style from './EditableTitleWithGridLayout.scss';

import type { ExplanationPageModel } from 'types/polls';

const ScreenToTitleKey = {
  [PollScreen.UNANSWERED]: 'notVotedHeadline',
  [PollScreen.RESPONDED]: 'votedHeadline',
  [PollScreen.ENDED]: 'closedHeadline',
  [PollScreen.EXPLANATION]: 'votedHeadline',
};

const MAX_LINE_COUNT = 2;

export default class EditableTitleWithGridLayout extends TitleWithGridlayout {
  renderExplanationPage(explanationPageStyles: ExplanationPageStyles) {
    // @ts-expect-error
    const { pollEditorState, question } = this.props;
    if (!question || !pollEditorState || pollEditorState.selectedScreen !== PollScreen.EXPLANATION) {
      return null;
    }

    const { explanationPage } = question.editorProperties;

    const explanationPageProps = {
      explanationPropertyChanged: this.explanationPropertyChanged,
      // @ts-expect-error
      createAssetUrl: this.props.createAssetUrl,
      ...explanationPage,
      styles: explanationPageStyles,
      // @ts-expect-error
      ...this.props,
    };

    return (
      // @ts-expect-error ts-migrate(2786) FIXME: 'EditableExplanationPage' cannot be used as a JSX ... Remove this comment to see the full error message
      <EditableExplanationPage {...explanationPageProps} />
    );
  }

  getSelectedScreen() {
    // @ts-expect-error
    const { pollEditorState } = this.props;
    return (pollEditorState && pollEditorState.selectedScreen) || PollScreen.UNANSWERED;
  }

  handleTextChanged = (text: string) => {
    // @ts-expect-error
    const { setEditorProperties } = this.props;
    if (!setEditorProperties) {
      return;
    }

    const titleKey = ScreenToTitleKey[this.getSelectedScreen()];

    setEditorProperties({
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [titleKey]: text,
    });
  };

  renderTitles(headingStyle: {}): ReactNode {
    // @ts-expect-error
    if (!this.props.isPollsTitleEditable) {
      return super.renderTitles(headingStyle);
    }

    const selectedScreen = this.getSelectedScreen();
    const titleKey = ScreenToTitleKey[selectedScreen];
    // @ts-expect-error
    const title = this.getEditorProperty(titleKey, '');
    return (
      // @ts-expect-error
      <div key={titleKey} className={this.getStyle('title')}>
        <div className={style.editableWrapper}>
          <LineLimitedContentEditable
            key={JSON.stringify(headingStyle)}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            className={this.getStyle('titleText')}
            style={headingStyle}
            text={title}
            lineLimit={MAX_LINE_COUNT}
            // @ts-expect-error
            disabled={Boolean(this.props.isReadOnly)}
            onBlur={this.handleTextChanged}
          />
        </div>
      </div>
    );
  }

  getCellComponent() {
    return EditableImageOption;
  }

  explanationPropertyChanged = (changes: ExplanationPageModel) => {
    // @ts-expect-error
    if (!this.props.setEditorProperties) {
      return;
    }

    // @ts-expect-error
    this.props.setEditorProperties({
      explanationPage: changes,
    });
  };
}
