import log from 'loglevel';

import { CrossOrigin, UploadPurpose } from 'config/constants';
import * as gaUtils from 'utils/gaUtils';
import { createAssetUrl } from 'utils/media/assetUtils';
import { ModalType } from 'utils/modalConfig';

export const MetaAttributes = {
  ASPECT_RATIO: 'data-sc-aspect-ratio',
  CROSS_ORIGIN: 'crossOrigin',
};

export default class SCImage {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCImage';
  }

  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get label() {
    return 'Image';
  }

  static define(registry: any, elementCreator: any, showModalAction: any, store: any) {
    registry.registerButton(SCImage, {
      command: {
        allowedContent: `img[!src,alt,crossorigin,${MetaAttributes.ASPECT_RATIO}]`,
        requiredContent: `img[src,alt,crossorigin,${MetaAttributes.ASPECT_RATIO}]`,

        exec(editor: any) {
          showModalAction(ModalType.IMAGE_UPLOAD, SCImage.name, { purpose: UploadPurpose.ARTICLE_IMAGE }).then(
            ({ assetIds }: any) => {
              this._hideModalCallBack(assetIds, editor);
            }
          );
          gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-open-image-modal');
        },

        _hideModalCallBack(assetIds = null, editor: any) {
          if (assetIds !== null) {
            gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-insert-image-modal');
            this._insertImagesIntoEditor(assetIds, editor);
          }
        },

        _insertImagesIntoEditor(assetIds: any, editor: any) {
          if (Array.isArray(assetIds)) {
            assetIds.forEach(assetId => {
              const imageURI = createAssetUrl(assetId);
              elementCreator.createAndInsert(editor, 'img', {
                src: imageURI,
                crossOrigin: CrossOrigin.USE_CREDENTIALS,
              });
            });
          } else {
            log.error(`Unexpected result type '${typeof assetIds}' when trying to insert image. Result:`, assetIds);
          }
        },
      },
    });
  }
}
