// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import PropTypes from 'prop-types';
import React from 'react';
import type { ReactNode } from 'react';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import AddItemButton from './AddItemButton';
import SelectableItem from './SelectableItem';

type PanelEditItem = {
  imageUrl?: string | null;
  description: string | undefined | null;
  placeholderControl?: ReactNode;
};

type OwnProps = {
  items: PanelEditItem[];
  selectedIndex: number;
  isReadOnly?: boolean;
  onItemSelected: (a: number) => void;
  onDeleteItem: (a: number) => void;
  onDuplicateItem?: (a: number) => Promise<unknown>;
  isDuplicating?: boolean;
  onAddItem: () => void;
  onDownloadItem?: (a: number) => Promise<unknown>;
  onDoneEditing?: () => void;
  title: ReactNode;
  addItemButton?: ReactNode;
  emptyDescriptionMessageId: string;
  deleteMessageId: string;
  downloadMessageId?: string;
  enableAdd?: boolean;
  enableDuplicate?: boolean;
  enableDelete?: boolean;
  enableDownload?: boolean;
  enableDoneEditing?: boolean;
  showDownloadButton?: boolean;
};

type State = any;

type Props = OwnProps & typeof ItemSelectPanel.defaultProps;

export default class ItemSelectPanel extends React.Component<Props, State> {
  static propTypes = {
    items: PropTypes.array,
    selectedIndex: PropTypes.number,
    emptyDescriptionMessageId: PropTypes.string,
    deleteMessageId: PropTypes.string,
    enableAdd: PropTypes.bool,
    enableDuplicate: PropTypes.bool,
    enableDelete: PropTypes.bool,
    enableDoneEditing: PropTypes.bool,

    onAddItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onDuplicateItem: PropTypes.func,
    isDuplicating: PropTypes.bool,
    onItemSelected: PropTypes.func,
    onDoneEditing: PropTypes.func,
  };

  static defaultProps = {
    enableAdd: true,
    enableDuplicate: true,
    enableDelete: true,
    enableDoneEditing: true,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  handleOutcomeDoneEditing = () => {
    const { onDoneEditing } = this.props;
    if (onDoneEditing) {
      onDoneEditing();
    }
  };

  handleItemSelected = (index: number) => {
    this.props.onItemSelected(index);
  };

  handleAddItem = () => {
    this.props.onAddItem();
  };

  handleDownloadItem = async (index: number) => {
    if (this.props.onDownloadItem) {
      await this.props.onDownloadItem(index);
    }
  };

  handleDeleteItem = (index: number) => {
    this.props.onDeleteItem(index);
  };

  handleDuplicateItem = (index: number) => {
    if (this.props.onDuplicateItem) {
      this.props.onDuplicateItem(index);
    }
  };

  render() {
    const {
      items,
      selectedIndex,
      isReadOnly,
      title,
      emptyDescriptionMessageId,
      deleteMessageId,
      downloadMessageId,
      enableAdd,
      enableDelete,
      enableDuplicate,
      enableDoneEditing,
      addItemButton,
    } = this.props;

    const itemControls = items.map((item, index) => ({
      key: String(index),
      control: (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <SelectableItem
          {...item}
          index={index}
          enableAdd={enableAdd}
          enableDelete={enableDelete}
          enableDuplicate={enableDuplicate}
          enableDoneEditing={enableDoneEditing}
          isActive={selectedIndex === index}
          emptyDescriptionMessageId={emptyDescriptionMessageId}
          deleteMessageId={deleteMessageId}
          downloadMessageId={downloadMessageId}
          onSelected={this.handleItemSelected}
          onDeleted={this.handleDeleteItem}
          onDuplicated={this.handleDuplicateItem}
          onDownload={this.handleDownloadItem}
          onDoneEditing={this.handleOutcomeDoneEditing}
          isDuplicating={this.props.isDuplicating}
        />
      ),
    }));

    if (!isReadOnly && enableAdd) {
      itemControls.unshift({
        key: 'addButton',
        // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
        control: addItemButton || <AddItemButton handleClick={this.handleAddItem} />,
      });
    }

    return <PropertyPanel title={title} isReadOnly={isReadOnly} fields={itemControls} />;
  }
}
