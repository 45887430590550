import classNames from 'classnames';
import { get } from 'lodash';
import log from 'loglevel';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { asTopSnap } from 'state/snaps/schema/snapEntityHelpers';

import { RichSnapComponentType } from 'config/constants';
import { question, trash } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { ModalType } from 'utils/modalConfig';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { getAttachmentOptionForSnap } from 'views/editor/richEditorConfig';

import style from './SnapEditorStatus.scss';
import SpectaclesEditorStatus from './SpectaclesEditorStatus';

import type { SnapId } from 'types/common';
import type { RichSnapComponent } from 'types/components';
import { isComponentSnap, isComponentTile } from 'types/components';
import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';
import { SnapType } from 'types/snaps';

type OwnProps = {
  showDeleteButton: boolean;
};
type StateProps = {
  activeComponent: RichSnapComponent | undefined | null;
  topsnapId: SnapId | undefined | null;
  editionId: EditionID | undefined | null;
  publisherId: PublisherID | undefined | null;
  isTileLockedForEdit: boolean;
  showDeleteButton: boolean;
};
export const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const snapId = editorSelectors.getActiveWholeSnapId(state);
  const editionId = editorSelectors.getActiveEditionId(state);
  return {
    activeComponent: componentsSelectors.getActiveComponent(state),
    topsnapId: snapId,
    editionId,
    publisherId: publishersSelectors.getActivePublisherId(state),
    isTileLockedForEdit: editionId && snapId ? editorSelectors.isTileLockedForEdit(state)({ snapId, editionId }) : true,
    showDeleteButton: ownProps.showDeleteButton,
  };
};
const mapDispatchToProps = {
  removeBottomSnapAndMakeTopsnapActive: editorActions.removeBottomSnapAndMakeTopsnapActive,
  deleteTileById: editorActions.deleteTileById,
  showModal: modalsActions.showModal,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;
export class SnapEditorStatus extends React.Component<Props> {
  deleteConfirmModalWrapper = (body: ReactNode, onConfirm: () => void) => {
    this.props.showModal(ModalType.DELETE_SNAP_COMPONENT, 'TileSettings', {
      onConfirm,
      body,
    });
  };

  deleteAttachment = () => {
    const component = this.props.activeComponent;
    if (!component) {
      return;
    }
    if (!isComponentSnap(component)) {
      return;
    }
    const { snap } = component;
    const options = getAttachmentOptionForSnap(snap);
    if (!options) {
      return;
    }

    const snapTypeText = options.name;
    const deleteModalText = (
      <FormattedMessage
        defaultMessage="Are you sure you wish to delete this {snapType} attachment?"
        description="Delete Component Alert"
        id="delete-component-alert"
        values={{
          snapType: <FormattedMessage {...snapTypeText} />,
        }}
      />
    );

    this.deleteConfirmModalWrapper(deleteModalText, () => {
      if (component.componentType !== RichSnapComponentType.SNAP || !snap) {
        log.error(`Deletion of ${component.componentType} components is not yet implemented`);
        return;
      }
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'component-delete', {
        componentType: component.componentType,
        snapType: snap.type,
      });
      const { topsnapId } = this.props;
      if (topsnapId) {
        const interactionRouteParams = {
          publisherId: this.props.publisherId,
          editionId: this.props.editionId,
          snapId: topsnapId,
          overwriteHistory: true,
        };
        this.props.removeBottomSnapAndMakeTopsnapActive({
          topsnapId,
          bottomSnapId: snap.id,
          interactionRouteParams,
        });
      }
    });
  };

  deleteTiles = () => {
    this.deleteConfirmModalWrapper(getMessageFromId('confirm-delete-tile'), () => {
      const tileComponent = this.props.activeComponent;
      if (tileComponent && isComponentTile(tileComponent)) {
        this.props.deleteTileById(tileComponent.tile.id);
        gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-delete');
      }
    });
  };

  renderDeleteButton = (onDelete: () => void) => {
    if (!this.props.showDeleteButton) {
      return null;
    }
    return (
      <div className={style.deleteButtonContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={trash}
          onClick={onDelete}
          disabled={this.props.isTileLockedForEdit}
          data-test="snapEditorStatus.deleteButton"
        />
      </div>
    );
  };

  renderStatus = (title: ReactNode, tooltipText?: ReactNode, onDelete?: () => void) => {
    return (
      <div className={classNames(style.root, { [style.centerStatus]: !onDelete })}>
        <div className={style.title} data-test="snapEditorStatus.title">
          {title}
          {tooltipText && (
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            <SDSTooltip placement={TooltipPosition.RIGHT} title={tooltipText} id="question-mark-tooltip">
              <Icon inlineIcon={question} className={(style as any).questionIcon} />
            </SDSTooltip>
          )}
        </div>
        {onDelete && this.renderDeleteButton(onDelete)}
      </div>
    );
  };

  renderEmptyStatus = () => {
    return <div className={style.root} />;
  };

  renderTopOrBottomSnapStatus = (activeComponent: RichSnapComponent) => {
    if (!isComponentSnap(activeComponent)) {
      return null;
    }
    const richSnapType = activeComponent.snap.type;
    let titleId;
    switch (richSnapType) {
      case SnapType.VIDEO:
        if (activeComponent.snap && get(activeComponent.snap, 'circular', false)) {
          const topsnap = asTopSnap(activeComponent.snap);
          return (
            <>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              {topsnap && <SpectaclesEditorStatus className={style.root} snap={topsnap} />}
            </>
          );
        }
        return this.renderEmptyStatus();
      case SnapType.BITMOJI_REMOTE_VIDEO:
        return this.renderEmptyStatus();
      default: {
        const attachmentOptions = getAttachmentOptionForSnap(activeComponent.snap);
        if (!attachmentOptions || !attachmentOptions.title) {
          return this.renderEmptyStatus();
        }
        titleId = attachmentOptions.title;
        break;
      }
    }
    return this.renderStatus(
      titleId && getMessageFromId(titleId),
      getMessageFromId('simple-status-tooltip-attachment'),
      this.deleteAttachment
    );
  };

  render() {
    const { activeComponent } = this.props;
    if (!activeComponent) {
      return this.renderEmptyStatus();
    }
    switch (activeComponent.componentType) {
      case RichSnapComponentType.TILE:
        return this.renderStatus(
          getMessageFromId('tile'),
          getMessageFromId('simple-status-tooltip-tile'),
          this.deleteTiles
        );
      case RichSnapComponentType.TILE_PLACEHOLDER:
      case RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER:
        return this.renderStatus(
          getMessageFromId('simple-status-add-tile'),
          getMessageFromId('simple-status-tooltip-tile')
        );
      case RichSnapComponentType.SNAP:
        return this.renderTopOrBottomSnapStatus(activeComponent);
      case RichSnapComponentType.SNAP_PLACEHOLDER:
        return this.renderStatus(
          getMessageFromId('simple-status-add-attachment'),
          getMessageFromId('simple-status-tooltip-attachment')
        );
      default:
        return this.renderEmptyStatus();
    }
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapEditorStatus);
