/* global CKEDITOR */
import _ from 'lodash';
import log from 'loglevel';

import * as regexUtils from 'utils/regexUtils';

// ****************************************************************************
// TODO (piers)
// ------------
// This is a really bad implementation: it depends on an alert(); it doesn't
// actually block the user from inserting the content; it doesn't have unit
// tests...yes, it's really bad. I will resolve these issues first thing Monday
// morning, but for now the most important thing is that publishers don't
// accidentally paste videos from a different Brightcove account and end up
// having them not play in the app. So the lesser of two evils :)
// ****************************************************************************

export default class SCCrossPublisherVideoPastingFilter {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCCrossPublisherVideoPastingFilter';
  }

  static define(registry: any, loadAssetInfo: any) {
    registry.registerPlugin(SCCrossPublisherVideoPastingFilter, {
      init(editor: any) {
        editor.on(
          'paste',
          (event: any) => {
            const dataTransfer = _.get(event, ['data', 'dataTransfer']);
            const html = dataTransfer && dataTransfer.getData('text/html');

            if (html) {
              const videoAssetIds = regexUtils
                .getAllMatches(html, /rel="([0-9]+)"/g)
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                .map(match => parseInt(match[1], 10));

              const showWarning = false;

              const promises = videoAssetIds.map(assetId =>
                loadAssetInfo(assetId)
                  .then((result: any) => {
                    // TODO: Handle copying article from one pub to another
                  })
                  .catch((err: any) => logUnrecognizedAssetWarning(assetId, err))
              );

              Promise.all(promises).then(() => {
                if (showWarning) {
                  const alertString =
                    'Warning: It looks like you have pasted a video belonging to a different publisher. This video will NOT play in the Snapchat app. Please re-insert the video before publishing.';
                  alert(alertString); // eslint-disable-line no-alert
                }
              });
            }
          },
          null,
          null,
          0
        );
      },
    });

    function logUnrecognizedAssetWarning(assetId: any, err: any) {
      log.warn(`Unrecognized asset id in pasted HTML: ${assetId}`, err);
    }
  }
}
