import classNames from 'classnames';
import React from 'react';

import StoryStateIcon, { StoryIcon } from 'views/common/components/StoryStateIcon/StoryStateIcon';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './HomepageButton.scss';

export const HomepageButtonTypes = {
  ANALYTICS: 'ANALYTICS',
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
};
export type HomepageButtonTypesEnum = typeof HomepageButtonTypes[keyof typeof HomepageButtonTypes];
type Props = {
  onClick: (a: Event) => void;
  disabled?: boolean;
  type: HomepageButtonTypesEnum;
};
type State = any;
export class HomepageButton extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  render() {
    let img = null;
    if (this.props.type === HomepageButtonTypes.ANALYTICS) {
      img = <StoryStateIcon data-test="homepage.homepageButton.analytics" hasAction icon={StoryIcon.ANALYTICS} />;
    } else if (this.props.type === HomepageButtonTypes.AVAILABLE) {
      img = <StoryStateIcon data-test="homepage.homepageButton.available" hasAction icon={StoryIcon.AVAILABLE} />;
    } else if (this.props.type === HomepageButtonTypes.UNAVAILABLE) {
      img = <StoryStateIcon data-test="homepage.homepageButton.unavailable" hasAction icon={StoryIcon.UNAVAILABLE} />;
    }
    const classes = classNames({
      [style.parent]: true,
      [style.disabled]: this.props.disabled,
      [(style as any).analytics]: this.props.type === HomepageButtonTypes.ANALYTICS,
      [(style as any).available]: this.props.type === HomepageButtonTypes.AVAILABLE,
      [(style as any).unavailable]: this.props.type === HomepageButtonTypes.UNAVAILABLE,
    });
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '((a: Event) => void) | null' is not assignab... Remove this comment to see the full error message
      <div className={classes} onClick={this.props.disabled ? null : this.props.onClick} data-test="homepageButton">
        {img}
      </div>
    );
  }
}
export default HomepageButton;
