/* global CKEDITOR */
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'scri... Remove this comment to see the full error message
import $script from 'scriptjs';

import './CKEditorStyleOverrides.scss';
import { CKEDITOR_BASEPATH, CKEDITOR_ASYNC_SCRIPT_URL } from './CKEditorConfigurator';

// CKEditor itself is around 500kb of JavaScript, which is only required if the user actually
// navigates to the article editor. This class takes care of loading ckeditor.js at runtime,
// hiding the complexity of the asynchronous load from the calling class.
//
// In order to make the calling code simpler, some methods can be called before CKEditor has
// loaded - see the Proxied Methods section. After the async load is complete, all CKEditor
// methods and properties are proxied directly onto this class so that the calling class
// can just act as though it's talking directly to the editor.
export default class AsyncCKEditorLoader {
  _configurator: any;

  _domNode: any;

  _instance: any;

  _queuedMethodCalls: any;

  _scriptLoader: any;

  constructor(domNode: any, configurator: any, scriptLoader = $script) {
    this._domNode = domNode;
    this._configurator = configurator;
    this._scriptLoader = scriptLoader;
    this._queuedMethodCalls = [];

    this._loadScript();
  }

  _loadScript() {
    // Must be set _before_ CKEditor is loaded
    // See http://docs.cksource.com/CKEditor_3.x/Developers_Guide/Specifying_the_Editor_Path
    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['CKEDITOR_BASEPATH'] = CKEDITOR_BASEPATH; // eslint-disable-line dot-notation

    this._scriptLoader(CKEDITOR_ASYNC_SCRIPT_URL, () => {
      this._configurator.applyGlobalConfig();
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
      this._instance = CKEDITOR.inline(this._domNode, this._configurator.getInstantiationConfig());
      this._instance.on('instanceReady', (event: any) => {
        this._proxyAllInstanceMethodsAndProperties();
        this._configurator.applyPostInstantiationConfig(event.editor);
      });

      this._applyQueuedMethodCalls();
    });
  }

  _queueCallIfNotReady(methodName: any, args?: any) {
    if (this._instance) {
      // eslint-disable-next-line prefer-spread
      this._instance[methodName].apply(this._instance, args);
    } else {
      this._queuedMethodCalls.push({ methodName, args });
    }
  }

  _applyQueuedMethodCalls() {
    this._queuedMethodCalls.forEach((callInfo: any) => {
      // eslint-disable-next-line prefer-spread
      this._instance[callInfo.methodName].apply(this._instance, callInfo.args);
    });
  }

  // Once the instance has loaded, we proxy all of its methods and properties
  // directly onto this class so that the interface is identical.
  _proxyAllInstanceMethodsAndProperties() {
    let i;

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (i in this._instance) {
      (key => {
        const value = this._instance[key];

        if (typeof value === 'function') {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          this[key] = value;
        } else {
          Object.defineProperty(this, key, {
            get: () => this._instance[key],
            set: newValue => {
              this._instance[key] = newValue;
            },
          });
        }
      })(i);
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Early callable methods - each of the following can safely be called even
  // before CKEditor has loaded. Where relevant, the method call will be queued
  // and performed after the editor instance has been created.

  parseHtml(html: any) {
    return new Promise(resolve => {
      // @ts-ignore ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
      if (typeof CKEDITOR !== 'undefined') {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
        resolve(CKEDITOR.htmlParser.fragment.fromHtml(html));
      } else {
        this.on('instanceReady', () => {
          // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
          resolve(CKEDITOR.htmlParser.fragment.fromHtml(html));
        });
      }
    });
  }

  getData() {
    return this._instance ? this._instance.getData() : '';
  }

  setData(...args: any[]) {
    this._queueCallIfNotReady('setData', args);
  }

  on(...args: any[]) {
    this._queueCallIfNotReady('on', args);
  }

  destroy() {
    this._queueCallIfNotReady('destroy');
  }

  // ...add others as necessary.
  // -------------------------------------------------------------------------------------------------------------------
}
