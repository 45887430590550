// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Radio } from '@snapchat/snapnet'; // discover-cms/no-snapnet
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import ImageOption from '@snapchat/web-attachments/lib/polls/markup/components/ImageOption/ImageOption';
import classNames from 'classnames';
import invariant from 'invariant';
import * as React from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ContentEditable from 'react-contenteditable';
import { defineMessages, intlShape, FormattedMessage } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'stri... Remove this comment to see the full error message
import striptags from 'striptags';

import { UploadPurpose, DropzoneType, PollScreen } from 'config/constants';
import { assertState } from 'utils/assertionUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSDropdown, { DropdownSize } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import ImageMediaUploader from 'views/editor/containers/ImageMediaUploader/ImageMediaUploader';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './EditableImageOption.scss';

import { assertAssetId } from 'types/assets';
import type { AssetID, Option } from 'types/polls';

export const formattedMessages = defineMessages({
  descriptionPlaceholderText: {
    id: 'editable-image-option-description-placeholder',
    description: 'Placeholder text for the optional choice description',
    defaultMessage: 'Type an option',
  },
});

type OwnProps = {};

type OwnState = {};
export default class EditableImageOption extends ImageOption<OwnProps, OwnState> {
  static contextTypes = {
    intl: intlShape,
  };

  shouldComponentUpdate(nextProps: OwnProps, nextState: OwnState) {
    // @ts-expect-error
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  handleOnUploadComplete = ({ assetId }: { assetId: AssetID }) => {
    assertAssetId(assetId);
    this.dispatchOptionUpdate({ imageAssetId: assetId });
  };

  handleDescriptionChanged = (description?: string | null) => {
    if (typeof description !== 'string') {
      return;
    }
    this.dispatchOptionUpdate({ description });
  };

  handleOutcomeChange = (outcomeIndex: number) => {
    this.dispatchOptionUpdate({ outcomeIndex });
  };

  dispatchOptionUpdate(data: Partial<Option>) {
    // @ts-expect-error
    const { isReadOnly } = this.props;
    if (isReadOnly) {
      return;
    }
    // @ts-expect-error
    const { updateOption, index } = this.props;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(updateOption).is.function();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(index).is.number();
    if (!updateOption) {
      return;
    }
    updateOption(index, data);
  }

  renderBorder(): React.ReactElement<any> | undefined | null {
    return null;
  }

  handleContentChange = (event: { target: Node }) => {
    this.handleDescriptionChanged(striptags(event.target.textContent));
  };

  renderDescription(description?: string | null): React.ReactElement<any> | undefined | null {
    // @ts-expect-error
    if (!this.props.shouldShowLabels || !this.props.option.imageAssetId) {
      return null;
    }

    // @ts-expect-error
    const placeholderMessage = this.context.intl.formatMessage(formattedMessages.descriptionPlaceholderText);
    // @ts-expect-error
    const classes = classNames(style.optional, this.getStyle('labelBar'));
    return (
      <ContentEditable
        // @ts-expect-error
        style={this.props.descriptionStyle}
        className={classes}
        html={description}
        // @ts-expect-error
        disabled={this.props.isReadOnly}
        placeholder={placeholderMessage}
        onBlur={this.handleContentChange}
        data-test="labelDescription"
      />
    );
  }

  handleCorrectAnswerChanged = (index: number) => () => {
    // @ts-expect-error
    if (this.props.isReadOnly) {
      return;
    }

    // @ts-expect-error
    invariant(this.props.setEditorProperties, 'This can be called only from Poll editor.');
    // @ts-expect-error
    this.props.setEditorProperties({ correctAnswer: index });
  };

  renderOutcomesControl() {
    // @ts-expect-error
    const { outcomes, option, isReadOnly } = this.props;
    if (!option.imageAssetId || !outcomes || outcomes.options.length === 0) {
      return null;
    }
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'outcome' implicitly has an 'any' type.
    const descriptions = outcomes.options.map((outcome, index) => ({
      value: index,
      label: (
        <div className={style.menuItem} data-test="assignOutCome.outcomeOptions">
          {outcome.description || getMessageFromId('outcome-default-text', { index: index + 1 })}
        </div>
      ),
    }));
    const selectedValue = typeof option.outcomeIndex === 'number' ? option.outcomeIndex : null;
    const emptyLabel = (
      <div className={style.emptyLabel}>
        <FormattedMessage
          id="polls-outcomes-assign-outcome"
          description="Assign Outcome message"
          defaultMessage="Assign Outcome"
        />
      </div>
    );
    return (
      <div key="outcomes-selector" className={style.outcomeSelector}>
        <SDSDropdown
          disableClear
          disabled={isReadOnly}
          placeholder={emptyLabel}
          onChange={this.handleOutcomeChange}
          data-test="EditImage.AssignOutcome"
          value={selectedValue}
          size={DropdownSize.SMALL}
        >
          {createSDSDropdownOptions(descriptions)}
        </SDSDropdown>
      </div>
    );
  }

  renderRadioButton() {
    // @ts-expect-error
    const { correctAnswer, index, option, pollEditorState } = this.props;
    // Do not render radio button selector for other than unanswered screen
    if (pollEditorState && pollEditorState.selectedScreen && pollEditorState.selectedScreen !== PollScreen.UNANSWERED) {
      return null;
    }
    if (typeof correctAnswer === 'undefined' || !option.imageAssetId) {
      return null;
    }
    const isCorrectAnswer = correctAnswer === index;
    const radioClasses = classNames(style.correctAnswer, isCorrectAnswer && (style as any).isChecked);
    return (
      <Radio
        className={radioClasses}
        key="radio"
        checked={isCorrectAnswer}
        // @ts-expect-error
        disabled={this.props.isReadOnly}
        onChange={this.handleCorrectAnswerChanged(index)}
        data-test="editor.PollLayouts.GridLayout.EditableImageOption.radioButtons"
      >
        <FormattedMessage
          id="quiz-correct-answer"
          description="Displays a text indicating, if the quiz option is correct answer"
          defaultMessage="Correct"
        />
      </Radio>
    );
  }

  renderImageUploader() {
    // @ts-expect-error
    const { option } = this.props;
    const isReplace = Boolean(option.imageAssetId);
    return (
      <ImageMediaUploader
        className={(style as any).optionDropzone}
        key="imageMediaUploader"
        // @ts-expect-error
        {...this.props}
        isReplace={isReplace}
        purpose={UploadPurpose.POLL_IMAGE}
        dropzoneType={DropzoneType.POLL_IMAGE}
        // @ts-expect-error
        customValidationOptions={this.props.customValidationOptions}
        onUploadComplete={this.handleOnUploadComplete}
        data-test="imageUploader"
      />
    );
  }

  renderImageOverlayItem(
    description?: string | null
  ): React.ReactElement<any> | undefined | null | Array<React.ReactElement<any> | undefined | null> {
    return [this.renderOutcomesControl(), this.renderRadioButton(), this.renderImageUploader()];
  }
}
