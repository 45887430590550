import moment from 'moment';
import * as React from 'react';

import { CrossOrigin } from 'config/constants';

import style from './TimelineThumbnail.scss';

type OwnProps = {
  image: HTMLImageElement | undefined;
  time: number;
  xPosition: number;
};

function TimelineThumbnail(props: OwnProps) {
  const { time, xPosition } = props;

  return (
    <div className={style.root} style={{ left: xPosition }}>
      <div className={style.card}>
        <div className={style.imageContainer} data-test="TimelineThumbnail.image">
          {props.image && (
            <img
              src={props.image.src}
              crossOrigin={CrossOrigin.USE_CREDENTIALS}
              width="64"
              height="113"
              alt=""
              data-test="TimelineThumbnail.img"
            />
          )}
        </div>
        <div className={style.timeContainer} data-test="TimelineThumbnail.time">
          {moment().startOf('day').milliseconds(time).format('mm:ss')}
        </div>
      </div>
      <div className={style.arrow} />
    </div>
  );
}

export default TimelineThumbnail;
