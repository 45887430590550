import moment from 'moment';
import React from 'react';
import type { ReactElement } from 'react';

import { getStoryModerationMessage } from 'state/moderation/selectors/moderationSelectors';
import {
  getActiveEdition,
  shouldUseSingleAssetEditor,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { warningTriangle } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import StoryStateIcon from 'views/common/components/StoryStateIcon/StoryStateIcon';
import StoryBuilderName from 'views/publisherStoryEditor/containers/StoryBuilderName/StoryBuilderName';
import StoryState from 'views/publisherStoryEditor/containers/StoryState/StoryState';

import style from './StoryStateOverview.scss';

import { StoryState as StoryStateEnum } from 'types/editions';
import type { Edition } from 'types/editions';

type OwnProps = {
  storyState: StoryStateEnum;
  isStoryPromoted?: boolean;
};

type StateProps = {
  activeStory: Edition | undefined | null;
  isSingleAssetStory: boolean | undefined | null;
  getStoryModerationMessage: ReactElement<any> | undefined | null;
};

type Props = StateProps & OwnProps;

const mapStateToProps = (state: State): StateProps => {
  const activeStory = getActiveEdition(state);

  return {
    activeStory,
    isSingleAssetStory: activeStory && shouldUseSingleAssetEditor(state)(activeStory.id),
    getStoryModerationMessage: activeStory && getStoryModerationMessage(state)(activeStory.id),
  };
};

export class StoryStateOverview extends React.Component<Props> {
  renderUpdatedAndModerationInfo = () => {
    const lastUpdated = this.props.activeStory && moment(this.props.activeStory.lastUpdatedAt);
    const moderationMessage = this.props.getStoryModerationMessage;

    return (
      <div className={style.publishingInfo} data-test="StoryStateOverview.publishingInfo">
        {lastUpdated && (
          <div data-test="StoryStateOverview.updatedMessage">
            {getMessageFromId('analytics-updated-story-title', { timeUpdated: lastUpdated.fromNow() })}
          </div>
        )}
        {moderationMessage && (
          <div className={style.moderationInfo} data-test="StoryStateOverview.moderationMessage">
            <Icon inlineIcon={warningTriangle} />
            <div>{moderationMessage}</div>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className={style.root}>
        <StoryBuilderName isStoryPromoted={this.props.isStoryPromoted} />
        <div className={style.liveStoryInfo}>
          <StoryStateIcon storyState={this.props.storyState} />
          <StoryState />
        </div>
        {this.props.isSingleAssetStory && this.renderUpdatedAndModerationInfo()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(StoryStateOverview);
