import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SDSDropdown, { DropdownType, DropdownSize } from '../SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from '../SDSDropdownOptions/SDSDropdownOptions';

import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

export const OptionTypes = enumObject({
  YES_NO: [
    {
      value: true,
      label: <FormattedMessage id="drop-down-yes" description="Drop down message Yes." defaultMessage="Yes" />,
    },
    {
      value: false,
      label: <FormattedMessage id="drop-down-no" description="Drop down message No." defaultMessage="No" />,
    },
  ],
});

type OptionType = Enum<typeof OptionTypes>;

type OwnProps = {
  onChange: (a: boolean) => void;
  selectedValue?: boolean;
  defaultValue: boolean;
  optionType: OptionType;
  'data-test': string;
};

type State = any;

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof YesNoDropDown.defaultProps;

export default class YesNoDropDown extends React.Component<Props, State> {
  // @ts-expect-error ts-migrate(2502) FIXME: 'defaultProps' is referenced directly or indirectl... Remove this comment to see the full error message
  static defaultProps: Props = {
    onChange: _.noop,
    defaultValue: false,
    optionType: OptionTypes.YES_NO,
    'data-test': 'YesNoDropDown',
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  render() {
    const { selectedValue, onChange, defaultValue, optionType } = this.props;
    return (
      <SDSDropdown
        disableClear
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={onChange}
        data-test={`${this.props['data-test']}-YesNo`}
        size={DropdownSize.MEDIUM}
        type={DropdownType.GREY}
      >
        {createSDSDropdownOptions(optionType)}
      </SDSDropdown>
    );
  }
}
