import type { SnapId } from 'types/common';
import type { TopSnap } from 'types/snaps';

export type DomElement = null | Element | Text;

export type SnapNode = {
  id: SnapId;
  key: string;
  index: number;
  snap: TopSnap;
};

export type SegmentNode = {
  id: string | number;
  key: string;
  isVirtualSegment: boolean;
  index: number;
  snapNodes: SnapNode[];
};

// Used internally for calculating Drag indexes, exported to be used in unit tests
export class DragIndex {
  _segmentIndex: number;

  _snapIndex: number | undefined | null;

  static fromArray(indexArray: number[]) {
    if (indexArray.length === 1) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return new DragIndex(indexArray[0]);
    }
    if (indexArray.length === 2) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      return new DragIndex(indexArray[0], indexArray[1]);
    }
    throw new Error('Expected indexArray to have one or two elements');
  }

  constructor(segmentIndex: number, snapIndex: number | undefined | null = null) {
    this._segmentIndex = segmentIndex;
    this._snapIndex = snapIndex;
  }

  isSegmentIndex(): boolean {
    return this._snapIndex === null;
  }

  isSnapIndex(): boolean {
    return this._snapIndex !== null;
  }

  getSegmentIndex(): number {
    return this._segmentIndex;
  }

  getSnapIndex(): number | undefined | null {
    return this._snapIndex;
  }

  setSegmentIndex(segmentIndex: number): void {
    this._segmentIndex = segmentIndex;
  }

  setSnapIndex(snapIndex?: number | null): void {
    this._snapIndex = snapIndex;
  }

  isEqual(otherIndex: DragIndex): boolean {
    return this._segmentIndex === otherIndex._segmentIndex && this._snapIndex === otherIndex._snapIndex;
  }

  isSameSegment(segmentIndex: number): boolean {
    return this._segmentIndex === segmentIndex;
  }

  toArray(): number[] {
    const snapIndex: number | undefined | null = this.getSnapIndex();
    if (typeof snapIndex === 'number') {
      return [this.getSegmentIndex(), snapIndex];
    }

    return [this.getSegmentIndex()];
  }

  clone(): DragIndex {
    return new DragIndex(this._segmentIndex, this._snapIndex);
  }
}

export type SourceItem = {
  segment?: SegmentNode;
  snap?: SnapNode;
  index: DragIndex;
  lastClientXOffset: number | undefined | null;
};
