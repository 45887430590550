import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { CrossOrigin } from 'config/constants';
import * as grapheneUtils from 'utils/grapheneUtils';
import * as videoUtils from 'utils/media/videoUtils';

import { Spinner, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import TopsnapNavigation from 'views/common/components/TopsnapNavigation/TopsnapNavigation';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/play.svg.inline' or its... Remove this comment to see the full error message
import playSvg from 'images/play.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/play.svg.inline' or its... Remove this comment to see the full error message
import arrowRightSvg from 'images/right_arrow.svg.inline';

import MediaError from './MediaError';
import PublisherEditionMetadata from './PublisherEditionMetadata';
import style from './TopsnapPreview.scss';

import { SnapProblem } from 'types/build';
import { SnapType } from 'types/snaps';

type OwnProps = {
  type: any; // TODO: PropTypes.oneOf([SnapType.IMAGE, SnapType.VIDEO, SnapType.UNKNOWN])
  snapStatus?: string;
  className?: string;
  interactive?: boolean;
  src?: string;
  subtitlesSrc?: string;
  subtitlesPreviewEnabled?: boolean;
  topsnap?: any;
  overlayMediaSrc?: string;
  showNavigationControls?: boolean;
  showCreateSnapPubButton?: boolean;
};
type State = any;
type Props = OwnProps & typeof TopsnapPreview.defaultProps;
export class TopsnapPreview extends React.PureComponent<Props, State> {
  static defaultProps = {
    children: null,
    interactive: true,
    topsnap: null,
    showCreateSnapPubButton: false,
  };

  state = {
    videoLoaded: false,
    videoPlaying: false,
  };

  stopwatch = grapheneUtils.createTimer();

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.src !== nextProps.src) {
      this.setState({ videoLoaded: false });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (!prevState.videoLoaded && this.state.videoLoaded) {
      this.stopwatch('snap_load_time');
    }
  }

  onMouseOver = () => {
    this.startVideo();
  };

  onMouseLeave = () => {
    this.stopVideo();
  };

  startVideo = () => {
    if (this.state && this.state.videoLoaded) {
      const videoDOMobject = this.refs.video;
      if (videoDOMobject && this.props.src) {
        // Google may not allow autoplaying, the promise will throw if that is the case
        // We have a fallback, we allow the user to start playing if they click the start button
        // Wrapping in Promise.resolve as some browsers don't return a promise
        Promise.resolve((videoDOMobject as any).play())
          .then(() => {
            this.setState({ videoPlaying: true });
          })
          // We swallow the error because we have a fallback (letting the user click the play button)
          .catch(() => {});
      }
    }
  };

  stopVideo = () => {
    if (this.state && this.state.videoLoaded) {
      const videoDOMobject = this.refs.video;
      if (videoDOMobject && this.props.src) {
        (videoDOMobject as any).pause();
      }
    }
    this.setState({ videoPlaying: false });
  };

  videoLoaded = () => {
    this.setState({
      videoLoaded: true,
    });
  };

  // converting to title case: https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
  getCTA(): string | undefined {
    const { topsnap, interactive } = this.props;
    const { videoLoaded } = this.state;
    if (videoLoaded && interactive && topsnap?.callToAction) {
      return topsnap.callToAction
        ?.replace('_', ' ')
        ?.replace(/\w\S*/g, (word: string) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
    }
    return undefined;
  }

  renderSpinnerOrPlay() {
    if (this.state && this.state.videoLoaded && this.props.interactive) {
      return (
        <span className={style.playButton} onClick={this.startVideo}>
          <InlineSVG src={playSvg} raw />
        </span>
      );
    }
    if (this.state && this.state.videoLoaded && !this.props.interactive) {
      return null;
    }
    return this.renderSpinner();
  }

  renderSpinner() {
    if ((this.props.snapStatus === SnapProblem.BUILDING && !this.props.src) || this.props.type === SnapType.UNKNOWN) {
      const videoDescription = (
        <FormattedMessage
          id="topsnap-preview-spinner-video-description"
          description="Description for video snaps that are being processed"
          defaultMessage="Your video will be available soon - you can continue to edit metadata or other Snaps while you wait"
        />
      );
      const imageDescription = (
        <FormattedMessage
          id="topsnap-preview-spinner-image-description"
          description="Description for image snaps that are being processed"
          defaultMessage="Your image will be available soon - you can continue to edit metadata or other Snaps while you wait"
        />
      );
      return (
        <div className={style.spinnerContainer}>
          <div className={style.spinnerTitle}>
            <FormattedMessage
              id="topsnap-preview-spinner-processing-snap"
              description="Message to display that a snap is being processed"
              defaultMessage="Processing Snap"
            />
          </div>
          <Spinner loading size={SpinnerSizes.LARGE} />
          <div className={style.spinnerSubtitle}>
            {this.props.type === SnapType.IMAGE ? imageDescription : videoDescription}
          </div>
        </div>
      );
    }
    return (
      <div className={style.spinnerContainer}>
        <Spinner loading size={SpinnerSizes.LARGE} />
      </div>
    );
  }

  renderSpinnerOrError() {
    if (this.props.snapStatus === SnapProblem.BUILD_ERROR && this.props.type === SnapType.UNKNOWN) {
      return <MediaError data-test="topSnapPreview.mediaError" />;
    }
    return this.renderSpinner();
  }

  renderOptionalOverlay() {
    if (!this.props.overlayMediaSrc && !this.props.children) {
      return null;
    }
    let content;
    if (this.props.children) {
      content = this.props.children;
    } else {
      content = (
        <img
          alt="video overlay"
          className={style.videoOverlay}
          src={this.props.overlayMediaSrc}
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          data-test="richSnapEditor.topsnapEditor.overlayImage"
        />
      );
    }
    return content;
  }

  renderNavigationControls() {
    if (!this.props.showNavigationControls) {
      return null;
    }
    return <TopsnapNavigation />;
  }

  renderMedia() {
    const renderableTypes = [SnapType.IMAGE, SnapType.VIDEO, SnapType.UNKNOWN];
    if (renderableTypes.indexOf(this.props.type) === -1) {
      return null;
    }
    if (this.props.src) {
      if (this.props.type === SnapType.IMAGE) {
        const imageContainerProps = {
          className: style.topsnapContainer,
        };
        return (
          <div {...imageContainerProps}>
            <div className={style.overlayHitBoxHelper} />
            {this.renderOptionalOverlay()}
            {this.renderNavigationControls()}
            <img
              className={style.image}
              src={this.props.src}
              alt=""
              crossOrigin={CrossOrigin.USE_CREDENTIALS}
              data-test="richSnapEditor.topsnapEditor.topsnapImage"
            />
          </div>
        );
      }
      if (this.props.type === SnapType.VIDEO) {
        const { interactive } = this.props;
        /* eslint-disable no-void */
        const videoContainerProps = {
          className: style.topsnapContainer,
          onMouseOver: interactive ? this.onMouseOver : void 0,
          onMouseLeave: interactive ? this.onMouseLeave : void 0,
        };
        /* eslint-enable no-void */
        const previewOverlayClasses = classNames({
          [style.previewOverlay]: true,
          [style.playing]: this.state.videoPlaying,
        });
        const spectaclesTopsnap = get(this.props.topsnap, 'circular', false);
        const haveSpectaclesVideoDimensions = spectaclesTopsnap && this.refs.video;
        const videoElementClasses = classNames(style.image, {
          [style.circularSpectaclesTopsnap]:
            haveSpectaclesVideoDimensions && videoUtils.isCircularVideo(this.refs.video),
          [style.rectangularSpectaclesTopsnap]:
            haveSpectaclesVideoDimensions && !videoUtils.isCircularVideo(this.refs.video),
          [style.hidden]: spectaclesTopsnap && !haveSpectaclesVideoDimensions,
        });
        const subtitlesSrc = this.props.subtitlesPreviewEnabled ? this.props.subtitlesSrc : '';
        return (
          <div {...videoContainerProps}>
            <div className={previewOverlayClasses}>{this.renderSpinnerOrPlay()}</div>
            {this.renderOptionalOverlay()}
            {this.renderNavigationControls()}
            <video
              key={this.props.src} // https://stackoverflow.com/a/47382850
              className={videoElementClasses}
              ref="video"
              crossOrigin={CrossOrigin.USE_CREDENTIALS}
              loop
              onLoadedData={this.videoLoaded}
              data-test="richSnapEditor.topsnapEditor.topsnapVideo"
            >
              <source src={this.props.src} />
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={subtitlesSrc}
                default
                ref="track"
                data-test="richSnapEditor.topsnapEditor.topsnapVideo.subTitle"
              />
            </video>
          </div>
        );
      }
    }
    return (
      <div className={style.topsnapContainer}>
        {this.renderOptionalOverlay()}
        {this.renderNavigationControls()}
        {this.renderSpinnerOrError()}
      </div>
    );
  }

  render() {
    const rootClass = classNames(style.root, this.props.className);
    return (
      <div className={rootClass}>
        {this.renderMedia()}
        {Boolean(this.props.src) && <PublisherEditionMetadata overrideCTA={this.getCTA()} />}
      </div>
    );
  }
}
export default TopsnapPreview;
