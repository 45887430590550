import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import ContentStatusPanel from '../ContentStatusPanel/ContentStatusPanel';

import { ContentStatus } from 'src/config/constants';
import { intlConnect } from 'utils/connectUtils';

import style from './CameosEditor.scss';

import type { State as RootState } from 'types/rootState';
import type { Snap } from 'types/snaps';

type Props = {
  snapId: number;
  topsnap: Snap;
  isReadOnly: boolean;
  isSaving: boolean;
  contentStatus: ContentStatus;
};

export const mapStateToProps = (state: RootState) => {
  const topsnap = editorSelectors.getActiveTopsnap(state);

  return {
    snapId: topsnap?.id,
    isReadOnly: editorSelectors.isReadOnly(state),
    isSaving: editorSelectors.isSaving(state),
    contentStatus: stagesSelectors.getContentStatus(state)(topsnap?.id),
    topsnap,
  };
};

const isMp4Url = (src?: string | null): boolean => {
  return /^https:\/\/.*\.mp4\?/.test(src || '');
};

const calculateDisplayState = (
  url: string | undefined | null,
  cameoId: number | undefined | null | string | undefined | null
): string => {
  if (!cameoId) {
    return 'start';
  }
  if (isMp4Url(url)) {
    return 'video';
  }
  return 'error';
};

const renderVideo = (mp4Url: any) => {
  return (
    <video
      data-test="CameosEditor.video"
      draggable="false"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; "data-test": string; dr... Remove this comment to see the full error message
      alt="Cameo Tile"
      preload="auto"
      controls
      key={mp4Url}
    >
      <source src={mp4Url} type="video/mp4" />
    </video>
  );
};

const renderStart = () => {
  return (
    <div className={style.content} data-test="CameosEditor.zeroState">
      <FormattedMessage
        id="cameo-editor-zero-state-message"
        description="Prompt to enter cameo id and revisions"
        defaultMessage="Enter a cameoId and revision"
      />
    </div>
  );
};

function noop() {}

const renderError = () => {
  return (
    <div className={classNames(style.content, style.error)} data-test="CameosEditor.errorState">
      <FormattedMessage
        id="cameo-editor-error-state-message"
        description="Message saying the data is bad"
        defaultMessage="Bad Cameo Data"
      />
    </div>
  );
};

export const CameosEditor = ({ snapId, isReadOnly, isSaving, contentStatus, topsnap }: Props) => {
  const [videoUrl, setVideoUrl] = useState(topsnap.cameoSnapModel?.previewUrl);
  const ds = calculateDisplayState(topsnap?.cameoSnapModel?.previewUrl, topsnap?.cameoSnapModel?.cameoId);
  const [displayState, setDisplayState] = useState(ds);

  useEffect(() => {
    setVideoUrl(topsnap?.cameoSnapModel?.previewUrl);
    setDisplayState(calculateDisplayState(topsnap?.cameoSnapModel?.previewUrl, topsnap?.cameoSnapModel?.cameoId));
  }, [topsnap]);

  return (
    <div className={style.editor}>
      <ContentStatusPanel status={contentStatus} isReadOnly={isReadOnly} onSave={noop} />
      <div className={style.previewWrapper}>
        <div className={style.body}>
          <div className={style.header} data-test="CameosEditor.header">
            <FormattedMessage
              id="cameo-preview-header"
              description="Cameo Preview header"
              defaultMessage="Cameos Preview"
            />
          </div>
          {displayState === 'start' && renderStart()}
          {displayState === 'video' && renderVideo(videoUrl)}
          {displayState === 'error' && renderError()}
        </div>
      </div>
    </div>
  );
};

export default intlConnect(mapStateToProps, null)(CameosEditor);
