import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSPopover from 'views/common/components/SDSPopover/SDSPopover';
import PreviewSnapcode from 'views/publisherStoryEditor/components/PreviewSnapcode/PreviewSnapcode';

import style from './SnapcodePreviewButtonPopover.scss';

type Props = {
  waitingForTranscode: boolean;
  children: ReactNode;
};

function SnapcodePreviewButtonPopover(props: Props) {
  const renderWaitingForTranscodeMessage = () => (
    <FormattedMessage
      id="single-asset-snapcode-preview-not-ready"
      description="Message shown when single asset snapcode preview is not ready yet"
      defaultMessage="Preview available when content is processed"
      data-test="SnapcodePreviewButtonPopover.waitingForTranscodeMessage"
    />
  );

  const renderSnapcodeContent = () => (
    <div data-test="SnapcodePreviewButtonPopover.snapcodeContent">
      <PreviewSnapcode className={style.previewSnapcode} />
      <div className={style.warningContainer}>
        <DotStatus status={DotStatusState.INCOMPLETE} />
        {getMessageFromId('snapcode-scan-warning')}
      </div>
    </div>
  );

  return (
    <SDSPopover
      overlayClassName={style.popover}
      trigger={props.waitingForTranscode ? 'hover' : 'click'}
      content={props.waitingForTranscode ? renderWaitingForTranscodeMessage() : renderSnapcodeContent()}
      data-test="SnapcodePreviewButtonPopover.popover"
    >
      {props.children}
    </SDSPopover>
  );
}

export default SnapcodePreviewButtonPopover;
