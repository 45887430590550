// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Row } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import { isEqual } from 'lodash';
import React from 'react';

import { getActiveTopsnap } from 'state/editor/selectors/editorSelectors';
import { isSubscribeSnapSelected } from 'state/singleAssetStory/selectors/singleAssetStorySelectors';

import { RichSnapComponentType } from 'config/constants';
import { assertState } from 'utils/assertionUtils';
import { intlConnect } from 'utils/connectUtils';

import { getRichEditorConfigForSnapType } from 'views/editor/richEditorConfig';
import SingleAssetSubtitlesPreview from 'views/subtitles/SingleAssetSubtitlesPreview/SingleAssetSubtitlesPreview';
import TopSnapSubtitlesPreview from 'views/subtitles/TopSnapSubtitlesPreview/TopSnapSubtitlesPreview';

import PlaceholderPanel from './Panels/PlaceholderPanel';
import TilePropertyPanel from './Panels/TilePropertyPanel';

import type { RichSnapComponent } from 'types/components';
import { State } from 'types/rootState';

type OwnProps = {
  component?: RichSnapComponent;
  isSingleAssetStoryEditor?: boolean;
  isSingleSnapBuilder?: boolean;
  isOnAttachmentTab?: boolean;
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const activeTopsnap = getActiveTopsnap(state);
  return {
    isSubscribeSnapActive: isSubscribeSnapSelected(state),
    activeTopsnapId: activeTopsnap?.id,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

const getTileEditorData = () => ({
  panelList: [TilePropertyPanel],
  panelSpecificProps: {},
});
export class EditorPropertyPanels extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props, nextState: Props) {
    return (nextProps && !isEqual(this.props, nextProps)) || (nextState && !isEqual(this.state, nextState));
  }

  renderPlaceholderPanel = () => {
    return (
      <PlaceholderPanel
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        isSingleAssetStoryEditor={this.props.isSingleAssetStoryEditor}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        isOnAttachmentTab={this.props.isOnAttachmentTab}
        isActiveComponent={!!this.props.component}
      />
    );
  };

  renderPanels() {
    if (!this.props.component) {
      return [<Row key="placeholder">{this.renderPlaceholderPanel()}</Row>];
    }

    const { componentType, componentId } = this.props.component;
    const { isSubscribeSnapActive } = this.props;
    let panelList = [];
    let panelSpecificProps = {};
    if (componentType === RichSnapComponentType.TILE) {
      ({ panelList, panelSpecificProps } = getTileEditorData());
    } else if (componentType === RichSnapComponentType.TILE_PLACEHOLDER) {
      ({ panelList, panelSpecificProps } = getTileEditorData());
    } else if (componentType === RichSnapComponentType.SNAP) {
      const config = getRichEditorConfigForSnapType((this.props.component as any).snap.type);
      panelList = config?.propertyPanels || [];
    } else if (componentType === RichSnapComponentType.SNAP_PLACEHOLDER) {
      panelList = [this.renderPlaceholderPanel];
      // TopSnapSubtitlesPreview is used for both SimpleStoryBuilder and in SingleSnapBuilder,
      // because in both cases the subtitles are attached to the media of the snap
    } else if (
      componentType === RichSnapComponentType.SUBTITLES &&
      (isSubscribeSnapActive || this.props.isSingleSnapBuilder)
    ) {
      panelList = [TopSnapSubtitlesPreview];
    } else if (componentType === RichSnapComponentType.SUBTITLES) {
      panelList = [SingleAssetSubtitlesPreview];
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(panelList).is.array();
    const props = {
      ...this.props,
      ...panelSpecificProps,
    };
    return panelList.map((Panel, index) => {
      return isSubscribeSnapActive ? (
        <Panel {...props} />
      ) : (
        // eslint-disable-next-line react/no-array-index-key
        <Row key={`${componentType}-${componentId}-${index}`}>
          <Panel {...props} />
        </Row>
      );
    });
  }

  render() {
    return <div>{this.renderPanels()}</div>;
  }
}

export default intlConnect(mapStateToProps, null)(EditorPropertyPanels);
