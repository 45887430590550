import React from 'react';

import { shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { isReadOnly } from 'state/editor/selectors/editorSelectors';
import { setSnapPropertiesAndSave } from 'state/snaps/actions/snapsActions';

import { intlConnect } from 'utils/connectUtils';

import SnapTagInput from 'views/editor/components/SnapTagInput/SnapTagInput';
import ModerationPanel, {
  ModerationItem,
} from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/ModerationPanel/ModerationPanel';
import NotesControl from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/NotesControl/NotesControl';
import TagLabel from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/TagLabel/TagLabel';

import { BuildStatusType } from 'types/build';
import { EditionID } from 'types/editionID';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';
import { TimelineSnap } from 'types/singleAssetStoryEditor';

type OwnProps = {
  snap: TimelineSnap;
  storyId: EditionID;
  snaps?: TimelineSnap[];
};

type StateProps = {
  isSnapReadOnly: boolean;
  buildStatus: BuildStatusType;
};

const mapDispatchToProps = {
  saveUpdatedSnapProperties: setSnapPropertiesAndSave,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    buildStatus: getDiscoverSnapBuildStatus(state)(ownProps.snap?.snapId),
    isSnapReadOnly: isReadOnly(state),
  };
};

export function SingleAssetSnapCardEdit({ snap, isSnapReadOnly, buildStatus, saveUpdatedSnapProperties }: Props) {
  return (
    <>
      <TagLabel buildStatus={buildStatus} />
      <SnapTagInput snapId={snap.snapId} disabled={isSnapReadOnly} data-test="SingleAssetSnapCardEdit.tagInput" />
      <NotesControl
        isReadOnly={isSnapReadOnly}
        notes={snap.notes}
        snapId={snap.snapId}
        setSnapPropertiesAndSave={saveUpdatedSnapProperties}
        data-test="SingleAssetSnapCardEdit.notesInput"
      />
      {shouldShowModeration(buildStatus?.audience) && (
        <ModerationPanel
          moderationItem={ModerationItem.SNAP}
          audienceList={buildStatus?.audience || []}
          violationList={buildStatus?.moderationViolations ?? []}
        />
      )}
    </>
  );
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SingleAssetSnapCardEdit);
