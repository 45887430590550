import React from 'react';
import { FormattedMessage } from 'react-intl';

import { blocker } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';

import style from './MediaError.scss';

export class MediaError extends React.PureComponent {
  render() {
    return (
      <div className={style.mediaError}>
        <Icon className={style.blockerIcon} inlineIcon={blocker} />
        <div className={style.mediaErrorMessage}>
          <FormattedMessage
            id="topsnap-media-error-message"
            description="Message to display when media has failed"
            defaultMessage="There has been an error with the media, please re-upload it."
          />
        </div>
      </div>
    );
  }
}

export default MediaError;
