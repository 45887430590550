// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { document, setTimeout } from 'global';
import log from 'loglevel';

import * as gaUtils from 'utils/gaUtils';

const SC_PLUGIN_COMMAND_REGEX = /^SC[a-zA-Z0-9]+Command$/;

export default class SCEditorAnalytics {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCEditorAnalytics';
  }

  static define(registry: any) {
    registry.registerPlugin(SCEditorAnalytics, {
      init(editor: any) {
        const editorApplyStyle = editor.applyStyle;
        // eslint-disable-next-line no-param-reassign
        editor.applyStyle = (...args: any[]) => {
          const returnValue = editorApplyStyle.apply(editor, args);
          setTimeout(() => logExtraStyles(args[0]));
          return returnValue;
        };

        editor.on('afterCommandExec', (event: any) => {
          let name = event.data && event.data.name;

          if (name) {
            // Events originating from plugins that we've created will be of the format
            // 'SCImageCommand', 'SCVideoCommand' etc, whereas the inbuilt ones are more
            // simple: 'bold', 'removeFormatting' etc. In order to make things look more
            // consistent in the Google Analytics data, we trim the 'Command' suffix from
            // any events originating from our plugins.
            if (SC_PLUGIN_COMMAND_REGEX.test(name)) {
              name = name.replace(/Command$/, '');
            }

            gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, `article-editor-${name}`);
          } else {
            log.error('CKEditor command did not have a name - could not log Google Analytics event');
          }
        });

        editor.on('paste', (event: any) => {
          const type = (event.data && event.data.type) || 'unknown';

          gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-paste', { type });
        });

        editor.on('drop', (event: any) => {
          const dataTransfer = event.data && event.data.dataTransfer;
          const numFiles = (dataTransfer && dataTransfer.getFilesCount()) || 0;

          gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-drop', { numFiles });
        });
      },
    });
  }
}

const styleMapping = {
  color: 'change-font-color',
  fontSize: 'change-font-size',
  fontFamily: 'change-font-family',
};
const styleList = Object.keys(styleMapping);

function logExtraStyles(styleObj: any) {
  const testSpan = document.createElement('span');
  styleObj.applyToObject(testSpan);

  const styleDefinition = testSpan.style;

  const gaEvents: any = [];

  styleList.forEach(styleKey => {
    const styleVal = styleDefinition[styleKey];
    if (!styleVal) {
      return;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const eventName = styleMapping[styleKey];
    gaEvents.push({
      name: eventName,
      value: styleVal,
    });
  });

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  gaEvents.forEach(event => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, `article-editor-${event.name}`, { value: event.value });
  });
}
