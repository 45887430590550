import React from 'react';
import type { ReactNode } from 'react';
import reactDOM from 'react-dom';
import InlineSVG from 'svg-inline-react';

import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { intlConnect } from 'utils/connectUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import ListItemWithIcon from 'views/common/components/ListItem/ListItemWithIcon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import type { SnapNode, DomElement } from 'views/publisherStoryEditor/containers/StoryCarousel/StoryCarouselTypes';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/delete.svg.inline' or it... Remove this comment to see the full error message
import deleteIcon from 'icons/delete.svg.inline';

import style from './DeletedSnapCarouselItem.scss';
import DeletedStoryTopsnapPreview from './DeletedStoryTopsnapPreview';

import type { TopSnap } from 'types/snaps';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => ({
  canDeleteSnap: publisherStoryEditorSelectors.getActiveEditionCanDeleteSnaps(state),
});
const mapDispatchToProps = {
  deleteSnapFromActiveEdition: publisherStoryEditorActions.deleteSnapFromActiveEdition,
};
type StateProps = {
  canDeleteSnap: boolean;
};
type DispatchProps = {
  deleteSnapFromActiveEdition: typeof publisherStoryEditorActions.deleteSnapFromActiveEdition;
};
type SuppliedProps = {
  snap: TopSnap;
  className?: string;
  saveSnapDomElement: (b: SnapNode, a: DomElement) => void;
  snapNode: SnapNode;
  style: string;
  connectDragSource: (a: ReactNode) => ReactNode;
};
type InternalState = {};
type OwnProps = StateProps & DispatchProps & SuppliedProps;
type Props = OwnProps & typeof DeletedSnapCarouselItem.defaultProps;
export class DeletedSnapCarouselItem extends React.Component<Props, InternalState> {
  static defaultProps = {
    connectDragSource: (item: ReactNode) => item,
  };

  shouldComponentUpdate(nextProps: Props, nextState: InternalState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  onDeletedSnapDismiss = () => {
    if (!this.props.canDeleteSnap) {
      return;
    }
    this.props.deleteSnapFromActiveEdition({ snapId: this.props.snap.id });
  };

  saveSnapElement = (element?: Element | null) => {
    this.props.saveSnapDomElement(this.props.snapNode, reactDOM.findDOMNode(element));
  };

  renderSnapRemoved() {
    return <div className={style.snapRemovedMessage}>{intlMessages.getMessageFromId('snap-removed')}</div>;
  }

  renderItemMenu() {
    const icon = <InlineSVG src={deleteIcon} className={style.dismissIcon} />;
    return (
      <div>
        <div className={(style as any).attachmentSpacer}>&#x200B;</div>
        <div>
          <ListItemWithIcon
            className={style.dismiss}
            iconImg={icon}
            text={intlMessages.getMessageFromId('dismiss')}
            onClick={this.onDeletedSnapDismiss}
          />
        </div>
      </div>
    );
  }

  renderSnapDotStatus = () => {
    return (
      <div className={style.dotStatus}>
        <DotStatus status={DotStatusState.ERROR} />
      </div>
    );
  };

  renderSnap() {
    return (
      <div className={style.snapContainer} ref={this.saveSnapElement}>
        {this.renderSnapDotStatus()}
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'CSSProper... Remove this comment to see the full error message */}
        <div className={this.props.className} style={this.props.style}>
          <DeletedStoryTopsnapPreview />
          {this.renderSnapRemoved()}
          {this.renderItemMenu()}
        </div>
      </div>
    );
  }

  render() {
    return this.props.connectDragSource(
      <div>
        <SDSTooltip
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.TOP}
          id="snap-status"
          title={intlMessages.getMessageFromId('snap-deleted-by-owner')}
          delayShow={250}
        >
          {this.renderSnap()}
        </SDSTooltip>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(DeletedSnapCarouselItem);
