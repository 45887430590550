import Select from 'antd/lib/select';
import _ from 'lodash';
import React from 'react';
import reactCSS from 'reactcss';

import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import style from './FontPicker.scss';

export const DEFAULT_FONT = {
  display: 'Avenir Next',
  output: 'Avenir Next,Helvetica Neue,Helvetica,Arial,sans-serif',
};

type OwnProps = {
  label?: string;
  onChange?: (...args: any[]) => any;
  fonts?: {
    display: string;
    output: string;
  }[];
  selectedFontOutput?: string;
};

type State = any;

type Props = OwnProps & typeof FontPicker.defaultProps;

export class FontPicker extends React.Component<Props, State> {
  static defaultProps = {
    fonts: _.sortBy(
      [
        { display: 'Arial', output: 'Arial, Helvetica, sans-serif' },
        { display: 'Courier New', output: 'Courier New, Courier, monospace' },
        { display: 'Georgia', output: 'Georgia, serif' },
        { display: 'Lucida Sans Unicode', output: 'Lucida Sans Unicode, Lucida Grande, sans-serif' },
        { display: 'Tahoma', output: 'Tahoma, Geneva, sans-serif' },
        { display: 'Times New Roman', output: 'Times New Roman, Times, serif' },
        { display: 'Trebuchet MS', output: 'Trebuchet MS, Helvetica, sans-serif' },
        { display: 'Verdana', output: 'Verdana, Geneva, sans-serif' },
        DEFAULT_FONT,
      ],
      ['display']
    ),
    onChange: _.noop,
    selectedFontOutput: DEFAULT_FONT.output,
  };

  state = {
    selectedFont: DEFAULT_FONT,
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    this.state.selectedFont = this.fontObjectFromOutput(props.selectedFontOutput, props.fonts);
  }

  fontObjectFromOutput = (output: any, fonts = this.props.fonts) => {
    return _.find(fonts, { output }) || DEFAULT_FONT;
  };

  handleChange = (value: any) => {
    const selectedFont = this.fontObjectFromOutput(value);
    this.setState({ selectedFont });
    this.props.onChange(selectedFont.output);
  };

  renderDropdownOptions = (optionsArray: any) => {
    if (!optionsArray) {
      return null;
    }
    return optionsArray.map((option: { output: any; display: any }) => {
      const menuItemCss = reactCSS({
        default: {
          menuItem: {
            fontFamily: option.output,
          },
        },
      });

      return (
        <Select.Option
          key={`SDSDropdownOption.${option.display}`}
          value={option.output}
          data-test={`SDSDropdown.selectOptions.${option.display}`}
        >
          <span style={menuItemCss.menuItem}>{option.display}</span>
        </Select.Option>
      );
    });
  };

  render() {
    const { label } = this.props;

    return (
      <div className={style.container}>
        <SDSLabel labelTitle={label}>
          <SDSDropdown
            disableClear
            value={this.state.selectedFont.output}
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
            onChange={this.handleChange}
            data-test="fontPicker"
          >
            {this.renderDropdownOptions(this.props.fonts)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }
}

export default FontPicker;
