import is from 'is_js';
import jQuery from 'jquery';
// @ts-expect-error ts-migrate(6133) FIXME: 'JQ' is declared but its value is never read.
import type JQ from 'jquery';
import _ from 'lodash';
import log from 'loglevel';

import { ArticleFields } from 'state/article/articleState';

import { CrossOrigin } from 'config/constants';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import { assertArg } from 'utils/assertionUtils';
import { maskAndParse, unmaskAndStringify, MASKED_SRC } from 'utils/htmlUtils';

import { MetaAttributes as ImageMetaAttributes } from 'views/editor/containers/ArticleEditor/plugins/SCImage';
import { MetaAttributes as VideoMetaAttributes } from 'views/editor/containers/ArticleEditor/plugins/SCVideo';

import { isAssetID, isMediaID, toAssetID } from 'types/assets';
import type { AssetID } from 'types/assets';

const GENERIC_IMG = 'img';
const VIDEO_IMG = `img[${VideoMetaAttributes.VIDEO_ID}]`;

const HTMLTarget = {
  EDITOR: 'EDITOR',
  APP: 'APP',
};

export default class ArticleHTMLBuilder {
  _fields: ArticleFields;

  // @ts-expect-error ts-migrate(2749) FIXME: 'JQ' refers to a value, but is being used as a typ... Remove this comment to see the full error message
  _parsedContent: JQ;

  constructor(fields: ArticleFields) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(fields).is.object();

    this._fields = fields;
    this._parsedContent = maskAndParse(fields.articleContent || '');
  }

  extractImageIds() {
    return this.extractImageUrls().map(extractAssetIdFromImageUrl).filter(is.existy);
  }

  extractVideoIds() {
    return this._getUniqueAttributes(VIDEO_IMG, VideoMetaAttributes.VIDEO_ID).filter(isAssetID).map(toAssetID);
  }

  extractImageUrls() {
    return this._getUniqueAttributes(GENERIC_IMG, MASKED_SRC);
  }

  _getUniqueAttributes(selector: any, attribute: any) {
    return _.uniq(
      this._parsedContent
        .find(selector)
        .map((index: any, img: any) => img.getAttribute(attribute))
        .get()
    );
  }

  replaceImageUrls(imageUrlsMap: any) {
    this._parsedContent.find(GENERIC_IMG).each((index: any, img: any) => {
      const originalSrc = img.getAttribute(MASKED_SRC);
      const updatedSrc = imageUrlsMap[originalSrc];

      if (typeof updatedSrc !== 'string') {
        throw new Error(`Could not find url mapping for ${originalSrc}`);
      }

      img.setAttribute(MASKED_SRC, updatedSrc);
      img.setAttribute('crossOrigin', CrossOrigin.USE_CREDENTIALS);
    });
  }

  replaceMediaIds(mediaToTranscodedMediaIdMap: any) {
    this._parsedContent.find(GENERIC_IMG).each((index: any, img: any) => {
      const originalSrc = img.getAttribute(VideoMetaAttributes.VIDEO_ID);
      const updatedSrc = mediaToTranscodedMediaIdMap[originalSrc];

      if (!isMediaID(originalSrc)) {
        return;
      }

      if (typeof updatedSrc !== 'string') {
        throw new Error(`Could not find transcodedMediaId for mediaId ${originalSrc}`);
      }

      img.setAttribute(VideoMetaAttributes.VIDEO_ID, updatedSrc);
      img.setAttribute('crossOrigin', CrossOrigin.USE_CREDENTIALS);
    });
  }

  embedImageAspectRatios(loadImageFn: any) {
    const imagesWithNoAspectRatio = this._parsedContent
      .find(GENERIC_IMG)
      .filter(`:not([${ImageMetaAttributes.ASPECT_RATIO}])`);

    const promises = imagesWithNoAspectRatio.map((index: any, img: any) => {
      return loadImageFn(img.getAttribute(MASKED_SRC)).then(this._embedImageAspectRatio.bind(this, img));
    });

    return Promise.all(promises);
  }

  _embedImageAspectRatio(targetImageNode: any, loadedImageNode: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const aspectRatio = loadedImageNode.height !== 0 ? loadedImageNode.width / loadedImageNode.height : 0;

      targetImageNode.setAttribute(ImageMetaAttributes.ASPECT_RATIO, aspectRatio);
      targetImageNode.setAttribute(ImageMetaAttributes.CROSS_ORIGIN, CrossOrigin.USE_CREDENTIALS);
      resolve();
    });
  }

  getChannel() {
    return this._fields.channel;
  }

  getHeadline() {
    return this._fields.headline;
  }

  getSecondaryHeadline() {
    return this._fields.secondaryHeadline;
  }

  getContentHTML() {
    return unmaskAndStringify(this._parsedContent);
  }

  // Generates the HTML that is loaded into the ArticleEditor
  // when the user opens a snap in the CMS.
  getWrapperHTMLForEditor() {
    return this._getWrapperHTMLForTarget(HTMLTarget.EDITOR);
  }

  // Generates the HTML that is loaded into the client app
  // (i.e. iOS/Android) when someone opens an article snap
  // in Discover.
  getWrapperHTMLForApp() {
    return this._getWrapperHTMLForTarget(HTMLTarget.APP);
  }

  _getWrapperHTMLForTarget(htmlTarget: any) {
    const fields = this._fields;
    const html = [];

    html.push('<html>');
    html.push('<head>');
    html.push(
      '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />'
    );
    html.push('</head>');
    html.push('<body>');

    html.push('<div class="in-preview-inner">');
    html.push('<div class="in-preview-container">');

    if (fields.channel) {
      html.push(`
      <div class="in-preview-channel">
        <div class="inner" dir="auto">${fields.channel}</div>
      </div>`);
    }

    if (fields.headline || fields.secondaryHeadline) {
      html.push('<div class="in-preview-headline">');

      if (fields.headline) {
        html.push(`<div class="inner" dir="auto">${fields.headline}</div>`);
      }

      // TODO It feels strange that we insert this node regardless of whether there's
      // a secondary headline or not, but this does match the behavior of the legacy
      // codebase. Once we phase out headlines this will be removed anyway, so ok to
      // leave it as-is for now.
      html.push(`<div class="secondary" dir="auto">${fields.secondaryHeadline || ''}</div></div>`);
    }

    if (!this._parsedContent.is(':empty')) {
      html.push('<div class="in-preview-content">');
      html.push(`<div class="inner" dir="auto">${this._getContentHTMLForSaving(htmlTarget)}</div>`);
      html.push('</div>');
    }

    html.push('</div>');
    html.push('</div>');

    html.push('</body></html>');

    return html.join('');
  }

  _getContentHTMLForSaving(htmlTarget: any) {
    const $content = maskAndParse(this.getContentHTML());
    const withOverlays = htmlTarget === HTMLTarget.APP ? this._addEmbeddedVideoOverlays($content) : $content;
    const converted = this._convertImageSourcesToAssetIds(withOverlays);
    return unmaskAndStringify(converted);
  }

  // Each video that gets embedded into the article has an 'overlay' node added to it
  // that is used to render the 'play' icon overlay in the app.
  _addEmbeddedVideoOverlays(parsedContent: any) {
    parsedContent.find(VIDEO_IMG).each((index: any, img: any) => {
      const node = jQuery(img);

      node.wrap('<div class="embed-video-container"></div>');

      const overlaysDiv = jQuery(`
          <div class="overlays">
            <div class="update"><span class="error"></span></div>
            <div class="play"></div>
          </div>`);

      const { updateSnapchatMessage } = this._fields.translatedMessages;

      jQuery(document.createTextNode(updateSnapchatMessage)).insertAfter(overlaysDiv.find('.error'));

      overlaysDiv.insertAfter(node);
    });

    return parsedContent;
  }

  // Rather than complete asset urls, the backend expects that each image tag's src
  // property contains just the id of the image asset it relates to. Here we extract
  // each of the asset ids and write them into the src property.
  //
  // @see inverse function: editorHTMLInputProcessor.convertAssetIdsToImageSources()
  _convertImageSourcesToAssetIds(parsedContent: any) {
    parsedContent.find(GENERIC_IMG).each((index: any, img: any) => {
      const src = img.getAttribute(MASKED_SRC);

      img.setAttribute(MASKED_SRC, extractAssetIdFromImageUrl(src));
    });

    return parsedContent;
  }
}

function extractAssetIdFromImageUrl(imageUrl: any): AssetID | undefined | null {
  if (isAssetID(imageUrl)) {
    return toAssetID(imageUrl);
  }

  // Otherwise, extract the id using the asset download URL matcher
  const matchResult = mediaLibraryAPI.asset.downloadMatcher(imageUrl);
  if (matchResult) {
    return toAssetID(matchResult.assetId);
  }

  // Should never happen
  log.error(`Could not extract assetId from image url ${imageUrl}`);
  return null;
}
