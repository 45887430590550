// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { FormControl } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import _ from 'lodash';
import React from 'react';

// @ts-expect-error ts-migrate(6133) FIXME: 'CallToActionOptions' is declared but its value is... Remove this comment to see the full error message
import { CallToActionOptions } from 'config/constants';

type Props = {
  options: any[]; // TODO: PropTypes.oneOf(Object.values(CallToActionOptions))
  isReadOnly?: boolean;
};
export default class CallToActionControl extends React.Component<Props> {
  render() {
    const { options = [], isReadOnly = false, ...props } = this.props;
    return (
      <FormControl
        componentClass="select"
        {...props}
        isReadOnly={isReadOnly} // isReadOnly should trump disabled if passed.
        value={(props as any).value || ''}
      >
        <option
          key="not_selected"
          value="not_selected"
          data-selected={!(props as any).value ? 'selected' : ''}
          disabled
        >
          Select...
        </option>
        {options.map(option => {
          const optionLabel = (option && _.startCase(_.toLower(option))) || 'None';
          return (
            <option key={option} value={option} data-selected={(props as any).value === option ? 'selected' : ''}>
              {optionLabel}
            </option>
          );
        })}
      </FormControl>
    );
  }
}
