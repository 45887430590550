import React from 'react';
import type { ReactNode } from 'react';

import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { push } from 'state/router/actions/routerActions';
import { getActiveCreatorHostUsername } from 'state/user/selectors/userSelectors';

import { media } from 'icons/SDS/allIcons';
import * as localRoutes from 'utils/apis/localRoutes';
import { intlConnect } from 'utils/connectUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import TopsnapEditorRow from 'views/editor/components/TopsnapEditorRow/TopsnapEditorRow';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import { State } from 'types/rootState';

type OwnProps = {
  editionId: EditionID;
  publisherId: PublisherID;
  snapId: SnapId;
  attachmentId: string | undefined | null;
  buttonText: ReactNode | null | Iterable<ReactNode | undefined | null>;
  displayAsRow: boolean | undefined | null;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = {
  push: typeof push;
};

type Props = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: State) => {
  return {
    publisher: getActivePublisherDetails(state),
    hostUsername: getActiveCreatorHostUsername(state),
  };
};

const mapDispatchToProps = {
  push,
};

const onClickHandler = (props: Props) => () => {
  // Log usage of the add media to story to grafana
  incrementCounterByPublisher(props.publisher, GrafanaMetrics.MEDIA_V2, {
    type: 'topSnap',
  });

  if (props.attachmentId) {
    props.push(
      localRoutes.creator.mediaLibraryForAttachment({
        editionId: props.editionId,
        snapId: props.snapId,
        attachmentId: props.attachmentId,
        hostUsername: props.hostUsername,
      })
    );
  } else {
    props.push(
      localRoutes.creator.mediaLibraryForTopSnap({
        editionId: props.editionId,
        snapId: props.snapId,
        hostUsername: props.hostUsername,
      })
    );
  }
};

export function MediaLibraryButton(props: Props) {
  return props.displayAsRow ? (
    <TopsnapEditorRow
      rowDescription={props.buttonText}
      handleClick={onClickHandler(props)}
      icon={media}
      data-test="mediaLibrary"
    />
  ) : (
    <SDSButton
      onClick={onClickHandler(props)}
      inlineIcon={media}
      data-test="mediaLibrary.button"
      type={ButtonType.SECONDARY}
    >
      {props.buttonText}
    </SDSButton>
  );
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(MediaLibraryButton);
