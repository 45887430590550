import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';
import * as videoLibrarySelectors from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { DropzoneType, UploadPurpose, PreviewStates, MlsMediaProfile } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';

import SaveCancelButtons from 'views/common/components/SaveCancelButtons/SaveCancelButtons';
import { Spinner, SpinnerLabels, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import VideoPlayer from 'views/editor/components/VideoPlayer/VideoPlayer';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';
import VideoDetails from 'views/editor/containers/VideoDetails/VideoDetails';

import style from './LongformVideoEditor.scss';

import { AssetID, isMediaID } from 'types/assets';
import { SnapId } from 'types/common';
import { LongformVideoSnap } from 'types/snaps';

export const mapStateToProps = (state: State, props: any) => {
  const bottomSnap = editorSelectors.getActiveBottomsnap(state) as LongformVideoSnap;
  const bottomSnapId = get(bottomSnap, 'id', undefined);
  const manifestUrl = bottomSnap.manifestUrl;
  const topsnapId = editorSelectors.getActiveWholeSnapId(state);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSaving' does not exist on type '{}'.
  const { isSaving, replaceLongformVideoPending } = editorSelectors.getEditorConfig(state);
  const activeSubtitleUploadCount = mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
    props.snapComponentId,
    UploadPurpose.SUBTITLE
  );
  // TODO (piers) Rename video > asset as part of renaming PR
  const previewState = videoLibrarySelectors.getVideoPreviewState(state)(props.snapComponentId);
  const editionId = editorSelectors.getActiveEditionId(state);
  const assetId = replaceLongformVideoPending || (bottomSnap && bottomSnap.longformVideoAssetId);
  const preview = videoLibrarySelectors.getVideoResultById(state)(assetId);
  const videoSrc = videoLibrarySelectors.getVideoSrcById(state)(assetId);
  return {
    preview,
    topsnapId,
    bottomSnapId,
    isSaving,
    replaceLongformVideoPending,
    activeSubtitleUploadCount,
    previewState,
    manifestUrl,
    videoSrc,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ snapId: any; editionId: number... Remove this comment to see the full error message
    isReadOnly: editorSelectors.isLocked(state)({ snapId: bottomSnapId, editionId }),
    editionId,
    assetId,
  };
};
const mapDispatchToProps = {
  setSnapPropertiesAndSave: snapsActions.setSnapPropertiesAndSave,
  setEditorConfigProperties: editorActions.setEditorConfigProperties,
  resolveVideoUntilComplete: videoLibraryActions.resolveVideoUntilComplete,
  getVideoMetadata: videoLibraryActions.getVideoMetadata,
  getFreshTranscode: mediaActions.getFreshTranscode,
};
const VIDEO_METADATA_LOADING_DELAY = 5000;

type LongformVideoEditorProps = {
  assetId?: AssetID;
  snapComponentId?: string;
  topsnapId?: SnapId;
  editionId?: number;
  bottomSnapId?: SnapId;
  isSaving?: boolean;
  manifestUrl?: string;
  replaceLongformVideoPending?: AssetID;
  preview?: any;
  activeSubtitleUploadCount?: number;
  isReadOnly?: boolean;
  videoSrc?: any;
};
export class LongformVideoEditor extends React.Component<LongformVideoEditorProps> {
  componentDidMount() {
    if (this.props.assetId) {
      (this.props as any).getVideoMetadata({ assetId: this.props.assetId });
    }
  }

  componentDidUpdate(prevProps: LongformVideoEditorProps) {
    if (this.props.assetId && this.props.assetId !== prevProps.assetId) {
      this.clearVideoMetadataTimeout();
      this.getVideoMetadataTimeout(this.props.assetId);
    }
  }

  componentWillUnmount() {
    this.clearVideoMetadataTimeout();
  }

  getVideoMetadataTimeout = (assetId: any) => {
    if (!this.props.videoSrc) {
      (this.props as any).getVideoMetadata({ assetId });
      this.videoMetadataTimeout = setTimeout(() => this.getVideoMetadataTimeout(assetId), VIDEO_METADATA_LOADING_DELAY);
    }
  };

  clearVideoMetadataTimeout = () => {
    if (this.videoMetadataTimeout) {
      clearTimeout(this.videoMetadataTimeout);
    }
  };

  videoMetadataTimeout: NodeJS.Timeout | null = null;

  async saveSnapMedia(mediaId: any) {
    let longformVideoAssetId = mediaId;
    if (isMediaID(mediaId)) {
      const claimResponse = await (this.props as any).getFreshTranscode(mediaId);
      longformVideoAssetId = claimResponse.payload.id;
    }
    return (this.props as any)
      .setSnapPropertiesAndSave({ snapId: this.props.bottomSnapId }, { longformVideoAssetId })
      .then(() => {
        (this.props as any).resolveVideoUntilComplete({ assetId: longformVideoAssetId });
      });
  }

  clickSave = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'longform-video-save');
    (this.props as any).setEditorConfigProperties({
      isSaving: true,
    });
    Promise.resolve(
      this.props.replaceLongformVideoPending
        ? // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          this.saveSnapMedia(this.props.replaceLongformVideoPending, MlsMediaProfile.LONGFORM_VIDEO)
        : null
    ).then(() => {
      (this.props as any).setEditorConfigProperties({
        isSaving: false,
        replaceLongformVideoPending: null,
      });
    });
  };

  clickCancel = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.MEDIA, 'longform-video-cancel');
    (this.props as any).setEditorConfigProperties({
      isSaving: false,
      replaceLongformVideoPending: null,
    });
  };

  isPreviewState(testState: any) {
    return (this.props as any).previewState === testState;
  }

  renderSpinner() {
    let message;
    if (this.isPreviewState(PreviewStates.UPLOADING)) {
      message = SpinnerLabels.UPLOADING;
    } else if (this.isPreviewState(PreviewStates.PROCESSING)) {
      message = SpinnerLabels.PROCESSING;
    } else if (this.props.activeSubtitleUploadCount) {
      message = SpinnerLabels.UPLOADING_SUBTITLES;
    } else if (this.isPreviewState(PreviewStates.SAVING)) {
      message = SpinnerLabels.SAVING;
    }
    if (!message) {
      return null;
    }
    return <Spinner loading withBox message={message} className={style.spinner} />;
  }

  renderBlankLongformVideo() {
    if (!this.isPreviewState(PreviewStates.EMPTY)) {
      return null;
    }
    if (this.props.manifestUrl) {
      return (
        <div className={style.manifestUrlIsPresent} data-test="longformVideo.descriptionMessage">
          <FormattedMessage
            id="manifest-url-is-present"
            description="you cannot upload media if manifest url is present"
            defaultMessage={
              'You cannot upload Media if a manifest URL is present. ' +
              'Please delete it if you would like to upload media instead.'
            }
          />
        </div>
      );
    }
    return (
      <div className={style.blankLongformVideo}>
        <MediaUploader
          assetId={this.props.assetId}
          isReadOnly={this.props.isReadOnly}
          editionId={this.props.editionId}
          snapId={this.props.bottomSnapId}
          purpose={UploadPurpose.LONGFORM_VIDEO}
          dropzoneType={DropzoneType.VIDEO}
        />
      </div>
    );
  }

  renderUploadingLongformVideo() {
    if (!this.isPreviewState(PreviewStates.UPLOADING)) {
      return null;
    }
    return (
      <div className={style.blankLongformVideo}>
        <h4 className={style.processingVideoMessage}>
          <Spinner loading size={SpinnerSizes.LARGE} />
        </h4>
      </div>
    );
  }

  renderProcessingLongformVideo() {
    const assetLoadedButNoURL = this.props.assetId && !this.props.videoSrc;
    if (!(this.isPreviewState(PreviewStates.PROCESSING) || assetLoadedButNoURL)) {
      return null;
    }
    return (
      <div className={style.blankLongformVideo}>
        <h4 className={style.processingVideoMessage}>
          <Spinner loading size={SpinnerSizes.LARGE} />
        </h4>
      </div>
    );
  }

  renderPlayer() {
    const { preview, videoSrc } = this.props;
    return (
      <div className={style.videoPreview}>
        <VideoPlayer
          className={style.video}
          src={get(videoSrc, ['url'])}
          poster={get(preview, ['images', 'poster', 'src'])}
          subtitles={get(preview, ['textTracks'])}
          data-test="LongformVideoEditor.videoPlayer"
        />
      </div>
    );
  }

  renderLongformVideoPreview() {
    if (
      !this.isPreviewState(PreviewStates.COMPLETE) &&
      !this.isPreviewState(PreviewStates.TEMP_PREVIEW) &&
      !this.isPreviewState(PreviewStates.SAVING)
    ) {
      return null;
    }
    const { preview, videoSrc, assetId } = this.props;
    if (!preview || !preview.complete || !get(videoSrc, ['url'])) {
      return null;
    }
    return (
      <div>
        {this.renderPlayer()}
        <VideoDetails className={style.videoDetails} assetId={assetId} evenSize />
      </div>
    );
  }

  renderConfirmEdit() {
    if (this.props.isSaving || !this.props.replaceLongformVideoPending) {
      return null;
    }
    return <SaveCancelButtons onCancel={this.clickCancel} onSave={this.clickSave} />;
  }

  render() {
    return (
      <div data-test="LongformVideoEditor" className={style.root}>
        {this.renderSpinner()}
        {this.renderConfirmEdit()}
        <div className={style.previewWrapper}>
          {this.renderUploadingLongformVideo()}
          {this.renderProcessingLongformVideo()}
          {this.renderBlankLongformVideo()}
          {this.renderLongformVideoPreview()}
        </div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(LongformVideoEditor);
