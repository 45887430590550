// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PollMainContent } from '@snapchat/web-attachments/lib/polls/markup/components/PollClient/PollClient';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import type { LayoutOverrides } from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayoutsFactory';
import {
  createEmptyPollOption,
  getPollsValidationOptions,
  MAX_GRID_HEIGHT,
  PollLayoutTypes,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayoutsFactory';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { TextLayout } from '@snapchat/web-attachments/lib/polls/markup/components/TextLayout/TextLayout';
import classNames from 'classnames';
import invariant from 'invariant';
import { isEmpty } from 'lodash';
import type { ReactNode } from 'react';
import React from 'react';

import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { stagePoll } from 'state/poll/actions/pollActions';
import { getPollAttachments } from 'state/poll/selectors/pollSelectors';
import { renderHtml } from 'state/poll/stages/pollClientRenderer';
import { POLL_STAGE_CONFIG } from 'state/poll/stages/pollStageConfig';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import EditableTitleWithGridLayout from '../../components/PollLayouts/Components/EditableTitleWithGridLayout/EditableTitleWithGridLayout';
import ContentStatusPanel from '../ContentStatusPanel/ContentStatusPanel';

import { ContentStatus, PollScreen } from 'config/constants';
import { State } from 'src/types/rootState';
import { assertArg } from 'utils/assertionUtils';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import ListItemWithIcon from 'views/common/components/ListItem/ListItemWithIcon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import EditableOutcomePage from 'views/editor/components/OutcomePage/editable/EditableOutcomePage';
import PagedPanel from 'views/editor/containers/PagedPanel/PagedPanel';
import LoadLocalContentModal from 'views/modals/containers/LoadLocalContentModal/LoadLocalContentModal';

import style from './PollEditor.scss';

import type { ServerAttachment } from 'types/attachments';
import type { SnapId as SnapID } from 'types/common';
import { RichSnapComponentId } from 'types/components';
import type { EditionID } from 'types/editions';
import type {
  EditorProperties,
  Option,
  Options,
  PollEditorState,
  PollID,
  PollProperties as Poll,
  Question,
} from 'types/polls';

type ExternalProps = {
  snapComponentId: RichSnapComponentId;
};

type StateProps = {
  activeEditionId: EditionID | undefined | null;
  contentStatus: ContentStatus;
  isReadOnly: boolean | undefined | null;
  poll: Poll | undefined | null;
  pollEditorState: PollEditorState;
  snapId: SnapID;
  pollAttachments: ServerAttachment[];
  isPollsTitleEditable?: boolean;
};

const mapStateToProps = (state: State, props: ExternalProps): StateProps => {
  const snapId = extractSnapIdFromComponentId(props.snapComponentId);
  const poll: Poll | undefined | null = stagesSelectors.getData(state)(snapId);
  const contentStatus = stagesSelectors.getContentStatus(state)(snapId);
  const activeEditionId = editorSelectors.getActiveEditionId(state);
  const isReadOnly = editorSelectors.isReadOnly(state);
  const pollEditorState = editorSelectors.getSnapEditorState(state)(snapId);
  const pollAttachments = getPollAttachments(state);
  const isPollsTitleEditable = featuresSelectors.isPollsTitleEditable(state);

  return {
    contentStatus,
    snapId,
    poll,
    isReadOnly,
    activeEditionId,
    pollEditorState,
    pollAttachments,
    isPollsTitleEditable,
  };
};

type DispatchProps = {
  loadPoll: (a: PollID) => void;
  savePoll: typeof stagesActions.commitStagedSnap;
  updatePoll: (id: PollID, properties: Partial<Poll>) => void;
  updateEditorState: (snapId: SnapID, editorState: PollEditorState) => void;
  getLocalBottomSnapFields: (snapId: SnapID) => Poll | undefined | null;
  showModal: typeof showModal;
};

const mapDispatchToProps = {
  loadPoll: stagePoll,
  savePoll: stagesActions.commitStagedSnap,
  updatePoll: stagesActions.updateProperties,
  updateEditorState: editorActions.updateSnapEditorState,
  getLocalBottomSnapFields: editorActions.getLocalBottomSnapFields,
  showModal,
};

const pollLayoutProvider = (layoutType: PollLayoutTypes) => {
  switch (layoutType) {
    case PollLayoutTypes.TEXT_POLL:
      return {
        LayoutComponent: TextLayout,
        layoutParameters: { rows: 0, cols: 0, gridHeight: MAX_GRID_HEIGHT },
      };
    case PollLayoutTypes.IMAGE_2X1:
      return {
        LayoutComponent: EditableTitleWithGridLayout,
        layoutParameters: { rows: 1, cols: 2, gridHeight: MAX_GRID_HEIGHT },
      };
    case PollLayoutTypes.IMAGE_2X2:
      return {
        LayoutComponent: EditableTitleWithGridLayout,
        layoutParameters: { rows: 2, cols: 2, gridHeight: MAX_GRID_HEIGHT },
      };
    default:
      return null;
  }
};

// see the react-intl docs:
// https://github.com/yahoo/react-intl/wiki/API#injectintl
// https://github.com/yahoo/react-intl/wiki/API#intlshape
type IntlProps = {};

type OwnProps = ExternalProps & StateProps & DispatchProps & IntlProps;

type OwnState = {
  previewKey: number;
  showPreview: boolean;
  isLocalContentModalVisible: boolean;
};

type Props = OwnProps & typeof PollEditor.defaultProps;

/* eslint-disable react/prop-types */
export class PollEditor extends React.Component<Props, OwnState> {
  static defaultProps = {
    pollEditorState: {
      selectedScreen: PollScreen.UNANSWERED,
    },
  };

  state = {
    showPreview: false,
    previewKey: 0,
    isLocalContentModalVisible: false,
  };

  componentDidMount() {
    if (this.props.poll === null) {
      this.props.loadPoll(this.getId());
    }

    const localFields = this.props.getLocalBottomSnapFields(this.props.snapId);

    if (localFields) {
      this.setState({ isLocalContentModalVisible: true });
    }
  }

  onConfirmLoadLocalContentModal = () => {
    const localFields = this.props.getLocalBottomSnapFields(this.props.snapId);
    this.updateProperties(localFields!);
    this.setState({ isLocalContentModalVisible: false });
  };

  onCancelLoadLocalContentModal = () => {
    this.setState({ isLocalContentModalVisible: false });
  };

  renderLocalContentModal() {
    const localFields = this.props.getLocalBottomSnapFields(this.props.snapId);

    if (localFields) {
      return (
        <LoadLocalContentModal
          snapId={this.props.snapId}
          onConfirm={this.onConfirmLoadLocalContentModal}
          onCancel={this.onCancelLoadLocalContentModal}
          visible={this.state.isLocalContentModalVisible}
        />
      );
    }
    return null;
  }

  getId() {
    return this.props.snapId;
  }

  getSelectedQuestionIndex() {
    const { pollEditorState } = this.props;

    if (!pollEditorState || typeof pollEditorState.selectedQuestionIndex !== 'number') {
      return 0;
    }

    return (pollEditorState && pollEditorState.selectedQuestionIndex) || 0;
  }

  getSelectedOutcomeIndex(): number {
    const { pollEditorState } = this.props;

    if (!pollEditorState || typeof pollEditorState.selectedOutcomeIndex !== 'number') {
      return -1;
    }

    return pollEditorState.selectedOutcomeIndex;
  }

  getSelectedQuestion() {
    const { poll } = this.props;
    if (!poll) {
      return null;
    }

    return poll.questions[this.getSelectedQuestionIndex()];
  }

  setMinOptionsRequired = (minOptionsCount: number) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(minOptionsCount).is.number();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(minOptionsCount).is.above(0);

    const question = this.getSelectedQuestion();
    const options: Options = question ? question.options : [];

    if (minOptionsCount > options.length) {
      const optionsCountDifference = minOptionsCount - options.length;
      const empties = [];
      for (let i = 0; i < optionsCountDifference; i++) {
        empties.push(createEmptyPollOption());
      }
      this.updateOptions(options.concat(empties));
    }
  };

  setEditorProperties = (editorProperties: Partial<EditorProperties>) => {
    if (!isEmpty(editorProperties)) {
      this.updateQuestion({ editorProperties });
    }
  };

  updateOptions(options: Options) {
    this.updateQuestion({ options });
  }

  savePoll = () => {
    if (!this.props.isReadOnly && this.props.activeEditionId) {
      const { activeEditionId } = this.props;
      this.props.savePoll(this.getId(), POLL_STAGE_CONFIG, {
        publisher: '', // TODO: Remove this, used to be legacy name.
        edition: activeEditionId,
      });
    }
  };

  updateOption = (index: number, changes: Partial<Option>) => {
    const question = this.getSelectedQuestion();
    if (!question) {
      return;
    }
    const questionChanges = {
      options: {
        [index]: changes,
      },
    };

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ options: { [x: number]: Partia... Remove this comment to see the full error message
    this.updateQuestion(questionChanges);
  };

  shouldRenderOutcome(): boolean {
    const { poll } = this.props;

    if (!poll || !poll.outcomes) {
      return false;
    }

    const question: Question = poll.outcomes;

    return Boolean(question.options[this.getSelectedOutcomeIndex()]);
  }

  updateOutcomes = (changes: Partial<Question>) => {
    const pollChanges = {
      outcomes: changes,
    };

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ outcomes: Partial<Question>; }... Remove this comment to see the full error message
    this.updateProperties(pollChanges);
  };

  updateQuestion = (changes: Partial<Question>) => {
    const { poll } = this.props;
    if (!poll) {
      return;
    }

    const index = this.getSelectedQuestionIndex();

    const questionChanges = {
      questions: {
        [index]: changes,
      },
    };

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ questions: { [x: number]: Part... Remove this comment to see the full error message
    this.updateProperties(questionChanges);
  };

  updateProperties = (properties: Partial<Poll>) => {
    this.props.updatePoll(this.getId(), properties);
  };

  buildSrcDoc = (isActivePreview: boolean): string | undefined | null => {
    const { poll } = this.props;

    if (!poll) {
      return null;
    }

    return renderHtml(
      poll,
      {},
      {
        isPreview: true,
        isActivePreview,
        createAssetUrl,
        setMinOptionsRequired: this.setMinOptionsRequired,
        pollAttachments: this.props.pollAttachments,
      },
      this.getSelectedQuestionIndex()
    );
  };

  showPopup = () => {
    this.setState(state => {
      return {
        previewKey: state.previewKey + 1,
        showPreview: true,
      };
    });
  };

  hidePopup = () => {
    this.setState(() => {
      return { showPreview: false };
    });
  };

  hasPollMoreThanOneQuestion(): boolean {
    const { poll } = this.props;
    if (!poll) {
      return false;
    }

    return Boolean(poll.questions) && poll.questions.length > 1;
  }

  handleQuestionSelected = (selectedQuestionIndex: number) => {
    this.updatePollEditorState({ selectedQuestionIndex });
  };

  handleLeftQuestionChange = () => {
    this.handleQuestionSelected(this.getSelectedQuestionIndex() - 1);
  };

  handleRightQuestionChange = () => {
    this.handleQuestionSelected(this.getSelectedQuestionIndex() + 1);
  };

  updatePollEditorState = (changes: Partial<PollEditorState>) => {
    this.props.updateEditorState(this.getId(), changes);
  };

  renderOutcome() {
    const { poll } = this.props;

    invariant(poll, 'Poll must be defined.');
    invariant(poll.outcomes, 'Outcomes must be defined.');

    const question: Question = poll.outcomes;
    invariant(question.options[this.getSelectedOutcomeIndex()], 'Outcome option must be defined.');

    return (
      // @ts-expect-error ts-migrate(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message
      <EditableOutcomePage
        key={this.getSelectedOutcomeIndex()}
        outcomes={poll.outcomes}
        index={this.getSelectedOutcomeIndex()}
        createAssetUrl={createAssetUrl}
        snapId={this.getId()}
        updateOutcomes={this.updateOutcomes}
        editionId={this.props.activeEditionId}
        isPreview={false}
        isEmbedded
      />
    );
  }

  renderEditPane = () => {
    const { poll, isReadOnly, isPollsTitleEditable } = this.props;

    if (!poll) {
      return null;
    }

    const question = this.getSelectedQuestion();
    if (!question) {
      return null;
    }

    const layoutOverrides: LayoutOverrides = {
      isPreview: true,
      isEmbedded: true,
      pollLayoutProvider,
      createAssetUrl,
      setMinOptionsRequired: this.setMinOptionsRequired,
      updateOption: this.updateOption,
      setEditorProperties: this.setEditorProperties,
      editionId: this.props.activeEditionId,
      customValidationOptions: getPollsValidationOptions(),
      isReadOnly,
      pollEditorState: this.props.pollEditorState,
      outcomes: poll.outcomes,
      isPollsTitleEditable,
    };

    if (question.editorProperties.backgroundAssetId) {
      layoutOverrides.backgroundImage = createAssetUrl(question.editorProperties.backgroundAssetId);
    }

    const mainContent = this.renderMainContent(poll, question, layoutOverrides);
    const selectedQuestionIndex = this.getSelectedQuestionIndex();
    const isLeftReadOnly = selectedQuestionIndex === 0;
    const isRightReadOnly = selectedQuestionIndex === poll.questions.length - 1;
    const shouldRenderPagedPanel = this.hasPollMoreThanOneQuestion() && !this.shouldRenderOutcome();
    return shouldRenderPagedPanel ? (
      <PagedPanel
        onLeftChange={this.handleLeftQuestionChange}
        onRightChange={this.handleRightQuestionChange}
        isLeftReadOnly={isLeftReadOnly}
        isRightReadOnly={isRightReadOnly}
      >
        {mainContent}
      </PagedPanel>
    ) : (
      mainContent
    );
  };

  renderQuestionIndicator(): ReactNode {
    const { poll } = this.props;
    if (!poll) {
      return null;
    }

    if (this.shouldRenderOutcome()) {
      return getMessageFromId('outcome-default-text', {
        index: '',
      });
    }

    return getMessageFromId('poll-question-indicator', {
      index: this.getSelectedQuestionIndex() + 1,
      count: poll.questions.length,
    });
  }

  renderMainContent(poll: Poll, question: Question, layoutOverrides: LayoutOverrides) {
    // Adding a possibility to style the child elements according the :global classes.
    // Otherwise I'm not able to style according the scoped classes.
    // I want to show the correct answer selector when we hover over the poll-editor.
    const editorClasses = classNames(style.previewWrapper, 'poll-editor');
    const shouldDisplayQuestionIndicator: boolean = this.hasPollMoreThanOneQuestion() || this.shouldRenderOutcome();
    return (
      <div className={editorClasses}>
        <div className={style.previewButtonContainer}>
          <div className={style.questionIndicator} data-test="pollEditor.PersonalityQuizQuestions">
            {shouldDisplayQuestionIndicator && this.renderQuestionIndicator()}
          </div>
          <ListItemWithIcon
            iconKey="eye"
            data-test="pollPreviewIcon"
            onClick={this.showPopup}
            className={style.previewButton}
            text={getMessageFromId('preview-button-label')}
          />
        </div>
        {this.shouldRenderOutcome() ? (
          <div className={style.previewWrapper}>{this.renderOutcome()}</div>
        ) : (
          <PollMainContent
            key={this.getSelectedQuestionIndex()}
            question={question}
            snapId={poll.id}
            layoutOverrides={layoutOverrides}
          />
        )}
      </div>
    );
  }

  renderPreviewPanel = () => {
    const html = this.buildSrcDoc(true);

    return (
      <div className={style.previewTabContent}>
        <div className={style.previewWrapper}>
          {html && (
            <iframe
              key={this.state.previewKey}
              data-test="pollPreviewPanel"
              style={{ width: '100%', height: '100%', border: 0 }}
              srcDoc={html}
            />
          )}
        </div>
      </div>
    );
  };

  renderModal() {
    return this.state.showPreview ? (
      <SDSCustomModal
        visible={this.state.showPreview}
        onClose={this.hidePopup}
        footer={
          <>
            <SDSButton type={ButtonType.PRIMARY} onClick={this.hidePopup} data-test="editor.pollPreview.close.button">
              {getMessageFromId('close-button-label')}
            </SDSButton>
            <SDSButton type={ButtonType.PRIMARY} onClick={this.showPopup} data-test="editor.pollPreview.restart.button">
              {getMessageFromId('restart-button-label')}
            </SDSButton>
          </>
        }
      >
        {this.renderPreviewPanel()}
      </SDSCustomModal>
    ) : null;
  }

  render() {
    return (
      <div className={style.editor} data-test="PollEditor">
        <ContentStatusPanel
          status={this.props.contentStatus}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          isReadOnly={this.props.isReadOnly}
          onSave={this.savePoll}
        />
        {this.renderModal()}
        {this.renderEditPane()}
        {this.renderLocalContentModal()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(PollEditor);
