import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { updateEpisodePrompt } from 'state/publisherTools/actions/publisherToolsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as showsSelectors from 'state/shows/selectors/showsSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import BadgesContainer, { BadgesAlignmentType } from 'views/badges/containers/BadgesContainer/BadgesContainer';
import style from 'views/publisherStoryEditor/containers/StoryBuilderBars/StoryBars.scss';

import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import type { Show } from 'types/shows';

type OwnProps = {
  isStoryPromoted: boolean;
};
type StateProps = {
  show: Show | undefined | null;
  storyId: EditionID;
  storyTitle: string | undefined | null;
  isSponsored: boolean | undefined;
  publisherId: PublisherID;
  businessProfileId: string;
  isTitleEditable: boolean;
  isShow: boolean;
  isStoryReadOnlyOrSaving: boolean;
  isEpisodeMetadataEditable: boolean;
};
type DispatchProps = {
  updateEpisodePrompt: typeof updateEpisodePrompt;
};
type Props = StateProps & DispatchProps & OwnProps;
const mapStateToProps = (state: State) => {
  const activeStory = publisherStoryEditorSelectors.getActiveEdition(state);
  const activePublisher = publishersSelectors.getActivePublisherDetails(state);
  const storyId = get(activeStory, 'id');
  const storyTitle = get(activeStory, 'title');
  const isSponsored = get(activeStory, 'isSponsored');
  const publisherId = get(activePublisher, 'id');
  const businessProfileId = get(activePublisher, 'businessProfileId');
  const isShow = publishersSelectors.activePublisherIsShow(state);
  const isTitleEditable = !publisherStoryEditorSelectors.getStoryIsReadOnlyOrSaving(state)(storyId);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
  const episode = episodesSelectors.getEpisodeById(state)(storyId);
  const show = showsSelectors.getShowById(state)(get(episode, 'showId'));
  const isEpisodeMetadataEditable = showsSelectors.canEditEpisodeMetadata(state);
  return {
    businessProfileId,
    show,
    storyId,
    storyTitle,
    isSponsored,
    publisherId,
    isTitleEditable,
    isShow,
    isEpisodeMetadataEditable,
  };
};
const mapDispatchToProps = {
  updateEpisodePrompt,
};
export class StoryBuilderName extends React.Component<Props> {
  handleUpdateEpisodeClick = () => {
    const {
      storyId,
      publisherId,
      businessProfileId,
      isTitleEditable,
      isShow,
      show,
      isEpisodeMetadataEditable,
    } = this.props;
    if (isShow && (isTitleEditable || isEpisodeMetadataEditable)) {
      this.props.updateEpisodePrompt({
        storyId,
        publisherId,
        businessProfileId,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ storyId: number; publisherId: ... Remove this comment to see the full error message
        show,
      });
    }
  };

  render() {
    const { isTitleEditable, isShow, storyTitle, isEpisodeMetadataEditable } = this.props;
    return (
      <div className={style.nameContainer}>
        <div
          onClick={this.handleUpdateEpisodeClick}
          className={classNames((style as any).text, style.storyTitle, {
            [style.clickable]: isShow && (isTitleEditable || isEpisodeMetadataEditable),
          })}
          data-test="title-div"
        >
          {storyTitle}
        </div>
        <div className={style.badgeContainer}>
          <BadgesContainer
            styleOptions={{ direction: BadgesAlignmentType.ROW }}
            editionId={this.props.storyId}
            featuresOptions={{
              isStoryPromoted: this.props.isStoryPromoted,
              isStorySponsored: this.props.isSponsored,
            }}
          />
        </div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryBuilderName);
