import classNames from 'classnames';
import { get, intersection } from 'lodash';
import React from 'react';

import {
  buildStatusToPrimaryMessageId,
  buildStatusToSecondaryMessageId,
  isSnapStatusNotPublishable,
} from 'state/buildStatus/schema/buildStatusHelpers';
import { getDiscoverSnapBuildStatus, getInfoStatuses } from 'state/buildStatus/selectors/buildStatusSelectors';
import { updateSingleAssetEditorState, onTabChangedHandler } from 'state/editor/actions/editorActions';
import { getActiveComponent } from 'state/editor/selectors/componentsSelectors';
import { getActiveEdition, getActiveWholeSnapId } from 'state/editor/selectors/editorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import {
  getSingleAssetActiveTab,
  isSubscribeSnapSelected,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import { getSnapById } from 'state/snaps/selectors/snapsSelectors';

import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import { AlertType } from 'views/common/components/AlertBox/AlertBox';
import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSTabs from 'views/common/components/SDSTabs/SDSTabs';
import type { TabHeader } from 'views/common/components/SDSTabs/SDSTabs';
import simpleBuilderStyle from 'views/dashboard/containers/SimpleStoryBuilder/SimpleStoryBuilder.scss';
import PanelErrorsField from 'views/editor/components/PanelErrorsField/PanelErrorsField';
import EditorPropertyPanels from 'views/editor/containers/EditorPropertyPanels/EditorPropertyPanels';
import SingleAssetSnapsPanel from 'views/singleAssetStoryEditor/containers/SingleAssetSnapsPanel/SingleAssetSnapsPanel';

import style from './ConfigPanel.scss';
import {
  SubscribeSnapTabConfig,
  SubscribeSnapVideoTabConfig,
  SingleSnapBuilderTabConfig,
} from './SingleAssetTabConfig';

import type { ExtendedBuildStatus, InfoStatus } from 'types/build';
import type { EditionID } from 'types/editions';
import { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as ReduxState } from 'types/rootState';
import { ConfigTab } from 'types/singleAssetStoryEditor';
import { SnapType } from 'types/snaps';

type OwnProps = {
  activeStoryId: EditionID;
  activePublisherId: PublisherID;
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: ReduxState, props: OwnProps) => {
  const wholeSnapId = getActiveWholeSnapId(state);
  const activeSnap = wholeSnapId ? getSnapById(state)(wholeSnapId) : null;
  const isActiveSnapVideo = activeSnap?.type === SnapType.VIDEO;
  const adPlacementMode = getActiveEdition(state)?.adPlacementMode;
  const advancedAdsEnabled = getActivePublisherDetails(state)?.advancedAdsEnabled;
  return {
    activeComponent: getActiveComponent(state),
    isActiveSnapVideo,
    isSubscribeSnapSelected: isSubscribeSnapSelected(state),
    activeTab: getSingleAssetActiveTab(state)(props.activeStoryId),
    buildStatus: wholeSnapId ? getDiscoverSnapBuildStatus(state)(wholeSnapId) : null,
    infoStatuses: getInfoStatuses(state)(props.activeStoryId),
    adPlacementMode,
    advancedAdsEnabled,
  };
};

const mapDispatchToProps = {
  onTabChangedHandler,
  updateSingleAssetEditorState,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

export class ConfigPanel extends React.Component<Props> {
  shouldRenderAlertBox = () => {
    return isSnapStatusNotPublishable(this.props.buildStatus);
  };

  renderAlertBox = () => {
    const primaryMessageId = buildStatusToPrimaryMessageId(this.props.buildStatus);
    const secondaryMessageId = buildStatusToSecondaryMessageId(this.props.buildStatus);

    const primaryMessage = primaryMessageId ? getMessageFromId(primaryMessageId) : null;
    const secondaryMessage = secondaryMessageId ? getMessageFromId(secondaryMessageId) : null;

    return primaryMessage ? (
      <div className={style.panelErrors}>
        <PanelErrorsField
          primaryText={primaryMessage}
          secondaryText={secondaryMessage}
          warningLevel={AlertType.ERROR}
        />
      </div>
    ) : null;
  };

  getTabConfig = () => {
    if (this.props.isSubscribeSnapSelected) {
      // if the subscribe snap is a video show subtitles tab
      return this.props.isActiveSnapVideo ? SubscribeSnapVideoTabConfig : SubscribeSnapTabConfig;
    }
    return SingleSnapBuilderTabConfig;
  };

  getPanel = () => {
    const panelWithAutoScrollbar = classNames(
      simpleBuilderStyle.panelContainer,
      simpleBuilderStyle.scrollable,
      style.panelAdjustmentsForTabLayout
    );

    if (this.props.activeTab !== ConfigTab.SNAP) {
      return (
        <div className={simpleBuilderStyle.root}>
          <div className={panelWithAutoScrollbar}>
            <div className={simpleBuilderStyle.scrollbar}>
              {this.shouldRenderAlertBox() ? this.renderAlertBox() : null}
              <EditorPropertyPanels
                component={this.props.activeComponent}
                isSingleAssetStoryEditor
                isSingleSnapBuilder
                isOnAttachmentTab={this.props.activeTab === ConfigTab.ATTACH}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={style.panel}>
        <SingleAssetSnapsPanel
          activeEditionId={this.props.activeStoryId}
          activePublisherId={this.props.activePublisherId}
        />
      </div>
    );
  };

  onTabChange = (tabIndex: number) => {
    const tabValue = Object.keys(this.getTabConfig())[tabIndex];

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    this.props.onTabChangedHandler(this.props.activeStoryId, tabValue);
  };

  renderAlertIcon = (
    errorStatuses: ExtendedBuildStatus[],
    warningStatuses: ExtendedBuildStatus[],
    infoStatuses: InfoStatus[]
  ) => {
    const extendedStatus = get(this.props.buildStatus, 'extendedStatus');

    if (extendedStatus && errorStatuses.includes(extendedStatus)) {
      return <DotStatus status={DotStatusState.ERROR} />;
    }

    if (extendedStatus && warningStatuses.includes(extendedStatus)) {
      return <DotStatus status={DotStatusState.INCOMPLETE} />;
    }

    if (intersection(this.props.infoStatuses, infoStatuses).length > 0) {
      return <DotStatus status={DotStatusState.INFO} />;
    }

    return null;
  };

  renderActiveTabs = (): TabHeader[] => {
    const activeTabs = Object.values(this.getTabConfig());

    return activeTabs.map((tab: any) => {
      const { errorStatuses, warningStatuses, infoStatuses, ...rest } = tab;

      return {
        ...rest,
        dotStatus: this.renderAlertIcon(errorStatuses, warningStatuses, infoStatuses),
      };
    });
  };

  render() {
    const tabIndex = Math.max(0, Object.keys(this.getTabConfig()).indexOf(this.props.activeTab));

    return (
      <div className={style.tabsContainer}>
        <SDSTabs selectedTab={tabIndex} handleTabSelected={this.onTabChange} tabs={this.renderActiveTabs()} />
        {this.getPanel()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(ConfigPanel);
