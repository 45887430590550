// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { CKEDITOR } from 'global';

import SCCaption from '../plugins/SCCaption';
import SCCrossPublisherVideoPastingFilter from '../plugins/SCCrossPublisherVideoPastingFilter';
import SCDragAndDrop from '../plugins/SCDragAndDrop';
import SCEditorAnalytics from '../plugins/SCEditorAnalytics';
import SCHTMLOutputFilter from '../plugins/SCHTMLOutputFilter';
import SCImage from '../plugins/SCImage';
import SCParagraph from '../plugins/SCParagraph';
import SCPlaintextPastingFilter from '../plugins/SCPlaintextPastingFilter';
import SCVideo from '../plugins/SCVideo';
import ElementCreator from '../plugins/utils/ElementCreator';
import PluginsRegistry from '../plugins/utils/PluginsRegistry';

// Base URL that CKEditor itself uses for resolving additional script files, stylesheets etc
export const CKEDITOR_BASEPATH = '/v2/ckeditor/';

// URL used by AsyncCKEditorLoader to download CKEditor from at runtime
export const CKEDITOR_ASYNC_SCRIPT_URL = `${CKEDITOR_BASEPATH}ckeditor.js`;

// CKEditor has different ways of configuring things at different times. Some have to be
// done globally (i.e. as properties on the global CKEDITOR object), others have to be
// done at instantiation time, and others have to be done post-instantiation. This class
// centralises all aspects of configuration so that there's one place to look for anything
// that needs to be configured.
export default class CKEditorConfigurator {
  _actions: any;

  _pluginsRegistry: any;

  _sharedToolbarId: any;

  _store: any;

  constructor({ actions, sharedToolbarId, store, pluginsRegistry = new PluginsRegistry() }: any) {
    this._actions = actions;
    this._store = store;
    this._sharedToolbarId = sharedToolbarId;
    this._pluginsRegistry = pluginsRegistry;
  }

  // Configures properties that have to be set on the global CKEDITOR instance.
  applyGlobalConfig() {
    CKEDITOR.disableAutoInline = true;
    CKEDITOR.config.customConfig = false;
    CKEDITOR.config.stylesSet = false;
    CKEDITOR.config.disableNativeSpellChecker = false;
  }

  // Returns a config object that can be passed to CKEDITOR at instantiation time.
  getInstantiationConfig() {
    this._definePlugins();

    return {
      skin: 'bootstrapck',
      autoParagraph: false,
      coreStyles_bold: { element: 'b', overrides: 'strong' },
      coreStyles_italic: { element: 'i', overrides: 'em' },
      coreStyles_strike: { element: 'strike', overrides: 's' },
      sharedSpaces: this._sharedToolbarId ? { top: this._sharedToolbarId } : null,
      toolbar: this._buildToolbar(),
      extraPlugins: this._stringifyPluginList(),
      removePlugins: 'image',
    };
  }

  _definePlugins() {
    const elementCreator = new ElementCreator();
    const registry = this._pluginsRegistry;
    const actions = this._actions;
    const store = this._store;
    SCImage.define(registry, elementCreator, actions.showModal, store);
    SCVideo.define(registry, elementCreator, actions.showModal, store, actions.openPreview);
    SCCaption.define(registry);
    SCParagraph.define(registry);
    SCDragAndDrop.define(registry, elementCreator);
    SCPlaintextPastingFilter.define(registry);
    SCCrossPublisherVideoPastingFilter.define(registry, actions.loadAssetInfo);
    SCHTMLOutputFilter.define(registry);
    SCEditorAnalytics.define(registry);
  }

  _buildToolbar() {
    // The '-' characters are interpreted as a vertical group separator by CKEditor
    // The '/' characters are interpreted as line breaks by CKEditor

    const fontStyleSection = ['Bold', 'Italic', 'Underline', 'Strike'];
    const justifySection = ['JustifyLeft', 'JustifyCenter', 'JustifyRight'];
    const removeFormat = ['RemoveFormat'];

    const paragraphSection = [SCParagraph.pluginName, 'NumberedList', 'BulletedList'];
    const quoteSection = [SCCaption.pluginName, 'Blockquote'];
    const mediaSection = [SCImage.pluginName, SCVideo.pluginName];

    const lineBreak = '/';

    // When adding new editor plugins, please, check if they are using the style attribute and permit them explicitly
    // as part of the pasteFilter, so copy-reloadpage-paste can work as expected.
    return [
      ['Font'],
      lineBreak,
      ['FontSize'],
      lineBreak,
      ['TextColor'],
      lineBreak,
      fontStyleSection.concat(removeFormat),
      lineBreak,
      paragraphSection.concat(quoteSection),
      lineBreak,
      justifySection.concat(mediaSection),
    ];
  }

  _stringifyPluginList() {
    return this._pluginsRegistry
      .getPluginList()
      .map((plugin: any) => plugin.pluginName)
      .join(',');
  }

  // Configures the supplied CKEditor instance with anything that can only be done
  // after the instance has been created.
  applyPostInstantiationConfig(editor: any) {
    ['p', 'ol', 'ul', 'li', 'h5', 'blockquote'].forEach(tag => {
      editor.dataProcessor.writer.setRules(tag, {
        indent: false,
        breakBeforeOpen: false,
        breakAfterOpen: false,
        breakBeforeClose: false,
        breakAfterClose: false,
      });
    });

    // http://docs.ckeditor.com/#!/api/CKEDITOR.config-cfg-pasteFilter
    // Default property for pasteFilter in Chrome is 'semantic-content', which allows:
    // Known tags (except div, span) with all attributes (except style and class) will be kept.
    // This rule allows div, spans and all tags with style attribute and any value
    // http://docs.ckeditor.com/#!/guide/dev_allowed_content_rules
    // All backend rules can be found here
    // https://github.sc-corp.net/Snapchat/appengine/blob/43b3eb8799d1f5cdc72c0fd32e5471b68af973c5/
    // common/discover-common/src/main/java/snapchat/discover/DiscoverSanitizer.java#L43
    if (editor.pasteFilter) {
      editor.pasteFilter.allow('span; *[style]{font-size, color, text-align}');
    }
  }
}

export { CKEditorConfigurator };
