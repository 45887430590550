import { findSnapIndexInEdition, isAdEnabledAfterSnap } from 'state/editions/schema/editionEntityHelpers';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AdvancedAdsState } from 'config/adsConfig';
import { isInfiniteAdsEnabled } from 'utils/publisherSettings/publisherSettingsUtils';

import { SnapId } from 'types/common';
import { Edition } from 'types/editions';
import { Claim } from 'types/permissions';
import { State } from 'types/rootState';

export const getAdIndexes = (state: any) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);
  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  const stagedIndexes = stagesSelectors.getData(state)(activeEdition.id);
  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  return stagedIndexes ? stagedIndexes.adSnapIndexes : activeEdition.adSnapIndexes;
};

export const getAdState = (state: State) => (edition: Edition | null, snapId: SnapId) => {
  const adSnapIndexes = getAdIndexes(state);
  const activePublisher = publishersSelectors.getActivePublisherDetails(state);
  if (!activePublisher) {
    return AdvancedAdsState.VALID;
  }

  const {
    advancedAdsEnabled,
    advancedAdsMinSlots,
    advancedAdsMaxSlots,
    advancedAdsMinSpacing,
    advancedAdsLatestFirstSlot,
  } = activePublisher;
  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  const thisAdIndex = findSnapIndexInEdition(edition, snapId) + 1;

  if (
    (!advancedAdsEnabled && !isInfiniteAdsEnabled(activePublisher)) ||
    userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_AD_PLACER)
  ) {
    return AdvancedAdsState.VALID;
  }

  if (isAdEnabledAfterSnap(edition, adSnapIndexes, snapId)) {
    for (let i = 0; i < adSnapIndexes.length - 1; i++) {
      if (adSnapIndexes[i + 1] === thisAdIndex || adSnapIndexes[i] === thisAdIndex) {
        if (adSnapIndexes[i + 1] - adSnapIndexes[i] < advancedAdsMinSpacing) {
          return AdvancedAdsState.INVALID_MIN_SPACING;
        }
      }
    }

    if (advancedAdsMaxSlots < adSnapIndexes.length) {
      return AdvancedAdsState.INVALID_MAX_SLOTS;
    }
  } else {
    if (advancedAdsLatestFirstSlot < adSnapIndexes[0] && advancedAdsLatestFirstSlot >= thisAdIndex) {
      return AdvancedAdsState.INVALID_LATEST_FIRST_SLOT;
    }

    if (advancedAdsMinSlots > adSnapIndexes.length) {
      return AdvancedAdsState.INVALID_MIN_SLOTS;
    }
  }

  return AdvancedAdsState.VALID;
};

export const getAdsStateIsInvalid = (state: any) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);
  if (!activeEdition) {
    return false;
  }

  const adIndexStage = stagesSelectors.getData(state)(activeEdition.id);
  return adIndexStage !== null;
};
