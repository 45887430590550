import classNames from 'classnames';
import is from 'is_js';
import React from 'react';
import type { ReactNode } from 'react';

import { CrossOrigin } from 'config/constants';
import { duplicate } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import DeleteButton from 'views/common/components/DeleteButton/DeleteButton';
import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import DownloadButton from 'views/common/components/DownloadButton/DownloadButton';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SelectableItem.scss';

type OwnProps = {
  isReadOnly?: boolean;
  isActive: boolean;
  imageUrl: string | undefined | null;
  placeholderControl: ReactNode | undefined | null;
  description: string | undefined | null;
  index: number;
  className?: string;
  hasDotStatus?: boolean;
  emptyDescriptionMessageId: string;
  deleteMessageId: string;
  downloadMessageId?: string;
  onDeleted: (a: number) => void;
  onDuplicated: (a: number) => void;
  onDownload: (a: number) => void;
  onDoneEditing: () => void;
  onSelected: (a: number) => void;
  enableAdd: boolean;
  enableDuplicate: boolean;
  enableDelete: boolean;
  enableDoneEditing: boolean;
  isFlagged: boolean;
  isDuplicating: boolean;
};

type Props = OwnProps & typeof SelectableItem.defaultProps;

export default class SelectableItem extends React.PureComponent<Props> {
  static defaultProps = {
    isFlagged: false,
    isDuplicating: false,
  };

  getDescriptionMessage() {
    const { description, emptyDescriptionMessageId, index } = this.props;

    return !description || is.empty(description)
      ? getMessageFromId(emptyDescriptionMessageId, { index: index + 1 })
      : description;
  }

  handleDeleteClick = () => {
    const { onDeleted, index } = this.props;
    onDeleted(index);
  };

  handleDuplicateClick = () => {
    const { onDuplicated, index } = this.props;
    onDuplicated(index);
  };

  handleDoneEditingClick = () => {
    const { onDoneEditing } = this.props;
    onDoneEditing();
  };

  handleItemClick = () => {
    const { onSelected, index } = this.props;
    onSelected(index);
  };

  handleDownloadClick = () => {
    const { onDownload, index } = this.props;
    onDownload(index);
  };

  renderDeleteButton() {
    const { deleteMessageId, isReadOnly, enableDelete } = this.props;

    if (!enableDelete) {
      return null;
    }

    return (
      <DeleteButton
        purpose="sidepanel"
        className={style.deleteButton}
        onDelete={this.handleDeleteClick}
        disabled={isReadOnly}
        alertMessage={getMessageFromId(deleteMessageId)}
      />
    );
  }

  renderDownloadButton() {
    const { downloadMessageId } = this.props;
    const downloadLabel = 'download-tile';

    return (
      <DownloadButton
        purpose="download-tile"
        metricButtonType={downloadMessageId ?? downloadLabel}
        className={style.deleteButton}
        onDownload={this.handleDownloadClick}
      />
    );
  }

  renderDuplicateButton() {
    const { isReadOnly, enableDuplicate, isDuplicating } = this.props;

    if (!enableDuplicate) {
      return null;
    }

    return (
      <SDSButton
        className={style.duplicateButton}
        type={ButtonType.SECONDARY}
        shape={ButtonShape.CIRCLE}
        inlineIcon={duplicate}
        disabled={isReadOnly}
        loading={isDuplicating}
        onClick={this.handleDuplicateClick}
        data-test="duplicate.sidepanel"
      />
    );
  }

  renderDoneEditingButton() {
    const { enableDoneEditing } = this.props;

    if (!enableDoneEditing) {
      return this.renderDeleteButton();
    }

    return (
      <SDSButton type={ButtonType.PRIMARY} onClick={this.handleDoneEditingClick} data-test="done-editing.sidepanel">
        {getMessageFromId('done-editing-button-label')}
      </SDSButton>
    );
  }

  renderIncompleteStatus = (isFlagged: boolean) => {
    if (!isFlagged) {
      return null;
    }

    if (this.props.hasDotStatus) {
      return (
        <div className={style.dotStatus}>
          <DotStatus status={DotStatusState.INCOMPLETE} />
        </div>
      );
    }

    return <div className={style.status}>({getMessageFromId('status-incomplete')})</div>;
  };

  render() {
    const { imageUrl, isActive, className, hasDotStatus, placeholderControl } = this.props;

    const isFlagged = (!imageUrl && !placeholderControl) || this.props.isFlagged;
    const buttonToRender = isActive ? this.renderDoneEditingButton() : this.renderDeleteButton();
    const rootClassNames = classNames(
      style.root,
      { [style.active]: isActive, 'active-selectable-item': isActive },
      className
    );

    return (
      <div className={rootClassNames}>
        <div className={style.imageWithText} onClick={this.handleItemClick} data-test="selectableItem.imgText">
          {imageUrl ? (
            <img src={imageUrl} crossOrigin={CrossOrigin.USE_CREDENTIALS} className={style.imagePlaceholder} alt="" />
          ) : (
            <div className={style.imagePlaceholder} data-test="selectableItem.imagePlaceholder">
              {placeholderControl}
            </div>
          )}
          <div
            className={classNames(style.descriptionContainer, { [style.dotStatusContainer]: hasDotStatus })}
            data-test="selectableItem.dotStatusContainer"
          >
            {this.renderIncompleteStatus(isFlagged)}
            <div dir="auto" className={style.description} data-test="selectableItem.description">
              {this.getDescriptionMessage()}
            </div>
          </div>
        </div>
        <div className={style.buttons} data-test="selectableItem.deleteButton">
          {this.renderDownloadButton()}
          {this.renderDuplicateButton()}
          {buttonToRender}
        </div>
      </div>
    );
  }
}
