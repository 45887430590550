import React from 'react';

import { getCloudVisionContentAnnotations, getExplicitContentAnnotations } from 'state/asset/selectors/assetSelectors';

import { intlConnect } from 'utils/connectUtils';

import CloudVisionLabels from './CouldVisionLabels/CouldVisionLabels';
import ExplicitContentAnnotations from './ExplicitContentAnnotations/ExplicitContentAnnotations';

import { AssetID } from 'types/assets';
import { State as ReduxState } from 'types/rootState';

type ExternalProps = {
  assetId: AssetID;
};

type StateProps = ReturnType<typeof mapStateToProps>;

function mapStateToProps(state: ReduxState, props: ExternalProps) {
  return {
    explicitContentAnnotations: getExplicitContentAnnotations(state)(props.assetId),
    cloudVisionContentAnnotations: getCloudVisionContentAnnotations(state)(props.assetId),
  };
}

type Props = ExternalProps & StateProps;

export class MediaAnnotations extends React.PureComponent<Props> {
  render() {
    return (
      <>
        <ExplicitContentAnnotations explicitContentAnnotations={this.props.explicitContentAnnotations} />
        <CloudVisionLabels cloudVisionContentAnnotations={this.props.cloudVisionContentAnnotations} />
      </>
    );
  }
}

export default intlConnect(mapStateToProps, {})(MediaAnnotations);
