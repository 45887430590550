import React from 'react';
import type { ReactNode } from 'react';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';

import style from './TopsnapEditorRow.scss';

type Props = {
  rowDescription: ReactNode | null | Iterable<ReactNode | undefined | null>;
  icon: string;
  'data-test'?: string;
  handleClick?: () => void;
};

export function TopsnapEditorRow(props: Props) {
  const { rowDescription, icon, handleClick } = props;
  const dataTest = props['data-test'];

  return (
    <div className={style.container} onClick={handleClick} data-test={dataTest || 'editor.topsnapEditor.row'}>
      <span
        className={style.descriptionSpan}
        data-test={dataTest ? `${dataTest}.description` : 'editor.topsnapEditor.row.description'}
      >
        {rowDescription}
      </span>
      <SDSButton
        type={ButtonType.WHITE}
        shape={ButtonShape.CIRCLE}
        inlineIcon={icon}
        data-test={dataTest ? `${dataTest}.button` : 'editor.topsnapEditor.row.button'}
      />
    </div>
  );
}

export default TopsnapEditorRow;
