import type { Dispatch } from 'redux';

import { loadAttachments } from 'state/attachments/actions/attachmentsActions';
import { getPollAttachments } from 'state/poll/selectors/pollSelectors';
import { getActivePublisherBusinessProfileId } from 'state/publishers/selectors/publishersSelectors';
import { stageData } from 'state/stages/actions/stagesActions';

import { getClientAttachments } from 'utils/attachmentUtils';

import type { PollID } from 'types/polls';
import type { GetState } from 'types/redux';
import { SnapType } from 'types/snaps';

export function loadPollAttachmentsIfNotLoaded() {
  return (dispatch: Dispatch, getState: GetState): Promise<{}> => {
    const pollAttachments = getPollAttachments(getState());
    const businessProfileId = getActivePublisherBusinessProfileId(getState());

    if (pollAttachments.length === 0) {
      const attachments = getClientAttachments(SnapType.POLL).map((attachment: any) => {
        return {
          businessProfileId,
          id: attachment.id,
          type: SnapType.POLL,
          fileType: attachment.type,
        };
      });

      // @ts-expect-error ts-migrate(2739) FIXME: Type 'AnyAction' is missing the following properti... Remove this comment to see the full error message
      return dispatch(loadAttachments(attachments));
    }
    return Promise.resolve({});
  };
}

export function stagePoll(id: PollID) {
  return (dispatch: Dispatch): Promise<{}> => {
    return (
      Promise.resolve()
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        .then(() => dispatch(loadPollAttachmentsIfNotLoaded()))
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        .then(() => dispatch(stageData(id)))
    );
  };
}
