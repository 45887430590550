import classNames from 'classnames';
import React, { ReactNode } from 'react';

import SDSButton, { ButtonShape, ButtonSize, ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SingleAssetPanel.scss';

export type PanelButtonProps = {
  disabled: boolean;
  onClick: () => void;
  buttonMsg: ReactNode;
  'data-test': string;
};

export type PanelSectionProps = {
  header?: ReactNode;
  description?: ReactNode;
  buttonProps?: PanelButtonProps;
  customProps?: ReactNode;
  cards?: ReactNode[];
};

type OwnProps = {
  sections: PanelSectionProps[];
};

export function SingleAssetPanel({ sections }: OwnProps) {
  const renderButton = (props: PanelButtonProps) => (
    <SDSButton
      size={ButtonSize.MEDIUM}
      type={ButtonType.WHITE}
      shape={ButtonShape.CIRCLE}
      onClick={props.onClick}
      data-test={props['data-test']}
      disabled={props.disabled}
      block
    >
      {props.buttonMsg}
    </SDSButton>
  );

  const renderSection = (
    { header, description, buttonProps, customProps, cards }: PanelSectionProps,
    index: number
  ) => {
    const hasCards = cards !== undefined;
    return (
      <div className={classNames(style.panelSection, { [style.panelSectionWithoutCards]: !hasCards })} key={index}>
        {header && (
          <div className={style.panelHeader} data-test="SingleAssetPanel.panelHeader">
            {header}
          </div>
        )}
        {description && (
          <div className={style.panelDescription} data-test="SingleAssetPanel.panelDescription">
            {description}
          </div>
        )}
        <div className={style.panelContents} data-test="SingleAssetPanel.panelContents">
          {buttonProps && renderButton(buttonProps)}
          {customProps}
        </div>
        {hasCards && (
          <div data-test="SingleAssetPanel.panelCardsContainer" className={style.panelCardsContainer}>
            {cards}
          </div>
        )}
      </div>
    );
  };

  return <div className={style.panelContainer}>{sections.map(renderSection)}</div>;
}
