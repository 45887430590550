import classNames from 'classnames';
import React from 'react';
import type { ReactNode, ReactElement } from 'react';

import Icon from 'views/common/components/Icon/Icon';

import sharedStyle from './ListItemStyles.scss';
import style from './ListItemWithIcon.scss';

type OwnProps = {
  text: ReactNode;
  icon?: string;
  iconImg?: ReactElement<any>;
  iconKey?: string;
  onClick: () => void;
  className?: string;
  optionsButtonPopover?: boolean;
  'data-test'?: string;
};

type Props = OwnProps & typeof ListItemWithIcon.defaultProps;

class ListItemWithIcon extends React.PureComponent<Props> {
  static defaultProps = {
    onClick: () => {},
  };

  render() {
    const { icon, iconKey, iconImg, className, optionsButtonPopover, onClick, text } = this.props;

    const itemClassName = classNames(style.itemWithIcon, className, { [sharedStyle.row]: optionsButtonPopover });
    return (
      <div className={itemClassName} onClick={onClick} data-test={this.props['data-test']}>
        {iconImg ? (
          React.cloneElement(iconImg, { className: classNames(iconImg.props.className, style.iconDefaultSpacing) })
        ) : (
          <Icon icon={iconKey} img={icon} className={style.iconDefaultSpacing} />
        )}
        <span>{text}</span>
      </div>
    );
  }
}

export default ListItemWithIcon;
