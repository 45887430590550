// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import { get } from 'lodash';
import React from 'react';
import type { ChangeEvent } from 'react';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import validator from 'validator';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { isSendToSpotlightEnabled, isSendToNoLocationEnabled } from 'state/features/selectors/featuresSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import { ENV, Environments } from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as uuidUtils from 'utils/uuidUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import TooltipMessageType from 'views/editor/components/TooltipMessages/TooltipMessageType';
import * as TooltipMessages from 'views/editor/components/TooltipMessages/TooltipMessages';

import style from './CameraAttachmentPanel.scss';

import { SendToBehavior } from 'types/cameraAttachment';
import type { CameraAttachmentSnap } from 'types/cameraAttachment';
import type { SnapId } from 'types/common';
import { RichSnapComponentId } from 'types/components';
import type { State as RootState } from 'types/rootState';

const messages = defineMessages({
  placeholderMessage: {
    id: 'camera-lens-input-placeholder',
    defaultMessage: 'eg https://www.snapchat.com/unlock...',
    description: 'Placeholder for new camera lens input',
  },
});
type IntlProps = {};
type ExternalProps = {
  component: {
    componentId: RichSnapComponentId;
  };
};
type StateProps = {
  snapId: SnapId;
  lensScancodeId: string;
  isReadOnly: boolean;
  isSaving: boolean;
  cameraAttachment: CameraAttachmentSnap | undefined | null;
  isValidCameraAttachment: boolean;
  isSendToNoLocationEnabled: boolean;
  isSendToSpotlightEnabled: boolean;
};
type OwnState = {
  currentScancodeUrl: string;
  isLenseUrlInvalid: boolean;
};
type DispatchProps = {
  updateCameraAttachment(id: SnapId, properties: Partial<CameraAttachmentSnap>): void;
};
type Props = ExternalProps & StateProps & DispatchProps & IntlProps;
export const mapStateToProps = (state: RootState, props: ExternalProps): StateProps => {
  const snapId = extractSnapIdFromComponentId(props.component.componentId);
  const cameraAttachment: CameraAttachmentSnap | undefined | null = stagesSelectors.getData(state)(snapId);
  const lensScancodeId = get(cameraAttachment, ['lenses', '0', 'lensScancodeId']) || '';
  const isSaving = editorSelectors.isSaving(state);
  const isReadOnly = editorSelectors.isReadOnly(state);
  const isValidCameraAttachment = editorSelectors.isValidCameraAttachment(state);
  return {
    snapId,
    lensScancodeId,
    isReadOnly,
    isSaving,
    cameraAttachment,
    isValidCameraAttachment,
    isSendToSpotlightEnabled: isSendToSpotlightEnabled(state),
    isSendToNoLocationEnabled: isSendToNoLocationEnabled(state),
  };
};
const mapDispatchToProps = {
  updateCameraAttachment: stagesActions.updateProperties,
};
export class CameraAttachmentPanel extends React.Component<Props, OwnState> {
  static getDerivedStateFromProps(props: Props, state: OwnState) {
    // If we have a saved Camera Attachment and there's no user input
    // then we rebuild the attachment url to populate the input field
    if (state.currentScancodeUrl === '' && props.lensScancodeId && props.lensScancodeId !== '') {
      const strippedUuid = uuidUtils.removeDashesFromUuid(props.lensScancodeId);
      // We don't support dev Snapcodes in Snapcode preview so this is kinda future proofing. Also Note that
      // this isn't an endpoint that Story Studio calls - it's just to populate the input field
      const env = ENV === Environments.DEV ? 'https://devsnapchat.appspot.com/' : 'https://www.snapchat.com/';
      return {
        currentScancodeUrl: `${env}unlock/?type=SNAPCODE&uuid=${strippedUuid}`,
      };
    }
    return null;
  }

  static contextTypes = {
    intl: intlShape,
  };

  state = {
    currentScancodeUrl: '',
    isLenseUrlInvalid: false,
  };

  getFields() {
    const { isReadOnly, isSaving } = this.props;
    return [
      {
        key: 'lenses',
        isSaving,
        control: (
          <SDSInput
            labelTitle={this.renderInputLabel()}
            value={this.state.currentScancodeUrl}
            disabled={isReadOnly}
            onChange={this.handleScancodeIdChanged}
            onBlur={this.validateFieldInput}
            errorMessage={
              this.state.isLenseUrlInvalid && (
                <FormattedMessage
                  id="snapcode-url-warning"
                  description="Warning that the provided snapcode URL is invalid"
                  defaultMessage={
                    'URL is invalid: it must be a Snapcode URL and contain a "uuid" parameter. Please try another URL.'
                  }
                />
              )
            }
            placeholder={this.context.intl.formatMessage(messages.placeholderMessage)}
            data-test="cameraAttachmentPanel.lenseUrlInputBox"
            autoFocus
          />
        ),
      },
    ];
  }

  handleScancodeIdChanged = (event: ChangeEvent<EventTarget>) => {
    const { target } = event;
    const { currentScancodeUrl } = this.state;
    if (target instanceof HTMLInputElement) {
      const newScancodeUrl = target.value;
      if (newScancodeUrl !== currentScancodeUrl) {
        this.setState({ currentScancodeUrl: newScancodeUrl });
        this.handleUpdateCameraAttachment(newScancodeUrl);
      }
    }
  };

  getSendToBehaviour = () => {
    if (this.props.isSendToSpotlightEnabled) {
      return SendToBehavior.SPOTLIGHT;
    }
    if (this.props.isSendToNoLocationEnabled) {
      return SendToBehavior.DEFAULT_SEND_TO;
    }
    return SendToBehavior.OUR_STORY;
  };

  handleUpdateCameraAttachment(fullScancodeUrl: string) {
    const undashedUuid = uuidUtils.stripUuidFromUrl(fullScancodeUrl);
    const lensScancodeId = uuidUtils.addDashesToUuid(undashedUuid);
    this.props.updateCameraAttachment(this.props.snapId, {
      lenses: [
        {
          lensScancodeId,
          scancodeVersion: 1,
        },
      ],
      sendToBehavior: this.getSendToBehaviour(),
    });
  }

  validateFieldInput = () => {
    const value = this.state.currentScancodeUrl;
    const whitespaceTrimmedValue = validator.trim(value);
    const isURL = validator.isURL(whitespaceTrimmedValue);
    const strippedUuid = uuidUtils.stripUuidFromUrl(whitespaceTrimmedValue);
    const isLenseUrlInvalid = value !== '' && (!isURL || strippedUuid === '');
    this.setState({ isLenseUrlInvalid });
  };

  renderInputLabel = () => {
    return (
      <div className={style.labelContainer}>
        <FormattedMessage
          id="camera-attachment-panel-lens-url"
          description="URL for the lens to attch"
          defaultMessage="Your Lens URL"
        />
        <SDSTooltip
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.TOP}
          title={TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_CAMERA)}
          id="lens-url-info-tooltip"
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    );
  };

  render() {
    const title = (
      <FormattedMessage id="camera-attachment-general-panel-title" description="General" defaultMessage="General" />
    );
    return (
      <div>
        <div>
          <PropertyPanel
            fields={this.getFields()}
            title={title}
            isReadOnly={this.props.isSaving}
            className={(style as any).cameraAttachmentInput}
            horizontal
            titleCaseLabels
          />
        </div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(CameraAttachmentPanel);
