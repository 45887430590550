import log from 'loglevel';
import * as React from 'react';
import type { ReactNode } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import type {
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"react-dnd"' has no exported member 'Decor... Remove this comment to see the full error message
  DecoratedComponent,
  DragSourceMonitor,
  DropTargetMonitor,
  ConnectDragSource,
  ConnectDragPreview,
  ConnectDropTarget,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import reactDOM from 'react-dom';

import { isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';

import SegmentCarouselItem from '../SegmentCarouselItem/SegmentCarouselItem';
import type { SegmentNode, SnapNode, DomElement, SourceItem } from '../StoryCarousel/StoryCarouselTypes';
import { DragIndex } from '../StoryCarousel/StoryCarouselTypes';
import {
  calculateSegmentHoverSnapIndex,
  HoverArea,
  getDragHoverArea,
  MovementDirection,
  getDragMovementDirection,
  calculatePercentOfSegmentMousePosition,
  sourceItemIsRealSegment,
} from '../StoryCarousel/StoryCarouselUtils';

import { DragTypes } from 'config/constants';
import * as browserUtils from 'utils/browserUtils';

import DraggableSnapCarouselItem from 'views/publisherStoryEditor/containers/DraggableSnapCarouselItem/DraggableSnapCarouselItem';
import SnapCarouselItem from 'views/publisherStoryEditor/containers/SnapCarouselItem/SnapCarouselItem';

import type { BuildStatusType } from 'types/build';
import type { SnapId } from 'types/common';
import type { Edition } from 'types/editions';

type Props = {
  segment: SegmentNode;
  segmentNumber: number | undefined | null;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  connectDragPreview: ConnectDragPreview;
  sourceItem: SourceItem | undefined | null;
  setItemIndex: (c: number[], b: number[], a: (d: number[]) => void) => void;
  endDrag: () => void;
  beginDrag: () => void;
  canDrag: () => boolean;
  isReadOnly: boolean;
  isAnySnapBeingDragged: boolean;
  saveSegmentDomElement: (b: SegmentNode, a: DomElement) => void;
  saveSnapDomElement: (b: SnapNode, a: DomElement) => void;
  animationDuration: number;
  isEditionSegmentsEnabled: boolean;
  addSnap: (index: number) => void;
  edition: Edition;
  buildStatuses: (id: SnapId) => BuildStatusType | undefined | null;
  convertSingleSnapSegment: (segmentNumber: number, toReal: boolean) => void;
};
export const segmentSource = {
  beginDrag(props: Props): SourceItem {
    // Errors can't propagate or drag-and-drop will be broken forever
    try {
      props.beginDrag();
    } catch (e) {
      log.error('DraggableSegmentCarouselItem error while begin dragging:', e);
    }
    return {
      segment: props.segment,
      index: new DragIndex(props.segment.index),
      lastClientXOffset: null,
    };
  },
  canDrag(props: Props) {
    // A carousel item is not draggable in the follow conditions:
    // - it's the subscribe snap
    // - the canDrag callback is false (e.g. if the edition is saving
    // - the segment contains 1 snap that has had its content deleted, if a segment contains more
    //   than 1 snap then we allow dragging the entire segment by dragging on the deleted snap
    return (
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      !isSubscribeSnap(props.segment.snapNodes[0].snap) &&
      props.canDrag() &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      !(props.segment.snapNodes.length === 1 && props.segment.snapNodes[0].snap.isContentDeleted)
    );
  },
  // We consider the item to be the dragged one if their ids match.
  // This is handy because the original dragged element may have been remounted
  // in another place and we need to know whether to render it as placeholder
  isDragging(props: Props, monitor: DragSourceMonitor<SourceItem>): boolean {
    const sourceItem: SourceItem = monitor.getItem();
    return (sourceItem.segment && sourceItem.segment.id === props.segment.id) || false;
  },
  endDrag(props: Props) {
    // Scheduling to next frame to free the event handler and improve responsiveness
    browserUtils.requestAnimationFrame(() => {
      // Errors can't propagate or drag-and-drop will be broken forever
      try {
        props.endDrag();
      } catch (e) {
        log.error('DraggableSegmentCarouselItem error while end dragging:', e);
      }
    });
  },
};
const setDraggingIndex = (component: DecoratedComponent, draggingIndex?: DragIndex | null): void => {
  // We might get a decorated component or a non-decorated component
  if (component && typeof component.getDecoratedComponentInstance === 'function') {
    component.getDecoratedComponentInstance().setState({ draggingIndex });
  } else if (component && typeof component.setState === 'function') {
    component.setState({ draggingIndex });
  }
};
export const segmentTarget = {
  drop(props: Props, monitor: DropTargetMonitor<SourceItem>, component: DecoratedComponent) {
    setDraggingIndex(component, null);
  },
  hover(props: Props, monitor: DropTargetMonitor<SourceItem>, component: DecoratedComponent) {
    if (!segmentSource.canDrag(props)) {
      return;
    }
    const sourceItem: SourceItem = monitor.getItem();
    // Does not trigger calculation if last drag event was very close, as these events have a high frequency
    // and this operation can be costly
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const clientXOffset: number = monitor.getClientOffset().x;
    const isFirstHover: boolean = sourceItem.lastClientXOffset === null;
    if (sourceItem.lastClientXOffset && Math.abs(sourceItem.lastClientXOffset - clientXOffset) < 3) {
      return;
    }
    sourceItem.lastClientXOffset = clientXOffset; // eslint-disable-line no-param-reassign
    const dragIndex: DragIndex = sourceItem.index;
    const hoverSegmentIndex: number = props.segment.index;
    const repositionIndex: DragIndex = new DragIndex(hoverSegmentIndex);
    const domElement = reactDOM.findDOMNode(component);
    if (!domElement || typeof (domElement as any).getBoundingClientRect !== 'function') {
      return;
    }
    const elementBoundingRect: {
      right: number;
      left: number;
    } = (domElement as any).getBoundingClientRect();
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$Enum'.
    const hoverArea: $Enum<typeof HoverArea> = getDragHoverArea(elementBoundingRect, clientXOffset);
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$Enum'.
    const movementDirection: $Enum<typeof MovementDirection> = getDragMovementDirection(
      dragIndex.getSegmentIndex(),
      hoverSegmentIndex
    );
    // If other element is the source, allow some area at beginning and end of node to trigger
    // movement to before and after this element respectively
    if (!dragIndex.isSameSegment(hoverSegmentIndex) || dragIndex.isSnapIndex()) {
      if (hoverArea === HoverArea.END) {
        repositionIndex.setSegmentIndex(hoverSegmentIndex + 1);
      }
    }
    // Don't add segment to itself (i.e., from [0] to [0, 1])
    if (dragIndex.isSegmentIndex() && dragIndex.getSegmentIndex() === repositionIndex.getSegmentIndex()) {
      if (isFirstHover) {
        setDraggingIndex(component, dragIndex);
      }
      return;
    }
    // If edition segments is not enabled, does not allow moving at snap level.
    // This will in effect disable creating new segments and altering existing segments.
    const isMovingAtSnapLevel: boolean =
      props.isEditionSegmentsEnabled && hoverArea === HoverArea.MIDDLE && !sourceItemIsRealSegment(sourceItem);
    // If dragging into this segment and source segment only has one snap, must calculate
    // the internal snap index to position it
    if (isMovingAtSnapLevel && repositionIndex.isSameSegment(hoverSegmentIndex)) {
      const movingFromSameSegment = dragIndex.getSegmentIndex() === repositionIndex.getSegmentIndex();
      // From this point we must calculate if it's being dragged to inside this segment
      const snapIndex: number = calculateSegmentHoverSnapIndex(
        movingFromSameSegment ? dragIndex.getSnapIndex() : null, // current snap index
        props.segment.snapNodes.length,
        elementBoundingRect,
        clientXOffset
      );
      repositionIndex.setSnapIndex(snapIndex);
    }
    // If moving at segment level only move if past half of segment size
    if (
      !isMovingAtSnapLevel &&
      dragIndex.isSegmentIndex() &&
      repositionIndex.isSegmentIndex() &&
      repositionIndex.isSameSegment(hoverSegmentIndex)
    ) {
      const positionInSegmentPercent: number = calculatePercentOfSegmentMousePosition(
        elementBoundingRect,
        clientXOffset
      );
      if (movementDirection === MovementDirection.RIGHT && positionInSegmentPercent < 0.5) {
        repositionIndex.setSegmentIndex(repositionIndex.getSegmentIndex() - 1);
      } else if (movementDirection === MovementDirection.LEFT && positionInSegmentPercent >= 0.5) {
        repositionIndex.setSegmentIndex(repositionIndex.getSegmentIndex() + 1);
      }
    }
    // If moving one segment right have to adjust if current hovered element will change position
    if (
      movementDirection === MovementDirection.RIGHT &&
      dragIndex.isSegmentIndex() &&
      repositionIndex.isSegmentIndex() &&
      repositionIndex.getSegmentIndex() - hoverSegmentIndex > 0
    ) {
      repositionIndex.setSegmentIndex(repositionIndex.getSegmentIndex() - 1);
    }
    // Don't replace segments or snaps with themselves
    if (dragIndex.isEqual(repositionIndex)) {
      if (isFirstHover) {
        setDraggingIndex(component, dragIndex);
      }
      return;
    }
    props.setItemIndex(dragIndex.toArray(), repositionIndex.toArray(), actualNewIndex => {
      monitor.getItem().index = DragIndex.fromArray(actualNewIndex); // eslint-disable-line no-param-reassign
      // At this point props could be out of date so we get the segment index directly from the component
      if (typeof component.setState === 'function') {
        const updatedSegmentIndex = component.props.segment.index;
        const snapIndexInThisSegment = monitor.getItem().index.isSameSegment(updatedSegmentIndex)
          ? monitor.getItem().index.clone()
          : null;
        setDraggingIndex(component, snapIndexInThisSegment);
      }
    });
  },
};
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
const collectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  sourceItem: monitor.getItem(),
});
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
});
type State = {
  draggingIndex: DragIndex | undefined | null;
};
export class DraggableSegmentCarouselItem extends React.PureComponent<Props, State> {
  state: State = {
    draggingIndex: null,
  };

  componentDidMount() {
    // Disabling the preview since it is handled by StoryCarouselDragLayer
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // The dragging index is reset on segmentTarget.drop but there are some edge cases
    // when segmentTarget.drop is not called that need to be treated
    if (this.state.draggingIndex) {
      // If the number of snaps is less that before then a snap was
      // hovered away from this segment but not dropped yet
      if (nextProps.segment.snapNodes.length < this.props.segment.snapNodes.length) {
        this.setState({ draggingIndex: null });
      }
      // If transitioning from dragging to not dragging the drop might
      // have happened outside this container
      if (this.props.isAnySnapBeingDragged && !nextProps.isAnySnapBeingDragged) {
        this.setState({ draggingIndex: null });
      }
    }
  }

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'React$ElementRef'.
  saveSegmentElement = (element?: React$ElementRef<any> | null) => {
    this.props.saveSegmentDomElement(this.props.segment, reactDOM.findDOMNode(element));
  };

  isDragging = (): boolean => {
    const { sourceItem } = this.props;
    if (!sourceItem) {
      return false;
    }
    const { segment } = this.props;
    const sameSegment = Boolean(sourceItem.segment && sourceItem.segment.id === segment.id);
    const sameSnap = Boolean(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      segment.snapNodes.length === 1 && sourceItem.snap && sourceItem.snap.id === segment.snapNodes[0].id
    );
    return sameSegment || sameSnap;
  };

  renderDraggableSnap = (snapProps: any, node: SnapNode, className?: string | null): ReactNode => {
    let { isPlaceholder } = snapProps;
    // If the snap being dragged is this one, should render as placeholder
    const segmentIndex: number | undefined | null =
      this.state.draggingIndex && this.state.draggingIndex.getSegmentIndex();
    const snapIndex: number | undefined | null = this.state.draggingIndex && this.state.draggingIndex.getSnapIndex();
    if (
      typeof snapIndex === 'number' &&
      typeof segmentIndex === 'number' &&
      segmentIndex === this.props.segment.index &&
      snapIndex < this.props.segment.snapNodes.length &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.props.segment.snapNodes[snapIndex].id === node.id
    ) {
      isPlaceholder = true;
    }
    if (this.props.segment.snapNodes.length === 1) {
      return (
        <SnapCarouselItem
          saveSnapDomElement={snapProps.saveSnapDomElement}
          style={snapProps.snapStyle}
          className={className}
          snap={node.snap}
          snapNode={node}
          isPlaceholder={isPlaceholder}
          isAnySnapBeingDragged={snapProps.isAnySnapBeingDragged}
          buildStatus={this.props.buildStatuses(node.snap.id)}
        />
      );
    }
    return (
      <DraggableSnapCarouselItem
        segmentIndex={this.props.segment.index}
        snapsInSegment={this.props.segment.snapNodes.length}
        saveSnapDomElement={snapProps.saveSnapDomElement}
        style={snapProps.snapStyle}
        key={node.key}
        endDrag={this.props.endDrag}
        beginDrag={this.props.beginDrag}
        canDrag={this.props.canDrag}
        className={className}
        snap={node.snap}
        snapNode={node}
        isPlaceholder={isPlaceholder}
        isAnySnapBeingDragged={snapProps.isAnySnapBeingDragged}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'BuildStatusType | null | undefined' is not a... Remove this comment to see the full error message
        buildStatus={this.props.buildStatuses(node.snap.id)}
      />
    );
  };

  render() {
    const {
      segment,
      connectDragSource,
      connectDropTarget,
      isAnySnapBeingDragged,
      isReadOnly,
      segmentNumber,
    } = this.props;
    return (
      <SegmentCarouselItem
        draggingIndex={this.state.draggingIndex} // This is only used to trigger re-rendering
        snapCarouselItemFactory={this.renderDraggableSnap}
        ref={this.saveSegmentElement}
        saveSnapDomElement={this.props.saveSnapDomElement}
        segment={segment}
        segmentNumber={segmentNumber}
        isReadOnly={isReadOnly}
        isPlaceholder={this.isDragging()}
        connectDragSource={connectDragSource}
        connectDropTarget={connectDropTarget}
        isAnySnapBeingDragged={isAnySnapBeingDragged}
        animationDuration={this.props.animationDuration}
        addSnap={this.props.addSnap}
        edition={this.props.edition}
        convertSingleSnapSegment={this.props.convertSingleSnapSegment}
      />
    );
  }
}
export default DropTarget(
  [DragTypes.STORY_SEGMENT, DragTypes.STORY_SNAP],
  segmentTarget,
  collectTarget
)(
  // eslint-disable-line new-cap
  DragSource(DragTypes.STORY_SEGMENT, segmentSource, collectSource)(DraggableSegmentCarouselItem) // eslint-disable-line new-cap
);
