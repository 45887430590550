// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import _ from 'lodash';
import { defineMessages } from 'react-intl';

import { isRemoteWebEnabled, isPollAttachmentsEnabled } from 'state/features/selectors/featuresSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { SharingMethod, UrlType } from 'config/constants';
import { State } from 'src/types/rootState';

import TooltipMessageType from 'views/editor/components/TooltipMessages/TooltipMessageType';
import * as TooltipMessages from 'views/editor/components/TooltipMessages/TooltipMessages';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-appi... Remove this comment to see the full error message
import appInstallIcon from 'images/attachments/canvas-appinstall.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-arti... Remove this comment to see the full error message
import articleIcon from 'images/attachments/canvas-article.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-pers... Remove this comment to see the full error message
import personalityQuizIcon from 'images/attachments/canvas-personality.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-poll... Remove this comment to see the full error message
import pollingIcon from 'images/attachments/canvas-poll.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-quiz... Remove this comment to see the full error message
import quizIcon from 'images/attachments/canvas-quiz.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-remo... Remove this comment to see the full error message
import remoteWebIcon from 'images/attachments/canvas-remoteweb.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-vide... Remove this comment to see the full error message
import longformVideoIcon from 'images/attachments/canvas-video.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/attachments/canvas-vote... Remove this comment to see the full error message
import votingIcon from 'images/attachments/canvas-vote.svg.inline';

import ArticleEditor from './containers/ArticleEditor/ArticleEditor';
import CameosEditor from './containers/CameosEditor/CameosEditor';
import CameraAttachmentEditor from './containers/CameraAttachmentEditor/CameraAttachmentEditor';
import ArticlePanel from './containers/EditorPropertyPanels/Panels/ArticlePanel';
import BitmojiRemoteVideoPanel from './containers/EditorPropertyPanels/Panels/BitmojiRemoteVideoPanel';
import CameoPanel from './containers/EditorPropertyPanels/Panels/CameoPanel';
import CameraAttachmentPanel from './containers/EditorPropertyPanels/Panels/CameraAttachmentPanel';
import LongformVideoPanel from './containers/EditorPropertyPanels/Panels/LongformVideoPanel';
import PollPanel from './containers/EditorPropertyPanels/Panels/PollPanel';
import RemoteWebPanel from './containers/EditorPropertyPanels/Panels/RemoteWebPanel';
import TopsnapPanel from './containers/EditorPropertyPanels/Panels/TopsnapPanel';
import SingleAssetTopsnapPanel from './containers/EditorPropertyPanels/Panels/topSnapPanels/SingleAssetTopsnapPanel/SingleAssetTopsnapPanel';
import LongformVideoEditor from './containers/LongformVideoEditor/LongformVideoEditor';
import PollEditor from './containers/PollEditor/PollEditor';
import RemoteWebEditor from './containers/RemoteWebEditor/RemoteWebEditor';
import TopsnapEditor from './containers/TopsnapEditor/TopsnapEditor';
import VideoLibraryPanel from './containers/VideoLibraryPanel/VideoLibraryPanel';

import { AttachmentOptions, AttachmentSnapProperties } from 'types/attachments';
import { Claim } from 'types/permissions';
import { Snap, SnapType as RichSnapType } from 'types/snaps';

const attachmentNames = defineMessages({
  longformVideo: {
    id: 'longform-video-bottom-snap',
    description: 'Bottom snap type Video',
    defaultMessage: 'Long Form Video',
  },
  webview: {
    id: 'webview-bottom-snap',
    description: 'Bottom snap type Webview',
    defaultMessage: 'Web View',
  },
  bitmojiWebview: {
    id: 'bitmoji-webview-bottom-snap',
    description: 'Bottom snap type Bitmoji Webview',
    defaultMessage: 'Bitmoji Web View',
  },
  camera: {
    id: 'camera-bottom-snap',
    description: 'Bottom snap type Camera',
    defaultMessage: 'Camera',
  },
  article: {
    id: 'article-bottom-snap',
    description: 'Bottom snap type Article',
    defaultMessage: 'Article',
  },
  vote: {
    id: 'vote-bottom-snap',
    description: 'Bottom snap type Vote',
    defaultMessage: 'Vote',
  },
  opinionPoll: {
    id: 'opinion-poll-bottom-snap',
    description: 'Bottom snap type Opinion Poll',
    defaultMessage: 'Opinion Poll',
  },
  triviaQuizQuestion: {
    id: 'trivia-quiz-bottom-snap',
    description:
      'Trivia Quiz is a new attachment that will allow publishers to ask a question to their users' +
      ', that has right or wrong answer. Example: What color is the sky? a: Blue, b: Green',
    defaultMessage: 'Trivia Quiz',
  },
  personalityQuizQuestion: {
    id: 'personality-quiz-bottom-snap',
    description:
      'Personality Quiz is  a new attachment that will allow publishers to ask a question to their users ' +
      'that has no right or wrong answer. Example: Choose a Puppy and we will tell you what StarWars character you are.',
    defaultMessage: 'Personality Quiz',
  },
  notification: {
    id: 'notification-bottom-snap',
    description: 'Bottom snap type Notifications',
    defaultMessage: 'Notifications',
  },
});

type SnapType = {
  editor: any;
  propertyPanels: any[];
  attachmentOptions?: AttachmentOptions | AttachmentOptions[];
  // Properties to match on to determine attachment type if the attachmentOptions is a list.
  matchProperties?: [keyof AttachmentSnapProperties];
};

const allSnapTypes: { [key in RichSnapType]: SnapType } = {
  [RichSnapType.UNKNOWN]: {
    editor: TopsnapEditor,
    propertyPanels: [TopsnapPanel],
  },
  [RichSnapType.IMAGE]: {
    editor: TopsnapEditor,
    propertyPanels: [TopsnapPanel],
  },

  [RichSnapType.VIDEO]: {
    editor: TopsnapEditor,
    propertyPanels: [TopsnapPanel],
  },

  [RichSnapType.SINGLE_ASSET]: {
    editor: TopsnapEditor,
    propertyPanels: [SingleAssetTopsnapPanel],
  },

  [RichSnapType.LONGFORM_VIDEO]: {
    editor: LongformVideoEditor,
    propertyPanels: [LongformVideoPanel, VideoLibraryPanel],
    attachmentOptions: {
      index: 1,
      name: attachmentNames.longformVideo,
      icon: longformVideoIcon,
      tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_LONGFORM_VIDEO),
      snapType: RichSnapType.LONGFORM_VIDEO,
      title: 'attachment-long-form-video',
      enabled: (state: State) => userSelectors.hasClaimForActivePublisher(state, Claim.LONGFORM_VIDEO_EDITOR),
    },
  },

  [RichSnapType.REMOTE_WEB]: {
    editor: RemoteWebEditor,
    propertyPanels: [RemoteWebPanel],
    matchProperties: ['urlType'],
    attachmentOptions: [
      {
        index: 6,
        name: attachmentNames.webview,
        icon: remoteWebIcon,
        tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_REMOTE_WEB),
        snapType: RichSnapType.REMOTE_WEB,
        snapProperties: {
          allowExternalSharing: false,
          sharingMethod: SharingMethod.DEEP_LINK_TOP_SNAP,
          urlType: UrlType.STANDARD,
        },
        title: 'attachment-web-view',
        enabled: (state: State) => isRemoteWebEnabled(state),
      },
    ],
  },

  [RichSnapType.BITMOJI_REMOTE_WEB]: {
    editor: RemoteWebEditor,
    propertyPanels: [RemoteWebPanel],
  },

  [RichSnapType.CAMEOS_CONTENT]: {
    editor: CameosEditor,
    propertyPanels: [CameoPanel],
  },

  [RichSnapType.CAMERA_ATTACHMENT]: {
    editor: CameraAttachmentEditor,
    propertyPanels: [CameraAttachmentPanel],
    attachmentOptions: {
      index: 10,
      name: attachmentNames.camera,
      // TODO (kim): get camera icon
      icon: appInstallIcon,
      tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_CAMERA),
      snapType: RichSnapType.CAMERA_ATTACHMENT,
      title: 'attachment-camera',
      enabled: (state: State) => userSelectors.hasClaimForActivePublisher(state, Claim.CAMERA_ATTACHMENT_EDITOR),
    },
  },

  [RichSnapType.BITMOJI_REMOTE_VIDEO]: {
    editor: TopsnapEditor, // Placeholder editor for now
    propertyPanels: [BitmojiRemoteVideoPanel],
  },
  [RichSnapType.ARTICLE]: {
    editor: ArticleEditor,
    propertyPanels: [ArticlePanel],
    attachmentOptions: {
      index: 0,
      name: attachmentNames.article,
      icon: articleIcon,
      tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_LONGFORM_VIDEO),
      snapType: RichSnapType.ARTICLE,
      title: 'attachment-article',
      enabled: (state: State) => userSelectors.hasClaimForActivePublisher(state, Claim.ARTICLE_EDITOR),
    },
  },

  [RichSnapType.POLL]: {
    editor: PollEditor,
    propertyPanels: [PollPanel],
    matchProperties: ['pollType'],
    attachmentOptions: [
      {
        index: 2,
        name: attachmentNames.vote,
        icon: votingIcon,
        tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_VOTE),
        snapType: RichSnapType.POLL,
        snapProperties: { pollType: POLL_TYPE.VOTE },
        title: 'attachment-vote',
        enabled: (state: State) =>
          isPollAttachmentsEnabled(state) && userSelectors.hasClaimForActivePublisher(state, Claim.VOTE_EDITOR),
      },
      {
        index: 3,
        name: attachmentNames.opinionPoll,
        icon: pollingIcon,
        tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_POLL),
        snapType: RichSnapType.POLL,
        snapProperties: { pollType: POLL_TYPE.POLL },
        title: 'attachment-opinion-poll',
        enabled: (state: State) =>
          isPollAttachmentsEnabled(state) && userSelectors.hasClaimForActivePublisher(state, Claim.OPINION_POLL_EDITOR),
      },
      {
        index: 4,
        name: attachmentNames.triviaQuizQuestion,
        icon: quizIcon,
        tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_POLL), // TODO
        snapType: RichSnapType.POLL,
        snapProperties: { pollType: POLL_TYPE.FACTUAL_QUESTION },
        title: 'attachment-trivia-quiz',
        enabled: (state: State) =>
          isPollAttachmentsEnabled(state) && userSelectors.hasClaimForActivePublisher(state, Claim.TRIVIA_QUIZ_EDITOR),
      },
      {
        index: 5,
        name: attachmentNames.personalityQuizQuestion,
        icon: personalityQuizIcon,
        tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.ATTACHMENT_POLL), // TODO
        snapType: RichSnapType.POLL,
        snapProperties: { pollType: POLL_TYPE.OPEN_QUESTION },
        title: 'attachment-personality-quiz',
        enabled: (state: State) =>
          isPollAttachmentsEnabled(state) &&
          userSelectors.hasClaimForActivePublisher(state, Claim.PERSONALITY_QUIZ_EDITOR),
      },
    ],
  },
  [RichSnapType.SUBSCRIBE]: {
    editor: null,
    propertyPanels: [],
  },
};

const allAttachmentOptions = _.flatten(Object.values(allSnapTypes).map(entry => entry.attachmentOptions))
  .filter(attachmentOptions => attachmentOptions !== undefined)
  .sort(
    (attachmentOptionsA, attachmentOptionsB) =>
      (attachmentOptionsA as AttachmentOptions).index - (attachmentOptionsB as AttachmentOptions).index
  ) as AttachmentOptions[];

export const getRichEditorConfigForSnapType = (type?: RichSnapType) => {
  if (!type || !(type in allSnapTypes)) {
    throw new Error(`No RichEditor config found for snap type ${type}`);
  }
  return allSnapTypes[type];
};

export const getAllSnapTypes = () => {
  return allSnapTypes;
};

export const getAllAttachmentOptions = (): AttachmentOptions[] => {
  return allAttachmentOptions;
};

export const getAttachmentOptionForSnap = (snap?: Snap): AttachmentOptions | undefined => {
  if (!snap) {
    return undefined;
  }

  const snapConfig = getRichEditorConfigForSnapType(snap.type);
  if (!snapConfig || !snapConfig.attachmentOptions) {
    return undefined;
  }

  return Array.isArray(snapConfig.attachmentOptions)
    ? findAttachmentOptionWithMatchingSnapProperties(snap, snapConfig.attachmentOptions, snapConfig.matchProperties)
    : snapConfig.attachmentOptions;
};

function findAttachmentOptionWithMatchingSnapProperties(
  snap: Snap,
  attachmentOptions: AttachmentOptions[],
  matchProperties?: [keyof AttachmentSnapProperties]
) {
  const matchingOption = attachmentOptions.find((option: AttachmentOptions) => {
    if (!option.snapProperties) {
      throw new Error('snapProperties must be defined in order to find matching attachment options');
    }

    const propertiesToMatch = matchProperties ? _.pick(option.snapProperties, matchProperties) : option.snapProperties;
    return _.isMatch(snap, propertiesToMatch);
  });

  if (!matchingOption) {
    throw new Error('Could not find a matching attachment option');
  }

  return matchingOption;
}
