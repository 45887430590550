import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import {
  findSnapIndexInEdition,
  isAdEnabledAfterSnap,
  isLastNonSubscribeSnapInEdition,
} from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as adControlsStagesActions from 'state/stages/adControls/actions/adControlsStagesActions';
import * as adControlsStagesSelectors from 'state/stages/adControls/selectors/adControlsStagesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AdvancedAdsState } from 'config/adsConfig';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { InfoContext, showInfoMessage } from 'utils/errors/infoMessage/infoMessageUtils';
import * as gaUtils from 'utils/gaUtils';

import ListItemWithIcon from 'views/common/components/ListItem/ListItemWithIcon';
import ListItemWithToggle from 'views/common/components/ListItem/ListItemWithToggle';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { SnapMenuItemConfig, SnapMenuItemIds } from 'views/publisherStoryEditor/utils/SnapMenuItemConfig';

import style from './SnapItemMenuAdControls.scss';

import { Edition } from 'types/editions';
import { Claim } from 'types/permissions';
import { SnapCommon } from 'types/snaps';

export const INVALID_AD_TOOLTIP_CONFIG = {
  [AdvancedAdsState.INVALID_LATEST_FIRST_SLOT]: (
    <FormattedMessage
      id="invalid-ad-latest-first-slot"
      description="Error tooltip when ad is placed too late in the story"
      defaultMessage="Ad not placed early enough"
    />
  ),
  [AdvancedAdsState.INVALID_MIN_SPACING]: (
    <FormattedMessage
      id="invalid-ad-min-spacing"
      description="Error tooltip when ad is placed too close to another"
      defaultMessage="Ad placed too close to another ad"
    />
  ),
  [AdvancedAdsState.INVALID_MIN_SLOTS]: (
    <FormattedMessage
      id="invalid-ad-min-slots"
      description="Error tooltip when there aren't enough ads"
      defaultMessage="Insufficient number of ads"
    />
  ),
  [AdvancedAdsState.INVALID_MAX_SLOTS]: (
    <FormattedMessage
      id="invalid-ad-max-slots"
      description="Error tooltip when there are too many ads"
      defaultMessage="Too many ads"
    />
  ),
};

const mapStateToProps = (state: State, ownProps: any) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);

  return {
    isAdPlacer: userSelectors.hasClaimForActivePublisher(state, Claim.AD_PLACER),
    isSensitive: getDiscoverSnapBuildStatus(state)(ownProps.snap.id)?.adsSensitive || false,
    isSuperAdPlacer: userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_AD_PLACER),
    activeEdition,
    isActiveEditionReadOnly: publisherStoryEditorSelectors.getActiveEditionIsReadOnly(state),
    editionHasSubscribeSnap: activeEdition ? editionsSelectors.getEditionHasSubscribeSnap(state)(activeEdition) : false,
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    adIndexes: adControlsStagesSelectors.getAdIndexes(state),
    adState: adControlsStagesSelectors.getAdState(state)(activeEdition, ownProps.snap.id),
  };
};

const mapDispatchToProps = {
  toggleAdEnabledAndMaybeSave: adControlsStagesActions.toggleAdEnabledAndMaybeSave,
  maybeClearAdsStage: adControlsStagesActions.maybeClearAdsStage,
  showInfoMessage,
};

type SnapItemMenuAdControlsProps = {
  snap: SnapCommon;
  isAdPlacer: boolean;
  isSensitive: boolean;
  isSuperAdPlacer: boolean;
  activeEdition: Edition;
  activePublisher?: any;
  adIndexes: number[];
  toggleAdEnabledAndMaybeSave: typeof adControlsStagesActions.toggleAdEnabledAndMaybeSave;
  adState?: string;
  maybeClearAdsStage: typeof adControlsStagesActions.maybeClearAdsStage;
  isActiveEditionReadOnly: boolean;
  editionHasSubscribeSnap: boolean;
  disabled: boolean;
  showInfoMessage: typeof showInfoMessage;
};

export class SnapItemMenuAdControls extends React.Component<SnapItemMenuAdControlsProps> {
  componentWillUnmount() {
    this.props.maybeClearAdsStage(this.props.activeEdition);
  }

  handleAdOptionsChange = (value: boolean) => {
    if (this.props.isSensitive) {
      this.props.showInfoMessage(InfoContext.ERROR_SENSITIVE_SNAP);
      return;
    }
    const snapIndex = findSnapIndexInEdition(this.props.activeEdition, this.props.snap.id);
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-ad-after-snap', {
      from: !value,
      to: value,
      index: snapIndex,
    });

    this.props.toggleAdEnabledAndMaybeSave(value, snapIndex);
  };

  maybeWrapInTooltipTrigger(toggle: any, isAdInvalid: any, invalidAdType: any) {
    if (!isAdInvalid) {
      return toggle;
    }

    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={INVALID_AD_TOOLTIP_CONFIG[invalidAdType]}
        id={`invalidAdTooltip-${this.props.snap.id}`}
      >
        {toggle}
      </SDSTooltip>
    );
  }

  render() {
    const adSnapIndexes = this.props.adIndexes;
    const adEnabled =
      isAdEnabledAfterSnap(this.props.activeEdition, adSnapIndexes, this.props.snap.id) && !this.props.isSensitive;
    const props = adEnabled ? SnapMenuItemConfig[SnapMenuItemIds.AD_PLAY] : SnapMenuItemConfig[SnapMenuItemIds.NO_AD];
    const advancedAdsEnabledForUser = this.props.activePublisher.advancedAdsEnabled && this.props.isAdPlacer;
    const isLastSnapBeforeSubscribeSnap = isLastNonSubscribeSnapInEdition(
      this.props.activeEdition,
      this.props.snap.id,
      this.props.editionHasSubscribeSnap
    );

    if (this.props.isSuperAdPlacer || (advancedAdsEnabledForUser && !isLastSnapBeforeSubscribeSnap)) {
      const invalidAdType = this.props.adState;
      const isAdInvalid = invalidAdType !== AdvancedAdsState.VALID;

      const toggle = (
        // @ts-expect-error
        <ListItemWithToggle
          {...props}
          value={adEnabled}
          onChange={this.handleAdOptionsChange}
          isReadOnly={this.props.isActiveEditionReadOnly || this.props.disabled}
          isInvalid={isAdInvalid}
        />
      );

      return this.maybeWrapInTooltipTrigger(toggle, isAdInvalid, invalidAdType);
    }

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return <ListItemWithIcon {...props} className={classNames(style.row, { [style.adEnabled]: !adEnabled })} />;
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapItemMenuAdControls);
