// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Grid } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import is from 'is_js';
import { get } from 'lodash';
import * as React from 'react';
import type { ReactNode } from 'react';

import { getCreativeIdFromSnap } from 'state/asset/selectors/assetSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';

import { EDITOR_MODES } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { withRouter } from 'utils/routerUtils';

import SnapEditorPanel from 'views/common/components/SnapEditorPanel/SnapEditorPanel';
import MediaLibrary from 'views/common/containers/MediaLibrary/MediaLibrary';
import StoryLevelBarBuilder from 'views/dashboard/containers/StoryBuilderCommon/StoryBuilderCommon';
import SnapPublisherPreCache from 'views/editor/components/SnapPublisher/SnapPublisherPreCache';
import ArticleEditor from 'views/editor/containers/ArticleEditor/ArticleEditor';
import EditorPropertyPanels from 'views/editor/containers/EditorPropertyPanels/EditorPropertyPanels';
import StatusMessage from 'views/editor/containers/StatusMessage/StatusMessage';
import MediaViewDragLayer from 'views/mediaLibrary/MediaViewDragLayer/MediaViewDragLayer';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import PublisherStoryEditorDropzone from 'views/publisherStoryEditor/containers/PublisherStoryEditorDropzone/PublisherStoryEditorDropzone';
import SnapPublisher from 'views/publisherStoryEditor/containers/SnapPublisher/SnapPublisher';
import SnapWebImport from 'views/publisherStoryEditor/containers/SnapWebImport/SnapWebImport';
import SnapLevelBar from 'views/publisherStoryEditor/containers/StoryBuilderBars/SnapLevelBar/SnapLevelBar';
import TopSnapSubtitlesPreview from 'views/subtitles/TopSnapSubtitlesPreview/TopSnapSubtitlesPreview';

import style from './SimpleStoryBuilder.scss';
import SnapEditorStatus from './SnapEditorStatus';

import type { RichSnapComponent } from 'types/components';
import type { Edition } from 'types/editions';
import type { Publisher } from 'types/publishers';
import type { CreativeID, TopSnap } from 'types/snaps';

const mapStateToProps = (state: State) => {
  const editorMode = publisherStoryEditorSelectors.getStoryEditorMode(state);
  const bottomsnapComponent = componentsSelectors.getBottomsnapComponentForActiveComponent(state);
  const activeTopsnap = editorSelectors.getActiveTopsnap(state);
  const isShowingSubtitlesPanel = Boolean(get(activeTopsnap, 'videoAssetId'));
  return {
    activeComponent: componentsSelectors.getActiveComponent(state),
    activeEdition: publisherStoryEditorSelectors.getActiveEdition(state),
    activePublisher: publishersSelectors.getActivePublisherDetails(state),
    activeSnap: activeTopsnap,
    creativeId: getCreativeIdFromSnap(state)(activeTopsnap),
    bottomsnapComponentId: bottomsnapComponent && (bottomsnapComponent as any).componentId,
    componentsOfTypeSnap: componentsSelectors.getComponentsOfTypeSnap(state),
    editorMode: editorMode.editorMode,
    editorModeMetadata: editorMode.metadata,
    editorModeOptions: editorMode.options,
    forceGoToSnapPublisher: editorMode.forceGoToSnapPublisher,
    isShowingNotificationMessage: snapAdminSelectors.isShowingNotificationMessage(state),
    isMediaLibraryVisible: publisherStoryEditorSelectors.isMediaLibraryDrawerVisible(state),
    isShowingSubtitlesPanel,
    isCuratedLayerEnabled:
      featuresSelectors.isCuratedLayerEnabled(state) || featuresSelectors.isAdvancedCurationEnabled(state),
  };
};
const mapDispatchToProps = {
  goToPublisherStoryEditor: routerActions.goToPublisherStoryEditor,
  initializeSnapComponentPreviews: editorActions.initializeSnapComponentPreviews,
  setEditorMode: publisherStoryEditorModeActions.setEditorMode,
  goToHomepage: routerActions.goToHomepage,
  toggleMediaLibraryDrawer: publisherStoryEditorActions.toggleMediaLibraryDrawer,
  openSnapEditor: publisherStoryEditorModeActions.openSnapEditor,
};
type OwnState = {
  isDraggingMediaLibraryItem: boolean;
  // Whether the drawer has been opened once during the current page session.
  // Do not render media library drawer if it has never been rendered.
  // In order to keep a smooth hide/show animation, we do not unmount media library
  // component once it has been opened
  mediaLibraryDrawerHasBeenOpened: boolean;
};
type Props = {
  activeComponent: RichSnapComponent | undefined | null;
  activeEdition: Edition;
  activePublisher: Publisher;
  activeSnap: TopSnap;
  creativeId: CreativeID | undefined | null;
  bottomsnapComponentId: string | undefined | null;
  children?: ReactNode;
  componentsOfTypeSnap: RichSnapComponent[];
  editorMode: boolean;
  forceGoToSnapPublisher: boolean;
  editorModeMetadata: string;
  editorModeOptions: {
    advancedMode?: boolean;
    trimMode: boolean;
  };
  goToHomepage: typeof routerActions.goToHomepage;
  goToPublisherStoryEditor: typeof routerActions.goToPublisherStoryEditor;
  initializeSnapComponentPreviews: typeof editorActions.initializeSnapComponentPreviews;
  isShowingNotificationMessage: boolean;
  isShowingSubtitlesPanel: boolean;
  match: unknown; // We don't use this router-injected props but needs to subscribe to its change,
  setEditorMode: typeof publisherStoryEditorModeActions.setEditorMode;
  isMediaLibraryVisible: boolean;
  toggleMediaLibraryDrawer: typeof publisherStoryEditorActions.toggleMediaLibraryDrawer;
  isCuratedLayerEnabled: boolean;
  openSnapEditor: typeof publisherStoryEditorModeActions.openSnapEditor;
};
export class SimpleStoryBuilder extends React.Component<Props, OwnState> {
  state = {
    isDraggingMediaLibraryItem: false,
    mediaLibraryDrawerHasBeenOpened: false,
  };

  UNSAFE_componentWillMount() {
    if (this.props.activeSnap) {
      this.props.initializeSnapComponentPreviews();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const nextSnapId = get(nextProps, ['activeSnap', 'id']);
    const prevSnapId = get(this.props, ['activeSnap', 'id']);
    if (nextProps.activeSnap && nextSnapId !== prevSnapId) {
      this.props.initializeSnapComponentPreviews();
    }
    if (nextProps.isMediaLibraryVisible && !this.state.mediaLibraryDrawerHasBeenOpened) {
      this.setState({
        mediaLibraryDrawerHasBeenOpened: true,
      });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: OwnState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isMediaLibraryVisible && !this.props.isMediaLibraryVisible && this.state.isDraggingMediaLibraryItem) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isDraggingMediaLibraryItem: false });
    }
  }

  onArticleSave = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    this.props.setEditorMode(EDITOR_MODES.SNAP_PUB, this.props.editorModeMetadata);
  };

  onMediaLibraryDragStart = () => {
    this.setState({
      isDraggingMediaLibraryItem: true,
    });
  };

  onMediaLibraryDragCancelled = () => {
    this.setState({
      isDraggingMediaLibraryItem: false,
    });
  };

  isTopsnapSelected = () => {
    const snapComponent = this.props.componentsOfTypeSnap[0];
    if (!snapComponent) {
      return false;
    }
    return get(this, ['props', 'activeComponent', 'componentId']) === snapComponent.componentId;
  };

  openSnapPublisher = (options: any) => {
    this.incrementCounter('openSnapPublisher');
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    this.props.setEditorMode(EDITOR_MODES.SNAP_PUB, '', options);
  };

  incrementCounter(metricName: string) {
    const publisher = this.props.activePublisher;
    const metric = `SimpleStoryBuilder.byType.${publisher.type}.byTier.${publisher.tier}.${metricName}`;
    incrementCounter(metric);
  }

  isOverlayOnEditor = () => {
    return (
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      this.props.editorMode === EDITOR_MODES.SNAP_PUB ||
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      this.props.editorMode === EDITOR_MODES.SNAP_PUB_AND_ARTICLE ||
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      this.props.editorMode === EDITOR_MODES.WEB_IMPORT
    );
  };

  handleClose = () => {
    if (this.isOverlayOnEditor()) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      this.props.setEditorMode(EDITOR_MODES.EDITOR);
      return;
    }
    const { activePublisher, activeEdition, goToPublisherStoryEditor } = this.props;
    goToPublisherStoryEditor({
      publisherId: get(activePublisher, 'id'),
      editionId: activeEdition.id,
      overwriteHistory: false,
    });
  };

  renderSnapLevelBar = () => {
    return <SnapLevelBar onClose={this.handleClose} shouldRenderTabs={!this.isOverlayOnEditor()} />;
  };

  renderStoryView = () => {
    const classname = classNames(style.storyView, {
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
      [style.hidden]: this.props.editorMode !== EDITOR_MODES.STORY,
    });
    return (
      <div data-test="SimpleStoryBuilder.storyView" className={classname}>
        <StoryLevelBarBuilder story={this.props.activeEdition} />
        <PublisherStoryEditorDropzone>{this.props.children}</PublisherStoryEditorDropzone>
      </div>
    );
  };

  renderMiddlePanel = () => {
    return (
      <div className={classNames(style.middlePanel, { [style.middlePanelCard]: !this.isTopsnapSelected() })}>
        <SnapEditorStatus showDeleteButton={!this.isTopsnapSelected()} />
        <StatusMessage />
        <SnapEditorPanel showNavigationControls={this.isTopsnapSelected()} />
      </div>
    );
  };

  renderLeftPanel = () => {
    if (this.isTopsnapSelected() && this.props.isShowingSubtitlesPanel) {
      const leftPanelWithAutoScrollbar = classNames(style.panelContainer, style.scrollable);
      return (
        <div className={style.leftPanel}>
          <div className={leftPanelWithAutoScrollbar}>
            <div className={style.scrollbar}>
              <TopSnapSubtitlesPreview key={this.props.activeSnap.id} />
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  renderPropertyPanels() {
    const { activeComponent } = this.props;
    return <EditorPropertyPanels component={activeComponent} openSnapPublisher={this.openSnapPublisher} />;
  }

  renderRightPanel = () => {
    const panelWithAutoScrollbar = classNames(style.panelContainer, style.scrollable);
    return (
      <div className={(style as any).rightPanel}>
        <div className={panelWithAutoScrollbar} data-test="simpleStoryBuilder.panelContainer">
          <div className={style.scrollbar}>{this.renderPropertyPanels()}</div>
        </div>
      </div>
    );
  };

  shouldRenderSnapView = () => {
    const { editorMode } = this.props;
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    return editorMode === EDITOR_MODES.EDITOR;
  };

  renderSnapView = () => {
    const hidden = !this.shouldRenderSnapView();
    const classname = classNames({
      [style.hidden]: hidden,
      [style.root]: true,
    });
    return (
      <div className={classname}>
        {!hidden && this.renderSnapLevelBar()}
        <div className={style.wrapper}>
          {this.renderLeftPanel()}
          {this.renderMiddlePanel()}
          {this.renderRightPanel()}
        </div>
      </div>
    );
  };

  renderSnapPublisher = () => {
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    const snapPubAndArticleMode = this.props.editorMode === EDITOR_MODES.SNAP_PUB_AND_ARTICLE;
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
    if (this.props.editorMode !== EDITOR_MODES.SNAP_PUB && !snapPubAndArticleMode) {
      return null;
    }
    const snapPublisherClassNames = classNames({
      [style.snapPublisher]: true,
      [style.heightWhenGlobalBarVisible]: this.props.isShowingNotificationMessage,
    });
    const options = {
      snapId: this.props.activeSnap.id,
      creativeId: this.props.creativeId || this.props.activeSnap.creativeId,
      webImportUrl: this.props.editorModeMetadata,
      assetId: get(this.props.activeSnap, 'imageAssetId') || get(this.props.activeSnap, 'videoAssetId'),
      ...(is.truthy(this.props.isCuratedLayerEnabled) && {
        layerAssetId: get(this.props.activeSnap, 'overlayImageAssetId'),
      }),
      ...this.props.editorModeOptions,
    };
    return (
      <div className={style.root}>
        {this.renderSnapLevelBar()}
        <div className={snapPublisherClassNames}>
          <SnapPublisher options={options} />
          {snapPubAndArticleMode && (
            <ArticleEditor
              className={style.articleEditor}
              snapComponentId={this.props.bottomsnapComponentId}
              onSave={this.onArticleSave}
              saveOnLoad
            />
          )}
        </div>
      </div>
    );
  };

  renderWebImport = () => {
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
    if (this.props.editorMode !== EDITOR_MODES.WEB_IMPORT) {
      return null;
    }
    return (
      <div className={style.root}>
        {this.renderSnapLevelBar()}
        <SnapWebImport snapId={this.props.activeSnap.id} />
      </div>
    );
  };

  renderMediaLibrary = () => {
    return (
      <div
        className={classNames(style.mediaLibrary, {
          [style.active]: this.props.isMediaLibraryVisible,
        })}
      >
        <MediaViewDragLayer />
        <div
          className={classNames(style.mediaLibraryDrawer, {
            [style.minimised]: this.state.isDraggingMediaLibraryItem,
          })}
        >
          {this.state.mediaLibraryDrawerHasBeenOpened ? (
            <MediaLibrary
              drawerMode
              onDragStart={this.onMediaLibraryDragStart}
              onItemDropped={this.props.toggleMediaLibraryDrawer}
              onDragCancelled={this.onMediaLibraryDragCancelled}
            />
          ) : null}
        </div>
        <div
          className={classNames(style.mediaLibraryPageOverlay, {
            [style.hidden]: !this.props.isMediaLibraryVisible,
            [style.clickThrough]: this.state.isDraggingMediaLibraryItem,
          })}
          onClick={this.props.toggleMediaLibraryDrawer}
        />
      </div>
    );
  };

  render() {
    return (
      <Grid fluid className={style.grid}>
        {this.renderMediaLibrary()}
        {this.renderSnapPublisher()}
        {this.renderWebImport()}
        {this.renderStoryView()}
        {this.renderSnapView()}
        <SnapPublisherPreCache />
      </Grid>
    );
  }
}
export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(SimpleStoryBuilder));
