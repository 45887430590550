import classNames from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { save } from 'views/analytics/utils/saveFile';
import PopoverCloseOnMovement from 'views/common/components/PopoverCloseOnMovement/PopoverCloseOnMovement';

import style from './SubtitlesOptionsPopover.scss';

import type { AssetID } from 'types/assets';

const POPOVER_ARROW_OFFSET_LEFT = '78px';

type OwnProps = {
  videoAssetId: AssetID;
  subtitlesFileName: string;
  rawSubtitles: string | undefined | null;
  subtitlesUrl: string;
  // @ts-expect-error ts-migrate(2694) FIXME: Namespace '"/Users/rkalpakova/Snapchat/Dev/discove... Remove this comment to see the full error message
  children: PropTypes.element;
  subtitlesPreviewEnabled: boolean;
  onDelete: () => any;
  onChangePreview: () => any;
  isReadOnly: boolean;
};

type Props = OwnProps & typeof SubtitlesOptionsPopover.defaultProps;

export default class SubtitlesOptionsPopover extends React.PureComponent<Props> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  static defaultProps = {
    onDelete: () => {},
    onChangePreview: () => {},
    isReadOnly: false,
  };

  onDownload = (event: Event) => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    save(new Blob([this.props.rawSubtitles], { type: 'text/vtt' }), this.props.subtitlesFileName);
    event.preventDefault();
  };

  renderRemoveSubtitles() {
    const className = classNames(style.menuItem, { [style.disabled]: this.props.isReadOnly });
    return (
      <div className={className}>
        <a
          onClick={this.props.isReadOnly ? noop : this.props.onDelete}
          data-test="subtitlesOptionsPopover.removeSubtitles"
        >
          <FormattedMessage
            id="subtitles-options-remove-subtitles"
            description="Text for user action to remove subtitles from snap"
            defaultMessage="Delete subtitles"
          />
        </a>
      </div>
    );
  }

  renderChangePreview() {
    const changePreviewMessage = this.props.subtitlesPreviewEnabled ? (
      <FormattedMessage
        id="subtitles-options-disable-preview"
        description="Text for user action to disable subtitles on the snap video preview in Editor"
        defaultMessage="Disable preview"
        data-test="subtitlesOptionsPopover.disablePreview"
      />
    ) : (
      <FormattedMessage
        id="subtitles-options-enable-preview"
        description="Text for user action to enable subtitles on the snap video preview in Editor"
        defaultMessage="Enable preview"
        data-test="subtitlesOptionsPopover.enablePreview"
      />
    );
    return (
      <div className={style.menuItem}>
        <a onClick={this.props.onChangePreview} data-test="subtitlesOptionsPopover.changePreview">
          {changePreviewMessage}
        </a>
      </div>
    );
  }

  renderDownloadSubtitles() {
    if (!this.props.rawSubtitles) {
      return null;
    }
    return (
      <div className={style.menuItem}>
        <a
          href={this.props.subtitlesUrl}
          download={this.props.subtitlesFileName}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: Event) => void' is not assignable to... Remove this comment to see the full error message
          onClick={this.onDownload}
          data-test="subtitlesOptionsPopover.downloadVtt"
        >
          <FormattedMessage
            id="subtitles-options-download-vtt"
            description="Text for user action to download subtitles in .vtt file"
            defaultMessage="Download .vtt"
          />
        </a>
      </div>
    );
  }

  render() {
    return (
      <div>
        <PopoverCloseOnMovement
          id="SubtitlesOptionsPopover"
          target={this.props.children}
          arrowOffsetLeft={POPOVER_ARROW_OFFSET_LEFT}
          className={style.customPopover}
          closeOnVerticalMovement={false}
          container={this.context.getScrollContainer}
          data-test="subtitleOptionPopoverBlock"
        >
          <div>
            {this.renderRemoveSubtitles()}
            {this.renderChangePreview()}
            {this.renderDownloadSubtitles()}
          </div>
        </PopoverCloseOnMovement>
      </div>
    );
  }
}
