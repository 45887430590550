import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as publisherToolsSelectors from 'state/publisherTools/selectors/publisherToolsSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { plus } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import Icon from 'views/common/components/Icon/Icon';
import ImageWithPlaceholder from 'views/common/components/ImageWithPlaceholder/ImageWithPlaceholder';
import StoryStateLabel from 'views/publisherStoryEditor/components/StoryStateLabel/StoryStateLabel';
import { SnapMenuItemConfig, SnapMenuItemIds } from 'views/publisherStoryEditor/utils/SnapMenuItemConfig';

import SelectedCheckmark from './SelectedCheckmark.svg';

import style from './StoryModalRow.scss';

import type { SnapId } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { Publisher, PublisherID } from 'types/publishers';

type OwnProps = {
  publisherId: PublisherID;
  storyId: EditionID;
  selectedStoryId: EditionID;
  onClick: () => void;
};

type StateProps = {
  publisher: Publisher;
  story: Edition | undefined | null;
  firstSnapId: SnapId | undefined | null;
  tileImageUrl: string | undefined | null;
};

type DispatchProps = {
  loadSnap: typeof snapsActions.loadSnap;
};

type Props = OwnProps & StateProps & DispatchProps;

function mapStateToProps(state: State, ownProps: any) {
  const story = editionsSelectors.getEditionById(state)(ownProps.storyId);

  const firstSnapId = _.get(story, ['snapIds', 0], null);

  const firstSnapTile = publisherToolsSelectors.getPresentationalTileForEdition(state)(ownProps.storyId);
  const tileAssetId = firstSnapTile && firstSnapTile.croppedImageAssetId;
  const tileImageUrl = tileAssetId && createAssetUrl(tileAssetId);

  return {
    publisher: userSelectors.getPublisherById(state)(ownProps.publisherId),
    story,
    firstSnapId,
    tileImageUrl,
  };
}

const mapDispatchToProps = {
  loadSnap: snapsActions.loadSnap,
};

export class StoryModalRow extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.story && this.props.firstSnapId) {
      this.props.loadSnap({ snapId: this.props.firstSnapId });
    }
  }

  renderNewStoryIcon() {
    return (
      <div className={classNames(style.content, style.newStory)}>
        <Icon inlineIcon={plus} />
      </div>
    );
  }

  renderTile() {
    return (
      <ImageWithPlaceholder
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        src={this.props.tileImageUrl}
        className={classNames(style.content)}
        showPlaceholderWhileLoading
      >
        <div className={classNames(style.content, style.placeholder)} />
      </ImageWithPlaceholder>
    );
  }

  renderPublisherIcon() {
    if (this.props.publisher.defaultFilledIconId) {
      return <Icon img={createAssetUrl(this.props.publisher.defaultFilledIconId)} className={style.publisherIcon} />;
    }

    return <div className={style.publisherIconTextPlaceholder}>{this.props.publisher.mutablePublisherName}</div>;
  }

  renderTitle() {
    let title;
    if (this.props.story) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      title = this.props.story.title || SnapMenuItemConfig[SnapMenuItemIds.TITLE_PLACEHOLDER].text;
    } else {
      title = getMessageFromId('title-create-new-story');
    }

    return <div className={style.title}>{title}</div>;
  }

  renderSelectedCheck() {
    if (this.props.selectedStoryId !== this.props.storyId) {
      return null;
    }
    return <Icon img={SelectedCheckmark} className={style.checkMark} />;
  }

  render() {
    const rootClass = classNames(style.root, {
      [style.selected]: this.props.selectedStoryId === this.props.storyId,
    });

    return (
      <div className={rootClass} onClick={this.props.onClick}>
        {this.renderSelectedCheck()}
        {!this.props.story && this.renderNewStoryIcon()}
        {this.props.story && this.renderTile()}
        <div className={style.info}>
          <div className={style.publisherIconContainer}>{this.renderPublisherIcon()}</div>
          {this.renderTitle()}
          {this.props.story && <StoryStateLabel storyState={this.props.story.state} storyId={this.props.storyId} />}
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryModalRow);
