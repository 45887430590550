import { get } from 'lodash';
import React from 'react';

import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';
import * as videoLibrarySelectors from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { intlConnect } from 'utils/connectUtils';

import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import VideoPlayer from 'views/editor/components/VideoPlayer/VideoPlayer';

import style from './PlayVideoModal.scss';

import { AssetID } from 'types/assets';
import { ModalId } from 'types/modal';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

const mapStateToProps = (state: State, props: any) => {
  const { assetId } = props.options;
  const videoSrc = videoLibrarySelectors.getVideoSrcById(state)(assetId);
  const videoResult = videoLibrarySelectors.getVideoResultById(state)(assetId);
  return {
    videoSrc,
    videoResult,
  };
};
const mapDispatchToProps = {
  getVideoMetadata: videoLibraryActions.getVideoMetadata,
  fetchVideoInformation: videoLibraryActions.fetchVideoInformation,
};

type OwnProps = {
  modalId: ModalId;
  options: {
    assetId: AssetID;
  };
  hideModal: (modalId: ModalId) => void;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;
export class PlayVideoModal extends React.Component<Props> {
  componentDidMount() {
    this.props.getVideoMetadata({
      assetId: this.props.options.assetId,
    });
    this.props.fetchVideoInformation({
      assetId: this.props.options.assetId,
    });
  }

  onClose = () => {
    this.props.hideModal(this.props.modalId);
  };

  render() {
    const { videoSrc, videoResult } = this.props;
    return (
      <SDSCustomModal visible onClose={this.onClose} footer={null}>
        <div className={style.videoFrame}>
          <VideoPlayer
            className={style.preview}
            src={get(videoSrc, ['url'])}
            poster={get(videoResult, ['images', 'poster', 'src'])}
            subtitles={get(videoResult, ['textTracks'])}
            data-test="PlayVideoModal.videoPlayer"
          />
        </div>
      </SDSCustomModal>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(PlayVideoModal);
