import classnames from 'classnames';
import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import type { ChangeEvent } from 'react';

import { subtitleTimingsFromMilliseconds, timingsToMilliseconds, toDisplayTimestamp } from '../../subtitleParser';

import { KeyCode } from 'config/constants';

import style from './TimestampEditor.scss';

type Props = {
  durationMs: number;
  disabled?: boolean;
  onChange: (a: number) => void;
  min: number;
  max: number;
  isValid: boolean;
};

function adjustValue(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

function isInBounds(value: number, min: number, max: number) {
  return min <= value && value <= max;
}

const TIMESTAMP_EDITOR_REGEX = /^(\d{0,2}):(\d{0,2})\.(\d{0,2})$/;

function matchTiming(e: ChangeEvent<EventTarget>) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
  const { value } = e.target;
  return value.match(TIMESTAMP_EDITOR_REGEX);
}

const UPDATE_STEP_MS = 150;

function TimestampEditor({ disabled, durationMs, onChange, min, max, isValid }: Props) {
  const [hh] = useMemo(() => subtitleTimingsFromMilliseconds(durationMs), [durationMs]);
  const displayTimestamp = useMemo(() => toDisplayTimestamp(durationMs), [durationMs]);

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
  const [timestamp, setTimestamp] = useState(displayTimestamp, [displayTimestamp]);

  useEffect(() => {
    setTimestamp(displayTimestamp);
  }, [displayTimestamp, setTimestamp]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<EventTarget>) => {
      const matches = matchTiming(e);
      if (matches) {
        setTimestamp(matches.input);
      }
    },
    [setTimestamp]
  );

  const handleOnBlur = useCallback(
    (e: ChangeEvent<EventTarget>) => {
      const matches = matchTiming(e);
      if (matches) {
        const [, /* hh */ mm, ss, ms] = matches
          .map((value: any) => parseInt(value, 10))
          .map((value: any) => {
            return Number.isNaN(value) ? 0 : value;
          });
        const newDuration = timingsToMilliseconds([hh, mm, ss, ms * 10]);
        if (isInBounds(newDuration, min, max)) {
          onChange(newDuration);
        } else {
          setTimestamp(displayTimestamp);
        }
      }
    },
    [hh, onChange, min, max, displayTimestamp]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === KeyCode.UP_ARROW) {
        onChange(adjustValue(durationMs + UPDATE_STEP_MS, min, max));
      } else if (event.keyCode === KeyCode.DOWN_ARROW) {
        onChange(adjustValue(durationMs - UPDATE_STEP_MS, min, max));
      }
    },
    [durationMs, onChange, min, max]
  );

  if (disabled) {
    return <span>{displayTimestamp}</span>;
  }

  return (
    <input
      type="text"
      className={classnames(style.input, { [style.error]: !isValid })}
      value={timestamp}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: KeyboardEvent) => void' is not assig... Remove this comment to see the full error message
      onKeyDown={handleKeyPress}
    />
  );
}

export default memo(TimestampEditor);
