import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { UploadPurpose, DropzoneType, CrossOrigin } from 'config/constants';
import * as gaUtils from 'utils/gaUtils';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import Spinner from 'views/common/components/Spinner/Spinner';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';
import type { OwnProps as MediaUploaderProps } from 'views/editor/containers/MediaUploader/MediaUploader';

import trashCanIcon from 'images/trash.svg';

import style from './FileUploadDeleteButton.scss';

import type { AssetID } from 'types/assets';

const DELETE_ALERT_TITLE = (
  <FormattedMessage id="delete-alert-title" description="Delete alert title" defaultMessage="Delete Alert" />
);
const DELETE_CONFIRM_TEXT = (
  <FormattedMessage id="delete-confirm-text" description="Delete confirm text" defaultMessage="Delete" />
);

const DELETE_SUBTITLE_MESSAGE = (
  <FormattedMessage
    id="delete-subtitle-message"
    description="Ask for subtitle delete confirmation"
    defaultMessage="Are you sure you want to delete these subtitles?"
  />
);

const DELETE_LONGFORM_VIDEO_MESSAGE = (
  <FormattedMessage
    id="delete-longform-video-message"
    description="Ask for LongFormVideo delete confirmation"
    defaultMessage="Are you sure you want to delete this LongForm Video?"
  />
);

const DELETE_GENERIC_MESSAGE = (
  <FormattedMessage
    id="delete-generic-message"
    description="Ask for generic delete confirmation"
    defaultMessage="Are you sure you want to delete?"
  />
);

type DZT =
  | typeof DropzoneType.VIDEO_REPLACE
  | typeof DropzoneType.REPLACE
  | typeof DropzoneType.POLL_IMAGE
  | typeof DropzoneType.POLL_IMAGE_REPLACE
  | typeof DropzoneType.SOUNDTRACK
  | typeof DropzoneType.SOUNDTRACK_REPLACE;

type Props = MediaUploaderProps & {
  'data-test'?: string;
  customValidationOptions?: {};
  deleteAlertMessage?: string | {};
  dropzoneType?: DZT;
  fileInfo?: {
    assetId?: AssetID;
    id?: string | number;
    label?: string;
  } | null;
  isReadOnly?: boolean;
  noBorder?: boolean;
  onDelete: () => void;
  subtitlesLanguage?: string;
  uploadCount?: number | null;
};

type State = {
  showDeleteAlert: boolean;
};

export default class FileUploadDeleteButton extends React.Component<Props, State> {
  state = {
    showDeleteAlert: false,
  };

  getAlertMessage() {
    let message = null;
    if (this.props.deleteAlertMessage) {
      return this.props.deleteAlertMessage;
    }

    switch (this.props.purpose) {
      case UploadPurpose.SUBTITLE:
        message = DELETE_SUBTITLE_MESSAGE;
        break;
      case UploadPurpose.LONGFORM_VIDEO:
        message = DELETE_LONGFORM_VIDEO_MESSAGE;
        break;
      default:
        message = DELETE_GENERIC_MESSAGE;
    }
    return message;
  }

  handleDelete = () => {
    this.setState({ showDeleteAlert: true });
  };

  hideDeleteAlert = () => {
    this.setState({ showDeleteAlert: false });
  };

  doDelete = () => {
    this.logGAEvent();
    this.props.onDelete();
    this.hideDeleteAlert();
  };

  logGAEvent() {
    const params = gaUtils.getGAParamsForUploadPurpose(this.props.purpose, 'delete');
    if (params) {
      gaUtils.logGAEvent(params.category, params.action);
    }
  }

  renderDeleteAlert() {
    return (
      <SDSDialog
        visible
        onCancel={this.hideDeleteAlert}
        okText={DELETE_CONFIRM_TEXT}
        onOk={this.doDelete}
        title={DELETE_ALERT_TITLE}
        data-test={`${String(this.props['data-test'])}Delete`}
      >
        {this.getAlertMessage()}
      </SDSDialog>
    );
  }

  renderAddButtonOrSpinner() {
    if (Number(this.props.uploadCount) > 0) {
      return <Spinner loading className={style.spinner} />;
    }
    return (
      <MediaUploader
        {...this.props}
        dropzoneType={this.props.dropzoneType}
        data-test="fileUploadDeleteButton.uploadSubTitle"
      />
    );
  }

  renderFileExists() {
    const { props } = this;
    return (
      <div>
        <div className={style.fileName}>{props.fileInfo && props.fileInfo.label}</div>
        {!this.props.isReadOnly && (
          <img
            className={classNames(style.deleteButton, {
              [style.readOnly]: props.isReadOnly,
              [style.noBorder]: props.noBorder,
            })}
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
            src={trashCanIcon}
            alt={`delete ${props.purpose}`}
            onClick={this.handleDelete}
            data-test="fileUploadDeleteButton.DeleteButton"
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className={style.root}>
        {!this.props.fileInfo && this.renderAddButtonOrSpinner()}
        {this.props.fileInfo && this.renderFileExists()}
        {this.state.showDeleteAlert ? this.renderDeleteAlert() : null}
      </div>
    );
  }
}
