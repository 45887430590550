import React from 'react';
import type { ReactElement } from 'react';

import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { withRouter } from 'utils/routerUtils';

import StoryDropzone from 'views/common/containers/StoryDropzone/StoryDropzone';
import PublisherStoryEditor from 'views/publisherStoryEditor/containers/PublisherStoryEditor/PublisherStoryEditor';

import type { Edition } from 'types/editions';
import { Publisher } from 'types/publishers';
import { SnapType } from 'types/snaps';

type Props = {
  children: ReactElement<any>;
  activeEdition: Edition;
  addMultipleSnapsWithMediaToEdition: typeof publisherStoryEditorActions.addMultipleSnapsWithMediaToEdition;
  addMediaToStory: typeof mediaLibraryActions.addMediaToStory;
  isCuratedLayerEnabled: boolean;
  publisher: Publisher | null;
};

const mapStateToProps = (state: State) => {
  return {
    activeEdition: publisherStoryEditorSelectors.getActiveEdition(state),
    isCuratedLayerEnabled:
      featuresSelectors.isCuratedLayerEnabled(state) || featuresSelectors.isAdvancedCurationEnabled(state),
    publisher: getActivePublisherDetails(state),
  };
};

const mapDispatchToProps = {
  addMultipleSnapsWithMediaToEdition: publisherStoryEditorActions.addMultipleSnapsWithMediaToEdition,
  addMediaToStory: mediaLibraryActions.addMediaToStory,
};

export class PublisherStoryEditorDropzone extends React.PureComponent<Props> {
  onDropFile = (files?: Blob[] | null, mediaLibraryItems?: any | null) => {
    if (mediaLibraryItems) {
      incrementCounterByPublisher(this.props.publisher, GrafanaMetrics.MEDIA_V2, {
        type: 'mediaUploadDrawerMediaItems',
        filter: 'AddNewSnap',
      });
    }

    const initialIndex = this.props.activeEdition.snapIds.length - 1;
    if (files && files.length > 0) {
      this.props.addMultipleSnapsWithMediaToEdition({
        editionId: this.props.activeEdition.id,
        initialIndex,
        snapType: SnapType.IMAGE,
        files,
      });
    }

    if (mediaLibraryItems) {
      this.props.addMediaToStory({
        snaps: mediaLibraryItems,
        editionId: this.props.activeEdition.id,
        initialIndex,
        isCuratedLayerEnabled: this.props.isCuratedLayerEnabled,
      });
    }
  };

  render() {
    return (
      <StoryDropzone onDrop={this.onDropFile} shallow allowMediaLibraryItems>
        <PublisherStoryEditor>{this.props.children || null}</PublisherStoryEditor>
      </StoryDropzone>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(PublisherStoryEditorDropzone));
