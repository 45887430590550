import log from 'loglevel';

export default class FileReaderUtil {
  static readAsDataURL(file: any) {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = event => {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        resolve(event.target.result);
      };

      reader.onerror = error => {
        log.error('Error reading file:', error);
      };

      reader.readAsDataURL(file);
    });
  }
}
