import classNames from 'classnames';
import React from 'react';

import { stopEventPropagation } from 'utils/browserUtils';

import StoryDropzone from 'views/common/containers/StoryDropzone/StoryDropzone';

import addIcon from '../../icons/addsnap.svg';

import style from './AddSnapRuler.scss';

type Props = {
  onClick: (a: any) => void;
  showDropzone?: boolean;
  onDropFile?: (files?: Blob[] | null, mediaLibraryItems?: any | null) => any;
  className?: string;
};

export class AddSnapRuler extends React.Component<Props> {
  renderRuler = () => {
    const ruler = <div className={style.ruler} />;
    if (this.props.showDropzone) {
      return (
        <StoryDropzone
          className={style.dropzone}
          childBoxClassName={style.dropzoneChildBox}
          onDrop={this.props.onDropFile}
          showChildrenBorder
          allowMediaLibraryItems
        >
          {ruler}
        </StoryDropzone>
      );
    }

    return ruler;
  };

  render() {
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event?: Event | null | undefined) => void' ... Remove this comment to see the full error message
      <div className={classNames(style.container, this.props.className)} onClick={stopEventPropagation}>
        <div className={style.hoverArea} onClick={this.props.onClick}>
          <img src={addIcon} alt="" draggable="false" data-test="addSnapRuler.img" />
          {this.renderRuler()}
        </div>
      </div>
    );
  }
}

export default AddSnapRuler;
