export default class SCCaption {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCCaption';
  }

  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get label() {
    return 'Caption';
  }

  static define(registry: any) {
    registry.registerStyleButton(SCCaption, {
      element: 'h5',
    });
  }
}
