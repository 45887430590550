import React from 'react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import style from '../StoryBars.scss';

import { chart } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isStoryPromoted } from 'utils/payToPromoteUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import MutePreviewButton from 'views/common/containers/MutePreviewButton/MutePreviewButton';
import withProfileAdAccountsQuery from 'views/payToPromote/hocs/withProfileAdAccountsQuery/withProfileAdAccountsQuery';
import { ProfileAdAccountsQueryResult } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import PublishStoryButton from 'views/publisherStoryEditor/components/PublishStoryButton/PublishStoryButton';
import SnapcodePreviewButton from 'views/publisherStoryEditor/components/SnapcodePreviewButton/SnapcodePreviewButton';
import StoryStateOverview from 'views/publisherStoryEditor/components/StoryStateOverview/StoryStateOverview';
import LockUnlockLiveStoryButton from 'views/publisherStoryEditor/containers/LockUnlockLiveStoryAlert/LockUnlockLiveStoryButton';
import StoryLevelMessage from 'views/publisherStoryEditor/containers/StoryLevelMessage/StoryLevelMessage';
import StoryOptionsButton from 'views/publisherStoryEditor/containers/StoryOptionsButton/StoryOptionsButton';
import StorySubtitleButton from 'views/publisherStoryEditor/containers/StorySubtitleButton/StorySubtitleButton';

import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';
import type { PublisherID } from 'types/publishers';

type StateProps = {
  activeStory: Edition;
  publisherId: PublisherID | null;
  goToStoryAnalytics: typeof routerActions.goToStoryAnalytics;
  isSingleAssetStoryEditor: boolean;
};

const mapStateToProps = (state: State) => {
  const activeStory = publisherStoryEditorSelectors.getActiveEdition(state);

  return {
    activeStory,
    publisherId: getActivePublisherId(state),
    isSingleAssetStoryEditor: activeStory?.id
      ? publisherStoryEditorSelectors.shouldUseSingleAssetEditor(state)(activeStory?.id)
      : false,
  };
};

type OwnProps = ProfileAdAccountsQueryResult;

type Props = OwnProps & StateProps;

const mapDispatchToProps = {
  goToStoryAnalytics: routerActions.goToStoryAnalytics,
};

export class UnavailableStoryBar extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, undefined, nextProps);
  }

  goToStoryAnalytics = () => {
    const { publisherId } = this.props;
    const editionId = this.props.activeStory.id;

    return this.props.goToStoryAnalytics({ publisherId, editionId });
  };

  renderLiveEditForPublicArchive() {
    // This bar is also used by Private Archive
    if (this.props.activeStory.state !== StoryState.ARCHIVED) {
      return null;
    }

    return <LockUnlockLiveStoryButton />;
  }

  renderMakeAvailableForPrivateArchive() {
    // This bar is also used by Private Archive
    if (this.props.activeStory.state !== StoryState.HIDDEN) {
      return null;
    }

    return <PublishStoryButton storyId={this.props.activeStory.id} />;
  }

  renderStoryAnalyticsButton() {
    return (
      <div data-test="storyBuilderViewStoryAnalyticsButton">
        <SDSButton type={ButtonType.WHITE_ON_GREY} inlineIcon={chart} onClick={this.goToStoryAnalytics}>
          {getMessageFromId('story-status-analytics')}
        </SDSButton>
      </div>
    );
  }

  render() {
    if (!this.props.activeStory) {
      return null;
    }

    return (
      <div className={style.root}>
        <div className={style.leftBar}>
          <StoryStateOverview
            storyState={this.props.activeStory.state}
            isStoryPromoted={isStoryPromoted(this.props.activeStory.id, this.props.promotedStoriesIds)}
          />
        </div>
        <div className={style.middleBar}>
          <StoryLevelMessage />
        </div>
        <div className={style.rightBar}>
          {this.renderStoryAnalyticsButton()}
          {this.renderMakeAvailableForPrivateArchive()}
          {this.renderLiveEditForPublicArchive()}
          {!this.props.isSingleAssetStoryEditor && <StorySubtitleButton editionId={this.props.activeStory.id} />}
          <SnapcodePreviewButton />
          <MutePreviewButton />
          <StoryOptionsButton />
        </div>
      </div>
    );
  }
}

export default withProfileAdAccountsQuery(intlConnect(mapStateToProps, mapDispatchToProps)(UnavailableStoryBar));
