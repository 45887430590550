import classnames from 'classnames';
import React, { memo } from 'react';

import MediaAnnotationsPanelView from 'views/common/components/MediaAnnotations/MediaAnnotationsPanelView/MediaAnnotationsPanelView';

import style from './ExplicitContentAnnotations.scss';

import { ExplicitContentAnnotation } from 'types/assets';

export function ExplicitContentAnnotationItem({ category, likelihood }: ExplicitContentAnnotation) {
  return (
    <div className={style.contentAnnotation}>
      <div className={style.category}>{category}</div>
      <div className={classnames(style.likelihood, likelihood)}>{likelihood}</div>
    </div>
  );
}

type Props = {
  explicitContentAnnotations: ExplicitContentAnnotation[];
};

function ExplicitContentAnnotations({ explicitContentAnnotations }: Props) {
  return (
    <MediaAnnotationsPanelView header={'Explicit Content Annotations'}>
      {explicitContentAnnotations.map(annotation => (
        <ExplicitContentAnnotationItem key={annotation.category} {...annotation} />
      ))}
    </MediaAnnotationsPanelView>
  );
}

export default memo(ExplicitContentAnnotations);
