import { Picker, Emoji } from 'emoji-mart';
import { noop } from 'lodash';
import React from 'react';

import SDSTooltip, { TooltipPosition } from '../SDSTooltip/SDSTooltip';

import style from './EmojiPicker.scss';
import { createCustomProps } from './customEmojisUtils';

import 'emoji-mart/css/emoji-mart.css';

type EmojiPickerWrapperProps = {
  onChange?: (...args: any[]) => any;
  className?: string;
};

// This is wrapper to plug the Emoji Picker to the SnapNet
// DropDown Menu. DropDown Menu injects the onSelect to the component.
// But Picker has only onClick method, so I'm rendering the picker, which receives the
// onSelect prop and pass it to onClick property.
// E: We now render this wrapper within an SDSTooltip, the onChange property is injected and passed to
// the onClick property.
function EmojiPickerWrapper(props: EmojiPickerWrapperProps) {
  return (
    <div className={props.className}>
      <Picker
        {...props}
        native
        emoji=""
        set="apple"
        skin={1}
        perLine={9}
        emojiSize={24}
        autoFocus={false}
        title=""
        useButton
        onClick={props.onChange}
      />
    </div>
  );
}

type OwnEmojiPickerProps = {
  onChange?: (...args: any[]) => any;
  selectedValue?: any;
  defaultValue?: any;
  filterEmojis?: (...args: any[]) => any;
  customSettings?: {
    className?: string;
    customEmojis?: string[];
    headline?: string | any;
  };
};

type EmojiPickerProps = OwnEmojiPickerProps & typeof EmojiPicker.defaultProps;

export default class EmojiPicker extends React.Component<EmojiPickerProps, { isVisible: boolean }> {
  private emojiPickerWrapperRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    onChange: noop,
    selectedValue: null,
    defaultValue: { id: 'blush' },
  };

  constructor(props: EmojiPickerProps) {
    super(props);
    this.state = { isVisible: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleExternalClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleExternalClick, false);
  }

  // Checks whether we are clicking within our tooltip, if we are return, if we aren't
  // We set the tooltip visibility to false, closing the tooltip.
  handleExternalClick = (e: any) => {
    if (this.emojiPickerWrapperRef.current) {
      if (
        this.emojiPickerWrapperRef.current.contains(e.target) ||
        document.getElementById(`emoji-container-${this.props.customSettings?.headline}`)?.contains(e.target)
      ) {
        return;
      }
    }
    this.setState({
      isVisible: false,
    });
  };

  getSelectedValue() {
    const { selectedValue, defaultValue } = this.props;
    return selectedValue || defaultValue;
  }

  handleTooltipToggleOnClick = () => {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  };

  handleChange = (emoji: any) => {
    this.props.onChange({ id: emoji.id });
    this.setState({ isVisible: false });
  };

  renderEmojiPicker = () => {
    return (
      <div ref={this.emojiPickerWrapperRef}>
        <EmojiPickerWrapper onChange={this.handleChange} {...createCustomProps(this.props.customSettings)} />
      </div>
    );
  };

  render() {
    const currentValue = this.getSelectedValue();
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.BOTTOM_LEFT}
        id="tooltip-emoji"
        title={this.renderEmojiPicker}
        trigger={'click'}
        overlayClassName={style.tooltip}
        visible={this.state.isVisible}
      >
        <div
          id={`emoji-container-${this.props.customSettings?.headline}`}
          className={style.emojiContainer}
          onClick={this.handleTooltipToggleOnClick}
          data-test="emoji-container"
        >
          <Emoji emoji={currentValue} size={24} native />
        </div>
      </SDSTooltip>
    );
  }
}
