import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import type { SnapId } from 'types/common';

export type LensScancode = {
  lensScancodeId: string;
  scancodeVersion: number;
};
export type LensCarousel = LensScancode[];

export const SendToBehavior = enumObject({
  DEFAULT_SEND_TO: 'DEFAULT_SEND_TO',
  OUR_STORY: 'OUR_STORY',
  SPOTLIGHT: 'SPOTLIGHT',
});

export type SendToBehaviorEnum = Enum<typeof SendToBehavior>;

export type CameraAttachmentSnap = {
  id: SnapId;
  lenses: LensCarousel;
  sendToBehavior: SendToBehaviorEnum;
};
