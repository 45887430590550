import React from 'react';
import { FormattedMessage } from 'react-intl';

import { tileWithTextLines, ad, subtitles, subscribe, attachment } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import {
  TILE_INCOMPLETE_STATUSES,
  TILE_ERROR_STATUSES,
  SNAP_INCOMPLETE_STATUSES,
  SNAP_ERROR_STATUSES,
  ATTACHMENT_ERROR_STATUSES,
  SUBTITLES_INFO_STATUSES,
} from 'views/editor/containers/RichSnapTabs/RichSnapTabsConfig';

import { ConfigTab } from 'types/singleAssetStoryEditor';

export const TabsConfig = (SnapConfigTab: {}) => ({
  // [ConfigTab.VIDEO]: {
  //   text: <FormattedMessage
  //     id="single-asset-editor-video-tab-header"
  //     description="Text for Video tab header"
  //     defaultMessage="Video"
  //   />,
  //   icon: mobileVideo,
  //   errorStatuses: [],
  //   warningStatuses: [],
  // },
  [ConfigTab.TILES]: {
    key: ConfigTab.TILES,
    text: (
      <FormattedMessage
        id="single-asset-editor-tiles-tab-header"
        description="Text for Tiles tab header"
        defaultMessage="Tiles"
      />
    ),
    icon: tileWithTextLines,
    errorStatuses: TILE_ERROR_STATUSES,
    warningStatuses: TILE_INCOMPLETE_STATUSES,
    infoStatuses: [],
  },
  ...SnapConfigTab,
  [ConfigTab.SUBTITLES]: {
    key: ConfigTab.SUBTITLES,
    text: (
      <FormattedMessage
        id="single-asset-editor-subtitles-tab-header"
        description="Text for Subtitles tab header"
        defaultMessage="Subs"
      />
    ),
    icon: subtitles,
    errorStatuses: [],
    warningStatuses: [],
    infoStatuses: SUBTITLES_INFO_STATUSES,
  },
  [ConfigTab.ATTACH]: {
    key: ConfigTab.ATTACH,
    text: (
      <FormattedMessage
        id="single-asset-editor-attach-tab-header"
        description="Text for Attachment tab header"
        defaultMessage="Attach"
      />
    ),
    icon: attachment,
    errorStatuses: ATTACHMENT_ERROR_STATUSES,
    warningStatuses: [],
    infoStatuses: [],
  },
});

export const SingleSnapBuilderTabConfig = TabsConfig({
  [ConfigTab.SNAP]: {
    key: ConfigTab.SNAP,
    text: (
      <FormattedMessage
        id="single-snap-editor-tab-header"
        description="Text for single Snap tab header"
        defaultMessage="Snap"
      />
    ),
    icon: ad,
    errorStatuses: SNAP_ERROR_STATUSES,
    warningStatuses: SNAP_INCOMPLETE_STATUSES,
    infoStatuses: [],
  },
});

export const SubscribeSnapTabConfig = {
  [ConfigTab.SUBSCRIBE_SNAP]: {
    key: ConfigTab.SUBSCRIBE_SNAP,
    text: getMessageFromId('story-end-snap-header'),
    icon: subscribe,
    errorStatuses: SNAP_ERROR_STATUSES,
    warningStatuses: SNAP_INCOMPLETE_STATUSES,
    infoStatuses: [],
  },
};

export const SubscribeSnapVideoTabConfig = {
  [ConfigTab.SUBSCRIBE_SNAP]: {
    key: ConfigTab.SUBSCRIBE_SNAP,
    text: getMessageFromId('story-end-snap-header'),
    icon: subscribe,
    errorStatuses: SNAP_ERROR_STATUSES,
    warningStatuses: SNAP_INCOMPLETE_STATUSES,
    infoStatuses: [],
  },
  [ConfigTab.SUBTITLES]: {
    key: ConfigTab.SUBTITLES,
    text: (
      <FormattedMessage
        id="single-asset-editor-subtitles-tab-header"
        description="Text for Subtitles tab header"
        defaultMessage="Subs"
      />
    ),
    icon: subtitles,
    errorStatuses: [],
    warningStatuses: [],
    infoStatuses: SUBTITLES_INFO_STATUSES,
  },
};
