import classNames from 'classnames';
import log from 'loglevel';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UploadPurpose } from 'config/constants';
import * as gaUtils from 'utils/gaUtils';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import trashCanIcon from 'images/trash.svg';

import style from './MediaButtons.scss';

import { SnapId } from 'types/common';

const DELETE_MEDIA_FILE = (
  <FormattedMessage
    id="delete-media-file"
    description="Ask for media file delete confirmation"
    defaultMessage="Are you sure you want to delete Media File?"
  />
);
const DELETE_OVERLAY_FILE = (
  <FormattedMessage
    id="delete-overlay"
    description="Ask for overlay delete confirmation"
    defaultMessage="Are you sure you want to delete Image Overlay?"
  />
);
const DELETE_MEDIA_AND_OVERLAY = (
  <FormattedMessage
    id="delete-media-file-and-overlay"
    description="Ask for media file delete confirmation (as well as delete image overlay)"
    defaultMessage="Are you sure you want to delete Media File? Doing this will also cause you to delete Image Overlay"
  />
);

type Props = {
  hasUploadError?: boolean;
  snapId?: SnapId;
  editionId?: number;
  onChange: (...args: any[]) => any;
  purpose: any; // TODO: PropTypes.oneOf(Object.values(UploadPurpose))
  hasOverlay?: boolean;
  isReadOnly?: boolean;
  showDeleteButton?: boolean;
  'data-test'?: string;
};

type State = any;

export class MediaButtons extends React.Component<Props, State> {
  state = {
    showDeleteDialog: false,
  };

  onDelete = () => {
    const params = gaUtils.getGAParamsForUploadPurpose(this.props.purpose, 'delete');
    gaUtils.logGAEvent(params.category, params.action);
    this.hideDeleteAlert();

    switch (this.props.purpose) {
      case UploadPurpose.TOP_SNAP:
        this.props.onChange({
          imageAssetId: null,
          videoAssetId: null,
          overlayImageAssetId: '',
        });
        break;
      case UploadPurpose.CURATED_SNAP_OVERLAY_MEDIA:
      case UploadPurpose.OVERLAY_MEDIA:
        this.props.onChange({
          overlayImageAssetId: '',
        });
        break;
      default:
        log.error(`Unhandled purpose ${this.props.purpose}`);
    }
  };

  getAlertMessage() {
    let message = null;
    switch (this.props.purpose) {
      case UploadPurpose.TOP_SNAP:
        message = this.props.hasOverlay ? DELETE_MEDIA_AND_OVERLAY : DELETE_MEDIA_FILE;
        break;
      case UploadPurpose.CURATED_SNAP_OVERLAY_MEDIA:
      case UploadPurpose.OVERLAY_MEDIA:
        message = DELETE_OVERLAY_FILE;
        break;
      default:
        log.error(`Unhandled purpose ${this.props.purpose}`);
    }
    return message;
  }

  handleDelete = () => {
    if (!this.props.isReadOnly) {
      this.setState({ showDeleteDialog: true });
    }
  };

  hideDeleteAlert = () => {
    this.setState({ showDeleteDialog: false });
  };

  onOk = () => {
    this.onDelete();
    this.hideDeleteAlert();
  };

  onCancel = () => {
    this.hideDeleteAlert();
  };

  renderDeleteAlert() {
    return (
      <SDSDialog
        visible={this.state.showDeleteDialog}
        onOk={this.onOk}
        onCancel={this.onCancel}
        okText={<FormattedMessage id="delete-confirm-text" description="Delete confirm text" defaultMessage="Delete" />}
        title={
          <FormattedMessage id="delete-alert-title" description="Delete alert title" defaultMessage="Delete Alert" />
        }
        data-test={`${this.props['data-test']}Delete`}
        isBodyCentered
      >
        {this.getAlertMessage()}
      </SDSDialog>
    );
  }

  render() {
    return (
      <div className={style.root}>
        <MediaUploader {...this.props} data-test={`${this.props['data-test']}Replace`} />
        {this.props.showDeleteButton && (
          <img
            className={classNames(style.deleteButton, { [style.readOnly]: this.props.isReadOnly })}
            src={trashCanIcon}
            alt={`delete ${this.props.purpose}`}
            onClick={this.handleDelete}
            data-test={`${this.props['data-test']}DeleteButton`}
          />
        )}
        {this.renderDeleteAlert()}
      </div>
    );
  }
}

export default MediaButtons;
