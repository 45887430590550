import classNames from 'classnames';
import { findIndex } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getAuthFailed } from 'state/auth/selectors/authSelectors';
import { fetchEditionBuildStatus } from 'state/buildStatus/actions/buildStatusActions';
import { getEditionBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { getEditionSubscribeSnap } from 'state/editions/selectors/editionsSelectors';
import {
  editTileById,
  initializeSnapComponentPreviews,
  updateSingleAssetEditorState,
} from 'state/editor/actions/editorActions';
import { isUploadingByComponentId } from 'state/media/selectors/mediaSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { getPreviewVolume, isPreviewMuted } from 'state/previews/selectors/previewsSelectors';
import {
  getActiveEdition,
  getStoryChapters,
  getStoryEditorMode,
  shouldUseSingleAssetEditor,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { goToHomepage } from 'state/router/actions/routerActions';
import {
  goToNextSnapOrShot,
  goToPreviousSnapOrShot,
  initializeSingleAssetStory,
  terminateSingleAssetStory,
  updateVideoPlayerTimeAndCheckForAd,
} from 'state/singleAssetStory/actions/singleAssetStoryActions';
import * as singleAssetStorySelectors from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import { getChaptersSummary } from 'state/snaps/schema/snapEntityHelpers';
import { getSubtitlesVisibility } from 'state/subtitles/selectors/subtitlesSelectors';

import VideoPlayerControlsOverlay from '../VideoPlayerControlsOverlay/VideoPlayerControlsOverlay';

import { EditionStatus, EDITOR_MODES } from 'config/constants';
import { setAsyncInterval, clearAsyncInterval } from 'utils/asyncInterval';
import { isDocumentHidden } from 'utils/browserUtils';
import * as componentUtils from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { ModalType } from 'utils/modalConfig';
import { getPlayingSnapOrShot, msToSeconds, secondsToMs } from 'utils/singleAssetStoryEditorUtils';

import SnapEditorPanel from 'views/common/components/SnapEditorPanel/SnapEditorPanel';
import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';
import PublisherEditionMetadata from 'views/common/components/TopsnapPreview/PublisherEditionMetadata';
import SnapEditorStatus from 'views/dashboard/containers/SimpleStoryBuilder/SnapEditorStatus';
import StoryLevelBarBuilder from 'views/dashboard/containers/StoryBuilderCommon/StoryBuilderCommon';
import SnapPublisherPreCache from 'views/editor/components/SnapPublisher/SnapPublisherPreCache';
import { HLSVideoPlayer } from 'views/editor/components/VideoPlayer/HLSVideoPlayer';
import StatusMessage from 'views/editor/containers/StatusMessage/StatusMessage';
import { AlertOptions } from 'views/modals/containers/AlertModal/AlertModal';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import ConfigPanel from 'views/singleAssetStoryEditor/containers/ConfigPanel/ConfigPanel';
import MockAd from 'views/singleAssetStoryEditor/containers/MockAd/MockAd';
import SnapPublisherWithBar from 'views/singleAssetStoryEditor/containers/SnapPublisherWithBar/SnapPublisherWithBar';
import TilePreviewSection from 'views/singleAssetStoryEditor/containers/TilePreviewSection/TilePreviewSection';
import VideoPlayerTimeline from 'views/singleAssetStoryEditor/containers/VideoPlayerTimeline/VideoPlayerTimeline';

import style from './SingleAssetStoryEditor.scss';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as ReduxState } from 'types/rootState';
import { ConfigTab } from 'types/singleAssetStoryEditor';

type OwnProps = {
  children?: React.ReactNode;
  publisherId: PublisherID;
  snapId: SnapId | undefined | null;
  storyId: EditionID;
};

const mapStateToProps = (state: ReduxState, { snapId, storyId }: OwnProps) => {
  const activeStory = getActiveEdition(state);

  const componentId = !snapId ? '' : componentUtils.buildComponentIdForSnapId(snapId);
  const isUploading = componentId ? isUploadingByComponentId(state)(componentId) : false;
  const editionBuildStatus = getEditionBuildStatus(state)(storyId);
  const activeConfigTab = singleAssetStorySelectors.getSingleAssetActiveTab(state)(storyId);
  const isAttachmentEditor = activeConfigTab === ConfigTab.ATTACH; // test

  return {
    activeConfigTab,
    currentTime: singleAssetStorySelectors.getSingleAssetPlayerCurrentTime(state)(storyId),
    isPlaying: singleAssetStorySelectors.getSingleAssetPlayerIsPlaying(state)(storyId),
    isShowingAd: singleAssetStorySelectors.getSingleAssetIsShowingAd(state)(storyId),
    activeStory,
    videoInfo: singleAssetStorySelectors.getVideoInfoForStory(state)(storyId),
    pendingCurrentTime: singleAssetStorySelectors.getSingleAssetPlayerPendingCurrentTime(state)(storyId),
    shots: singleAssetStorySelectors.getShotsForStory(state)(storyId),
    snaps: singleAssetStorySelectors.getTimelineSnaps(state)(storyId),
    chapters: getChaptersSummary(getStoryChapters(state)(storyId)),
    tile: singleAssetStorySelectors.getActiveTileOrPresentationalTile(state)(snapId, storyId),
    isMuted: isPreviewMuted(state),
    subscribeSnap: getEditionSubscribeSnap(state)(storyId),
    isSubscribeSnapSelected: singleAssetStorySelectors.isSubscribeSnapSelected(state),
    editorMode: getStoryEditorMode(state),
    subtitlesPreviewEnabled: getSubtitlesVisibility(state),
    authFailed: getAuthFailed(state),
    isUploading,
    totalDuration: singleAssetStorySelectors.getSingleAssetPlayerDuration(state)(storyId),
    volume: getPreviewVolume(state),
    editionBuildStatus,
    isAttachmentEditor,
    shouldShowNotificationBar: snapAdminSelectors.isShowingCmsNotification(state),
    isInitialTilesOnly: shouldUseSingleAssetEditor(state)(storyId),
  };
};

const mapDispatchToProps = {
  updateSingleAssetEditorState,
  initializeSingleAssetStory,
  terminateSingleAssetStory,
  editTileById,
  updateVideoPlayerTimeAndCheckForAd,
  fetchEditionBuildStatus,
  showModal,
  goToHomepage,
  goToNextSnapOrShot,
  goToPreviousSnapOrShot,
  initializeSnapComponentPreviews,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

const STORY_STATUS_INTERVAL = 2000;

export class SingleAssetStoryEditor extends React.Component<Props> {
  videoPlayer: React.RefObject<any>;

  originalVideoSrc: string | undefined | null;

  originalMimeType: string | undefined | null;

  storyStatusInterval: { clear: boolean };

  constructor(props: Props) {
    super(props);
    this.videoPlayer = React.createRef();
    this.originalVideoSrc = null;
    this.originalMimeType = null;
    this.storyStatusInterval = { clear: false };
  }

  componentDidMount(): void {
    this.props.initializeSingleAssetStory(this.props.publisherId, this.props.storyId);

    this.fetchEditionBuildStatus(); // call once before interval

    setAsyncInterval(this.fetchEditionBuildStatus, STORY_STATUS_INTERVAL, this.storyStatusInterval);

    if (this.props.editionBuildStatus === EditionStatus.SHOT_DETECTION_FAILED) {
      this.showShotDetectionFailureModal();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.pendingCurrentTime !== prevProps.pendingCurrentTime) {
      this.onSetCurrentTime(this.props.pendingCurrentTime);
    }

    if (prevProps.activeConfigTab === ConfigTab.ATTACH && this.props.activeConfigTab !== ConfigTab.ATTACH) {
      this.onSetCurrentTime(this.props.currentTime);
    }

    if (prevProps.activeConfigTab !== ConfigTab.ATTACH && this.props.activeConfigTab === ConfigTab.ATTACH) {
      this.setPlaying(false);
    }

    // ensures that a mock ad is shown if the video is played while the current time is within the ad hitbox.
    // video player onTimeUpdate calls can be delayed up to 250ms after playing, so without this, ads can be missed
    if (!prevProps.isPlaying && this.props.isPlaying) {
      this.onTimeUpdate(msToSeconds(this.props.currentTime + 1));
    }

    // When you edit and save subtitles, a new asset is created and passed to the video player, causing the player to be reinitialised.
    // This causes the player to go black for a couple of seconds and the time to be reset. We force the player to use the original video
    // src to prevent this (ignore the new assets).
    if (this.props.videoInfo && this.props.videoInfo.src && this.props.videoInfo.mimeType) {
      this.originalVideoSrc = this.props.videoInfo.src;
      this.originalMimeType = this.props.videoInfo.mimeType;
    }

    if (
      this.props.editionBuildStatus === EditionStatus.SHOT_DETECTION_FAILED &&
      prevProps.editionBuildStatus !== EditionStatus.SHOT_DETECTION_FAILED
    ) {
      this.showShotDetectionFailureModal();
    }

    if (this.props.snapId !== prevProps.snapId) {
      this.props.initializeSnapComponentPreviews();
    }
  }

  componentWillUnmount(): void {
    this.props.terminateSingleAssetStory(this.props.storyId);
    this.props.updateSingleAssetEditorState(this.props.storyId, {
      isZoomed: false,
      isEditingAds: false,
    });

    if (this.storyStatusInterval) {
      clearAsyncInterval(this.storyStatusInterval);
    }
  }

  // some of the selectors are not correctly memoized so we must avoid re-rendering here
  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, undefined, nextProps, undefined);
  }

  fetchEditionBuildStatus = async () => {
    if (!isDocumentHidden() && !this.props.authFailed) {
      await this.props.fetchEditionBuildStatus({ editionId: this.props.storyId, noBailout: true });
    }
  };

  hideAd = () => {
    this.setPlaying(true);
  };

  onPlay = () => {
    this.setPlaying(true);
  };

  onPause = () => {
    this.setPlaying(false);
  };

  setPlaying = (isPlaying: boolean) => {
    this.props.updateSingleAssetEditorState(this.props.storyId, {
      videoPlayer: {
        isPlaying,
      },
    });
  };

  onPlayPauseButtonClick = () => {
    this.setPlaying(!this.props.isPlaying);
  };

  // Called when player time was manipulated outside of this class
  onSetCurrentTime = (timeInMs: number) => {
    if (this.videoPlayer.current) {
      this.videoPlayer.current.setCurrentTime(msToSeconds(timeInMs));
    }
  };

  // this is called when the video player component time updates
  onTimeUpdate = (timeInSeconds: number): void => {
    const timeInMs = secondsToMs(timeInSeconds);

    this.props.updateVideoPlayerTimeAndCheckForAd(
      timeInMs,
      this.props.snapId,
      this.props.storyId,
      this.props.publisherId
    );
  };

  showSubscribeSnap = () => {
    const { subscribeSnap, isSubscribeSnapSelected } = this.props;

    // it is possible that the subscribe snap is selected but we are on the subtitles tab instead
    return isSubscribeSnapSelected && !!subscribeSnap;
  };

  renderPreviews = () => {
    const { snapId, isShowingAd, isAttachmentEditor, tile, isInitialTilesOnly } = this.props;
    const snapIndex = findIndex(this.props.snaps, s => s.snapId === this.props.snapId);

    let hideTilePreview = !snapId || isShowingAd || this.showSubscribeSnap() || isAttachmentEditor;
    // If INITIAL_TILES_ONLY is enabled we prevent users from adding tiles to non-initial snap.
    // They can still edit the tiles that have been added prior to enabling the feature flag.
    if (isInitialTilesOnly) {
      hideTilePreview ||= snapIndex > 0 && !tile;
    }

    const previewClassNames = classNames(style.previews, {
      [style.previewsWithNotificationBar]: this.props.shouldShowNotificationBar,
      [style.previewsWithoutNotificationBar]: !this.props.shouldShowNotificationBar,
    });

    return (
      <div className={previewClassNames}>
        {this.props.isUploading && <Spinner withBox loading message={SpinnerLabels.UPLOADING} />}
        <TilePreviewSection
          tile={tile}
          snapId={snapId}
          snapIndex={snapIndex}
          className={hideTilePreview ? style.hiddenTile : null}
        />
        {this.renderMedia()}
        {isAttachmentEditor && this.renderAttachmentsMenu()}
      </div>
    );
  };

  renderAttachmentsMenu = () => {
    return (
      <div className={style.attachmentContainer}>
        <div
          className={style.attachmentPanel}
          data-test="SingleAssetStoryEditor.attachmentMenu"
          key={this.props.snapId}
        >
          <SnapEditorStatus showDeleteButton />
          <StatusMessage />
          <SnapEditorPanel />
        </div>
      </div>
    );
  };

  // Returns points the player next/previous should navigate to: chapters for unified shows or snaps.
  getSeekPoints() {
    const { snaps, chapters } = this.props;
    return chapters || snaps;
  }

  onNextClick = () => {
    const { storyId, currentTime } = this.props;
    this.props.goToNextSnapOrShot(storyId, currentTime, this.getSeekPoints());
  };

  onPreviousClick = () => {
    const { storyId, currentTime } = this.props;
    this.props.goToPreviousSnapOrShot(storyId, currentTime, this.getSeekPoints());
  };

  // Do not unmount player when on subscribe snap
  // Saves on initializing the player
  renderVideoPlayer = (show: boolean) => {
    const { videoInfo, currentTime } = this.props;
    if (!videoInfo) {
      return null;
    }

    const currentSnap = getPlayingSnapOrShot(currentTime, this.getSeekPoints());
    const videoSrc = this.originalVideoSrc || videoInfo.src;
    const mimeType = this.originalMimeType || videoInfo.mimeType;

    return (
      <div
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"none" | null' is not assignable to type 'Di... Remove this comment to see the full error message
        style={{ display: show ? null : 'none' }}
        className={style.videoPlayerContainer}
        data-test="SingleAssetStoryEditor.videoPlayerContainer"
      >
        <PublisherEditionMetadata />
        <VideoPlayerControlsOverlay
          onPreviousClick={this.onPreviousClick}
          onNextClick={this.onNextClick}
          onPlayPauseClick={this.onPlayPauseButtonClick}
          isPlaying={this.props.isPlaying}
          hasNext={currentSnap && !currentSnap.isLast}
          hasPrevious={currentSnap && !currentSnap.isFirst}
          playableMode
          data-test="SingleAssetStoryEditor.VideoPlayerControlsOverlay"
        />
        {videoSrc && mimeType && (
          <HLSVideoPlayer
            className={style.video}
            isPlaying={this.props.isPlaying}
            autoplay={false}
            isMuted={this.props.isMuted}
            volume={this.props.volume}
            controls={false}
            src={videoSrc}
            mimeType={mimeType}
            poster={videoInfo.poster}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            subtitleTrack={videoInfo.activeSubtitleTrack}
            data-test="SingleAssetStoryEditor.videoPlayer"
            onTimeUpdate={this.onTimeUpdate}
            onPlay={this.onPlay}
            onPause={this.onPause}
            ref={this.videoPlayer}
            subtitlesPreviewEnabled={this.props.subtitlesPreviewEnabled}
          />
        )}
      </div>
    );
  };

  renderMedia = () => {
    const shouldShowVideoPlayer = !this.showSubscribeSnap() && !this.props.isAttachmentEditor;

    return (
      <div className={style.mediaContainer}>
        {this.props.isShowingAd && <MockAd hideAd={this.hideAd} data-test="SingleAssetStoryEditor.mockAd" />}
        {this.showSubscribeSnap() ? <SnapEditorPanel enableDynamicSizing /> : null}
        {this.renderVideoPlayer(shouldShowVideoPlayer)}
      </div>
    );
  };

  renderVideoPlayerTimeline = () => {
    return (
      <VideoPlayerTimeline
        publisherId={this.props.publisherId}
        activeStoryId={this.props.storyId}
        activeSnapId={this.props.snapId}
        playableMode={!this.props.isAttachmentEditor}
      />
    );
  };

  showShotDetectionFailureModal = () => {
    const modalConfig: AlertOptions = {
      onConfirm: () => {},
      body: (
        <FormattedMessage
          id="single-asset-shot-detection-failure-message"
          description="Message shown in alert modal telling the user that snap generation has failed, so they should create a new story"
          defaultMessage="Snap generation has failed. Please create a new story."
        />
      ),
    };

    this.props.showModal(ModalType.ALERT, 'SingleAssetStoryEditor', modalConfig);
  };

  renderEditor = () => {
    const { editorMode } = this.props;
    const showEditor = editorMode.editorMode === EDITOR_MODES.STORY || editorMode.editorMode === EDITOR_MODES.EDITOR;

    // conditionally rendering the editor and snap publisher causes an infinite loop with editorMode being set to STORY and SNAP_PUB
    // the workaround (used in SimpleStoryBuilder as well) is to just hide the editor with css.
    return (
      <div className={classNames(style.editorContainer, { [style.hidden]: !showEditor })}>
        <div className={style.editorAndTabs}>
          <div className={style.editor}>
            <StoryLevelBarBuilder story={this.props.activeStory} />
            {this.renderPreviews()}
            {this.props.children}
          </div>
          <ConfigPanel activeStoryId={this.props.storyId} activePublisherId={this.props.publisherId} />
        </div>
        {this.renderVideoPlayerTimeline()}
      </div>
    );
  };

  render() {
    const { editorMode } = this.props;
    const showSnapPublisher = editorMode.editorMode === EDITOR_MODES.SNAP_PUB;

    return (
      <div className={style.root} style={{ height: showSnapPublisher ? '100%' : 'auto' }}>
        {showSnapPublisher && <SnapPublisherWithBar data-test="SingleAssetStoryEditor.snapPublisherWithBar" />}
        {this.renderEditor()}
        <SnapPublisherPreCache handshake />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SingleAssetStoryEditor);
