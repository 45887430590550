import React from 'react';
import type { ReactNode } from 'react';

import style from './PanelTitle.scss';

type Props = {
  title: ReactNode;
};

const PanelTitle = ({ title }: Props): JSX.Element => <div className={style.title}>{title}</div>;

export default PanelTitle;
