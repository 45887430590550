import { CallToActionOptions } from 'config/constants';

import { SnapType } from 'types/snaps';
// https://jira.sc-corp.net/browse/ADS-7287
export const CallToActionConfigs = {
  [SnapType.ARTICLE]: [CallToActionOptions.READ],
  [SnapType.CAMERA_ATTACHMENT]: [CallToActionOptions.SEND_TO_OUR_STORY, CallToActionOptions.TRY_LENS],
  [SnapType.REMOTE_WEB]: [
    CallToActionOptions.MORE,
    CallToActionOptions.PLAY,
    CallToActionOptions.READ,
    CallToActionOptions.SHOW,
    CallToActionOptions.VIEW,
    CallToActionOptions.SIGN_UP,
    CallToActionOptions.SHOP_NOW,
    CallToActionOptions.ORDER_NOW,
    CallToActionOptions.WATCH,
    CallToActionOptions.BOOK_NOW,
    CallToActionOptions.BUY_TICKETS,
    CallToActionOptions.SHOWTIMES,
    CallToActionOptions.GET_NOW,
    CallToActionOptions.LISTEN,
    CallToActionOptions.APPLY_NOW,
    CallToActionOptions.LEARN_MORE,
  ],
  [SnapType.LONGFORM_VIDEO]: [CallToActionOptions.WATCH, CallToActionOptions.WATCH_EPISODE],
  [SnapType.POLL]: [CallToActionOptions.VOTE],
  [SnapType.SUBSCRIBE]: [CallToActionOptions.BLANK],
};
