import className from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import SnapItemMenuButtonStyle from '../styles/SnapItemMenuButtonRow.scss';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../icons/adplaysafter.svg.inli... Remove this comment to see the full error message
import adPlayIcon from '../icons/adplaysafter.svg.inline';
import appInstallIcon from '../icons/appinstall.svg';
import articleIcon from '../icons/article.svg';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../icons/noadafter.svg.inline'... Remove this comment to see the full error message
import noAdIcon from '../icons/noadafter.svg.inline';
import noneIcon from '../icons/none.svg';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../icons/options.svg.inline' o... Remove this comment to see the full error message
import optionsIcon from '../icons/options.svg.inline';
import personalityQuizIcon from '../icons/personalityQuiz.svg';
import pollIcon from '../icons/poll.svg';
import quizIcon from '../icons/quiz.svg';
import remoteWebIcon from '../icons/remoteweb.svg';
import videoIcon from '../icons/video.svg';
import voteIcon from '../icons/vote.svg';

export const SnapMenuItemIds = {
  NONE_ATTACHED: 'noneAttached',
  VIDEO_ATTACHED: 'videoAttached',
  ARTICLE_ATTACHED: 'articleAttached',
  POLL_ATTACHED: 'pollAttached',
  VOTE_ATTACHED: 'voteAttached',
  OPEN_QUIZ_QUESTION_ATTACHED: 'openQuizQuestionAttached',
  FACTUAL_QUIZ_QUESTION_ATTACHED: 'factualQuizQuestionAttached',
  REMOTE_WEB_ATTACHED: 'remoteWebAttached',
  OPTIONS: 'options',
  NOTE_PLACEHOLDER: 'notePlaceholder',
  TITLE_PLACEHOLDER: 'titlePlaceholder',
  TILE_TOOLTIP_ADD: 'tileToolTipAdd',
  AD_PLAY: 'adPlay',
  NO_AD: 'noAd',
  NOTIFICATIONS_ATTACHED: 'notificationsAttached',
  CAMERA_ATTACHED: 'cameraAttached',
};
export const SnapMenuItemConfig = {
  [SnapMenuItemIds.NONE_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-none-attached"
        description="Snap that contains no bottom snap"
        defaultMessage="No attachment"
      />
    ),
    icon: noneIcon,
    className: className(
      SnapItemMenuButtonStyle.row,
      SnapItemMenuButtonStyle.disabled,
      SnapItemMenuButtonStyle.bottomSnap
    ),
    'data-test': 'storySnapListItem.menu.noneAttached',
  },
  [SnapMenuItemIds.VIDEO_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-video-attached"
        description="Bottom snap containing video"
        defaultMessage="Video attached"
      />
    ),
    icon: videoIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.videoAttached',
  },
  [SnapMenuItemIds.ARTICLE_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-article-attached"
        description="Bottom snap containing an article"
        defaultMessage="Article attached"
      />
    ),
    icon: articleIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.articleAttached',
  },
  [SnapMenuItemIds.POLL_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-poll-attached"
        description="Bottom snap containing a poll"
        defaultMessage="Poll attached"
      />
    ),
    icon: pollIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.pollAttached',
  },
  [SnapMenuItemIds.VOTE_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-vote-attached"
        description="Bottom snap containing a vote"
        defaultMessage="Vote attached"
      />
    ),
    icon: voteIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.voteAttached',
  },
  [SnapMenuItemIds.OPEN_QUIZ_QUESTION_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-personality-quiz-question-attached"
        description="Personality Quiz Attached"
        defaultMessage="Personality Quiz"
      />
    ),
    icon: personalityQuizIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.openQuizQuestionAttached',
  },
  [SnapMenuItemIds.FACTUAL_QUIZ_QUESTION_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-trivia-quiz-question-attached"
        description="Trivia Quiz Attached"
        defaultMessage="Trivia Quiz"
      />
    ),
    icon: quizIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.factualQuizQuestionAttached',
  },
  [SnapMenuItemIds.REMOTE_WEB_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-remote-web-attached"
        description="Bottom snap containing a web view"
        defaultMessage="Web View attached"
      />
    ),
    icon: remoteWebIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.remoteWebAttached',
  },
  [SnapMenuItemIds.OPTIONS]: {
    text: <FormattedMessage id="snap-options" description="Options for a snap" defaultMessage="Options" />,
    iconImg: <InlineSVG src={optionsIcon} />,
    className: SnapItemMenuButtonStyle.row,
    'data-test': 'storySnapListItem.menu.options',
  },
  [SnapMenuItemIds.NOTE_PLACEHOLDER]: {
    text: (
      <FormattedMessage
        id="snap-menu-notes-placeholder"
        description="Text used when there is lack of notes"
        defaultMessage="Notes..."
      />
    ),
    isNotes: true,
    'data-test': 'storySnapListItem.notePlaceholder',
  },
  [SnapMenuItemIds.TITLE_PLACEHOLDER]: {
    text: (
      <FormattedMessage
        id="title-placeholder"
        description="Text used when there is lack of a title"
        defaultMessage="Untitled"
      />
    ),
    isNotes: false,
    'data-test': 'storySnapListItem.menu.titlePlaceholder',
  },
  [SnapMenuItemIds.TILE_TOOLTIP_ADD]: {
    text: (
      <FormattedMessage
        id="add-tile-tooltip"
        description="Text used as tooltip to add new tile variant"
        defaultMessage="Upload new A/B tile"
      />
    ),
    isNotes: false,
  },
  [SnapMenuItemIds.AD_PLAY]: {
    text: (
      <FormattedMessage
        id="ad-play-after"
        description="The ad will play after this snap"
        defaultMessage="Ad plays after"
      />
    ),
    iconImg: <InlineSVG src={adPlayIcon} />,
    className: SnapItemMenuButtonStyle.row,
    'data-test': 'storySnapListItem.menu.adPlaysAfter',
  },
  [SnapMenuItemIds.NO_AD]: {
    text: (
      <FormattedMessage
        id="no-ad-after"
        description="The ad will not play after this snap"
        defaultMessage="No ad after"
      />
    ),
    iconImg: <InlineSVG src={noAdIcon} />,
    className: className(SnapItemMenuButtonStyle.row, (SnapItemMenuButtonStyle as any).placeholder),
    'data-test': 'storySnapListItem.menu.noAdAfter',
  },
  [SnapMenuItemIds.NOTIFICATIONS_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-notification-settings-attached"
        description="Bottom snap containing a notification settings attachment"
        defaultMessage="Notifications"
      />
    ),
    icon: appInstallIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.notifications',
  },
  [SnapMenuItemIds.CAMERA_ATTACHED]: {
    text: (
      <FormattedMessage
        id="snap-camera-attached"
        description="Bottom snap containing a camera attachment"
        defaultMessage="Camera Attachment"
      />
    ),
    icon: appInstallIcon,
    className: className(SnapItemMenuButtonStyle.row, SnapItemMenuButtonStyle.bottomSnap),
    'data-test': 'storySnapListItem.menu.camera',
  },
};
