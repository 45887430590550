/* global CKEDITOR */
/* eslint-disable new-cap, no-param-reassign */
import { applyFnChain } from 'utils/functionUtils';

import { MetaAttributes as SCVideoMetaAttributes } from './SCVideo';

// @see http://docs.ckeditor.com/#!/api/CKEDITOR.editor-event-toDataFormat
const FilteringStages = {
  HTML_STRING_PRE_FILTER: 1,
  HTML_ELEMENTS_PRE_FILTER: 5,
  HTML_ELEMENTS_POST_FILTER: 12,
  HTML_STRING_POST_FILTER: 16,
};

const ALL_ELEMENTS = '$';

const WHITESPACE = /^(&nbsp;|&#160;|&#xa0;|\s|\t)*$/;

export default class SCHTMLOutputFilter {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCHTMLOutputFilter';
  }

  static define(registry: any) {
    registry.registerPlugin(SCHTMLOutputFilter, {
      init(editor: any) {
        let root: any;
        let elementsMap: any;

        handleStage(FilteringStages.HTML_ELEMENTS_PRE_FILTER, (event: any) => {
          root = event.data.dataValue;
          elementsMap = {};
        });

        // Collect all elements and bucketize by type
        addElementRule(ALL_ELEMENTS, (element: any) => {
          getElementsOfType(element.name).push(element);
        });

        // ---------------------------------------------------------------------------------------------------
        // FilteringStages.HTML_ELEMENTS_POST_FILTER
        //
        // CKEditor provides its own lightweight DOM representation that is used while the editor content is
        // being processed for HTML output. Here we apply some filtering steps on this DOM representation in
        // order to ensure it's properly formatted for display in the app.
        //
        // @see http://docs.ckeditor.com/#!/api/CKEDITOR.editor-event-toDataFormat
        // ---------------------------------------------------------------------------------------------------
        handleStage(FilteringStages.HTML_ELEMENTS_POST_FILTER, () => {
          const images = getElementsOfType('img');

          /*
           * Move any images that are nested within other container elements up to the top level. This is done
           * for styling reasons, as it ensures that images will always appear full width without and additional
           * padding/margin added by the parent elements.
           */
          while (images.some(hoistImageIfNested)) {
            /* repeat until no images need unwrapping */
          }

          function hoistImageIfNested(image: any) {
            if (image.parent && image.parent !== root) {
              const container = image.parent;
              const elementsAfterImage = getElementsAfter(image);

              image.remove();
              image.insertAfter(container);

              // In order to maintain the order of nodes that were adjacent to the image, we take each of the
              // nodes that originally came after it and move them into a new container. The new container is
              // then inserted after the image, which honors the original sequence.
              if (elementsAfterImage.length > 0) {
                const newContainer = createElementOfMatchingType(container);
                addChildren(newContainer, elementsAfterImage);
                newContainer.insertAfter(image);
              }

              // As we've removed the image from its previous container, we may have left behind a subtree of
              // now-empty containers. These have no value and can lead to spacing issues in the client, so we
              // remove them.
              recursivelyRemoveEmptyElementsUpwards(container);

              return true;
            }

            return false;
          }

          /*
           * Reassign any alt attributes on <img> nodes created by the SCVideo plugin back to the actual src
           * attribute. This ensures that the raw version of the video preview gets saved, rather version of
           * the video preview image seen in the editor which has the 'play' icon composited onto it.
           */
          images.forEach((image: any) => {
            const isVideoPreviewImage = !!image.attributes[SCVideoMetaAttributes.VIDEO_ID];

            if (isVideoPreviewImage) {
              const originalSrc = image.attributes[SCVideoMetaAttributes.ORIGINAL_SRC];

              if (originalSrc) {
                image.attributes.src = originalSrc;
                delete image.attributes[SCVideoMetaAttributes.ORIGINAL_SRC];
              }
            }
          });

          /*
           * Loose non-block level elements can sometimes occur in pasted content, and result in spacing issues
           * if not wrapped in a <p> tag.
           */
          const looseStylingElements = getElementsOfType('br')
            .concat(getElementsOfType('b'))
            .concat(getElementsOfType('u'))
            .concat(getElementsOfType('i'))
            .concat(getLooseTextNodes());

          looseStylingElements.forEach(wrapInParagraphIfAtRoot);

          function wrapInParagraphIfAtRoot(element: any) {
            if (element.parent === root) {
              // @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'CKEDITOR'. Did you mean 'editor'... Remove this comment to see the full error message
              element.wrapWith(new CKEDITOR.htmlParser.element('p')); // eslint-disable-line new-cap
            }
          }

          /*
           * Empty paragraphs or paragraphs that just contain spaces and tabs are not rendered by the WebView
           * in the client, which leads to paragraph spacing inconsistencies between content seen in the editor
           * and the final result in the app.
           */
          getElementsOfType('p').filter(isEmptyOrJustContainsWhitespace).forEach(replaceChildrenWithBr);

          function isEmptyOrJustContainsWhitespace(element: any) {
            return (
              element.children.length === 0 ||
              element.children.every((child: any) => isTextNode(child) && WHITESPACE.test(child.value))
            );
          }

          function replaceChildrenWithBr(element: any) {
            element.setHtml('<br />');
          }
        });

        // ---------------------------------------------------------------------------------------------------
        // FilteringStages.HTML_STRING_POST_FILTER
        //
        // In addition to the filters that are defined above using CKEditor's htmlFilter, we also perform some
        // final cleaning up by operating directly on the HTML output string.
        //
        // @see http://docs.ckeditor.com/#!/api/CKEDITOR.editor-event-toDataFormat
        // ---------------------------------------------------------------------------------------------------
        handleStage(FilteringStages.HTML_STRING_POST_FILTER, (event: any) => {
          applyHtmlStringFilterChain(event, [
            /*
             * Pasted HTML often includes a lot of non-breaking spaces that don't make sense into the context
             * of a narrower snap-sized article. So in order to ensure that lines wrap naturally, we remove
             * non-breaking spaces.
             */
            (html: any) => html.replace(/&nbsp;/g, ' ').replace(/  /g, ' &nbsp;'), // eslint-disable-line no-regex-spaces

            /*
             * Fixes an issue whereby pasted HTML that contains \n characters gets extra paragraphs inserted.
             */
            (html: any) => html.replace(/\n/g, ''),
          ]);
        });

        // -------------------------------------------------------------------------------------------------------------
        // Helpers
        // -------------------------------------------------------------------------------------------------------------

        function addElementRule(elementType: any, filterFunction: any) {
          editor.dataProcessor.htmlFilter.addRules({
            elements: {
              [elementType]: filterFunction,
            },
          });
        }

        function handleStage(stage: any, handler: any) {
          editor.on('toDataFormat', handler, null, null, stage);
        }

        function getElementsOfType(type: any) {
          let elements = elementsMap[type];
          if (!elements) {
            elements = [];
            elementsMap[type] = elements;
          }
          return elements;
        }

        function getLooseTextNodes() {
          return root.children.filter(isTextNode);
        }

        function isTextNode(node: any) {
          // @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'CKEDITOR'. Did you mean 'editor'... Remove this comment to see the full error message
          return node instanceof CKEDITOR.htmlParser.text;
        }

        function createElementOfMatchingType(element: any) {
          // @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'CKEDITOR'. Did you mean 'editor'... Remove this comment to see the full error message
          return new CKEDITOR.htmlParser.element(element.name); // eslint-disable-line new-cap
        }

        function addChildren(parent: any, children: any) {
          children.forEach((child: any) => {
            child.remove();
            parent.add(child);
          });
        }

        function getElementsAfter(element: any) {
          return element.parent ? element.parent.children.slice(element.getIndex() + 1) : [];
        }

        function recursivelyRemoveEmptyElementsUpwards(element: any) {
          if (element && element !== root && element.children.length === 0) {
            const { parent } = element;
            element.remove();
            recursivelyRemoveEmptyElementsUpwards(parent);
          }
        }

        function applyHtmlStringFilterChain(event: any, chain: any) {
          let html = event.data.dataValue;

          if (html) {
            html = applyFnChain(html, chain);
            event.data.dataValue = html; // eslint-disable-line no-param-reassign
          }
        }
      },
    });
  }
}
