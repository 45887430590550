import classNames from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { document, setTimeout } from 'global';
import is from 'is_js';
import _ from 'lodash';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { defer } from 'q';
import React from 'react';
import Frame from 'react-frame-component';
import { FormattedMessage } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'stri... Remove this comment to see the full error message
import striptags from 'striptags';

import ArticleHTMLBuilder from 'state/article/actions/ArticleHTMLBuilder';
import * as articleActions from 'state/article/actions/articleActions';
import * as articleSelectors from 'state/article/selectors/articleSelectors';
import * as assetActions from 'state/asset/actions/assetActions';
import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publishersStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import ContentStatusPanel from '../ContentStatusPanel/ContentStatusPanel';

import { ContentStatus, SECONDS } from 'config/constants';
import { ArticleFields } from 'src/state/article/articleState';
import { State } from 'src/types/rootState';
import { assertState } from 'utils/assertionUtils';
import * as brightcoveUtils from 'utils/brightcoveUtils';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import LoadLocalContentModal from 'views/modals/containers/LoadLocalContentModal/LoadLocalContentModal';

import style from './ArticleEditor.scss';
import AsyncCKEditorLoaderFactory from './utils/AsyncCKEditorLoaderFactory';
import { CKEditorConfigurator } from './utils/CKEditorConfigurator';

import { AttachmentType } from 'types/attachments';
import { SnapId } from 'types/common';

function mapStateToProps(state: State, props: ExternalProps) {
  const snapId = extractSnapIdFromComponentId(props.snapComponentId);
  const isReadOnly = editorSelectors.isReadOnly(state);
  const articleContentFieldIsFocused = editorSelectors.articleContentFieldIsFocused(state);
  const sharedToolbarId = editorSelectors.getSharedToolbarId(state);
  const editionId = editorSelectors.getActiveEditionId(state);
  const primaryLanguage = publishersSelectors.getActivePublisherPrimaryLanguage(state);
  return {
    snapId,
    editionId,
    article: {
      status: articleSelectors.getStatus(state),
      fields: articleSelectors.getFields(state),
      attachments: articleSelectors.getAttachments(state),
      isUnsaved: articleSelectors.isUnsaved(state),
    },
    isReadOnly,
    articleContentFieldIsFocused,
    sharedToolbarId,
    primaryLanguage,
    snap: snapsSelectors.getSnapById(state)(snapId),
    isIngestingUrl: publishersStoryEditorSelectors.getIngestingArticleBySnapId(state)(snapId),
  };
}
const mapDispatchToProps = {
  loadArticle: articleActions.loadArticle,
  saveArticle: articleActions.saveArticle,
  processArticleImages: articleActions.processArticleImages,
  processArticleVideos: articleActions.processArticleVideos,
  notifyArticleEdited: articleActions.notifyArticleEdited,
  loadAssetInfo: assetActions.loadAssetInfo,
  showModal: modalsActions.showModal,
  openPreview: mediaActions.openPreview,
  setEditorConfigProperties: editorActions.setEditorConfigProperties,
  getLocalBottomSnapFields: editorActions.getLocalBottomSnapFields,
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = {
  getLocalBottomSnapFields: (bottomSnapId: SnapId) => ArticleFields | null;
  notifyArticleEdited: typeof articleActions.notifyArticleEdited;
};

type ExternalProps = {
  snapComponentId: string;
  saveOnLoad?: boolean;
  ckeditorLoaderFactory?: any;
  onSave?: (...args: any[]) => any;
  className: string;
};

type Props = ExternalProps & StateProps & DispatchProps;

type OwnState = {
  loaded: boolean;
  isLocalContentModalVisible: boolean;
};
export class ArticleEditor extends React.Component<Props, OwnState> {
  static contextTypes = {
    store: PropTypes.object,
  };

  _articleLoadPromise: any;

  _channelDiv: any;

  _editor: any;

  _editorDeferral: any;

  _editorDiv: any;

  _headlineDiv: any;

  _secondaryHeadlineDiv: any;

  _updatingArticleFields: any;

  // Some trivial state that isn't really worth the additional indirection cost of
  // adding to the Redux store.
  state = {
    loaded: false,
    isLocalContentModalVisible: false,
  };

  _lastSharedToolbar = null;

  componentDidMount() {
    this._editorDeferral = defer();
    if (this.props.sharedToolbarId) {
      this._makeEditor();
    }
    this.loadArticle(this.props.article.fields);
    this._setEditorReadOnlyState();
  }

  shouldComponentUpdate(nextProps: Props, nextState: OwnState) {
    return (
      this.props.article !== nextProps.article ||
      this.state !== nextState ||
      this.props.sharedToolbarId !== nextProps.sharedToolbarId ||
      this.props.articleContentFieldIsFocused !== nextProps.articleContentFieldIsFocused
    );
  }

  componentDidUpdate(prevProps: Props) {
    // If we have been passed a new sharedToolbarId - give it to ckeditorConfigurator
    if (this.props.sharedToolbarId !== prevProps.sharedToolbarId) {
      this._makeEditor();
    }
    // Only modify DOM node contents if the article has been modified, and only if the reason
    // it was modified was that an article was loaded. Modifying fields in response to other
    // article statuses can result in the cursor position being lost, as the underlying DOM
    // is modified while the user is interacting with the contents.
    const hasBeenLoaded =
      this.props.article.status === ContentStatus.LOADED_CLEAN &&
      prevProps.article.status !== ContentStatus.LOADED_CLEAN;
    const hasBeenIngested = !this.props.isIngestingUrl && prevProps.isIngestingUrl;
    const editorHtmlChanged = _.get(this.props.snap, ['editorHtml']) !== _.get(prevProps.snap, ['editorHtml']);
    if (editorHtmlChanged && hasBeenIngested) {
      this.reloadArticle(true);
    } else if (this.props.article !== prevProps.article && (hasBeenLoaded || hasBeenIngested)) {
      this.loadArticle(this.props.article.fields);
    }
    if (this.props.saveOnLoad && Object.keys(this.props.article.attachments).length > 0 && this.state.loaded) {
      if (this._updatingArticleFields && this._articleLoadPromise) {
        this._articleLoadPromise.then(() => {
          this._saveArticle();
        });
      } else {
        this._saveArticle();
      }
    }
    this._setEditorReadOnlyState();
  }

  componentWillUnmount() {
    (this.props as any).setEditorConfigProperties({ articleContentFieldIsFocused: false });
    if (this._editor) {
      this._editor.destroy();
    }
  }

  loadArticle(articleFields: ArticleFields | null) {
    this._updatingArticleFields = true;
    // It'd be better to do this in render() using JSX, but for the main content field we
    // have to call CKEditor's setData() method in order for CKEditor to be aware of the
    // change. So, in order to be consistent, I decided to do all field setting for each
    // of the article fields using refs.
    if (this._channelDiv) {
      this._channelDiv.textContent = articleFields?.channel || '';
    }
    if (this._headlineDiv) {
      this._headlineDiv.textContent = articleFields?.headline || '';
    }
    if (this._secondaryHeadlineDiv) {
      this._secondaryHeadlineDiv.textContent = articleFields?.secondaryHeadline || '';
    }
    this._articleLoadPromise = this.setEditorContentAsHTML(articleFields?.articleContent || '')
      .then(() => {
        this._updatingArticleFields = false;
        this._articleLoadPromise = null;
      })
      .catch((err: any) => {
        log.error('Error setting editor content from HTML', err);
      });
  }

  _setChannelDiv = (ref: any) => {
    this._channelDiv = ref;
    if (this._channelDiv) {
      this._channelDiv.textContent = _.get(this.props.article, ['fields', 'channel'], '');
    }
  };

  _setHeadlineDiv = (ref: any) => {
    this._headlineDiv = ref;
    if (this._headlineDiv) {
      this._headlineDiv.textContent = _.get(this.props.article, ['fields', 'headline'], '');
    }
  };

  _setSecondaryHeadlineDiv = (ref: any) => {
    this._secondaryHeadlineDiv = ref;
    if (this._secondaryHeadlineDiv) {
      this._secondaryHeadlineDiv.textContent = _.get(this.props.article, ['fields', 'secondaryHeadline'], '');
    }
  };

  getEditorContentAsHTML() {
    try {
      return this._editor.getData();
    } catch (err) {
      log.error('Error converting editor state to HTML', err);
      return '';
    }
  }

  setEditorContentAsHTML(html: any) {
    return this._editorDeferral.promise.then(
      () =>
        new Promise((resolve, reject) => {
          this._editor.setData(html, () => {
            if (this._editorDiv) {
              brightcoveUtils.addBrightcovePreviewHandlers(this._editorDiv, (this.props as any).openPreview);
            }
            // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
            resolve();
          });
          // In case the call fails, ensure that an error is thrown eventually.
          setTimeout(() => {
            reject(new Error('timeout setting data'));
          }, 20 * SECONDS);
        })
    );
  }

  _getSharedToolbarId() {
    return this.props.sharedToolbarId;
  }

  _getSharedToolbar() {
    if (!this._lastSharedToolbar) {
      this._lastSharedToolbar = document.querySelector(`#${this.props.sharedToolbarId}`);
    }
    return this._lastSharedToolbar;
  }

  _setEditorDiv = (ref: any) => {
    this._editorDiv = ref;
    if (this._editorDiv) {
      this._makeEditor();
    }
  };

  _makeEditor = () => {
    if (this._editor) {
      return;
    }
    if ((!this._getSharedToolbar() && !this.props.saveOnLoad) || !this._editorDiv) {
      return;
    }
    const ckeditorConfigurator = new CKEditorConfigurator({
      sharedToolbarId: this._getSharedToolbarId(),
      store: this.context.store,
      actions: {
        loadAssetInfo: (this.props as any).loadAssetInfo,
        showModal: (this.props as any).showModal,
        openPreview: (this.props as any).openPreview,
      },
    });
    const ckeditorLoaderFactory = this.props.ckeditorLoaderFactory || AsyncCKEditorLoaderFactory;
    this._editor = ckeditorLoaderFactory.create(this._editorDiv, ckeditorConfigurator);
    this._editor.on('instanceReady', this._handleInstanceReady);
    this._editor.on('change', this._markDirty);
    this._editor.on('focus', this._onContentFieldFocused);
    this._editor.on('blur', this._onContentFieldBlurred);
    this._editor.on('selectionChange', this._guessFontDelayed);

    if (__DEBUG__) {
      (window as any).editor = this._editor;
    }
    this._editorDeferral.resolve(this._editor);
    gaUtils.logGAEvent(gaUtils.GAQoSMetrics.GENERAL, 'article-load');
  };

  reloadArticle = (reload = false, loadFromLocalStorage = false) => {
    return (this.props as any)
      .loadArticle({
        snapId: this.props.snapId,
        htmlParser: this._editor.parseHtml.bind(this._editor),
        reload,
        loadFromLocalStorage,
      })
      .then(() => {
        this.setState({ loaded: true });
      });
  };

  _handleInstanceReady = () => {
    const localFields = (this.props as any).getLocalBottomSnapFields(this.props.snapId);
    if (localFields) {
      this.showLocalContentModal();
    }
    this.reloadArticle();
  };

  _setEditorReadOnlyState = () => {
    if (this._editor && this._editor.instanceReady) {
      this._editor.setReadOnly(this.props.isReadOnly);
    }
  };

  _isLocked = () => {
    return this.props.isReadOnly || this._isSaving();
  };

  _isSaving = () => {
    return this.props.article.status === ContentStatus.SAVING;
  };

  _guessFontDelayed = () => {
    setTimeout(() => this._guessFont());
  };

  _guessFont = () => {
    const sharedToolbar = this._getSharedToolbar();
    /*
          Okay to throw here, won't propogate higher up because of setTimeout in
          _guessFontDelayed.

          These should never actually trigger because the toolbar is guaranteed
          to exist before the editor is created.

          See: _makeEditor()
        */
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(sharedToolbar).is.truthy();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(sharedToolbar.querySelector).is.function();
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const fontButton = sharedToolbar.querySelector('.cke_combo_button .cke_combo_text');
    if (!fontButton) {
      return;
    }
    let guessedFont = String(fontButton.textContent);
    if (guessedFont.toLowerCase() === 'font') {
      guessedFont = '';
    }
    fontButton.style.fontFamily = guessedFont;
  };

  _handlePlaintextFieldChanged = (event: any) => {
    if (!this.props.isReadOnly) {
      // Strip any pasted HTML from the input, so that the formatting applied
      // by the channel/heading/subheading field CSS is correctly honoured.
      event.target.textContent = striptags(event.target.textContent); // eslint-disable-line no-param-reassign
      // Safari moves the cursor to the beginning of the input when textContent is updated.
      // To counteract this, we need to move it back to the end.
      if (is.safari()) {
        const range = document.createRange();
        range.selectNodeContents(event.target);
        range.collapse(false);
        const sel = window.getSelection();
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        sel.removeAllRanges();
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        sel.addRange(range);
      }
      this._markDirty();
    }
  };

  _markDirty = () => {
    if (!this.props.isReadOnly && !this._updatingArticleFields) {
      (this.props as any).notifyArticleEdited({ snap: this.props.snapId }, this._getArticleFields());
    }
  };

  _saveArticle = () => {
    if (!this.props.isReadOnly) {
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-save');
      const fields = this._getArticleFields();
      // @ts-expect-error
      const htmlBuilder = new ArticleHTMLBuilder(fields);
      const attachmentsByType = this.props.article.attachments;

      this.props
        // @ts-expect-error
        .processArticleImages(htmlBuilder, fields.imageAssetIds || [], this.props.editionId)
        // @ts-expect-error
        .then(() => this.props.processArticleVideos(htmlBuilder, this.props.editionId))
        .then(() => {
          // @ts-expect-error
          this.props.saveArticle({ snap: this.props.snapId }, htmlBuilder, attachmentsByType).then(() => {
            if (this.props.onSave) {
              this.props.onSave();
            }
          });
        });
    }
  };

  _getArticleFields() {
    return {
      ...this.props.article.fields,
      channel: this._channelDiv.textContent,
      headline: this._headlineDiv.textContent,
      secondaryHeadline: this._secondaryHeadlineDiv.textContent,
      articleContent: this.getEditorContentAsHTML(),
      translatedMessages: getMessageFromId('update-snapchat-article-message', {}),
    };
  }

  _onContentFieldFocused = () => {
    (this.props as any).setEditorConfigProperties({ articleContentFieldIsFocused: true });
  };

  _onContentFieldBlurred = () => {
    (this.props as any).setEditorConfigProperties({ articleContentFieldIsFocused: false });
  };

  renderContentStatusPanel(isSaving: any, article: any) {
    return (
      <ContentStatusPanel
        status={article.status}
        isReadOnly={this.props.isReadOnly || false}
        onSave={this._saveArticle}
      />
    );
  }

  _getArticleHeadTags(cssAttachment: any) {
    const headTags = [];
    // This prevents potentially sensitive information such as publisherIds from being leaked
    // via the referrer header to servers hosting images that are pasted into articles.
    headTags.push(<meta key="meta" name="referrer" content="no-referrer" />);
    // Calling `dangerouslySetInnerHTML` is required here because by default React will encode
    // CSS which in turn makes invalidates the syntax. There's no security risk since the CSS
    // is our own.
    //
    // This has been approved by Infosec.
    //
    /* eslint-disable react/no-danger */
    // @ts-ignore

    // Forgive me Father for I have sinned.
    const previewFrameStyles =
      '.in-preview-container {\n' +
      '  .optional:empty:before {\n' +
      "    content: '(optional)';\n" +
      '    opacity: 0.3;\n' +
      '  }\n' +
      '\n' +
      '  .editor.show-placeholder:before {\n' +
      "    content: 'Click to start writing!';\n" +
      '    opacity: 0.3;\n' +
      '    margin-left: 12px;\n' +
      '  }\n' +
      '\n' +
      '  .center-justify {\n' +
      '    text-align: center;\n' +
      '  }\n' +
      '\n' +
      '  .right-justify {\n' +
      '    text-align: right;\n' +
      '  }\n' +
      '\n' +
      '  img {\n' +
      '    vertical-align: middle;\n' +
      '  }\n' +
      '}\n';

    headTags.push(<style key="previewFrameStyles" dangerouslySetInnerHTML={{ __html: previewFrameStyles }} />);
    if (cssAttachment) {
      headTags.push(<style key="articleStyles" dangerouslySetInnerHTML={{ __html: cssAttachment.content }} />);
    }
    /* eslint-enable react/no-danger */
    return headTags;
  }

  showLocalContentModal = () => {
    this.setState({ isLocalContentModalVisible: true });
  };

  onConfirmLocalContentModal = () => {
    const localFields = this.props.getLocalBottomSnapFields(this.props.snapId);
    this.loadArticle(localFields);
    this.setState({ isLocalContentModalVisible: false });
    return this.props.notifyArticleEdited({ snap: this.props.snapId }, localFields);
  };

  onCancelLocalContentModal = () => {
    this.setState({ isLocalContentModalVisible: false });
  };

  renderLocalContentModal = () => {
    return (
      <LoadLocalContentModal
        snapId={this.props.snapId}
        onConfirm={this.onConfirmLocalContentModal}
        onCancel={this.onCancelLocalContentModal}
        visible={this.state.isLocalContentModalVisible}
      />
    );
  };

  renderSpinner() {
    const loadingString = this.props.isIngestingUrl ? (
      <FormattedMessage
        id="ingesting-article-spinner-message"
        defaultMessage="Ingesting Article..."
        description="message when an article is in the process of being ingested"
      />
    ) : (
      <FormattedMessage
        id="loading-article-spinner-message"
        defaultMessage="Loading Article..."
        description="message when an article is in the process of being loaded"
      />
    );
    return (
      <div className={classNames(style.editorContainerInner, style.spinner)} data-test="ArticleEditor.Spinner">
        <SpinnerIcon className={style.spinnerIcon} />
        {loadingString}
      </div>
    );
  }

  renderCKEditor(isLoading: any) {
    const cssAttachment = this.props.article.attachments[AttachmentType.CSS];
    const articleIsEmpty = !this.props.article.fields?.articleContent;
    return (
      <Frame
        id="ArticleEditorFrame"
        className={classNames(style.editorContainerInner, { [style.isHidden]: isLoading })}
        head={this._getArticleHeadTags(cssAttachment)}
      >
        <div className="article-editor-frame-root">
          <div className="in-preview-container">
            <div className="in-preview-channel">
              <div
                ref={this._setChannelDiv}
                className="inner optional"
                onInput={this._handlePlaintextFieldChanged}
                contentEditable={!this._isLocked()}
                dir="auto"
              />
            </div>
            <div className="in-preview-headline">
              <div
                ref={this._setHeadlineDiv}
                className="inner optional"
                onInput={this._handlePlaintextFieldChanged}
                contentEditable={!this._isLocked()}
                dir="auto"
              />
              <div
                ref={this._setSecondaryHeadlineDiv}
                className="secondary optional"
                onInput={this._handlePlaintextFieldChanged}
                contentEditable={!this._isLocked()}
                dir="auto"
              />
            </div>
            <div className="in-preview-content">
              <div
                ref={this._setEditorDiv}
                className={classNames('inner', 'editor', { 'show-placeholder': articleIsEmpty })}
                onInput={this._markDirty}
                contentEditable={!this._isLocked()}
                dir="auto"
              />
            </div>
          </div>
        </div>
      </Frame>
    );
  }

  render() {
    const { article } = this.props;
    const cssAttachment = article.attachments[AttachmentType.CSS];
    const isSaving = this._isSaving();
    const rootClassName = classNames(this.props.className, style.wrapper, {
      [style.isSaving]: isSaving,
    });
    const isLoading = this.props.isIngestingUrl || !cssAttachment;
    return (
      <>
        <div className={rootClassName} data-test="ArticleEditor">
          {this.renderContentStatusPanel(isSaving, article)}
          <div className={classNames(style.editorContainerOuter, { [style.isLocked]: this._isLocked() })}>
            {this.renderCKEditor(isLoading)}
            {isLoading && this.renderSpinner()}
          </div>
        </div>
        {this.renderLocalContentModal()}
      </>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(ArticleEditor);
