import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import validator from 'validator';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { isCurationPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import ContentStatusPanel from '../ContentStatusPanel/ContentStatusPanel';

import { CrossOrigin } from 'config/constants';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as uuidUtils from 'utils/uuidUtils';

import style from './CameraAttachmentEditor.scss';

import type { CameraAttachmentSnap } from 'types/cameraAttachment';
import type { SnapId } from 'types/common';
import { RichSnapComponentId } from 'types/components';
import type { Publisher } from 'types/publishers';
import type { State as RootState } from 'types/rootState';

type IntlProps = {};

type ExternalProps = {
  snapComponentId: RichSnapComponentId;
};

type StateProps = {
  snapId: SnapId;
  lensScancodeId: string;
  lensId: number | undefined | null;
  cameraAttachment: CameraAttachmentSnap | undefined | null;
  contentStatus: string;
  isReadOnly: boolean | undefined | null;
  isValidCameraAttachment: boolean;
  currentPublisher: Publisher | null;
};

type DispatchProps = {
  loadCameraAttachment: typeof stagesActions.stageData;
  saveCameraAttachment: typeof stagesActions.commitData;
};

type Props = ExternalProps & StateProps & DispatchProps & IntlProps;
type State = {};

const mapStateToProps = (state: RootState, props: ExternalProps): StateProps => {
  const snapId = extractSnapIdFromComponentId(props.snapComponentId);
  const cameraAttachment = stagesSelectors.getData(state)(snapId);
  const lensScancodeId = get(cameraAttachment, ['lenses', '0', 'lensScancodeId']) || '';
  const lensId = get(cameraAttachment, ['lenses', '0', 'lensId']) || null;
  const isReadOnly = editorSelectors.isReadOnly(state);
  const contentStatus = stagesSelectors.getContentStatus(state)(snapId);
  const isValidCameraAttachment = editorSelectors.isValidCameraAttachment(state);
  const currentPublisher = getActivePublisherDetails(state);

  return {
    snapId,
    cameraAttachment,
    lensScancodeId,
    lensId,
    contentStatus,
    isReadOnly,
    isValidCameraAttachment,
    currentPublisher,
  };
};

const mapDispatchToProps = {
  loadCameraAttachment: stagesActions.stageData,
  saveCameraAttachment: stagesActions.commitData,
};

export class CameraAttachmentEditor extends React.Component<Props, State> {
  componentDidMount() {
    if (this.props.cameraAttachment === null) {
      this.props.loadCameraAttachment(this.getId());
    }
  }

  getId() {
    return this.props.snapId;
  }

  getScancodeId() {
    const { cameraAttachment } = this.props;
    return get(cameraAttachment, ['lenses', '0', 'lensScancodeId']);
  }

  getScancodeImageUrl() {
    const { lensScancodeId } = this.props;

    if (!lensScancodeId || lensScancodeId === '') {
      return '';
    }

    // needs to get the URL, strip the UUID, strip the dashes and add to the url below
    const uuidWithNoDashes = uuidUtils.removeDashesFromUuid(lensScancodeId);

    return `https://app.snapchat.com/web/deeplink/snapcode?data=${uuidWithNoDashes}&version=1&type=svg&image_style=LENS`;
  }

  save = () => {
    const { isReadOnly } = this.props;
    if (!isReadOnly) {
      this.props.saveCameraAttachment(this.getId());
    }
  };

  renderEmptyCameraAttachmentPreview = () => {
    return (
      <div className={style.emptyCamera}>
        <div className={style.emptyHeader}>
          <div className={style.cameraAttachmentHeader} data-test="cameraAttachmentEditor.heading">
            <FormattedMessage
              id="create-your-camera-attachment"
              description="Create camera attachment area"
              defaultMessage="Create your Camera attachment"
            />
          </div>
          <div className={style.cameraAttachmentDescription} data-test="cameraAttachmentEditor.description">
            <FormattedMessage
              id="create-your-camera-attachment-text"
              description="Create camera attachment description"
              defaultMessage="Paste your lens id in the panel to the right."
            />
          </div>
        </div>
        <div className={style.emptyAttachment} data-test="cameraAttachmentEditor.emptyAttachment">
          <FormattedMessage
            id="empty-camera-attachment"
            description="Create camera attachment web area"
            defaultMessage="No valid lenses yet"
          />
        </div>
      </div>
    );
  };

  renderLensId() {
    return [
      <div key="lens-id-1" className={style.findSnapsWithLensMessage}>
        <FormattedMessage
          id="find-snaps-with-lens-message"
          description="Message displayed to inform user of the way to look up a lens in curation tool"
          defaultMessage="To find Snaps taken using this lens, filter by the following lens ID in our curation tool:"
        />
      </div>,
      <div key="lens-id-2" className={style.lensId}>
        {this.props.lensId}
      </div>,
    ];
  }

  renderProductPreview() {
    return (
      <div className={style.setCamera}>
        <div className={style.setLens}>
          <div className={style.lensAttachmentMessage}>
            <FormattedMessage
              id="lens-selected-on-swipe-up-message"
              description="Message displayed when a lens has been included"
              defaultMessage="This lens will be selected on swipe up"
            />
          </div>
          <div className={style.snapcodePreview}>
            <img
              className={style.snapcodeImg}
              crossOrigin={CrossOrigin.USE_CREDENTIALS}
              src={this.getScancodeImageUrl()}
              alt="Lens Snapcode"
            />
          </div>
          {isCurationPublisher(this.props.currentPublisher) && this.props.lensId ? this.renderLensId() : null}
        </div>
      </div>
    );
  }

  renderCameraAttachmentPreview = () => {
    const uuid = this.getScancodeId() || '';
    const isScancodeUuidPotentiallyValid = validator.isUUID(uuid);

    const savePreview = (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <ContentStatusPanel status={this.props.contentStatus} isReadOnly={this.props.isReadOnly} onSave={this.save} />
    );
    return (
      <div className={style.editor} data-test="camerAttachmentEditor.preview">
        {isScancodeUuidPotentiallyValid ? savePreview : null}
        <div className={style.previewWrapper}>
          {isScancodeUuidPotentiallyValid ? this.renderProductPreview() : this.renderEmptyCameraAttachmentPreview()}
        </div>
      </div>
    );
  };

  render() {
    return this.renderCameraAttachmentPreview();
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(CameraAttachmentEditor);
