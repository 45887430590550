import _ from 'lodash';
import * as React from 'react';

import { isGeneratingSubtitles } from 'state/buildStatus/schema/buildStatusHelpers';
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import { findSnapIndexInEdition } from 'state/editions/schema/editionEntityHelpers';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import { SnapStatusMessageBar } from './SnapStatusMessageBar';
import type { Props as SnapStatusMessageBarProps } from './SnapStatusMessageBar';

import type { BuildStatusType } from 'types/build';
import type { Edition } from 'types/editions';
import type { Snap } from 'types/snaps';

type OwnProps = {
  snap: Snap;
  className: string | undefined | null;
};
type StateProps = {
  snapIndexInEdition: number;
  isUploading: boolean;
  isSavingOrUploading: boolean;
  uploadProgress: number;
  buildStatus: BuildStatusType | undefined | null;
};
const mapStateToProps = (state: State, ownProps: any): StateProps => {
  const activeEdition: Edition | undefined | null = publisherStoryEditorSelectors.getActiveEdition(state);
  const snapIndexInEdition: number = findSnapIndexInEdition(activeEdition, ownProps.snap.id) || 0;
  return {
    snapIndexInEdition,
    buildStatus: buildStatusSelectors.getDiscoverSnapBuildStatus(state)(ownProps.snap.id),
    isUploading: publisherStoryEditorSelectors.getWholeSnapIsUploadingById(state)(ownProps.snap.id),
    isSavingOrUploading: editorSelectors.wholeSnapHasTransactionOrUploading(state)(ownProps.snap.id),
    uploadProgress: editorSelectors.wholeSnapUploadProgressSummary(state)(ownProps.snap.id),
  };
};
type Props = OwnProps & StateProps;
class SnapStatusMessageBarConnected extends React.Component<Props> {
  getChildProps(): SnapStatusMessageBarProps {
    return {
      snapName: this.props.snap.name,
      snapIndexInEdition: this.props.snapIndexInEdition,
      isSaving: this.props.isSavingOrUploading && !this.isUploading(),
      isUploading: this.isUploading(),
      uploadProgress: this.props.uploadProgress,
      className: this.props.className,
      hasSubtitles:
        (this.props.snap as any).subtitlesAssetIds != null &&
        _.size((this.props.snap as any).subtitlesAssetIds) !== 0 &&
        !isGeneratingSubtitles(this.props.buildStatus),
    };
  }

  isUploading() {
    // Can't rely on only isUploading because the upload process encompasses more than just the uploading part
    // and we want to give very accurate info to the users at this stage
    return this.props.isUploading && this.props.uploadProgress < 1;
  }

  render() {
    const props = this.getChildProps();
    return <SnapStatusMessageBar {...props} />;
  }
}
export default intlConnect(mapStateToProps, null)(SnapStatusMessageBarConnected);
