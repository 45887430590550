import classNames from 'classnames';
import _, { memoize } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, intlShape, FormattedMessage } from 'react-intl';

import { plus, duplicate, download, pencil } from 'icons/SDS/allIcons';
import * as gaUtils from 'utils/gaUtils';
import { openInNewWindow } from 'utils/locationUtils';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { SpinnerIcon } from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './SnapcodeModal.scss';

import { AssetID } from 'types/assets';

const messages = defineMessages({
  shareableHeadlinePlaceholder: {
    id: 'snapcode-shareable-headline-placeholder',
    description: 'Placeholder for shareable headline form field',
    defaultMessage: 'Appears when you scan Snapcode or visit URL',
  },
  notesPlaceholder: {
    id: 'snapcode-notes-placeholder',
    description: 'Placeholder for snapcode notes form field',
    defaultMessage: 'Where will this code be used?',
  },
});

type OwnProps = {
  onHide: (...args: any[]) => any;
  snapcodes: {
    id?: AssetID;
    fullDeepLinkUrl: string;
    downloadLinkUrl: string;
    name: string;
    shareableHeadline?: string;
  }[];
  isSnapcodesLoading: boolean;
  snapcodesLastUpdated: number;
  isReadOnly: boolean;
  entityId?: AssetID;
  loadSnapcodes: (...args: any[]) => any;
  createSnapcode: (...args: any[]) => any;
  updateSnapcode: (...args: any[]) => any;
  gaCategory: any; // TODO: PropTypes.oneOf(Object.values(gaUtils.GAUserActions))
  gaContext: string;
  hasHeadline?: boolean;
};

type State = any;

type Props = OwnProps & typeof SnapcodeModal.defaultProps;

export class SnapcodeModal extends React.Component<Props, State> {
  static defaultProps = {
    hasHeadline: true,
  };

  static contextTypes = {
    intl: intlShape,
  };

  modalBody: any;

  snapcodeHiddenInput: any;

  state = {
    editingSnapcodeId: null,
    newSnapcodeHeadline: '',
    newSnapcodeName: '',
    editingSnapcodeHeadline: '',
    editingSnapcodeName: '',
    mountedAt: 0,
    showNewSnapcodeLoading: false,
  };

  UNSAFE_componentWillMount() {
    this.setState({ mountedAt: Date.now() });

    this.props.loadSnapcodes(this.props.entityId);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.snapcodes.length < nextProps.snapcodes.length) {
      this.setState({ showNewSnapcodeLoading: false });
    }
  }

  onSnapcodeDownload = memoize(url => () => {
    openInNewWindow(url);
    gaUtils.logGAEvent(this.props.gaCategory, `${this.props.gaContext}-download-snapcode`);
  });

  showSpinner() {
    return this.isDataStale() || this.props.isSnapcodesLoading;
  }

  isDataStale() {
    return this.props.snapcodesLastUpdated < this.state.mountedAt;
  }

  createSnapcode = () => {
    if (!this.isNewSnapcodeValid()) {
      return;
    }

    gaUtils.logGAEvent(this.props.gaCategory, `${this.props.gaContext}-create-snapcode`);

    this.props.createSnapcode(this.props.entityId, {
      shareableHeadline: this.state.newSnapcodeHeadline,
      name: this.state.newSnapcodeName,
    });

    this.setState({
      newSnapcodeHeadline: '',
      newSnapcodeName: '',
      showNewSnapcodeLoading: true,
    });
  };

  isNewSnapcodeValid() {
    return (
      (this.props.hasHeadline ? this.state.newSnapcodeHeadline.length > 2 : true) &&
      this.state.newSnapcodeName.length > 2
    );
  }

  copyToClipboard = memoize(snapcode => () => {
    gaUtils.logGAEvent(this.props.gaCategory, `${this.props.gaContext}-copy-snapcode-to-clipboard`);

    this.snapcodeHiddenInput.value = snapcode.fullDeepLinkUrl;
    this.snapcodeHiddenInput.select();
    document.execCommand('copy');
  });

  editSnapcode = memoize(snapcode => () => {
    this.setState({
      editingSnapcodeId: snapcode.id,
      editingSnapcodeHeadline: snapcode.shareableHeadline,
      editingSnapcodeName: snapcode.name,
    });
  });

  cancelEditSnapcode = () => {
    this.setState({
      editingSnapcodeId: null,
      editingSnapcodeHeadline: '',
      editingSnapcodeName: '',
    });
  };

  updateSnapcode = () => {
    if (!this.isEditingSnapcodeValid()) {
      return;
    }

    const originalSnapcode = _.find(this.props.snapcodes, snapcode => snapcode.id === this.state.editingSnapcodeId);

    if (
      originalSnapcode &&
      (originalSnapcode.shareableHeadline !== this.state.editingSnapcodeHeadline ||
        originalSnapcode.name !== this.state.editingSnapcodeName)
    ) {
      gaUtils.logGAEvent(this.props.gaCategory, `${this.props.gaContext}-edit-snapcode`);

      this.props.updateSnapcode(this.props.entityId, {
        id: this.state.editingSnapcodeId,
        shareableHeadline: this.state.editingSnapcodeHeadline,
        name: this.state.editingSnapcodeName,
      });
    }

    this.setState({
      editingSnapcodeId: null,
      editingSnapcodeHeadline: '',
      editingSnapcodeName: '',
    });
  };

  isEditingSnapcodeValid() {
    return (
      (this.props.hasHeadline ? this.state.editingSnapcodeHeadline.length > 2 : true) &&
      this.state.editingSnapcodeName.length > 2
    );
  }

  handleSnapcodeHeadlineChange = (event: any) => {
    this.setState({ newSnapcodeHeadline: event.target.value });
  };

  handleSnapcodeNameChange = (event: any) => {
    this.setState({ newSnapcodeName: event.target.value });
  };

  handleSnapcodeEditingHeadlineChange = (event: any) => {
    this.setState({ editingSnapcodeHeadline: event.target.value });
  };

  handleSnapcodeEditingNameChange = (event: any) => {
    this.setState({ editingSnapcodeName: event.target.value });
  };

  renderSnapcodeAddNewSnapcodeRow() {
    if (this.props.isReadOnly) {
      return null;
    }

    return (
      <div className={style.rowContainer}>
        {this.props.hasHeadline && (
          <div className={style.headlineContainer}>
            <SDSInput
              labelTitle={
                <FormattedMessage
                  id="snapcodes-modal-shareable"
                  description="Shareable headline label"
                  defaultMessage="Shareable Headline"
                />
              }
              maxLength={70}
              placeholder={this.context.intl.formatMessage(messages.shareableHeadlinePlaceholder)}
              value={this.state.newSnapcodeHeadline}
              onChange={this.handleSnapcodeHeadlineChange}
              data-test="storyEditor.snapcodeModal.addHeadline"
            />
          </div>
        )}
        <div className={style.notesContainer}>
          <SDSInput
            labelTitle={
              <FormattedMessage id="snapcodes-modal-notes" description="Notes label" defaultMessage="Notes" />
            }
            maxLength={140}
            placeholder={this.context.intl.formatMessage(messages.notesPlaceholder)}
            value={this.state.newSnapcodeName}
            onChange={this.handleSnapcodeNameChange}
            data-test="storyEditor.snapcodeModal.addName"
          />
        </div>
        <div className={style.controlsContainer}>
          <SDSTooltip
            id="add-new-snapcode"
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            placement={TooltipPosition.TOP}
            title={
              <FormattedMessage
                id="snapcode-create-new-code-tooltip"
                defaultMessage="Create new code"
                description="tooltip message when user hovers over 'add new snapcode' button"
              />
            }
          >
            <div className={style.addButtonContainer}>
              <SDSButton
                type={ButtonType.SECONDARY}
                shape={ButtonShape.CIRCLE}
                inlineIcon={plus}
                disabled={!this.isNewSnapcodeValid()}
                onClick={this.createSnapcode}
                data-test="storyEditor.snapcodeModal.addButton"
              />
            </div>
          </SDSTooltip>
        </div>
      </div>
    );
  }

  renderSnapcodeRowEditMode(snapcode: any) {
    return (
      <div data-test="storyEditor.snapcodeModal.editableRow" className={style.rowContainer}>
        {this.props.hasHeadline && (
          <div className={style.headlineContainer}>
            <SDSInput
              maxLength={70}
              value={this.state.editingSnapcodeHeadline}
              onChange={this.handleSnapcodeEditingHeadlineChange}
              data-test="storyEditor.snapcodeModal.editHeadline"
            />
          </div>
        )}
        <div className={style.notesContainer}>
          <SDSInput
            maxLength={140}
            value={this.state.editingSnapcodeName}
            onChange={this.handleSnapcodeEditingNameChange}
            data-test="storyEditor.snapcodeModal.editName"
          />
        </div>
        <div className={style.controlsContainer}>
          <SDSButton
            onClick={this.cancelEditSnapcode}
            type={ButtonType.SECONDARY}
            data-test="storyEditor.snapcodeModal.editCancelButton"
          >
            <FormattedMessage id="edit-snapcode-cancel-button" description="Cancel Button" defaultMessage="Cancel" />
          </SDSButton>
          <SDSButton
            onClick={this.updateSnapcode}
            type={this.isEditingSnapcodeValid() ? ButtonType.PRIMARY : ButtonType.SECONDARY}
            disabled={!this.isEditingSnapcodeValid()}
            data-test="storyEditor.snapcodeModal.editSaveButton"
          >
            <FormattedMessage id="edit-snapcode-save-button" description="Save Button" defaultMessage="Save" />
          </SDSButton>
        </div>
      </div>
    );
  }

  renderSnapcodeRow = (snapcode: any) => {
    if (snapcode.id === this.state.editingSnapcodeId) {
      return this.renderSnapcodeRowEditMode(snapcode);
    }

    const shareTooltip = (
      <FormattedMessage
        id="snapcode-share-tooltip"
        defaultMessage="Copy URL to clipboard"
        description="tooltip message when user hovers over 'copy url to clipboard' button"
      />
    );

    const downloadTooltip = (
      <FormattedMessage
        id="snapcode-download-tooltip"
        defaultMessage="Download snapcode"
        description="tooltip message when user hovers over 'download snapcode' button"
      />
    );

    return (
      <div className={style.rowContainer} data-test="storyEditor.snapcodeModal.nonEditableRow">
        {this.props.hasHeadline && <div className={style.headlineContainer}>{snapcode.shareableHeadline}</div>}
        <div className={style.notesContainer}>{snapcode.name}</div>
        <div className={style.controlsContainer}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <SDSTooltip placement={TooltipPosition.TOP} title={shareTooltip} id="copy-snapcode-url">
            <SDSButton
              type={ButtonType.WHITE}
              shape={ButtonShape.CIRCLE}
              inlineIcon={duplicate}
              onClick={this.copyToClipboard(snapcode)}
              data-test="storyEditor.snapcodeModal.copyButton"
            />
          </SDSTooltip>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <SDSTooltip placement={TooltipPosition.TOP} title={downloadTooltip} id="download-snapcode-url">
            <SDSButton
              type={ButtonType.WHITE}
              shape={ButtonShape.CIRCLE}
              inlineIcon={download}
              data-test="storyEditor.snapcodeModal.downloadButton"
              onClick={this.onSnapcodeDownload(snapcode.downloadLinkUrl)}
            />
          </SDSTooltip>
          {this.props.isReadOnly ? undefined : (
            <SDSButton
              type={ButtonType.WHITE}
              shape={ButtonShape.CIRCLE}
              inlineIcon={pencil}
              onClick={this.editSnapcode(snapcode)}
              data-test="storyEditor.snapcodeModal.editButton"
            />
          )}
        </div>
      </div>
    );
  };

  renderSnapcodeLoading() {
    return (
      <div className={style.rowContainer} key="new-snapcode-loading" data-test="storyEditor.snapcodeModal.loadingRow">
        <SpinnerIcon className={classNames(style.spinner, style.spinnerCenter)} />
      </div>
    );
  }

  renderSnapcodes() {
    if (this.showSpinner()) {
      return <SpinnerIcon className={style.spinner} data-test="storyEditor.snapcodeModal.loadingSpinner" />;
    }

    return (
      <div className={style.rowsContainer}>
        {this.renderSnapcodeAddNewSnapcodeRow()}
        {this.props.snapcodes.map(this.renderSnapcodeRow)}
        {this.state.showNewSnapcodeLoading ? this.renderSnapcodeLoading() : null}
      </div>
    );
  }

  handleHiddenInputRef = (element: any) => {
    this.snapcodeHiddenInput = ReactDOM.findDOMNode(element);
  };

  render() {
    return (
      <SDSCustomModal
        visible
        width={1100}
        onClose={this.props.onHide}
        title={
          <FormattedMessage
            id="manage-snapcodes"
            defaultMessage="Manage snapcodes"
            description="Modal title of a dialog presented for the user to manage snapcodes"
          />
        }
        footer={
          <>
            <SDSButton
              onClick={this.props.onHide}
              type={ButtonType.PRIMARY}
              data-test="storyEditor.snapcodeModal.dismissButton"
            >
              <FormattedMessage
                id="manage-snapcodes-dismiss"
                defaultMessage="Dismiss"
                description="Dismiss button for Manage snapcodes modal"
              />
            </SDSButton>
          </>
        }
      >
        <input type="text" ref={this.handleHiddenInputRef} className={style.hiddenInput} />
        {this.renderSnapcodes()}
      </SDSCustomModal>
    );
  }
}

export default SnapcodeModal;
