import isUrl from 'is-url';
import * as React from 'react';
import type { ChangeEvent } from 'react';
import { intlShape } from 'react-intl';

import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { EDITOR_MODES } from 'config/constants';
import importImage from 'images/CreationIcon.png';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId, getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './SnapWebImport.scss';

import type { Context, SnapId } from 'types/common';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as BaseState } from 'types/rootState';

type StateProps = {
  publisherId: PublisherID;
};

type State = {
  importURL: string | undefined | null;
  isLoading: boolean;
  isUrlInvalid: boolean;
};

const mapStateToProps = (state: BaseState): StateProps => ({
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
  publisherId: userSelectors.getActivePublisherId(state),
});

const mapDispatchToProps = {
  setEditorMode: publisherStoryEditorModeActions.setEditorMode,
  createArticleAttachmentAndIngestFromUrl: publisherStoryEditorActions.createArticleAttachmentAndIngestFromUrl,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = {
  snapId: SnapId;
} & DispatchProps &
  StateProps;

export class SnapWebImport extends React.Component<Props, State> {
  static contextTypes: Context = {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'IntlShape' is not assignable to type 'IntlCo... Remove this comment to see the full error message
    intl: intlShape,
  };

  state = {
    importURL: undefined,
    isUrlInvalid: false,
    isLoading: false,
  };

  onChange = (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    this.setState({ importURL: value, isUrlInvalid: false });
  };

  handleOnBlur = () => {
    const url = this.state.importURL;

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    if (!isUrl(url)) {
      this.setState({ isUrlInvalid: true });
    }
  };

  onSave = () => {
    const url = this.state.importURL;
    if (url && isUrl(url)) {
      this.setState({ isLoading: true, isUrlInvalid: false });
      this.props.createArticleAttachmentAndIngestFromUrl({
        snapId: this.props.snapId,
        url,
        publisherId: this.props.publisherId,
      });
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      this.props.setEditorMode(EDITOR_MODES.SNAP_PUB, url);
    }
  };

  renderLoading() {
    return <SpinnerIcon className={style.spinner} />;
  }

  renderImportBox() {
    return (
      <>
        <div className={style.header}>{getMessageFromId('snap-web-import-header')}</div>
        <img className={style.image} src={importImage} alt="Web Import" />
        <div className={style.info}>{getMessageFromId('snap-web-import-info')}</div>
        <SDSInput
          placeholder={getLocalisedMessageFromId(this.context, 'snap-web-import-text-placeholder')}
          onChange={this.onChange}
          onBlur={this.handleOnBlur}
          onPressEnter={this.onSave}
          value={this.state.importURL || ''}
          errorMessage={this.state.isUrlInvalid ? getMessageFromId('snap-web-import-url-error') : null}
          data-test="publisherStoryEditor.snapWebImport.url.input"
        />
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.onSave}
          disabled={this.state.isUrlInvalid}
          data-test="publisherStoryEditor.snapWebImport.import.button"
        >
          {getMessageFromId('snap-web-import-button')}
        </SDSButton>
      </>
    );
  }

  render() {
    return (
      <div className={style.parent}>
        <div className={style.importBox}>{this.state.isLoading ? this.renderLoading() : this.renderImportBox()}</div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapWebImport);
