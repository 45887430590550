import classNames from 'classnames';
import React from 'react';

import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import style from './SharedToolbar.scss';

function mapStateToProps(state: State) {
  return {
    sharedToolbarId: editorSelectors.getSharedToolbarId(state),
    articleContentFieldIsFocused: editorSelectors.articleContentFieldIsFocused(state),
  };
}
const mapDispatchToProps = {
  setEditorConfigProperties: editorActions.setEditorConfigProperties,
};
export const UNIQUE_SHARED_TOOLBAR_ID = 'uniqueSharedToolBarId';

type OwnProps = {
  sharedToolbarId?: string;
  articleContentFieldIsFocused?: boolean;
  isReadOnly?: boolean;
  setEditorConfigProperties: typeof editorActions.setEditorConfigProperties;
};
type Props = OwnProps & typeof SharedToolbar.defaultProps;
export class SharedToolbar extends React.Component<Props> {
  static defaultProps = {
    isReadOnly: false,
  };

  componentDidMount() {
    this.props.setEditorConfigProperties({ sharedToolbarId: UNIQUE_SHARED_TOOLBAR_ID });
  }

  componentWillUnmount() {
    this.props.setEditorConfigProperties({ sharedToolbarId: null });
  }

  render() {
    const toolbarClassNames = classNames(style.root, {
      [style.dimmed]: !this.props.articleContentFieldIsFocused || this.props.isReadOnly,
    });
    return <div id={UNIQUE_SHARED_TOOLBAR_ID} className={toolbarClassNames} />;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SharedToolbar);
