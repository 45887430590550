import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as assetSelectors from 'state/asset/selectors/assetSelectors';
import * as autocompleteActions from 'state/autocomplete/actions/autocompleteActions';
import * as autocompleteSelectors from 'state/autocomplete/selectors/autocompleteSelectors';
import { getModerationTagsBySnapId } from 'state/buildStatus/selectors/buildStatusSelectors';
import { findSegmentForSnap } from 'state/editions/schema/editionEntityHelpers';
import { getActiveEdition } from 'state/editor/selectors/editorSelectors';
import { shouldUseSingleSnapBuilder } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import { topsnapHasSCCTags } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import { hasOngoingTransactionsBySnapId } from 'state/transactions/selectors/transactionsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { EMPTY_ARRAY, SnapTag } from 'config/constants';
import type { SnapTagEnum } from 'config/constants';
import {
  duplicate,
  createGroup,
  magicWand,
  profileSingle,
  assignToAll,
  deleteIcon,
  deleteIconMap,
} from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { localStorage } from 'utils/localStorageUtils';

import MultiSelectInput from 'views/common/components/MultiInput/MultiSelectInput';
import type { TagOptions } from 'views/common/components/MultiInput/MultiSelectInput';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './SnapTagInput.scss';

import type { SnapId } from 'types/common';
import { Edition } from 'types/editions';
import { Claim } from 'types/permissions';
import { Publisher } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';
import type { TopSnap, TagsMap } from 'types/snaps';

type ExternalProps = {
  snapId: SnapId;
  disabled?: boolean;
};
type StateProps = {
  snap: TopSnap | undefined | null;
  sccTagsMap: {
    [x: string]: string;
  };
  newTagCopy: boolean;
  automaticTags: TagsMap;
  moderatedTags: TagsMap;
  isSingleSnapBuilder: boolean;
  canViewModeratedTags: boolean;
  canViewAutomaticTags: boolean;
  enforceSCCTag: boolean;
  publisher: Publisher | null;
  activeEdition: Edition | null | undefined;
  snapHasOngoingTransactions: boolean;
};
type State = {
  latestAutocompleteString: string;
  autocompleteSuggestions: {
    [k in SnapTagEnum]: string[];
  };
  // TODO (dlipowicz): Move tags to redux store
  tags: {
    [k in SnapTagEnum]: string[];
  };
  pastedTags: {
    [k in SnapTagEnum]: string[];
  };
  pasteAutomaticTagsCounter: number;
  newTagCopy: boolean;
  clearedTags: boolean;
};
const MIN_NUM_CHARS_TO_SEARCH = 3;
const TAGS_ORDER = [SnapTag.SCC, SnapTag.WIKI, SnapTag.MANUAL];
export const mapStateToProps = (state: RootState, props: ExternalProps): StateProps => {
  const snap = (snapsSelectors.getSnapById(state)(props.snapId) as any) as TopSnap | undefined | null;
  // finding if the segment has at least one SCC tag
  const activeEdition = getActiveEdition(state);
  const segment = activeEdition ? findSegmentForSnap(activeEdition, props.snapId) : null;
  const otherSnapIdsInSegment = _.get(segment, ['snapIds'], []).filter(snapId => snapId !== props.snapId);
  const snapBelongsToSegment = segment !== null;
  const restOfSegmentContainsAnSCCTag = snapsSelectors.getSetOfSnapsContainsAtLeastOneSCCTagBySnapIds(state)(
    otherSnapIdsInSegment
  );
  const enforceSCCTag = snapBelongsToSegment && !restOfSegmentContainsAnSCCTag && !topsnapHasSCCTags(snap);
  return {
    snap,
    sccTagsMap: autocompleteSelectors.getSccCodeToTextMapping(state),
    newTagCopy: false,
    automaticTags: assetSelectors.getMediaAnnotationsTagsFromSnap(state)(snap),
    isSingleSnapBuilder: shouldUseSingleSnapBuilder(state)(activeEdition?.id),
    moderatedTags: getModerationTagsBySnapId(state)(props.snapId),
    canViewModeratedTags: userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    canViewAutomaticTags: userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    enforceSCCTag,
    publisher: getActivePublisherDetails(state),
    activeEdition,
    snapHasOngoingTransactions: hasOngoingTransactionsBySnapId(state)(props.snapId),
  };
};
const mapDispatchToProps = {
  setSnapPropertiesAndSave: snapsActions.setSnapPropertiesAndSave,
  autocompleteWikiTagSearch: autocompleteActions.autocompleteWikiTagSearch,
  autocompleteSCCTagSearch: autocompleteActions.autocompleteSCCTagSearch,
  assignTagsToSnapsInStory: snapsActions.assignTagsToSnapsInStory,
  clearTagsForSnapsInStory: snapsActions.clearTagsForSnapsInStory,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;
export class SnapTagInput extends React.PureComponent<Props, State> {
  state = {
    autocompleteSuggestions: {},
    latestAutocompleteString: '',
    tags: {} as any,
    pastedTags: {},
    pasteAutomaticTagsCounter: 0,
    newTagCopy: false,
    clearedTags: false,
  };

  onTagsChanged = (
    tags: {
      [k in SnapTagEnum]: string[];
    }
  ) => {
    this.setState({ tags, clearedTags: false });
    this.handleSave();
  };

  onTagsSearch = (search: string) => {
    if (search === '') {
      return;
    }
    if (search.length >= MIN_NUM_CHARS_TO_SEARCH) {
      if (this.props.enforceSCCTag) {
        // only suggest SCC tags for snap in current segment if we do not have one in the segment already
        this.setState({ latestAutocompleteString: search, autocompleteSuggestions: {} });
        this.searchSCCTags(search);
        this.updateSuggestions(SnapTag.WIKI, []);
        this.updateSuggestions(SnapTag.MANUAL, []);
      } else {
        this.setState({ latestAutocompleteString: search, autocompleteSuggestions: {} });
        this.searchWikiTags(search);
        this.searchSCCTags(search);
        this.updateSuggestions(SnapTag.MANUAL, [search]);
      }
    } else if (this.props.enforceSCCTag) {
      this.setState({
        autocompleteSuggestions: { [SnapTag.MANUAL]: [], [SnapTag.WIKI]: [], [SnapTag.SCC]: [] },
        latestAutocompleteString: search,
      });
    } else {
      this.setState({
        autocompleteSuggestions: { [SnapTag.MANUAL]: [search], [SnapTag.WIKI]: [], [SnapTag.SCC]: [] },
        latestAutocompleteString: search,
      });
    }
  };

  searchWikiTags = _.debounce(
    (search: string) =>
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
      this.props.autocompleteWikiTagSearch(search).then(({ payload }) => {
        if (this.state.latestAutocompleteString === search) {
          this.updateSuggestions(SnapTag.WIKI, payload);
        }
      }),
    150
  );

  searchSCCTags = _.debounce(
    (search: string) =>
      this.props.autocompleteSCCTagSearch(search).then(resultTags => {
        if (this.state.latestAutocompleteString === search) {
          this.updateSuggestions(SnapTag.SCC, resultTags);
        }
      }),
    150
  );

  updateSuggestions = (tagType: SnapTagEnum, tags: string[]) => {
    this.setState((prevState: State) => {
      const autocompleteSuggestions = {};
      Object.keys(prevState.autocompleteSuggestions).forEach((key: SnapTagEnum) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        autocompleteSuggestions[key] = prevState.autocompleteSuggestions[key];
      });
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      autocompleteSuggestions[tagType] = tags || [];
      // We don't want to show a tag as a manual tag if it's in another category already
      const lowercaseTags = tags.map(value => {
        return value.toLowerCase();
      });
      const manualTag = _.get(autocompleteSuggestions, `${SnapTag.MANUAL}[0]`, '');
      if (tagType !== SnapTag.MANUAL && lowercaseTags.indexOf(manualTag.toLowerCase()) !== -1) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        autocompleteSuggestions[SnapTag.MANUAL] = [];
      }
      return { autocompleteSuggestions };
    });
  };

  handleSave = _.debounce(() => {
    this.props.setSnapPropertiesAndSave(
      { snapId: this.props.snapId },
      {
        decorations: {
          discover: {
            tags: this.state.tags,
          },
        },
      }
    );
  }, 1500);

  handleCopyTags = () => {
    const copiedTagsHaveChanged = this.assertNewCopiedTagsAreDifferentToStoredCopy();
    const tags = _.get(this.props.snap, ['decorations', 'discover', 'tags'], {});
    this.setState({ newTagCopy: false });
    if (copiedTagsHaveChanged) {
      this.setState({ newTagCopy: true });
      localStorage.setItem('copiedSnapTags', JSON.stringify(tags));
    }
  };

  handleAssignToAllTags = () => {
    return this.props.assignTagsToSnapsInStory(this.props.snapId, this.props.activeEdition?.id, this.state.tags);
  };

  handlePasteTags = () => {
    const tags = localStorage.getParsedJSON('copiedSnapTags');
    if (tags) {
      this.setState({ pastedTags: tags });
    }
  };

  handleClearTagsForAllSnaps = () => {
    this.setState({ tags: {}, clearedTags: true });
    this.props.clearTagsForSnapsInStory(this.props.snapId, this.props.activeEdition?.id);
  };

  handleClearTags = () => {
    this.setState({ tags: {}, clearedTags: true });
  };

  handleAutomaticTagsSuggestion = () => {
    this.setState(prevState => ({
      pasteAutomaticTagsCounter: prevState.pasteAutomaticTagsCounter + 1,
    }));
  };

  assertNewCopiedTagsAreDifferentToStoredCopy = () => {
    const tags = _.get(this.props.snap, ['decorations', 'discover', 'tags'], {});
    const storedTags = localStorage.getParsedJSON('copiedSnapTags');
    return !_.isEqual(tags, storedTags);
  };

  isTagsListEmpty = () => {
    const tags = _.get(this.props.snap, ['decorations', 'discover', 'tags'], {});
    return this.isTagsMapEmpty(tags);
  };

  isTagsMapEmpty = (tags: TagsMap) => {
    if (!tags || _.isEmpty(tags)) {
      return true;
    }
    const listLength = _.size(tags[SnapTag.SCC]) + _.size(tags[SnapTag.WIKI]) + _.size(tags[SnapTag.MANUAL]);
    return listLength === 0;
  };

  renderCopyPasteContainer() {
    const isTagsListEmpty = this.isTagsListEmpty();
    const emptyLocalStorage = localStorage.getItem('copiedSnapTags') == null;

    return (
      <div className={classNames(style.copyPasteContainer)} data-test="SnapTagInput.copyPasteContainer">
        <div
          className={classNames(style.copyTagsLabel, { [style.showLabel]: this.state.newTagCopy })}
          data-test="SnapTagInput.copyTagsLabel"
        >
          <FormattedMessage
            id="copy-tags-label"
            description="Label for copy tags button"
            defaultMessage="Tags copied!"
          />
        </div>
        <div className={style.copyButtonContainer}>
          <SDSTooltip
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            placement={TooltipPosition.TOP}
            title={
              <FormattedMessage
                id="assign-tags-to-all-snaps"
                description="Assign tags to all snaps"
                defaultMessage="Assign to all Snaps"
              />
            }
            id="tooltip"
          >
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={assignToAll}
              onClick={this.handleAssignToAllTags}
              disabled={isTagsListEmpty || this.props.snapHasOngoingTransactions}
              data-test="SnapTagInput.assignToAllTagsButton"
            />
          </SDSTooltip>
        </div>
        <div className={style.copyButtonContainer}>
          <SDSButton
            type={ButtonType.SECONDARY}
            shape={ButtonShape.CIRCLE}
            inlineIcon={duplicate}
            onClick={this.handleCopyTags}
            disabled={isTagsListEmpty}
            data-test="SnapTagInput.copyTagsButton"
          />
        </div>
        <div className={style.copyButtonContainer}>
          <SDSButton
            type={ButtonType.SECONDARY}
            shape={ButtonShape.CIRCLE}
            inlineIcon={createGroup}
            onClick={this.handlePasteTags}
            disabled={this.props.disabled || emptyLocalStorage}
            data-test="SnapTagInput.pasteTagsButton"
          />
        </div>
        <div className={style.copyButtonContainer}>
          <SDSTooltip
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            placement={TooltipPosition.TOP}
            title={<FormattedMessage id="clear-tags" description="Clear tags" defaultMessage="Clear tags" />}
            id="tooltip"
          >
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={deleteIcon}
              onClick={this.handleClearTags}
              disabled={isTagsListEmpty || this.props.snapHasOngoingTransactions}
              data-test="SnapTagInput.clearTagsButton"
            />
          </SDSTooltip>
        </div>
        <div className={style.copyButtonContainer}>
          <SDSTooltip
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            placement={TooltipPosition.TOP}
            title={
              <FormattedMessage
                id="clear-tags-for-all-snaps"
                description="Clear tags for all snaps"
                defaultMessage="Clear tags for all Snaps"
              />
            }
            id="tooltip"
          >
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={deleteIconMap}
              onClick={this.handleClearTagsForAllSnaps}
              disabled={this.props.snapHasOngoingTransactions}
              data-test="SnapTagInput.clearTagsForAllSnapsButton"
            />
          </SDSTooltip>
        </div>
      </div>
    );
  }

  renderModeratedTags() {
    if (!this.props.canViewModeratedTags) {
      return null;
    }
    const tags = this.props.moderatedTags || {};
    if (this.isTagsMapEmpty(tags)) {
      return null;
    }
    const tagOptions: {
      [k in SnapTagEnum]: TagOptions;
    } = {
      [SnapTag.SCC]: {
        defaultTags: tags[SnapTag.SCC] || [],
        suggestions: EMPTY_ARRAY,
        tagMapping: this.props.sccTagsMap,
        sectionHeader: getMessageFromId('snap-tag-scc'),
        className: style.scc,
        icon: profileSingle,
      },
      [SnapTag.WIKI]: {
        defaultTags: tags[SnapTag.WIKI] || [],
        suggestions: EMPTY_ARRAY,
        sectionHeader: getMessageFromId('snap-tag-wikipedia'),
        className: style.wiki,
      },
      [SnapTag.MANUAL]: {
        defaultTags: tags[SnapTag.MANUAL] || [],
        suggestions: EMPTY_ARRAY,
        sectionHeader: null,
        className: style.manual,
      },
    };
    return (
      <MultiSelectInput
        tagOptions={tagOptions}
        tagTypeOrder={TAGS_ORDER}
        onChange={this.onTagsChanged}
        searchItems={this.onTagsSearch}
        hideSearchWhenDisabled
        disabled
      />
    );
  }

  renderAutomaticTags() {
    if (!this.props.canViewAutomaticTags) {
      return null;
    }
    const tags = this.props.automaticTags || {};
    if (this.isTagsMapEmpty(tags)) {
      return null;
    }
    const tagOptions: {
      [k in SnapTagEnum]: TagOptions;
    } = {
      [SnapTag.SCC]: {
        defaultTags: tags[SnapTag.SCC] || [],
        suggestions: EMPTY_ARRAY,
        tagMapping: this.props.sccTagsMap,
        sectionHeader: getMessageFromId('snap-tag-scc'),
        className: style.scc,
        icon: magicWand,
      },
      [SnapTag.WIKI]: {
        defaultTags: tags[SnapTag.WIKI] || [],
        suggestions: EMPTY_ARRAY,
        sectionHeader: getMessageFromId('snap-tag-wikipedia'),
        className: style.wiki,
      },
      [SnapTag.MANUAL]: {
        defaultTags: tags[SnapTag.MANUAL] || [],
        suggestions: EMPTY_ARRAY,
        sectionHeader: null,
        className: style.manual,
      },
    };
    return (
      <MultiSelectInput
        tagOptions={tagOptions}
        tagTypeOrder={TAGS_ORDER}
        onChange={this.onTagsChanged}
        searchItems={this.onTagsSearch}
        hideSearchWhenDisabled
        disabled
        data-test="SnapTagInput.automaticTagsDisplay"
      />
    );
  }

  render() {
    const tags = _.get(this.props.snap, ['decorations', 'discover', 'tags'], {});
    const tagOptions: {
      [k in SnapTagEnum]: TagOptions;
    } = {
      [SnapTag.SCC]: {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defaultTags: tags[SnapTag.SCC] || [],
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions: this.state.autocompleteSuggestions[SnapTag.SCC],
        tagMapping: this.props.sccTagsMap,
        sectionHeader: getMessageFromId('snap-tag-scc'),
        className: style.scc,
        maxSelected: 3,
      },
      [SnapTag.WIKI]: {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defaultTags: tags[SnapTag.WIKI] || [],
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions: this.state.autocompleteSuggestions[SnapTag.WIKI],
        sectionHeader: getMessageFromId('snap-tag-wikipedia'),
        className: style.wiki,
        maxSelected: 5,
      },
      [SnapTag.MANUAL]: {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defaultTags: tags[SnapTag.MANUAL] || [],
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        suggestions: this.state.autocompleteSuggestions[SnapTag.MANUAL],
        sectionHeader: null,
        className: style.manual,
      },
    };
    return (
      <div>
        {!this.props.isSingleSnapBuilder && this.renderCopyPasteContainer()}
        {this.renderModeratedTags()}
        {this.renderAutomaticTags()}
        <MultiSelectInput
          className={classNames((style as any).multiSelectInput)}
          tagOptions={tagOptions}
          tagTypeOrder={TAGS_ORDER}
          onChange={this.onTagsChanged}
          searchItems={this.onTagsSearch}
          disabled={this.props.disabled}
          addSearchTermOnEnter
          waitForAllSuggestionsLoaded
          pastedTags={this.state.pastedTags}
          pasteAutomaticTagsCounter={this.state.pasteAutomaticTagsCounter}
          automaticTags={this.props.automaticTags}
          enforceSCCTag={this.props.enforceSCCTag}
          clearedTags={this.state.clearedTags}
          data-test="SnapTagInput.MultiSelectInput"
        />
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapTagInput);
