import React from 'react';

import { getSingleAssetTranscodeStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { isSingleAssetSnapcodeEnabled } from 'state/features/selectors/featuresSelectors';
import {
  getActiveEdition,
  shouldUseSingleAssetEditor,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { phone } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SnapcodePreviewButtonPopover from 'views/publisherStoryEditor/components/SnapcodePreviewButtonPopover/SnapcodePreviewButtonPopover';

import { Claim } from 'types/permissions';
import type { State } from 'types/rootState';
import { SingleAssetTranscodeStatus } from 'types/singleAssetStoryEditor';

type StateProps = {
  isSnapcodePreviewer: boolean;
  isSingleAssetStoryEditor: boolean;
  isSingleAssetSnapcodesEnabled: boolean;
  isSingleAssetTranscodeReady: boolean;
};

type Props = StateProps;

const mapStateToProps = (state: State) => {
  const activeStory = getActiveEdition(state);

  return {
    isSnapcodePreviewer: hasClaimForActivePublisher(state, Claim.SNAPCODE_PREVIEWER),
    isSingleAssetStoryEditor: activeStory?.id ? shouldUseSingleAssetEditor(state)(activeStory?.id) : false,
    isSingleAssetSnapcodesEnabled: isSingleAssetSnapcodeEnabled(state),
    isSingleAssetTranscodeReady: activeStory?.id
      ? getSingleAssetTranscodeStatus(state)(activeStory?.id) === SingleAssetTranscodeStatus.SUCCESS
      : false,
  };
};

export class SnapcodePreviewButton extends React.Component<Props> {
  render() {
    const shouldShow =
      this.props.isSnapcodePreviewer &&
      (this.props.isSingleAssetSnapcodesEnabled || !this.props.isSingleAssetStoryEditor);

    if (!shouldShow) {
      return null;
    }

    const waitingForTranscode = this.props.isSingleAssetSnapcodesEnabled && !this.props.isSingleAssetTranscodeReady;

    return (
      <SnapcodePreviewButtonPopover waitingForTranscode={waitingForTranscode} data-test="SnapcodePreviewButton.popover">
        <div>
          <SDSButton
            type={ButtonType.WHITE_ON_GREY}
            shape={ButtonShape.CIRCLE}
            inlineIcon={phone}
            disabled={waitingForTranscode}
            data-test="SnapcodePreviewButton.button"
          />
        </div>
      </SnapcodePreviewButtonPopover>
    );
  }
}

export default intlConnect(mapStateToProps, null)(SnapcodePreviewButton);
