import is from 'is_js';
import { createSelector as s } from 'reselect';

import { getAttachmentById } from 'state/attachments/selectors/attachmentsSelectors';

import { getClientAttachments } from 'utils/attachmentUtils';

import { SnapType } from 'types/snaps';

export const pollAttachmentsIds = getClientAttachments(SnapType.POLL).map((attachment: any) => attachment.id);

let cachedPollAttachments: any = [];

export const getPollAttachments = s(getAttachmentById, getAttachmentByIdFn => {
  if (cachedPollAttachments.length > 0) {
    return cachedPollAttachments;
  }

  const pollAttachments = pollAttachmentsIds.map(getAttachmentByIdFn).filter(is.not.null);

  if (pollAttachments.length === pollAttachmentsIds.length) {
    cachedPollAttachments = pollAttachments;
  }

  return pollAttachments;
});
