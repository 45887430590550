import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-select/scss/default.scss';
// @ts-expect-error ts-migrate(2497) FIXME: This module can only be referenced with ECMAScript... Remove this comment to see the full error message
import { DateUtils } from 'react-day-picker';

import * as publisherToolsSelectors from 'state/publisherTools/selectors/publisherToolsSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as assetUtils from 'utils/media/assetUtils';

import ImageWithPlaceholder from 'views/common/components/ImageWithPlaceholder/ImageWithPlaceholder';
import RelativeTime from 'views/common/components/RelativeTime/RelativeTime';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import StoryStateLabel from 'views/publisherStoryEditor/components/StoryStateLabel/StoryStateLabel';
import { SnapMenuItemConfig, SnapMenuItemIds } from 'views/publisherStoryEditor/utils/SnapMenuItemConfig';

import style from './ScheduleStoryTooltip.scss';

import type { LiveEdition, ScheduledEdition } from 'types/editions';
import type { Tile } from 'types/tiles';

type LiveOrScheduledEdition = LiveEdition | ScheduledEdition;

type OwnProps = {
  date: Date;
  liveAndScheduledStories: LiveOrScheduledEdition[];
};

type StateProps = {
  storiesInDay: LiveOrScheduledEdition[];
  tiles: Tile[];
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const storiesInDay = ownProps.liveAndScheduledStories
    .filter(story => {
      return DateUtils.isSameDay(ownProps.date, new Date(story.startDate));
    })
    .sort((a: LiveOrScheduledEdition, b: LiveOrScheduledEdition) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

  const tiles = storiesInDay.map(story => {
    return publisherToolsSelectors.getPresentationalTileForEdition(state)(story.id);
  });

  return {
    storiesInDay,
    tiles,
  };
};

export class ScheduleStoryTooltip extends React.Component<Props> {
  static propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    liveAndScheduledStories: PropTypes.array.isRequired,
  };

  formatRelativeTime(positive: number, hours: number, minutes: number, seconds: number) {
    return `${hours}h ${minutes}m`;
  }

  renderStoryRow(story: LiveOrScheduledEdition, index: number) {
    const imgSrc = this.props.tiles[index]
      ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        assetUtils.getImagePreviewUrl(this.props.tiles[index].croppedImageAssetId)
      : '';
    return (
      <div className={style.root} key={story.id}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <ImageWithPlaceholder src={imgSrc} className={style.content} showPlaceholderWhileLoading>
          <div className={classNames(style.content, style.placeholder)} />
        </ImageWithPlaceholder>
        <div className={style.info}>
          <div className={style.title}>
            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            {story.title || SnapMenuItemConfig[SnapMenuItemIds.TITLE_PLACEHOLDER].text}
          </div>
          <div className={style.stateContainer}>
            <StoryStateLabel className={style.storyState} storyState={story.state} storyId={story.id} />
            <RelativeTime
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              baseTime={story.startDate && Date.parse(story.startDate)}
              className={style.relativeTime}
              formatTimeFunc={this.formatRelativeTime}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.storiesInDay.length === 0) {
      return <div data-test="scheduleStoryTooltip.day">{this.props.date.getDate()}</div>;
    }

    const tooltip = (
      <div data-test="scheduleStoryTooltip.storyRow" className={style.tooltip}>
        {this.props.storiesInDay.map((story, index) => this.renderStoryRow(story, index))}
      </div>
    );

    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={tooltip}
        id="stories-scheduled-for-day"
      >
        <div data-test="scheduleStoryTooltip.day">{this.props.date.getDate()}</div>
      </SDSTooltip>
    );
  }
}

export default intlConnect(mapStateToProps, {})(ScheduleStoryTooltip);
