import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getStoryScheduleStatus } from 'state/editions/selectors/editionsSelectors';

import { StoryStatus } from 'config/constants';
import type { StoryStatusEnum } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import style from './StoryStateLabel.scss';

import { StoryState } from 'types/editions';
import type { EditionID } from 'types/editions';
import type { State } from 'types/rootState';

const draftIncompleteMsg = (
  <FormattedMessage
    id="story-state-draft-incomplete"
    description="Story state is a draft which is not yet completed"
    defaultMessage="Draft, incomplete"
  />
);

const draftReadyToSubmitMsg = (
  <FormattedMessage
    id="story-state-draft-ready"
    description="Story state is a draft and ready to submit"
    defaultMessage="Draft, ready to submit"
  />
);

const availableInMsg = (
  <FormattedMessage
    id="story-state-scheduled-cheetah"
    description="Story state is 'scheduled', indicates that it becomes available in X time"
    defaultMessage="Available in"
  />
);

const storyDeletedMsg = (
  <FormattedMessage id="story-state-deleted" description="Story has been deleted" defaultMessage="Deleted" />
);

const storyStateDraft = (storyStatus: StoryStatusEnum) => {
  return storyStatus === StoryStatus.PUBLISHABLE ? draftReadyToSubmitMsg : draftIncompleteMsg;
};

type OwnProps = {
  storyId: EditionID;
  className: string;
  storyState: StoryState;
};

type StateProps = {
  storyStatus: StoryStatusEnum;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: State, props: OwnProps) => {
  return {
    storyStatus: getStoryScheduleStatus(state)(props.storyId),
  };
};

export class StoryStateLabel extends React.Component<Props> {
  getStoryStateMessage = (): ReactNode => {
    const { storyStatus } = this.props;

    if (storyStatus === StoryStatus.SAVING) {
      return getMessageFromId('saving');
    }

    switch (this.props.storyState) {
      case StoryState.NEW:
      case StoryState.READY:
        return storyStateDraft(storyStatus);
      case StoryState.SCHEDULED:
        return availableInMsg;
      case StoryState.SCHEDULED_PENDING_APPROVAL:
      case StoryState.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL:
        return getMessageFromId('story-state-submitting');
      case StoryState.PENDING_APPROVAL:
      case StoryState.LIVE_EDIT_PENDING_APPROVAL:
        return getMessageFromId('story-state-submitted');
      case StoryState.LIVE:
      case StoryState.ARCHIVED:
        return getMessageFromId('story-state-public-archive');
      case StoryState.IN_PROGRESS:
      case StoryState.PUBLISHING:
        return getMessageFromId('story-state-processing');
      case StoryState.UNPUBLISHING:
        return getMessageFromId('story-state-unpublishing');
      case StoryState.HIDDEN:
        return getMessageFromId('story-state-private-archive');
      case StoryState.DELETED:
        return storyDeletedMsg;
      default:
        return null;
    }
  };

  render() {
    const className = classNames(style.label, this.props.className);

    return (
      <div className={className} data-test="storyStateLabel">
        {this.getStoryStateMessage()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(StoryStateLabel);
