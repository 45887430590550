import { reselectById } from 'state/common/selectorFactories';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { StoryStatus } from 'config/constants';
import type { StoryStatusEnum } from 'config/constants';

import { getEditionById, getStoryScheduleStatus } from './editionsSelectors';

import { StoryState } from 'types/editions';
import type { Edition, EditionID } from 'types/editions';
import { Claim } from 'types/permissions';

const editionStatesThatCanBeMadeAvailable = [
  StoryState.NEW,
  StoryState.IN_PROGRESS,
  StoryState.READY,
  StoryState.HIDDEN,
];

export const canEditionBeMadeAvailable = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  (state: any, editionId: any) => getStoryScheduleStatus(state)(editionId),
  (state: any) => hasClaimForActivePublisher(state, Claim.LOCKED_EDITION_PUBLISHER),
  (state: any) => hasClaimForActivePublisher(state, Claim.STORY_PUBLISHER),
  (
    edition: Edition,
    editionStatus: StoryStatusEnum,
    isLockedEditionPublisher: boolean,
    isStoryPublisher: boolean
  ): boolean => {
    // Edition must be publishable
    if (editionStatus !== StoryStatus.PUBLISHABLE) {
      return false;
    }

    // Edition must be in a state that can transition to LIVE or ARCHIVED
    if (!editionStatesThatCanBeMadeAvailable.includes(edition.state)) {
      return false;
    }

    // User must have permission to make this edition available
    if (!isStoryPublisher) {
      return false;
    }

    // If edition has hidden lock then user must have the LOCKED_EDITION_PUBLISHER claim that currently comes from
    // being a super admin
    if (!isLockedEditionPublisher && edition.hiddenLock) {
      return false;
    }

    return true;
  }
);

export const canEditionBeMadeUnavailable = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  (state: any) => hasClaimForActivePublisher(state, Claim.STORY_PUBLISHER),
  (edition: Edition, isStoryPublisher: boolean): boolean => {
    // Edition must be in a state that can transition to DRAFT or HIDDEN
    if (editionStatesThatCanBeMadeAvailable.includes(edition.state)) {
      return false;
    }

    // User must have permission to make this edition unavailable
    if (!isStoryPublisher) {
      return false;
    }

    return true;
  }
);

export const isEditionAvailableOrScheduled = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  (edition: Edition) => {
    return !editionStatesThatCanBeMadeAvailable.includes(edition.state);
  }
);
