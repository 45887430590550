import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getAuthType } from 'state/auth/selectors/authSelectors';

import { AuthType, UploadPurpose } from 'config/constants';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import MutePreviewButton from 'views/common/containers/MutePreviewButton/MutePreviewButton';
import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';
import StoryBuilderName from 'views/publisherStoryEditor/containers/StoryBuilderName/StoryBuilderName';
import StorySnapCardRenderer from 'views/storySnapUploader/components/StorySnapCards/StorySnapCardRenderer/StorySnapCardRenderer';
import SubmittedPostSection from 'views/storySnapUploader/components/SubmittedPostSection/SubmittedPostSection';
import { useUploaderHandlers } from 'views/storySnapUploader/hooks/useUploaderHandlers';
import { useUploaderState } from 'views/storySnapUploader/hooks/useUploaderState';

import style from './MultiStorySnapEditor.scss';

import { StorySnapMediaWithPreview } from 'types/media';
import { UploaderState } from 'types/storySnapEditor';

export default function MultiStorySnapEditor() {
  const authType = useSelector(getAuthType);

  const {
    state: { setUploaderState, uploadState },
    utils: { isUploadNotReady },
  } = useUploaderState();
  const { handleOnUpload, handleOnPost } = useUploaderHandlers(UploadPurpose.PUBLIC_STORY);
  const [mediaWithPreview, setMediaWithPreview] = useState<StorySnapMediaWithPreview | undefined>();

  const onUpload = useCallback(
    (file: File) => {
      handleOnUpload(file, setUploaderState).then(snap => setMediaWithPreview(snap));
    },
    [handleOnUpload, setUploaderState]
  );

  const renderBlankPageWithText = () => {
    return (
      <BlankPageWithText
        happyState
        message={
          <FormattedMessage
            id="uploader-no-public-story-google-auth"
            description="Access denied to the Public Story Editor because user is logged in with GA"
            defaultMessage="Please login with Snap Auth to upload to Public Story"
          />
        }
      />
    );
  };

  const handleOnClick = useCallback(() => {
    handleOnPost(false, mediaWithPreview, undefined, undefined, undefined, setUploaderState);
  }, [handleOnPost, mediaWithPreview, setUploaderState]);

  const renderEditorNavBar = () => {
    return (
      <div className={style.navbar}>
        <StoryBuilderName />
        <div className={style.buttonsWrapper}>
          <MutePreviewButton />
          <SDSButton
            type={ButtonType.PRIMARY}
            disabled={isUploadNotReady()}
            onClick={handleOnClick}
            data-test="MultiStorySnapEditor.submit.button"
          >
            {
              <FormattedMessage
                id="edition-post-snap-button"
                description="Post Snap button"
                defaultMessage="Post to Snap"
              />
            }
          </SDSButton>
        </div>
      </div>
    );
  };

  const handleClearMedia = useCallback(() => {
    setUploaderState(UploaderState.NOT_STARTED);
    setMediaWithPreview(undefined);
  }, [setUploaderState]);

  const handleClearEditor = useCallback(() => {
    // If posting failed then don't clear the media to let the user try again
    if (uploadState === UploaderState.SUBMITTED_ERROR) {
      setUploaderState(UploaderState.DONE);
      return;
    }
    handleClearMedia();
  }, [handleClearMedia, setUploaderState, uploadState]);

  const renderCenterComponent = () => {
    switch (uploadState) {
      case UploaderState.SUBMITTED:
        return (
          <div className={style.submittedWrapper}>
            <SubmittedPostSection handleClearEditor={handleClearEditor} />
          </div>
        );
      case UploaderState.SUBMITTED_ERROR:
        return (
          <div className={style.submittedWrapper}>
            <SubmittedPostSection handleClearEditor={handleClearEditor} isError />
          </div>
        );
      default:
        return (
          <StorySnapCardRenderer uploadState={uploadState} onUpload={onUpload} mediaWithPreview={mediaWithPreview} />
        );
    }
  };

  const renderUploaderView = () => {
    return (
      <div className={style.editorViewWrapper}>
        {renderEditorNavBar()}
        <div data-test="MultiStorySnapEditor.cardWrapper" className={style.cardWrapper}>
          {renderCenterComponent()}
        </div>
      </div>
    );
  };

  return (
    <div className={style.viewWrapper}>
      {authType === AuthType.GOOGLE ? renderBlankPageWithText() : renderUploaderView()}
    </div>
  );
}
