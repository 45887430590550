import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { goToMagicSearch } from 'state/router/actions/routerActions';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import ListItemWithIcon from '../../../common/components/ListItem/ListItemWithIcon';
import SnapPopoverButtonRowStyle from '../../styles/SnapPopoverButtonRow.scss';
import { SnapPopoverConfig, SnapPopoverRowIds } from '../../utils/SnapPopoverRowConfig';
import SnapSnapcodeModal from '../SnapSnapcodeModal/SnapSnapcodeModal';

import * as localRoutes from 'utils/apis/localRoutes';
import { copyToClipboard } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import * as locationUtils from 'utils/locationUtils';
import { ModalType } from 'utils/modalConfig';

import PopoverCloseOnMovement from 'views/common/components/PopoverCloseOnMovement/PopoverCloseOnMovement';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import { CopyToPublisherOptions } from 'views/modals/containers/CopyToPublisherModal/CopyToPublisherModal';

import style from './SnapItemOptionsPopover.scss';

import { Claim } from 'types/permissions';
import type { Publisher } from 'types/publishers';

const POPOVER_ARROW_OFFSET_LEFT = '48px';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => ({
  activePublisher: publishersSelectors.getActivePublisherDetails(state),
  activeSnapHasActionsInProgress: editorSelectors.wholeSnapHasTransactionOrUploading(state)(ownProps.snap.id),
  canDeleteSnap: publisherStoryEditorSelectors.getActiveEditionCanDeleteSnaps(state),
  editionId: publisherStoryEditorSelectors.getActiveEditionId(state),
  editionIsReadOnly: publisherStoryEditorSelectors.getActiveEditionIsReadOnly(state),
  isDebugEntityViewer: userSelectors.hasClaimForActivePublisher(state, Claim.DEBUG_ENTITY_VIEWER),
  isSnapIdCopier: userSelectors.hasClaimForActivePublisher(state, Claim.SNAP_ID_COPIER),
  isSnapCopierForAtLeastOnePublisher: userSelectors.hasClaimForAtLeastOnePublisher(state)(Claim.SNAP_COPIER),
  isSnapSnapcodeEditor: userSelectors.hasClaimForActivePublisher(state, Claim.SNAP_SNAPCODE_EDITOR),
  isSnapSnapcodeViewer: userSelectors.hasClaimForActivePublisher(state, Claim.SNAP_SNAPCODE_VIEWER),
  isSnapSourceCopier: userSelectors.hasClaimForActivePublisher(state, Claim.SNAP_SOURCE_COPIER),
});

const mapDispatchToProps = {
  deleteSnapFromActiveEdition: publisherStoryEditorActions.deleteSnapFromActiveEdition,
  showModal: modalsActions.showModal,
  forceSnapRebuild: snapsActions.forceSnapRebuild,
};

type SnapItemOptionsPopoverProps = {
  activePublisher: Publisher;
  activeSnapHasActionsInProgress: boolean;
  canDeleteSnap?: boolean;
  className?: string;
  deleteSnapFromActiveEdition: (...args: any[]) => any;
  editionId: number;
  editionIsReadOnly: boolean;
  forceSnapRebuild?: (...args: any[]) => any;
  isDebugEntityViewer: boolean;
  isSnapCopierForAtLeastOnePublisher: boolean;
  isSnapIdCopier: boolean;
  isSnapSnapcodeEditor: boolean;
  showModal: (...args: any[]) => any;
  // @ts-expect-error ts-migrate(2749) FIXME: 'snapPropType' refers to a value, but is being use... Remove this comment to see the full error message
  snap: snapPropType;
  isSnapSnapcodeViewer: boolean;
  isSnapSourceCopier: boolean;
};

type SnapItemOptionsPopoverState = any;

export class SnapItemOptionsPopover extends React.Component<SnapItemOptionsPopoverProps, SnapItemOptionsPopoverState> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  state = {
    showDeleteDialog: false,
    showSnapcodeModal: false,
  };

  onDelete = () => {
    if (!this.props.canDeleteSnap) {
      return;
    }

    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'snap-delete');

    this.props.deleteSnapFromActiveEdition({ snapId: this.props.snap.id });
    this.hideDeleteDialog();
  };

  onDebugSnapClick = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'snap-show-debug');
    const { props } = this;
    locationUtils.openInNewWindow(
      localRoutes.debug.snap({ hostUsername: props.activePublisher.hostUsername, snapId: props.snap.id })
    );
  };

  onMagicSearchSnapClick = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'show-magic-search-snap');
    const { snap } = this.props;
    goToMagicSearch(snap.id.split('-')[1]);
  };

  onRebuildSnapClick = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'snap-rebuild-snap');
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.forceSnapRebuild({ snapId: this.props.snap.id });
  };

  onCopySnapId = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'snap-copy-id-to-clipboard');
    copyToClipboard(this.props.snap.id);
  };

  showDeleteDialog = () => {
    if (this.props.canDeleteSnap) {
      this.setState({ showDeleteDialog: true });
    }
  };

  hideDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  };

  showSnapcodeModal = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'snap-show-snapcodes');
    this.setState({ showSnapcodeModal: true });
  };

  hideSnapcodeModal = () => {
    this.setState({ showSnapcodeModal: false });
  };

  updateStyleIfReadOnly(rowId: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return classNames(SnapPopoverConfig[rowId].className, {
      [SnapPopoverButtonRowStyle.disabled]: this.props.editionIsReadOnly || this.props.activeSnapHasActionsInProgress,
    });
  }

  updateDeleteButtonStyle(rowId: any) {
    return classNames(this.updateStyleIfReadOnly(rowId), {
      [SnapPopoverButtonRowStyle.disabled]: !this.props.canDeleteSnap,
    });
  }

  updateManagerSnapcodesStyle(rowId: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return classNames(SnapPopoverConfig[rowId].className, {
      [SnapPopoverButtonRowStyle.disabled]: !snapEntityHelpers.isShareable(this.props.snap),
    });
  }

  updateCopyToEditionStyles() {
    return classNames(SnapPopoverConfig[SnapPopoverRowIds.COPY_TO].className, {
      [SnapPopoverButtonRowStyle.disabled]:
        !this.props.isSnapCopierForAtLeastOnePublisher || this.props.activeSnapHasActionsInProgress,
    });
  }

  showCopyToModal = () => {
    const options: CopyToPublisherOptions = {
      snapId: this.props.snap.id,
      copySnap: true,
    };
    this.props.showModal(ModalType.COPY_TO_PUBLISHER, 'SnapItemOptionsPopover', options);
  };

  renderDeleteDialog() {
    return (
      <SDSDialog
        visible={this.state.showDeleteDialog}
        onOk={this.onDelete}
        onCancel={this.hideDeleteDialog}
        isBodyCentered
        data-test="storySnapListItem.menu.options.deleteAlert"
      >
        <FormattedMessage
          id="are-you-sure"
          description="Ask for confirmation when deleting a snap"
          defaultMessage="Are you sure?"
        />
      </SDSDialog>
    );
  }

  renderSnapcodeModal() {
    return (
      <SnapSnapcodeModal
        entityId={this.props.snap.id}
        isReadOnly={!this.props.isSnapSnapcodeEditor}
        onHide={this.hideSnapcodeModal}
      />
    );
  }

  renderAdminTools() {
    const { isDebugEntityViewer, isSnapIdCopier } = this.props;

    const renderDebugSnap = () => {
      return (
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.DEBUG_SNAP]}
          data-test="snapItemOptionsPopover.snapOptions.debugSnap"
          onClick={this.onDebugSnapClick}
        />
      );
    };

    const renderMagicSearchSnap = () => {
      return (
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.MAGIC_SEARCH_SNAP]}
          data-test="snapItemOptionsPopover.snapOptions.magicSearchSnap"
          onClick={this.onMagicSearchSnapClick}
        />
      );
    };

    const renderCopySnapId = () => {
      return (
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.COPY_SNAP_ID]}
          data-test="snapItemOptionsPopover.snapOptions.copySnap"
          onClick={this.onCopySnapId}
        />
      );
    };

    const hasAnyAdminControls = isDebugEntityViewer || isSnapIdCopier;
    return (
      hasAnyAdminControls && (
        <div data-test="snapItemOptionsPopover.adminOptions">
          {isDebugEntityViewer && <div className={style.lineSeparator} />}
          {isDebugEntityViewer && renderDebugSnap()}
          {isDebugEntityViewer && renderMagicSearchSnap()}
          {isSnapIdCopier && <div className={style.lineSeparator} />}
          {isSnapIdCopier && renderCopySnapId()}
        </div>
      )
    );
  }

  renderCopySnapToModal() {
    const shouldRender = this.props.isSnapCopierForAtLeastOnePublisher && this.props.isSnapSourceCopier;
    if (shouldRender) {
      return (
        <>
          <div className={style.lineSeparator} />
          <ListItemWithIcon
            {...SnapPopoverConfig[SnapPopoverRowIds.COPY_TO]}
            onClick={this.showCopyToModal}
            className={this.updateCopyToEditionStyles()}
            data-test="storySnapListItem.menu.options.copyTo"
          />
        </>
      );
    }
    return null;
  }

  renderOptionsForSnapType() {
    const isSubscribeSnap = snapEntityHelpers.isSubscribeSnap(this.props.snap);
    const defaultOptions = (
      <div data-test="storySnapListItem.menu.options.defaultOptions">
        {this.renderCopySnapToModal()}
        <div className={style.lineSeparator} />
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.DELETE]}
          onClick={this.showDeleteDialog}
          className={this.updateDeleteButtonStyle(SnapPopoverRowIds.DELETE)}
          data-test="storySnapListItem.menu.options.delete"
        />
      </div>
    );

    return isSubscribeSnap ? '' : defaultOptions;
  }

  render() {
    return (
      <div data-test="storySnapListItem.options.popover" className={this.props.className}>
        <PopoverCloseOnMovement
          id="SnapItemOptionsPopover"
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          target={this.props.children}
          arrowOffsetLeft={POPOVER_ARROW_OFFSET_LEFT}
          className={style.customPopover}
          closeOnVerticalMovement={false}
          container={this.context.getScrollContainer}
        >
          {this.props.isSnapSnapcodeViewer && (
            <ListItemWithIcon
              {...SnapPopoverConfig[SnapPopoverRowIds.MANAGE_SNAPCODES]}
              onClick={this.showSnapcodeModal}
              className={this.updateManagerSnapcodesStyle(SnapPopoverRowIds.MANAGE_SNAPCODES)}
              data-test="storySnapListItem.menu.options.manageSnapcodes"
            />
          )}
          {this.renderOptionsForSnapType()}
          {this.renderAdminTools()}
          <div className={style.lineSeparator} />
          <ListItemWithIcon
            {...SnapPopoverConfig[SnapPopoverRowIds.REBUILD_SNAP]}
            className={this.updateStyleIfReadOnly(SnapPopoverRowIds.REBUILD_SNAP)}
            onClick={this.onRebuildSnapClick}
            data-test="storySnapListItem.menu.options.rebuildSnap"
          />
        </PopoverCloseOnMovement>
        {this.state.showDeleteDialog ? this.renderDeleteDialog() : null}
        {this.state.showSnapcodeModal ? this.renderSnapcodeModal() : null}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapItemOptionsPopover);
