import classNames from 'classnames';
import _ from 'lodash';
import log from 'loglevel';
import PropTypes from 'prop-types';
import * as React from 'react';

import style from './RadioButtons.scss';

type Value = string | number;

type OwnProps = {
  'data-test'?: string;
  className?: string;
  checkedClassName?: string;
  isReadOnly?: boolean;
  name: string;
  onChange?: (a: Event) => void;
  onMouseEnter?: (b: string, a: Value) => void;
  onMouseLeave?: (a: string) => void;
  options: Array<{
    iconChecked?: string;
    iconClassName?: string;
    iconDefault?: string;
    label?: string;
    value: Value;
  }>;
  showIconCheckedOnHover?: boolean;
  value?: Value;
};

type State = {
  hoveredValue: Value | undefined | null;
};

type Props = OwnProps & typeof RadioButtons.defaultProps;

export default class RadioButtons extends React.Component<Props, State> {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    'data-test': PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string,
        iconDefault: PropTypes.string,
        iconChecked: PropTypes.string,
        iconClassName: PropTypes.string,
      })
    ).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isReadOnly: PropTypes.bool,
    showIconCheckedOnHover: PropTypes.bool,
  };

  static defaultProps = {
    isReadOnly: false,
    onMouseEnter: _.noop,
    onMouseLeave: _.noop,
  };

  state: State = {
    hoveredValue: null,
  };

  // can't shallow-compare because 'options' array can be recreated in nextProps
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (nextProps && !_.isEqual(this.props, nextProps)) || (nextState && !_.isEqual(this.state, nextState));
  }

  _handleChange = (event: Event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  _handleMouseEnter = (value: Value) => () => {
    this.setState({ hoveredValue: value });
    const { onMouseEnter } = this.props;
    if (onMouseEnter) {
      onMouseEnter(this.props.name, value);
    }
  };

  _handleMouseLeave = () => {
    this.setState({ hoveredValue: null });
    const { onMouseLeave } = this.props;
    if (onMouseLeave) {
      onMouseLeave(this.props.name);
    }
  };

  _renderInputs() {
    let foundInput = false;

    const { isReadOnly, checkedClassName } = this.props;
    const nonSrcClassName = isReadOnly ? style.disabled : null;

    const inputs = _.map(this.props.options, option => {
      const checked = option.value === this.props.value;

      const shouldShowCheckedIcon =
        this.props.showIconCheckedOnHover && this.state.hoveredValue !== null
          ? this.state.hoveredValue === option.value
          : checked;

      const src = shouldShowCheckedIcon ? option.iconChecked : option.iconDefault;

      foundInput = foundInput || checked;

      const labelClassNames = classNames(
        style.label,
        checked ? checkedClassName : null // can not use an object cause flow will complain that checkedClassName might be undefined
      );

      return (
        <label
          className={labelClassNames}
          key={option.value}
          onMouseEnter={this._handleMouseEnter(option.value)}
          onMouseLeave={this._handleMouseLeave}
          htmlFor={`RadioButtons_${this.props.name}_${option.value}`}
        >
          <input
            type="radio"
            id={`RadioButtons_${this.props.name}_${option.value}`}
            name={this.props.name}
            value={option.value}
            checked={checked}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
            className={src ? style.hidden : nonSrcClassName}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: Event) => void' is not assignable to... Remove this comment to see the full error message
            onChange={this._handleChange}
            disabled={isReadOnly}
          />
          {src && (
            <img
              src={src}
              alt={option.label}
              className={classNames(option.iconClassName, { [style.disabled]: isReadOnly })}
            />
          )}
          {!src && option.label}
        </label>
      );
    });

    if (!foundInput && this.props.value !== undefined) {
      log.warn(`Supplied value ${String(this.props.value)} does not match any of the available options`);
    }

    return inputs;
  }

  render() {
    // ESLint incorrectly flags this as a violation.
    // Might want to add 'data-test' to the 'ignore' option for react/prop-types.
    // eslint-disable-next-line react/prop-types
    const { 'data-test': dataTest } = this.props;

    return (
      <div className={this.props.className} data-test={dataTest}>
        {this._renderInputs()}
      </div>
    );
  }
}
