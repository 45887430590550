import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';

import SDSButton, { ButtonSize, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import { SnapPopoverModalConfig } from 'views/publisherStoryEditor/utils/SnapPopoverRowConfig';

import style from './LockUnlockLiveStoryAlert.scss';

import { StoryState, LiveEditStatus } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';

const mapStateToProps = (state: State) => {
  const activePublisherId = publishersSelectors.getActivePublisherId(state);
  return {
    isRollingNewsEnabled: publishersSelectors.isRollingNewsEnabledForPublisherWithId(state)(activePublisherId),
    activeStory: publisherStoryEditorSelectors.getActiveEdition(state),
  };
};

const nextEditionStatusMap = {
  [LiveEditStatus.NONE_OR_PUBLISHED]: LiveEditStatus.IN_PROGRESS,
  [LiveEditStatus.IN_PROGRESS]: LiveEditStatus.READY_FOR_PUBLISHING,
  [LiveEditStatus.READY_FOR_PUBLISHING]: LiveEditStatus.IN_PROGRESS,
};
const mapDispatchToProps = {
  setLiveStoryLock: editionsActions.setLiveEditionLock,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type OwnProps = {
  onHide: () => void;
};

type Props = StateProps & DispatchProps & OwnProps;

export class LockUnlockLiveStoryAlert extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  state = {
    isConfirmationAlertShowing: false,
    isNewsDecisionAlertShowing: false,
    isNewsUpdate: false,
  };

  UNSAFE_componentWillMount() {
    this.showCorrectAlert();
  }

  storyIsUnlocked = () => (this.props.activeStory as any).liveEditStatus === LiveEditStatus.IN_PROGRESS;

  storyPendingPublishLiveEdits = () => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'liveEditStatus' does not exist on type '... Remove this comment to see the full error message
      activeStory: { state, liveEditStatus },
    } = this.props;
    return state === StoryState.LIVE && liveEditStatus === LiveEditStatus.READY_FOR_PUBLISHING;
  };

  showCorrectAlert = () => {
    if (this.storyIsUnlocked() && this.props.isRollingNewsEnabled) {
      this.setState({ isNewsDecisionAlertShowing: true });
    } else {
      this.setState({ isConfirmationAlertShowing: true });
    }
  };

  handleIsNewsUpdate = (isNewsUpdate: any) => {
    this.setState({ isConfirmationAlertShowing: true, isNewsUpdate });
    this.setState({ isNewsDecisionAlertShowing: false });
  };

  handleEditionLock = () => {
    if (this.props.activeStory) {
      const thisEditionStatus = this.props.activeStory.liveEditStatus;
      const nextEditionStatus = nextEditionStatusMap[thisEditionStatus];
      gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-live-edit-lock', {
        from: thisEditionStatus,
        to: nextEditionStatus,
      });

      this.props.setLiveStoryLock({
        editionId: this.props.activeStory.id,
        nextStatus: nextEditionStatus,
        newsUpdate: this.state.isNewsUpdate,
      });
    }
  };

  renderConfirmChangingLockStateAlert = () => {
    let alertConfig;
    if (this.storyPendingPublishLiveEdits()) {
      alertConfig = SnapPopoverModalConfig.UnlockLiveEditionWithPendingPublish;
    } else if (!this.storyIsUnlocked()) {
      alertConfig = SnapPopoverModalConfig.UnlockLiveEdition;
    } else if (!this.props.isRollingNewsEnabled) {
      alertConfig = SnapPopoverModalConfig.LockLiveEdition;
    } else {
      alertConfig = this.state.isNewsUpdate
        ? SnapPopoverModalConfig.LockNewsUpdate
        : SnapPopoverModalConfig.LockLiveEdition;
    }
    return this.renderConfirmationAlert(alertConfig);
  };

  onOkConfirmationAlert = () => {
    this.handleEditionLock();
    this.props.onHide();
  };

  renderConfirmationAlert = (alertConfig: any) => {
    return (
      <SDSDialog
        visible
        isBodyCentered
        title={alertConfig.text.title}
        onOk={this.onOkConfirmationAlert}
        onCancel={this.props.onHide}
        data-test={`lockUnlockLiveStoryAlert.confirmation.${alertConfig.id}`}
      >
        <p>{alertConfig.text.paragraph1}</p>
        <div className={style.note}>{alertConfig.text.paragraph2}</div>
      </SDSDialog>
    );
  };

  handleBreakingNewsOnClick = () => {
    this.handleIsNewsUpdate(true);
  };

  handleStoryRevisionOnClick = () => {
    this.handleIsNewsUpdate(false);
  };

  footer = (
    <>
      <SDSButton
        type={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={this.handleBreakingNewsOnClick}
        data-test="lockUnlockLiveStoryAlert.newDecision.breaking.news.button"
      >
        <FormattedMessage
          id="live-edition-lock-alert-confirm-button"
          description="Text informing that live changes confirm button"
          defaultMessage="Breaking News"
        />
      </SDSButton>
      <SDSButton
        type={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={this.handleStoryRevisionOnClick}
        data-test="lockUnlockLiveStoryAlert.newDecision.story.revision.button"
      >
        <FormattedMessage
          id="live-edition-lock-alert-cancel-button"
          description="Text informing that live changes cancel button"
          defaultMessage="Story Revision"
        />
      </SDSButton>
    </>
  );

  renderNewsDecisionAlert = () => {
    const alertConfig = SnapPopoverModalConfig.IsThisNewsUpdate;
    return (
      <SDSCustomModal
        visible
        title={alertConfig.text.title}
        footer={this.footer}
        onClose={this.props.onHide}
        data-test={`lockUnlockLiveStoryAlert.newDecision.${alertConfig.id}`}
      >
        {alertConfig.text.paragraph2}
      </SDSCustomModal>
    );
  };

  render() {
    return (
      <div>
        {this.state.isNewsDecisionAlertShowing ? this.renderNewsDecisionAlert() : null}
        {this.state.isConfirmationAlertShowing ? this.renderConfirmChangingLockStateAlert() : null}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(LockUnlockLiveStoryAlert);
