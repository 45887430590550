import classNames from 'classnames';
import React from 'react';
import type { MouseEvent } from 'react';

import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';

import sharedStyle from './ListItemStyles.scss';
import style from './ListItemWithToggle.scss';

type OwnProps = {
  text: React.ReactNode;
  onChange: (value: boolean) => void;
  onMouseOver?: (...args: any[]) => any;
  onMouseOut?: (...args: any[]) => any;
  className?: string;
  value: boolean;
  optionsButtonPopover?: boolean;
  isReadOnly?: boolean;
  isInvalid?: boolean;
  'data-test'?: string;
};

type Props = OwnProps & typeof ListItemWithToggle.defaultProps;

class ListItemWithToggle extends React.Component<Props> {
  static defaultProps = {
    onClick: (event: MouseEvent<HTMLDivElement>) => {},
    onMouseOver: () => {},
    onMouseOut: () => {},
    isReadOnly: false,
  };

  onChange = (value: boolean) => {
    this.props.onChange(value);
  };

  // Prevents clicking on toggle buttons from closing the popover
  preventEventPropagation = (e: MouseEvent<HTMLDivElement>) => {
    if (e) {
      e.stopPropagation();
    }
  };

  render() {
    const itemClassName = classNames(style.itemWithToggle, this.props.className, {
      [sharedStyle.row]: this.props.optionsButtonPopover,
    });
    return (
      <div
        className={itemClassName}
        onClick={this.preventEventPropagation}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
      >
        <div className={style.toggle}>
          <SDSSwitch
            onChange={this.onChange}
            value={this.props.value}
            isReadOnly={this.props.isReadOnly}
            isSmallToggle
            data-test={
              this.props['data-test'] ? `${this.props['data-test']}.toggleSwitch` : 'listItem.withToggle.toggleSwitch'
            }
            hasError={this.props.isInvalid}
          />
        </div>
        {this.props.text}
      </div>
    );
  }
}

export default ListItemWithToggle;
