import _ from 'lodash';
import React from 'react';

import { chevronLeft, chevronRight } from 'icons/SDS/allIcons';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';

import style from './PagedPanel.scss';

type OwnProps = {
  onLeftChange?: (...args: any[]) => any;
  isLeftReadOnly?: boolean;
  onRightChange?: (...args: any[]) => any;
  isRightReadOnly?: boolean;
};

type Props = OwnProps & typeof PagedPanel.defaultProps;

export default class PagedPanel extends React.Component<Props> {
  static defaultProps = {
    onLeftChange: _.noop,
    isLeftReadOnly: false,
    onRightChange: _.noop,
    isRightReadOnly: false,
  };

  handleLeftChange = () => {
    if (!this.props.isLeftReadOnly) {
      this.props.onLeftChange();
    }
  };

  handleRightChange = () => {
    if (!this.props.isRightReadOnly) {
      this.props.onRightChange();
    }
  };

  render() {
    return (
      <div className={style.panelContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={chevronLeft}
          onClick={this.handleLeftChange}
          disabled={this.props.isLeftReadOnly}
          data-test={`editor.pagedPanel.${this.props.isLeftReadOnly}.arrowRound.button`}
        />
        {this.props.children}
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={chevronRight}
          onClick={this.handleRightChange}
          disabled={this.props.isRightReadOnly}
          data-test={`editor.pagedPanel.${this.props.isRightReadOnly}.arrowRound.button`}
        />
      </div>
    );
  }
}
