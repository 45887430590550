import Progress from 'antd/lib/progress';
import type { ProgressProps } from 'antd/lib/progress';
import classNames from 'classnames';
import { omit } from 'lodash';
import React from 'react';

import style from './SDSProgressBar.scss';

export enum ProgressBarType {
  LINE = 'line',
  CIRCLE = 'circle',
}

export enum ProgressBarSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

enum ProgressBarThickness {
  SMALL = 25,
  MEDIUM = 15,
}

type Props = ProgressProps & {
  progressType: ProgressBarType;
  progressSize: ProgressBarSize;
  'data-test': string;
};

function SDSProgressBar(props: Props) {
  const { progressType, progressSize } = props;

  const progressClassNames = classNames({
    [style.smallProgressCircle]: progressSize === ProgressBarSize.SMALL && progressType === ProgressBarType.CIRCLE,
    [style.mediumProgressCircle]: progressSize === ProgressBarSize.MEDIUM && progressType === ProgressBarType.CIRCLE,
  });

  return (
    <div className={progressClassNames} data-test={`${props['data-test']}.SDSProgressBar`}>
      <Progress
        {...omit(props, ['progressSize', 'progressType'])}
        type={progressType}
        strokeWidth={progressSize === ProgressBarSize.SMALL ? ProgressBarThickness.SMALL : ProgressBarThickness.MEDIUM}
      />
    </div>
  );
}

export default SDSProgressBar;
