import classNames from 'classnames';
import React from 'react';

import { TileFormat, TileFormatConfig } from 'config/tileConfig';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import TilePreview from 'views/editor/containers/TilePreview/TilePreview';

import style from './SchedulingTilePreview.scss';

import type { Tile } from 'types/tiles';

type Props = {
  tile: Tile;
  showEditText?: boolean;
  onTileClick: () => void;
};

const SchedulingTilePreview = (props: Props) => {
  return (
    <span onClick={props.onTileClick}>
      <div className={style.layoutGroup}>
        <TilePreview
          className={style.tilePreview}
          tile={props.tile}
          format={TileFormatConfig[TileFormat.CHEETAH_DEFAULT]}
          useSmallHeadline
        />
        <div className={classNames(style.tileShadow, style.firstShadow)} />
        <div className={classNames(style.tileShadow, style.secondShadow)} />
      </div>
      {props.showEditText ? <div className={style.editText}>{getMessageFromId('schedule-modal-edit-tile')}</div> : null}
    </span>
  );
};

export default SchedulingTilePreview;
