import { getAllStorySubtitlesGenerated } from 'state/buildStatus/selectors/buildStatusSelectors';
import { reselectById } from 'state/common/selectorFactories';
import { editionIsReadOnly } from 'state/editions/selectors/editionsSelectors';
import { hasMediaOnEachSnap } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import type { EditionID } from 'types/editions';

// If story contains only subtitles which are generated and it is not read only we display the subtitles warning.
export const shouldDisplayManageSubtitlesInfoBox = reselectById<boolean, EditionID>(
  false,
  (state: any, storyId: any) => getAllStorySubtitlesGenerated(state)(storyId),
  (state: any, storyId: any) => hasMediaOnEachSnap(state)(storyId),
  (state: any, storyId: any) => editionIsReadOnly(state)(storyId),
  (allStorySubtitlesAreGenerate: any, mediaOnEachSnap: any, isReadOnly: any) =>
    allStorySubtitlesAreGenerate && mediaOnEachSnap && !isReadOnly
);
