import * as snapcodesActions from 'state/snapcodes/actions/snapcodesActions';
import {
  getDecoratedSnapcodesPerSnapId,
  getLastUpdatedPerSnapId,
  getIsLoadingPerSnapId,
} from 'state/snapcodes/selectors/snapcodesSelectors';

import { SnapcodeModal } from '../../components/SnapcodeModal/SnapcodeModal';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { functionRef } from 'utils/functionUtils';
import * as gaUtils from 'utils/gaUtils';

function mapStateToProps(state: State, ownProps: any) {
  return {
    isSnapcodesLoading: getIsLoadingPerSnapId(state)(ownProps.entityId) > 0,
    snapcodesLastUpdated: getLastUpdatedPerSnapId(state)(ownProps.entityId),
    snapcodes: getDecoratedSnapcodesPerSnapId(state)(ownProps.entityId),
    gaCategory: gaUtils.GAUserActions.EDITION,
    gaContext: 'snap',
  };
}

const mapDispatchToProps = {
  loadSnapcodes: functionRef(snapcodesActions, 'loadSnapSnapcodes'),
  updateSnapcode: functionRef(snapcodesActions, 'updateSnapSnapcode'),
  createSnapcode: functionRef(snapcodesActions, 'createSnapSnapcode'),
};

export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapcodeModal);
