import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { discardLiveEditChanges, reloadStoryAndSnaps } from 'state/editions/actions/editionsActions';
import { showModal as showModalAction } from 'state/modals/actions/modalsActions';
import { shouldUseSingleAssetEditor } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import {
  initializeSingleAssetStory,
  terminateSingleAssetStory,
} from 'state/singleAssetStory/actions/singleAssetStoryActions';

import { trash as rubbish } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { ModalType } from 'utils/modalConfig';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { AlertOptions } from 'views/modals/containers/AlertModal/AlertModal';

import { EditionID } from 'types/editionID';
import { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

type OwnProps = {
  publisherId: PublisherID;
  storyId: EditionID;
};

const mapStateToProps = (state: State, props: OwnProps) => {
  return {
    isSingleAssetStory: shouldUseSingleAssetEditor(state)(props.storyId),
  };
};

const mapDispatchToProps = {
  showModal: showModalAction,
  discardChanges: discardLiveEditChanges,
  reloadStory: reloadStoryAndSnaps,
  terminateSingleAssetEditor: terminateSingleAssetStory,
  initialiseSingleAssetEditor: initializeSingleAssetStory,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

export function DiscardLiveEditChangesButton({
  publisherId,
  storyId,
  isSingleAssetStory,
  showModal,
  discardChanges,
  reloadStory,
  terminateSingleAssetEditor,
  initialiseSingleAssetEditor,
}: Props) {
  const onConfirm = useCallback(async () => {
    await discardChanges(storyId);
    await reloadStory(storyId);
    if (isSingleAssetStory) {
      await terminateSingleAssetEditor(storyId);
      await initialiseSingleAssetEditor(publisherId, storyId);
    }
  }, [
    discardChanges,
    storyId,
    reloadStory,
    isSingleAssetStory,
    terminateSingleAssetEditor,
    initialiseSingleAssetEditor,
    publisherId,
  ]);

  const handleOnClick = useCallback(() => {
    const options: AlertOptions = {
      onConfirm,
      body: (
        <FormattedMessage
          id="discard-live-edit-warning"
          description="Warning about changes being lost after discarding"
          defaultMessage="Are you sure you want to discard all the changes? This action cannot be reverted"
        />
      ),
    };

    showModal(ModalType.ALERT, 'DiscardLiveEditChangesButton', options);
  }, [showModal, onConfirm]);

  const button = (
    <SDSButton
      onClick={handleOnClick}
      inlineIcon={rubbish}
      type={ButtonType.WHITE_ON_GREY}
      shape={ButtonShape.CIRCLE}
      data-test="DiscardLiveEditChangesButton.button"
    />
  );

  const title = (
    <FormattedMessage
      id="discard-live-edit-message"
      description="Discard live edit tooltip message"
      defaultMessage="Discard unpublished changes"
    />
  );

  return (
    <SDSTooltip
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      placement={TooltipPosition.BOTTOM}
      title={title}
      id="discard-live-edit-tooltip"
      data-test="DiscardLiveEditChangesButton.tooltip"
    >
      {button}
    </SDSTooltip>
  );
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(DiscardLiveEditChangesButton);
