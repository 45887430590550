import classNames from 'classnames';
import { get } from 'lodash';
import React, { memo } from 'react';
import type { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedMessage } from 'react-intl';

import { ZENDESK_BASE_URL } from 'config/constants';
import { TextValidationResult } from 'config/textValidationConstants';
import type { ValidateTextResponses } from 'config/textValidationConstants';
import { help, externalLink } from 'icons/SDS/allIcons';
import { escapeRegExp } from 'utils/regexUtils';

import { AlertBox, AlertType } from 'views/common/components/AlertBox/AlertBox';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './PanelTextValidationField.scss';

const MESSAGES = {
  ERROR: {
    PRIMARY: {
      TEXT_NOT_ALLOWED: (
        <FormattedMessage
          id="text-validation-field-tile-headline-not-allowed"
          description="Text Validation Field explanation for text not being allowed"
          defaultMessage="Tile headline not allowed"
          data-test="editor.tilePropertyPanel.textNotAllowed"
        />
      ),
    },
    SECONDARY: {
      NO_TEXT: (
        <FormattedMessage
          id="text-validation-field-empty-text-warning"
          description="Text Validation Field explanation for empty text"
          defaultMessage="Headline must not be empty"
          data-test="editor.tilePropertyPanel.noTextWarning"
        />
      ),
      INVALID_ELEMENTS: (
        <FormattedMessage
          id="text-validation-field-invalid-elements-content-guideline-reference"
          description="Text Validation Field explanation for invalid elements"
          defaultMessage="Does not pass our content guidelines"
          data-test="editor.tilePropertyPanel.invalidElementsInTextWarning"
        />
      ),
    },
  },
  WARNING: {
    PRIMARY: {
      TEXT_NOT_ALLOWED: (
        <FormattedMessage
          id="text-validation-field-tile-headline-not-allowed-unhealthy"
          description="Text Validation Field explanation for text triggering a warning"
          defaultMessage="Tile headline may be rejected"
          data-test="editor.tilePropertyPanel.textNotAllowedUnhealthy"
        />
      ),
    },
    SECONDARY: {
      INVALID_ELEMENTS: (
        <FormattedMessage
          id="text-validation-field-invalid-elements-content-guideline-reference-unhealthy"
          description="Text Validation Field explanation for having warning elements"
          defaultMessage="Please consider updating to avoid a violation"
          data-test="editor.tilePropertyPanel.invalidElementsInTextWarningUnhealthy"
        />
      ),
    },
  },
  INFO: {
    PRIMARY: {
      TEXT_NOT_ALLOWED: (
        <FormattedMessage
          id="text-validation-field-tile-headline-not-allowed-unhealthy-info"
          description="Text Validation Field explanation for text triggering a soft warning"
          defaultMessage="Unrecognized Capitalized Word"
          data-test="editor.tilePropertyPanel.textNotAllowedUnhealthyInfo"
        />
      ),
    },
    SECONDARY: {
      INVALID_ELEMENTS: (
        <FormattedMessage
          id="text-validation-field-invalid-elements-content-guideline-reference-unhealthy-info"
          description="Text Validation Field explanation for having soft warning elements"
          defaultMessage="Publishing is enabled. Check guidelines for info"
          data-test="editor.tilePropertyPanel.invalidElementsInTextWarningUnhealthyInfo"
        />
      ),
    },
  },
};

type Props = {
  label: ReactNode;
  labelTooltipText?: ReactNode;
  text: string | undefined | null;
  textValidationResult: ValidateTextResponses;
};

class PanelTextValidationField extends React.PureComponent<Props> {
  CONTENT_GUIDELINES_URL = `${ZENDESK_BASE_URL}/hc/en-us/articles/360040504854-Tile-Violation-Definitions`;

  hasText() {
    return !!this.props.text;
  }

  hasTextPassedValidation() {
    const criticalResult = get(this.props, ['textValidationResult', 'critical', 'result'], null);
    return !criticalResult || criticalResult !== TextValidationResult.INVALID;
  }

  hasPassedWarningsCheck() {
    const unhealthyResult = get(this.props, ['textValidationResult', 'unhealthy', 'result'], null);
    return !unhealthyResult || unhealthyResult !== TextValidationResult.INVALID;
  }

  isTextValid() {
    return this.hasText() && this.hasTextPassedValidation();
  }

  renderFieldLabelTooltip() {
    if (!this.props.labelTooltipText) {
      return null;
    }
    return (
      <SDSTooltip
        title={this.props.labelTooltipText}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.RIGHT}
      >
        <Icon
          inlineIcon={help}
          className={classNames(style.infoIcon, {
            [style.validStyle]: this.isTextValid(),
            [style.notValidStyle]: !this.isTextValid(),
          })}
          data-test="editor.tilePropertyPanel.labelTooltip"
        />
      </SDSTooltip>
    );
  }

  renderFieldLabel() {
    if (!this.hasText() || (this.hasTextPassedValidation() && this.hasPassedWarningsCheck())) {
      return null;
    }
    return (
      <div
        data-test="editor.tilePropertyPanel.label"
        className={classNames(style.formLabel, {
          [style.validColor]: this.isTextValid(),
          [style.notValidColor]: !this.isTextValid(),
        })}
      >
        {this.props.label}
        {this.renderFieldLabelTooltip()}
      </div>
    );
  }

  renderTextBox() {
    if (!this.hasText() || (this.hasTextPassedValidation() && this.hasPassedWarningsCheck())) {
      return null;
    }
    return (
      <div data-test="editor.tilePropertyPanel.textBox" className={style.formTextBox}>
        <div className={style.formText}>
          <Highlighter
            searchWords={this.getSearchWords()}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            textToHighlight={this.props.text}
            highlightTag={this.highlight}
            caseSensitive
          />
        </div>
      </div>
    );
  }

  highlight = ({ children }: { children: string }) => {
    const criticalWords = this.getSearchWordsCriticalCase();
    const unhealthyWords = this.getSearchWordsUnhealthyCase();
    const isCritical = criticalWords.indexOf(children) >= 0;
    const isUnhealthy = !isCritical && unhealthyWords.indexOf(children) >= 0;
    const severityLevel = (isCritical && 'critical') || (this.isWordUppercase(children) && 'info') || 'unhealthy';
    const dataTestLabel = `editor.tilePropertyPanel.mark.${severityLevel}`;
    return (
      <mark
        className={classNames(style.highlightedTextStyle, {
          [style.criticalWord]: isCritical,
          [style.unhealthyInfoOnlyWord]: isUnhealthy && this.isWordUppercase(children),
          [style.unhealthyWord]: isUnhealthy && !this.isWordUppercase(children),
        })}
        data-test={dataTestLabel}
      >
        {children}
      </mark>
    );
  };

  getSearchWords() {
    return this.getSearchWordsCriticalCase().concat(this.getSearchWordsUnhealthyCase());
  }

  getSearchWordsCriticalCase() {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    return get(this.props, ['textValidationResult', 'critical', 'reasons'], []).map((word: any) => escapeRegExp(word));
  }

  getSearchWordsUnhealthyCase() {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    return get(this.props, ['textValidationResult', 'unhealthy', 'reasons'], []).map((word: any) => escapeRegExp(word));
  }

  isWordUppercase(word: string) {
    return word.toUpperCase() === word;
  }

  renderWrappedAlertBox() {
    const contentToDisplay = this.getProblemsFoundAlertBoxCriticalContentProps();
    if (!contentToDisplay) {
      return null;
    }
    const { hasExternalLinkIcon } = contentToDisplay;
    if (hasExternalLinkIcon) {
      return (
        <a href={this.CONTENT_GUIDELINES_URL} target="_blank" data-test="editor.tilePropertyPanel.alertBox.link">
          {this.renderAlertBox()}
        </a>
      );
    }
    return this.renderAlertBox();
  }

  renderAlertBox() {
    const contentToDisplay = this.getProblemsFoundAlertBoxCriticalContentProps();
    if (!contentToDisplay) {
      return null;
    }
    const { primaryText, secondaryText, hasExternalLinkIcon, warningLevel } = contentToDisplay;
    return (
      <AlertBox className={style.alertBoxStyle} type={warningLevel}>
        <div className={style.alertBoxContent} data-test={`panelTextvalidationField.AlertBox${warningLevel}`}>
          <div className={style.problemsFoundTextBox}>
            <div
              className={style.problemsFoundPrimaryText}
              data-test={`panelTextvalidationField.AlertBox${warningLevel}PrimaryText`}
            >
              {primaryText}
            </div>
            {secondaryText && (
              <div
                className={style.problemsFoundSecondaryText}
                data-test={`panelTextvalidationField.AlertBox${warningLevel}SecondaryText`}
              >
                {secondaryText}
              </div>
            )}
          </div>
          {hasExternalLinkIcon && (
            <Icon
              inlineIcon={externalLink}
              className={style.externalLinkIcon}
              data-test="editor.tilePropertyPanel.alertBox.externalLinkIcon"
            />
          )}
        </div>
      </AlertBox>
    );
  }

  getProblemsFoundAlertBoxCriticalContentProps() {
    if (!this.hasText()) {
      return {
        primaryText: MESSAGES.ERROR.PRIMARY.TEXT_NOT_ALLOWED,
        secondaryText: MESSAGES.ERROR.SECONDARY.NO_TEXT,
        hasExternalLinkIcon: false,
        warningLevel: AlertType.ERROR,
      };
    }
    if (!this.hasTextPassedValidation()) {
      return {
        primaryText: MESSAGES.ERROR.PRIMARY.TEXT_NOT_ALLOWED,
        secondaryText: MESSAGES.ERROR.SECONDARY.INVALID_ELEMENTS,
        hasExternalLinkIcon: true,
        warningLevel: AlertType.ERROR,
      };
    }
    if (!this.hasPassedWarningsCheck()) {
      if (this.getSearchWordsUnhealthyCase().every(this.isWordUppercase)) {
        return {
          primaryText: MESSAGES.INFO.PRIMARY.TEXT_NOT_ALLOWED,
          secondaryText: MESSAGES.INFO.SECONDARY.INVALID_ELEMENTS,
          hasExternalLinkIcon: true,
          warningLevel: AlertType.INFO,
        };
      }
      return {
        primaryText: MESSAGES.WARNING.PRIMARY.TEXT_NOT_ALLOWED,
        secondaryText: MESSAGES.WARNING.SECONDARY.INVALID_ELEMENTS,
        hasExternalLinkIcon: true,
        warningLevel: AlertType.WARNING,
      };
    }
    return null;
  }

  render() {
    return (
      <div className={style.panelTextValidationComponentBox}>
        {this.renderWrappedAlertBox()}
        {this.renderFieldLabel()}
        {this.renderTextBox()}
      </div>
    );
  }
}

export default memo(PanelTextValidationField);
