import { Promise } from 'core-js';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { audienceToLongString, renderViolations } from 'state/buildStatus/schema/moderationHelpers';

import PanelTitle from '../PanelTitle/PanelTitle';

import { ZENDESK_BASE_URL } from 'config/constants';
import { openInNewWindow } from 'utils/locationUtils';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './ModerationPanel.scss';

import type { AudienceEnum, ModerationViolationEnum } from 'types/moderation';

export enum ModerationItem {
  TILE,
  SNAP,
}

const remoderatedMessages = {
  [ModerationItem.TILE]: (
    <FormattedMessage
      id="tile-will-be-moderated-again"
      description="Informs publisher how to recover if a snap is flagged by moderation as inappropriate"
      defaultMessage="This tile will be moderated again if you edit and re-publish the story."
    />
  ),
  [ModerationItem.SNAP]: (
    <FormattedMessage
      id="snap-will-be-moderated-again"
      description="Informs publisher how to recover if a snap is flagged by moderation as inappropriate"
      defaultMessage="This snap will be moderated again if you edit and re-publish the story."
    />
  ),
};

type OwnProps = {
  moderationItem: ModerationItem;
  audienceList: Array<AudienceEnum>;
  violationList: Array<ModerationViolationEnum>;
  isAppealEnabled?: boolean;
  isAppealAvailable?: boolean;
  onAppeal?: () => Promise<void>;
};

export class ModerationPanel extends React.Component<OwnProps> {
  zendeskLinks = {
    [ModerationItem.TILE]: `${ZENDESK_BASE_URL}/hc/en-us/articles/360040504854-Tile-Violation-Definitions`,
    [ModerationItem.SNAP]: `${ZENDESK_BASE_URL}/hc/en-us/articles/219502287-Content-Guidelines`,
  };

  handleOpenHelp = () => {
    const link = this.zendeskLinks[this.props.moderationItem];
    openInNewWindow(link);
  };

  handleOnAppeal = () => {
    if (this.props.onAppeal) {
      return this.props.onAppeal();
    }
    return Promise.resolve();
  };

  renderTitle() {
    return (
      <div className={style.title}>
        <DotStatus status={DotStatusState.INCOMPLETE} />
        <PanelTitle
          title={
            <FormattedMessage
              id="moderation-headline"
              description="Informs user the snap/tile has been flagged as inappropriate"
              defaultMessage="Flagged by moderation"
            />
          }
        />
      </div>
    );
  }

  renderAudiences() {
    return (
      <div className={style.audiences}>
        {this.props.audienceList.map(audience => {
          return <div key={audience}>{audienceToLongString(audience)}</div>;
        })}
      </div>
    );
  }

  renderViolations() {
    return <div className={style.violations}>{renderViolations(this.props.violationList)}</div>;
  }

  renderLearnMoreButton() {
    return (
      <div className={style.learnMoreButtonContainer}>
        <SDSButton type={ButtonType.LINK} onClick={this.handleOpenHelp} data-test="ModerationPanel.LearnMore.Button">
          <FormattedMessage
            id="learn-more"
            description="Button text that allows user to learn more information about tile moderations"
            defaultMessage="Learn more"
          />
        </SDSButton>
      </div>
    );
  }

  renderRemoderatedMessage() {
    const remoderatedMessage = remoderatedMessages[this.props.moderationItem];
    return <div>{remoderatedMessage}</div>;
  }

  renderViolationAppealButton() {
    return (
      <div className={style.learnMoreButtonContainer}>
        <SDSButton
          type={ButtonType.LINK}
          onClick={this.handleOnAppeal}
          data-test="ModerationPanel.ViolationAppeal.Button"
        >
          <FormattedMessage
            id="appeal-tile-violation"
            description="Button text that allows user to appeal moderation violation"
            defaultMessage="Appeal violation"
          />
        </SDSButton>
      </div>
    );
  }

  renderViolationAppealSubmittedMessage() {
    return (
      <div className={style.learnMoreButtonContainer}>
        <div className={style.appealMessage} data-test="ModerationPanel.ViolationAppeal.Submitted">
          <FormattedMessage
            id="appeal-tile-violation-submitted"
            description="Message shown instead of appeal button when an appeal has already been submitted"
            defaultMessage="Appeal already submitted"
          />
        </div>
      </div>
    );
  }

  renderViolationAppeal() {
    const { isAppealEnabled, isAppealAvailable } = this.props;
    if (!isAppealEnabled) {
      return null;
    }
    if (isAppealAvailable) {
      return this.renderViolationAppealButton();
    }
    return this.renderViolationAppealSubmittedMessage();
  }

  render() {
    return (
      <div>
        {this.renderTitle()}
        {this.renderAudiences()}
        {this.renderViolations()}
        {this.renderLearnMoreButton()}
        {this.renderRemoderatedMessage()}
        {this.renderViolationAppeal()}
      </div>
    );
  }
}

export default memo(ModerationPanel);
