import React from 'react';

import {
  getSingleAssetPlayerCurrentTime,
  getSingleAssetPlayerDuration,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';

import { intlConnect } from 'utils/connectUtils';

import Marker from 'views/singleAssetStoryEditor/containers/Marker/Marker';

import type { EditionID } from 'types/editions';
import type { State as ReduxState } from 'types/rootState';

type OwnProps = {
  storyId: EditionID;
  isSmall?: boolean;
};

type StateProps = {
  currentTime: number;
  duration: number;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  return {
    currentTime: getSingleAssetPlayerCurrentTime(state)(ownProps.storyId),
    duration: getSingleAssetPlayerDuration(state)(ownProps.storyId),
  };
};

export const VideoPlayHead = (props: Props) => {
  let progress = 0;

  if (props.duration > 0) {
    progress = props.currentTime / props.duration;
  }

  const playHeadStyle = {
    left: `calc(${progress * 100}%)`,
  };

  const smallStyle = {
    ...playHeadStyle,
    top: '26.5px',
    height: '12px',
  };

  return (
    <Marker data-test="VideoPlayHead.Marker" color="blue" style={props.isSmall ? smallStyle : playHeadStyle} overlay />
  );
};

export default intlConnect(mapStateToProps, null)(React.memo(VideoPlayHead));
