import classNames from 'classnames';
import React from 'react';
import reactDOM from 'react-dom';

import {
  buildStatusToStringId,
  isSnapStatusBuilding,
  isSnapStatusIncomplete,
  isSnapStatusNonBlockingWarning,
  isSnapStatusNotPublishable,
  isSnapStatusWithError,
} from 'state/buildStatus/schema/buildStatusHelpers';
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import { generateSubscriptionOverlayIfNeeded } from 'state/editions/actions/editionsActions';
import { findSnapIndexInEdition, isLastNonSubscribeSnapInEdition } from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';
import { isPreviewMuted } from 'state/previews/selectors/previewsSelectors';
import { addMultipleSnapsWithMediaToEdition } from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import Spinner from '../../../common/components/Spinner/Spinner';
import SnapStatusMessageBarConnected from '../../components/SnapStatusMessageBar/SnapStatusMessageBarConnected';
import SnapItemMenu from '../SnapItemMenu/SnapItemMenu';
import StoryTopsnapPreview from '../StoryTopsnapPreview/StoryTopsnapPreview';

import { State } from 'src/types/rootState';
import tooltipStyle from 'styles/tooltip.scss';
import { intlConnect } from 'utils/connectUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import StoryDropzone from 'views/common/containers/StoryDropzone/StoryDropzone';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './PopulatedSnapCarouselItem.scss';

import { Publisher } from 'types/publishers';
import { SnapType, Snap } from 'types/snaps';

const mapStateToProps = (state: State, ownProps: any) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);
  return {
    activeEdition,
    activeEditionId: activeEdition?.id,
    activePublisherId: userSelectors.getActivePublisherId(state),
    buildStatus: buildStatusSelectors.getDiscoverSnapBuildStatus(state)(ownProps.snap.id),
    editionIsReadOnly: editionsSelectors.editionIsReadOnly(state)(activeEdition?.id),
    muted: isPreviewMuted(state),
    isCuratedLayerEnabled:
      featuresSelectors.isCuratedLayerEnabled(state) || featuresSelectors.isAdvancedCurationEnabled(state),
    editionHasSubscribeSnap: editionsSelectors.getEditionHasSubscribeSnap(state),
    publisher: getActivePublisherDetails(state),
  };
};
const mapDispatchToProps = {
  openSnapEditor: publisherStoryEditorModeActions.openSnapEditor,
  uploadMediaToTopsnap: mediaActions.uploadMediaToTopsnap,
  addMultipleSnapsWithMediaToEdition,
  addMediaToStory: mediaLibraryActions.addMediaToStory,
  generateSubscribeOverlay: generateSubscriptionOverlayIfNeeded,
};

type OwnPopulatedSnapCarouselItemProps = {
  isPlaceholder: boolean;
  connectDragSource?: (...args: any[]) => any;
  snap: Snap;
  buildStatus?: any;
  activeEdition: any;
  activeEditionId: number;
  activePublisherId: number;
  openSnapEditor: (...args: any[]) => any;
  isAnySnapBeingDragged?: boolean;
  className?: string;
  editionIsReadOnly: boolean;
  saveSnapDomElement?: (...args: any[]) => any;
  generateSubscribeOverlay: (...args: any[]) => any;
  snapNode: any;
  style?: any;
  addMultipleSnapsWithMediaToEdition: (...args: any[]) => any;
  addMediaToStory: (...args: any[]) => any;
  muted: boolean;
  isCuratedLayerEnabled?: boolean;
  editionHasSubscribeSnap?: boolean;
  publisher: Publisher | null;
};
type PopulatedSnapCarouselItemState = any;
type PopulatedSnapCarouselItemProps = OwnPopulatedSnapCarouselItemProps & typeof PopulatedSnapCarouselItem.defaultProps;
export class PopulatedSnapCarouselItem extends React.Component<
  PopulatedSnapCarouselItemProps,
  PopulatedSnapCarouselItemState
> {
  // In case it is instantiated by the drag layer it can't be a dragsource
  static defaultProps = {
    connectDragSource: (item: any) => item,
  };

  shouldComponentUpdate(nextProps: PopulatedSnapCarouselItemProps, nextState: PopulatedSnapCarouselItemState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  onClick = () => {
    this.props.openSnapEditor({
      publisherId: this.props.activePublisherId,
      editionId: this.props.activeEditionId,
      snapId: this.props.snap.id,
      overwriteHistory: false,
    });
  };

  onDropSnap = (files: any, mediaLibraryItems: any) => {
    // Index of the snap being dropped on
    const droppedSnapIndex = findSnapIndexInEdition(this.props.activeEdition, this.props.snap.id);

    if (mediaLibraryItems) {
      incrementCounterByPublisher(this.props.publisher, GrafanaMetrics.MEDIA_V2, {
        type: 'mediaUploadDrawerMediaItems',
        filter: 'UpdateExistingSnap',
      });
    }

    if (files && files.length > 0) {
      (this.props as any)
        .uploadMediaToTopsnap({
          file: files[0],
          snapId: this.props.snap.id,
          editionId: this.props.activeEditionId,
        })
        .then(() => this.generateSubscribeOverlayIfNeeded());
      if (files.length > 1) {
        this.props.addMultipleSnapsWithMediaToEdition({
          editionId: this.props.activeEditionId,
          // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
          initialIndex: droppedSnapIndex + 1,
          snapType: SnapType.IMAGE,
          files: files.slice(1), // Removes the first element that replaced the existing Snap
        });
      }
    }
    if (mediaLibraryItems) {
      this.props
        .addMediaToStory({
          snaps: mediaLibraryItems,
          snapId: this.props.snap.id,
          editionId: this.props.activeEditionId,
          initialIndex: droppedSnapIndex,
          isCuratedLayerEnabled: this.props.isCuratedLayerEnabled,
        })
        .then(() => this.generateSubscribeOverlayIfNeeded());
    }
  };

  generateSubscribeOverlayIfNeeded() {
    const { generateSubscribeOverlay, activeEditionId: editionId, snap } = this.props;
    generateSubscribeOverlay(snap, editionId);
  }

  saveSnapElement = (element: any) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.saveSnapDomElement(this.props.snapNode, reactDOM.findDOMNode(element));
  };

  shouldRenderWithTooltip = () => {
    return isSnapStatusNotPublishable(this.props.buildStatus) || isSnapStatusNonBlockingWarning(this.props.buildStatus);
  };

  renderPreview() {
    return (
      <StoryTopsnapPreview
        readOnlyMediaUploader
        snap={this.props.snap}
        editionId={this.props.activeEditionId}
        onClick={this.onClick}
        className={style.topsnapPreview}
        isAnySnapBeingDragged={this.props.isAnySnapBeingDragged}
        muted={this.props.muted}
        data-test="storySnapListItem.preview"
      />
    );
  }

  renderBuildStatus() {
    return <SnapStatusMessageBarConnected className={style.buildStatus} snap={this.props.snap} />;
  }

  renderItemMenu() {
    return (
      <SnapItemMenu
        snap={this.props.snap}
        onClickHandler={this.onClick}
        isLastNonSubscribeSnapInEdition={isLastNonSubscribeSnapInEdition(
          this.props.activeEdition,
          this.props.snap.id,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
          this.props.editionHasSubscribeSnap
        )}
      />
    );
  }

  renderSnapDotStatus() {
    const isNonBlocking = isSnapStatusNonBlockingWarning(this.props.buildStatus);
    const isNotPublishable = isSnapStatusNotPublishable(this.props.buildStatus);
    if (!isNotPublishable && !isNonBlocking) {
      return null;
    }

    const isBuilding = isSnapStatusBuilding(this.props.buildStatus);
    let isIncomplete = isSnapStatusIncomplete(this.props.buildStatus);
    const hasError = isSnapStatusWithError(this.props.buildStatus);
    if (!isBuilding && !hasError && !isIncomplete) {
      isIncomplete = isNonBlocking;
    }
    function renderIcon() {
      if (isBuilding) {
        return <Spinner loading />;
      }
      if (hasError) {
        return <DotStatus status={DotStatusState.ERROR} />;
      }
      if (isIncomplete) {
        return <DotStatus status={DotStatusState.INCOMPLETE} />;
      }

      return null;
    }
    return <div className={classNames(style.dotStatus, { [style.dotStatusSpinner]: isBuilding })}>{renderIcon()}</div>;
  }

  rightClickHandler(event: any) {
    event.preventDefault();
  }

  renderSnapComponent() {
    const { isPlaceholder } = this.props;
    return (
      <div className={style.snapContainer} onContextMenu={this.rightClickHandler}>
        {this.renderSnapDotStatus()}
        <div className={this.props.className} style={this.props.style} data-test="populatedSnapCarouselItem">
          {isPlaceholder || this.renderPreview()}
          {isPlaceholder || this.renderBuildStatus()}
          {isPlaceholder || this.renderItemMenu()}
        </div>
      </div>
    );
  }

  renderSnap() {
    if (this.props.isAnySnapBeingDragged) {
      return (
        <div className={style.snapDropzone} ref={this.saveSnapElement}>
          <div className={style.snapComponent}>{this.renderSnapComponent()}</div>
        </div>
      );
    }
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    const snapIndex = findSnapIndexInEdition(this.props.activeEdition, this.props.snap.id) + 1;
    return (
      <div className={style.snapDropzone} ref={this.saveSnapElement}>
        <StoryDropzone
          title={getMessageFromId('drag-and-drop-snap-replace-media-subtitle', { snapIndex })}
          className={style.snapStoryDropzone}
          onDrop={this.onDropSnap}
          showChildrenBorder
          allowMediaLibraryItems
          isSubscribeSnap={isSubscribeSnap(this.props.snap)}
        >
          <div className={style.snapComponent}>{this.renderSnapComponent()}</div>
        </StoryDropzone>
      </div>
    );
  }

  renderTooltip = () => {
    if (this.shouldRenderWithTooltip()) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
      return <>{getMessageFromId(buildStatusToStringId(this.props.buildStatus))}</>;
    }
    return null;
  };

  render() {
    if (this.shouldRenderWithTooltip()) {
      return this.props.connectDragSource(
        <div>
          <SDSTooltip
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            placement={TooltipPosition.TOP}
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            title={this.renderTooltip()}
            id="snap-status"
            overlayClassName={classNames(tooltipStyle.tooltip, style.tooltip)}
          >
            {this.renderSnap()}
          </SDSTooltip>
        </div>
      );
    }
    return this.props.connectDragSource(this.renderSnap());
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(PopulatedSnapCarouselItem);
