import classNames from 'classnames';
import React from 'react';

import * as userActions from 'state/user/actions/userActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { intlConnect } from 'utils/connectUtils';

import ListItemWithToggle from 'views/common/components/ListItem/ListItemWithToggle';
import SnapPopoverButtonRowStyle from 'views/publisherStoryEditor/styles/SnapPopoverButtonRow.scss';
import { SnapPopoverConfig, SnapPopoverRowIds } from 'views/publisherStoryEditor/utils/SnapPopoverRowConfig';

import { State } from 'types/rootState';

const mapStateToProps = (state: State) => ({
  isShowTilesEnabled: userSelectors.getShowTilesEnabled(state),
});

const mapDispatchToProps = {
  setShowTiles: userActions.setShowTiles,
};

type ShowTilesToggleProps = {
  isShowTilesEnabled: boolean;
  setShowTiles: (...args: any[]) => any;
  className?: string;
};

export class ShowTilesToggle extends React.Component<ShowTilesToggleProps> {
  updateShowTilesStyle() {
    return classNames(SnapPopoverConfig[SnapPopoverRowIds.SHOW_TILES].className, {
      [SnapPopoverButtonRowStyle.disabled]: false,
    });
  }

  updateShowTilesPreference = (value: any) => {
    this.props.setShowTiles(value);
  };

  render() {
    const { isShowTilesEnabled } = this.props;
    return (
      <ListItemWithToggle
        {...SnapPopoverConfig[SnapPopoverRowIds.SHOW_TILES]}
        data-test="storyBar.showTiles.switch"
        value={isShowTilesEnabled}
        onChange={this.updateShowTilesPreference}
        className={this.updateShowTilesStyle()}
      />
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(ShowTilesToggle);
