import React from 'react';
import { FormattedMessage } from 'react-intl';

export const title = (
  <FormattedMessage
    id="Subtitles.manage.title"
    defaultMessage="Review your subtitles"
    description="Title for the prompt asking user to manager or review subtitles"
  />
);

export const description = (
  <FormattedMessage
    id="Subtitles.manage.description"
    defaultMessage="Increase engagement by reviewing and correcting subtitles before publishing"
    description="Description for the prompt asking user to manager or review subtitles"
  />
);

export const buttonManageSubtitles = (
  <FormattedMessage
    id="Subtitles.manage.button.manage"
    defaultMessage="Manage Subtitles"
    description="Link to manage subtitles"
  />
);

export const buttonReviewSubtitles = (
  <FormattedMessage
    id="Subtitles.manage.button.review"
    defaultMessage="Review Subtitles"
    description="Link to review subtitles"
  />
);
