import classNames from 'classnames';
import log from 'loglevel';
import moment from 'moment-timezone';
import React from 'react';

import * as videoLibrarySelectors from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { PT_TIME_ZONE } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import style from './VideoDetails.scss';
import videoDetailsConfig from './videoDetailsConfig';

require('moment-duration-format'); // eslint-disable-line

const mapStateToProps = (state: State, props: any) => {
  const video = props.video || videoLibrarySelectors.getVideoResultById(state)(props.assetId);
  return {
    video,
  };
};

type OwnProps = {
  className?: string;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps & typeof VideoDetails.defaultProps;

export class VideoDetails extends React.Component<Props> {
  static defaultProps = {
    evenSize: false,
    fieldConfigs: videoDetailsConfig,
  };

  static mapStateToProps = mapStateToProps;

  renderFields = (fieldValues: any) => ({ key, message }: any) => {
    return fieldValues[key] ? (
      <div className={style.field} key={key}>
        <div className={style.fieldKey}>{message}</div>
        <div className={style.fieldValue} data-test={`videoDetails.fileName.mainDiv.${key}`}>
          {fieldValues[key]}
        </div>
      </div>
    ) : null;
  };

  render() {
    if (!this.props.video) {
      log.warn('No video is loaded');
      return null;
    }

    const { id, name: filename, createdAt, duration } = this.props.video;

    const uploaded = createdAt && moment(createdAt, moment.ISO_8601).tz(PT_TIME_ZONE).format('LL');
    const length = duration && moment.duration(duration).format('hh:mm:ss');

    const fieldValues = {
      id,
      filename,
      uploaded,
      length,
    };

    const rootClass = classNames(
      {
        [style.fields]: true,
        [style.evenSize]: this.props.evenSize,
      },
      this.props.className
    );

    return <div className={rootClass}>{this.props.fieldConfigs.map(this.renderFields(fieldValues))}</div>;
  }
}

export default intlConnect(mapStateToProps, null)(VideoDetails);
