import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { help } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './TagLabel.scss';

import type { BuildStatusType } from 'types/build';
import { ExtendedIncompleteStatus } from 'types/build';

type OwnProps = {
  buildStatus?: BuildStatusType | null;
};
export class TagLabel extends React.Component<OwnProps> {
  generateTagTooltip = () => {
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        id="tag-tooltip-no-link"
        title={
          <>
            <HelpCenterLink
              data-test="EditorPropertyPanels.tagLabel"
              destination={HelpCenterDestination.CONTENT_TAGGING}
            />
            <div className={style.withMargins}>
              <FormattedMessage
                id="tag-tooltip-text-no-link"
                description="Summary of tagging"
                defaultMessage="Content Tags help us optimise your content"
              />
            </div>
          </>
        }
      >
        <Icon inlineIcon={help} className={style.infoIcon} />
      </SDSTooltip>
    );
  };

  render() {
    const { buildStatus } = this.props;
    if (get(buildStatus, 'extendedStatus') !== ExtendedIncompleteStatus.MISSING_TAGS) {
      return (
        <div data-test="topsnapPanel.tagsLabel" className={style.tagsTitleContainer}>
          {getMessageFromId('snap-tags-label')}
          {this.generateTagTooltip()}
        </div>
      );
    }
    return (
      <div className={(style as any).mediaLabelContainer}>
        <DotStatus status={DotStatusState.INCOMPLETE} />
        {getMessageFromId('snap-tags-label')}
        {this.generateTagTooltip()}
      </div>
    );
  }
}
export default TagLabel;
