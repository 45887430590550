// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { RemoteWeb } from 'state/remoteWeb/stages/remoteWebStageConfig';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { UrlType } from 'config/constants';
import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isValidAffiliateUrl, isValidUri } from 'utils/uriUtils';

import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSSwitch from 'views/common/components/SDSSwitch/SDSSwitch';

import { RichSnapComponentId } from 'types/components';
import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';

const EDITING_RESTRICTED_URLS = ['https://snapchat.turbovote.org'];
const TURBO_VOTE_URL = 'https://snapchat.turbovote.org/?r=2020election_';
export const REFERRER_FORWARDING_URL = 'https://webattachments.snapchat.com/public/referrer/index.html#';
const WHITELISTABLE_COUNTRY_CODES = ['US'];
const SNAPCHAT_PREFIXED_URLS = [REFERRER_FORWARDING_URL];
const URL_VALIDATION_OPTIONS = {
  protocols: ['http', 'https', 'snapchat'],
  require_tld: false,
};

function stripSnapchatPrefix(url: any) {
  if (!url) {
    return url;
  }
  for (let i = 0; i < SNAPCHAT_PREFIXED_URLS.length; i++) {
    const urlPrefix = SNAPCHAT_PREFIXED_URLS[i];
    if (url.indexOf(urlPrefix) === 0) {
      return url.replace(urlPrefix, '');
    }
  }
  return url;
}

type ExternalProps = {
  component: {
    componentId: RichSnapComponentId;
  };
};

export const mapStateToProps = (state: State, props: ExternalProps) => {
  const snapId = extractSnapIdFromComponentId(props.component.componentId);
  const isGeofencedWebviewAttachmentsEnabled = featuresSelectors.isGeofencedWebviewAttachmentsEnabled(state);
  const publisher = publishersSelectors.getActivePublisherDetails(state);
  const remoteWeb: RemoteWeb = stagesSelectors.getData(state)(snapId);
  return {
    snapId,
    isReadOnly: editorSelectors.isReadOnly(state),
    isSaving: editorSelectors.isSaving(state),
    remoteWeb,
    isRemoteWebWithReferrerEnabled: featuresSelectors.isRemoteWebWithReferrerEnabled(state),
    isSuperAdmin: hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    isGeofencedWebviewAttachmentsEnabled,
    publisher,
  };
};
const mapDispatchToProps = {
  updateRemoteWeb: stagesActions.updateProperties,
  saveRemoteWeb: stagesActions.commitData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & ExternalProps & typeof RemoteWebPanel.defaultProps;

type RemoteWebPanelState = any;

export class RemoteWebPanel extends React.PureComponent<Props, RemoteWebPanelState> {
  static defaultProps = {
    isReadOnly: false,
    title: <FormattedMessage id="remote-web-general-panel-title" description="General" defaultMessage="General" />,
    isAutoSave: false,
  };

  static contextTypes = {
    intl: intlShape,
  };

  state: RemoteWebPanelState = {
    validRemoteUrl: true,
  };

  UNSAFE_componentWillMount() {
    const { remoteWeb } = this.props;
    this.setState({
      remoteUrl: stripSnapchatPrefix(remoteWeb && remoteWeb.remoteUrl),
      isRemoteUrlSaving: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { remoteWeb, isGeofencedWebviewAttachmentsEnabled, publisher } = this.props;
    const stateObj: RemoteWebPanelState = {};
    const currentRemoteUrl = stripSnapchatPrefix(remoteWeb && remoteWeb.remoteUrl);
    const nextRemoteUrl = stripSnapchatPrefix(nextProps.remoteWeb && nextProps.remoteWeb.remoteUrl);
    if (currentRemoteUrl !== nextRemoteUrl) {
      stateObj.remoteUrl = nextRemoteUrl;
    }
    if (!nextProps.isSaving) {
      stateObj.isRemoteUrlSaving = false;
    }
    if (isGeofencedWebviewAttachmentsEnabled && nextProps.remoteWeb && !nextProps.remoteWeb.remoteUrl) {
      const remoteUrl = `${TURBO_VOTE_URL}${publisher?.urlSafeFormalName}`;
      this.props.updateRemoteWeb(this._getId(), {
        allowedCountries: WHITELISTABLE_COUNTRY_CODES,
        remoteUrl,
      });
      stateObj.remoteUrl = remoteUrl;
    }
    this.setState(stateObj);
  }

  _getId() {
    const { snapId } = this.props;
    return snapId;
  }

  _handleURLChanged = (event: any) => {
    const remoteUrl = event && event.target && event.target.value;
    // clear error state on edit, blur will reset it.
    this.setState({ remoteUrl, validRemoteUrl: true });
  };

  isValidUrl() {
    if (!this.props.remoteWeb) {
      return false;
    }

    const { urlType } = this.props.remoteWeb;
    const { remoteUrl } = this.state;
    if (!remoteUrl) {
      return true;
    }
    if (!isValidUri(remoteUrl, URL_VALIDATION_OPTIONS)) {
      return false;
    }
    if (urlType === UrlType.AFFILIATE) {
      return isValidAffiliateUrl(remoteUrl);
    }
    return true;
  }

  _handleURLBlurred = () => {
    let { remoteUrl, isRemoteUrlSaving } = this.state;
    remoteUrl = remoteUrl && remoteUrl.trim();

    const validRemoteUrl = this.isValidUrl();
    const isRemoteUrlChanged = !this.props.remoteWeb || remoteUrl !== this.props.remoteWeb.remoteUrl;
    // if actually a valid url store it off
    if (remoteUrl && validRemoteUrl && isRemoteUrlChanged) {
      if (!/^https?:\/\//.test(remoteUrl.toLowerCase()) && !/^snapchat:\/\//.test(remoteUrl.toLowerCase())) {
        remoteUrl = `https://${remoteUrl}`;
      }
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'remote-web-panel-url-changed', {
        source: 'RemoteWebPanel',
        properties: { remoteUrl: '[REDACTED]' },
      });
      this.props.updateRemoteWeb(this._getId(), {
        remoteUrl,
      });
      if (this.props.isAutoSave) {
        isRemoteUrlSaving = true;
      }
      if (this.props.isAutoSave) {
        this.props.saveRemoteWeb(this._getId());
      }
    }
    this.setState({
      validRemoteUrl,
      remoteUrl,
      isRemoteUrlSaving,
    });
  };

  isEditingEnabled() {
    const { remoteUrl } = this.state;
    if (!remoteUrl) {
      return true;
    }
    const isEditingRestrictedUrl = EDITING_RESTRICTED_URLS.some(url => remoteUrl.indexOf(url) === 0);
    return !isEditingRestrictedUrl;
  }

  handleReferrerChanged = (useReferrer: boolean) => {
    this.props.updateRemoteWeb(this._getId(), {
      useReferrer,
    });
  };

  renderFields() {
    const { isReadOnly } = this.props;
    const urlType = this.props.remoteWeb?.urlType || UrlType.STANDARD;
    const { validRemoteUrl, remoteUrl, isRemoteUrlSaving } = this.state;
    let urlValidationError = null;
    if (!validRemoteUrl) {
      urlValidationError =
        urlType === UrlType.AFFILIATE ? (
          <FormattedMessage
            id="validator-url-affiliate"
            description="Message displayed to users when a field is not a correct affiliate url"
            defaultMessage="URL is not in the correct format, expected: https://go.skimresources.com/?id=[ID]X[SUB_ID]&xs=[NONCE]&url=[MERCHANT_LINK]"
          />
        ) : (
          <FormattedMessage
            id="validator-url"
            description="Message displayed to users when a field is not a correct url"
            defaultMessage="URL is not in the correct format."
          />
        );
    }
    return [
      {
        key: 'referrerToggle',
        label: (
          <FormattedMessage
            id="use-snapchat-referrer"
            description="Toggle to send snapchat referrer when loading the page"
            defaultMessage="Use Referrer"
          />
        ),
        predicate: Boolean(this.props.isRemoteWebWithReferrerEnabled) && Boolean(this.props.isSuperAdmin),
        control: (
          <SDSSwitch
            isSmallToggle
            value={this.props.remoteWeb?.useReferrer || false}
            isReadOnly={isReadOnly || !this.isEditingEnabled()}
            onChange={this.handleReferrerChanged}
            data-test="remote-web.useReferrer"
          />
        ),
      },
      {
        key: 'remoteUrl',
        isSaving: isRemoteUrlSaving,
        control: (
          <SDSInput
            labelTitle={getMessageFromId('url-msg')}
            data-test="editor.remoteWebPanel.remoteUrl.input"
            value={remoteUrl}
            disabled={isReadOnly || !this.isEditingEnabled()}
            onChange={this._handleURLChanged}
            onBlur={this._handleURLBlurred}
            placeholder="https://www.yourdomain.com/"
            errorMessage={urlValidationError}
          />
        ),
      },
    ];
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <PropertyPanel
          fields={this.renderFields()}
          title={this.props.title}
          isReadOnly={this.props.isSaving}
          horizontal
          titleCaseLabels
        />
        {this.props.isGeofencedWebviewAttachmentsEnabled ? (
          <FormattedMessage
            id="geofenced-attachments-message"
            description="Notification that attachment is geofenced."
            defaultMessage="This webpage will only be visible to users within the USA"
          />
        ) : null}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(RemoteWebPanel);
