import React, { memo, ReactNode } from 'react';

import { hidden } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSPanel from 'views/common/components/SDSPanel/SDSPanel';

import style from './MediaAnnotationsPanelView.scss';

type Props = {
  header: ReactNode;
  children: ReactNode;
};

function MediaAnnotationsPanelView({ header, children }: Props) {
  return (
    <SDSPanel
      header={
        <div className={style.header}>
          <Icon inlineIcon={hidden} /> {header}
        </div>
      }
      className={style.root}
    >
      <div className={style.body}>{children}</div>
    </SDSPanel>
  );
}

export default memo(MediaAnnotationsPanelView);
