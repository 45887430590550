import classNames from 'classnames';
import React from 'react';
import type { ReactNode, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import style from '../styles/SnapPopoverButtonRow.scss';

import { SpeedBumpInputText } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import StoryStateIcon, { StoryIcon } from 'views/common/components/StoryStateIcon/StoryStateIcon';

import archiveIcon from '../icons/archive.svg';
import crossIcon from '../icons/cross.svg';
import downloadIcon from '../icons/download.svg';
import pencilIcon from '../icons/drawIcon.svg';
import duplicateIcon from '../icons/duplicate.svg';
import gearIcon from '../icons/gear.svg';
import lockIcon from '../icons/lock.svg';
import rocket from '../icons/rocket.svg';
import manageIcon from '../icons/share.svg';
import trashIcon from '../icons/trash.svg';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/magic_wand.svg.inline' o... Remove this comment to see the full error message
import magicWand from 'icons/magic_wand.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/snapcode.svg.inline' or ... Remove this comment to see the full error message
import snapcodeIcon from 'icons/snapcode.svg.inline';

export enum SnapPopoverRowIds {
  AD_CONTROL = 'adControl',
  AD_TAKEOVER = 'adTakeover',
  ADS_DISABLED = 'adsDisabled',
  AGE_GATE = 'ageGate',
  COPY_SNAP_ID = 'copySnapId',
  COPY_TO = 'copyTo',
  COPY_TO_STORY = 'copyToStory',
  DEBUG_SNAP = 'debugSnap',
  DEBUG_STORY = 'debugStory',
  SPONSORED = 'Sponsored',
  MAGIC_SEARCH_SNAP = 'magicSearchSnap',
  MAGIC_SEARCH_STORY = 'magicSearchStory',
  STORY_DOWNLOAD_TOPSNAPS = 'downloadAssets',
  STORY_DOWNLOAD_VIDEO = 'downloadVideo',
  DELETE = 'deleteSnap',
  DELETE_STORY = 'deleteStory',
  LOCK_LIVE_EDITION = 'lockLiveEdition',
  MAKE_STORY_AVAILABLE = 'makeStoryAvailable',
  MAKE_STORY_UNAVAILABLE = 'makeStoryUnavailable',
  MANAGE_SNAPCODES = 'manageSnapcodes',
  PRIVATE_ARCHIVE = 'hideStory',
  PRIVATE_ARCHIVE_LOCK = 'privateArchiveLock',
  PUBLIC_ARCHIVE_STORY = 'publicArchiveStory',
  REBUILD_SNAP = 'rebuildSnap',
  RENAME_STORY = 'renameStory',
  SHAREABLE = 'shareable',
  SHARING = 'sharing',
  SHOW_TILES = 'snap-show-tiles',
  SINGLE_ASSET_EDITOR_DEBUG = 'singleAssetEditorDebug',
  TAKE_DOWN_LIVE_EDITION = 'takeDownLiveEdition',
  UNLOCK_LIVE_EDITION = 'unlockLiveEdition',
}

export const liveChangesNote = (
  <FormattedMessage
    id="live-edition-lock-alert-part-2"
    description="Text informing user that live changes can take several minutes to appear"
    defaultMessage="Note: Live changes take several minutes to appear in-app."
  />
);

export const liveChangesNotePlus = (
  <FormattedMessage
    id="live-edition-lock-alert-plus"
    description="Text informing user about live changes"
    defaultMessage="Breaking News updates should include new Snaps with new content. Story Revisions are for minor edits. Live updates take several minutes to appear in-app." /* eslint max-len: ["error", 300] */
  />
);

interface SnapPopoverRowType {
  text: ReactNode;
  icon?: string;
  iconImg?: ReactElement<any>;
  className?: string;
  'data-test'?: string;
}

type SnapPopoverConfigType = {
  [key in SnapPopoverRowIds]: SnapPopoverRowType;
};

export const SnapPopoverConfig: SnapPopoverConfigType = {
  [SnapPopoverRowIds.MANAGE_SNAPCODES]: {
    text: (
      <FormattedMessage
        id="snap-manage-snapcodes"
        description="Manage the snapcode for an individual snap"
        defaultMessage="Manage Snapcodes"
      />
    ),
    icon: manageIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.ShowSnapcodeItem',
  },
  [SnapPopoverRowIds.COPY_TO_STORY]: {
    text: (
      <FormattedMessage
        id="story-copy-to"
        description="Copy story to another publisher"
        defaultMessage="Copy Story to..."
      />
    ),
    icon: duplicateIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.CopyMenuItem',
  },
  [SnapPopoverRowIds.COPY_TO]: {
    text: (
      <FormattedMessage id="snap-copy-to" description="Copy snap to another story" defaultMessage="Copy Snap to..." />
    ),
    icon: duplicateIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.CopyTo',
  },
  [SnapPopoverRowIds.DELETE]: {
    text: (
      <FormattedMessage
        id="snap-delete"
        description="Deletes the selected Snap from the Story"
        defaultMessage="Delete Snap"
      />
    ),
    icon: trashIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.Delete',
  },
  [SnapPopoverRowIds.AD_CONTROL]: {
    text: (
      <FormattedMessage
        id="snap-adcontrol"
        description="Whether the ad controls should be shown"
        defaultMessage="View Ad Controls"
      />
    ),
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.AdControl',
  },
  [SnapPopoverRowIds.AD_TAKEOVER]: {
    text: (
      <FormattedMessage
        id="snap-ad-takeover"
        description="Whether the story is overriding the optional ads with fixed ad slots"
        defaultMessage="Ad Takeover"
      />
    ),
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.AdControl',
  },
  [SnapPopoverRowIds.ADS_DISABLED]: {
    text: (
      <FormattedMessage
        id="story-ads-disabled"
        description="If we want to disable ads on the story"
        defaultMessage="Disable advertising"
      />
    ),
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.AdsDisabled',
  },
  [SnapPopoverRowIds.SPONSORED]: {
    text: (
      <FormattedMessage
        id="sponsored"
        description="Menu item that shows popup, where the user can add 'sponsored' flag for a story that includes product placement"
        defaultMessage="Sponsored"
      />
    ),
    className: classNames(style.row, style.squarePng),
    icon: rocket,
    'data-test': 'storyEditor.StoryOptionsPopover.Sponsored',
  },
  [SnapPopoverRowIds.SHOW_TILES]: {
    text: <FormattedMessage id="snap-show-tiles" description="Show Tiles" defaultMessage="Show Tiles" />,
    className: style.row,
    'data-test': 'storyBar.showTiles.switch',
  },
  [SnapPopoverRowIds.UNLOCK_LIVE_EDITION]: {
    text: (
      <FormattedMessage
        id="snap-unlock-live-edition"
        description="Unlock to live edit"
        defaultMessage="Unlock to Live Edit"
      />
    ),
    icon: lockIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.UnlockLiveEdition',
  },
  [SnapPopoverRowIds.LOCK_LIVE_EDITION]: {
    text: (
      <FormattedMessage
        id="snap-lock-live-edition"
        description="Lock and save changes"
        defaultMessage="Lock and save changes"
      />
    ),
    icon: lockIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.LockLiveEdition',
  },
  [SnapPopoverRowIds.TAKE_DOWN_LIVE_EDITION]: {
    text: (
      <FormattedMessage
        id="snap-take-down-live-edition"
        description="Take down live Story"
        defaultMessage="Take down Live Story"
      />
    ),
    icon: crossIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.TakeDownLiveEdition',
  },
  [SnapPopoverRowIds.SHAREABLE]: {
    text: (
      <FormattedMessage
        id="snap-shareable-toggle"
        description="Label for the toggle that enabled or disables sharing"
        defaultMessage="Shareable"
      />
    ),
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.Shareable',
  },
  [SnapPopoverRowIds.PUBLIC_ARCHIVE_STORY]: {
    text: (
      <FormattedMessage
        id="public-archive-story-menu-item"
        description="Menu item for the user to make a story available"
        defaultMessage="Make available"
      />
    ),
    icon: archiveIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.ArchiveMenuItem',
  },
  [SnapPopoverRowIds.PRIVATE_ARCHIVE]: {
    text: (
      <FormattedMessage
        id="private-archive-menu-item"
        description="Menu item for the user to make a story unavailable"
        defaultMessage="Make unavailable"
      />
    ),
    icon: archiveIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.HideMenuItem',
  },
  [SnapPopoverRowIds.PRIVATE_ARCHIVE_LOCK]: {
    text: getMessageFromId('private-archive-lock-menu-item'),
    icon: lockIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.PrivateArchiveLockMenuItem',
  },
  [SnapPopoverRowIds.DELETE_STORY]: {
    text: (
      <FormattedMessage
        id="delete-story-menu-item"
        description="Menu item for delete a story"
        defaultMessage="Delete Story"
      />
    ),
    icon: trashIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.DeleteStory',
  },
  [SnapPopoverRowIds.AGE_GATE]: {
    text: (
      <FormattedMessage
        id="snap-set-edition-age-gate"
        description="Sensitive content flag"
        defaultMessage="Sensitive Content"
      />
    ),
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.AgeGateItem',
  },
  [SnapPopoverRowIds.SINGLE_ASSET_EDITOR_DEBUG]: {
    text: (
      <FormattedMessage
        id="single-asset-editor-debug-menu-item"
        description="Menu item for Single Asset Editor debug mode"
        defaultMessage="Single Asset Debug"
      />
    ),
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.SingleAssetEditorDebug',
  },
  [SnapPopoverRowIds.DEBUG_STORY]: {
    text: (
      <FormattedMessage
        id="debug-story-menu-item"
        description="Menu item for an admin to debug a story"
        defaultMessage="Debug Story"
      />
    ),
    icon: gearIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.DebugStoryMenuItem',
  },
  [SnapPopoverRowIds.MAGIC_SEARCH_STORY]: {
    text: (
      <FormattedMessage
        id="magic-search-story-menu-item"
        description="Menu item for an admin to magic search a story"
        defaultMessage="Magic Search"
      />
    ),
    iconImg: <InlineSVG src={magicWand} />,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.magicSearchStoryMenuItem',
  },
  [SnapPopoverRowIds.STORY_DOWNLOAD_TOPSNAPS]: {
    text: (
      <FormattedMessage
        id="debug-dowload-story-assets-menu-item"
        description="Menu item to download all top snap assets"
        defaultMessage="Download Topsnaps"
      />
    ),
    icon: downloadIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.DownloadTopsnaps',
  },
  [SnapPopoverRowIds.STORY_DOWNLOAD_VIDEO]: {
    text: (
      <FormattedMessage
        id="debug-dowload-video-menu-item"
        description="Menu item to download the video of single asset story"
        defaultMessage="Download Video"
      />
    ),
    icon: downloadIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.DownloadVideo',
  },
  [SnapPopoverRowIds.DEBUG_SNAP]: {
    text: (
      <FormattedMessage
        id="debug-snap-snap-menu-item"
        description="Menu item for showing the snap debug page"
        defaultMessage="Debug Snap"
      />
    ),
    icon: gearIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.DebugSnap',
  },
  [SnapPopoverRowIds.MAGIC_SEARCH_SNAP]: {
    text: (
      <FormattedMessage
        id="magic-search-snap-snap-menu-item"
        description="Menu item for an admin to magic search a snap"
        defaultMessage="Magic Search"
      />
    ),
    iconImg: <InlineSVG src={magicWand} />,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.magicSearchSnapMenuItem',
  },
  [SnapPopoverRowIds.REBUILD_SNAP]: {
    text: (
      <FormattedMessage
        id="rebuild-snap-menu-item"
        description="Menu item for rebuilding a snap"
        defaultMessage="Rebuild Snap"
      />
    ),
    icon: gearIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.RebuildSnap',
  },
  [SnapPopoverRowIds.RENAME_STORY]: {
    text: (
      <FormattedMessage
        id="rename-story-menu-item"
        description="Menu item for renaming the story"
        defaultMessage="Rename Story"
      />
    ),
    icon: pencilIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.RenameMenuItem',
  },
  [SnapPopoverRowIds.COPY_SNAP_ID]: {
    text: (
      <FormattedMessage
        id="copy-snap-id-menu-item"
        description="Menu item for copying the snap id to clipboard"
        defaultMessage="Copy Snap Id"
      />
    ),
    icon: gearIcon,
    className: style.row,
    'data-test': 'storyEditor.StoryOptionsPopover.CopySnapId',
  },
  [SnapPopoverRowIds.MAKE_STORY_AVAILABLE]: {
    text: (
      <FormattedMessage
        id="make-story-available-menu-item"
        description="Menu item for making a story available"
        defaultMessage="Make Available"
      />
    ),
    iconImg: <StoryStateIcon icon={StoryIcon.AVAILABLE} />,
    className: classNames(style.row, style.squarePng),
    'data-test': 'storyEditor.StoryOptionsPopover.MakeStoryAvailable',
  },
  [SnapPopoverRowIds.MAKE_STORY_UNAVAILABLE]: {
    text: (
      <FormattedMessage
        id="make-story-unavailable-menu-item"
        description="Menu item for making a story unavailable"
        defaultMessage="Make Unavailable"
      />
    ),
    iconImg: <StoryStateIcon icon={StoryIcon.UNAVAILABLE} />,
    className: classNames(style.row, style.squarePng),
    'data-test': 'storyEditor.StoryOptionsPopover.MakeStoryUnavailable',
  },
  [SnapPopoverRowIds.SHARING]: {
    text: (
      <FormattedMessage
        id="snapcode-sharing-menu-item"
        description="Menu item for showing the story Snapcode"
        defaultMessage="Sharing"
      />
    ),
    iconImg: <InlineSVG src={snapcodeIcon} />,
    className: classNames(style.row),
    'data-test': 'storyEditor.StoryOptionsPopover.SharingItem',
  },
};

export const SnapPopoverModalConfig = {
  UnlockLiveEdition: {
    text: {
      title: '',
      paragraph1: (
        <FormattedMessage
          id="live-edition-unlock-alert-para-1"
          description="Text informing user that we recommend live editing is only used rarely"
          defaultMessage="We recommend that you use live editing rarely as it may impact your channel's performance
        and quality. Snapchatters who have already viewed this story won't be notified of your changes."
        />
      ),
      paragraph2: (
        <FormattedMessage
          id="live-edition-unlock-alert-para-2"
          description="Text informing user that live editing may not take effect immediately"
          defaultMessage="Note: live changes may not take effect immediately."
        />
      ),
    },
    id: 'unlock-live-edition-config',
  },
  UnlockLiveEditionWithPendingPublish: {
    text: {
      title: (
        <FormattedMessage
          id="live-edition-pending-unlock-alert-title"
          description="Ask for confirmation when unlocking a live edition before previous changed applied"
          defaultMessage="Previous update is still processing"
        />
      ),
      paragraph1: (
        <FormattedMessage
          id="live-edition-pending-unlock-alert-para-1"
          description="Text informing user that he's going to cancel the previous changes that are still not applied"
          defaultMessage="Your last update has not yet been processed. Editing your story now will cancel the previous update. Do you wish to proceed?"
        />
      ),
      paragraph2: (
        <FormattedMessage
          id="live-edition-pending-unlock-alert-para-2"
          description="Text informing user that live editing may not take effect immediately"
          defaultMessage="Note: All changes take ~2 mins to process."
        />
      ),
    },
    id: 'unlock-pending-live-edition-config',
  },
  LockLiveEdition: {
    text: {
      title: (
        <FormattedMessage
          id="live-edition-lock-alert-title"
          description="Ask for confirmation when locking a live edition after making edits"
          defaultMessage="Are you sure?"
        />
      ),
      paragraph1: (
        <FormattedMessage
          id="live-edition-lock-alert-para-1"
          description="Text informing user that they are about to lock an edited live edition"
          defaultMessage="You are about to republish this Story with all of your edits. Snapchatters who have already
        viewed this Story won't be notified of your changes."
        />
      ),
      paragraph2: (
        <FormattedMessage
          id="live-edition-lock-alert-para-2"
          description="Text informing user that live changes can take several minutes to appear"
          defaultMessage="Note: live changes take several minutes to appear in-app."
        />
      ),
    },
    id: 'lock-live-edition-config',
  },
  IsThisNewsUpdate: {
    text: {
      title: (
        <FormattedMessage
          id="news-update-alert-title"
          description="Checking with user if update contains news update"
          defaultMessage="What type of update is this?"
        />
      ),
      paragraph1: null,
      paragraph2: liveChangesNotePlus,
    },
    id: 'is-news-update',
  },
  LockNewsUpdate: {
    text: {
      title: (
        <FormattedMessage
          id="live-edition-lock-alert-title"
          description="Ask for confirmation when locking a live edition after making edits"
          defaultMessage="Are you sure?"
        />
      ),
      paragraph1: (
        <FormattedMessage
          id="live-news-lock-alert-para-1"
          description="Text informing user that they are about to lock live story containing news"
          defaultMessage="You are about to republish this Story with a news update and any edits you may have made."
        />
      ),
      paragraph2: liveChangesNote,
    },
    id: 'lock-live-edition-config',
  },
  TakeDownLiveEdition: {
    text: {
      title: (
        <FormattedMessage
          id="take-down-live-edition-alert-title"
          description="Ask for confirmation when taking down a live story"
          defaultMessage="Are you sure you want to take your live Story down?"
        />
      ),
      paragraph1: (
        <FormattedMessage
          id="take-down-live-edition-alert-para-1"
          description="Text informing user that this will remove their live story"
          defaultMessage="This will remove your current live Story from Discover."
        />
      ),
      paragraph2: (
        <FormattedMessage
          id="take-down-live-edition-alert-para-2"
          description="Text informing user that they must type words in the field below to take down their story"
          defaultMessage="To enable, type {takeDownText} in the field below:"
          values={{
            takeDownText: SpeedBumpInputText.TAKE_DOWN,
          }}
        />
      ),
    },
    id: 'take-down-live-edition-config',
  },
  Archive: {
    text: {
      title: (
        <FormattedMessage
          id="archive-confirmation-message"
          description="Modal message for confirming that the user wants to archive a story"
          defaultMessage="Are you sure you want to archive this Story?"
        />
      ),
      confirmText: (
        <FormattedMessage
          id="archive-confirm-button-label"
          description="Text indicating to user to confirm he wants to archive a story"
          defaultMessage="Archive"
        />
      ),
      cancelText: (
        <FormattedMessage
          id="archive-cancel-button-label"
          description="Text indicating the user want to cancel archiving a story"
          defaultMessage="Never mind"
        />
      ),
    },
  },
  DeleteStory: {
    text: {
      title: (
        <FormattedMessage
          id="delete-story-confirmation-message"
          description="Modal message for confirming that the story will be deleted"
          defaultMessage="Deleting this draft cannot be undone."
        />
      ),
      confirmText: (
        <FormattedMessage
          id="delete-story-confirm-button-label"
          description="Text indicating to user to confirm he wants to delete a story"
          defaultMessage="Delete"
        />
      ),
      cancelText: (
        <FormattedMessage
          id="delete-story-cancel-button-label"
          description="Text in the button for user to cancel deleting a story"
          defaultMessage="Cancel"
        />
      ),
    },
  },
  AgeGate: {
    text: {
      title: (
        <FormattedMessage
          id="age-gate-confirmation-message"
          description="Modal message for confirming that the user wants to enable age gating"
          defaultMessage="With Sensitive Content selected, users under the age of 18 will not see this story."
        />
      ),
      confirmText: (
        <FormattedMessage
          id="age-gate-confirm-button-label"
          description="Text indicating to user to confirm he wants to enable age gating"
          defaultMessage="Confirm"
        />
      ),
      cancelText: (
        <FormattedMessage
          id="age-gate-cancel-button-label"
          description="Text indicating the user want to cancel enabling age gating"
          defaultMessage="Cancel"
        />
      ),
    },
  },
};
