import React from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from 'state/modals/actions/modalsActions';

import { subtitles } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { ModalType } from 'utils/modalConfig';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';

type Props = {
  showModal: typeof showModal;
  disabled?: boolean;
  editionId: EditionID;
  publisherId: PublisherID;
};

const mapDispatchToProps = {
  showModal,
};

export class StorySubtitleButton extends React.PureComponent<Props> {
  handleShowSubtitleModal = () => {
    this.props.showModal(ModalType.MANAGE_SUBTITLES, 'StorySubtitleButton', {
      editionId: this.props.editionId,
      publisherId: this.props.publisherId,
    });
  };

  render() {
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={
          <FormattedMessage
            id="manage-subtitles"
            description="Tooltip for the button to manage subitles"
            defaultMessage="Manage subtitles"
          />
        }
      >
        <SDSButton
          disabled={this.props.disabled}
          type={ButtonType.WHITE_ON_GREY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={subtitles}
          onClick={this.handleShowSubtitleModal}
          data-test="story.subtitle.button"
        />
      </SDSTooltip>
    );
  }
}

export default intlConnect(() => ({}), mapDispatchToProps)(StorySubtitleButton);
