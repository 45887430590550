import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { campaign } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './MockAd.scss';

type OwnProps = {
  hideAd: (a?: Event | null) => void;
};

function MockAd(props: OwnProps) {
  const { hideAd } = props;
  useEffect(() => {
    const timerId = setTimeout(() => {
      hideAd();
    }, 5000);

    return () => {
      clearTimeout(timerId);
    };
  }, [hideAd]);

  return (
    <div data-test="MockAd.ad" className={style.root}>
      <div />
      <Icon inlineIcon={campaign} />
      <FormattedMessage
        id="mock-ad-message"
        description="Message shown on mock ad"
        defaultMessage="Ad will play here"
      />
      <SDSButton type={ButtonType.SECONDARY} onClick={props.hideAd} data-test="MockAd.skipButton">
        <FormattedMessage id="mock-ad-skip-button-text" description="Mock ad skip button text" defaultMessage="Skip" />
      </SDSButton>
    </div>
  );
}

export default MockAd;
