import type { Enum } from 'utils/enum';

export const TextValidationResult = {
  VALID: 'VALID',
  INVALID: 'INVALID',
};

export type TextValidationResultEnum = Enum<typeof TextValidationResult>;

export type ValidateTextResponse = {
  result: TextValidationResultEnum;
  reasons: string[] | undefined | null;
};

export type ValidateTextResponses = {
  critical: ValidateTextResponse;
  unhealthy: ValidateTextResponse;
};
