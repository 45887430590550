import classNames from 'classnames';
import { isEqual, noop } from 'lodash';
import log from 'loglevel';
import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
// @ts-expect-error ts-migrate(2724) FIXME: '"video.js"' has no exported member named 'VideoJS... Remove this comment to see the full error message
import type { VideoJSPlayer } from 'video.js';

import { CrossOrigin } from 'config/constants';
import * as gaUtils from 'utils/gaUtils';

type OwnProps = {
  autoplay: boolean;
  className: string;
  createVideoPlayer: typeof videojs;
  controls: boolean;
  'data-test': string;
  isMuted: boolean;
  onPause: () => void;
  onPlay: () => void;
  onTimeUpdate: (currentTime: number, totalDuration: number) => void;
  poster?: string;
  src: string;
  subtitles: string[];
};

type Props = OwnProps & typeof VideoPlayer.defaultProps;

export class VideoPlayer extends React.Component<Props> {
  player: VideoJSPlayer;

  videoNode: HTMLVideoElement | undefined | null;

  static defaultProps = {
    subtitles: [],
    autoplay: true,
    controls: true,
    createVideoPlayer: videojs,
    onTimeUpdate: noop,
    onPlay: noop,
    onPause: noop,
    isMuted: false,
  };

  componentDidMount() {
    const options = {
      controls: this.props.controls,
      src: this.props.src,
      type: 'video/mp4',
      poster: this.props.poster,
      autoplay: this.props.autoplay,
      preload: 'auto',
      children: {
        mediaLoader: {},
        posterImage: {},
        textTrackDisplay: {},
        loadingSpinner: {},
        controlBar: { fullscreenToggle: false },
        errorDisplay: {},
        textTrackSettings: {},
      },
    };

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ controls: boolean; src: string... Remove this comment to see the full error message
    this.player = this.props.createVideoPlayer(this.videoNode, options);
    this.player.on('ready', this.onPlayerReady);

    this.props.subtitles.forEach(subtitle => this.player.addRemoteTextTrack(subtitle, true));

    this.player.on('error', this.errorHandler);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.poster !== nextProps.poster) {
      this.player.poster(nextProps.poster);
    }

    if (this.props.src !== nextProps.src) {
      this.player.src({ src: nextProps.src, type: 'video/mp4' });
    }

    // Using isEqual because the subtitles don't respect the identity equality
    if (!isEqual(this.props.subtitles, nextProps.subtitles)) {
      this.props.subtitles.forEach(subtitle => this.player.removeRemoteTextTrack(subtitle));
      nextProps.subtitles.forEach(subtitle => this.player.addRemoteTextTrack(subtitle, true));
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  componentWillUnmount() {
    if (this.player) {
      this.props.subtitles.forEach(subtitle => this.player.removeRemoteTextTrack(subtitle));
      this.player.off('error', this.errorHandler);
      this.player.off('ready', this.onPlayerReady);
      this.player.off('timeupdate', this.onTimeUpdate);
      this.player.off('loadedmetadata', this.onLoadedMetadata);
      this.player.off('play', this.onPlay);
      this.player.off('pause', this.onPause);
      this.player.dispose();
    }
  }

  onPlayerReady = () => {
    if (this.player && this.props.src) {
      this.player.src({ src: this.props.src, type: 'video/mp4' });
      this.player.on('timeupdate', this.onTimeUpdate);
      this.player.on('loadedmetadata', this.onLoadedMetadata);
      this.player.on('play', this.onPlay);
      this.player.on('pause', this.onPause);
    }
  };

  onPlay = () => {
    this.props.onPlay();
  };

  onPause = () => {
    this.props.onPause();
  };

  play = () => {
    this.player.play();
  };

  pause = () => {
    this.player.pause();
  };

  setCurrentTime = (newCurrentTime: number) => {
    this.player.currentTime(newCurrentTime);
    this.props.onTimeUpdate(this.player.currentTime(), this.player.duration());
  };

  onLoadedMetadata = () => {
    this.props.onTimeUpdate(this.player.currentTime(), this.player.duration());
  };

  onTimeUpdate = () => {
    this.props.onTimeUpdate(this.player.currentTime(), this.player.duration());
  };

  errorHandler = () => {
    const error = this.player.error() || new Error('Unknown video error');

    log.error(error); // Will be sent to backend
    gaUtils.logGAEvent(gaUtils.GAQoSMetrics.MEDIA, 'longform-playback-error', {
      code: error.code ? error.code : undefined,
      message: error.message,
      status: error.status ? error.status : undefined,
      src: this.props.src,
    });
  };

  asignNode = (node?: HTMLVideoElement | null) => {
    this.videoNode = node;
  };

  render() {
    const { className } = this.props;

    return (
      <div data-vjs-player data-test={this.props['data-test']}>
        <video
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          ref={this.asignNode}
          className={classNames(className, 'video-js')}
          muted={this.props.isMuted}
        />
      </div>
    );
  }
}

export default VideoPlayer;
