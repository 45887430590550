import React, { useState, useEffect, useRef, useCallback } from 'react';
import type { ReactNode, ChangeEvent } from 'react';

import SDSInput from 'views/common/components/SDSInput/SDSInput';
import type { InputTypeEnum } from 'views/common/components/SDSInput/SDSInput';

import useDebounce from './useDebounce';

type Props = {
  labelTitle?: ReactNode;
  'data-test': string | undefined | null;
  value: number | undefined | null | string | undefined | null;
  disabled: boolean;
  onChange: (data: string | number) => void;
  errorMessage?: ReactNode;
  type?: InputTypeEnum;
};

export const CameoInput = ({
  labelTitle,
  'data-test': dataTest,
  value,
  disabled,
  onChange,
  errorMessage,
  type,
}: Props) => {
  const [info, setInfo] = useState({ value: value || '' });
  const debouncedValue = useDebounce(info.value, 1000);
  const didMount = useRef(false);
  const savedValue = useRef(value);
  useEffect(() => {
    if (didMount.current) {
      if (`${savedValue.current || ''}` !== `${debouncedValue || ''}`) {
        savedValue.current = debouncedValue;
        onChange(debouncedValue);
      }
    } else {
      didMount.current = true;
    }
  }, [debouncedValue, onChange]);

  useEffect(() => {
    if (`${savedValue.current || ''}` !== `${value || ''}`) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | null | undefined' is not a... Remove this comment to see the full error message
      setInfo({ value });
    }
  }, [value, setInfo]);

  const handleValueChange = useCallback(
    (event: ChangeEvent<EventTarget>) => {
      const {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
        target: { value: userInfo },
      } = event;

      setInfo({ value: userInfo || '' });
    },
    [setInfo]
  );

  return (
    <SDSInput
      labelTitle={labelTitle}
      type={type}
      data-test={dataTest || ''}
      value={info.value}
      disabled={disabled}
      onChange={handleValueChange}
      errorMessage={errorMessage}
    />
  );
};

export default CameoInput;
