// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'quer... Remove this comment to see the full error message
import { parse } from 'query-string';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'vali... Remove this comment to see the full error message
import { isUUID } from 'validator';

export const uuidLengthWithDashes = 36;
export const uuidLengthNoDashes = 32;

// Adds dashes to a uuid that has had them stripped out (use case is a uuid passed as a url parameter)
export const addDashesToUuid = (uuid: string) => {
  const dash = '-';
  const slice1 = uuid.slice(0, 8) + dash + uuid.slice(8);
  const slice2 = slice1.slice(0, 13) + dash + slice1.slice(13);
  const slice3 = slice2.slice(0, 18) + dash + slice2.slice(18);
  const fullUuid = slice3.slice(0, 23) + dash + slice3.slice(23);

  return isUUID(fullUuid) ? fullUuid : '';
};

export const removeDashesFromUuid = (uuid: string) => {
  return uuid.replace(/-/g, '');
};

// Strips uuid parameter from a url
export const stripUuidFromUrl = (url: string) => {
  const parsedUrl = parse(url);

  return parsedUrl.uuid || '';
};

export const isUuidV4 = (uuid: string) => isUUID(uuid, 4);
