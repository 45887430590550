// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
// discover-cms/no-snapnet
import { PropertyPanel } from '@snapchat/snapnet';
import classNames from 'classnames';
import { debounce, get } from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import validator from 'validator';

import * as assetSelectors from 'state/asset/selectors/assetSelectors';
import { isSnapMediaWithError } from 'state/buildStatus/schema/buildStatusHelpers';
import { shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import {
  getActiveTopsnap,
  getActiveEditionId,
  isLocked,
  isReadOnly,
  isSaving,
  getActiveBottomsnap,
} from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import {
  topsnapHasOverlay,
  topsnapHasVideoMedia,
  isSubscribeSnap,
  isCuratedSnap,
} from 'state/snaps/schema/snapEntityHelpers';
import { getVideoAssetIdBySnapId } from 'state/snaps/selectors/snapsSelectors';
import { getSubtitlesByVideoAssetId } from 'state/subtitles/selectors/subtitlesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import {
  EMPTY_ARRAY,
  VideoMode,
  UploadPurpose,
  DropzoneType,
  ErrorType,
  CropPosition,
  ShareOption,
  ExternalSnapSource,
  scancodeBaseUrlWithUuid,
  ZENDESK_BASE_URL,
} from 'config/constants';
import { help, pencil } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { getMessageBody, getMessageFromId } from 'utils/intlMessages/intlMessages';
import { removeDashesFromUuid, stripUuidFromUrl, addDashesToUuid } from 'utils/uuidUtils';

import { AlertType } from 'views/common/components/AlertBox/AlertBox';
import { CallToActionConfigs } from 'views/common/components/CallToActionControl/CallToActionConfig';
import CallToActionControl from 'views/common/components/CallToActionControl/CallToActionControl';
import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import Icon from 'views/common/components/Icon/Icon';
import LineView from 'views/common/components/LineView/LineView';
import ListItemWithToggle from 'views/common/components/ListItem/ListItemWithToggle';
import MediaAnnotations from 'views/common/components/MediaAnnotations/MediaAnnotations';
import MediaButtons from 'views/common/components/MediaButtons/MediaButtons';
import RadioButtons from 'views/common/components/RadioButtons/RadioButtons';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import MediaSelector from 'views/editor/components/MediaSelector/MediaSelector';
import PanelErrorsField from 'views/editor/components/PanelErrorsField/PanelErrorsField';
import SnapTagInput from 'views/editor/components/SnapTagInput/SnapTagInput';
import TooltipMessageType from 'views/editor/components/TooltipMessages/TooltipMessageType';
import * as TooltipMessages from 'views/editor/components/TooltipMessages/TooltipMessages';
import ContextInfoBox from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/ContextInfoBox/ContextInfoBox';
import CurationPanel from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/CurationPanel/CurationPanel';
import ModerationPanel, {
  ModerationItem,
} from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/ModerationPanel/ModerationPanel';
import NotesControl from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/NotesControl/NotesControl';
import PanelTitle from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/PanelTitle/PanelTitle';
import TagLabel from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/TagLabel/TagLabel';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import cropPositionIconBottomSelected from './icons/crop-bottom-selected.svg';
import cropPositionIconMiddleSelected from './icons/crop-middle-selected.svg';
import cropPositionIconTopSelected from './icons/crop-top-selected.svg';

import CuratedPublicSnapButton from './CuratedPublicSnapButton';
import style from './TopsnapPanel.scss';

import { BuildStatusType, ExtendedIncompleteStatus } from 'types/build';
import { Claim } from 'types/permissions';
import { BottomSnap, SnapType, SubscribeSnap } from 'types/snaps';

const MAX_SAVE_RETRIES = 4;
export const messages = defineMessages({
  snapPublisherCreateConfirmation: {
    id: 'snap-publisher-create-confirmation-text',
    description: 'Confirmation presented to user to assign Snap Publisher video to their top snap',
    defaultMessage: 'Welcome back! Your Snap Publisher media will be added.',
  },
});

export const snapProperties = (
  <FormattedMessage
    id="snap-properties"
    defaultMessage="Snap Properties"
    description="Title for the snap properties panel"
  />
);

export const mapStateToProps = (state: State) => {
  const topsnap = getActiveTopsnap(state);
  const activeComponent = componentsSelectors.getActiveComponent(state);
  const publisher = publishersSelectors.getActivePublisherDetails(state);
  const lensScancodeId = get(topsnap, ['lens', 'scancodeId']) || null;
  let activeUploadCount = 0;
  if (activeComponent) {
    activeUploadCount = mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
      (activeComponent as any).componentId,
      UploadPurpose.TOP_SNAP
    );
  }
  const storyId = getActiveEditionId(state);
  const topsnapId = get(topsnap, 'id', null);
  const isUploadingMedia = activeUploadCount > 0;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | null' is not assignable... Remove this comment to see the full error message
  const videoAssetId = getVideoAssetIdBySnapId(state)(topsnapId);
  const assetUserAttatchments = videoAssetId
    ? assetSelectors.getAssetUserAttatchmentsFromId(state)(videoAssetId)
    : null;
  const isAdminUser = userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER);
  return {
    publisherId: get(publisher, 'id'),
    buildStatus: topsnapId ? buildStatusSelectors.getDiscoverSnapBuildStatus(state)(topsnapId) : null,
    creativeId: assetSelectors.getCreativeIdFromSnap(state)(topsnap),
    editionId: storyId,
    bottomsnap: getActiveBottomsnap(state),
    callToActionEnabled: featuresSelectors.isCallToActionEnabled(state),
    isAdvancedCurationEnabled: featuresSelectors.isAdvancedCurationEnabled(state),
    isBitmojiContentEnabled: featuresSelectors.isBitmojiContentEnabled(state),
    isCameoContentEnabled: featuresSelectors.isCameoContentEnabled(state),
    isCurationSnapDownloader:
      userSelectors.isCurationSnapDownloader(state) && featuresSelectors.isAdvancedCurationEnabled(state),
    canViewAnnotations: isAdminUser && featuresSelectors.isContentAnnotationsEnabled(state),
    isLocked: !topsnapId || isLocked(state)({ snapId: topsnapId }),
    isReadOnly: isReadOnly(state),
    isSaving: isSaving(state),
    isTopsnapDownloader: userSelectors.hasClaimForActivePublisher(state, Claim.TOPSNAP_DOWNLOADER),
    publisherAgeGateEnabled: publishersSelectors.isPublisherAgeGateEnabled(state),
    isTopsnapShareableToggler: userSelectors.hasClaimForActivePublisher(state, Claim.TOPSNAP_SHAREABLE_TOGGLER),
    isTopsnapVideoLoopEditor: userSelectors.hasClaimForActivePublisher(state, Claim.TOPSNAP_VIDEO_LOOP_EDITOR),
    isTopSnapWebViewEnabled: featuresSelectors.isTopSnapWebViewEnabled(state),
    isUploadingMedia,
    loopVideoEnabled: featuresSelectors.isTopsnapLoopVideoEnabled(state),
    primaryLanguage: publishersSelectors.getActivePublisherPrimaryLanguage(state),
    defaultSubtitlesLanguage: publishersSelectors.getActivePublisherDefaultSubtitlesLanguage(state),
    subtitlesAssetIds: videoAssetId
      ? getSubtitlesByVideoAssetId(state)(videoAssetId).map((entry: any) => entry.assetId)
      : [],
    topsnap,
    lensScancodeId,
    assetUserAttatchments,
  };
};

const mapDispatchToProps = {
  downloadTopsnapAssets: publisherStoryEditorActions.downloadTopsnapAssets,
  setEditorConfigProperties: editorActions.setEditorConfigProperties,
  setSnapPropertiesAndSave: snapsActions.setSnapPropertiesAndSave,
};

type OwnTopsnapPanelProps = {
  isLocked: boolean;
  publisherId?: number;
  buildStatus?: BuildStatusType | null;
  creativeId?: string;
  editionId?: number;
  bottomsnap: BottomSnap | null;
  callToActionEnabled?: boolean;
  canViewAnnotations: boolean;
  isAdvancedCurationEnabled?: boolean;
  isCurationSnapDownloader?: boolean;
  isReadOnly?: boolean;
  isSaving?: boolean;
  isTopsnapDownloader?: boolean;
  isTopsnapShareableToggler?: boolean;
  publisherAgeGateEnabled: boolean;
  isTopsnapVideoLoopEditor?: boolean;
  isUploadingMedia?: boolean;
  loopVideoEnabled?: boolean;
  openSnapPublisher?: (...args: any[]) => any;
  primaryLanguage?: string;
  defaultSubtitlesLanguage?: string;
  query?: any;
  subtitlesAssetIds?: Array<string>;
  topsnap?: any;
  lensScancodeId?: string;
  assetUserAttatchments?: any[];
  setSnapPropertiesAndSave: typeof snapsActions.setSnapPropertiesAndSave;
};

type TopsnapPanelState = {
  currentLensUrl: string | null;
  localSubscriptionText?: string;
};

type TopsnapPanelProps = OwnTopsnapPanelProps & typeof TopsnapPanel.defaultProps;

export class TopsnapPanel extends React.Component<TopsnapPanelProps, TopsnapPanelState> {
  static defaultProps = {
    isUploadingMedia: false,
    isReadOnly: false,
  };

  static contextTypes = {
    intl: intlShape,
  };

  lensUrl(strippedUuid: any) {
    return scancodeBaseUrlWithUuid(strippedUuid);
  }

  constructor(props: TopsnapPanelProps) {
    super(props);
    this.state = {
      currentLensUrl: null,
    };
  }

  async componentDidUpdate(prevProps: TopsnapPanelProps) {
    if (this.props.lensScancodeId && prevProps.lensScancodeId !== this.props.lensScancodeId) {
      const strippedUuid = removeDashesFromUuid(this.props.lensScancodeId);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentLensUrl: this.lensUrl(strippedUuid) });
    }

    const hasTopsnap = !prevProps.topsnap && this.props.topsnap;
    if (hasTopsnap) {
      await this.generateOverlayIfNotExist();
    }
  }

  async generateOverlayIfNotExist() {
    const { topsnap, isReadOnly: isStoryReadOnly } = this.props;
    const hasMedia = Boolean(topsnap?.videoAssetId) || Boolean(topsnap?.imageAssetId);
    if (!isStoryReadOnly && hasMedia && topsnap && isSubscribeSnap(topsnap) && !topsnapHasOverlay(topsnap)) {
      this.handleSubscribeSnapOnSave();
    }

    const subscriptionText = await this.getSubscriptionTextByLocale(this.props.topsnap);
    this.setState({ localSubscriptionText: subscriptionText });
  }

  async componentDidMount() {
    if (this.props.lensScancodeId) {
      const strippedUuid = removeDashesFromUuid(this.props.lensScancodeId);
      this.setState({ currentLensUrl: this.lensUrl(strippedUuid) });
    }
    await this.generateOverlayIfNotExist();
  }

  getSubscriptionTextByLocale = async (subscribeSnap: SubscribeSnap) => {
    return (
      subscribeSnap?.subscriptionText ||
      getMessageBody(getMessageFromId('end-snap-default-label-2'), {}, this.props.primaryLanguage)
    );
  };

  handleCallToActionOnChange = (event: any) => {
    const callToAction = event && event.target && event.target.value;
    const properties = { callToAction };
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'topsnap-change-call-to-action', {
      source: 'TopsnapPanel',
      properties,
    });
    this.props.setSnapPropertiesAndSave({ snapId: this.props.topsnap.id }, properties);
  };

  getMediaSelector = () => {
    const { topsnap, editionId, subtitlesAssetIds, creativeId, buildStatus, isAdvancedCurationEnabled } = this.props;
    const topsnapHasImportableMedia = !!(
      get(topsnap, 'creativeId') ||
      Boolean(creativeId) ||
      get(topsnap, 'imageAssetId') ||
      get(topsnap, 'videoAssetId')
    );
    const createButtonLabel = topsnapHasImportableMedia ? getMessageFromId('edit-button-label') : null;
    return (
      <MediaSelector
        createButtonLabel={createButtonLabel}
        dropzoneType={DropzoneType.TOPSNAP_CLICK_UPLOAD}
        editionId={editionId}
        handleMediaCreate={this.handleOpenSnapPublisher}
        handleMediaDelete={this.handleMediaDelete}
        handleMediaDownload={this.handleMediaDownload}
        handleMediaTrim={this.handleOpenSnapPublisherTrimMode}
        hasOverlay={topsnapHasOverlay(topsnap)}
        hasUploadError={isSnapMediaWithError(buildStatus)}
        isReadOnly={this.props.isReadOnly}
        mediaDownloadEnabled={this.canShowMediaDownload()}
        skipMediaValidation={isAdvancedCurationEnabled}
        snap={topsnap}
        subtitlesMediaIds={subtitlesAssetIds}
        trimMediaEnabled={Boolean(get(topsnap, 'videoAssetId'))}
        uploadPurpose={UploadPurpose.TOP_SNAP}
      />
    );
  };

  canToggleVideoLooping() {
    const { loopVideoEnabled, isTopsnapVideoLoopEditor } = this.props;
    return Boolean(loopVideoEnabled || isTopsnapVideoLoopEditor);
  }

  canShowMediaDownload = () => {
    const { isTopsnapDownloader, topsnap, isUploadingMedia, isCurationSnapDownloader } = this.props;
    const hasAssetId = Boolean(topsnap.imageAssetId) || Boolean(topsnap.videoAssetId);
    return (
      !isUploadingMedia && // cannot download when user is in the process of uploading the media
      (!isCuratedSnap(topsnap) || isCurationSnapDownloader) && // cannot download curated snaps except for advanced curation publishers
      isTopsnapDownloader && // user must have the correct permissions to download topsnaps
      hasAssetId
    ); // an asset must exist for the user to download
  };

  handleMediaDownload = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'downloadTopsnapAssets' does not exist on... Remove this comment to see the full error message
    const { downloadTopsnapAssets, editionId, topsnap } = this.props;
    downloadTopsnapAssets({ editionId, snapId: topsnap.id, includeOverlay: true });
  };

  handleOpenSnapPublisherTrimMode = () => {
    if (this.props.openSnapPublisher) {
      this.props.openSnapPublisher({ trimMode: true });
    }
  };

  handleOpenSnapPublisher = () => {
    const { openSnapPublisher } = this.props;
    if (openSnapPublisher) {
      openSnapPublisher();
    }
  };

  handleLoopVideoOnChange = (value: any) => {
    const thisVideoMode = value ? VideoMode.LOOPING : VideoMode.ONCE;
    const properties = { videoMode: thisVideoMode };
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'topsnap-loop-video', {
      source: 'TopsnapPanel',
      properties,
    });
    (this.props as any).setSnapPropertiesAndSave(
      { snapId: this.props.topsnap.id },
      properties,
      ErrorType.SET_SNAP_PROPERTY
    );
  };

  handleMediaDelete = (diffObject: any) => {
    (this.props as any).setSnapPropertiesAndSave({ snapId: this.props.topsnap.id }, diffObject, ErrorType.DELETE);
  };

  handleSubscribeSnapChange = (e: any) => {
    const subscriptionText = e.target.value;
    this.setState({ localSubscriptionText: subscriptionText });
  };

  handleSubscribeSnapOnSave = debounce((retryNo = 1) => {
    if (this.props.isLocked && retryNo <= MAX_SAVE_RETRIES) {
      this.handleSubscribeSnapOnSave(retryNo + 1);
      return;
    }
    this.props.setSnapPropertiesAndSave(
      { snapId: this.props.topsnap.id },
      { subscriptionText: this.state.localSubscriptionText }
    );
  }, 500);

  handleSnapShareableToggle = (value: any) => {
    if (!this.props.publisherAgeGateEnabled) {
      const snapShareableToggle = value ? ShareOption.SHARE_LIVE_ARCHIVED : ShareOption.NO_SHARE;
      (this.props as any).setSnapPropertiesAndSave(
        { snapId: this.props.topsnap.id },
        { shareOption: snapShareableToggle },
        ErrorType.SET_SNAP_PROPERTY
      );
    }
  };

  handleCropPositionOnChange = (event: any) => {
    const { name, value } = event.currentTarget;
    const properties = { [name]: value };
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'topsnap-change-crop-position', {
      source: 'TopsnapPanel',
      properties,
    });
    (this.props as any).setSnapPropertiesAndSave({ snapId: this.props.topsnap.id }, properties);
  };

  handleLensOverlayBlur = (event: any) => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'snap-change-topsnap-lens', {
      source: 'TopsnapPanel',
      properties: {
        lens: '[REDACTED]',
      },
    });
    const { value } = event.currentTarget;
    const undashedUuid = stripUuidFromUrl(value);
    const lensScancodeId = addDashesToUuid(undashedUuid);
    if (value && this.validLensUrl(this.state.currentLensUrl) && value !== '') {
      const newLens = {
        lens: {
          scancodeId: lensScancodeId,
        },
      };
      (this.props as any).setSnapPropertiesAndSave(
        {
          snapId: this.props.topsnap.id,
        },
        newLens
      );
    }
  };

  handleLensOverlayChange = (event: any) => {
    const { target } = event;
    const { currentLensUrl } = this.state;
    if (target.value !== currentLensUrl) {
      if (target.value === '' && this.props.lensScancodeId) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        this.removeLensOverlay();
      } else {
        this.setState({ currentLensUrl: target.value });
      }
    }
  };

  removeLensOverlay = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    const deletedLens = {
      lens: {
        scancodeId: '',
      },
    };
    (this.props as any)
      .setSnapPropertiesAndSave(
        {
          snapId: this.props.topsnap.id,
        },
        deletedLens
      )
      .then(() => {
        this.setState({ currentLensUrl: '' });
      });
  };

  validLensUrl(input: any) {
    if (!input) {
      return false;
    }
    const whitespaceTrimmedValue = validator.trim(input);
    const isURL = validator.isURL(whitespaceTrimmedValue);
    const strippedUuid = stripUuidFromUrl(whitespaceTrimmedValue);
    if (input !== '' && (!isURL || strippedUuid === '')) {
      return false;
    }
    return true;
  }

  renderLensOverlayUrlErrorMessage = () => {
    if (!this.state.currentLensUrl) {
      return null;
    }
    const valid = this.validLensUrl(this.state.currentLensUrl);
    if (!valid) {
      return (
        <FormattedMessage
          id="lens-overlay-url-warning"
          description="Warning that the provided lens URL is invalid"
          defaultMessage={
            'Lens URL is invalid: it must be a Snapcode URL and contain a "uuid" parameter. Please try another URL.'
          }
        />
      );
    }
    return null;
  };

  handleCropPositionMouseEnter = (name: any, value: any) => {
    (this.props as any).setEditorConfigProperties({
      hoverCropPosition: value,
    });
  };

  handleCropPositionMouseLeave = (name: any) => {
    (this.props as any).setEditorConfigProperties({
      hoverCropPosition: null,
    });
  };

  handleSubtitlesUploadComplete = () => {
    grapheneUtils.incrementCounter('subtitles', {
      click: 'topsnap_uploaded',
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      language: this.props.defaultSubtitlesLanguage,
    });
  };

  transformToWebviewClick = () => {
    (this.props as any).setSnapPropertiesAndSave(
      {
        snapId: this.props.topsnap.id,
      },
      {
        type: SnapType.REMOTE_WEB,
      }
    );
  };

  transformToBitmojiWebviewClick = () => {
    (this.props as any).setSnapPropertiesAndSave(
      {
        snapId: this.props.topsnap.id,
      },
      {
        type: SnapType.BITMOJI_REMOTE_WEB,
      }
    );
  };

  transformToBitmojiRemoteVideoClick = () => {
    (this.props as any).setSnapPropertiesAndSave(
      {
        snapId: this.props.topsnap.id,
      },
      {
        type: SnapType.BITMOJI_REMOTE_VIDEO,
      }
    );
  };

  transformToCameoClick = () => {
    (this.props as any).setSnapPropertiesAndSave(
      {
        snapId: this.props.topsnap.id,
      },
      {
        type: SnapType.CAMEOS_CONTENT,
        cameoSnapModel: {
          cameoId: 0,
          revision: 0,
          previewUrl: 'test.html',
        },
      }
    );
  };

  renderSourceAction = () => {
    return (
      <div className={style.curatedPublicSnapButtonContainer}>
        <CuratedPublicSnapButton snap={this.props.topsnap} />
      </div>
    );
  };

  renderMediaLabel = () => {
    const { buildStatus } = this.props;
    return (
      <div className={style.mediaLabelContainer}>
        {isSnapMediaWithError(buildStatus) && (
          <div className={style.dotStatusIcon}>
            <DotStatus status={DotStatusState.ERROR} />
          </div>
        )}
        {getMessageFromId('media-file-label')}
        <SDSTooltip
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'appTitle' does not exist on type '{ inva... Remove this comment to see the full error message
          position={TooltipPosition.TOP}
          id="media-file-label-tooltip"
          title={TooltipMessages.getTooltipMessage(TooltipMessageType.TOPSNAP_MEDIA_FILE)}
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    );
  };

  renderSourceLabel = () => {
    return (
      <div className={style.mediaLabelContainer} data-test="topsnapPanel.sourceLabel">
        {getMessageFromId('source')}
      </div>
    );
  };

  renderFields = () => {
    const { topsnap, buildStatus, bottomsnap, callToActionEnabled } = this.props;
    const hasExternalSnapDeeplink = !!this.props.topsnap?.originalSnapId;
    const showModeration = shouldShowModeration(buildStatus?.audience);
    const zendeskHelpURL = `${ZENDESK_BASE_URL}/hc/en-us/articles/5191519553683-Content-Guidelines`;
    return [
      {
        predicate: Boolean((this.props as any).curationComment) && (this.props as any).curationComment.length > 0,
        key: 'story-curation',
        control: <CurationPanel curationComment={(this.props as any).curationComment} />,
      },
      {
        predicate: Boolean(showModeration),
        key: 'snap-moderation',
        control: (
          <ModerationPanel
            moderationItem={ModerationItem.SNAP}
            audienceList={get(buildStatus, 'audience', EMPTY_ARRAY)}
            violationList={buildStatus?.moderationViolations ?? EMPTY_ARRAY}
          />
        ),
      },
      {
        predicate: Boolean(topsnap && callToActionEnabled && !!bottomsnap),
        label: (
          <FormattedMessage
            id="top-panel-call-to-action"
            description="Call to Action field form control"
            defaultMessage="Call to Action"
          />
        ),
        key: 'callToAction',
        tooltip: TooltipMessages.getTooltipMessage(TooltipMessageType.GENERAL_CALL_TO_ACTION),
        control: (
          <CallToActionControl
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            id="call-to-action-input"
            value={topsnap?.callToAction}
            onChange={this.handleCallToActionOnChange}
            options={(bottomsnap && CallToActionConfigs[bottomsnap.type]) || []}
          />
        ),
      },
      {
        predicate: Boolean(showModeration),
        key: 'snap-moderation-divider',
        // @ts-expect-error ts-migrate(2786) FIXME: 'LineView' cannot be used as a JSX component.
        control: <LineView className={style.divider} />,
      },
      {
        predicate: true,
        key: 'snap-properties',
        control: <PanelTitle title={snapProperties} />,
      },
      {
        predicate:
          this.props.buildStatus &&
          this.props.buildStatus.extendedStatus === ExtendedIncompleteStatus.SENSITIVE_CONTENT,
        key: 'snap-sensitive-content',
        control: (
          <PanelErrorsField
            primaryText={
              <FormattedMessage
                id="top-snap-panel-sensitive-content-primary"
                description="Primary text for sensitive content flagged snaps"
                defaultMessage={'Flagged for Potentially Sensitive Content'}
              />
            }
            secondaryText={
              <FormattedMessage
                id="top-snap-panel-sensitive-content-secondary"
                description="Secondary text for sensitive content flagged snaps"
                defaultMessage={'Publishing is enabled. Check guidelines for info.'}
              />
            }
            warningLevel={AlertType.INFO}
            url={zendeskHelpURL}
          />
        ),
      },
      {
        predicate: Boolean(topsnapHasVideoMedia(topsnap) && this.canToggleVideoLooping()),
        key: 'loopVideoField',
        control: (
          <ListItemWithToggle
            className={style.listItemWithToggle}
            text={<FormattedMessage id="loop-video" description="Loop video" defaultMessage="Loop video" />}
            value={topsnap.videoMode === VideoMode.LOOPING}
            data-test="richSnapEditor.topsnapEditor.videoMode"
            onChange={this.handleLoopVideoOnChange}
          />
        ),
      },
      {
        predicate: true,
        label: this.renderMediaLabel(),
        key: 'mediaFile',
        control: this.getMediaSelector(),
      },
      {
        predicate: Boolean(hasExternalSnapDeeplink),
        label: this.renderSourceLabel(),
        key: 'mediaSource',
        control: this.renderSourceAction(),
      },
      {
        predicate: Boolean(topsnapHasVideoMedia(topsnap) && !topsnapHasOverlay(topsnap) && !isSubscribeSnap(topsnap)),
        label: <FormattedMessage id="overlay" description="overlay" defaultMessage="Overlay" />,
        key: 'imageOverlayUpload',
        control: (
          <MediaUploader
            snapId={topsnap.id}
            editionId={this.props.editionId}
            dropzoneType={DropzoneType.NEW_OVERLAY}
            purpose={UploadPurpose.OVERLAY_MEDIA}
            isReadOnly={this.props.isReadOnly}
            data-test="richSnapEditor.topsnapPanel.overlayUploader"
          />
        ),
      },
      {
        predicate: Boolean(topsnapHasOverlay(topsnap) && !isSubscribeSnap(topsnap)),
        label: <FormattedMessage id="overlay" description="overlay" defaultMessage="Overlay" />,
        key: 'imageOverlayEdit',
        control: (
          <MediaButtons
            snapId={topsnap.id}
            editionId={this.props.editionId}
            isReadOnly={this.props.isReadOnly}
            onChange={this.handleMediaDelete}
            purpose={UploadPurpose.OVERLAY_MEDIA}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            dropzoneType={DropzoneType.REPLACE}
            data-test="richSnapEditor.topsnapPanel.overlay"
            showDeleteButton
          />
        ),
      },
      {
        predicate: true,
        label: (
          <FormattedMessage
            id="crop-position-headline"
            description="Crop position headline"
            defaultMessage="Crop position"
          />
        ),
        key: 'cropPosition',
        control: (
          <RadioButtons
            name="cropPosition"
            value={topsnap.cropPosition}
            onMouseEnter={this.handleCropPositionMouseEnter}
            onMouseLeave={this.handleCropPositionMouseLeave}
            data-test="richSnapEditor.topsnapEditor.cropPosition"
            options={[
              {
                value: CropPosition.TOP,
                iconDefault: cropPositionIconTopSelected,
                iconChecked: cropPositionIconTopSelected,
                iconClassName: style.cropPositionIcon,
              },
              {
                value: CropPosition.MIDDLE,
                iconDefault: cropPositionIconMiddleSelected,
                iconChecked: cropPositionIconMiddleSelected,
                iconClassName: style.cropPositionIcon,
              },
              {
                value: CropPosition.BOTTOM,
                iconDefault: cropPositionIconBottomSelected,
                iconChecked: cropPositionIconBottomSelected,
                iconClassName: style.cropPositionIcon,
              },
            ]}
            onChange={this.handleCropPositionOnChange}
            className={style.cropRadioButtons}
            checkedClassName={style.checkedButton}
            showIconCheckedOnHover
            isReadOnly={this.props.isReadOnly}
          />
        ),
      },
      {
        predicate: true,
        label: <TagLabel buildStatus={this.props.buildStatus} />,
        key: 'tagsInput',
        control: <SnapTagInput snapId={this.props.topsnap.id} disabled={this.props.isReadOnly} />,
      },
      {
        predicate: true,
        key: 'notesInput',
        control: (
          <NotesControl
            isReadOnly={this.props.isReadOnly}
            snapId={this.props.topsnap.id}
            notes={this.props.topsnap.name || ''}
            setSnapPropertiesAndSave={(this.props as any).setSnapPropertiesAndSave}
          />
        ),
      },
      {
        predicate: Boolean(this.props.assetUserAttatchments?.length),
        key: 'assetUserAttatchments',
        control: (
          <div data-test="editor.EditorPropertyPanels.contextInfoBoxContainer" className={style.alertBoxContainer}>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'any[] | undefined' is not assignable to type... Remove this comment to see the full error message */}
            <ContextInfoBox assetUserAttatchments={this.props.assetUserAttatchments} />
          </div>
        ),
      },
      {
        predicate: Boolean(isSubscribeSnap(topsnap) && !!topsnap && topsnap.imageAssetId !== null),
        key: 'subscribeHeadline',
        control: (
          <SDSInput
            labelTitle={
              <FormattedMessage
                id="image-overlay-headline"
                description="Image overlay headline"
                defaultMessage="Headline"
              />
            }
            onBlur={this.handleSubscribeSnapOnSave}
            onPressEnter={this.handleSubscribeSnapOnSave}
            onChange={this.handleSubscribeSnapChange}
            value={this.state.localSubscriptionText}
            disabled={this.props.isLocked}
            data-test="topSnapPanel.subscribeHeadline"
            maxLength={100}
          />
        ),
      },
      {
        predicate: Boolean((this.props as any).isTopSnapWebViewEnabled && !(this.props as any).isBitmojiContentEnabled),
        key: 'make-webpage',
        control: (
          <SDSButton type={ButtonType.SECONDARY} inlineIcon={pencil} onClick={this.transformToWebviewClick}>
            <FormattedMessage
              id="transform-to-webview"
              description="Transform top snap to webpage view"
              defaultMessage="Transform To Webview"
            />
          </SDSButton>
        ),
      },
      {
        predicate: Boolean((this.props as any).isBitmojiContentEnabled),
        key: 'make-bitmoji-webpage',
        control: (
          <SDSButton type={ButtonType.SECONDARY} inlineIcon={pencil} onClick={this.transformToBitmojiWebviewClick}>
            <FormattedMessage
              id="transform-to-bitmoji-webpage"
              description="Transform top snap to bitmoji webpage view"
              defaultMessage="Transform To Bitmoji Webview"
            />
          </SDSButton>
        ),
      },
      {
        predicate: Boolean((this.props as any).isBitmojiContentEnabled),
        key: 'make-remote-video',
        control: (
          <SDSButton type={ButtonType.SECONDARY} inlineIcon={pencil} onClick={this.transformToBitmojiRemoteVideoClick}>
            <FormattedMessage
              id="transform-to-bitmoji-remote-video"
              description="Transform top snap to bitmoji remote video"
              defaultMessage="Transform To Bitmoji Remote Video"
            />
          </SDSButton>
        ),
      },
      {
        predicate: Boolean((this.props as any).isCameoContentEnabled && !topsnap.cameoSnapModel),
        key: 'make-cameo',
        control: (
          <SDSButton type={ButtonType.SECONDARY} inlineIcon={pencil} onClick={this.transformToCameoClick}>
            <FormattedMessage
              id="transform-to-cameo"
              description="Transform snap to Cameo"
              defaultMessage="Transform To Cameo"
            />
          </SDSButton>
        ),
      },
      {
        predicate: Boolean(
          topsnap.externalSnapSource !== ExternalSnapSource.STORY_KIT_OUR_STORIES &&
            this.props.isTopsnapShareableToggler
        ),
        tooltip: null,
        key: 'shareable-snap-toggle',
        isSaving: this.props.isSaving,
        control: (
          <ListItemWithToggle
            className={classNames(style.listItemWithToggle, {
              [style.disabled]: this.props.publisherAgeGateEnabled,
            })}
            text={
              <FormattedMessage
                id="shareable-snap-toggle"
                description="Label for the toggle that enabled or disables sharing"
                defaultMessage="Shareable"
              />
            }
            data-test="richSnapEditor.topsnapEditor.shareableSnapSwitch"
            value={topsnap.shareOption !== ShareOption.NO_SHARE && !this.props.publisherAgeGateEnabled}
            onChange={this.handleSnapShareableToggle}
          />
        ),
      },
      {
        predicate: this.props.canViewAnnotations,
        label: <>Media Annotations</>,
        key: 'media-annotations',
        control: <MediaAnnotations assetId={topsnap?.videoAssetId || topsnap?.imageAssetId} />,
      },
    ];
  };

  render() {
    if (!this.props.topsnap) {
      return null;
    }
    return (
      <div className={style.topsnapPanel}>
        <PropertyPanel
          fields={this.renderFields()}
          isReadOnly={this.props.isReadOnly || this.props.isSaving || false}
          titleCaseLabels
          horizontal
        />
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(TopsnapPanel);
