import classnames from 'classnames';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

import { getEdition } from 'state/editions/actions/getEditionActions';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import * as storySelectors from 'state/editions/selectors/editionsSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { SECONDS } from 'config/constants';
import { State } from 'src/types/rootState';
import tooltipStyle from 'styles/tooltip.scss';
import { stopEventPropagation } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import { formatTimeToTwoMetrics } from 'utils/dateUtils';

import RelativeTime from 'views/common/components/RelativeTime/RelativeTime';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import StoryStateLabel from 'views/publisherStoryEditor/components/StoryStateLabel/StoryStateLabel';

import style from './StoryState.scss';

import { Edition, StoryState as StoryStateEnum } from 'types/editions';

export const TICK = 5000;
const mapStateToProps = (state: State) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);
  return {
    activeEdition,
    hasStoryErrors: activeEdition ? !!storySelectors.getEditionErrorById(state)(activeEdition.id) : false,
  };
};
const mapDispatchToProps = {
  getStory: getEdition,
};
const hideTimeEditionStates = new Set([StoryStateEnum.HIDDEN, StoryStateEnum.NEW, StoryStateEnum.READY]);
// Only renders the state and timer for some states
const labelStates = new Set([
  StoryStateEnum.NEW,
  StoryStateEnum.READY,
  StoryStateEnum.IN_PROGRESS,
  StoryStateEnum.SCHEDULED,
  StoryStateEnum.SCHEDULED_PENDING_APPROVAL,
  StoryStateEnum.PENDING_APPROVAL,
  StoryStateEnum.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL,
  StoryStateEnum.LIVE_EDIT_PENDING_APPROVAL,
  StoryStateEnum.LIVE,
  StoryStateEnum.ARCHIVED,
  StoryStateEnum.HIDDEN,
]);
type StoryStateProps = {
  activeEdition?: Edition;
  getStory: (...args: any[]) => any;
  hasStoryErrors: boolean;
};
type StoryStateState = any;
export class StoryState extends React.Component<StoryStateProps, StoryStateState> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  timerId: any;

  state = {};

  UNSAFE_componentWillMount() {
    this.updateStoryState(this.props.activeEdition);
    this.checkTimers(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!isEqual(this.props.activeEdition, nextProps.activeEdition)) {
      this.updateStoryState(nextProps.activeEdition);
      this.checkTimers(nextProps);
    }
  }

  shouldComponentUpdate(nextProps: StoryStateProps, nextState: StoryStateState) {
    return (nextProps && !isEqual(this.props, nextProps)) || (nextState && !isEqual(this.state, nextState));
  }

  componentWillUnmount() {
    this.resetTimer();
    this.hasViewUnmounted = true;
  }

  getDateTimeValue(timestamp: any) {
    return {
      date: (
        <div className={style.time}>
          <FormattedDate value={timestamp} timeZone="America/New_York" />
        </div>
      ),
      time: (
        <div className={style.time}>
          <FormattedTime
            value={timestamp}
            hour="numeric"
            minute="numeric"
            timeZone="America/New_York"
            timeZoneName="short"
          />
        </div>
      ),
    };
  }

  checkTimers(props: any) {
    // Remove older timers
    this.resetTimer();
    this.handleEditionTransitioningState(props);
  }

  hasViewUnmounted = false;

  resetTimer = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  // Story does not go instantly live. It takes some time for the publish state to change from SCHEDULED to LIVE
  // To show up to date UI, we check every TICK seconds if state has changed
  fetchStoryState = (props: any) => {
    this.updateStoryState(props.activeEdition);
    props.getStory({ editionId: props.activeEdition.id }).then(() => {
      // The view may get unmounted before then() is executed. This leaves the timer orphaned
      if (!this.hasViewUnmounted) {
        this.timerId = this.shouldFetchStoryState(props)
          ? setTimeout(this.fetchStoryState.bind(null, props), TICK)
          : null;
      }
    });
  };

  shouldFetchStoryState = (props: any) => {
    const { activeEdition } = props;
    const isPublishing =
      activeEdition && editionEntityHelpers.isPublishing(activeEdition) && !this.props.hasStoryErrors;
    const isUnpublishing = activeEdition && editionEntityHelpers.isUnpublishing(activeEdition);
    return isPublishing || isUnpublishing;
  };

  updateStoryState(activeEdition: any) {
    if (!activeEdition) {
      return;
    }
    let currentStoryState = activeEdition.state;
    if (editionEntityHelpers.isPublishing(activeEdition)) {
      currentStoryState = StoryStateEnum.PUBLISHING;
    } else if (editionEntityHelpers.isUnpublishing(activeEdition)) {
      currentStoryState = StoryStateEnum.UNPUBLISHING;
    }
    if (currentStoryState !== (this.state as any).storyState) {
      this.setState({ storyState: currentStoryState });
    }
  }

  handleEditionTransitioningState = (props: any) => {
    if (this.shouldFetchStoryState(props || this.props)) {
      this.fetchStoryState(props || this.props);
    }
  };

  renderStartTimeMessage(dateTimeValues: any) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'editionPropType | undefined' is ... Remove this comment to see the full error message
    if (editionEntityHelpers.isPublishing(this.props.activeEdition)) {
      return (
        <FormattedMessage
          id="story-state-is-processing"
          defaultMessage="Story will show in the feed when media has finished processing"
          description="Show the user that the story is processing the media and publishing the story"
          values={dateTimeValues}
        />
      );
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    switch (this.props.activeEdition.state) {
      case StoryStateEnum.LIVE:
      case StoryStateEnum.ARCHIVED:
        return (
          <FormattedMessage
            id="story-state-went-live"
            defaultMessage="Story was made available on {date} at {time}"
            description="Show the user at what time a story was made available"
            values={dateTimeValues}
          />
        );
      case StoryStateEnum.SCHEDULED:
        return (
          <FormattedMessage
            id="story-state-goes-live"
            defaultMessage="Story will become available on {date} at {time}"
            description="Show the user at what time a story will become available"
            values={dateTimeValues}
          />
        );
      default:
        return null;
    }
  }

  renderStoryStateTooltip() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const startDate = Date.parse(this.props.activeEdition.startDate);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const lastUpdated = Date.parse(this.props.activeEdition.lastUpdatedAt);
    return (
      <div className={style.tooltipTitle} data-test="publisherStoryEditory.storyState.tooltip.title">
        {this.renderStartTimeMessage(this.getDateTimeValue(startDate))}
        <FormattedMessage
          id="story-last-updated"
          defaultMessage="Story was last updated on {date} at {time}"
          description="Show the user what was the last time a story was updated"
          values={this.getDateTimeValue(lastUpdated)}
        />
      </div>
    );
  }

  renderRelativeTime() {
    let startTime;
    if (
      (this.state as any).storyState !== StoryStateEnum.PUBLISHING &&
      (this.state as any).storyState !== StoryStateEnum.UNPUBLISHING &&
      (this.state as any).storyState !== StoryStateEnum.SCHEDULED_PENDING_APPROVAL &&
      (this.state as any).storyState !== StoryStateEnum.PENDING_APPROVAL &&
      (this.state as any).storyState !== StoryStateEnum.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL &&
      (this.state as any).storyState !== StoryStateEnum.LIVE_EDIT_PENDING_APPROVAL &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      !hideTimeEditionStates.has(this.props.activeEdition.state) &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.props.activeEdition.startDate
    ) {
      startTime = (
        <RelativeTime
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          baseTime={Date.parse(this.props.activeEdition.startDate)}
          className={style.relativeTime}
          refreshIntervalMs={1 * SECONDS}
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          formatTimeFunc={formatTimeToTwoMetrics}
          notifyWhenSwitchToPositive={this.handleEditionTransitioningState}
        />
      );
    }
    if (!startTime) {
      return null;
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'editionPropType | undefined' is ... Remove this comment to see the full error message
    if (!editionEntityHelpers.isAvailable(this.props.activeEdition) || !this.props.activeEdition.endDate) {
      return startTime;
    }
    const endTime = (
      <RelativeTime
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        baseTime={Date.parse(this.props.activeEdition.endDate)}
        className={style.relativeTime}
        refreshIntervalMs={1 * SECONDS}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        formatTimeFunc={formatTimeToTwoMetrics}
        notifyWhenSwitchToPositive={this.handleEditionTransitioningState}
      />
    );
    return (
      <div className={style.relativeTime}>
        <FormattedMessage
          data-test="state.startEndTimes"
          id="story-available-expiration-date"
          defaultMessage="{timeSinceAvailable}, becomes unavailable in&nbsp;{timeToExpiration}"
          description="what time a story was made available and time to expiration. &nbsp; is a char sequence for space, do not remove"
          values={{ timeSinceAvailable: startTime, timeToExpiration: endTime }}
        />
      </div>
    );
  }

  render() {
    const { activeEdition } = this.props;
    if (!activeEdition) {
      return null;
    }
    if (!labelStates.has(activeEdition.state)) {
      return null;
    }
    const tooltip = this.renderStoryStateTooltip();

    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event?: Event | null | undefined) => void' ... Remove this comment to see the full error message
      <div onClick={stopEventPropagation}>
        <SDSTooltip
          id="story-state"
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.TOP_LEFT}
          title={tooltip}
          overlayClassName={classnames(style.tooltipContainer, tooltipStyle.tooltip)}
        >
          <div className={style.storyState}>
            <StoryStateLabel
              className={style.label}
              storyState={(this.state as any).storyState}
              storyId={activeEdition.id}
            />
            {this.renderRelativeTime()}
          </div>
        </SDSTooltip>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryState);
