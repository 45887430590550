import React from 'react';
import { FormattedMessage } from 'react-intl';

import { updateSingleAssetEditorState } from 'state/editor/actions/editorActions';
import {
  getActiveEditionIsReadOnly,
  shouldUseSingleSnapBuilder,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { replaceStoryMediaPrompt } from 'state/singleAssetStory/actions/singleAssetStoryActions';
import { getActiveTimelineSnap, getTimelineSnaps } from 'state/singleAssetStory/selectors/singleAssetStorySelectors';

import { intlConnect } from 'utils/connectUtils';
import { isWasmEnabled } from 'utils/platform/platformUtils';

import {
  PanelButtonProps,
  PanelSectionProps,
  SingleAssetPanel,
} from 'views/singleAssetStoryEditor/containers/SingleAssetPanel/SingleAssetPanel';
import SingleAssetSnapCardEdit from 'views/singleAssetStoryEditor/containers/SingleAssetSnapCard/SingleAssetSnapCardEdit';

import { EditionID } from 'types/editionID';
import { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';
import { TimelineSnap } from 'types/singleAssetStoryEditor';

type OwnProps = {
  activePublisherId: PublisherID;
  activeEditionId: EditionID;
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    snaps: getTimelineSnaps(state)(ownProps.activeEditionId),
    activeTimelineSnap: getActiveTimelineSnap(state)(ownProps.activeEditionId),
    isActiveEditionReadOnly: getActiveEditionIsReadOnly(state),
    isSingleSnapBuilder: shouldUseSingleSnapBuilder(state)(ownProps.activeEditionId),
    // Do not let users upload media if WASM is not enabled as this skips media validation
    isMediaUploadDisabled: !isWasmEnabled(),
  };
};

const mapDispatchToProps = {
  updateEditorState: updateSingleAssetEditorState,
  replaceStoryMedia: replaceStoryMediaPrompt,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

function getSingleSnapEdit(snaps: TimelineSnap[], activeEditionId: EditionID) {
  return (
    snaps[0] && (
      <SingleAssetSnapCardEdit
        data-test="SingleAssetSnapsPanel.SingleAssetSnapCardEdit"
        snap={snaps[0]}
        storyId={activeEditionId}
      />
    )
  );
}

export function SingleAssetSnapsPanel({
  activePublisherId,
  activeEditionId,
  snaps,
  isActiveEditionReadOnly,
  isMediaUploadDisabled,
  replaceStoryMedia,
}: Props) {
  const replaceMediaButton: PanelButtonProps = {
    disabled: isActiveEditionReadOnly || isMediaUploadDisabled,
    buttonMsg: (
      <FormattedMessage
        id="single-asset-replace-media"
        description="Replace video button for single asset"
        defaultMessage="Replace video file"
      />
    ),
    onClick: () => {
      replaceStoryMedia(activeEditionId, activePublisherId);
    },
    'data-test': 'SingleAssetSnapsPanel.replaceMediaButton',
  };

  const replaceMediaSection: PanelSectionProps = {
    header: <FormattedMessage id="media-header" description="Media panel header" defaultMessage="Media" />,
    description: (
      <FormattedMessage
        id="replace-media-description"
        description="Media replacement description"
        defaultMessage="Replace video"
      />
    ),
    buttonProps: replaceMediaButton,
  };

  // Single Snap section appears in Single Snap stories
  const editSingleSnapSection: PanelSectionProps = {
    customProps: getSingleSnapEdit(snaps, activeEditionId),
  };

  const sections = [replaceMediaSection, editSingleSnapSection];
  return <SingleAssetPanel sections={sections} />;
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SingleAssetSnapsPanel);
