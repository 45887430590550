import invariant from 'invariant';
import { isEmpty } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { loadAssetInfo } from 'state/asset/actions/assetActions';
import { getAssetById } from 'state/asset/selectors/assetSelectors';
import { isGeneratingStorySubtitles } from 'state/buildStatus/schema/buildStatusHelpers';
import { editionScheduleStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import {
  getActiveEditionId,
  getTranscodedMediaIdForStory,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActiveTimelineSnap } from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import * as subtitlesActions from 'state/subtitles/actions/subtitlesActions';
import {
  getSubtitlesTrackContentById,
  getSubtitlesByActiveLanguageAndVideoAssetId,
  getSubtitlesVisibility,
} from 'state/subtitles/selectors/subtitlesSelectors';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import { DropzoneType, UploadPurpose } from 'config/constants';
import type { SubtitleTrack } from 'constants/subtitles';
import { ellipsis } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SubtitlesPreview from 'views/common/components/SubtitlesPreview/SubtitlesPreview';
import type { DisplayRange } from 'views/common/components/SubtitlesPreview/components/SubtitlesBody/SubtitlesBody';
import SubtitlesLanguageDropdownForAsset from 'views/common/components/SubtitlesPreview/components/SubtitlesLanguagesDropdown/SubtitlesLanguageDropdownForAsset';
import SubtitlesOptionsPopover from 'views/publisherStoryEditor/containers/SubtitlesOptionsPopover/SubtitlesOptionsPopover';
import MultiLanguageSubtitlesManager from 'views/subtitles/MultiLanguageSubtitlesManager/MultiLanguageSubtitlesManager';

import style from './SingleAssetSubtitlesPreview.scss';

import type { Asset, AssetID } from 'types/assets';
import type { EditionID } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';
import type { TimelineSnap } from 'types/singleAssetStoryEditor';

type StateProps = {
  hasSubtitles: boolean;
  videoAsset: Asset | undefined | null;
  videoAssetId: AssetID;
  activeTimelineSnap: TimelineSnap | undefined | null;
  activeStoryId: EditionID | undefined | null;
  rawSubtitles: string | undefined | null;
  subtitlesTrack: SubtitleTrack | undefined | null;
  subtitlesPreviewEnabled: boolean;
  isWaitingForSubtitles: boolean;
  isReadOnly: boolean;
};

const singleAssetUploadParams = {
  purpose: UploadPurpose.EDITION_SUBTITLES,
  // uploadMediaAndFinalize requires the componentId to be string, even if it is not used for subtitles,
  componentId: '',
};

const mapStateToProps = (state: State): StateProps => {
  const activeStoryId = getActiveEditionId(state);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const videoAssetId = getTranscodedMediaIdForStory(state)(activeStoryId);
  const videoAsset = videoAssetId && getAssetById(state)(videoAssetId);
  const activeTimelineSnap = activeStoryId && getActiveTimelineSnap(state)(activeStoryId);

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const subtitlesTrack = getSubtitlesByActiveLanguageAndVideoAssetId(state)(videoAssetId);
  const hasSubtitles = Boolean(subtitlesTrack);
  const rawSubtitles = subtitlesTrack && getSubtitlesTrackContentById(state)(subtitlesTrack.assetId);

  const subtitlesPreviewEnabled = getSubtitlesVisibility(state);
  const isReadOnly = editorSelectors.isReadOnly(state);
  const isWaitingForSubtitles = activeStoryId
    ? isGeneratingStorySubtitles(editionScheduleStatus(state)(activeStoryId))
    : false;

  return {
    isReadOnly,
    hasSubtitles,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    videoAssetId,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | Asset | null | undefined' is not assign... Remove this comment to see the full error message
    videoAsset,
    activeTimelineSnap,
    activeStoryId,
    rawSubtitles,
    subtitlesTrack,
    subtitlesPreviewEnabled,
    isWaitingForSubtitles,
  };
};

const mapDispatchToProps = {
  loadVideoAsset: loadAssetInfo,
  deleteSingleAssetStorySubtitles: videoLibraryActions.deleteSingleAssetStorySubtitles,
  setSubtitlesPreview: subtitlesActions.setSubtitlesPreview,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;
type OwnState = {
  displayRange: DisplayRange;
};

export class SingleAssetSubtitlesPreview extends React.PureComponent<Props, OwnState> {
  state = {
    displayRange: {
      startTimeMs: 0,
      endTimeMs: 0,
    },
  };

  componentDidMount() {
    this.fetchAsset();
    this.updateDisplayRange();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.videoAssetId !== this.props.videoAssetId) {
      this.fetchAsset();
    }
    if (prevProps.activeTimelineSnap !== this.props.activeTimelineSnap) {
      this.updateDisplayRange();
    }
  }

  updateDisplayRange() {
    const { activeTimelineSnap } = this.props;
    const startTimeMs = activeTimelineSnap?.startTimeMs || 0;
    const durationMs = activeTimelineSnap?.durationMs || 0;
    const endTimeMs = startTimeMs + durationMs;

    this.setState({
      displayRange: {
        startTimeMs,
        endTimeMs,
      },
    });
  }

  fetchAsset() {
    const { videoAsset, videoAssetId, loadVideoAsset } = this.props;

    if (videoAssetId && isEmpty(videoAsset)) {
      loadVideoAsset(videoAssetId);
    }
  }

  onDelete = () => {
    const { activeStoryId, videoAssetId, subtitlesTrack } = this.props;
    const languageToDelete = subtitlesTrack?.language;
    invariant(languageToDelete, 'Track does not have the language defined.');
    incrementCounter('subtitles', {
      click: 'single_asset_delete',
      language: languageToDelete,
    });
    this.props.deleteSingleAssetStorySubtitles(activeStoryId, videoAssetId, languageToDelete);
  };

  onChangePreview = () => {
    this.props.setSubtitlesPreview(!this.props.subtitlesPreviewEnabled);
  };

  renderSubtitleTrackDropdown = () => {
    return <SubtitlesLanguageDropdownForAsset videoAssetId={this.props.videoAssetId} />;
  };

  renderSubtitlesPopover() {
    if (!this.props.hasSubtitles) {
      return null;
    }

    return (
      <SubtitlesOptionsPopover
        videoAssetId={this.props.videoAssetId}
        subtitlesFileName={`Subtitles_StoryId_${this.props.activeStoryId || 'unknown'}.vtt`}
        rawSubtitles={this.props.rawSubtitles}
        subtitlesUrl={this.props.subtitlesTrack ? this.props.subtitlesTrack.source : ''}
        subtitlesPreviewEnabled={this.props.subtitlesPreviewEnabled}
        onDelete={this.onDelete}
        onChangePreview={this.onChangePreview}
        isReadOnly={this.props.isReadOnly}
        data-test="SingleAssetSubtitlesPreview.subtitlesOptionsPopover"
      >
        <SDSButton
          type={ButtonType.WHITE}
          shape={ButtonShape.CIRCLE}
          inlineIcon={ellipsis}
          data-test="SingleAssetSubtitlesPreview.subtitlesStoryConfigButton"
        />
      </SubtitlesOptionsPopover>
    );
  }

  renderSubtitlesPreview = () => {
    const { activeStoryId, videoAssetId, activeTimelineSnap, isWaitingForSubtitles } = this.props;

    if (!activeTimelineSnap) {
      return (
        <div className={style.message}>
          <FormattedMessage
            id="single-asset-subtitles-no-snap-selected-text"
            description="Text telling the user they do not have a snap selected"
            defaultMessage="Subtitles will be available once the snap has been generated."
            data-test="SingleAssetSubtitlesPreview.noSnapSelectedMessage"
          />
        </div>
      );
    }

    return (
      <SubtitlesPreview
        key={videoAssetId}
        videoAssetId={videoAssetId}
        subtitlesFileName={activeStoryId && `Subtitles_StoryId_${activeStoryId}.vtt`}
        isWaitingForSubtitles={isWaitingForSubtitles}
        displayRange={this.state.displayRange}
        uploadParams={singleAssetUploadParams}
      />
    );
  };

  render() {
    const { videoAssetId, hasSubtitles, isReadOnly, activeStoryId } = this.props;

    if (!videoAssetId || !activeStoryId) {
      return null;
    }

    return (
      <div className={style.rootContainer} data-test="SingleAssetSubtitlesPreview.root">
        <MultiLanguageSubtitlesManager
          purpose={UploadPurpose.EDITION_SUBTITLES}
          dropzoneType={DropzoneType.SINGLE_ASSET_SUBTITLES}
          isReadOnly={isReadOnly}
          dataTestPrefix="SingleAssetSubtitlesPreview"
          hasSubtitles={hasSubtitles}
          showInfo={!hasSubtitles}
          subtitlesDropdown={this.renderSubtitleTrackDropdown()}
          optionsPopover={this.renderSubtitlesPopover()}
          videoAssetId={videoAssetId}
          displayRange={this.state.displayRange}
        />
        {this.renderSubtitlesPreview()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SingleAssetSubtitlesPreview);
