/* global CKEDITOR */
import log from 'loglevel';

export default class ElementCreator {
  _elementConstructor: any;

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
  constructor(elementConstructor = CKEDITOR.dom.element) {
    this._elementConstructor = elementConstructor;
  }

  create(elementType: any, attributes = null) {
    if (typeof elementType !== 'string' || elementType === '') {
      throw new Error('ElementCreator.create() expects an element type');
    }

    try {
      const element = new this._elementConstructor(elementType); // eslint-disable-line new-cap
      if (attributes !== null) {
        element.setAttributes(attributes);
      }
      return element;
    } catch (err) {
      log.error('Error creating element', err);
      return null;
    }
  }

  createAndInsert(editor: any, elementType: any, attributes = null) {
    const element = this.create(elementType, attributes);

    if (element) {
      try {
        editor.insertElement(element);
        editor.fire('actionPerformed', this);
        this._fireRelevantAdditionEvent(editor, elementType, element, attributes);
      } catch (err) {
        log.error('Error inserting element', err);
      }
    }

    return element;
  }

  _fireRelevantAdditionEvent(editor: any, elementType: any, element: any, attributes = null) {
    switch (elementType) {
      case 'img':
        editor.fire('imageAdd', {
          el: element,
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          file: attributes.src,
        });
        break;

      default:
        // Ignore types for which no addition event is necessary
        break;
    }
  }
}
