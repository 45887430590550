import is from 'is_js';
import { get } from 'lodash';
import React from 'react';

import { getCreativeIdFromSnap } from 'state/asset/selectors/assetSelectors';
import { getActiveTopsnap } from 'state/editor/selectors/editorSelectors';
import { isAdvancedCurationEnabled, isCuratedLayerEnabled } from 'state/features/selectors/featuresSelectors';
import { getStoryEditorMode } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { asTopSnap } from 'state/snaps/schema/snapEntityHelpers';

import type { EditorMode } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';

import SnapPublisher from 'views/publisherStoryEditor/containers/SnapPublisher/SnapPublisher';
import SnapPublisherBar from 'views/singleAssetStoryEditor/containers/SnapPublisherBar/SnapPublisherBar';

import style from './SnapPublisherWithBar.scss';

import type { State as ReduxState } from 'types/rootState';
import type { CreativeID, TopSnap } from 'types/snaps';

type StateProps = {
  activeSnap: TopSnap | undefined | null;
  creativeId: CreativeID | undefined | null;
  isCuratedLayerEnabled: boolean;
  editorMode: EditorMode;
};

const mapStateToProps = (state: ReduxState): StateProps => {
  const activeSnap = asTopSnap(getActiveTopsnap(state));
  return {
    activeSnap,
    creativeId: getCreativeIdFromSnap(state)(activeSnap && activeSnap.id),
    isCuratedLayerEnabled: isCuratedLayerEnabled(state) || isAdvancedCurationEnabled(state),
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ editorMode: string; metadata: string; opti... Remove this comment to see the full error message
    editorMode: getStoryEditorMode(state),
  };
};

type Props = StateProps;

export class SnapPublisherWithBar extends React.PureComponent<Props> {
  render() {
    const { activeSnap } = this.props;

    const options = {
      snapId: activeSnap && activeSnap.id,
      creativeId: this.props.creativeId || (activeSnap && activeSnap.creativeId),
      webImportUrl: this.props.editorMode.editorModeMetadata,
      assetId: get(activeSnap, 'imageAssetId') || get(activeSnap, 'videoAssetId'),
      ...(is.truthy(this.props.isCuratedLayerEnabled) && { layerAssetId: get(activeSnap, 'overlayImageAssetId') }),
      ...this.props.editorMode.editorModeOptions,
    };

    return (
      <div className={style.root}>
        <SnapPublisherBar />
        <SnapPublisher options={options} />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(SnapPublisherWithBar);
