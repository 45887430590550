import PropTypes from 'prop-types';
import React from 'react';

import { plus } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './AddItemButton.scss';

type Props = {
  isReadOnly?: boolean;
  handleClick: () => void;
};

export default function OutcomePageAddItem(props: Props) {
  const { isReadOnly, handleClick } = props;

  if (isReadOnly) {
    return null;
  }

  return (
    <div className={style.root}>
      <SDSButton
        onClick={handleClick}
        inlineIcon={plus}
        type={ButtonType.SECONDARY}
        data-test="editor.itemsSelectPanel.addItem.button"
      >
        {getMessageFromId('add-outcome-button-label')}
      </SDSButton>
    </div>
  );
}

OutcomePageAddItem.propTypes = {
  isReadOnly: PropTypes.bool,
};
