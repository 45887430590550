import { assertArg } from 'utils/assertionUtils';

export function getAllMatches(str: string, regex: RegExp) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(str).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(regex).is.regexp();

  if (!regex.global) {
    throw new Error('Expected regex provided to getAllMatches() to use the /g flag');
  }

  const matches = [];
  let result;

  // eslint-disable-next-line no-cond-assign
  while ((result = regex.exec(str)) !== null) {
    matches.push(result);
  }

  return matches;
}

// https://stackoverflow.com/questions/3115150/how-to-escape-regular-expression-special-characters-using-javascript
export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
