import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import { defineMessages, intlShape } from 'react-intl';

import * as authSelectors from 'state/auth/selectors/authSelectors';
import { getStoryModerationStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { getSnapIds } from 'state/editions/actions/editionsActions';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import { getEditionLoadingById, getEditionSavingById } from 'state/editions/selectors/editionsSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import * as adControlsStagesSelectors from 'state/stages/adControls/selectors/adControlsStagesSelectors';
import { shouldDisplayManageSubtitlesInfoBox } from 'state/subtitles/selectors/manageSubtitlesSelectors';

import { State } from 'src/types/rootState';
import { clearAsyncInterval, setAsyncInterval } from 'utils/asyncInterval';
import * as browserUtils from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import { isExpiredSessionError } from 'utils/router/authErrorUtils';
import { withRouter } from 'utils/routerUtils';

import AnalyticsCarousel from 'views/common/containers/AnalyticsCarousel/AnalyticsCarousel';
import StoryCarousel from 'views/publisherStoryEditor/containers/StoryCarousel/StoryCarousel';
import ManageSubtitlesInfoBox from 'views/subtitles/ManageSubtitlesInfoBox/ManageSubtitlesInfoBox';

import style from './PublisherStoryEditor.scss';

import type { EditionID, Edition } from 'types/editions';
import { StoryModerationStatus } from 'types/moderation';
import { ExtractDispatchProps } from 'types/redux';
import type { Snap } from 'types/snaps';

const messages = defineMessages({
  unsavedBottomSnapChanges: {
    id: 'bottom-snap-unsaved-changes',
    description: 'Message shown to user when a navigation action will cause him to lose unsaved changes',
    defaultMessage:
      'The bottom Snap has unsaved changes. If you choose to proceed those changes will be lost. Continue?',
  },
  unsavedAdsChanges: {
    id: 'ads-unsaved-changes',
    description:
      'Message shown to user when a navigation action will cause him to lose unsaved changes in ads placement',
    defaultMessage:
      'Your ads placement has unsaved changes. If you choose to proceed those changes might be lost. Continue?',
  },
});
const SNAPID_POLLING_FREQUENCY = 6 * 1000;
const CONCORDE_STATUS_POLLING_FREQUENCY = 2000;

type OwnProps = {
  match: any;
  history: any;
  activeEdition?: Edition;
  activeEditionSnaps: Snap[];
  goToPublisherStoryEditor: (...args: any[]) => any;
  editionIsSaving: number;
  editionIsLoading: number;
  authFailed: boolean;
  bottomSnapHasPendingChanges?: boolean;
  adsStateInvalid?: boolean;
  displayManageSubtitlesInfoBox?: boolean;
  storyModerationStatus?: any; // TODO: PropTypes.oneOf(Object.values(StoryModerationStatus))
};

function mapStateToProps(state: State, ownProps: OwnProps) {
  const editionId = parseInt(ownProps.match.params.editionId, 10);
  const next = {
    activeEdition: publisherStoryEditorSelectors.getActiveEdition(state),
    editionIsSaving: getEditionSavingById(state)(editionId),
    editionIsLoading: getEditionLoadingById(state)(editionId),
    activeEditionSnaps: publisherStoryEditorSelectors.getActiveEditionSnaps(state),
    bottomSnapHasPendingChanges: editorSelectors.getBottomSnapHasPendingChanges(state),
    adsStateInvalid: adControlsStagesSelectors.getAdsStateIsInvalid(state),
    authFailed: authSelectors.getAuthFailed(state),
    storyModerationStatus: getStoryModerationStatus(state)(editionId),
    displayManageSubtitlesInfoBox: shouldDisplayManageSubtitlesInfoBox(state)(editionId),
  };
  return next;
}
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  fetchEditionBuildStatusAndRefreshStaleBuilds: editorActions.fetchEditionBuildStatusAndRefreshStaleBuilds,
  goToPublisherStoryEditor: routerActions.goToPublisherStoryEditor,
  getSnapIds,
  saveBottomSnapLocally: editorActions.saveBottomSnapLocally,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = DispatchProps & StateProps & OwnProps;
export class PublisherStoryEditor extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  _fetchEditionInterval: { clear: boolean };

  _fetchSnapIdsInterval: { clear: boolean };

  listenBeforeHook: any;

  state = {};

  constructor(props: Props) {
    super(props);
    this._fetchEditionInterval = { clear: false };
    this._fetchSnapIdsInterval = { clear: false };
  }

  UNSAFE_componentWillMount() {
    // Installs a hook that gets called before a route change. If the hook returns a string,
    // a confirmation dialog is presented to the user with the message. This gives the user the
    // opportunity to cancel the navigation to avoid losing changes
    this.listenBeforeHook = this.props.history.block((newPath: any) => {
      const hasSessionExpired = newPath?.search ? isExpiredSessionError(newPath.search) : false;
      if (this.props.bottomSnapHasPendingChanges && hasSessionExpired) {
        return this.props.saveBottomSnapLocally();
      }
      const pathEditionSubsection = `edition/${this.props.match.params.editionId}`;
      const isLeavingEdition = newPath ? newPath.pathname.indexOf(pathEditionSubsection) === -1 : true;
      if (this.props.bottomSnapHasPendingChanges) {
        return this.context.intl.formatMessage(messages.unsavedBottomSnapChanges);
      }
      if (this.props.adsStateInvalid && isLeavingEdition) {
        return this.context.intl.formatMessage(messages.unsavedAdsChanges);
      }
      return null;
    });
    const editionId = parseInt(this.props.match.params.editionId, 10);
    this.initializeEditor(editionId);
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  UNSAFE_componentWillUpdate(nextProps: any) {
    const currentEditionId = parseInt(this.props.match.params.editionId, 10);
    const nextEditionId = parseInt(nextProps.match.params.editionId, 10);
    if (currentEditionId !== nextEditionId) {
      this.initializeEditor(nextEditionId);
    }
  }

  componentWillUnmount() {
    clearAsyncInterval(this._fetchEditionInterval);
    clearAsyncInterval(this._fetchSnapIdsInterval);
    this.listenBeforeHook();
  }

  onClick = () => {
    if (!this.props.match.isExact) {
      const { publisherId, editionId } = this.props.match.params;
      this.props.goToPublisherStoryEditor({ publisherId, editionId, overwriteHistory: true });
    }
  };

  getMonitorBuildStatusIntervalTimeout() {
    return CONCORDE_STATUS_POLLING_FREQUENCY;
  }

  initializeEditor(editionId: EditionID) {
    clearAsyncInterval(this._fetchEditionInterval);
    clearAsyncInterval(this._fetchSnapIdsInterval);
    this.props.fetchEditionBuildStatusAndRefreshStaleBuilds({ editionId }).catch(() => {});
    this.monitorBuildStatus(editionId);
    this.ensureSnapIdFreshness(editionId);
  }

  getBuildStatus = (editionId: EditionID) => async () => {
    if (!browserUtils.isDocumentHidden() && !this.props.authFailed) {
      await this.props.fetchEditionBuildStatusAndRefreshStaleBuilds({ editionId }).catch(() => {});
    }
  };

  monitorBuildStatus(editionId: EditionID) {
    this._fetchEditionInterval = { clear: false };
    setAsyncInterval(
      this.getBuildStatus(editionId),
      this.getMonitorBuildStatusIntervalTimeout(),
      this._fetchEditionInterval
    );
  }

  refreshSnapId = (editionId: EditionID) => async () => {
    if (
      !browserUtils.isDocumentHidden() &&
      !this.props.editionIsLoading &&
      !this.props.editionIsSaving &&
      !this.props.authFailed
    ) {
      await this.props.getSnapIds({ editionId });
    }
  };

  ensureSnapIdFreshness(editionId: EditionID) {
    this._fetchSnapIdsInterval = { clear: false };
    setAsyncInterval(this.refreshSnapId(editionId), SNAPID_POLLING_FREQUENCY, this._fetchSnapIdsInterval);
  }

  renderStoryBanner() {
    const { activeEdition, displayManageSubtitlesInfoBox } = this.props;
    const isEditionAvailable = editionEntityHelpers.isAvailable(activeEdition);
    const isEditionScheduled = editionEntityHelpers.isScheduled(activeEdition);
    const hasEditionBeenModeratedBefore = this.props.storyModerationStatus === StoryModerationStatus.COMPLETE;
    if (displayManageSubtitlesInfoBox) {
      return (
        <div className={style.infoBoxContainer}>
          <ManageSubtitlesInfoBox />
        </div>
      );
    }
    if (isEditionAvailable || isEditionScheduled || hasEditionBeenModeratedBefore) {
      return (
        <AnalyticsCarousel
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ activeEdition: editionPropType | undefined... Remove this comment to see the full error message
          activeEdition={activeEdition}
          className={classNames(style.analyticsCarousel, { [style.scheduled]: isEditionScheduled })}
          boxClassName={style.analyticsBox}
          hideKPIs={isEditionScheduled}
        />
      );
    }
    return null;
  }

  render() {
    const { activeEdition, activeEditionSnaps } = this.props;
    if (!activeEdition) {
      return null;
    }
    // Normally this.props.children comes from router.js. In the case where there are no children, we render an
    // empty RichSnapEditor.
    return (
      <div className={style.container}>
        {this.renderStoryBanner()}
        <StoryCarousel
          activeEdition={activeEdition}
          activeEditionSnaps={activeEditionSnaps}
          className={style.snapList}
          onClick={this.onClick}
        />
        {this.props.children || null}
      </div>
    );
  }
}
export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(PublisherStoryEditor));
