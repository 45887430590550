import React from 'react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getIsStoryLiveEdited } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import style from '../StoryBars.scss';

import { chart } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isStoryPromoted } from 'utils/payToPromoteUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import MutePreviewButton from 'views/common/containers/MutePreviewButton/MutePreviewButton';
import PayToPromoteButton from 'views/payToPromote/components/PayToPromoteButton/PayToPromoteButton';
import withProfileAdAccountsQuery from 'views/payToPromote/hocs/withProfileAdAccountsQuery/withProfileAdAccountsQuery';
import { ProfileAdAccountsQueryResult } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import DiscardLiveEditChangesButton from 'views/publisherStoryEditor/components/DiscardLiveEditChangesButton/DiscardLiveEditChangesButton';
import MediaLibraryButton from 'views/publisherStoryEditor/components/MediaLibraryButton/MediaLibraryButton';
import SnapcodePreviewButton from 'views/publisherStoryEditor/components/SnapcodePreviewButton/SnapcodePreviewButton';
import StoryStateOverview from 'views/publisherStoryEditor/components/StoryStateOverview/StoryStateOverview';
import LockUnlockLiveStoryButton from 'views/publisherStoryEditor/containers/LockUnlockLiveStoryAlert/LockUnlockLiveStoryButton';
import StoryLevelMessage from 'views/publisherStoryEditor/containers/StoryLevelMessage/StoryLevelMessage';
import StoryOptionsButton from 'views/publisherStoryEditor/containers/StoryOptionsButton/StoryOptionsButton';
import StorySubtitleButton from 'views/publisherStoryEditor/containers/StorySubtitleButton/StorySubtitleButton';

import { StoryState } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';

const mapStateToProps = (state: State) => {
  const activeStory = publisherStoryEditorSelectors.getActiveEdition(state);
  const storyId = activeStory?.id || null;

  return {
    activeStory: publisherStoryEditorSelectors.getActiveEdition(state),
    publisherId: getActivePublisherId(state),
    isStoryReadOnlyOrSaving: publisherStoryEditorSelectors.getStoryIsReadOnlyOrSaving(state)(storyId),
    isSingleAssetStoryEditor: storyId && publisherStoryEditorSelectors.shouldUseSingleAssetEditor(state)(storyId),
    isStoryLiveEdited: storyId && getIsStoryLiveEdited(state)(storyId),
  };
};

const mapDispatchToProps = {
  goToStoryAnalytics: routerActions.goToStoryAnalytics,
};

type OwnProps = ProfileAdAccountsQueryResult;
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

export class LiveStoryBar extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, undefined, nextProps);
  }

  goToStoryAnalytics = () => {
    const { publisherId } = this.props;
    const editionId = this.props.activeStory?.id;

    return this.props.goToStoryAnalytics({ publisherId, editionId });
  };

  renderStoryAnalyticsButton() {
    return (
      <div data-test="storyBuilderViewStoryAnalyticsButton">
        <SDSButton type={ButtonType.WHITE_ON_GREY} inlineIcon={chart} onClick={this.goToStoryAnalytics}>
          {getMessageFromId('story-status-analytics')}
        </SDSButton>
      </div>
    );
  }

  render() {
    const { publisherId, activeStory, isStoryLiveEdited, isSingleAssetStoryEditor } = this.props;
    if (!activeStory) {
      return null;
    }

    return (
      <div className={style.root}>
        <div className={style.leftBar}>
          <StoryStateOverview
            storyState={StoryState.LIVE}
            isStoryPromoted={isStoryPromoted(activeStory.id, this.props.promotedStoriesIds)}
          />
        </div>
        <div className={style.middleBar}>
          <StoryLevelMessage />
          {isStoryLiveEdited && <DiscardLiveEditChangesButton publisherId={publisherId} storyId={activeStory.id} />}
        </div>
        <div className={style.rightBar}>
          <PayToPromoteButton
            buttonType={ButtonType.WHITE_ON_GREY}
            storyId={activeStory.id}
            error={this.props.profileAdAccountsError}
            adAccountId={this.props.adAccountId}
          />
          {this.renderStoryAnalyticsButton()}
          <LockUnlockLiveStoryButton />
          {!isSingleAssetStoryEditor && (
            <StorySubtitleButton disabled={this.props.isStoryReadOnlyOrSaving} editionId={activeStory.id} />
          )}
          <SnapcodePreviewButton />
          {!isSingleAssetStoryEditor && <MediaLibraryButton disabled />}
          <MutePreviewButton />
          <StoryOptionsButton />
        </div>
      </div>
    );
  }
}

export default withProfileAdAccountsQuery(intlConnect(mapStateToProps, mapDispatchToProps)(LiveStoryBar));
