import classNames from 'classnames';
import { findIndex } from 'lodash';
import type { MouseEvent as ReactMouseEvent } from 'react';
import * as React from 'react';
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position';

import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { getEditionSubscribeSnap } from 'state/editions/selectors/editionsSelectors';
import { onTabChangedHandler, updateSingleAssetEditorState } from 'state/editor/actions/editorActions';
import { getStoryEditorMode } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { openPublisherDetailsModal } from 'state/publishers/actions/publishersActions';
import { activePublisherHasAddedRequiredDetails } from 'state/publishers/selectors/publishersSelectors';
import { goToSnap } from 'state/router/actions/routerActions';
import { goToNextSnapOrShot, goToPreviousSnapOrShot } from 'state/singleAssetStory/actions/singleAssetStoryActions';
import {
  getActiveTimelineSnap,
  getShotsForStory,
  getSingleAssetIsInDebugMode,
  getSingleAssetPlayerCurrentTime,
  getSingleAssetPlayerDuration,
  getSingleAssetPlayerIsPlaying,
  getTimelineSnaps,
  isSubscribeSnapSelected,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';

import { EDITOR_MODES } from 'config/constants';
import { chevronLeft, chevronRight, pause, playFilled } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { msToSeconds } from 'utils/singleAssetStoryEditorUtils';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import SDSButton, { ButtonShape, ButtonSize, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import TimelineHoverDecoration from 'views/singleAssetStoryEditor/containers/TimelineHoverDecoration/TimelineHoverDecoration';
import TimelineSnapCell from 'views/singleAssetStoryEditor/containers/TimelineSnapCell/TimelineSnapCell';
import VideoPlayHead from 'views/singleAssetStoryEditor/containers/VideoPlayHead/VideoPlayHead';
import VideoPlayerTimeLabel from 'views/singleAssetStoryEditor/containers/VideoPlayerTimeLabel/VideoPlayerTimeLabel';

import style from './VideoPlayerTimeline.scss';

import { SnapProblem } from 'types/build';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State as ReduxState } from 'types/rootState';
import type { ExtendedShot, TimelineSnap } from 'types/singleAssetStoryEditor';
import type { SubscribeSnap } from 'types/snaps';

type StateProps = {
  duration: number;
  isPlaying: boolean;
  snaps: TimelineSnap[];
  subscribeSnap: SubscribeSnap | undefined | null;
  shots: ExtendedShot[];
  isInDebugMode: boolean;
  isSubscribeSnapSelected: boolean;
  hasErrorOnSubscribeSnap: boolean;
  currentTime: number;
  isShowingSnapPublisher: boolean;
  activeTimelineSnap: TimelineSnap | undefined | null;
  publisherHasAddedRequiredDetails: boolean;
};

type OwnProps = {
  publisherId: PublisherID;
  activeStoryId: EditionID;
  activeSnapId: SnapId | undefined | null;
  playableMode: boolean;
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const storyId = ownProps.activeStoryId;
  const subscribeSnapId = getEditionSubscribeSnap(state)(storyId)?.relatedSnapIds?.TOP;
  const snapExtendedStatus = subscribeSnapId
    ? getDiscoverSnapBuildStatus(state)(subscribeSnapId)?.extendedStatus
    : null;
  const hasErrorOnSubscribeSnap = snapExtendedStatus !== SnapProblem.PUBLISHABLE;
  const isShowingSnapPublisher = getStoryEditorMode(state).editorMode === EDITOR_MODES.SNAP_PUB;

  return {
    duration: getSingleAssetPlayerDuration(state)(storyId),
    isPlaying: getSingleAssetPlayerIsPlaying(state)(storyId),
    snaps: getTimelineSnaps(state)(storyId),
    subscribeSnap: getEditionSubscribeSnap(state)(storyId),
    shots: getShotsForStory(state)(storyId),
    isInDebugMode: getSingleAssetIsInDebugMode(state)(storyId),
    isSubscribeSnapSelected: isSubscribeSnapSelected(state),
    hasErrorOnSubscribeSnap,
    currentTime: getSingleAssetPlayerCurrentTime(state)(storyId),
    isShowingSnapPublisher,
    activeTimelineSnap: getActiveTimelineSnap(state)(ownProps.activeStoryId),
    publisherHasAddedRequiredDetails: activePublisherHasAddedRequiredDetails(state),
  };
};

const mapDispatchToProps = {
  goToSnap,
  updateSingleAssetEditorState,
  goToNextSnapOrShot,
  goToPreviousSnapOrShot,
  onTabChangedHandler,
  openPublisherDetailsModal,
};

export class VideoPlayerTimeline extends React.Component<Props> {
  private readonly timelineDiv: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.timelineDiv = React.createRef();
  }

  onPlayPauseButtonClick = () => {
    return this.props.updateSingleAssetEditorState(this.props.activeStoryId, {
      videoPlayer: {
        isPlaying: !this.props.isPlaying,
      },
    });
  };

  onTimelineClick = (event: ReactMouseEvent<HTMLDivElement>) => {
    if (this.props.duration === 0) {
      return;
    }

    const rect = event.currentTarget.getBoundingClientRect();
    const xRelativeToElement = event.clientX - rect.left;
    const elementWidth = rect.width;

    const newCurrentTime = (xRelativeToElement / elementWidth) * this.props.duration;

    this.props.updateSingleAssetEditorState(this.props.activeStoryId, {
      videoPlayer: {
        pendingCurrentTime: Math.round(newCurrentTime),
      },
    });
  };

  renderDebugTimeline = () => {
    const { shots } = this.props;

    return <div className={style.debugTimelineContainer}>{shots.map(this.renderDebugShot)}</div>;
  };

  renderDebugShot = (shot: ExtendedShot) => {
    if (!this.props.duration) {
      return null;
    }

    const startPositionPercentage = (shot.startTimeMs / this.props.duration) * 100;
    const endPositionPercentage = ((shot.startTimeMs + shot.durationMs) / this.props.duration) * 100;

    return (
      <div key={shot.startTimeMs} className={style.debugShotContainer}>
        <div className={style.debugShot} style={{ left: `${startPositionPercentage}%` }} />
        <div
          className={style.debugShotTime}
          style={{
            left: `${startPositionPercentage + (endPositionPercentage - startPositionPercentage) / 2}%`,
          }}
        >
          {msToSeconds(shot.durationMs).toFixed(1)}
        </div>
        <div className={style.debugShot} style={{ left: `${endPositionPercentage}%` }} />
      </div>
    );
  };

  renderVideoTimeline = () => {
    const { snaps } = this.props;

    return (
      <div
        className={style.timelineSnapCellsContainer}
        onClick={this.onTimelineClick}
        data-test="singleAssetStoryEditor.VideoPlayerTimeline.storyTimeline"
      >
        {snaps.map(this.renderTimelineSnapCell)}
        {this.renderTimelineProgressBar()}
      </div>
    );
  };

  renderTimelineProgressBar = () => {
    let width = 100; // no snaps so fill timeline

    const lastSnap = this.props.snaps[this.props.snaps.length - 1];
    if (lastSnap) {
      const totalSnapsDuration = lastSnap.startTimeMs + lastSnap.durationMs;
      const remainingTime = this.props.duration - totalSnapsDuration;
      if (remainingTime < 1000) {
        return null;
      }
      const remainingPercent = (remainingTime / this.props.duration) * 100;
      width = remainingPercent;
    }

    return (
      <div
        data-test="VideoPlayerTimeline.progressBar"
        className={style.timelineProgressBar}
        style={{ minWidth: `${width}%` }}
      >
        <div />
      </div>
    );
  };

  renderTimelineSnapCell = (snap: TimelineSnap, index: number) => {
    return (
      <TimelineSnapCell
        key={snap.snapId}
        snap={snap}
        activeStoryId={this.props.activeStoryId}
        hideDecoration={!this.props.playableMode}
        data-test="VideoPlayerTimeline.timelineSnapCell"
      />
    );
  };

  onNextClick = () => {
    const { activeStoryId, snaps, currentTime } = this.props;

    this.props.goToNextSnapOrShot(activeStoryId, currentTime, snaps);
  };

  onPreviousClick = () => {
    const { activeStoryId, snaps, currentTime } = this.props;

    this.props.goToPreviousSnapOrShot(activeStoryId, currentTime, snaps);
  };

  renderVideoControlButtons = () => {
    return (
      <div className={style.videoControlButtons}>
        <SDSButton
          size={ButtonSize.SMALL}
          type={ButtonType.WHITE}
          shape={ButtonShape.CIRCLE}
          inlineIcon={chevronLeft}
          onClick={this.onPreviousClick}
          data-test="VideoPlayerTimeline.previousButton"
        />
        {this.renderPlayButtonOrSnapNumber()}
        <SDSButton
          size={ButtonSize.SMALL}
          type={ButtonType.WHITE}
          shape={ButtonShape.CIRCLE}
          inlineIcon={chevronRight}
          onClick={this.onNextClick}
          data-test="VideoPlayerTimeline.nextButton"
        />
      </div>
    );
  };

  onSubscribeSnapTimelineClick = () => {
    if (this.props.subscribeSnap) {
      if (this.props.publisherHasAddedRequiredDetails) {
        this.props.goToSnap({
          publisherId: this.props.publisherId,
          editionId: this.props.activeStoryId,
          snapId: this.props.subscribeSnap.relatedSnapIds.TOP,
          overwriteHistory: true,
        });
      } else {
        this.props.openPublisherDetailsModal('SASSubscribeSnap');
      }
    }
  };

  renderSubscribeSnapTimeline = () => {
    const subSnapClassNames = classNames(style.timelineBackground, style.subscribeSnapTimeline, {
      [style.subscribeSelected]: this.props.isSubscribeSnapSelected,
    });
    return (
      <div
        className={subSnapClassNames}
        onClick={this.onSubscribeSnapTimelineClick}
        data-test="singleAssetStoryEditor.VideoPlayerTimeline.subscribeSnapTimeline"
      >
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.TOP}
          title={<DotStatus status={DotStatusState.ERROR} />}
          visible={this.props.hasErrorOnSubscribeSnap && !this.props.isShowingSnapPublisher}
          disablePointerEvents
        >
          <div className={style.timelineSnapCell} />
        </SDSTooltip>
      </div>
    );
  };

  renderPlayButtonOrSnapNumber = () => {
    const snapIndex = findIndex(this.props.snaps, (s: TimelineSnap) => s.snapId === this.props.activeSnapId);

    return (
      <div
        className={style.playButtonOrSnapNumberContainer}
        data-test="VideoPlayerTimeline.playButtonOrSnapNumberContainer"
      >
        {this.props.playableMode ? (
          <SDSButton
            size={ButtonSize.LARGE}
            type={ButtonType.WHITE}
            shape={ButtonShape.CIRCLE}
            inlineIcon={this.props.isPlaying ? pause : playFilled}
            onClick={this.onPlayPauseButtonClick}
            data-test="VideoPlayerTimeline.playPauseButton"
          />
        ) : (
          <div className={style.snapNumber} data-test="VideoPlayerTimeline.snapNumber">
            {getMessageFromId('snap-with-index', { index: snapIndex + 1 })}
          </div>
        )}
      </div>
    );
  };

  renderInnerTimeline = () => {
    return (
      <div className={style.timelineWrapper} ref={this.timelineDiv}>
        {!this.props.isSubscribeSnapSelected && this.props.playableMode && (
          <VideoPlayHead storyId={this.props.activeStoryId} data-test="VideoPlayerTimeline.VideoPlayHead" />
        )}
        {this.renderVideoTimeline()}
        {this.props.isInDebugMode && this.renderDebugTimeline()}
      </div>
    );
  };

  renderTimeline = () => {
    return (
      <div className={style.timelineBackground} data-test={'VideoPlayerTimeline.timelineContainer'}>
        <ReactCursorPosition
          className={style.cursorPosition}
          activationInteractionMouse={INTERACTIONS.HOVER}
          data-test="VideoPlayerTimeline.ReactCursorPosition"
        >
          <TimelineHoverDecoration
            /* ReactCursorPosition doesn't play well with TypeScript:
              https://github.com/ethanselzer/react-cursor-position/pull/29 */
            activeStoryId={this.props.activeStoryId}
            shots={this.props.shots}
            videoDuration={this.props.duration}
            showMarker={this.props.playableMode}
            data-test="VideoPlayerTimeline.TimelineHoverDecoration"
          />
          {this.renderInnerTimeline()}
        </ReactCursorPosition>
      </div>
    );
  };

  render() {
    return (
      <div className={style.root} data-test="VideoPlayerTimeline.root">
        {this.renderVideoControlButtons()}
        {this.renderTimeline()}
        {this.props.playableMode && this.props.subscribeSnap && this.renderSubscribeSnapTimeline()}
        <VideoPlayerTimeLabel
          storyId={this.props.activeStoryId}
          snap={!this.props.playableMode ? this.props.activeTimelineSnap : undefined}
          data-test="VideoPlayerTimeline.VideoPlayerTimeLabel"
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(VideoPlayerTimeline);
