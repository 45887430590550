import { useState, useEffect, useRef } from 'react';

export default (value: string | number, timeout: number) => {
  const [state, setState] = useState(value);
  const handel = useRef();
  useEffect(() => {
    if (handel.current) {
      clearTimeout(handel.current);
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    handel.current = setTimeout(() => setState(value), timeout);

    return () => {
      if (handel.current) {
        clearTimeout(handel.current);
      }
    };
  }, [value, timeout]);

  return state;
};
