import classNames from 'classnames';
import { memoize, defer } from 'lodash';
import * as React from 'react';
import InlineSVG from 'svg-inline-react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as snapcodesActions from 'state/snapcodes/actions/snapcodesActions';
import * as snapcodesSelectors from 'state/snapcodes/selectors/snapcodesSelectors';

import { DeeplinkType } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { getSnapcodeImageUrl, createSnapcodeFromSnapcodeEntity } from 'utils/snapcodeUtils';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/BlankSnapcode.svg.inlin... Remove this comment to see the full error message
import blankSnapcode from 'images/BlankSnapcode.svg.inline';

import style from './PreviewSnapcode.scss';

import type { EditionID } from 'types/editionID';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';
import { BuildSettingByQuality } from 'types/snapcodes';
import type { Snapcode, QualityEnum } from 'types/snapcodes';

type ExternalProps = {
  className?: string;
};

type StateProps = {
  quality: QualityEnum;
  activeEditionId: EditionID | undefined | null;
};

type InternalState = {
  snapcode: Snapcode | undefined | null;
  loading: boolean;
  error: boolean;
};

const mapStateToProps = (state: RootState): StateProps => ({
  activeEditionId: publisherStoryEditorSelectors.getActiveEditionId(state),
  quality: snapcodesSelectors.getSnapcodePreviewQuality(state),
});

const mapDispatchToProps = {
  fetchEditionPreviewSnapcode: snapcodesActions.fetchEditionPreviewSnapcode,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & DispatchProps & StateProps;

export class PreviewSnapcode extends React.Component<Props, InternalState> {
  state = {
    snapcode: null,
    loading: false,
    error: false,
  };

  fetchSnapcode = memoize(
    (editionId, quality) => {
      defer(() => {
        this.setState({ loading: true });
        // Reload the snapcode each time this component is mounted, this is because we reset the snap counter on FSN side
        // back to 0 each time
        const buildSetting = BuildSettingByQuality[quality];
        this.props
          .fetchEditionPreviewSnapcode(editionId, buildSetting)
          .then(({ payload }: any) => {
            this.setState({
              snapcode: createSnapcodeFromSnapcodeEntity(
                DeeplinkType.EDITION,
                editionId
              )({ ...payload, cb: `${Date.now()}` }),
            });
          })
          .finally(() => {
            this.setState({ loading: false });

            // During fetching the snapcode, we want to ignore subsequent calls,
            // However, once the snapcode download has completed (suuccessfully or otherwise), we can start accepting
            // calls to fetchSnapcode again

            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            this.fetchSnapcode.cache.clear();
          })
          .catch(() => {
            this.setState({ error: true });
          });
      });
    },
    (_, [editionId, quality]) => `${editionId}${quality}`
  );

  renderStatus() {
    if (this.state.loading) {
      return getMessageFromId('loading-snapcode');
    }
    if (this.state.error) {
      return getMessageFromId('generic-error');
    }
    return getMessageFromId('snapcode-up-to-date');
  }

  renderPreview() {
    const { snapcode, loading, error } = this.state;

    if (snapcode && !loading && !error) {
      const previewImageUrl = getSnapcodeImageUrl(snapcode);
      return (
        <div className={style.previewContainer}>
          <img src={previewImageUrl} alt="" />
        </div>
      );
    }
    return (
      <div className={style.previewContainer}>
        {error && (
          <div className={style.errorContainer}>
            <div className={`${style.error1} ${style.errorCommon}`} />
            <div className={`${style.error2} ${style.errorCommon}`} />
          </div>
        )}
        <InlineSVG
          className={classNames(style.placeholderSnapcode, {
            [style.loading]: loading,
          })}
          src={blankSnapcode}
        />
        {loading && <SpinnerIcon className={style.spinner} />}
      </div>
    );
  }

  render() {
    const { activeEditionId, quality, className } = this.props;
    this.fetchSnapcode(activeEditionId, quality);

    return (
      <div className={className}>
        <span className={style.statusMessage}>{this.renderStatus()}</span>
        {this.renderPreview()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(PreviewSnapcode);
