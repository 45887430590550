import { get, memoize } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getEditableActiveEditionsForPublisher } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import StoryModalRow from 'views/publisherStoryEditor/containers/StoryModalRow/StoryModalRow';

import style from './CopyToPublisherModal.scss';

import type { SnapId } from 'types/common';
import { ModalId } from 'types/modal';

export const mapStateToProps = (state: State) => {
  const activePublisher = getActivePublisherDetails(state);
  const activePublisherId = getActivePublisherId(state);
  const possibleTargetEditions = getEditableActiveEditionsForPublisher(state)(activePublisherId);
  return {
    activePublisher,
    activePublisherId,
    possibleTargetEditions,
    activeEdition: publisherStoryEditorSelectors.getActiveEdition(state),
    loadingActiveEditionsByPublisherId: publishersSelectors.getLoadingActiveEditionsById(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  hideModal: modalsActions.hideModal,
  showModal: modalsActions.showModal,
  duplicateStory: editionsActions.duplicateStory,
  copySnapToEdition: editionsActions.copySnapToEdition,
  getActiveEditionsForPublisher: publishersActions.getActiveEditionsForPublisher,
  copySnapOrStoryWithModal: editionsActions.copySnapOrStoryWithModal,
};

export type CopyToPublisherOptions = {
  copySnap: boolean;
  snapId?: SnapId;
};

type OwnProps = {
  modalId: ModalId;
  options: CopyToPublisherOptions;
};
type CopyToPublisherModalProps = OwnProps & StateProps;

type CopyToPublisherModalState = {
  selectedEdition: null | number;
};
export class CopyToPublisherModal extends React.Component<CopyToPublisherModalProps, CopyToPublisherModalState> {
  state: CopyToPublisherModalState = {
    selectedEdition: null,
  };

  static NEW_STORY_ID = -1;

  hideThisModal = () => (this.props as any).hideModal(this.props.modalId);

  duplicateStory = () => {
    const { activePublisherId } = this.props;
    const sourceStoryId = this.props.activeEdition?.id;
    const sourceSnapId = get(this.props.options, 'snapId', null);
    const copySnapOptions =
      this.state.selectedEdition !== CopyToPublisherModal.NEW_STORY_ID
        ? { destinationStoryId: this.state.selectedEdition }
        : { copySnapToNewStory: true };
    (this.props as any).copySnapOrStoryWithModal({
      sourceStoryId: sourceStoryId || 0,
      sourceSnapId,
      destinationPublisherId: activePublisherId || 0,
      ...copySnapOptions,
      hideThisModal: this.hideThisModal,
      copySnapFn: (this.props as any).copySnapToEdition,
      copyStoryFn: (this.props as any).duplicateStory,
    });
  };

  componentDidMount() {
    if (this.props.options.copySnap && this.props.activePublisherId) {
      (this.props as any).getActiveEditionsForPublisher({ publisherId: this.props.activePublisherId });
    }
  }

  handleEditionChange = memoize(editionId => () => {
    this.setState({ selectedEdition: editionId });
  });

  renderStorySelection = () => {
    const title = (
      <FormattedMessage
        id="story-label-in-copy-to"
        defaultMessage="Select a Story"
        description="Select a story to copy to"
      />
    );
    return (
      <div className={style.selectStoryLabel}>
        <SDSLabel labelTitle={title}>
          {this.props.loadingActiveEditionsByPublisherId(this.props.activePublisherId || 0) > 0 ? (
            <SpinnerIcon />
          ) : (
            this.renderStoryRows()
          )}
        </SDSLabel>
      </div>
    );
  };

  renderStoryRows = () => {
    const { possibleTargetEditions } = this.props;
    if (!possibleTargetEditions || possibleTargetEditions?.length === 0) {
      return (
        <FormattedMessage
          id="no-target-editions"
          defaultMessage="There are no stories for you to copy to for this publisher. Please choose another publisher."
          description="message shows when there are no active editions to copy a snap to"
        />
      );
    }
    const targetEditionRows = this.props.possibleTargetEditions
      .sort((a: any, b: any) => {
        return Date.parse(b.lastUpdatedAt) - Date.parse(a.lastUpdatedAt);
      })
      .map((edition: any) => (
        <StoryModalRow
          key={edition.id}
          publisherId={this.props.activePublisherId}
          storyId={edition.id}
          selectedStoryId={this.state.selectedEdition}
          onClick={this.handleEditionChange(edition.id)}
        />
      ));
    return (
      <div className={style.storySelect}>
        {[
          <StoryModalRow
            key={CopyToPublisherModal.NEW_STORY_ID}
            publisherId={this.props.activePublisherId}
            storyId={CopyToPublisherModal.NEW_STORY_ID}
            selectedStoryId={this.state.selectedEdition}
            onClick={this.handleEditionChange(CopyToPublisherModal.NEW_STORY_ID)}
          />,
          ...targetEditionRows,
        ]}
      </div>
    );
  };

  renderModalHeader = () => {
    if (this.props.options.copySnap) {
      return (
        <FormattedMessage
          id="duplicate-snap"
          description="Duplicate snap to the story in the same publisher"
          defaultMessage="Duplicate Snap"
        />
      );
    }
    return (
      <FormattedMessage
        id="duplicate-story"
        description="Duplicate story in the same publisher"
        defaultMessage="Duplicate Story"
      />
    );
  };

  render() {
    const { options } = this.props;
    return (
      <SDSCustomModal
        visible
        onClose={this.hideThisModal}
        title={this.renderModalHeader()}
        footer={
          <>
            <SDSButton
              type={ButtonType.PRIMARY}
              onClick={this.duplicateStory}
              data-test="modals.copyToPublisher.submit.button"
            >
              <FormattedMessage
                id="submit-copy-story-to-publisher"
                description="button to submit copy story to publisher"
                defaultMessage="Submit"
              />
            </SDSButton>
          </>
        }
        width={500}
        isBodyCentered
        data-test="modals.copyToPublisher"
      >
        {options.copySnap ? (
          this.renderStorySelection()
        ) : (
          <FormattedMessage
            id="copy-story"
            description="Copy story message"
            defaultMessage="Create duplicate story for {publisher}"
            values={{ publisher: this.props.activePublisher?.mutablePublisherName }}
          />
        )}
      </SDSCustomModal>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(CopyToPublisherModal);
