import classNames from 'classnames';
import * as React from 'react';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import * as editionsLifecycleSelectors from 'state/editions/selectors/editionsLifecycleSelectors';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';

import SnapPopoverButtonRowStyle from '../../styles/SnapPopoverButtonRow.scss';

import { intlConnect } from 'utils/connectUtils';
import { functionRef } from 'utils/functionUtils';

import ListItemWithIcon from 'views/common/components/ListItem/ListItemWithIcon';
import { SnapPopoverConfig, SnapPopoverRowIds } from 'views/publisherStoryEditor/utils/SnapPopoverRowConfig';

import type { EditionID, Edition } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';

type OwnProps = {
  storyId: EditionID;
  hostUsername?: string | null;
};

type StateProps = {
  canBeMadeAvailable: boolean;
  canBeMadeUnavailable: boolean;
  isEditionAvailableOrScheduled: boolean;
  isScheduleToLive: boolean;
  isScheduleToArchive: boolean;
};

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const edition: Edition | undefined | null = editionsSelectors.getEditionById(state)(ownProps.storyId);
  return {
    isScheduleToLive: !!edition && editionEntityHelpers.isScheduleToLive(edition),
    isScheduleToArchive: !!edition && editionEntityHelpers.isScheduleToArchive(edition),
    canBeMadeAvailable: editionsLifecycleSelectors.canEditionBeMadeAvailable(state)(ownProps.storyId),
    canBeMadeUnavailable: editionsLifecycleSelectors.canEditionBeMadeUnavailable(state)(ownProps.storyId),
    isEditionAvailableOrScheduled: editionsLifecycleSelectors.isEditionAvailableOrScheduled(state)(ownProps.storyId),
  };
};

const mapDispatchToProps = {
  makeStoriesUnavailable: functionRef(editionsActions, 'makeStoriesUnavailable'),
  makeStoriesAvailable: functionRef(editionsActions, 'makeStoriesAvailable'),
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;
type SnapPopoverRowIdsEnum = typeof SnapPopoverRowIds[keyof typeof SnapPopoverRowIds];

export class CheetahStoryOptionsControls extends React.Component<Props> {
  onMakeAvailableClick = () => this.props.makeStoriesAvailable([this.props.storyId]);

  onMakeUnavailableClick = () => this.props.makeStoriesUnavailable([this.props.storyId]);

  setDisabled(configKey: SnapPopoverRowIdsEnum, disabled: boolean) {
    const className = classNames(SnapPopoverConfig[configKey].className, {
      [SnapPopoverButtonRowStyle.disabled]: disabled,
    });
    return { ...SnapPopoverConfig[configKey], className };
  }

  render() {
    if (this.props.isEditionAvailableOrScheduled) {
      return (
        <ListItemWithIcon
          {...this.setDisabled(SnapPopoverRowIds.MAKE_STORY_UNAVAILABLE, !this.props.canBeMadeUnavailable)}
          onClick={this.onMakeUnavailableClick}
          data-test="cheetahStoryOptionControls.makeStoryUnavailable"
        />
      );
    }

    return (
      <ListItemWithIcon
        {...this.setDisabled(
          SnapPopoverRowIds.MAKE_STORY_AVAILABLE,
          !this.props.canBeMadeAvailable || Boolean(!this.props.hostUsername)
        )}
        onClick={this.onMakeAvailableClick}
      />
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(CheetahStoryOptionsControls);
