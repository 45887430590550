import React from 'react';

import { ContentStatus } from 'config/constants';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

type Props = {
  status: string;
  readOnly: boolean;
  onSave: (...args: any[]) => any;
};

const ActionButtons = (props: Props) => {
  const disableSaveButton = props.readOnly || props.status === ContentStatus.SAVING;

  return (
    <SDSButton
      type={ButtonType.PRIMARY}
      onClick={props.onSave}
      disabled={disableSaveButton}
      data-test="editor.actionButtons.save.button"
    >
      {getMessageFromId('save-button-text')}
    </SDSButton>
  );
};

export default ActionButtons;
