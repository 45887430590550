import _ from 'lodash';
import React from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import { getTileIdOrNull, isCropping } from 'state/tiles/schema/tilesEntityHelpers';

import TileBigPreview from '../../components/TileBigPreview/TileBigPreview';

import { UploadPurpose } from 'config/constants';
import { TileCropType } from 'config/tileConfig';
import { State } from 'src/types/rootState';
import * as componentUtils from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';

import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './TilePreviewEditor.scss';

export const mapStateToProps = (state: State, props: any) => {
  const topsnapId = editorSelectors.getActiveWholeSnapId(state);
  const editionId = editorSelectors.getActiveEditionId(state);
  const componentId = !topsnapId ? '' : componentUtils.buildComponentIdForSnapId(topsnapId);
  const activeTileUploadCount = mediaSelectors.getActiveUploadCountsForComponentIdByPurpose(state)(
    componentId,
    UploadPurpose.TILE_IMAGE
  );
  const activePublisherDetails = publishersSelectors.getActivePublisherDetails(state);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tile' does not exist on type 'null'.
  const { tile } = componentsSelectors.getComponentByIdForActiveSnap(state)(props.snapComponentId);
  // when tile is (blank) null, the pendingTile will also be null
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
  const pendingTile = editorSelectors.getPendingTileById(state)(getTileIdOrNull(tile));

  const shouldBlockByLoadingCropping = _.get(pendingTile, 'cropLoading', false);
  return {
    tile,
    isLoading: !!editionsSelectors.getEditionLoadingById(state)(editionId),
    isSaving: !!editionsSelectors.getEditionSavingById(state)(editionId),
    activeTileUploadCount,
    activePublisherDetails,
    pendingTile,
    shouldBlockByLoadingCropping,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    topsnap: snapsSelectors.getSnapById(state)(topsnapId),
  };
};

type OwnTilePreviewEditorProps = {
  isLoading?: boolean;
  isSaving?: boolean;
  shouldBlockByLoadingCropping?: boolean;
  tile?: any;
  pendingTile?: any;
  activeTileUploadCount?: number;
  topsnap?: any;
  activePublisherDetails?: any;
  isEditable?: boolean;
  dummyHeadline?: string;
  dummyLogo?: string;
  newTileDefaultProperties?: any;
};

type TilePreviewEditorState = any;

type TilePreviewEditorProps = OwnTilePreviewEditorProps & typeof TilePreviewEditor.defaultProps;

export class TilePreviewEditor extends React.Component<TilePreviewEditorProps, TilePreviewEditorState> {
  static defaultProps = {
    isEditable: true,
  };

  shouldComponentUpdate(nextProps: TilePreviewEditorProps, nextState: TilePreviewEditorState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  previewType() {
    return TileCropType.CHEETAH;
  }

  renderSpinner() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.props.activeTileUploadCount > 0) {
      return (
        <Spinner
          withBox
          loading
          message={SpinnerLabels.UPLOADING}
          data-test="richSnapEditor.tilePreviewEditor.spinner"
        />
      );
    }

    if (isCropping(this.props.tile)) {
      return (
        <Spinner
          withBox
          loading
          message={SpinnerLabels.CROPPING}
          data-test="richSnapEditor.tilePreviewEditor.spinner"
        />
      );
    }

    const isLoading = Boolean(
      !this.props.topsnap || !this.props.activePublisherDetails || this.props.isLoading || this.props.isSaving
    );
    return (
      <Spinner
        withBox
        loading={isLoading}
        message={SpinnerLabels.SAVING}
        data-test="richSnapEditor.tilePreviewEditor.spinner"
      />
    );
  }

  renderTileBigPreview() {
    const { tile, pendingTile, isEditable, dummyHeadline, dummyLogo, newTileDefaultProperties } = this.props;
    const previewType = this.previewType();
    const tileToRender = u(pendingTile || {}, tile);

    return (
      <TileBigPreview
        activeTypeId={previewType}
        tile={tileToRender}
        key={previewType}
        isInteractive={isEditable}
        dummyHeadline={dummyHeadline}
        dummyLogo={dummyLogo}
        newTileDefaultProperties={newTileDefaultProperties}
      />
    );
  }

  render() {
    return (
      <div className={style.root} data-test="richSnapEditor.tileEditor.preview">
        {this.renderSpinner()}
        {this.renderTileBigPreview()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(TilePreviewEditor);
