// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import { get } from 'lodash';

import type { Question, PollType } from 'types/polls';

export function isPollQuestion(question?: Question | null): boolean {
  return Boolean(question && question.pollType === POLL_TYPE.POLL);
}

export function isMultiQuestionPoll(pollType?: PollType | null): boolean {
  return Boolean(pollType && pollType === POLL_TYPE.OPEN_QUESTION);
}

export function isTriviaQuestion(question?: Question | null): boolean {
  return Boolean(question && question.pollType === POLL_TYPE.FACTUAL_QUESTION);
}

export function getQuestionEditorProperty<T>(question: Question, propertyName: string, defaultValue?: T): T {
  return get(question, ['editorProperties', propertyName], defaultValue);
}
