import classnames from 'classnames';
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { subtitleAlignBottom, subtitleAlignTop } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import { SubtitlePosition } from 'views/common/components/SubtitlesPreview/subtitleParser';
import type { SubtitlePositionEnum } from 'views/common/components/SubtitlesPreview/subtitleParser';

import style from './SubtitlesPositionToggle.scss';

type Props = {
  position: SubtitlePositionEnum;
  disabled: boolean;
  onToggle: () => void;
};

const bottomMessage = (
  <FormattedMessage
    id="subtitles-position-bottom"
    description="Position subtitle at the bottom of the screen"
    defaultMessage="Bottom"
  />
);

const topMessage = (
  <FormattedMessage
    id="subtitles-position-top"
    description="Position subtitle at the top of the screen"
    defaultMessage="Top"
  />
);

function SubtitlesPositionToggle({ position = SubtitlePosition.BOTTOM, disabled, onToggle }: Props) {
  const isBottomPosition = position === SubtitlePosition.BOTTOM;
  const positionText = isBottomPosition ? bottomMessage : topMessage;
  const positionIcon = isBottomPosition ? (
    <Icon inlineIcon={subtitleAlignBottom} data-test="bottomIcon" />
  ) : (
    <Icon inlineIcon={subtitleAlignTop} data-test="topIcon" />
  );

  const handlePositionChange = useCallback(() => {
    if (!disabled) {
      onToggle();
    }
  }, [onToggle, disabled]);

  return (
    <div className={classnames(style.toggleRoot, { [style.enabled]: !disabled })} onClick={handlePositionChange}>
      {positionIcon}
      {!disabled && (
        <div className={style.positionText} data-test="subtitlePositionToggle">
          {positionText}
        </div>
      )}
    </div>
  );
}

export default memo(SubtitlesPositionToggle);
