// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';

import { intlConnect } from 'utils/connectUtils';

import CameoInput from 'views/common/components/CameoInput/CameoInput';
import { InputType } from 'views/common/components/SDSInput/SDSInput';
import SnapTagInput from 'views/editor/components/SnapTagInput/SnapTagInput';
import TagLabel from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/TagLabel/TagLabel';

import { BuildStatusType } from 'types/build';
import type { State as RootState } from 'types/rootState';
import type { Snap } from 'types/snaps';

type StateProps = {
  snapId?: number | string | null;
  buildStatus?: BuildStatusType | null;
  topsnap?: Snap | null;
  isCameoContentEnabled: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
};

type DispatchProps = {
  setSnapPropertiesAndSave: typeof snapsActions.setSnapPropertiesAndSave;
};

type Props = StateProps & DispatchProps;

export const mapStateToProps = (state: RootState): StateProps => {
  const topsnap = editorSelectors.getActiveTopsnap(state);

  return {
    snapId: topsnap?.id,
    buildStatus: topsnap ? getDiscoverSnapBuildStatus(state)(topsnap.id) : null,
    isReadOnly: editorSelectors.isReadOnly(state),
    isSaving: editorSelectors.isSaving(state),
    isCameoContentEnabled: featuresSelectors.isCameosContentEnabled(state),
    topsnap,
  };
};

const mapDispatchToProps = {
  setSnapPropertiesAndSave: snapsActions.setSnapPropertiesAndSave,
};

function validateCameoId(info: string) {
  return info.length >= 4;
}

export const CameoPanel = ({
  snapId,
  topsnap,
  buildStatus,
  isReadOnly,
  isSaving,
  isCameoContentEnabled,
  setSnapPropertiesAndSave,
}: Props) => {
  const [cameoId, setCameoId] = useState(topsnap?.cameoSnapModel?.cameoId || '');
  const [revision, setRevision] = useState(topsnap?.cameoSnapModel?.revision || '');

  useEffect(() => {
    setCameoId(topsnap?.cameoSnapModel?.cameoId || 0);
    setRevision(topsnap?.cameoSnapModel?.revision || 0);
  }, [topsnap]);

  const handleCameoIdChange = useCallback(
    (value: string | number) => {
      if (!validateCameoId(`${value}`)) {
        setCameoId(value);
      } else if (topsnap) {
        setSnapPropertiesAndSave(
          {
            snapId: topsnap?.id,
          },
          {
            cameoSnapModel: {
              cameoId: value,
              revision: topsnap?.cameoSnapModel?.revision || 0,
              previewUrl: topsnap?.cameoSnapModel?.previewUrl || 'test.html',
            },
          }
        );
      }
    },
    [topsnap, setSnapPropertiesAndSave]
  );

  const handleCameoRevisionChange = useCallback(
    (value: string | number) => {
      if (value === topsnap?.cameoSnapModel?.revision && value === revision) {
        return;
      }
      setRevision(value);
      if (topsnap) {
        setSnapPropertiesAndSave(
          {
            snapId: topsnap?.id,
          },
          {
            cameoSnapModel: {
              cameoId: topsnap?.cameoSnapModel?.cameoId || 0,
              revision: +value,
              previewUrl: topsnap?.cameoSnapModel?.previewUrl || 'test.html',
            },
          }
        );
      }
    },
    [revision, topsnap, setSnapPropertiesAndSave]
  );

  const inputs = [
    {
      predicate: true,
      label: <TagLabel buildStatus={buildStatus} />,
      key: 'tagsInput',
      control: <SnapTagInput snapId={topsnap?.id} disabled={isReadOnly} />,
    },
    {
      key: 'cameoId',
      control: (
        <CameoInput
          labelTitle={
            <FormattedMessage id="cameo-id-label" description="Label for CameoId" defaultMessage="Cameo Id" />
          }
          type={InputType.NUMBER}
          data-test="CameoPanel.cameoId.input"
          value={cameoId}
          disabled={isReadOnly}
          onChange={handleCameoIdChange}
          errorMessage={
            validateCameoId(`${cameoId}`) ? null : (
              <FormattedMessage
                id="cameo-id-input-error"
                description="Error for CameoId input"
                defaultMessage="Cameo Id should be at least 4 numbers"
              />
            )
          }
        />
      ),
    },
    {
      key: 'cameoRevision',
      control: (
        <CameoInput
          labelTitle={
            <FormattedMessage
              id="cameo-revision-label"
              description="Label for Cameo Revision"
              defaultMessage="Cameo Revision"
            />
          }
          type={InputType.NUMBER}
          data-test="CameoPanel.cameoRevision.input"
          value={revision}
          disabled={isReadOnly}
          onChange={handleCameoRevisionChange}
        />
      ),
    },
  ];

  if (!topsnap) {
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <PropertyPanel
        fields={inputs}
        title={<FormattedMessage id="cameo-general-panel-title" description="General" defaultMessage="General" />}
        isReadOnly={isSaving}
        horizontal
        titleCaseLabels
      />
    </div>
  );
};

export default intlConnect(mapStateToProps, mapDispatchToProps)(CameoPanel);
