import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { isContentEmpty } from 'views/common/components/SubtitlesPreview/subtitleParser';

import style from './SubtitleHeadline.scss';

type Props = {
  index: number;
  subtitleLine: string;
};

const subtitleToBeDeleted = (
  <FormattedMessage
    id="subtitles-preview-subtitle-headline-to-be-deleted"
    description="If there is empty subtitle line, the headline will show this text."
    defaultMessage="To be deleted"
  />
);

function SubtitleHeadline({ index, subtitleLine }: Props) {
  const shouldRenderToBeDeleted = isContentEmpty(subtitleLine);
  const headlineMessage = shouldRenderToBeDeleted ? (
    subtitleToBeDeleted
  ) : (
    <FormattedMessage
      id="subtitles-preview-subtitle-headline-1"
      description="Subtitle headline with it's number in subtitle file"
      defaultMessage="Sub {index}"
      values={{ index }}
    />
  );

  return <span className={style.headline}>{headlineMessage}</span>;
}

export default memo(SubtitleHeadline);
