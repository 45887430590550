import { get } from 'lodash';
import * as React from 'react';

import DraftsStoryBar from 'views/publisherStoryEditor/containers/StoryBuilderBars/DraftStoryBar/DraftStoryBar';
import CurrentStoryBar from 'views/publisherStoryEditor/containers/StoryBuilderBars/LiveStoryBar/LiveStoryBar';
import ScheduledStoryBar from 'views/publisherStoryEditor/containers/StoryBuilderBars/ScheduledStoryBar/ScheduledStoryBar';
import UnavailableStoryBar from 'views/publisherStoryEditor/containers/StoryBuilderBars/UnavailableStoryBar/UnavailableStoryBar';

import style from './StoryBuilderCommon.scss';

import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';

const editionStateToStoryLevelBar = {
  [StoryState.NEW]: DraftsStoryBar,
  [StoryState.ARCHIVED]: UnavailableStoryBar,
  [StoryState.HIDDEN]: UnavailableStoryBar,
  [StoryState.READY]: DraftsStoryBar,
  [StoryState.LIVE]: CurrentStoryBar,
  [StoryState.SCHEDULED]: ScheduledStoryBar,
};

type Props = {
  story: Edition | undefined | null;
};

export const StoryLevelBarBuilder = (props: Props) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const StoryLevelBar = editionStateToStoryLevelBar[get(props, 'story.state')];
  if (!StoryLevelBar) {
    return null;
  }

  return (
    <div className={style.bar}>
      <StoryLevelBar />
    </div>
  );
};

export default React.memo(StoryLevelBarBuilder);
