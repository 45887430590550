import React from 'react';
import { FormattedMessage } from 'react-intl';

export const videoDetailsConfig = [
  {
    key: 'filename',
    message: (
      <FormattedMessage id="video-details-filename" description="Filename Field for Video" defaultMessage="Filename" />
    ),
  },
  {
    key: 'id',
    message: <FormattedMessage id="video-details-id" description="Id Field for Video" defaultMessage="Id" />,
  },
  {
    key: 'length',
    message: (
      <FormattedMessage id="video-details-length" description="Length Field for Video" defaultMessage="Length" />
    ),
  },
  {
    key: 'uploaded',
    message: (
      <FormattedMessage
        id="video-details-uploaded"
        description="Upload Time Field for Video"
        defaultMessage="Uploaded"
      />
    ),
  },
];

export default videoDetailsConfig;
