export default class SCParagraph {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCParagraph';
  }

  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get label() {
    return 'P';
  }

  static define(registry: any) {
    registry.registerStyleButton(SCParagraph, {
      element: 'p',
    });
  }
}
