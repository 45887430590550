/* global CKEDITOR */

function _commandNameForPlugin(PluginClass: any) {
  return `${PluginClass.pluginName}Command`;
}

export default class PluginsRegistry {
  _plugins: any;

  constructor() {
    this._plugins = {};
  }

  getPluginList() {
    return Object.keys(this._plugins).map(pluginName => this._plugins[pluginName]);
  }

  registerPlugin(PluginClass: any, params: any) {
    if (!PluginClass) {
      throw new Error('Cannot register a plugin without supplying the plugin class');
    } else if (!PluginClass.pluginName) {
      throw new Error('All plugin classes must have a pluginName property');
    }

    const { pluginName } = PluginClass;
    this._plugins[pluginName] = PluginClass;

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
    if (!CKEDITOR.plugins.get(pluginName)) {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
      CKEDITOR.plugins.add(pluginName, params);
    }
  }

  registerButton(PluginClass: any, { command, init }: any = {}) {
    if (!PluginClass.label) {
      throw new Error(`${PluginClass.pluginName} does not have a label`);
    } else if (!command) {
      throw new Error('Cannot register a button without supplying a command definition');
    }

    const name = PluginClass.pluginName;
    const commandName = _commandNameForPlugin(PluginClass);

    this.registerPlugin(PluginClass, {
      init(editor: any) {
        editor.addCommand(commandName, command);
        editor.ui.addButton(name, { command: commandName, label: PluginClass.label });

        if (init) {
          init(editor);
        }
      },
    });
  }

  registerStyleButton(PluginClass: any, { element }: any = {}) {
    if (!element) {
      throw new Error('Cannot register a style button without specifying the type of element');
    }

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
    const style = new CKEDITOR.style({ element }); // eslint-disable-line new-cap
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'CKEDITOR'.
    const command = new CKEDITOR.styleCommand(style); // eslint-disable-line new-cap

    this.registerButton(PluginClass, {
      command,
      init(editor: any) {
        // From the CKEDITOR docs:
        //
        //     Registers a function to be called whenever the selection position changes in the
        //     editing area. The current state is passed to the function. The possible states
        //     are {@link CKEDITOR#TRISTATE_ON} and {@link CKEDITOR#TRISTATE_OFF}.
        //
        // So this hook will be called when ever the user moves their selection within the editor,
        // with the `state` argument being either CKEDITOR.TRISTATE_ON or CKEDITOR.TRISTATE_OFF
        // based on whether the cursor is within an element of our target type or not (at any level
        // of depth).
        //
        // We can use this to toggle the visible state of the button on the toolbar, so that
        // it reflects whether the currently selected text has the style already applied or not.
        // This is done by calling the command's setState() function, which in turn results in
        // the button being re-rendered in the correct state.
        editor.attachStyleStateChange(style, (state: any) => {
          if (!editor.readOnly) {
            editor.getCommand(_commandNameForPlugin(PluginClass)).setState(state);
          }
        });
      },
    });
  }
}
