import classNames from 'classnames';
import React from 'react';
import InlineSVG from 'svg-inline-react';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import { getAssetId } from 'state/snaps/schema/snapEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/arrow_up.svg.inline' or ... Remove this comment to see the full error message
import arrowUp from 'icons/arrow_up.svg.inline';

import style from './TopsnapNavigation.scss';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import type { TopSnap } from 'types/snaps';

const mapStateToProps = (state: State) => {
  return {
    activeEditionId: editorSelectors.getActiveEditionId(state),
    activePublisherId: userSelectors.getActivePublisherId(state),
    activeSnap: editorSelectors.getActiveTopsnap(state),
    previousSnapId: editorSelectors.getPreviousTopsnapIdWithContent(state),
    nextSnapId: editorSelectors.getNextTopsnapIdWithContent(state),
  };
};

const mapDispatchToProps = {
  openSnapEditor: publisherStoryEditorModeActions.openSnapEditor,
};

type Props = {
  activeEditionId: EditionID | undefined | null;
  activePublisherId: PublisherID | undefined | null;
  activeSnap: TopSnap | undefined | null;
  previousSnapId: SnapId | undefined | null;
  nextSnapId: SnapId | undefined | null;
  openSnapEditor: typeof publisherStoryEditorModeActions.openSnapEditor;
};

export class TopsnapNavigation extends React.PureComponent<Props> {
  onNavClick = (snapId?: SnapId | null) => {
    if (snapId) {
      this.props.openSnapEditor({
        publisherId: this.props.activePublisherId,
        editionId: this.props.activeEditionId,
        overwriteHistory: false,
        snapId,
      });
    }
  };

  goToPrevSnap = () => {
    this.onNavClick(this.props.previousSnapId);
  };

  goToNextSnap = () => {
    this.onNavClick(this.props.nextSnapId);
  };

  render() {
    const { activeSnap, previousSnapId, nextSnapId } = this.props;

    const hasContent = activeSnap ? getAssetId(activeSnap) : false;
    const classes = classNames({
      [style.navigation]: true,
      [style.whiteNav]: !hasContent,
      [style.firstItem]: !previousSnapId,
      [style.lastItem]: !nextSnapId,
    });

    return (
      <div className={classes}>
        <div className={style.navLeft} onClick={this.goToPrevSnap} data-test="topsnapNavigation.Previous">
          <InlineSVG src={arrowUp} className={classNames(style.navArrow, style.arrowLeft)} />
        </div>
        <div className={style.navRight} onClick={this.goToNextSnap} data-test="topsnapNavigation.Next">
          <InlineSVG src={arrowUp} className={classNames(style.navArrow, style.arrowRight)} />
        </div>
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TopsnapNavigation);
