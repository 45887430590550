import React from 'react';
import { FormattedMessage } from 'react-intl';

import { setEditorMode } from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';

import { EDITOR_MODES } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SnapPublisherBar.scss';

import { ExtractDispatchProps } from 'types/redux';

const mapDispatchToProps = {
  setEditorMode,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps;

export class SnapPublisherBar extends React.PureComponent<Props> {
  handleSnapPublisherClose = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    this.props.setEditorMode(EDITOR_MODES.EDITOR);
  };

  render() {
    return (
      <div className={style.root}>
        <FormattedMessage
          id="snap-publisher-create-end-snap"
          description="Top bar title when using snap publisher to create media for a end snap"
          defaultMessage="Create an End Snap"
        />
        <SDSButton
          type={ButtonType.WHITE_ON_GREY}
          data-test="SingleAssetStoryEditor.closeSnapPublisherButton"
          onClick={this.handleSnapPublisherClose}
        >
          {getMessageFromId('cancel-button-modal')}
        </SDSButton>
      </div>
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(SnapPublisherBar);
