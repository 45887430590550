// External
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

// Utils

// Selectors
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { EDITOR_MODES } from 'config/constants';
import { cross } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

// Components/Views
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import RichSnapTabs from 'views/editor/containers/RichSnapTabs/RichSnapTabs';
import EditorTopBar from 'views/page/components/EditorTopBar/EditorTopBar';

// Types

// Icons

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/edit.svg.inline' or its... Remove this comment to see the full error message
import edit from 'images/edit.svg.inline';

// Style
import style from './SnapLevelBar.scss';

import { SnapProblem } from 'types/build';
import type { BuildStatusType } from 'types/build';
import type { SnapId } from 'types/common';

const DEFAULT_STATUS = {
  status: SnapProblem.NO_BUILD,
};

const SnapStatusIntlMessage: { [key in SnapProblem]: string | null } = {
  [SnapProblem.INCOMPLETE]: 'status-incomplete',
  [SnapProblem.NO_BUILD]: 'snap-status-error',
  [SnapProblem.BUILD_STALE]: 'snap-status-error',
  [SnapProblem.BUILDING]: 'snap-status-updating',
  [SnapProblem.BUILD_ERROR]: 'snap-status-error',
  [SnapProblem.BUILD_OVERSIZED]: 'snap-status-error',
  [SnapProblem.PROCESSING_INCOMPLETE]: 'snap-status-error',
  [SnapProblem.PUBLISHABLE]: null,
  [SnapProblem.ILLEGAL_STATE]: 'snap-status-error',
  [SnapProblem.CONTENT_DELETED]: null,
};

export function getSnapStatusIntlMessage(status: SnapProblem): string | null {
  return SnapStatusIntlMessage?.[status] || null;
}

function mapStateToProps(state: State) {
  const activeSnapId = editorSelectors.getActiveWholeSnapId(state);

  return {
    activeSnapId,
    buildStatus: activeSnapId ? buildStatusSelectors.getDiscoverSnapBuildStatus(state)(activeSnapId) : DEFAULT_STATUS,
    activeSnapIndex: editorSelectors.getActiveSnapIndex(state),
    editorMode: publisherStoryEditorSelectors.getEditorMode(state),
  };
}

type Props = {
  onClose: () => void;
  shouldRenderTabs: boolean;
  buildStatus: BuildStatusType | undefined | null;
  activeSnapIndex: number;
  activeSnapId: SnapId;
  editorMode: string;
};

export class SnapLevelBar extends React.PureComponent<Props> {
  renderSnapDetails() {
    const { activeSnapIndex, buildStatus, activeSnapId } = this.props;

    if (!activeSnapId || !buildStatus) {
      return <div className={style.snapDetails} />;
    }

    const snapDetailsIntlMessage = getSnapStatusIntlMessage(buildStatus.status);

    return (
      <div className={style.snapDetails}>
        <InlineSVG src={edit} className={style.pencil} />
        <span className={style.snapIndex}>{getMessageFromId('snap-with-index', { index: activeSnapIndex + 1 })}</span>
        <span className={style.snapStatus}>{snapDetailsIntlMessage && getMessageFromId(snapDetailsIntlMessage)}</span>
      </div>
    );
  }

  renderTabs() {
    if (!this.props.shouldRenderTabs) {
      return null;
    }
    return <RichSnapTabs />;
  }

  renderActions() {
    const isSnapPub = this.props.editorMode === EDITOR_MODES.SNAP_PUB;
    const discardMessage = (
      <FormattedMessage
        id="cancel-snap-pub"
        description="Message to discard snap pub changes"
        defaultMessage="Cancel"
      />
    );

    return (
      <div className={style.buttonContainer}>
        <SDSButton
          type={ButtonType.SECONDARY}
          shape={isSnapPub ? undefined : ButtonShape.CIRCLE}
          inlineIcon={isSnapPub ? undefined : cross}
          onClick={this.props.onClose}
          data-test="publisherStoryEditor.storyBuilder.snapLevel.bar.close.button"
        >
          {isSnapPub ? discardMessage : null}
        </SDSButton>
      </div>
    );
  }

  render() {
    return (
      <EditorTopBar>
        <div className={style.menuContent}>
          {this.renderSnapDetails()}
          {this.renderTabs()}
          {this.renderActions()}
        </div>
      </EditorTopBar>
    );
  }
}

export default intlConnect(mapStateToProps, null)(SnapLevelBar);
