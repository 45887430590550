import log from 'loglevel';
import React from 'react';

import { getActiveComponent } from 'state/editor/selectors/componentsSelectors';
import { getActiveWholeSnapId } from 'state/editor/selectors/editorSelectors';

import { RichSnapComponentType } from 'config/constants';
import { State } from 'src/types/rootState';
import { buildComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';

import NewInteractionEditor from 'views/editor/containers/NewInteractionEditor/NewInteractionEditor';
import TilePreviewEditor from 'views/editor/containers/TilePreviewEditor/TilePreviewEditor';
import TopsnapEditor from 'views/editor/containers/TopsnapEditor/TopsnapEditor';
import { getRichEditorConfigForSnapType } from 'views/editor/richEditorConfig';

import snapGhostIcon from 'images/snap-ghost.svg';

import style from './SnapEditorPanel.scss';

import { SnapId } from 'types/common';
import type { RichSnapComponent } from 'types/components';
import { isComponentSnap, isComponentTile } from 'types/components';
import { TileFlavor } from 'types/tiles';

function mapStateToProps(state: State) {
  return {
    activeSnapId: getActiveWholeSnapId(state),
    activeComponent: getActiveComponent(state),
  };
}

type Props = {
  activeComponent: RichSnapComponent;
  showNavigationControls: boolean | undefined | null;
  enableDynamicSizing: boolean | undefined | null;
  activeSnapId: SnapId;
};

const getTileEditorData = () => ({
  Editor: TilePreviewEditor,
  extraEditorProps: {
    newTileDefaultProperties: {
      tileFlavor: TileFlavor.DISCOVER_FEED_RECTANGULAR,
    },
  },
});
export class SnapEditorPanel extends React.PureComponent<Props> {
  render() {
    const { activeComponent, activeSnapId, showNavigationControls, enableDynamicSizing } = this.props;

    if (!activeComponent) {
      return (
        <div className={style.placeholder}>
          <div className={style.placeholderEditor}>
            <img src={snapGhostIcon} alt="ghost" />
          </div>
        </div>
      );
    }

    const { componentType } = activeComponent;
    let snapComponentId = activeComponent.componentId;

    let Editor;
    let extraEditorProps = {};

    switch (componentType) {
      case RichSnapComponentType.TILE: {
        // This should always be false but will ensure proper typing in a safe way.
        if (!isComponentTile(activeComponent)) {
          break;
        }
        ({ Editor, extraEditorProps } = getTileEditorData());
        break;
      }
      case RichSnapComponentType.TILE_PLACEHOLDER: {
        ({ Editor, extraEditorProps } = getTileEditorData());
        break;
      }
      case RichSnapComponentType.SNAP: {
        // This should always be false but will ensure proper typing in a safe way.
        if (!isComponentSnap(activeComponent)) {
          break;
        }
        const editorConfig = getRichEditorConfigForSnapType(activeComponent.snap.type);
        if (editorConfig) {
          Editor = editorConfig.editor;
        }
        break;
      }
      case RichSnapComponentType.SUBTITLES:
        // when showing the subtitles on the subscribe snap we need the topsnap editor
        snapComponentId = buildComponentId(RichSnapComponentType.SNAP, activeSnapId);
        Editor = TopsnapEditor;
        break;
      case RichSnapComponentType.SNAP_PLACEHOLDER:
        Editor = NewInteractionEditor;
        break;
      default:
        log.error(`Editor for ${componentType} is not defined`);
        Editor = null;
        break;
    }

    if (!Editor) {
      return null;
    }

    return (
      <Editor
        key={componentType}
        snapComponentId={snapComponentId}
        showNavigationControls={showNavigationControls}
        enableDynamicSizing={enableDynamicSizing}
        {...extraEditorProps}
      />
    );
  }
}

export default intlConnect(mapStateToProps, null)(SnapEditorPanel);
