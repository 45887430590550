import { get } from 'lodash';
import React from 'react';

import type { SnapNode, DomElement } from '../StoryCarousel/StoryCarouselTypes';

import DeletedSnapCarouselItem from 'views/publisherStoryEditor/containers/DeletedSnapCarouselItem/DeletedSnapCarouselItem';
import PopulatedSnapCarouselItem from 'views/publisherStoryEditor/containers/PopulatedSnapCarouselItem/PopulatedSnapCarouselItem';

import { SnapProblem } from 'types/build';
import type { BuildStatusType } from 'types/build';
import type { Snap } from 'types/snaps';

type Props = {
  snap: Snap;
  saveSnapDomElement: (b: SnapNode, a: DomElement) => void;
  style: any;
  className: string | undefined | null;
  snapNode: SnapNode;
  isPlaceholder: boolean;
  isAnySnapBeingDragged: boolean;
  buildStatus: BuildStatusType | undefined | null;
};

export default class SnapCarouselItem extends React.PureComponent<Props> {
  render() {
    if (get(this.props.buildStatus, 'status') === SnapProblem.CONTENT_DELETED) {
      return <DeletedSnapCarouselItem {...this.props} />;
    }
    return <PopulatedSnapCarouselItem {...this.props} />;
  }
}
