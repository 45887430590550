import React from 'react';

import { toggleMediaLibraryDrawer } from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';

import { media } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';

type OwnProps = {
  disabled?: boolean;
};

type DispatchProps = {
  toggleMediaLibraryDrawer: typeof toggleMediaLibraryDrawer;
};

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = {
  toggleMediaLibraryDrawer,
};

export class MediaLibraryButton extends React.Component<Props> {
  render() {
    return (
      <SDSButton
        disabled={this.props.disabled}
        type={ButtonType.WHITE_ON_GREY}
        shape={ButtonShape.CIRCLE}
        inlineIcon={media}
        onClick={this.props.toggleMediaLibraryDrawer}
        data-test="mediaLibraryButton"
      />
    );
  }
}

export default intlConnect(null, mapDispatchToProps)(MediaLibraryButton);
