import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import { plus } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';

import style from './AttachmentSelection.scss';

type Props = {
  index: number;
  name: FormattedMessage.MessageDescriptor;
  icon: string;
  onClick: (...args: any[]) => any;
  intl: IntlShape;
};

// eslint-disable-next-line react/prefer-stateless-function
export const AttachmentSelection = (props: Props) => {
  const { name, icon } = props;
  return (
    <div className={style.container} onClick={props.onClick} data-test={`attachmentSelection.${name.id}`}>
      <div className={style.descriptionContainer}>
        <Icon inlineIcon={icon} className={style.imageContainer} />
        {/* @ts-expect-error should be fixed with v3 */}
        <span className={style.descriptionSpan}>{props.intl.formatMessage(name)}</span>
      </div>
      <SDSButton type={ButtonType.WHITE} shape={ButtonShape.CIRCLE} inlineIcon={plus} />
    </div>
  );
};

export default intlConnect(null, null)(AttachmentSelection);
