import { without } from 'lodash';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import * as adControlsStagesSelectors from '../selectors/adControlsStagesSelectors';

import { StageType } from 'config/constants';
import { GetState } from 'src/types/redux';
import * as adSnapIndexUtils from 'utils/adSnapIndexUtils';
import { isInfiniteAdsEnabled } from 'utils/publisherSettings/publisherSettingsUtils';

import { Claim } from 'types/permissions';

export const isValidFirstSlotAndSpacing = (
  adSnapIndexes: any,
  advancedAdsLatestFirstSlot: any,
  advancedAdsMinSpacing: any
) => {
  if (advancedAdsLatestFirstSlot > 0 && adSnapIndexes[0] > advancedAdsLatestFirstSlot) {
    return false;
  }

  for (let i = 0; i < adSnapIndexes.length - 1; i++) {
    if (adSnapIndexes[i + 1] - adSnapIndexes[i] < advancedAdsMinSpacing) {
      return false;
    }
  }

  return true;
};

export const isValidAdLayout = (adSnapIndexes: any, activePublisher: any, isSuperAdsPlacer: any) => {
  if (isSuperAdsPlacer) {
    return true;
  }

  const {
    advertisingEnabled,
    advancedAdsEnabled,
    advancedAdsMinSlots,
    advancedAdsMaxSlots,
    advancedAdsMinSpacing,
    advancedAdsLatestFirstSlot,
  } = activePublisher;

  if ((!advancedAdsEnabled && !isInfiniteAdsEnabled(activePublisher)) || !advertisingEnabled) {
    return false;
  }

  if (advancedAdsMinSlots > adSnapIndexes.length) {
    return false;
  }

  if (advancedAdsMaxSlots < adSnapIndexes.length) {
    return false;
  }

  return isValidFirstSlotAndSpacing(adSnapIndexes, advancedAdsLatestFirstSlot, advancedAdsMinSpacing);
};

export const isValidSASAdLayout = (adSnapIndexes: any, activePublisher: any, maxNumberOfAds: any) => {
  const { advancedAdsMinSpacing, advancedAdsLatestFirstSlot } = activePublisher;

  if (maxNumberOfAds !== adSnapIndexes.length) {
    return false;
  }

  return isValidFirstSlotAndSpacing(adSnapIndexes, advancedAdsLatestFirstSlot, advancedAdsMinSpacing);
};

const toggleAdEnabled = (adShouldBeEnabled: any, snapIndex: any, adSnapIndexes: any) => {
  let newAdIndexes;
  if (adShouldBeEnabled) {
    // Turn ad on
    newAdIndexes = adSnapIndexUtils.sanitizeAdSlotIndexes([...adSnapIndexes, snapIndex + 1]);
    newAdIndexes.sort((a, b) => a - b);
    newAdIndexes = newAdIndexes.filter((el, i, array) => {
      return array.indexOf(el) === i;
    });
  } else {
    // Turn ad off
    newAdIndexes = adSnapIndexUtils.sanitizeAdSlotIndexes(without(adSnapIndexes, snapIndex + 1));
  }

  return newAdIndexes;
};

export const maybeClearAdsStage = (activeEdition: any) => {
  return async (dispatch: any, getState: GetState) => {
    const adIndexStage = stagesSelectors.getData(getState())(activeEdition.id);
    if (adIndexStage) {
      await dispatch(editionsActions.clearInvalidAdLayoutNotification());
      return dispatch(stagesActions.discardData(activeEdition.id));
    }
    return Promise.resolve();
  };
};

export const toggleAdEnabledAndMaybeSave = (value: any, snapIndex: any) => {
  return async (dispatch: any, getState: GetState) => {
    const activeEdition = publisherStoryEditorSelectors.getActiveEdition(getState());
    const activePublisher = publishersSelectors.getActivePublisherDetails(getState());
    const isSuperAdsPlacer = userSelectors.hasClaimForActivePublisher(getState(), Claim.SUPER_AD_PLACER);
    const oldAdIndexes = adControlsStagesSelectors.getAdIndexes(getState());

    const adSnapIndexes = toggleAdEnabled(value, snapIndex, oldAdIndexes);
    if (activeEdition?.id) {
      await dispatch(stagesActions.updateProperties(activeEdition.id, { adSnapIndexes }));

      if (isValidAdLayout(adSnapIndexes, activePublisher, isSuperAdsPlacer)) {
        await dispatch(stagesActions.commitData(activeEdition.id, StageType.EDITION));
        return dispatch(maybeClearAdsStage(activeEdition));
      }
      return dispatch(editionsActions.notifyInvalidAdLayout());
    }

    return Promise.resolve();
  };
};
