import invariant from 'invariant';

import { setEditionPropertiesAndSave } from 'state/editions/actions/editionsActions';
import { uploadMediaAndFinalize } from 'state/media/actions/mediaActions';
import { showModal } from 'state/modals/actions/modalsActions';
import {
  getActiveEdition,
  getActiveEditionId,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { goToSnap } from 'state/router/actions/routerActions';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import { UploadPurpose } from 'config/constants';
import { ModalType } from 'utils/modalConfig';

import { createSubtitleFile } from 'views/common/components/SubtitlesPreview/subtitleParser';
import { getSubtitlesEditorState } from 'views/subtitles/state/selectors/subtitlesEditorSelectors';

import { AssetID } from 'types/assets';
import { SnapId } from 'types/common';
import { EditionID } from 'types/editionID';
import type { Dispatch, GetState } from 'types/redux';

export function reviewSubtitles() {
  return (dispatch: Dispatch, getState: GetState) => {
    const publisherId = getActivePublisherId(getState());
    const story = getActiveEdition(getState());
    invariant(story, 'Active story is not defined');
    const firstSnapId = story.snapIds[0];
    invariant(firstSnapId, 'Fist snap is not defined');

    return dispatch(
      goToSnap({
        publisherId,
        editionId: story.id,
        snapId: firstSnapId,
        overwriteHistory: false,
      })
    );
  };
}

export function manageSubtitles() {
  return (dispatch: Dispatch, getState: GetState) => {
    const publisherId = getActivePublisherId(getState());
    const storyId = getActiveEditionId(getState());
    return dispatch(
      showModal(ModalType.MANAGE_SUBTITLES, 'ManageSubtitles', {
        publisherId,
        editionId: storyId,
      })
    );
  };
}

type SaveSubtitlesParams = {
  assetId: AssetID;
  editionId: EditionID;
  subtitlesLanguage: string;
  purpose: UploadPurpose;
  componentId?: string;
  snapId?: SnapId;
};

export function saveSubtitles(subtitlesAssetId: AssetID, params: SaveSubtitlesParams) {
  return (dispatch: Dispatch, getState: GetState) => {
    const subtitlesEditorState = getSubtitlesEditorState(getState())(subtitlesAssetId);
    const subtitlesFragments = subtitlesEditorState?.subtitleFragments;
    invariant(subtitlesFragments, 'Subtitle fragments are not defined');
    const subtitlesFile = createSubtitleFile(subtitlesFragments);
    const newSubtitleFile = new Blob([subtitlesFile], { type: 'text/vtt' });
    return dispatch(
      uploadMediaAndFinalize({
        media: newSubtitleFile,
        fileType: 'text',
        params,
        setEditionPropertiesAndSave,
      })
    );
  };
}
