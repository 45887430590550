import { omit, difference, pick, remove, forOwn, get, values } from 'lodash'; // eslint-disable-line object-curly-newline
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'velo... Remove this comment to see the full error message
import animate from 'velocity-animate';

import { assertState, assertArg } from 'utils/assertionUtils';
import * as browserUtils from 'utils/browserUtils';

import type { SegmentNode, SnapNode, DomElement } from './StoryCarouselTypes';
import { getSegmentSizeStyleProperties, getSnapSizeStyleProperties } from './StoryCarouselUtils';

type DomNode = {
  domNode: DomElement;
};
type StyleProperties = {};
type ResizeOperation = {
  domNode: DomElement;
  from: StyleProperties;
  to: StyleProperties;
};
export const AnimationTypes = {
  DRAG: 'DRAG',
  UPDATE: 'UPDATE',
};
type AnimationType = typeof AnimationTypes[keyof typeof AnimationTypes];
type AnimationOptions = {
  disableAnimation: boolean;
  animationType: AnimationType;
};
type SegmentProperties = {
  numberOfSnaps: number;
  styleProperties: StyleProperties;
};
type Easing = string | number[];
type VelocityProperties = {};
// @ts-expect-error ts-migrate(2315) FIXME: Type 'NodeList' is not generic.
type ElementCallback = (elements: NodeList<HTMLElement>) => void;
type ProgressCallback = (
  // @ts-expect-error ts-migrate(2315) FIXME: Type 'NodeList' is not generic.
  elements: NodeList<HTMLElement>,
  percentComplete: number,
  timeRemaining: number,
  timeStart: number,
  tweenValue: number
) => void;
type VelocityOptions = {
  duration?: string | number;
  begin?: ElementCallback;
  complete?: ElementCallback;
  display?: string | boolean;
  delay?: number | boolean;
  mobileHA?: boolean;
  _cacheValues?: boolean;
  container?: unknown; // JQuery,,
  axis?: string;
  offset?: number;
  queue?: string | boolean;
  easing?: Easing;
  progress?: ProgressCallback;
  loop?: number | boolean;
};
type AnimationConfig = {
  segmentAnimationInState: {
    scaleX?: number;
    scaleY?: number;
  };
  segmentAnimationOutState: {
    maxWidth: number;
    minWidth: number;
    marginLeft: number;
    marginRight: number;
    paddingLeft: number;
    paddingRight?: number;
  };
  segmentAnimationOptions: VelocityOptions;
  snapAnimationInState: {};
  snapAnimationOutState: {
    maxWidth: number;
    minWidth: number;
    marginLeft: number;
    marginRight: number;
  };
  snapAnimationOptions: VelocityOptions;
};
/**
 * Perform StoryCarousel animations. Uses velocity-animate to animate segment and snap items.
 * Depends on the DOM elements being supplied from the carousel before doing any animation.
 *
 * Only one animation 'batch' can be happening at any one time, and the StoryCarousel is supposed to call
 * 'hasPendingAnimation' to know when *not* to change the segment nodes. An error will be thrown otherwise.
 *
 * There are currently two types of animations, DRAG and UPDATE. They have different animation parameters, including duration.
 */
export class StoryAnimationManager {
  static animationConfig: {
    [key in AnimationType]: AnimationConfig;
  } = {
    [AnimationTypes.DRAG]: {
      segmentAnimationInState: {
        // Intentionally not setting any size properties as these are calculated dynamically
      },
      segmentAnimationOutState: {
        maxWidth: 0,
        minWidth: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
      segmentAnimationOptions: {
        easing: 'linear',
        duration: 150,
      },
      snapAnimationInState: {
        // Intentionally not setting any size properties as these are calculated dynamically
      },
      snapAnimationOutState: {
        maxWidth: 0,
        minWidth: 0,
        marginLeft: 0,
        marginRight: 0,
      },
      snapAnimationOptions: {
        easing: 'linear',
        duration: 150,
      },
    },
    [AnimationTypes.UPDATE]: {
      segmentAnimationInState: {
        scaleX: 1,
        scaleY: 1,
        // Intentionally not setting any size properties as these are calculated dynamically
      },
      segmentAnimationOutState: {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: { segmentAnimationInState: {}... Remove this comment to see the full error message
        scaleX: 0,
        scaleY: 0,
        maxWidth: 0,
        minWidth: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
      segmentAnimationOptions: {
        easing: [0.175, 0.885, 0.32, 1],
        duration: 400,
      },
      snapAnimationInState: {
        // Intentionally not setting any size proeprties as these are calculated dynamically
      },
      snapAnimationOutState: {
        maxWidth: 0,
        minWidth: 0,
        marginLeft: 0,
        marginRight: 0,
      },
      snapAnimationOptions: {
        easing: [0.175, 0.885, 0.32, 1],
        duration: 400,
      },
    },
  };

  static create(onComplete: () => void): StoryAnimationManager {
    return new StoryAnimationManager(onComplete);
  }

  segmentKeyToDomElement: {
    [x: string]: DomNode;
  };

  snapKeyToDomElement: {
    [x: string]: DomNode;
  };

  onComplete: () => void;

  pendingSegmentsToRemove: {
    [x: string]: DomNode;
  };

  pendingSegmentsToResize: {
    [x: string]: ResizeOperation;
  };

  pendingSnapsToRemove: {
    [x: string]: DomNode;
  };

  pendingSnapsToAdd: {
    [x: string]: DomNode;
  };

  isAnimating: boolean;

  pendingSegmentDomNodes: string[];

  pendingSnapDomNodes: string[];

  currentSegmentProperties: {
    [x: string]: SegmentProperties;
  };

  animationOptions: AnimationOptions;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'pendingAnimationsByKey' has no initializ... Remove this comment to see the full error message
  pendingAnimationsByKey: {
    [x: string]: HTMLElement;
  };

  constructor(onComplete: () => void) {
    this.segmentKeyToDomElement = {};
    this.snapKeyToDomElement = {};
    this.onComplete = () => {
      browserUtils.requestAnimationFrame(() => {
        // Since this is triggered only in the next tick, we need to make sure we haven't started animating again
        if (!this.hasPendingAnimation()) {
          onComplete();
        }
      });
    };
    this.pendingSegmentsToRemove = {};
    this.pendingSegmentsToResize = {};
    this.pendingSnapsToRemove = {};
    this.pendingSnapsToAdd = {};
    this.isAnimating = false;
    this.pendingSegmentDomNodes = [];
    this.pendingSnapDomNodes = [];
    this.currentSegmentProperties = {};
    this.animationOptions = {
      disableAnimation: false,
      animationType: AnimationTypes.UPDATE,
    };
  }

  getMaxAnimationDuration(): number {
    return Math.max(
      ...values(StoryAnimationManager.animationConfig).map((config: AnimationConfig) =>
        Number(config.segmentAnimationOptions.duration || 0)
      )
    );
  }

  setSegmentNodes(segmentNodes: SegmentNode[], animationOptions: AnimationOptions) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(animationOptions.animationType).is.inValues(AnimationTypes);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(this.hasPendingAnimation()).is.equal(false);
    const segmentKeyToDomElement = {};
    const snapKeyToDomElement = {};
    const segmentProperties = {};
    segmentNodes.forEach(segmentNode => {
      // Key has to be string as it's used as object key and would be cast to string
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(segmentNode.key).is.string();
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      segmentKeyToDomElement[segmentNode.key] = this.segmentKeyToDomElement[segmentNode.key] || { domNode: null };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      segmentProperties[segmentNode.key] = { numberOfSnaps: segmentNode.snapNodes.length };
      segmentNode.snapNodes.forEach((snapNode: SnapNode) => {
        // Key has to be string as it's used as object key and would be cast to string
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        assertArg(snapNode.key).is.string();
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        snapKeyToDomElement[snapNode.key] = this.snapKeyToDomElement[snapNode.key] || { domNode: null };
      });
    });
    // Calculating new and old segment nodes
    const oldSegmentKeys: string[] = Object.keys(this.segmentKeyToDomElement);
    const newSegmentKeys: string[] = Object.keys(segmentKeyToDomElement);
    const segmentKeysToRemove = difference(oldSegmentKeys, newSegmentKeys);
    const segmentKeysToAdd = difference(newSegmentKeys, oldSegmentKeys);
    this.pendingSegmentDomNodes = segmentKeysToAdd;
    this.pendingSegmentsToRemove = pick(this.segmentKeyToDomElement, segmentKeysToRemove);
    // Calculating segment resizes
    const pendingSegmentsToResize = {};
    forOwn(segmentProperties, (props, key) => {
      // If previous size already matches the number of snaps, do nothing
      if (
        this.currentSegmentProperties[key] &&
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        (props as any).numberOfSnaps === this.currentSegmentProperties[key].numberOfSnaps
      ) {
        return;
      }
      // If segment did not exist previously, use the out state as the 'from'
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'StyleProperties | { maxWidth: number; minWid... Remove this comment to see the full error message
      const previousStyleProperties: SegmentProperties = get(
        this.currentSegmentProperties,
        [key, 'styleProperties'],
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'StyleProperties | { maxWidth: number; minWid... Remove this comment to see the full error message
        StoryAnimationManager.animationConfig[animationOptions.animationType].segmentAnimationOutState
      );
      this.currentSegmentProperties[key] = {
        numberOfSnaps: (props as any).numberOfSnaps,
        styleProperties: this._calculateSegmentStyleProperties(
          animationOptions.animationType,
          (props as any).numberOfSnaps
        ),
      };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      pendingSegmentsToResize[key] = {
        domNode: get(this.segmentKeyToDomElement, [key, 'domNode'], null),
        from: previousStyleProperties,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        to: this.currentSegmentProperties[key].styleProperties,
      };
    });
    this.pendingSegmentsToResize = pendingSegmentsToResize;
    // Calculating new and old snap nodes
    const oldSnapKeys = Object.keys(this.snapKeyToDomElement);
    const newSnapKeys = Object.keys(snapKeyToDomElement);
    const snapKeysToRemove = difference(oldSnapKeys, newSnapKeys);
    const snapKeysToAdd = difference(newSnapKeys, oldSnapKeys);
    this.pendingSnapDomNodes = snapKeysToAdd;
    this.pendingSnapsToRemove = pick(this.snapKeyToDomElement, snapKeysToRemove);
    this.pendingSnapsToAdd = pick(snapKeyToDomElement, snapKeysToAdd);
    this.animationOptions = animationOptions;
    this.segmentKeyToDomElement = segmentKeyToDomElement;
    this.snapKeyToDomElement = snapKeyToDomElement;
    this.pendingAnimationsByKey = {};
    this._flushPendingAnimations();
  }

  _calculateSegmentStyleProperties(animationType: AnimationType, size: number): StyleProperties {
    return {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      ...StoryAnimationManager.animationConfig[animationType].segmentAnimationInState,
      ...getSegmentSizeStyleProperties(size),
    };
  }

  hasPendingAnimation(): boolean {
    return this.isAnimating || this.pendingSegmentDomNodes.length !== 0 || this.pendingSnapDomNodes.length !== 0;
  }

  setSegmentElement(segment: SegmentNode, element: DomElement): void {
    if (this.segmentKeyToDomElement[segment.key]) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.segmentKeyToDomElement[segment.key].domNode = element;
      if (this.pendingSegmentsToResize[segment.key]) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        this.pendingSegmentsToResize[segment.key].domNode = element;
      }
      remove(this.pendingSegmentDomNodes, key => key === segment.key);
      this._flushPendingAnimations();
    }
  }

  setSnapElement(snap: SnapNode, element: DomElement): void {
    if (this.snapKeyToDomElement[snap.key]) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.snapKeyToDomElement[snap.key].domNode = element;
      remove(this.pendingSnapDomNodes, key => key === snap.key);
      this._flushPendingAnimations();
    }
  }

  _flushPendingAnimations() {
    if (this.pendingSegmentDomNodes.length === 0 && this.pendingSnapDomNodes.length === 0) {
      this._animateSegments(this.pendingSegmentsToRemove, this.pendingSegmentsToResize);
      this.pendingSegmentsToRemove = {};
      this.pendingSegmentsToResize = {};
      this._animateSnaps(this.pendingSnapsToRemove, this.pendingSnapsToAdd);
      this.pendingSnapsToRemove = {};
      this.pendingSnapsToAdd = {};
      if (!this.hasPendingAnimation()) {
        this.onComplete();
      }
    }
  }

  finishAllAnimations() {
    forOwn(this.pendingAnimationsByKey, domNode => this._animate(domNode, 'finish'));
  }

  _animateSegments(
    segmentsToRemove: {
      [x: string]: DomNode;
    },
    segmentsToResize: {
      [x: string]: ResizeOperation;
    }
  ) {
    const animationConfig = StoryAnimationManager.animationConfig[this.animationOptions.animationType];
    forOwn(segmentsToRemove, (segment, key) =>
      this._animateOneSegment(
        key,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
        segment.domNode,
        true,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
        this.currentSegmentProperties[key].styleProperties,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
        animationConfig.segmentAnimationOutState,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
        animationConfig.segmentAnimationOptions
      )
    );

    forOwn(segmentsToResize, (segment, key) =>
      this._animateOneSegment(
        key,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
        segment.domNode,
        false,
        segment.from,
        segment.to,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
        animationConfig.segmentAnimationOptions
      )
    );
  }

  _animateSnaps(
    snapsToRemove: {
      [x: string]: DomNode;
    },
    snapsToAdd: {
      [x: string]: DomNode;
    }
  ) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
    forOwn(snapsToRemove, (snap, key) => this._animateOneSnap(key, snap.domNode, true));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DomElement' is not assignable to... Remove this comment to see the full error message
    forOwn(snapsToAdd, (snap, key) => this._animateOneSnap(key, snap.domNode, false));
  }

  _animateOneSegment(
    key: string,
    domNode: HTMLElement,
    isRemoval: boolean,
    from: VelocityProperties,
    to: VelocityProperties,
    animationOptions: VelocityOptions
  ): void {
    this.isAnimating = true;
    this.pendingAnimationsByKey[key] = domNode;
    this._executeAnimation(key, domNode, from, to, animationOptions, isRemoval, this.animationOptions.disableAnimation);
  }

  _animateOneSnap(key: string, domNode: HTMLElement, isRemoval: boolean) {
    this.isAnimating = true;
    this.pendingAnimationsByKey[key] = domNode;
    const animationConfig = StoryAnimationManager.animationConfig[this.animationOptions.animationType];
    const snapInState = {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      ...animationConfig.snapAnimationInState,
      ...getSnapSizeStyleProperties(),
    };
    const animationBeginState = isRemoval
      ? snapInState
      : // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        animationConfig.snapAnimationOutState;
    const animationEndState = isRemoval
      ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        animationConfig.snapAnimationOutState
      : snapInState;

    this._executeAnimation(
      key,
      domNode,
      animationBeginState,
      animationEndState,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      animationConfig.snapAnimationOptions,
      isRemoval,
      this.animationOptions.disableAnimation || false
    );
  }

  _executeAnimation(
    key: string,
    domNode: HTMLElement,
    animationBeginState: VelocityProperties,
    animationEndState: VelocityProperties,
    animationOptions: VelocityOptions,
    isRemoval: boolean,
    disableAnimation: boolean
  ): void {
    // First setting the node to the 'from' state
    if (!disableAnimation) {
      this._animate(domNode, animationBeginState, {
        ...animationOptions,
      });
      this._animate(domNode, 'finish');
    }
    // Then setting the node to the 'to' state
    this._animate(domNode, animationEndState, {
      ...animationOptions,
      complete: this._completeElementAnimation.bind(this, key),
      display: isRemoval ? 'none' : undefined,
    });
    if (disableAnimation) {
      this._animate(domNode, 'finish');
    }
  }

  _completeElementAnimation(key: string): void {
    this.pendingAnimationsByKey = omit(this.pendingAnimationsByKey, key);
    if (Object.keys(this.pendingAnimationsByKey).length === 0) {
      this.isAnimating = false;
      this.onComplete();
    }
  }

  // Just a wrapper around velocity-animate to help mocking in unit tests
  _animate(
    // @ts-expect-error ts-migrate(2315) FIXME: Type 'NodeList' is not generic.
    elements: HTMLElement | NodeList<HTMLElement>,
    properties: string | VelocityProperties,
    options?: VelocityOptions
  ) {
    animate(elements, properties, options);
  }
}
export default StoryAnimationManager;
