// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import _, { memoize } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PollScreen } from 'config/constants';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './PollScreensPanel.scss';

import type { PollScreenEnum } from 'types/polls';

const SCREEN_LABELS = {
  [PollScreen.UNANSWERED]: (
    <FormattedMessage
      id="poll-editor-screen-unanswered"
      description="When Publisher create a poll, he can choose a screen to edit. This screen shows unanswered poll"
      defaultMessage="Unanswered"
    />
  ),
  [PollScreen.RESPONDED]: (
    <FormattedMessage
      id="poll-editor-screen-responded"
      description="When Publisher create a poll, he can choose a screen to edit. This screen shows responded poll"
      defaultMessage="Responded"
    />
  ),
  [PollScreen.ENDED]: (
    <FormattedMessage
      id="poll-editor-screen-ended"
      description="When Publisher create a poll, he can choose a screen to edit. This screen shows ended poll"
      defaultMessage="Ended"
    />
  ),
  [PollScreen.EXPLANATION]: (
    <FormattedMessage
      id="poll-editor-screen-explanation"
      description="When Publisher create a poll, he can choose a screen to edit. This screen shows explanation page"
      defaultMessage="Explanation"
    />
  ),
};

const BUTTON_TEXTS = {
  select: (
    <FormattedMessage id="poll-editor-screen-select" description="Select screen button" defaultMessage="Select" />
  ),
  selected: (
    <FormattedMessage id="poll-editor-screen-selected" description="Selected screen button" defaultMessage="Selected" />
  ),
};

const PANEL_TITLE = (
  <FormattedMessage
    id="poll-editor-screen-screens"
    description="Title of the panel with different poll screens"
    defaultMessage="Screens"
  />
);

export type OwnProps = {
  onScreenSelected: (screen: PollScreenEnum) => void;
  screensToDisplay: Array<PollScreenEnum>;
  selectedScreen: PollScreenEnum;
};

type Props = OwnProps & typeof PollScreensPanel.defaultProps;

export default class PollScreensPanel extends React.Component<Props> {
  static defaultProps = {
    selectedScreen: PollScreen.UNANSWERED,
    screensToDisplay: [],
  };

  getButtonForScreen(screen: PollScreenEnum) {
    const isDisabled = screen === this.props.selectedScreen;

    return (
      <SDSButton
        type={ButtonType.WHITE}
        onClick={this.handleClick(screen)}
        disabled={isDisabled}
        data-test="editor.PollScreensPanel.selectedScreen"
      >
        {isDisabled ? BUTTON_TEXTS.selected : BUTTON_TEXTS.select}
      </SDSButton>
    );
  }

  getFields() {
    return _.map(this.props.screensToDisplay, screen => ({
      id: screen,
      key: screen,
      label: SCREEN_LABELS[screen],
      multiline: false,
      control: this.getButtonForScreen(screen),
    }));
  }

  handleClick = memoize(selectedScreen => () => {
    this.props.onScreenSelected(selectedScreen);
  });

  render() {
    return (
      <PropertyPanel
        className={style.root}
        fields={this.getFields()}
        title={PANEL_TITLE}
        isReadOnly={false}
        showBottomLine
      />
    );
  }
}
