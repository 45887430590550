import is from 'is_js';
import jQuery from 'jquery';

export const MASKED_SRC = 'data-sc-masked-src';

export function maskAndParse(html: string) {
  if (is.not.string(html)) {
    throw new Error('maskAndParse() expects an HTML string');
  }

  return jQuery(`<div>${maskSources(html)}</div>`);
}

interface HTMLable {
  html(): string;
}

export function unmaskAndStringify(jqueryObject?: HTMLable | null) {
  if (!jqueryObject || is.not.function(jqueryObject.html)) {
    throw new Error('unmaskAndStringify() expects a jqueryObject');
  }

  return unmaskSources(jqueryObject.html());
}

// Prevents sources from being loaded while we're using jQuery to parse/manipulate HTML.
// Needs to add a space before 'src=' because some urls include 'src='
export function maskSources(html: string) {
  return html.replace(/ src=/g, ` ${MASKED_SRC}=`);
}

export function unmaskSources(html: string) {
  return html.replace(new RegExp(` ${MASKED_SRC}=`, 'g'), ' src=');
}
