import invariant from 'invariant';
import is from 'is_js';
import React from 'react';

import * as assetActions from 'state/asset/actions/assetActions';
import { getAuthType } from 'state/auth/selectors/authSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import { loadSnapPreviewIfMissing } from 'state/previews/actions/previewsActions';
import { setLastVisitedSnapPublisherUrl } from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { loadSnap, setSnapPropertiesAndSave } from 'state/snaps/actions/snapsActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import {
  AuthType,
  SELF_SERVE_CREATIVE_SUITE_GOOGLE_HOSTNAME,
  SELF_SERVE_CREATIVE_SUITE_SNAP_HOSTNAME,
  SNAP_PUBLISHER_PUBLISH_MESSAGE_NAME,
  EDITOR_MODES,
} from 'config/constants';
import type { CropPositionEnum } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { snapPublisherEmbedUrl } from 'utils/snapPublisherUtils';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';
import { SnapType } from 'types/snaps';
import type { CreativeID } from 'types/snaps';

type SnapPublisherOptions = {
  snapId: SnapId;
  creativeId: CreativeID;
  webImportUrl: string | undefined | null;
  assetId: AssetID;
  advancedMode?: boolean;
  trimMode?: boolean;
  layerAssetId?: AssetID;
};

type StateProps = {
  modalId: string;
  hideModal: (modalId: string, results: {}) => void;
  editionId: EditionID;
  publisherId: PublisherID;
  options: SnapPublisherOptions;
  embedUrl: string;
  isSnapSSO: boolean;
  isCuratedLayerEnabled: boolean;
};

const mapStateToProps = (state: State, props: StateProps) => {
  const editionId = publisherStoryEditorSelectors.getActiveEditionId(state);
  const publisherId = userSelectors.getActivePublisherId(state);
  const authType = getAuthType(state);
  const isSnapSSO = authType === AuthType.SNAPCHAT;
  const { creativeId } = props.options;
  const sourceContentUrl = props.options.webImportUrl;
  const { assetId } = props.options;
  const { advancedMode } = props.options;
  const { trimMode } = props.options;
  const { layerAssetId } = props.options;
  const { snapId } = props.options;

  invariant(editionId, 'editionId must not be null');

  const embedUrl = snapPublisherEmbedUrl({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    publisherId,
    editionId,
    snapId,
    creativeId,
    isSnapSSO,
    sourceContentUrl,
    assetId,
    advancedMode,
    trimMode,
    layerAssetId,
  });

  return {
    key: embedUrl,
    editionId,
    publisherId,
    embedUrl,
    isSnapSSO,
    isCuratedLayerEnabled:
      featuresSelectors.isCuratedLayerEnabled(state) || featuresSelectors.isAdvancedCurationEnabled(state),
  };
};

const mapDispatchToProps = {
  setLastVisitedSnapPublisherUrl,
  setSnapPropertiesAndSave,
  setEditorMode: publisherStoryEditorModeActions.setEditorMode,
  loadAssetInfoUntilResolved: assetActions.loadAssetInfoUntilResolved,
  loadSnap,
  loadSnapPreviewIfMissing,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;

type MessageData = {
  assetId: AssetID;
  creativeId: number;
  docking?: CropPositionEnum;
  snapPublisherCreationMetadataEnvelope: string | undefined | null;
  snapPublisherCreationMetadataEnvelopeVersion: number | undefined | null;
};

type Message = {
  data: string;
  origin: any;
};

type getValidSnapPublisherMessageDataType = (message: Message, props: Props) => MessageData | null;

const getValidSnapPublisherMessageData: getValidSnapPublisherMessageDataType = (message, props) => {
  const creativeSuiteHostname = props.isSnapSSO
    ? SELF_SERVE_CREATIVE_SUITE_SNAP_HOSTNAME
    : SELF_SERVE_CREATIVE_SUITE_GOOGLE_HOSTNAME;
  if (message.origin && message.origin.includes(creativeSuiteHostname)) {
    const data = JSON.parse(message.data);
    const { type, publisherId, editionId } = data;

    const { publisherId: activePublisherId, editionId: activeEditionId } = props;

    if (
      type === SNAP_PUBLISHER_PUBLISH_MESSAGE_NAME &&
      parseInt(publisherId, 10) === activePublisherId &&
      parseInt(editionId, 10) === activeEditionId
    ) {
      return data;
    }
  }

  return null;
};

export class SnapPublisher extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.setUpPostMessage();
  }

  componentDidMount() {
    this.props.setLastVisitedSnapPublisherUrl(this.props.embedUrl);
  }

  shouldComponentUpdate = () => false;

  componentWillUnmount() {
    this.tearDownPostMessage();
  }

  setUpPostMessage() {
    window.addEventListener('message', this.handleSnapPublisherMessage, false);
  }

  tearDownPostMessage() {
    window.removeEventListener('message', this.handleSnapPublisherMessage, false);
  }

  hideModal = () => {
    this.props.hideModal(this.props.modalId, {});
  };

  handleSnapPublisherMessage: (message: Message) => void = message => {
    const validMessageData = getValidSnapPublisherMessageData(message, this.props);

    if (validMessageData) {
      const {
        assetId,
        creativeId,
        docking,
        snapPublisherCreationMetadataEnvelope,
        snapPublisherCreationMetadataEnvelopeVersion,
      } = validMessageData;

      if (assetId && creativeId) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        this.props.setEditorMode(EDITOR_MODES.EDITOR);

        let envelopes;
        if (snapPublisherCreationMetadataEnvelope && snapPublisherCreationMetadataEnvelopeVersion) {
          envelopes = [
            {
              envelope: snapPublisherCreationMetadataEnvelope,
              envelopeVersion: snapPublisherCreationMetadataEnvelopeVersion,
            },
          ];
        }

        const properties = {
          assetId,
          type: SnapType.UNKNOWN,
          creativeId,

          ...(docking ? { cropPosition: docking } : {}),
          // Once user click edit button, we will remove the overlay, because right now
          // Snap publisher only return us a single asset id.
          ...(is.truthy(this.props.isCuratedLayerEnabled) && { overlayImageAssetId: '' }),
          ...(envelopes ? { envelopes } : {}),
        };

        this.props.setSnapPropertiesAndSave(
          {
            snapId: this.props.options.snapId,
          },
          properties
        );

        const callback = () => {
          const snapId = this.props.options.snapId;
          this.props.loadSnap({ snapId }).then(() => this.props.loadSnapPreviewIfMissing(snapId));
        };

        this.props.loadAssetInfoUntilResolved(assetId, 20, callback);
      }
    }
  };

  render() {
    return (
      <iframe
        src={this.props.embedUrl}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
        seamless="seamless"
        frameBorder="0"
        width="100%"
        height="100%"
      />
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapPublisher);
