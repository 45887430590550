import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import style from './LineView.scss';

type Props = {
  className: string | undefined | null;
};

const LineView = ({ className }: Props): ReactNode => <div className={classNames(className, style.line)} />;

export default LineView;
