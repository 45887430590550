import React from 'react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import style from '../StoryBars.scss';

import { help } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isStoryPromoted } from 'utils/payToPromoteUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import MutePreviewButton from 'views/common/containers/MutePreviewButton/MutePreviewButton';
import PayToPromoteButton from 'views/payToPromote/components/PayToPromoteButton/PayToPromoteButton';
import withProfileAdAccountsQuery from 'views/payToPromote/hocs/withProfileAdAccountsQuery/withProfileAdAccountsQuery';
import { ProfileAdAccountsQueryResult } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import MediaLibraryButton from 'views/publisherStoryEditor/components/MediaLibraryButton/MediaLibraryButton';
import PublishStoryButton from 'views/publisherStoryEditor/components/PublishStoryButton/PublishStoryButton';
import SnapcodePreviewButton from 'views/publisherStoryEditor/components/SnapcodePreviewButton/SnapcodePreviewButton';
import StoryStateOverview from 'views/publisherStoryEditor/components/StoryStateOverview/StoryStateOverview';
import StoryLevelMessage from 'views/publisherStoryEditor/containers/StoryLevelMessage/StoryLevelMessage';
import StoryOptionsButton from 'views/publisherStoryEditor/containers/StoryOptionsButton/StoryOptionsButton';
import StorySubtitleButton from 'views/publisherStoryEditor/containers/StorySubtitleButton/StorySubtitleButton';

import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';

type StateProps = {
  activeStory: Edition;
  hasAddedPublisherDetails: boolean;
  isSingleAssetStoryEditor: boolean;
  openPublisherDetailsModal: typeof publishersActions.openPublisherDetailsModal;
};
const mapStateToProps = (state: State) => {
  const activeStory = publisherStoryEditorSelectors.getActiveEdition(state);
  return {
    activeStory,
    hasAddedPublisherDetails: publishersSelectors.activePublisherHasAddedRequiredDetails(state),
    isSingleAssetStoryEditor: activeStory?.id
      ? publisherStoryEditorSelectors.shouldUseSingleAssetEditor(state)(activeStory?.id)
      : false,
  };
};
const mapDispatchToProps = {
  openPublisherDetailsModal: publishersActions.openPublisherDetailsModal,
};
type OwnProps = ProfileAdAccountsQueryResult;
type Props = OwnProps & StateProps;
export class DraftStoryBar extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, undefined, nextProps);
  }

  handlePublisherDetailsClick = () => {
    (this.props.openPublisherDetailsModal('DraftStoryBar') as any).catch(() => {});
  };

  render() {
    const showMediaLibraryButton = !this.props.isSingleAssetStoryEditor;
    if (!this.props.activeStory) {
      return null;
    }
    let addPublisherDetails = null;
    if (!this.props.hasAddedPublisherDetails) {
      addPublisherDetails = (
        <SDSButton
          type={ButtonType.WHITE_ON_GREY}
          inlineIcon={help}
          onClick={this.handlePublisherDetailsClick}
          data-test="publisherStoryEditor.storyBuilder.draftStory.bar.pubDetails.button"
        >
          {getMessageFromId('publisher-details-button')}
        </SDSButton>
      );
    }
    return (
      <div className={style.root}>
        <div className={style.leftBar}>
          <StoryStateOverview
            storyState={StoryState.NEW}
            isStoryPromoted={isStoryPromoted(this.props.activeStory.id, this.props.promotedStoriesIds)}
          />
        </div>
        <div className={style.middleBar}>{addPublisherDetails || <StoryLevelMessage />}</div>
        <div className={style.rightBar}>
          <PayToPromoteButton
            buttonType={ButtonType.WHITE_ON_GREY}
            storyId={this.props.activeStory.id}
            error={this.props.profileAdAccountsError}
            adAccountId={this.props.adAccountId}
          />
          <PublishStoryButton storyId={this.props.activeStory.id} />
          {!this.props.isSingleAssetStoryEditor && <StorySubtitleButton editionId={this.props.activeStory.id} />}
          <SnapcodePreviewButton />
          {showMediaLibraryButton && <MediaLibraryButton />}
          <MutePreviewButton />
          <StoryOptionsButton />
        </div>
      </div>
    );
  }
}
export default withProfileAdAccountsQuery(intlConnect(mapStateToProps, mapDispatchToProps)(DraftStoryBar));
