import classNames from 'classnames';
import React from 'react';

import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { getTileIdOrNull } from 'state/tiles/schema/tilesEntityHelpers';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import ImageCroppie from '../ImageCroppie/ImageCroppie';

import { BuildType } from 'config/constants';
import { TileCropType } from 'src/config/tileConfig';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import { createAssetUrl } from 'utils/media/assetUtils';

import style from './CropTile.scss';

import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

export const mapStateToProps = (state: State) => {
  const editionId = editorSelectors.getActiveEditionId(state);
  const segmentId = editorSelectors.getActiveSegmentReduxId(state);
  const snapId = editorSelectors.getActiveWholeSnapId(state);
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const tile = componentsSelectors.getActiveComponent(state).tile;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
  const pendingTile = editorSelectors.getPendingTileById(state)(getTileIdOrNull(tile));
  const publisherId = getActivePublisherId(state);
  return {
    editionId,
    segmentId,
    snapId,
    tile,
    pendingTile,
    publisherId,
    isReadOnly: editorSelectors.isReadOnly(state),
  };
};
const mapDispatchToProps = {
  setTileCroppingAndSave: editorActions.setTileCroppingAndSave,
  saveCurrentVerticalCropTile: editorActions.saveCurrentVerticalCropTile,
  setEditorConfigTileProperties: editorActions.setEditorConfigTileProperties,
  removeEditorConfigTileProperties: editorActions.removeEditorConfigTileProperties,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type OwnProps = {
  uploadPurpose?: string;
  'data-test'?: string;
  isDynamicSize: boolean;
  tileCropType: TileCropType;
  isCircle: boolean;
};
type Props = StateProps & DispatchProps & OwnProps;

export class CropTile extends React.Component<Props> {
  onSave = () => {
    const { tile, editionId, segmentId, snapId, uploadPurpose } = this.props;
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-crop-save', { source: 'CropImage' });
    // For Cheetah we want to save Vertical Crop.
    return (this.props as any).saveCurrentVerticalCropTile(
      {
        editionId,
        snapId,
        segmentId,
      },
      tile,
      uploadPurpose
    );
  };

  tileAssetUrl() {
    return createAssetUrl(this.props.tile.baseImageAssetId, {
      buildTypeId: BuildType.IMAGE_PREVIEW,
      buildSettingId: 'baseline',
    });
  }

  render() {
    const { tile } = this.props;
    const tileId = getTileIdOrNull(tile);
    if (!tileId) {
      return null;
    }
    return (
      <div
        className={classNames(style.root, { [style.readOnly]: this.props.isReadOnly })}
        data-test={this.props['data-test']}
      >
        <ImageCroppie
          imageId={tileId}
          image={this.props.tile}
          imageURL={this.tileAssetUrl()}
          pendingImage={this.props.pendingTile}
          isReadOnly={this.props.isReadOnly}
          tileCropType={this.props.tileCropType}
          isCircle={this.props.isCircle}
          onUpdate={this.props.setEditorConfigTileProperties}
          onSave={this.onSave}
          onDiscard={this.props.removeEditorConfigTileProperties}
          isDynamicSize={this.props.isDynamicSize}
        />
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(CropTile);
