import React from 'react';

import { getActivePublisherDefaultSubtitlesLanguage } from 'state/publishers/selectors/publishersSelectors';
import { manageSubtitles, reviewSubtitles } from 'state/subtitles/actions/manageSubtitlesActions';

import { subtitles } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import Icon from 'views/common/components/Icon/Icon';
import InfoBox from 'views/common/components/InfoBox/InfoBox';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import * as messages from './ManageSubtitlesInfoBox.messages';
import style from './ManageSubtitlesInfoBox.scss';

import { ExtractDispatchProps } from 'types/redux';

type StateProps = {
  defaultSubtitlesLanguage: string;
};
function mapStateToProps(state: State): StateProps {
  return {
    defaultSubtitlesLanguage: getActivePublisherDefaultSubtitlesLanguage(state),
  };
}
const mapDispatchToProps = {
  manageSubtitles,
  reviewSubtitles,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps & StateProps;
function BoxTitle() {
  return (
    <span className={style.title}>
      <Icon inlineIcon={subtitles} />
      {messages.title}
    </span>
  );
}
export class ManageSubtitlesInfoBox extends React.PureComponent<Props> {
  componentDidMount() {
    grapheneUtils.incrementCounter('subtitles', {
      show: 'manage_subtitles',
      language: this.props.defaultSubtitlesLanguage,
    });
  }

  handleManageSubtitlesClick = () => {
    this.props.manageSubtitles();
  };

  handleReviewSubtitlesClick = () => {
    this.props.reviewSubtitles();
  };

  render() {
    return (
      <div className={(style as any).container}>
        <InfoBox title={<BoxTitle />} data-test="ManageSubtitlesInfoBox" className={style.infoBox}>
          <div className={style.description}>{messages.description}</div>
          <div className={style.buttonsContainer}>
            <SDSButton
              type={ButtonType.LINK}
              onClick={this.handleManageSubtitlesClick}
              metricName="subtitles"
              metricDimensions={{
                click: 'manage_subtitles',
                language: this.props.defaultSubtitlesLanguage,
              }}
              data-test="ManageSubtitlesInfoBox.manageSubtitles"
            >
              {messages.buttonManageSubtitles}
            </SDSButton>
            <SDSButton
              type={ButtonType.LINK}
              onClick={this.handleReviewSubtitlesClick}
              metricName="subtitles"
              metricDimensions={{
                click: 'review_subtitles',
                language: this.props.defaultSubtitlesLanguage,
              }}
              data-test="ManageSubtitlesInfoBox.reviewSubtitles"
            >
              {messages.buttonReviewSubtitles}
            </SDSButton>
          </div>
        </InfoBox>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(ManageSubtitlesInfoBox);
