import { noop } from 'lodash';
import * as React from 'react';
import { DragLayer } from 'react-dnd';

import SegmentCarouselItem from '../SegmentCarouselItem/SegmentCarouselItem';
import type { SourceItem, SegmentNode } from '../StoryCarousel/StoryCarouselTypes';
import { getSegmentSizeStyleProperties, getSnapSizeStyleProperties } from '../StoryCarousel/StoryCarouselUtils';

import { DragTypes } from 'config/constants';

import style from './StoryCarouselDragLayer.scss';

type Props = {
  item: SourceItem;
  itemType: string;
  currentOffset: {
    x: number;
    y: number;
  };
  isDragging: boolean;
};

export class StoryCarouselDragLayer extends React.Component<Props> {
  getItemStyles(): any {
    const { currentOffset } = this.props;
    if (!currentOffset) {
      return { display: 'none' };
    }

    const { x, y } = currentOffset;
    return { transform: `translate(${x}px, ${y - 40}px)` };
  }

  renderItem(segment: SegmentNode): React.ReactNode {
    return (
      <SegmentCarouselItem
        style={getSegmentSizeStyleProperties(segment.snapNodes.length)}
        snapStyle={getSnapSizeStyleProperties()}
        segment={segment}
        isPlaceholder={false}
        isAnySnapBeingDragged
        saveSegmentDomElement={noop}
        saveSnapDomElement={noop}
      />
    );
  }

  render(): React.ReactNode {
    const { item, itemType, isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    let { segment } = item;

    if (![DragTypes.STORY_SEGMENT, DragTypes.STORY_SNAP].includes(itemType)) {
      return null;
    }

    // If it's a snap being dragged, let's wrap it into a placeholder SegmentNode
    if (itemType === DragTypes.STORY_SNAP && item.snap) {
      segment = {
        id: 'none',
        key: 'none',
        isVirtualSegment: true,
        index: 0,
        snapNodes: [item.snap],
      };
    }

    if (!segment) {
      return null;
    }

    return (
      <div className={style.dragLayer} style={this.getItemStyles()}>
        {this.renderItem(segment)}
      </div>
    );
  }
}

export default DragLayer(monitor => ({
  // eslint-disable-line new-cap
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof StoryCarouselDragLayer' i... Remove this comment to see the full error message
}))(StoryCarouselDragLayer);
