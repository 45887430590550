import * as React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getActiveWholeSnapId } from 'state/editor/selectors/editorSelectors';
import {
  getActiveEdition,
  shouldUseSingleAssetEditor,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import BlankPageWithText from 'views/dashboard/components/BlankPageWithText/BlankPageWithText';
import SimpleStoryBuilder from 'views/dashboard/containers/SimpleStoryBuilder/SimpleStoryBuilder';
import MultiStorySnapEditor from 'views/multiStorySnapEditor/MultiStorySnapEditor/MultiStorySnapEditor';
import SingleAssetStoryEditor from 'views/singleAssetStoryEditor/containers/SingleAssetStoryEditor/SingleAssetStoryEditor';

import type { SnapId } from 'types/common';
import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';
import { PostingStoryType } from 'types/storySnapEditor';

const mapStateToProps = (state: State) => {
  const story = getActiveEdition(state);
  return {
    isSingleAssetStoryEditor: story?.id ? shouldUseSingleAssetEditor(state)(story?.id) : false,
    snapId: getActiveWholeSnapId(state),
    story,
  };
};

type Props = {
  children?: ReactNode;
  isSingleAssetStoryEditor: boolean;
  snapId: SnapId | undefined | null;
  story?: Edition;
};

export const StoryEditorSwitch = (props: Props) => {
  // A story has a posting story type which dictates what editor to open (this will be until all experiences are unified)
  // A single asset story (regardless of type) is open with the SingleAssetStoryEditor
  // A Public Story is open with the new MultiStorySnapEditor
  // All other stories are opened with SimpleStoryBuilder and will be migrated to MultiStorySnapEditor
  const StoryEditor =
    props.story?.postingStoryType === PostingStoryType.PUBLIC_STORY ? MultiStorySnapEditor : SimpleStoryBuilder;

  const ComponentToRender = props.isSingleAssetStoryEditor ? SingleAssetStoryEditor : StoryEditor;

  if (!props.story) {
    return null;
  }

  if (props.story.state === StoryState.DELETED) {
    return (
      <BlankPageWithText
        message={
          <FormattedMessage
            id="story-has-been-deleted-message"
            description="Message shown on the page of a deleted story"
            defaultMessage="This story has been deleted."
          />
        }
      />
    );
  }

  return (
    <ComponentToRender storyId={props.story.id} publisherId={props.story.publisherId} snapId={props.snapId}>
      {props.children}
    </ComponentToRender>
  );
};

// @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1.
export default withRouter(intlConnect(mapStateToProps)(StoryEditorSwitch));
