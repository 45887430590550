import { get, noop, some, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getDiscoverSnapBuildStatus, getStoryScoreById } from 'state/buildStatus/selectors/buildStatusSelectors';
import {
  makeStoriesAvailable,
  makeStoriesUnavailable,
  setLiveEditionLock,
} from 'state/editions/actions/editionsActions';
import {
  getStoryScheduleStatus,
  getEditionById,
  getEditionSavingById,
  getEditionSubscribeSnap,
  getVendingTilesByEditionId,
} from 'state/editions/selectors/editionsSelectors';
import { updateSingleAssetEditorState } from 'state/editor/actions/editorActions';
import { areSnapsUploadingOrLockedByATransaction } from 'state/editor/selectors/editorSelectors';
import { isHostUserRequired, isPublishingPaused } from 'state/features/selectors/featuresSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import {
  getStoryStatusMessageForStoryId,
  shouldShowGenerateSubtitlesPrompt,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { isDynamicEditionsPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { getSingleAssetIsEditingAds } from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import { hasOngoingTransactionsByStoryId } from 'state/transactions/selectors/transactionsSelectors';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { StoryStatus } from 'config/constants';
import type { StoryStatusEnum } from 'config/constants';
import { megaphone } from 'icons/SDS/allIcons';
import { Tile } from 'src/types/tiles';
import greyButtonStyle from 'styles/greyButton.scss';
import primaryButtonStyle from 'styles/primaryButton.scss';
import { stopEventPropagation } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { ModalType } from 'utils/modalConfig';
import { executePromisesWhileTrue } from 'utils/promiseUtils';
import { getProgressColor } from 'utils/publisherStoryEditor/storyScoreUtils';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSProgressBar, {
  ProgressBarSize,
  ProgressBarType,
} from 'views/common/components/SDSProgressBar/SDSProgressBar';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import HomepageButton, { HomepageButtonTypes } from 'views/homepage/components/HomepageButton/HomepageButton';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import StoryScoreTooltip from 'views/publisherStoryEditor/components/StoryScoreTooltip/StoryScoreTooltip';

import style from './PublishStoryButton.scss';

import { SnapProblem, ComputedStoryScore } from 'types/build';
import { StoryState, LiveEditStatus } from 'types/editions';
import type { EditionID } from 'types/editions';
import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';

const submitHappeningNowBreakingNewsStoryMsg = (
  <FormattedMessage
    id="submit-hn-breaking-news-story-button-text"
    defaultMessage="Submit breaking news story"
    description="[Button] submit Happening Now breaking news story button"
  />
);
type OwnProps = {
  storyId: EditionID;
  isHomepageButton: boolean;
  scheduleImmediately: boolean;
  isHnBreakingNews?: boolean;
  onClick: (e: Event) => void;
};
type StateProps = {
  isDynamicEditionsPublisher: boolean;
  isReadOnlyOrSaving: boolean;
  shouldShowGenerateSubtitlesPrompt: boolean;
  storyState: StoryState;
  storyStatus: StoryStatusEnum;
  storyStatusMessage: ReactNode;
  publishingEnabled: boolean;
  publishingPaused: boolean;
  isHostUserNonBlocking: boolean;
  showModal: typeof showModal;
  makeStoriesUnavailable: typeof makeStoriesUnavailable;
  subscribeSnapIsIncomplete: boolean;
  storyHasOngoingTransactions: boolean;
  editionTiles: Tile[];
  storyScore: ComputedStoryScore;
  isEditingAds: boolean;
};
type PublishButtonConfig = {
  buttonTitle: ReactNode;
  onClick: (e: Event) => void;
  style: string;
};
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const publisher = getActivePublisherDetails(state);
  const story = getEditionById(state)(ownProps.storyId);
  const storyScore = getStoryScoreById(state)(ownProps.storyId);
  const isStoryPublisher = hasClaimForActivePublisher(state, Claim.STORY_PUBLISHER);
  const isStorySaving = getEditionSavingById(state)(ownProps.storyId);
  const areSnapsUploadingOrHaveTransactions = areSnapsUploadingOrLockedByATransaction(state)(ownProps.storyId);
  const subscribeSnapId =
    !ownProps.isHomepageButton && getEditionSubscribeSnap(state)(ownProps.storyId)?.relatedSnapIds?.TOP;
  const subscribeSnapBuildStatus =
    subscribeSnapId && getDiscoverSnapBuildStatus(state)(subscribeSnapId)?.extendedStatus;
  const subscribeSnapIsIncomplete = subscribeSnapBuildStatus === SnapProblem.INCOMPLETE;
  const storyHasOngoingTransactions = hasOngoingTransactionsByStoryId(state)(ownProps.storyId);

  const isHostUserNonBlocking = !isHostUserRequired(state) || Boolean(get(publisher, 'hostUsername', ''));
  const storyStatusDefaultMessage = getMessageFromId('story-status-publishable');

  return {
    shouldShowGenerateSubtitlesPrompt: shouldShowGenerateSubtitlesPrompt(state)(ownProps.storyId),
    storyStatus: getStoryScheduleStatus(state)(ownProps.storyId),
    storyState: get(story, 'state'),
    storyStatusMessage: getStoryStatusMessageForStoryId(state)({
      storyId: ownProps.storyId,
      defaultMessage: isHostUserNonBlocking
        ? storyStatusDefaultMessage
        : getMessageFromId('publishing-disabled-host-user'),
    }),
    publishingEnabled: get(publisher, 'publishingEnabled', true),
    publishingPaused: isPublishingPaused(state),
    isHostUserNonBlocking,
    isDynamicEditionsPublisher: isDynamicEditionsPublisher(publisher),
    isReadOnlyOrSaving: !isStoryPublisher || isStorySaving || areSnapsUploadingOrHaveTransactions,
    subscribeSnapIsIncomplete,
    storyHasOngoingTransactions,
    editionTiles: getVendingTilesByEditionId(state)(ownProps.storyId),
    storyScore,
    isEditingAds: getSingleAssetIsEditingAds(state)(ownProps.storyId),
  };
};
const mapDispatchToProps = {
  makeStoriesAvailable,
  makeStoriesUnavailable,
  setLiveEditionLock,
  showModal,
  updateSingleAssetEditorState,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;
export class PublishStoryButton extends React.Component<Props> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, undefined, nextProps);
  }

  makeStoriesAvailable = () => {
    this.props.makeStoriesAvailable(
      [this.props.storyId],
      this.props.isHomepageButton,
      this.props.scheduleImmediately,
      this.props.isHnBreakingNews
    );
  };

  showSubtitlesGenerationPrompt = () => {
    return this.props.showModal(ModalType.GENERATE_SUBTITLES, 'GenerateSubtitlesConfirm', {
      storyId: this.props.storyId,
    });
  };

  tryShowSubtitlesGenerationPrompt = (): Promise<boolean> => {
    if (this.props.shouldShowGenerateSubtitlesPrompt && !this.props.isHomepageButton) {
      grapheneUtils.incrementCounter('ScheduleButton.openTranslateStoryPrompt');
      return (this.showSubtitlesGenerationPrompt() as any).then((result: any) => !result.confirmed);
    }
    return Promise.resolve(true);
  };

  openScheduleModal = (): Promise<boolean> => {
    grapheneUtils.incrementCounter('ScheduleButton.openMakeStoriesAvailableModal');
    this.makeStoriesAvailable();
    return Promise.resolve(true);
  };

  publishStory = (e: Event) => {
    if (this.props.isEditingAds) {
      this.props.updateSingleAssetEditorState(this.props.storyId, {
        isEditingAds: false,
      });
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    } else {
      grapheneUtils.incrementCounter('ScheduleButton.click');
      // There is a list of the promises which return a boolean.
      // If one of the promises in the chain returns false, the others will not be executed
      executePromisesWhileTrue([this.tryShowSubtitlesGenerationPrompt, this.openScheduleModal]);
    }
  };

  editStory = () => {
    this.props.makeStoriesUnavailable([this.props.storyId]);
  };

  editLiveStory = () => {
    this.props.setLiveEditionLock({
      editionId: this.props.storyId,
      nextStatus: LiveEditStatus.IN_PROGRESS,
      newsUpdate: false,
    });
  };

  editionContainsUncroppedTiles = () => some(this.props.editionTiles, tile => isEmpty(tile.croppedImageAssetId));

  canBePublished = () =>
    this.props.storyStatus === StoryStatus.PUBLISHABLE &&
    this.props.publishingEnabled &&
    !this.props.publishingPaused &&
    this.props.isHostUserNonBlocking &&
    !this.props.subscribeSnapIsIncomplete &&
    !this.editionContainsUncroppedTiles();

  canBeUnlocked = () =>
    this.props.storyState === StoryState.SCHEDULED ||
    this.props.storyState === StoryState.SCHEDULED_PENDING_APPROVAL ||
    this.props.storyState === StoryState.PENDING_APPROVAL ||
    this.props.storyState === StoryState.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL ||
    this.props.storyState === StoryState.LIVE_EDIT_PENDING_APPROVAL;

  isPublishingButtonEnabled = () =>
    !this.props.isReadOnlyOrSaving &&
    !this.props.storyHasOngoingTransactions &&
    (this.canBeUnlocked() || this.canBePublished());

  shouldShowPlainButton = () => !this.props.isHomepageButton && this.isPublishingButtonEnabled();

  shouldShowStoryScore = () =>
    !!this.props.storyScore && !this.props.isHomepageButton && !this.props.isDynamicEditionsPublisher;

  getPublishButtonText = () => getMessageFromId('publish-story-label-schedule-modal');

  getPublishButtonConfig = (): PublishButtonConfig => {
    switch (this.props.storyState) {
      case StoryState.NEW:
      case StoryState.READY:
      case StoryState.HIDDEN:
        return {
          buttonTitle: this.getPublishButtonText(),
          style: primaryButtonStyle.primaryButton,
          onClick: this.publishStory,
        };
      case StoryState.SCHEDULED:
      case StoryState.SCHEDULED_PENDING_APPROVAL:
      case StoryState.PENDING_APPROVAL:
        return {
          buttonTitle: getMessageFromId('title-edit-story-name'),
          style: greyButtonStyle.greyButton,
          onClick: this.editStory,
        };
      case StoryState.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL:
      case StoryState.LIVE_EDIT_PENDING_APPROVAL:
        return {
          buttonTitle: getMessageFromId('title-edit-story-name'),
          style: greyButtonStyle.greyButton,
          onClick: this.editLiveStory,
        };
      case StoryState.LIVE:
      case StoryState.ARCHIVED:
        return {
          buttonTitle: this.getPublishButtonText(),
          style: primaryButtonStyle.primaryButton,
          onClick: this.publishStory,
        };
      case StoryState.DELETED:
      default:
        return {
          buttonTitle: null,
          onClick: noop,
          style: '',
        };
    }
  };

  renderStoryScoreProgress = () => {
    // if the story is saving do not show the story status message in the tooltip
    const shouldShowStatusMessage = this.props.isReadOnlyOrSaving ? false : !this.isPublishingButtonEnabled();
    const color = getProgressColor(this.props.storyScore.achievedPercentage, shouldShowStatusMessage);
    return (
      <div className={style.storyScoreProgressContainer}>
        <SDSProgressBar
          progressType={ProgressBarType.CIRCLE}
          progressSize={ProgressBarSize.SMALL}
          showInfo={false}
          percent={shouldShowStatusMessage ? 0 : this.props.storyScore.achievedPercentage}
          data-test="publisherStoryEditor.PublishStoryButton.storyScore.progress"
          strokeColor={color.main}
          trailColor={color.tail}
        />
      </div>
    );
  };

  shouldShowStoryScoreIcon = () => {
    // if the user has 100% we show the blue primary button without the icon
    return this.shouldShowStoryScore() && this.props.storyScore.achievedPercentage !== 100;
  };

  renderPlainButton = () => {
    if (this.props.isHomepageButton) {
      return this.renderHomepageButton();
    }
    const buttonConfig = this.getPublishButtonConfig();
    const shouldShowScoreIcon = this.shouldShowStoryScoreIcon();
    return (
      <SDSButton
        type={shouldShowScoreIcon ? ButtonType.WHITE_ON_GREY : ButtonType.PRIMARY_ON_GREY}
        onClick={buttonConfig.onClick}
        disabled={!this.isPublishingButtonEnabled()}
        data-test="publishStoryButton"
      >
        {buttonConfig.buttonTitle}
        {shouldShowScoreIcon ? this.renderStoryScoreProgress() : null}
      </SDSButton>
    );
  };

  renderButtonWithStoryScore = () => {
    // if the story is saving do not show the story status message in the tooltip
    const shouldShowStatusMessage = this.props.isReadOnlyOrSaving ? false : !this.isPublishingButtonEnabled();
    return (
      <div data-test="publisherStoryEditor.PublishStoryButton.storyScore.tooltipContainer">
        <SDSTooltip
          title={
            <StoryScoreTooltip
              storyScore={this.props.storyScore}
              storyStatusMessage={this.props.storyStatusMessage}
              isStoryIncomplete={shouldShowStatusMessage}
            />
          }
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          placement={TooltipPosition.BOTTOM}
          overlayClassName={style.storyScoreTooltip}
        >
          {this.renderPlainButton()}
        </SDSTooltip>
      </div>
    );
  };

  renderButtonWithTooltip = () => {
    const tooltipPlacement = this.props.isHomepageButton ? TooltipPosition.TOP_RIGHT : TooltipPosition.TOP;
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <SDSTooltip title={this.props.storyStatusMessage} placement={tooltipPlacement} mouseEnterDelay={0.3}>
        {this.renderPlainButton()}
      </SDSTooltip>
    );
  };

  renderHomepageButton = () => {
    return (
      <HomepageButton
        onClick={this.publishStory}
        disabled={!this.isPublishingButtonEnabled()}
        type={HomepageButtonTypes.AVAILABLE}
      />
    );
  };

  renderHappeningNowBreakingNewsButton = () => {
    return (
      <SDSTooltip
        title={submitHappeningNowBreakingNewsStoryMsg}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={this.props.isHomepageButton ? TooltipPosition.TOP : TooltipPosition.BOTTOM}
      >
        <SDSButton
          type={this.props.isHomepageButton ? ButtonType.WHITE : ButtonType.WHITE_ON_GREY}
          inlineIcon={megaphone}
          onClick={this.publishStory}
          disabled={!this.isPublishingButtonEnabled()}
          shape={ButtonShape.CIRCLE}
          data-test="publishStoryButton.HappeningNowBreakingNews"
        />
      </SDSTooltip>
    );
  };

  renderButton = () => {
    if (this.props.isHnBreakingNews) {
      return this.renderHappeningNowBreakingNewsButton();
    }
    if (this.shouldShowStoryScore()) {
      return this.renderButtonWithStoryScore();
    }
    if (this.shouldShowPlainButton()) {
      return this.renderPlainButton();
    }
    return this.renderButtonWithTooltip();
  };

  render() {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(event?: Event | null | undefined) => void' ... Remove this comment to see the full error message
    return <div onClick={stopEventPropagation}>{this.renderButton()}</div>;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(PublishStoryButton);
