import classnames from 'classnames';
import React from 'react';

import * as editorActions from 'state/editor/actions/editorActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getTileId } from 'state/tiles/schema/tilesEntityHelpers';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import TileLogoPreview from 'views/common/components/TileLogoPreview/TileLogoPreview';

import style from './TileLogoPicker.scss';

import type { Tile } from 'types/tiles';

export const mapStateToProps = (state: State, props: any) => {
  const activePublisherId = publishersSelectors.getActivePublisherId(state);

  return {
    activePublisherId,
    tileLogos: tilesSelectors.getTileLogosByPublisherId(state)(activePublisherId),
  };
};

const mapDispatchToProps = {
  updateTileAndSave: editorActions.updateTileAndSave,
};

const tilePropertiesToWatch = ['baseImageAssetId', 'logoImageAssetId', 'logoReadStateOverlayColor'];

type OwnTileLogoPickerProps = {
  tile?: Tile;
  tileLogos?: any[];
  isEditionReadOnly?: boolean;
  locked?: boolean;
  updateTileAndSave?: (...args: any[]) => any;
};

type TileLogoPickerProps = OwnTileLogoPickerProps & typeof TileLogoPicker.defaultProps;

export class TileLogoPicker extends React.Component<TileLogoPickerProps> {
  static defaultProps = {
    isEditionReadOnly: true,
    locked: true,
  };

  shouldComponentUpdate(nextProps: TileLogoPickerProps) {
    const tilesEqual = tilePropertiesToWatch.reduce((result, property) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return result && nextProps.tile[property] === this.props.tile[property];
    }, true);

    return !tilesEqual || nextProps.isEditionReadOnly !== this.props.isEditionReadOnly;
  }

  selectLogo = (logo: any) => {
    if (this.props.isEditionReadOnly || this.props.locked) {
      return null;
    }

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return this.props.updateTileAndSave(getTileId(this.props.tile), {
      ...this.props.tile,
      isLogoEnabled: true,
      logoImageAssetId: logo.id,
      logoReadStateOverlayColor: logo.color,
    });
  };

  renderTileLogoPreview(tileLogo: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return <TileLogoPreview id={tileLogo.id} tileId={this.props.tile.croppedImageAssetId} />;
  }

  render() {
    let { tileLogos } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'logoImageAssetId' does not exist on type... Remove this comment to see the full error message
    const { logoImageAssetId } = this.props.tile;

    // get rid of all other unselected tile logos if edition is read only, nothing will be clickable anyway
    if (this.props.isEditionReadOnly && logoImageAssetId) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      tileLogos = tileLogos.filter(tileLogo => tileLogo.id === logoImageAssetId);
    }

    // Filter empty logos
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    tileLogos = tileLogos.filter(tileLogo => !!tileLogo.id);

    return (
      <div>
        {tileLogos.map((tileLogo, i) => {
          const finalClassName = classnames({
            [style.tileLogoOption]: true,
            [style.disabled]: this.props.isEditionReadOnly,
            [style.selected]: tileLogo.id === logoImageAssetId || (!logoImageAssetId && i === 0),
            [style.rightOption]: i % 2 === 1,
          });
          return (
            <div
              className={finalClassName}
              key={tileLogo.id}
              onClick={() => this.selectLogo(tileLogo)} // eslint-disable-line
              data-test="tileLogoPicker"
            >
              {this.renderTileLogoPreview(tileLogo)}
            </div>
          );
        })}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TileLogoPicker);
