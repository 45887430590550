export async function setAsyncInterval(callback: () => void, ms: number | undefined, state: { clear: boolean }) {
  if (state.clear) {
    return;
  }
  await callback();
  setTimeout(() => {
    setAsyncInterval(callback, ms, state);
  }, ms);
}

export function clearAsyncInterval(state: { clear: boolean }) {
  const _state = state;
  _state.clear = true;
}
