import React, { useCallback } from 'react';
import InlineSVG from 'svg-inline-react';

import { chevronLeft, chevronRight, playFilled, pause } from 'icons/SDS/allIcons';

import style from './VideoPlayerControlsOverlay.scss';

type Props = {
  onPreviousClick: () => void;
  onNextClick: () => void;
  onPlayPauseClick: () => void;
  isPlaying: boolean | undefined | null;
  hasNext: boolean | undefined | null;
  hasPrevious: boolean | undefined | null;
  playableMode: boolean;
};

export default function VideoPlayerControlsOverlay({
  onPreviousClick,
  onNextClick,
  onPlayPauseClick,
  isPlaying,
  hasNext,
  hasPrevious,
  playableMode,
}: Props) {
  const handlePreviousClick = useCallback(() => {
    if (hasPrevious) {
      onPreviousClick();
    }
  }, [onPreviousClick, hasPrevious]);

  const handleNextClick = useCallback(() => {
    if (hasNext) {
      onNextClick();
    }
  }, [onNextClick, hasNext]);

  const handleOnPlayPauseClick = useCallback(() => {
    if (playableMode) {
      onPlayPauseClick();
    }
  }, [onPlayPauseClick, playableMode]);

  return (
    <div className={style.rootContainer}>
      <button
        type="button"
        onClick={handlePreviousClick}
        className={style.previousSection}
        data-test="videoPlayerControl.previousSnap"
      >
        {hasPrevious && <InlineSVG src={chevronLeft} className={style.chevron} />}
      </button>
      <button
        type="button"
        onClick={handleOnPlayPauseClick}
        className={style.playPauseSection}
        data-test="videoPlayerControl.playPause"
      >
        {playableMode && <InlineSVG src={isPlaying ? pause : playFilled} className={style.playPauseIcon} />}
      </button>
      <button
        type="button"
        onClick={handleNextClick}
        className={style.nextSection}
        data-test="videoPlayerControl.nextSnap"
      >
        {hasNext && <InlineSVG src={chevronRight} className={style.chevron} />}
      </button>
    </div>
  );
}
