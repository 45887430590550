import classNames from 'classnames';
import { isEqual, memoize } from 'lodash';
import * as React from 'react';
import type { ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import { findSnapIndexInEdition } from 'state/editions/schema/editionEntityHelpers';

import { AddSnapRuler } from '../../components/AddSnapRuler/AddSnapRuler';
import type { SegmentNode, SnapNode, DomElement } from '../StoryCarousel/StoryCarouselTypes';

import { State } from 'src/types/rootState';
import { stopEventPropagation } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';

import SegmentCarouselHeader from 'views/publisherStoryEditor/containers/SegmentCarouselHeader/SegmentCarouselHeader';
import SnapCarouselItem from 'views/publisherStoryEditor/containers/SnapCarouselItem/SnapCarouselItem';

import style from './SegmentCarouselItem.scss';

import type { BuildStatusType } from 'types/build';
import type { SnapId } from 'types/common';
import type { Edition } from 'types/editions';

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    buildStatuses: buildStatusSelectors.getDiscoverSnapBuildStatus(state),
  };
};
const mapDispatchToProps = {};
type OwnProps = {
  segment: SegmentNode;
  segmentNumber: number | undefined | null;
  isPlaceholder: boolean;
  connectDragSource: (a: ReactNode) => ReactNode;
  connectDropTarget: (a: ReactNode) => ReactNode;
  isAnySnapBeingDragged: boolean;
  saveSnapDomElement: (b: SnapNode, a: DomElement) => void;
  style: unknown;
  snapStyle: unknown;
  animationDuration: number;
  isReadOnly: boolean;
  snapCarouselItemFactory: (snapProps: unknown, node: SnapNode, className?: string | null) => ReactNode;
  addSnap: (index: number) => void;
  edition: Edition;
  buildStatuses: (id: SnapId) => BuildStatusType | undefined | null;
  convertSingleSnapSegment: (segmentNumber: number, toReal: boolean) => void;
};
// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SegmentCarouselItem.defaultProps;
export class SegmentCarouselItem extends React.Component<Props> {
  // In case it is instantiated by the drag layer it can't be a dragsource or droptarget
  // @ts-expect-error ts-migrate(2502) FIXME: 'defaultProps' is referenced directly or indirectl... Remove this comment to see the full error message
  static defaultProps: Partial<Props> = {
    connectDragSource: (item: any) => item,
    connectDropTarget: (item: any) => item,
  };

  shouldComponentUpdate(nextProps: Props) {
    return nextProps && !isEqual(this.props, nextProps);
  }

  addSnapHandler = memoize((index: number) => () => {
    const insertAfterSnap = this.props.segment.snapNodes[index] || null;
    if (!insertAfterSnap) {
      return;
    }
    const insertAfterSnapIndex = findSnapIndexInEdition(this.props.edition, insertAfterSnap.id);
    if (insertAfterSnapIndex !== undefined && insertAfterSnapIndex !== -1) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.props.addSnap(insertAfterSnapIndex + 1);
    }
  });

  wrapInTransition = (element: ReactNode, key: string) => {
    return (
      <CSSTransition
        timeout={{ enter: this.props.animationDuration || 0, exit: this.props.animationDuration || 0 }}
        key={key}
        classNames="none"
      >
        {element}
      </CSSTransition>
    );
  };

  renderSnap = (props: Props, node: SnapNode, className?: string | null): ReactNode => {
    return (
      <SnapCarouselItem
        saveSnapDomElement={props.saveSnapDomElement}
        style={props.snapStyle}
        className={className}
        snap={node.snap}
        snapNode={node}
        isPlaceholder={props.isPlaceholder}
        isAnySnapBeingDragged={props.isAnySnapBeingDragged}
        buildStatus={props.buildStatuses(node.snap.id)}
      />
    );
  };

  renderSnaps() {
    const snapComponents = this.props.segment.snapNodes.map((node: any) => {
      const snapComponent = this.props.snapCarouselItemFactory
        ? this.props.snapCarouselItemFactory(this.props, node, style.child)
        : this.renderSnap(this.props, node, style.child);
      return this.wrapInTransition(snapComponent, node.key);
    });
    const childComponents = [];
    for (let i = 0; i < snapComponents.length - 1; ++i) {
      const snapNode = this.props.segment.snapNodes[i];
      childComponents.push(snapComponents[i]);
      if (!this.props.isAnySnapBeingDragged && !this.props.isReadOnly) {
        const addSnapCallback = this.addSnapHandler(i);
        const snapRulerKey = `snapruler-${snapNode.id}`;
        const snapRuler = <AddSnapRuler key={snapRulerKey} onClick={addSnapCallback} className={style.addRuler} />;
        childComponents.push(this.wrapInTransition(snapRuler, snapRulerKey));
      }
    }
    childComponents.push(snapComponents[snapComponents.length - 1]);
    return childComponents;
  }

  renderSegmentCarouselHeader = () => {
    const { isPlaceholder, segmentNumber, segment, edition } = this.props;
    return (
      <SegmentCarouselHeader
        segment={segment}
        editionId={edition ? edition.id : null}
        segmentNumber={segmentNumber}
        isPlaceholder={isPlaceholder}
      />
    );
  };

  render() {
    const { isPlaceholder, connectDragSource, connectDropTarget } = this.props;
    const containerClassName: string = classNames(style.container, {
      [style.placeholder]: isPlaceholder,
      [style.notDragging]: !this.props.isAnySnapBeingDragged,
    });
    return connectDragSource(
      connectDropTarget(
        <div
          className={containerClassName}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(event?: Event | null | undefined) => void' ... Remove this comment to see the full error message
          onClick={stopEventPropagation}
          data-test="storySnapListItem.mainDiv"
          style={this.props.style}
        >
          {this.renderSegmentCarouselHeader()}
          <TransitionGroup className={style.transitionGroup}>{this.renderSnaps()}</TransitionGroup>
        </div>
      )
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SegmentCarouselItem);
