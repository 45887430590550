import React from 'react';
import { FormattedMessage } from 'react-intl';

import variables from 'styles/variables.scss';

import { StoryScoreType } from 'types/build';

export const colorsConfig = {
  RED: {
    main: variables.red200,
    tail: variables.translucentRed200,
  },
  ORANGE: {
    main: variables.orange200,
    tail: variables.translucentOrange200,
  },
  YELLOW: {
    main: variables.yellow200,
    tail: variables.translucentYellow200,
  },
  GREEN: {
    main: variables.green200,
    tail: variables.translucentGreen200,
  },
  BLUE: {
    main: variables.blue200,
    tail: variables.translucentBlue200,
  },
};

export const progressTitleConfig = {
  INCOMPLETE: (
    <FormattedMessage id="story-score-incomplete" description="Story is incomplete" defaultMessage="Incomplete" />
  ),
  WEAK: <FormattedMessage id="story-score-weak" description="Story score is weak" defaultMessage="Weak" />,
  IMPROVED: (
    <FormattedMessage id="story-score-improved" description="Story score is improved" defaultMessage="Improved" />
  ),
  GOOD: <FormattedMessage id="story-score-good" description="Story score is good" defaultMessage="Good" />,
  EXCELLENT: (
    <FormattedMessage id="story-score-excellent" description="Story score is excellent" defaultMessage="Excellent" />
  ),
};

export const storyScoreDescriptionConfig = {
  [StoryScoreType.TAGS]: (
    <FormattedMessage
      id="story-score-tags"
      description="Warning to user to add tags to all snaps in the story"
      defaultMessage="Add tags to all Snaps"
    />
  ),
  [StoryScoreType.SUBTITLES]: (
    <FormattedMessage
      id="story-score-subtitles"
      description="Warning to user to add subtitles to at least one snap in the story"
      defaultMessage="Add subtitles to your story"
    />
  ),
  [StoryScoreType.MULTIPLE_TILES]: (
    <FormattedMessage
      id="story-score-multiple-tiles"
      description="Warning to user to add tiles to more than one snap"
      defaultMessage="Add tiles to multiple snaps"
    />
  ),
  [StoryScoreType.LEADING_TILES]: (
    <FormattedMessage
      id="story-score-leading-tiles"
      description="Warning to user to add 4 tiles to the leading snap"
      defaultMessage="Add 4 tile variants to first snap"
    />
  ),
};

export const getStoryScoreMessage = (scoreType: StoryScoreType) => {
  return scoreType ? storyScoreDescriptionConfig[scoreType] : null;
};

export const getProgressColor = (achievedPercentage: number, isStoryIncomplete: boolean) => {
  // color of the progress wheel based on percentage achieved
  if (achievedPercentage <= 20 || isStoryIncomplete) {
    return colorsConfig.RED;
  }
  if (achievedPercentage <= 40) {
    return colorsConfig.ORANGE;
  }
  if (achievedPercentage <= 60) {
    return colorsConfig.YELLOW;
  }
  if (achievedPercentage <= 80) {
    return colorsConfig.GREEN;
  }

  return colorsConfig.BLUE;
};

export const getProgressTitle = (achievedPercentage: number, isStoryIncomplete: boolean) => {
  if (isStoryIncomplete) {
    return progressTitleConfig.INCOMPLETE;
  }
  if (achievedPercentage <= 20) {
    return progressTitleConfig.WEAK;
  }
  if (achievedPercentage <= 60) {
    return progressTitleConfig.IMPROVED;
  }
  if (achievedPercentage <= 80) {
    return progressTitleConfig.GOOD;
  }

  return progressTitleConfig.EXCELLENT;
};

export const getDisplayText = (achievedScore: number, isStoryIncomplete: boolean) => {
  if (isStoryIncomplete || !achievedScore) {
    return '!';
  }

  return achievedScore;
};
