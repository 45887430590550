import React from 'react';
import { FormattedMessage } from 'react-intl';

import TooltipMessageType from './TooltipMessageType';

const genericTooltips = {
  [TooltipMessageType.COMPONENT_SNAP]: (
    <FormattedMessage
      id="component-snap-tooltip"
      description="Tooltip for Snap component"
      defaultMessage="The initial video a Snapchatter sees"
    />
  ),
  [TooltipMessageType.COMPONENT_TILE]: (
    <FormattedMessage
      id="component-tile-tooltip"
      description="Tooltip for Tile Snap Component"
      defaultMessage="The image a Snapchatter sees in Discover"
    />
  ),
  [TooltipMessageType.COMPONENT_SUBSCRIBE_SNAP]: (
    <FormattedMessage
      id="component-follow-tooltip"
      description="Tooltip for End Snap Component"
      defaultMessage="The final Snap that a Snapchatter sees inviting them to add you."
    />
  ),
  [TooltipMessageType.COMPONENT_NO_ACCESS]: (
    <FormattedMessage
      id="component-no-access-tooltip"
      description="Tooltip for unavailable feature"
      defaultMessage="This feature is not available"
    />
  ),
};

const tooltipsText = {
  [TooltipMessageType.COMPONENT_ATTACHMENT]: (
    <FormattedMessage
      id="component-attachment-tooltip-snaps"
      description="Tooltip for Attachment Component"
      defaultMessage="In-app experience a Snapchatter sees when they swipe up on a Snap"
    />
  ),
  [TooltipMessageType.ATTACHMENT_LONGFORM_VIDEO]: (
    <FormattedMessage
      id="attachment-longform-video-tooltip-snaps"
      description="Tooltip for Attachment - Longform Video"
      defaultMessage="Swipe up on Snap to view a video"
    />
  ),
  [TooltipMessageType.ATTACHMENT_REMOTE_WEB]: (
    <FormattedMessage
      id="attachment-remote-web-tooltip-snaps"
      description="Tooltip for Attachment - Remote Web"
      defaultMessage="Swipe up on Snap to view a mobile website"
    />
  ),
  [TooltipMessageType.ATTACHMENT_ARTICLE]: (
    <FormattedMessage
      id="attachment-article-tooltip-snaps"
      description="Tooltip for Attachment - Article"
      defaultMessage="Swipe up on Snap to view an article"
    />
  ),
  [TooltipMessageType.ATTACHMENT_POLL]: (
    <FormattedMessage
      id="attachment-poll-tooltip-snaps"
      description="Tooltip for Attachment - Poll"
      defaultMessage="Swipe up on Snap to take a poll"
    />
  ),
  [TooltipMessageType.ATTACHMENT_VOTE]: (
    <FormattedMessage
      id="attachment-vote-tooltip-snaps"
      description="Tooltip for Attachment - Vote"
      defaultMessage="Swipe up on Snap to submit a vote"
    />
  ),
  [TooltipMessageType.ATTACHMENT_CAMERA]: (
    <FormattedMessage
      id="attachment-camera-tooltip-snap"
      description="Tooltip for Attachment -- Camera"
      defaultMessage="Swipe up on Snap to a camera view (with lens carousel)"
    />
  ),
  [TooltipMessageType.GENERAL_SHARING]: (
    <FormattedMessage
      id="general-sharing-tooltip-snaps"
      description="Tooltip for Sharing switch"
      defaultMessage="Allows Snapchatters to share snaps with friends"
    />
  ),
  [TooltipMessageType.TOPSNAP_MEDIA_FILE]: (
    <FormattedMessage
      id="topsnap-media-file-tooltip-snaps"
      description="Tooltip for Top Snap Media File"
      defaultMessage="Choose your Snap. Full screen, vertically formatted live and/or motion graphic video."
    />
  ),
};

export const getTooltipMessage = (type: any) => {
  return genericTooltips[type] || tooltipsText[type];
};
