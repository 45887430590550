import { STORIES_EVERYWHERE_BASE_URL, STORIES_EVERYWHERE_EMBED_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';
import { openInNewWindow } from 'utils/locationUtils';

import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';

export const embedMode = () => {
  const builder = new ScopedURLBuilder(STORIES_EVERYWHERE_BASE_URL);
  return builder.createAPICall('curation/');
};

export const webPlayer = (publisherId: PublisherID, editionId: EditionID) => {
  const builder = new ScopedURLBuilder(STORIES_EVERYWHERE_BASE_URL);
  return builder.createAPICall(`s/c:16::${publisherId}${encodeURIComponent('#')}${editionId}::0`, {}, '|');
};

export const embedPlayer = (publisherId: PublisherID, editionId: EditionID) => {
  const builder = new ScopedURLBuilder(STORIES_EVERYWHERE_EMBED_BASE_URL);
  return builder.createAPICall(`c:16::${publisherId}${encodeURIComponent('#')}${editionId}::0`, {}, '|');
};

export const getOurStoryWebLink = (curatedSnapId: string) => {
  return `${STORIES_EVERYWHERE_BASE_URL}o/${curatedSnapId}`;
};

export const openInWebPlayer = (curatedSnapId: string) => {
  openInNewWindow(getOurStoryWebLink(curatedSnapId));
};
