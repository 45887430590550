/* global CKEDITOR */

export default class SCPlaintextPastingFilter {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCPlaintextPastingFilter';
  }

  static define(registry: any) {
    registry.registerPlugin(SCPlaintextPastingFilter, {
      init(editor: any) {
        editor.on(
          'paste',
          (event: any) => {
            const { dataTransfer } = event.data;

            if (dataTransfer && !dataTransfer.getData('text/html')) {
              const data = event.data.dataTransfer.getData('text/plain');

              if (data) {
                // @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'CKEDITOR'. Did you mean 'editor'... Remove this comment to see the full error message
                const value = CKEDITOR.tools.transformPlainTextToHtml(data, CKEDITOR.ENTER_BR);
                event.data.type = 'text'; // eslint-disable-line no-param-reassign
                event.data.dataValue = value; // eslint-disable-line no-param-reassign
              }
            }
          },
          null,
          null,
          0
        );
      },
    });
  }
}
