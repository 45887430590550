import classNames from 'classnames';
import * as React from 'react';

import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { getStoryEditorMode } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import {
  isSubscribeSnapSelected,
  getSingleAssetPlayerDuration,
  getSingleAssetPlayerCurrentTime,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import { getTilesByWholeSnapId, getBottomSnapByWholeSnapId } from 'state/snaps/selectors/snapsSelectors';

import { EDITOR_MODES } from 'config/constants';
import { tileWithTextLines, attachment } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import DotStatus, { DotStatusState } from 'views/common/components/DotStatus/DotStatus';
import Icon from 'views/common/components/Icon/Icon';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import { SNAP_TILE_ATTACHMENT_INCOMPLETE_STATUSES } from 'views/editor/containers/RichSnapTabs/RichSnapTabsConfig';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './TimelineSnapCell.scss';

import { SnapProblem } from 'types/build';
import type { EditionID } from 'types/editionID';
import type { State as ReduxState } from 'types/rootState';
import type { TimelineSnap } from 'types/singleAssetStoryEditor';

type StateProps = {
  adSlotBeingDragged: boolean;
  duration: number;
  hasWarning: boolean;
  hasError: boolean;
  hasTiles: boolean;
  isSnapPlaying: boolean;
  isShowingSnapPublisher: boolean;
  hasAttachment: boolean;
};
type OwnProps = {
  snap: TimelineSnap;
  activeStoryId: EditionID;
};
export type Props = StateProps & OwnProps;
export type OwnState = {
  isHovering: boolean;
};
const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const currentTime = getSingleAssetPlayerCurrentTime(state)(ownProps.activeStoryId);
  const isSubscribeSnapSelect = isSubscribeSnapSelected(state);
  const snapExtendedStatus = getDiscoverSnapBuildStatus(state)(ownProps.snap.snapId)?.extendedStatus;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ExtendedBuildStatus | undefined'... Remove this comment to see the full error message
  const hasWarning = SNAP_TILE_ATTACHMENT_INCOMPLETE_STATUSES.includes(snapExtendedStatus);
  const hasError = !hasWarning && snapExtendedStatus !== SnapProblem.PUBLISHABLE;
  const hasTiles = (getTilesByWholeSnapId(state)(ownProps.snap.snapId) || []).length > 0;
  const hasAttachment = Boolean(getBottomSnapByWholeSnapId(state)(ownProps.snap.snapId));
  const isSnapPlaying =
    currentTime >= ownProps.snap.startTimeMs &&
    currentTime < ownProps.snap.startTimeMs + ownProps.snap.durationMs &&
    !isSubscribeSnapSelect;
  const isShowingSnapPublisher = getStoryEditorMode(state).editorMode === EDITOR_MODES.SNAP_PUB;
  return {
    hasWarning,
    hasError,
    hasTiles,
    duration: getSingleAssetPlayerDuration(state)(ownProps.activeStoryId),
    isSnapPlaying,
    adSlotBeingDragged: false,
    isShowingSnapPublisher,
    hasAttachment,
  };
};
export class TimelineSnapCell extends React.Component<Props, OwnState> {
  private readonly tooltip: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.tooltip = React.createRef();
    this.state = { isHovering: false };
  }

  shouldComponentUpdate(nextProps: Props, nextState: OwnState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  handleHover = (isHovering: boolean) => () => {
    this.setState({ isHovering });
  };

  renderErrorWarning = () => {
    if (this.props.hasError) {
      return <DotStatus status={DotStatusState.ERROR} />;
    }
    if (this.props.hasWarning) {
      return <DotStatus status={DotStatusState.INCOMPLETE} />;
    }
    return null;
  };

  renderTileIcon = () => {
    return this.props.hasTiles ? <Icon inlineIcon={tileWithTextLines} data-test="TimelineSnapCell.tileIcon" /> : null;
  };

  renderAttachmentIcon = () => {
    return this.props.hasAttachment ? (
      <Icon inlineIcon={attachment} data-test="TimelineSnapCell.attachmentIcon" />
    ) : null;
  };

  renderTooltip = () => {
    return (
      <div className={style.tooltip}>
        {this.renderErrorWarning()}
        {this.renderTileIcon()}
        {this.renderAttachmentIcon()}
      </div>
    );
  };

  wrapCellInTooltip() {
    const totalDuration = this.props.duration;
    const showTooltip =
      (this.props.hasWarning || this.props.hasError || this.props.hasTiles || this.props.hasAttachment) &&
      !this.state.isHovering && // Do not show when hovering over the element
      !!totalDuration && // show only when the cell has been laid out
      !this.props.isShowingSnapPublisher; // Do not show when snap publisher iframe is showing
    return (
      <SDSTooltip
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        placement={TooltipPosition.TOP}
        title={this.renderTooltip()}
        visible={showTooltip}
        disablePointerEvents
      >
        <div ref={this.tooltip} />
      </SDSTooltip>
    );
  }

  render() {
    const { adSlotBeingDragged, snap, duration, isSnapPlaying } = this.props;
    // Without knowing total video duration we can't render the snap
    if (!duration || duration === 0) {
      return null;
    }
    const isAdSlotBeingDragged = !!adSlotBeingDragged;
    const classes = classNames(style.root, {
      [style.playing]: isSnapPlaying,
      [(style as any).dragging]: isAdSlotBeingDragged,
    });
    const width = snap.durationMs / duration;
    const widthPercent = `${width * 100}%`;
    return (
      <div
        className={classes}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ '--cellWidth': string; }' is not assignabl... Remove this comment to see the full error message
        style={{ '--cellWidth': widthPercent }}
        data-test="TimelineSnapCell.cell"
        onMouseEnter={this.handleHover(true)}
        onMouseLeave={this.handleHover(false)}
      >
        {this.wrapCellInTooltip()}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, null)(TimelineSnapCell);
