import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { onTabChangedHandler } from 'state/editor/actions/editorActions';
import { getPendingTileById } from 'state/editor/selectors/editorSelectors';
import { getActiveEditionId } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getTileIdOrNull } from 'state/tiles/schema/tilesEntityHelpers';

import { TileFormatConfig, TileCropType, TileFormat } from 'config/tileConfig';
import { tileWithTextLines } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';

import { nonRoundCornerTypes } from 'views/editor/components/TileBigPreview/TileBigPreview';
import TilePreview from 'views/editor/containers/TilePreview/TilePreview';

import style from './TilePreviewSection.scss';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import { ExtractDispatchProps } from 'types/redux';
import type { State as ReduxState } from 'types/rootState';
import { ConfigTab } from 'types/singleAssetStoryEditor';
import type { PendingTile, Tile } from 'types/tiles';

type StateProps = {
  pendingTile: PendingTile | undefined | null;
  activeEditionId: EditionID | undefined | null;
};

type OwnProps = {
  snapId: SnapId;
  snapIndex: number;
  tile: Tile | undefined | null;
  className: string | undefined | null;
};

const mapDispatchToProps = {
  onTabChangedHandler,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  const tileId = getTileIdOrNull(ownProps.tile);
  const pendingTile = tileId ? getPendingTileById(state)(tileId) : null;

  const activeEditionId = getActiveEditionId(state);

  return {
    activeEditionId,

    pendingTile,
  };
};

export class TilePreviewSection extends React.Component<Props> {
  onHover = () => {
    if (this.props.activeEditionId) {
      this.props.onTabChangedHandler(this.props.activeEditionId, ConfigTab.TILES);
    }
  };

  render() {
    const activeTypeId = TileCropType.CHEETAH;
    const key = `PRE_${activeTypeId}` as TileFormat;
    const tileFormat = TileFormatConfig[key];
    const tileToRender = u(this.props.pendingTile || {}, this.props.tile);

    const rootClassName = classNames(this.props.className, style.rootColumn);
    return (
      <div className={rootClassName} onMouseEnter={this.onHover} onDragEnter={this.onHover}>
        <div className={style.iconAndTitleRow}>
          <InlineSVG src={tileWithTextLines} className={style.icon} />
          {this.props.snapIndex === 0 ? (
            <FormattedMessage
              id="tile-preview-leading-tile-title"
              description="Leading tile preview title"
              defaultMessage="Leading Tile"
            />
          ) : (
            <FormattedMessage
              id="tile-preview-snap-index-title"
              description="Snap index tile preview title"
              defaultMessage="Tile for Snap {snapIndex}"
              values={{
                snapIndex: this.props.snapIndex + 1,
              }}
            />
          )}
        </div>
        <TilePreview
          format={tileFormat}
          tile={tileToRender}
          roundCorners={!nonRoundCornerTypes.includes(activeTypeId)}
          isEditable
          forceImagePreview={false}
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TilePreviewSection);
