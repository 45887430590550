// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { Form } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import React from 'react';

type Props = {
  hasTitle?: boolean;
  title?: string | any;
  children?: React.ReactNode;
  className?: string;
};

const PanelBase = (props: Props) => (
  <div className={classNames('panelBase', props.className)}>
    <Form componentClass="div" title={props.title}>
      {props.children}
    </Form>
  </div>
);

export default PanelBase;
