export default {
  COMPONENT_TILE: 'COMPONENT_TILE',
  COMPONENT_SNAP: 'COMPONENT_SNAP',
  COMPONENT_SUBSCRIBE_SNAP: 'COMPONENT_SUBSCRIBE_SNAP',
  COMPONENT_ATTACHMENT: 'COMPONENT_ATTACHMENT',
  COMPONENT_NO_ACCESS: 'COMPONENT_NO_ACCESS',

  ATTACHMENT_LONGFORM_VIDEO: 'COMPONENT_ATTACHMENT_LONGFORM_VIDEO',
  ATTACHMENT_REMOTE_WEB: 'ATTACHMENT_REMOTE_WEB',
  ATTACHMENT_ARTICLE: 'ATTACHMENT_ARTICLE',
  ATTACHMENT_POLL: 'ATTACHMENT_POLL',
  ATTACHMENT_VOTE: 'ATTACHMENT_VOTE',
  ATTACHMENT_CAMERA: 'ATTACHMENT_CAMERA',

  GENERAL_SHARING: 'GENERAL_SHARING',
  GENERAL_CALL_TO_ACTION: 'GENERAL_CALL_TO_ACTION',

  TOPSNAP_MEDIA_FILE: 'TOPSNAP_MEDIA_FILE',

  DEEP_LINK_APP_ICON: 'DEEP_LINK_APP_ICON',

  REMOTE_WEB_URL: 'REMOTE_WEB_URL',
};
