import classNames from 'classnames';
import type { ReactNode } from 'react';
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  getDisplayText,
  getProgressColor,
  getProgressTitle,
  getStoryScoreMessage,
} from 'utils/publisherStoryEditor/storyScoreUtils';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import SDSProgressBar, {
  ProgressBarSize,
  ProgressBarType,
} from 'views/common/components/SDSProgressBar/SDSProgressBar';

import style from './StoryScoreTooltip.scss';

import type { ComputedStoryScore } from 'types/build';
import { StoryScoreType } from 'types/build';

type Props = {
  storyScore: ComputedStoryScore;
  storyStatusMessage: ReactNode;
  isStoryIncomplete: boolean;
};

function StoryScoreTooltip({ storyScore, storyStatusMessage, isStoryIncomplete }: Props) {
  const { achievedScore, achievedPercentage, failedScores, fulfilledScores } = storyScore;

  const color = getProgressColor(storyScore.achievedPercentage, isStoryIncomplete);

  const getFormat = useCallback(() => getDisplayText(achievedScore, isStoryIncomplete), [
    achievedScore,
    isStoryIncomplete,
  ]);

  const requiredSection = isStoryIncomplete ? (
    <div>
      <div className={style.container} data-test="publisherStoryEditor.StoryScoreTooltip.requiredSection">
        <div className={style.title}>
          <FormattedMessage
            id="story-score-required"
            description="Story score tooltip title for required section"
            defaultMessage="Required"
          />
        </div>
      </div>
      <div className={style.score}>{storyStatusMessage}</div>
    </div>
  ) : null;

  const improvementsSection =
    failedScores.length + fulfilledScores.length > 0 ? (
      <div data-test="publisherStoryEditor.StoryScoreTooltip.improvementsSection">
        <div className={classNames(style.container, style.withBorder)}>
          <div className={classNames(style.container, style.title)}>
            <FormattedMessage
              id="story-score-improvements"
              description="Story score tooltip title for improvements section"
              defaultMessage="Improvements"
            />
          </div>
        </div>
        <div
          data-test="publisherStoryEditor.StoryScoreTooltip.failedScores"
          className={classNames({
            [style.container]: !isStoryIncomplete,
          })}
        >
          {failedScores.map((scoreType: StoryScoreType) => (
            <div className={style.score} key={scoreType}>
              {getStoryScoreMessage(scoreType)}
            </div>
          ))}
        </div>
        {fulfilledScores.length ? (
          <div
            data-test="publisherStoryEditor.StoryScoreTooltip.fulfilledScores"
            className={classNames(style.container, {
              [style.withBorder]: failedScores.length,
            })}
          >
            {fulfilledScores.map((scoreType: StoryScoreType) => (
              <div className={classNames(style.score, style.fulfilledScore)} key={scoreType}>
                {getStoryScoreMessage(scoreType)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    ) : null;

  return (
    <div className={style.wrapper} data-test="publisherStoryEditor.StoryScoreTooltip.tooltipBody">
      <div className={style.flexWrapper}>
        <div>
          <div className={style.title}>
            <FormattedMessage
              id="story-score-title"
              description="Story score tooltip title"
              defaultMessage="Story score"
            />
          </div>
          <div className={style.progressWrapper}>
            <SDSProgressBar
              progressType={ProgressBarType.CIRCLE}
              progressSize={ProgressBarSize.MEDIUM}
              percent={isStoryIncomplete ? 0 : achievedPercentage}
              data-test="publisherStoryEditor.StoryScoreTooltip.storyScore.progress"
              strokeColor={color.main}
              trailColor={color.tail}
              format={getFormat}
            />
            <div className={style.progressText} data-test="publisherStoryEditor.StoryScoreTooltip.progressTitle">
              {getProgressTitle(achievedPercentage, isStoryIncomplete)}
            </div>
          </div>
        </div>
        <div>
          <HelpCenterLink
            destination={HelpCenterDestination.STORY_SCORING}
            data-test="publisherStoryEditor.StoryScoreTooltip"
          />
        </div>
      </div>
      {requiredSection}
      {improvementsSection}
    </div>
  );
}

export default memo(StoryScoreTooltip);
