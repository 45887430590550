import classNames from 'classnames';
import invariant from 'invariant';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
  setEditionShareOption,
  setEditionAdPlacementMode,
  setAgeGate,
  setEditionPrivateArchiveLock,
  deleteStory,
} from 'state/editions/actions/editionsActions';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import { updateSingleAssetEditorState } from 'state/editor/actions/editorActions';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import {
  downloadTopsnapAssets,
  downloadSASVideoAsset,
} from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { renameStoryPrompt } from 'state/publisherTools/actions/publisherToolsActions';
import { isCurationPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { goToMagicSearch } from 'state/router/actions/routerActions';
import { updateAdSnapIndexes } from 'state/singleAssetStory/actions/singleAssetStoryActions';
import { getSingleAssetIsInDebugMode } from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import { getUnifiedSingleSnap } from 'state/snaps/schema/snapEntityHelpers';
import * as userActions from 'state/user/actions/userActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import SnapPopoverButtonRowStyle from '../../styles/SnapPopoverButtonRow.scss';

import { AdPlacementMode, AgeGate, EMPTY_ARRAY, ShareOption } from 'config/constants';
import { generateNewAdIndexes } from 'utils/adSnapIndexUtils';
import * as localRoutes from 'utils/apis/localRoutes';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';
import * as locationUtils from 'utils/locationUtils';
import { ModalType } from 'utils/modalConfig';

import ListItemWithIcon from 'views/common/components/ListItem/ListItemWithIcon';
import ListItemWithToggle from 'views/common/components/ListItem/ListItemWithToggle';
import PopoverCloseOnMovement from 'views/common/components/PopoverCloseOnMovement/PopoverCloseOnMovement';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import { CopyToPublisherOptions } from 'views/modals/containers/CopyToPublisherModal/CopyToPublisherModal';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';
import ShowTilesToggle from 'views/publisherStoryEditor/components/ShowTilesToggle/ShowTilesToggle';
import CheetahStoryOptionsControls from 'views/publisherStoryEditor/containers/CheetahStoryOptionsControls/CheetahStoryOptionsControls';
import {
  SnapPopoverConfig,
  SnapPopoverRowIds,
  SnapPopoverModalConfig,
} from 'views/publisherStoryEditor/utils/SnapPopoverRowConfig';

import style from './StoryOptionsButtonPopover.scss';

import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';
import { Claim } from 'types/permissions';
import type { Publisher } from 'types/publishers';
import type { State } from 'types/rootState';
import { SingleAssetSnap, Snap } from 'types/snaps';

type OwnProps = {
  activeEdition: Edition;
  activeEditionSnaps: Snap[];
  activePublisherId: number;
  publisher: Publisher;
  adControlsEnabled: boolean;
  ageGateEnabled: boolean;
  publisherAgeGateEnabled: boolean;
  children: any;
  isActiveEditionReadOnly: boolean;
  isActiveEditionSaving: boolean;
  isAlwaysShowAdControlsEnabled: boolean;
  isDisplayDisableAdsControlToggleEnabled: boolean;
  isAdvertisingEnabled: boolean;
  isCurationSnapDownloader: boolean;
  isDebugEntityViewer: boolean;
  isDynamicAdsEnabled: boolean;
  isOptionalAdsOverrideEnabled: boolean;
  isProductPlacementEnabled: boolean;
  isStoryAgeGateEditor: boolean;
  isStoryBlocker: boolean;
  isStoryCopierForAtLeastOnePublisher: boolean;
  isStoryDeleter: boolean;
  isStoryShareableEditor: boolean;
  isTopsnapDownloader: boolean;
  onHide: () => void;
  onShow: () => void;
  hasAtLeastOneCuratedSnap: boolean;
  isSingleAssetStoryEditor: boolean;
  isSingleSnapBuilder: boolean;
  getSingleAssetIsInDebugMode: boolean;
  isSuperAdmin: boolean;
  hasSubscribeSnap: boolean;
  isAdvertisingDisabledForStoryType: boolean;
};
type OwnState = {
  ageGateAlertIsShowing: boolean;
  deleteStoryAlertIsShowing?: boolean;
};

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const activeEdition = publisherStoryEditorSelectors.getActiveEdition(state);
  invariant(activeEdition != null, 'active edition should not be null');
  const publisher = getActivePublisherDetails(state);
  return {
    activeEdition,
    activeEditionSnaps: publisherStoryEditorSelectors.getEditionSnaps(state)(activeEdition.id),
    activePublisherId: userSelectors.getActivePublisherId(state),
    publisher,
    adControlsEnabled: userSelectors.getAdControlsEnabled(state),
    ageGateEnabled: editionEntityHelpers.isAgeGateEnabled(activeEdition),
    publisherAgeGateEnabled: publishersSelectors.isPublisherAgeGateEnabled(state),
    isActiveEditionReadOnly: publisherStoryEditorSelectors.getActiveEditionIsReadOnly(state),
    isActiveEditionSaving: editionsSelectors.getEditionSavingById(state)(_.get(activeEdition, 'id')) > 0,
    isAdvertisingEnabled: publishersSelectors.isAdvertisingEnabled(state),
    isAlwaysShowAdControlsEnabled: featuresSelectors.isAlwaysShowAdControlsEnabled(state),
    isDisplayDisableAdsControlToggleEnabled: featuresSelectors.isDisableAdsControlEnabled(state),
    isCurationSnapDownloader:
      userSelectors.isCurationSnapDownloader(state) && featuresSelectors.isAdvancedCurationEnabled(state),
    isDebugEntityViewer: userSelectors.hasClaimForActivePublisher(state, Claim.DEBUG_ENTITY_VIEWER),
    isDynamicAdsEnabled: publishersSelectors.isDynamicAdsEnabled(state),
    isOptionalAdsOverrideEnabled: featuresSelectors.isOptionalAdsOverrideEnabled(state),
    isProductPlacementEnabled: featuresSelectors.isProductPlacementEnabled(state),
    isStoryAgeGateEditor: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_AGE_GATE_EDITOR),
    isStoryBlocker: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_BLOCKER),
    isStoryCopierForAtLeastOnePublisher:
      userSelectors.hasClaimForAtLeastOnePublisher(state)(Claim.STORY_COPIER) && !isCurationPublisher(publisher),
    isStoryDeleter: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_DELETER),
    isStoryShareableEditor: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_SHAREABLE_EDITOR),
    isTopsnapDownloader: userSelectors.hasClaimForActivePublisher(state, Claim.TOPSNAP_DOWNLOADER),
    hasAtLeastOneCuratedSnap: publisherStoryEditorSelectors.activeEditionHasAtLeastOneCuratedSnap(state),
    isSuperAdmin: userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    isSingleAssetStoryEditor: activeEdition?.id
      ? publisherStoryEditorSelectors.shouldUseSingleAssetEditor(state)(activeEdition?.id)
      : false,
    isSingleSnapBuilder: activeEdition?.id
      ? publisherStoryEditorSelectors.shouldUseSingleSnapBuilder(state)(activeEdition?.id)
      : false,
    getSingleAssetIsInDebugMode: getSingleAssetIsInDebugMode(state)(activeEdition.id),
    hasSubscribeSnap: editionsSelectors.getEditionHasSubscribeSnap(state)(activeEdition),
    isAdvertisingDisabledForStoryType: publisherStoryEditorSelectors.getIsAdvertisingDisabled(state)(activeEdition),
  };
};
const mapDispatchToProps = {
  setAdControls: userActions.setAdControls,
  setAgeGate,
  renameStoryPrompt,
  showModal: modalsActions.showModal,
  setEditionPrivateArchiveLock,
  deleteStory,
  setEditionShareOption,
  setEditionAdPlacementMode,
  downloadTopsnapAssets,
  updateSingleAssetEditorState,
  downloadSASVideoAsset,
  updateAdSnapIndexes,
  setSnapPropertiesAndSave: snapsActions.setSnapPropertiesAndSave,
};
type DispatchProps = {
  setAdControls: typeof userActions.setAdControls;
  setAgeGate: typeof setAgeGate;
  setEditionShareOption: typeof setEditionShareOption;
  setEditionAdPlacementMode: typeof setEditionAdPlacementMode;
  renameStoryPrompt: typeof renameStoryPrompt;
  showModal: typeof modalsActions.showModal;
  setEditionPrivateArchiveLock: typeof setEditionPrivateArchiveLock;
  deleteStory: typeof deleteStory;
  downloadTopsnapAssets: typeof downloadTopsnapAssets;
  updateSingleAssetEditorState: typeof updateSingleAssetEditorState;
  downloadSASVideoAsset: typeof downloadSASVideoAsset;
  updateAdSnapIndexes: typeof updateAdSnapIndexes;
  setSnapPropertiesAndSave: typeof snapsActions.setSnapPropertiesAndSave;
};

type Props = OwnProps & DispatchProps;
export class StoryOptionsButtonPopover extends React.Component<Props, OwnState> {
  static contextTypes = {
    getScrollContainer: PropTypes.func,
  };

  state = {
    ageGateAlertIsShowing: false,
  };

  shouldComponentUpdate(nextProps: Props, nextState: OwnState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  showRenamePopup = () => {
    this.props.renameStoryPrompt({
      storyId: this.props.activeEdition.id,
    });
  };

  hideAllAlerts = () => {
    this.setState({
      ageGateAlertIsShowing: false,
      deleteStoryAlertIsShowing: false,
    });
  };

  editionIsHidden() {
    return editionEntityHelpers.isHidden(this.props.activeEdition);
  }

  handleDeleteStoryConfirm = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'delete-story');
    this.hideAllAlerts();
    this.props.deleteStory(this.props.activeEdition.id);
  };

  handleAdControlsChange = (value: any) => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-ad-controls', {
      from: this.props.adControlsEnabled,
      to: value,
    });
    this.props.setAdControls(value);
  };

  handleSingleAssetEditorDebugChange = (value: any) => {
    this.props.updateSingleAssetEditorState(this.props.activeEdition.id, {
      isInDebugMode: value,
    });
  };

  handleAgeGateControlsChange = (value: any) => {
    if (value) {
      this.setState({ ageGateAlertIsShowing: true });
    } else if (this.props.ageGateEnabled !== value) {
      gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-age-gating', {
        from: this.props.activeEdition.ageGate,
        to: AgeGate.UNRESTRICTED,
      });
      this.props.setAgeGate(this.props.activeEdition.id, AgeGate.UNRESTRICTED);
    }
  };

  handleShareableChange = (value: boolean) => {
    const shareOption = editionEntityHelpers.isShareable(this.props.activeEdition)
      ? ShareOption.NO_SHARE
      : ShareOption.SHARE_LIVE_ARCHIVED;
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-shareable', {
      from: this.props.activeEdition.shareOption,
      to: shareOption,
    });
    // Why 'SHARE_LIVE_ARCHIVED' and not 'SHARE_LIVE'? The old angular app only used the archived one so I figured that
    // is safer in the sense of having been more battle-tested
    this.props.setEditionShareOption({
      editionId: this.props.activeEdition.id,
      shareOption,
    });
  };

  handleFixedAdSlotsOverrideChange = () => {
    const isFixedAdSlotsOverrideEnabled = editionEntityHelpers.isFixedAdSlotsOverrideEnabled(this.props.activeEdition);
    const newAdPlacementMode = isFixedAdSlotsOverrideEnabled
      ? AdPlacementMode.OPTIONAL_AD_SLOTS
      : AdPlacementMode.FIXED_AD_SLOTS;
    this.props.setEditionAdPlacementMode({
      editionId: this.props.activeEdition.id,
      adPlacementMode: newAdPlacementMode,
    });
  };

  handleDisablingAdsForStoryType = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-disable-ads-on-story', {
      from: this.props.isAdvertisingDisabledForStoryType,
      to: !this.props.isAdvertisingDisabledForStoryType,
    });

    if (this.props.isSingleSnapBuilder) {
      this.handleDisablingAdsForUnifiedSingleSnap();
    } else {
      this.handleDisablingAdsForLegacyStory();
    }
  };

  handleDisablingAdsForLegacyStory = () => {
    // If we want to disable advertising on the story, we need to:
    // - allow ad takeover (which means flippgint FIXED_AD_SLOTS)
    // - delete all existing ad indexes
    // If a super admin makes a mistake and flips the switch by accident, we want to reassure them there will be ads and hence we regenerated the ads but keep "All ad takeover" on
    const isAdvertisingDisabledForStory = editionEntityHelpers.isAdvertisingDisabledForStory(this.props.activeEdition);
    const adIndexes = isAdvertisingDisabledForStory
      ? generateNewAdIndexes(this.props.publisher, this.props.activeEdition.snapIds.length, this.props.hasSubscribeSnap)
      : EMPTY_ARRAY;

    this.props
      .setEditionAdPlacementMode({
        editionId: this.props.activeEdition.id,
        adPlacementMode: AdPlacementMode.FIXED_AD_SLOTS,
      })
      // @ts-expect-error
      .then(() => {
        this.props.updateAdSnapIndexes(this.props.activeEdition.id, adIndexes);
      });
  };

  handleDisablingAdsForUnifiedSingleSnap = () => {
    const unifiedSingleSnap = getUnifiedSingleSnap(this.props.activeEditionSnaps as SingleAssetSnap[]);
    if (unifiedSingleSnap?.id) {
      this.props.setSnapPropertiesAndSave(
        { snapId: unifiedSingleSnap.id },
        { isAdsDisabled: !unifiedSingleSnap.isAdsDisabled }
      );
    }
  };

  updateIsAdsDisabledForUnifiedSingleSnap = (
    unifiedSingleSnap: SingleAssetSnap | undefined,
    isAdsDisabled: boolean
  ) => {
    if (unifiedSingleSnap?.id) {
      this.props.setSnapPropertiesAndSave({ snapId: unifiedSingleSnap.id }, { isAdsDisabled });
    }
  };

  showSponsoredPopup = () => {
    const { id, isSponsored } = this.props.activeEdition;
    const options = {
      editionId: id,
      isSponsored,
    };
    this.props.showModal(ModalType.SPONSORED_CONTENT_MODAL, 'SponsoredContentModal', options);
  };

  showStorySnapcodeSharingModal = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'edition-show-snapcodes');
    const options = { entityId: this.props.activeEdition.id, title: this.props.activeEdition.title };
    this.props.showModal(ModalType.STORY_SNAPCODE_SHARING_MODAL, 'StorySnapcodeSharingModal', options);
  };

  showDeleteStoryAlert = () => {
    this.setState({ deleteStoryAlertIsShowing: true });
  };

  updateShareableStyle() {
    const isAllowedToToggleShareable =
      !this.props.publisherAgeGateEnabled &&
      this.props.isStoryShareableEditor &&
      !this.props.isActiveEditionSaving &&
      !this.props.isActiveEditionReadOnly &&
      !this.props.ageGateEnabled;
    return classNames(SnapPopoverConfig[SnapPopoverRowIds.SHAREABLE].className, {
      [SnapPopoverButtonRowStyle.disabled]: !isAllowedToToggleShareable,
    });
  }

  updateManageSnapcodesStyle() {
    const isSnapcodesEnabled = editionEntityHelpers.isShareable(this.props.activeEdition) && !this.props.ageGateEnabled;
    return classNames(SnapPopoverConfig[SnapPopoverRowIds.MANAGE_SNAPCODES].className, {
      [SnapPopoverButtonRowStyle.disabled]: !isSnapcodesEnabled,
    });
  }

  updateAgeGateControlStyle() {
    const { publisherAgeGateEnabled, isActiveEditionReadOnly, isStoryAgeGateEditor } = this.props;
    const isAgeGatingDisabled = publisherAgeGateEnabled || isActiveEditionReadOnly || !isStoryAgeGateEditor;
    return classNames(SnapPopoverConfig[SnapPopoverRowIds.AGE_GATE].className, {
      [SnapPopoverButtonRowStyle.disabled]: isAgeGatingDisabled,
    });
  }

  handleDuplicatingOptions = () => {
    const options: CopyToPublisherOptions = {
      copySnap: false,
    };
    this.props.showModal(ModalType.COPY_TO_PUBLISHER, 'StoryOptionsPopover', options);
  };

  handleDebugStory = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'show-debug-story');
    const { activeEdition, publisher } = this.props;
    locationUtils.openInNewWindow(
      localRoutes.debug.edition({ editionId: activeEdition.id, hostUsername: publisher.hostUsername })
    );
  };

  handleMagicSearchStory = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'show-magic-search-story');
    const { activeEdition } = this.props;
    goToMagicSearch(activeEdition.id);
  };

  handleDownloadStoryAssets = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'download-top-snap-assets');
    this.props.downloadTopsnapAssets({ editionId: this.props.activeEdition.id });
  };

  handleDownloadSASAssets = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'download-single-asset-story-assets');
    this.props.downloadSASVideoAsset({ storyId: this.props.activeEdition.id });
  };

  handleAgeGateEnabled = () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-age-gating', {
      from: this.props.activeEdition.ageGate,
      to: AgeGate.EXPLICIT,
    });
    this.hideAllAlerts();
    this.props.setAgeGate(this.props.activeEdition.id, AgeGate.EXPLICIT);
  };

  handlePrivateArchiveLock = (hiddenLock: boolean) => {
    if (!this.props.isStoryBlocker) {
      return;
    }
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'toggle-lock-private-archive', {
      hiddenLock,
    });
    this.hideAllAlerts();
    const editionId = this.props.activeEdition.id;
    this.props.setEditionPrivateArchiveLock({ editionId, hiddenLock });
  };

  canDeleteStory() {
    // Only allow deleting drafts that were never live
    return (
      editionEntityHelpers.isDraft(this.props.activeEdition) &&
      !this.props.activeEdition.firstLiveDate &&
      this.props.isStoryDeleter
    );
  }

  canDebugStory() {
    return this.props.isDebugEntityViewer;
  }

  canDownloadStoryTopsnaps() {
    return (
      this.props.isTopsnapDownloader && (!this.props.hasAtLeastOneCuratedSnap || this.props.isCurationSnapDownloader)
    );
  }

  shouldRenderPrivateArchiveLock() {
    return this.editionIsHidden() && this.props.isStoryBlocker;
  }

  renderAgeGateControl() {
    return (
      <div data-test="storyOptionPopOver.ageGate">
        <div className={style.lineSeparator} />
        <ListItemWithToggle
          {...SnapPopoverConfig[SnapPopoverRowIds.AGE_GATE]}
          className={this.updateAgeGateControlStyle()}
          onChange={this.handleAgeGateControlsChange}
          value={this.props.publisherAgeGateEnabled || this.props.ageGateEnabled}
        />
      </div>
    );
  }

  renderSnapcodeControl() {
    return (
      <div>
        <div className={style.lineSeparator} />
        <ListItemWithIcon
          onClick={this.showStorySnapcodeSharingModal}
          {...SnapPopoverConfig[SnapPopoverRowIds.SHARING]}
          className={this.updateManageSnapcodesStyle()}
        />
      </div>
    );
  }

  renderDeleteStoryControl() {
    return (
      <div>
        <div className={style.lineSeparator} />
        <ListItemWithIcon {...SnapPopoverConfig[SnapPopoverRowIds.DELETE_STORY]} onClick={this.showDeleteStoryAlert} />
      </div>
    );
  }

  renderCopyStory() {
    return (
      <div>
        <div className={style.lineSeparator} />
        <ListItemWithIcon
          data-test="storyEditor.StoryOptionsPopover.CopyMenuItem"
          {...SnapPopoverConfig[SnapPopoverRowIds.COPY_TO_STORY]}
          onClick={this.handleDuplicatingOptions}
        />
      </div>
    );
  }

  renderRenameStory() {
    const className = classNames({
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [SnapPopoverConfig[SnapPopoverRowIds.RENAME_STORY].className]: true,
      [SnapPopoverButtonRowStyle.disabled]: this.props.isActiveEditionReadOnly,
    });
    return (
      <div>
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.RENAME_STORY]}
          className={className}
          onClick={this.showRenamePopup}
        />
      </div>
    );
  }

  renderPrivateArchiveLock() {
    return (
      <div>
        <ListItemWithToggle
          {...SnapPopoverConfig[SnapPopoverRowIds.PRIVATE_ARCHIVE_LOCK]}
          value={this.props.activeEdition.hiddenLock}
          onChange={this.handlePrivateArchiveLock}
          isReadOnly={!this.props.isStoryBlocker}
        />
      </div>
    );
  }

  renderSponsoredOption() {
    const className = classNames({
      [SnapPopoverConfig[SnapPopoverRowIds.SPONSORED].className as string]: true,
      [SnapPopoverButtonRowStyle.disabled]: this.props.isActiveEditionReadOnly,
    });

    return (
      <div>
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.SPONSORED]}
          className={className}
          onClick={this.showSponsoredPopup}
        />
      </div>
    );
  }

  renderDebugStory() {
    return (
      <div>
        <div className={style.lineSeparator} />
        <ListItemWithIcon {...SnapPopoverConfig[SnapPopoverRowIds.DEBUG_STORY]} onClick={this.handleDebugStory} />
      </div>
    );
  }

  renderMagicSearchStory() {
    return (
      <div>
        <div className={style.lineSeparator} />
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.MAGIC_SEARCH_STORY]}
          onClick={this.handleMagicSearchStory}
        />
      </div>
    );
  }

  renderStoryDownloadAssets() {
    if (this.props.isSingleAssetStoryEditor) {
      return (
        <div>
          <div className={style.lineSeparator} />
          <ListItemWithIcon
            {...SnapPopoverConfig[SnapPopoverRowIds.STORY_DOWNLOAD_VIDEO]}
            onClick={this.handleDownloadSASAssets}
          />
        </div>
      );
    }
    return (
      <div>
        <div className={style.lineSeparator} />
        <ListItemWithIcon
          {...SnapPopoverConfig[SnapPopoverRowIds.STORY_DOWNLOAD_TOPSNAPS]}
          onClick={this.handleDownloadStoryAssets}
        />
      </div>
    );
  }

  renderAgeGateModal() {
    const { title, confirmText, cancelText } = SnapPopoverModalConfig.AgeGate.text;
    return (
      <SDSDialog
        visible
        onCancel={this.hideAllAlerts}
        onOk={this.handleAgeGateEnabled}
        okText={confirmText}
        cancelText={cancelText}
        data-test="dialog.ageGate"
      >
        {title}
      </SDSDialog>
    );
  }

  renderDeleteStoryAlert() {
    const { title, confirmText, cancelText } = SnapPopoverModalConfig.DeleteStory.text;
    return (
      <SDSDialog
        onCancel={this.hideAllAlerts}
        onOk={this.handleDeleteStoryConfirm}
        okText={confirmText}
        cancelText={cancelText}
        visible
        data-test="dialog.delete"
      >
        {title}
      </SDSDialog>
    );
  }

  renderOptionsControls() {
    return (
      <div>
        <div className={style.lineSeparator} />
        <CheetahStoryOptionsControls
          storyId={this.props.activeEdition.id}
          hostUsername={this.props.publisher?.hostUsername}
        />
      </div>
    );
  }

  renderAdControls() {
    const {
      isDynamicAdsEnabled,
      isOptionalAdsOverrideEnabled,
      isAlwaysShowAdControlsEnabled,
      isDisplayDisableAdsControlToggleEnabled,
      isSingleSnapBuilder,
    } = this.props;
    return (
      <>
        {isDynamicAdsEnabled && isOptionalAdsOverrideEnabled && (
          <ListItemWithToggle
            {...SnapPopoverConfig[SnapPopoverRowIds.AD_TAKEOVER]}
            onChange={this.handleFixedAdSlotsOverrideChange}
            value={editionEntityHelpers.isFixedAdSlotsOverrideEnabled(this.props.activeEdition)}
            isReadOnly={this.props.isActiveEditionReadOnly}
          />
        )}
        {((isDynamicAdsEnabled && isOptionalAdsOverrideEnabled && this.props.isSuperAdmin) ||
          isDisplayDisableAdsControlToggleEnabled) && (
          <ListItemWithToggle
            {...SnapPopoverConfig[SnapPopoverRowIds.ADS_DISABLED]}
            onChange={this.handleDisablingAdsForStoryType}
            value={this.props.isAdvertisingDisabledForStoryType}
            isReadOnly={this.props.isActiveEditionReadOnly}
          />
        )}
        {!isDynamicAdsEnabled && !isAlwaysShowAdControlsEnabled && !isSingleSnapBuilder && (
          <ListItemWithToggle
            {...SnapPopoverConfig[SnapPopoverRowIds.AD_CONTROL]}
            onChange={this.handleAdControlsChange}
            value={this.props.adControlsEnabled}
          />
        )}
      </>
    );
  }

  render() {
    return (
      <div>
        <PopoverCloseOnMovement
          id="StoryOptionsPopover"
          target={this.props.children}
          {...this.props}
          arrowOffsetLeft="184px"
          className={style.popover}
          closeOnVerticalMovement={false}
          container={this.context.getScrollContainer}
          data-test="story.option.menu"
        >
          {!this.props.isSingleAssetStoryEditor && <ShowTilesToggle />}
          {this.props.isAdvertisingEnabled && this.renderAdControls()}
          <ListItemWithToggle
            {...SnapPopoverConfig[SnapPopoverRowIds.SHAREABLE]}
            onChange={this.handleShareableChange}
            value={!this.props.publisherAgeGateEnabled && editionEntityHelpers.isShareable(this.props.activeEdition)}
            className={this.updateShareableStyle()}
          />
          {this.props.isProductPlacementEnabled && this.renderSponsoredOption()}
          {this.props.isSingleAssetStoryEditor && this.props.isDebugEntityViewer && (
            <ListItemWithToggle
              {...SnapPopoverConfig[SnapPopoverRowIds.SINGLE_ASSET_EDITOR_DEBUG]}
              onChange={this.handleSingleAssetEditorDebugChange}
              value={this.props.getSingleAssetIsInDebugMode}
            />
          )}
          {this.shouldRenderPrivateArchiveLock() ? this.renderPrivateArchiveLock() : null}
          {this.renderAgeGateControl()}
          {this.renderOptionsControls()}
          <div className={style.lineSeparator} data-test="storyEditor.StoryOptionsPopover.lineSeparator" />
          {this.renderRenameStory()}
          {this.props.activeEdition.state !== StoryState.NEW ? this.renderSnapcodeControl() : null}
          {this.props.isStoryCopierForAtLeastOnePublisher ? this.renderCopyStory() : null}
          {this.canDeleteStory() ? this.renderDeleteStoryControl() : null}
          {this.canDebugStory() ? this.renderDebugStory() : null}
          {this.canDebugStory() ? this.renderMagicSearchStory() : null}
          {this.canDownloadStoryTopsnaps() ? this.renderStoryDownloadAssets() : null}
        </PopoverCloseOnMovement>
        {this.state.ageGateAlertIsShowing ? this.renderAgeGateModal() : null}
        {(this.state as any).deleteStoryAlertIsShowing ? this.renderDeleteStoryAlert() : null}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryOptionsButtonPopover);
