import invariant from 'invariant';
import { difference, keyBy, isEqual, forOwn, get, fromPairs } from 'lodash';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { isTopSnap, asTopSnap } from 'state/snaps/schema/snapEntityHelpers';

import { EMPTY_ARRAY, ScsMagneticSegment } from 'config/constants';
import { assertArg, assertState } from 'utils/assertionUtils';

import type { SegmentNode, SnapNode } from './StoryCarouselTypes';

import type { SnapId } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { SnapReorderOperation } from 'types/scs';
import type { Segment, SegmentID } from 'types/segments';
import type { Snap } from 'types/snaps';

type SegmentDiff = {
  id?: SegmentID;
  editionId?: EditionID;
  snapIds?: SnapId[];
};

type EditionDiffInternal = {
  snapIds: SnapId[];
  existingSegmentsById: {
    [k in SegmentID]: SegmentDiff;
  };
  newSegments: SegmentDiff[];
};

type EditionDiff = {
  snapIds?: SnapId[];
  segments?: SegmentDiff[];
};

/*
 * This helper class calculates segment and snap order changes for the StoryCarousel.
 * It represents the carousel in two levels, segment and snap:
 *
 * segment: [{
 *  id: ...,
 *  key: ...,
 *  index: ...,
 *  snapNodes: [{
 *    id: ...,
 *    key: ...,
 *    index: ...,
 *    snap: ...,
 *  }],
 * }]
 *
 * At each level there is an 'index' that represents the index in the story carousel for segment, or the index in the segment
 * for snap. The order they are rendered on screen follow the index.
 *
 * The 'key' is necessary for react to render lists of elements, and is also used for triggering animations by changing the index
 * of a moved element, that tricks react into triggering a remove/add animation.
 *
 * This class also calculates the edition diff that is necessary for saving to the backend.
 */
export class StoryChangeManager {
  nextKey: number;

  editionId: EditionID | undefined | null;

  baselineSegmentNodes: SegmentNode[];

  orderedSegmentNodes: SegmentNode[];

  // Properties to track movement and help calculate reorder operation
  lastFromIndex: number[] | undefined | null;

  lastToIndex: number[] | undefined | null;

  numSnaps: number | undefined | null;

  originalSegmentNodes: SegmentNode[] | undefined | null;

  makeNew: boolean;

  static create(): StoryChangeManager {
    return new StoryChangeManager();
  }

  constructor() {
    this.nextKey = 0;
    this.baselineSegmentNodes = [];
    this.orderedSegmentNodes = [];
    this.makeNew = false;
  }

  setEdition(edition: Edition, editionSnaps: Snap[]): void {
    this.orderedSegmentNodes = this.generateSegmentNodes(edition, editionSnaps);
    this.editionId = edition.id;
    this.baselineSegmentNodes = this.orderedSegmentNodes;
  }

  _nextId(): number {
    return this.nextKey++;
  }

  generateSegmentNodes(edition: Edition, editionSnaps: Snap[]): SegmentNode[] {
    const snapsById: {
      [k in SnapId]: Snap | undefined | null;
    } = keyBy(editionSnaps, snap => snap?.id);

    const snapIds = edition.snapIds || EMPTY_ARRAY;
    let snapIdsOutsideSegments: SnapId[] = snapIds.slice();

    // Creating real segments based on edition segments
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: SegmentID; key: string; isVirtualSegme... Remove this comment to see the full error message
    let segments: SegmentNode[] = (edition.segments || EMPTY_ARRAY).map((segment: Segment, index: number) => {
      // removing segment snapIds from edition pool of ids
      snapIdsOutsideSegments = difference(snapIdsOutsideSegments, segment.snapIds);

      return {
        id: segment.id,
        key: `SEGMENT-${this._nextId()}`,
        isVirtualSegment: false,
        index,
        snapNodes: segment.snapIds
          .map((snapId: SnapId, snapIndex: number) => {
            const snap: Snap | undefined | null = snapsById[snapId];
            if (!snap || !isTopSnap(snap)) {
              return null;
            }
            const topSnap = asTopSnap(snap);
            invariant(topSnap, 'snap is not a topsnap');

            return {
              id: snapId,
              key: `SNAP-${this._nextId()}`,
              index: snapIndex,
              snap: topSnap,
            };
          })
          .filter(Boolean),
      };
    });

    // Creating virtual segments based on snaps that don't belong to any segment
    segments = segments
      .concat(
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        snapIdsOutsideSegments.map((snapId: SnapId) => {
          const snap: Snap | undefined | null = snapsById[snapId];
          if (!snap || !isTopSnap(snap)) {
            return null;
          }
          const topSnap = asTopSnap(snap);
          invariant(topSnap, 'snap is not a topsnap');

          return {
            id: `PLACEHOLDER-SEGMENT-${snapId}`,
            key: `SEGMENT-${this._nextId()}`,
            isVirtualSegment: true,
            index: 0,
            snapNodes: [
              {
                id: snapId,
                key: `SNAP-${this._nextId()}`,
                index: 0,
                snap: topSnap,
              },
            ],
          };
        })
      )
      .filter(Boolean);

    // Sorting segment nodes based on the index of their first snap in edition, or they will
    // show out of order in the carousel
    const snapIdToIndexInEdition = fromPairs(snapIds.map((id: SnapId, index: number) => [id, index]));
    segments.sort((a, b) => {
      if (!a.snapNodes.length && !b.snapNodes.length) {
        return 0;
      }
      if (a.snapNodes.length && !b.snapNodes.length) {
        return 1;
      }
      if (!a.snapNodes.length && b.snapNodes.length) {
        return -1;
      }
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return snapIdToIndexInEdition[a.snapNodes[0].snap.id] - snapIdToIndexInEdition[b.snapNodes[0].snap.id];
    });
    // Adjusting indexes since the segments were sorted
    return u.freeze(segments.map((segment, index) => ({ ...segment, index })));
  }

  updateEdition(edition: Edition, editionSnaps: Snap[]): void {
    const newSegmentNodes = this.generateSegmentNodes(edition, editionSnaps);
    const oldSegmentById = {};
    const segmentByFirstSnapId = {};
    const oldSnapBySegmentKeyAndId = {};
    this.orderedSegmentNodes.forEach(segment => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      oldSegmentById[segment.id] = segment;
      if (segment.snapNodes.length) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        segmentByFirstSnapId[segment.snapNodes[0].id] = segment;
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      oldSnapBySegmentKeyAndId[segment.key] = {};
      segment.snapNodes.forEach(snap => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        oldSnapBySegmentKeyAndId[segment.key][snap.id] = snap;
      });
    });

    const matchingSegmentsBlackList: any = [];
    this.orderedSegmentNodes = newSegmentNodes.map(
      (newSegment: SegmentNode): SegmentNode => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        let matchingSegment: SegmentNode | undefined | null = oldSegmentById[newSegment.id];
        if (matchingSegmentsBlackList.includes(matchingSegment)) {
          matchingSegment = undefined;
        }

        // If no matching segment found, it might be because the id has changed because of placeholders.
        // Let's try to find based on the snap ids instead
        if (!matchingSegment) {
          const newSnapIds = newSegment.snapNodes.map(node => node.id);

          matchingSegment = this.orderedSegmentNodes.find(oldSegment => {
            // Let's ensure a segment key is never reused
            if (matchingSegmentsBlackList.includes(oldSegment)) {
              return false;
            }

            const oldSegmentSnapIds = oldSegment.snapNodes.map(node => node.id);

            // This treats the special case when a snap is deleted from a segment that has 2 snaps, which deletes the segment.
            // We'd like the segment key to remain the same or animations will be wrong
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | undefined' is not assig... Remove this comment to see the full error message
            if (newSnapIds.length === 1 && oldSegmentSnapIds.includes(newSnapIds[0])) {
              return true;
            }

            // This treats the special case when a snap is added to a placeholder segment, which changes the segment id
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | undefined' is not assig... Remove this comment to see the full error message
            if (oldSegmentSnapIds.length === 1 && newSnapIds.includes(oldSegmentSnapIds[0])) {
              return true;
            }

            return isEqual(newSnapIds, oldSegmentSnapIds);
          });
        }

        if (!matchingSegment) {
          return newSegment;
        }

        matchingSegmentsBlackList.push(matchingSegment);

        const segmentWithAdjustedKey: SegmentNode = { ...newSegment, key: matchingSegment.key };

        if (isEqual(segmentWithAdjustedKey, matchingSegment)) {
          return matchingSegment;
        }

        return {
          ...segmentWithAdjustedKey,
          snapNodes: newSegment.snapNodes.map(
            (snap: SnapNode): SnapNode => {
              const oldSnap: SnapNode | undefined | null = get(oldSnapBySegmentKeyAndId, [
                segmentWithAdjustedKey.key,
                snap.id.toString(),
              ]);
              if (!oldSnap) {
                return snap;
              }

              const snapWithAdjustedKey: SnapNode = { ...snap, key: oldSnap.key };

              if (isEqual(snapWithAdjustedKey, oldSnap)) {
                return oldSnap;
              }

              return snapWithAdjustedKey;
            }
          ),
        };
      }
    );

    this.editionId = edition.id;
    this.baselineSegmentNodes = this.orderedSegmentNodes;
  }

  _extractEditionProperties(segmentNodes: SegmentNode[]): EditionDiffInternal {
    // For now, only calculate snapIds change. Leave segments changes for later.
    const snapIds: SnapId[] = [];
    const newSegments: SegmentDiff[] = [];
    const existingSegmentsById: {
      [k in SegmentID]: SegmentDiff;
    } = {};
    segmentNodes.forEach((segmentNode: SegmentNode) => {
      const segmentSnapIds: any = [];
      const segment: SegmentDiff = {
        snapIds: segmentSnapIds,
      };

      // Don't add id if it's a placeholder id
      if (typeof segmentNode.id === 'number') {
        segment.id = segmentNode.id;
      }

      segmentNode.snapNodes.forEach(snapNode => {
        segmentSnapIds.push(snapNode.snap.id);
        snapIds.push(snapNode.snap.id);
      });

      // Avoid adding placeholder segments, unless they represent a new segment
      // (more than one snap)
      if (segmentNode.isVirtualSegment && segmentNode.snapNodes.length <= 1) {
        return;
      }

      if (!segmentNode.isVirtualSegment && segment.id) {
        existingSegmentsById[segment.id] = segment;
      } else {
        newSegments.push({ ...segment, editionId: this.editionId || undefined });
      }
    });

    return {
      snapIds,
      existingSegmentsById,
      newSegments,
    };
  }

  buildEditionDiff(): any {
    const oldEditionProperties: EditionDiffInternal = this._extractEditionProperties(this.baselineSegmentNodes);
    const newEditionProperties: EditionDiffInternal = this._extractEditionProperties(this.orderedSegmentNodes);
    const propertiesDiff: EditionDiff = {};

    if (!isEqual(oldEditionProperties.snapIds, newEditionProperties.snapIds)) {
      propertiesDiff.snapIds = newEditionProperties.snapIds;
    }

    if (
      isEqual(oldEditionProperties.existingSegmentsById, newEditionProperties.existingSegmentsById) &&
      newEditionProperties.newSegments.length === 0
    ) {
      return propertiesDiff;
    }

    propertiesDiff.segments = [];

    forOwn(newEditionProperties.existingSegmentsById, (segment, key) => {
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const oldSegment = oldEditionProperties.existingSegmentsById[segment.id];

      if (oldSegment && isEqual(oldSegment.snapIds, segment.snapIds) && propertiesDiff.segments) {
        propertiesDiff.segments.push({ id: segment.id });
        return;
      }

      if (propertiesDiff.segments) {
        propertiesDiff.segments.push({ id: segment.id, snapIds: segment.snapIds });
      }
    });

    if (propertiesDiff.segments) {
      propertiesDiff.segments = propertiesDiff.segments.concat(newEditionProperties.newSegments);
    }

    return propertiesDiff;
  }

  getSegmentNodes(): SegmentNode[] {
    return this.orderedSegmentNodes;
  }

  resetMovement() {
    this.lastFromIndex = null;
    this.lastToIndex = null;
    this.numSnaps = null;
    this.originalSegmentNodes = null;
    this.makeNew = false;
  }

  startMovement() {
    this.resetMovement();
  }

  endMovement() {
    this.resetMovement();
  }

  calculateReorderOperation(): SnapReorderOperation {
    invariant(this.lastFromIndex, 'Missing last from index');
    invariant(this.lastToIndex, 'Missing last to index');
    invariant(this.originalSegmentNodes, 'Missing original segment nodes');

    const from = this.lastFromIndex;
    const to = this.lastToIndex;
    const flatFrom = this.flattenIndex(this.originalSegmentNodes, from);
    const flatTo = this.flattenIndex(this.orderedSegmentNodes, to);

    let magneticSegment = this.makeNew ? ScsMagneticSegment.NEW : ScsMagneticSegment.NONE;

    if (to.length === 2) {
      const positionInSegment = to[1];
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const segmentSnaps = this.orderedSegmentNodes[to[0]].snapNodes.length;

      if (to[1] === 0) {
        magneticSegment = ScsMagneticSegment.RIGHT;
      }
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (positionInSegment >= segmentSnaps - 1) {
        magneticSegment = ScsMagneticSegment.LEFT;
      }
    }

    invariant(this.numSnaps, 'Missing numSnaps');

    return {
      index: flatFrom,
      offset: flatTo - flatFrom,
      numSnaps: this.numSnaps,
      magneticSegment,
    };
  }

  flattenIndex(segmentNodes: SegmentNode[], index: number[]): number {
    const numSnapsBefore = segmentNodes
      .slice(0, index[0])
      .map(segmentNode => {
        return segmentNode.snapNodes.length;
      })
      .reduce((a, b) => a + b, 0);

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return numSnapsBefore + (index.length === 1 ? 0 : index[1]);
  }

  // convert a virtual (single snap) segment to a real single snap segment, and vice versa
  convertSingleSnapSegment(segmentIndex: number, toReal: boolean) {
    this.lastFromIndex = [segmentIndex];
    this.lastToIndex = [segmentIndex];
    this.numSnaps = 1;
    this.originalSegmentNodes = this.orderedSegmentNodes;
    this.makeNew = toReal;

    let removedSegment: SegmentNode = this._removeSegment(segmentIndex);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(removedSegment.isVirtualSegment).is.equal(toReal);
    // can only convert a single snap segment
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(removedSegment.snapNodes.length).is.equal(1);

    // Re-generating keys or react will move the node instead of recreating it, spoiling the animations
    const segmentPrefix = toReal ? 'SEGMENT' : 'PLACEHOLDER-SEGMENT';
    removedSegment = u(
      {
        key: `${segmentPrefix}-${this._nextId()}`,
        snapNodes: u.map((node: any) => ({
          ...node,
          key: `SNAP-${this._nextId()}`,
        })),
        isVirtualSegment: !toReal,
      },
      removedSegment
    );

    this._addSegment(segmentIndex, removedSegment);
  }

  moveToIndex(oldIndex: number[], newIndex: number[]): number[] {
    if (this.lastFromIndex == null) {
      this.lastFromIndex = oldIndex;
      this.originalSegmentNodes = this.orderedSegmentNodes;
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      this.numSnaps = oldIndex.length === 1 ? this.orderedSegmentNodes[oldIndex[0]].snapNodes.length : 1;
    }

    const nextIndex = this.moveToIndexImpl(oldIndex, newIndex);
    this.lastToIndex = nextIndex;
    return nextIndex;
  }

  moveToIndexImpl(oldIndex: number[], newIndex: number[]): number[] {
    // indexes are arrays with one or two elements. If only one element it's at segment level. If two elements, at snap level
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(oldIndex).is.array();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(oldIndex.length).is.above(0);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(oldIndex.length).is.under(3);

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(newIndex).is.array();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(newIndex.length).is.above(0);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(newIndex.length).is.under(3);

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(oldIndex[0]).is.above(-1);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(oldIndex[0]).is.under(this.orderedSegmentNodes.length);

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(newIndex[0]).is.above(-1);

    if (oldIndex.length === 2) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(oldIndex[1]).is.above(-1);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(oldIndex[1]).is.under(this.orderedSegmentNodes[oldIndex[0]].snapNodes.length);

      // If moving a snap it can create one more segment so segment index can be +1
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(newIndex[0]).is.under(this.orderedSegmentNodes.length + 1);
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(newIndex[0]).is.under(this.orderedSegmentNodes.length);
    }

    if (newIndex.length === 2) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(newIndex[1]).is.above(-1);
      if (oldIndex.length === 2 && oldIndex[0] === newIndex[0]) {
        // Moving inside same segment does not alter snapNodes size
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        assertArg(newIndex[1]).is.under(this.orderedSegmentNodes[newIndex[0]].snapNodes.length);
      } else {
        // Moving inside another segment alters snapNodes size
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        assertArg(newIndex[1]).is.under(this.orderedSegmentNodes[newIndex[0]].snapNodes.length + 1);
      }
    }

    // Segment to segment level
    if (oldIndex.length === 1 && newIndex.length === 1) {
      if (oldIndex[0] === newIndex[0]) {
        return newIndex;
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      let removedSegment: SegmentNode = this._removeSegment(oldIndex[0]);

      // Re-generating keys or react will move the node instead of recreating it, spoiling the animations
      removedSegment = u(
        {
          key: `SEGMENT-${this._nextId()}`,
          index: newIndex[0],
          snapNodes: u.map((node: any) => ({
            ...node,
            key: `SNAP-${this._nextId()}`,
          })),
        },
        removedSegment
      );

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      this._addSegment(newIndex[0], removedSegment);

      return newIndex;
      // Segment to snap level
    }
    if (oldIndex.length === 1 && newIndex.length === 2) {
      // Forbid adding a segment to itself
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(oldIndex[0]).is.not.equal(newIndex[0]);

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      const removedSegment = this._removeSegment(oldIndex[0]);

      // Can't move to snap level if segment has more than one snap
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(removedSegment.snapNodes.length).is.equal(1);

      const snap = u({ key: `SNAP-${this._nextId()}`, index: newIndex[1] }, removedSegment.snapNodes[0]);

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const actualNewIndex = [newIndex[0] < oldIndex[0] ? newIndex[0] : newIndex[0] - 1, newIndex[1]];
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(number | undefined)[]' is not a... Remove this comment to see the full error message
      this._addSnap(actualNewIndex, snap);

      // @ts-expect-error ts-migrate(2322) FIXME: Type '(number | undefined)[]' is not assignable to... Remove this comment to see the full error message
      return actualNewIndex;
      // Snap to snap level
    }
    if (oldIndex.length === 2 && newIndex.length === 2) {
      if (isEqual(oldIndex, newIndex)) {
        return newIndex;
      }

      const { removedSnap, didRemoveSegment } = this._removeSnap(oldIndex);

      const snap = u({ key: `SNAP-${this._nextId()}`, index: newIndex[1] }, removedSnap);

      let actualNewIndex = newIndex;
      if (didRemoveSegment) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        actualNewIndex = [newIndex[0] < oldIndex[0] ? newIndex[0] : newIndex[0] - 1, newIndex[1]];
      }

      this._addSnap(actualNewIndex, snap);

      return actualNewIndex;
      // Snap to segment level
    }
    if (oldIndex.length === 2 && newIndex.length === 1) {
      const { removedSnap, didRemoveSegment } = this._removeSnap(oldIndex);

      const snapNode: SnapNode = u({ key: `SNAP-${this._nextId()}`, index: 0 }, removedSnap);

      const segmentNode: SegmentNode = {
        id: `PLACEHOLDER-SEGMENT-${snapNode.id}`,
        key: `SEGMENT-${this._nextId()}`,
        isVirtualSegment: true,
        index: 0,
        snapNodes: [snapNode],
      };

      let actualNewIndex: number[] = newIndex;
      if (didRemoveSegment) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        actualNewIndex = [newIndex[0] < oldIndex[0] ? newIndex[0] : newIndex[0] - 1];
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      this._addSegment(actualNewIndex[0], segmentNode);

      return actualNewIndex;
    }

    return oldIndex;
  }

  _removeSegment(index: number): SegmentNode {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'SegmentNode | undefined' is not assignable t... Remove this comment to see the full error message
    const removedSegment: SegmentNode = this.orderedSegmentNodes[index];

    // Removing the segment. Changes the array identity so react updates
    this.orderedSegmentNodes = u.reject((segmentNode: any) => segmentNode.index === index, this.orderedSegmentNodes);

    // Adjusting the indexes of the segments after, using updeep to trigger correct react rerenderings
    this.orderedSegmentNodes = this._adjustIndexes(this.orderedSegmentNodes);
    return removedSegment;
  }

  _removeSnap(
    index: number[]
  ): {
    removedSnap: SnapNode;
    didRemoveSegment: boolean;
  } {
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const removedSnap = this.orderedSegmentNodes[index[0]].snapNodes[index[1]];

    let didRemoveSegment = false;
    // If only snap in segment, remove whole segment. If not, remove just the snap
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (this.orderedSegmentNodes[index[0]].snapNodes.length === 1) {
      this.orderedSegmentNodes = u.reject(
        (segmentNode: any) => segmentNode.index === index[0],
        this.orderedSegmentNodes
      );
      this.orderedSegmentNodes = this._adjustIndexes(this.orderedSegmentNodes);
      didRemoveSegment = true;
    } else {
      this.orderedSegmentNodes = u.map((segmentNode: any) => {
        if (segmentNode.index !== index[0]) {
          return segmentNode;
        }
        return {
          ...segmentNode,
          snapNodes: this._adjustIndexes(
            u.reject((snapNode: any) => snapNode.index === index[1], segmentNode.snapNodes)
          ),
        };
      }, this.orderedSegmentNodes);
    }

    return { removedSnap, didRemoveSegment };
  }

  _addSegment(index: number, segment: SegmentNode): void {
    this.orderedSegmentNodes = [
      ...this.orderedSegmentNodes.slice(0, index),
      segment,
      ...this.orderedSegmentNodes.slice(index),
    ];
    this.orderedSegmentNodes = this._adjustIndexes(this.orderedSegmentNodes);
  }

  _adjustIndexes<T>(nodeArray: T[]): T[] {
    return u.map((node: any, i: any) => (node.index === i ? node : { ...node, index: i }), nodeArray);
  }

  _addSnap(index: number[], snap: SnapNode): void {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(index.length).is.equal(2);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(index[0]).is.under(this.orderedSegmentNodes.length);

    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    let segmentToAddTo = this.orderedSegmentNodes[index[0]];
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(index[1]).is.under(segmentToAddTo.snapNodes.length + 1);
    let segmentToAddToSnapNodes = [
      ...segmentToAddTo.snapNodes.slice(0, index[1]),
      snap,
      ...segmentToAddTo.snapNodes.slice(index[1]),
    ];
    segmentToAddToSnapNodes = this._adjustIndexes(segmentToAddToSnapNodes);
    segmentToAddTo = { ...segmentToAddTo, snapNodes: segmentToAddToSnapNodes };

    this.orderedSegmentNodes = u.map(
      (node: any) => (node.index !== index[0] ? node : segmentToAddTo),
      this.orderedSegmentNodes
    );
  }
}

export default StoryChangeManager;
