import { isEqual } from 'lodash';
import React from 'react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import PublishStoryButton from 'views/publisherStoryEditor/components/PublishStoryButton/PublishStoryButton';
import LockUnlockLiveStoryAlert from 'views/publisherStoryEditor/containers/LockUnlockLiveStoryAlert/LockUnlockLiveStoryAlert';

import { LiveEditStatus } from 'types/editions';
import { Claim } from 'types/permissions';

const mapStateToProps = (state: State) => {
  return {
    activeStory: publisherStoryEditorSelectors.getActiveEdition(state),
    isStoryPublisher: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_PUBLISHER),
  };
};

type LockUnlockLiveStoryButtonProps = {
  activeStory?: any;
  isStoryPublisher: boolean;
};

type LockUnlockLiveStoryButtonState = any;

export class LockUnlockLiveStoryButton extends React.Component<
  LockUnlockLiveStoryButtonProps,
  LockUnlockLiveStoryButtonState
> {
  state = {
    lockUnlockAlertIsShowing: false,
  };

  shouldComponentUpdate(nextProps: LockUnlockLiveStoryButtonProps, nextState: LockUnlockLiveStoryButtonState) {
    return (nextProps && !isEqual(this.props, nextProps)) || (nextState && !isEqual(this.state, nextState));
  }

  storyIsUnlocked = () => this.props.activeStory.liveEditStatus === LiveEditStatus.IN_PROGRESS;

  showLockUnlockAlert = () => this.setState({ lockUnlockAlertIsShowing: true });

  hideLiveStoryAlert = () => this.setState({ lockUnlockAlertIsShowing: false });

  renderEditStoryButton = () => {
    return (
      <SDSButton
        type={ButtonType.WHITE_ON_GREY}
        data-test="StoryBuilder.LiveEdit.Button"
        onClick={this.showLockUnlockAlert}
        disabled={!this.props.isStoryPublisher}
      >
        {getMessageFromId('edit-story-button')}
      </SDSButton>
    );
  };

  renderPublishUpdatesButton = () => {
    return <PublishStoryButton storyId={this.props.activeStory.id} onClick={this.showLockUnlockAlert} />;
  };

  render() {
    return (
      <div data-test="LockUnlockLiveStoryButton.edit">
        {this.storyIsUnlocked() ? this.renderPublishUpdatesButton() : this.renderEditStoryButton()}
        {this.state.lockUnlockAlertIsShowing ? <LockUnlockLiveStoryAlert onHide={this.hideLiveStoryAlert} /> : null}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, null)(LockUnlockLiveStoryButton);
