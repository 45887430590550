import classNames from 'classnames';
import * as React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import StatusMessageBar from 'views/common/components/StatusMessageBar/StatusMessageBar';
import UploadStatusBar from 'views/common/components/UploadStatusBar/UploadStatusBar';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import subtitlesSvg from 'icons/subtitles.svg';

import style from './SnapStatusMessageBar.scss';

const MessageTypes: {
  [x: string]: string;
} = {
  UPLOADING: 'UPLOADING',
  SAVING: 'SAVING',
};

type MessageConfig = {
  message: ReactNode;
};

type MessagesMapType = {
  [k in keyof typeof MessageTypes]: MessageConfig;
};

const messages: MessagesMapType = u.freeze({
  // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
  [MessageTypes.UPLOADING]: {
    message: (
      <FormattedMessage
        id="snap-message-uploading"
        defaultMessage="Uploading"
        description="snap media is being uploaded"
      />
    ),
  },
  // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
  [MessageTypes.SAVING]: {
    message: (
      <FormattedMessage id="snap-message-saving" defaultMessage="Saving" description="snap media is being saved" />
    ),
  },
});

export type Props = {
  snapIndexInEdition: number;
  isUploading: boolean;
  isSaving: boolean;
  uploadProgress: number;
  className: string | undefined | null;
  snapName: string | undefined | null;
  hasSubtitles: boolean;
};

type State = any;

export class SnapStatusMessageBar extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps: Props) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, {});
  }

  renderIcon(messageConfig: MessageConfig): ReactNode | undefined | null {
    return <SpinnerIcon className={classNames(style.spinner, style.warning)} />;
  }

  renderMessage(messageConfig: MessageConfig): ReactNode {
    return (
      <StatusMessageBar
        className={classNames(this.props.className, style.warning)}
        icon={this.renderIcon(messageConfig)}
        message={messageConfig.message}
      />
    );
  }

  render() {
    const { uploadProgress, className, isSaving, isUploading, hasSubtitles } = this.props;

    // Upload media status has precedence over build status because it's earlier in the pipeline
    if (isUploading) {
      return (
        <UploadStatusBar
          className={style.container}
          progress={uploadProgress}
          barClass={classNames(className, style.warning)}
          spinnerClass={style.warning}
        />
      );
    }

    // eslint-disable-next-line jsx-a11y/alt-text
    const subtitlesIcon = hasSubtitles && <img className={style.subtitlesIcon} src={subtitlesSvg} />;
    let innerChild;
    if (isSaving) {
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      innerChild = this.renderMessage(messages[MessageTypes.SAVING]);
    }

    if (!innerChild && this.props.snapName) {
      innerChild = (
        <div className={classNames(className, style.snapIndex)} data-test="SnapStatusMessageBarIndex">
          {`${this.props.snapIndexInEdition + 1}. ${this.props.snapName}`}
          {subtitlesIcon}
        </div>
      );
    }

    if (!innerChild) {
      innerChild = (
        <div className={classNames(className, style.snapIndex)}>
          {getMessageFromId('snap-index-in-edition', { index: this.props.snapIndexInEdition + 1 })}
          {subtitlesIcon}
        </div>
      );
    }

    return (
      <div className={style.container}>
        {innerChild}
        <div className={style.uploadBarSpacer} />
      </div>
    );
  }
}

export default SnapStatusMessageBar;
