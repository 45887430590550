import classnames from 'classnames';
import React, { memo, useCallback } from 'react';
import type { ChangeEvent } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ContentEditable from 'react-contenteditable';

import style from './SubtitleLine.scss';

type Props = {
  disabled?: boolean;
  subtitleLine: string;
  onChange: (a: string) => void;
};

function SubtitleLine({ disabled, subtitleLine, onChange }: Props) {
  const handleOnChange = useCallback(
    (e: ChangeEvent<EventTarget>) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      const { value } = e.target;
      onChange(value);
    },
    [onChange]
  );

  return (
    <ContentEditable
      disabled={disabled}
      html={subtitleLine}
      dir="auto"
      className={classnames(style.subtitlesLine, { [style.enabled]: !disabled })}
      data-test="subtitlesPreview.subtitlesLine"
      onChange={handleOnChange}
    />
  );
}

export default memo(SubtitleLine);
