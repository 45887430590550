// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { createEmptyPollOption } from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayoutsFactory';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import updeep from 'updeep';

import { createAssetUrl } from 'utils/media/assetUtils';

import ItemSelectPanel from 'views/editor/components/ItemSelectPanel/ItemSelectPanel';

import type { PollProperties as Poll, PollEditorState } from 'types/polls';

type Props = {
  poll: Poll;
  isReadOnly?: boolean;
  pollEditorState: PollEditorState;
  updatePollProperties: (a: Partial<Poll>) => void;
  updatePollEditorState: (a: Partial<PollEditorState>) => void;
};

export default class OutcomePageSelector extends React.PureComponent<Props> {
  handleOutcomeSelected = (selectedOutcomeIndex: number) => {
    this.props.updatePollEditorState({ selectedOutcomeIndex });
  };

  handleDoneEditing = () => {
    this.handleOutcomeSelected(-1);
  };

  handleAddOutcome = () => {
    const pollChanges = updeep(
      {
        outcomes: {
          options: (options: any) => [...options, createEmptyPollOption()],
        },
      },
      this.props.poll
    );

    this.props.updatePollProperties(pollChanges);
  };

  handleRemoveOutcome = (index: number) => {
    const pollChanges = updeep(
      {
        outcomes: {
          options: (options: any) => _.without(options, options[index]),
        },
      },
      this.props.poll
    );

    this.props.updatePollProperties(pollChanges);
  };

  render() {
    const { pollEditorState, poll, isReadOnly } = this.props;

    const selectedOutcomeIndex =
      pollEditorState && typeof pollEditorState.selectedOutcomeIndex === 'number'
        ? pollEditorState.selectedOutcomeIndex
        : -1;

    const outcomes = poll.outcomes
      ? poll.outcomes.options.map(({ imageAssetId, description }) => ({
          description,
          imageUrl: imageAssetId ? createAssetUrl(imageAssetId) : null,
        }))
      : [];

    return (
      <ItemSelectPanel
        items={outcomes}
        isReadOnly={isReadOnly}
        selectedIndex={selectedOutcomeIndex}
        onItemSelected={this.handleOutcomeSelected}
        onAddItem={this.handleAddOutcome}
        onDeleteItem={this.handleRemoveOutcome}
        onDoneEditing={this.handleDoneEditing}
        enableDuplicate={false}
        emptyDescriptionMessageId="outcome-default-text"
        deleteMessageId="confirm-delete-outcome"
        title={
          <FormattedMessage
            id="outcomes-headline"
            defaultMessage="Outcomes"
            description="Right panel headline for quiz outcomes"
          />
        }
      />
    );
  }
}
