import React from 'react';

import { gear } from 'icons/SDS/allIcons';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import StoryOptionsButtonPopover from 'views/publisherStoryEditor/containers/StoryOptionsButtonPopover/StoryOptionsButtonPopover';

type Props = {};

export class StoryOptionsButton extends React.Component<Props> {
  render() {
    return (
      <StoryOptionsButtonPopover>
        <SDSButton
          type={ButtonType.WHITE_ON_GREY}
          shape={ButtonShape.CIRCLE}
          inlineIcon={gear}
          data-test="StoryOptionsButton.button"
        />
      </StoryOptionsButtonPopover>
    );
  }
}

export default StoryOptionsButton;
