import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CropPosition } from 'config/constants';

import style from './CropPreview.scss';

type Props = {
  cropPosition?: string;
};

export default class CropPreview extends React.Component<Props> {
  static cropPositionToStyle = {
    [CropPosition.BOTTOM]: style.bottom,
    [CropPosition.MIDDLE]: style.middle,
    [CropPosition.TOP]: style.top,
  };

  renderCropTop() {
    return (
      <div className={style.cropTopCover}>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop3x5' does not exist on type 'typeof ... Remove this comment to see the full error message */}
        <div className={style.crop3x5}>
          <FormattedMessage id="3x5ratio" description="3:5 ratio" defaultMessage="3:5 RATIO" />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop10x16' does not exist on type 'typeo... Remove this comment to see the full error message */}
        <div className={style.crop10x16}>
          <FormattedMessage id="10x16ratio" description="10:16 ratio" defaultMessage="10:16 RATIO" />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop2x3' does not exist on type 'typeof ... Remove this comment to see the full error message */}
        <div className={style.crop2x3}>
          <FormattedMessage id="2x3ratio" description="2:3 ratio" defaultMessage="2:3 RATIO" />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop3x4' does not exist on type 'typeof ... Remove this comment to see the full error message */}
        <div className={style.crop3x4}>
          <FormattedMessage id="3x4ratio" description="3:4 ratio" defaultMessage="3:4 RATIO" />
        </div>
      </div>
    );
  }

  renderCropBottom() {
    return (
      <div className={style.cropBottomCover}>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop3x4' does not exist on type 'typeof ... Remove this comment to see the full error message */}
        <div className={style.crop3x4}>
          <FormattedMessage id="3x4ratio" description="3:4 ratio" defaultMessage="3:4 RATIO" />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop2x3' does not exist on type 'typeof ... Remove this comment to see the full error message */}
        <div className={style.crop2x3}>
          <FormattedMessage id="2x3ratio" description="2:3 ratio" defaultMessage="2:3 RATIO" />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop10x16' does not exist on type 'typeo... Remove this comment to see the full error message */}
        <div className={style.crop10x16}>
          <FormattedMessage id="10x16ratio" description="10:16 ratio" defaultMessage="10:16 RATIO" />
        </div>
        {/* @ts-expect-error ts-migrate(2551) FIXME: Property 'crop3x5' does not exist on type 'typeof ... Remove this comment to see the full error message */}
        <div className={style.crop3x5}>
          <FormattedMessage id="3x5ratio" description="3:5 ratio" defaultMessage="3:5 RATIO" />
        </div>
      </div>
    );
  }

  render() {
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const cropPositionStyle = CropPreview.cropPositionToStyle[this.props.cropPosition] || style.top;
    return (
      <div className={cropPositionStyle}>
        {this.renderCropTop()}
        {this.renderCropBottom()}
      </div>
    );
  }
}
