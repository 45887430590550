import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isSubtitlesMultiLanguageEnabled } from 'state/features/selectors/featuresSelectors';
import { getActivePublisherDefaultSubtitlesLanguage } from 'state/publishers/selectors/publishersSelectors';
import { setSubtitlesLanguage } from 'state/subtitles/actions/subtitlesActions';
import {
  getAvailableSubtitlesLanguageCodesByVideoAssetId,
  getSubtitlesActiveLanguageCode,
} from 'state/subtitles/selectors/subtitlesSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import SubtitlesLanguagesDropdown from './SubtitlesLanguagesDropdown';

import type { AssetID } from 'types/assets';
import { ExtractDispatchProps } from 'types/redux';

type ExternalProps = {
  videoAssetId: AssetID;
  disabled?: boolean;
};

type StateProps = {
  existingLanguages: string[];
  selectedLanguage: string;
  defaultLanguage: string;
  isSubtitlesMultiLanguageEnabled: boolean;
};

function mapStateToProps(state: State, props: ExternalProps): StateProps {
  return {
    existingLanguages: getAvailableSubtitlesLanguageCodesByVideoAssetId(state)(props.videoAssetId),
    selectedLanguage: getSubtitlesActiveLanguageCode(state),
    defaultLanguage: getActivePublisherDefaultSubtitlesLanguage(state),
    isSubtitlesMultiLanguageEnabled: isSubtitlesMultiLanguageEnabled(state),
  };
}

const mapDispatchToProps = {
  setSubtitlesLanguage,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = ExternalProps & StateProps & DispatchProps;

export class SubtitlesLanguageDropdownForAsset extends React.PureComponent<Props> {
  render() {
    const { existingLanguages, selectedLanguage, defaultLanguage } = this.props;

    return (
      <SDSLabel
        withoutDefaultMargin
        labelTitle={
          <FormattedMessage
            id="subtitles-subtitle-track-dropdown-label"
            description="Label for subtitle track dropdown"
            defaultMessage="Subtitle track"
          />
        }
      >
        <SubtitlesLanguagesDropdown
          defaultLanguage={defaultLanguage}
          selectedLanguage={selectedLanguage}
          existingLanguages={existingLanguages || []}
          onSelectedChange={this.props.setSubtitlesLanguage}
          disabled={!this.props.isSubtitlesMultiLanguageEnabled}
        />
      </SDSLabel>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SubtitlesLanguageDropdownForAsset);
