import classNames from 'classnames';
import is from 'is_js';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { download } from 'icons/SDS/allIcons';

import MediaButtons from 'views/common/components/MediaButtons/MediaButtons';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './MediaSelector.scss';

type OwnProps = {
  createButtonLabel?: any;
  dropzoneType?: string;
  editionId?: number;
  handleMediaCreate?: (...args: any[]) => any;
  handleMediaDelete?: (...args: any[]) => any;
  handleMediaDownload?: (...args: any[]) => any;
  handleMediaTrim?: (...args: any[]) => any;
  hasOverlay?: boolean;
  hasUploadError?: boolean;
  isReadOnly?: boolean;
  isUploading?: boolean;
  mediaDownloadEnabled?: boolean;
  mediaUploaderEnabled?: boolean;
  skipMediaValidation?: boolean;
  snap?: any;
  subtitlesMediaIds?: Array<string>;
  trimMediaEnabled?: boolean;
  uploadPurpose?: string;
};

type Props = OwnProps & typeof MediaSelector.defaultProps;

export default class MediaSelector extends React.Component<Props> {
  static defaultProps = {
    isReadOnly: false,
    mediaDownloadEnabled: false,
    snap: {},
  };

  renderCreateButton() {
    const { createButtonLabel, handleMediaCreate, isReadOnly } = this.props;
    return (
      is.existy(createButtonLabel) && (
        <div className={style.createButton}>
          <SDSButton
            onClick={handleMediaCreate}
            data-test="mediaFile.createMedia.button"
            disabled={isReadOnly}
            type={ButtonType.SECONDARY}
          >
            {createButtonLabel}
          </SDSButton>
        </div>
      )
    );
  }

  renderTrimButton() {
    const { handleMediaTrim, isReadOnly, trimMediaEnabled } = this.props;
    return (
      trimMediaEnabled && (
        <div className={style.createButton}>
          <SDSButton
            data-test="mediaFile.trim.button"
            disabled={isReadOnly}
            onClick={handleMediaTrim}
            type={ButtonType.SECONDARY}
          >
            <FormattedMessage
              id="media-selector-trim-button-label"
              description="Label for trim button on media selector"
              defaultMessage="Trim"
            />
          </SDSButton>
        </div>
      )
    );
  }

  renderMediaDownloadButton() {
    const { handleMediaDownload, isUploading, mediaDownloadEnabled } = this.props;
    return (
      mediaDownloadEnabled && (
        <div className={style.createButton}>
          <SDSButton
            data-test="mediaSelector.downloadButton"
            disabled={isUploading}
            inlineIcon={download}
            onClick={handleMediaDownload}
            shape={ButtonShape.CIRCLE}
            type={ButtonType.SECONDARY}
          />
        </div>
      )
    );
  }

  render() {
    const {
      dropzoneType,
      editionId,
      handleMediaDelete,
      hasOverlay,
      hasUploadError,
      isReadOnly,
      isUploading,
      skipMediaValidation,
      snap,
      subtitlesMediaIds,
      uploadPurpose,
    } = this.props;

    return (
      <div className={classNames(style.discoverRoot)} data-test="mediaFile.allButtons">
        <MediaButtons
          data-test="richSnapEditor.topsnapPanel.topsnap"
          dropzoneType={dropzoneType}
          editionId={editionId}
          hasOverlay={hasOverlay}
          hasUploadError={hasUploadError}
          isReadOnly={isReadOnly}
          isUploading={isUploading}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          onChange={handleMediaDelete}
          purpose={uploadPurpose}
          skipMediaValidation={skipMediaValidation}
          snapId={snap.id}
          subtitlesMediaIds={subtitlesMediaIds}
          uploadButtonIsPrimary
        />
        {this.renderCreateButton()}
        {this.renderTrimButton()}
        {this.renderMediaDownloadButton()}
      </div>
    );
  }
}
