import invariant from 'invariant';
import _ from 'lodash';
import React from 'react';
import { intlShape } from 'react-intl';

import * as subtitlesActions from 'state/subtitles/actions/subtitlesActions';
import * as subtitlesSelectors from 'state/subtitles/selectors/subtitlesSelectors';

import { parseSubtitles, SubtitlePosition } from '../../subtitleParser';
import type { SubtitleFragment, SubtitleTimestamp } from '../../subtitleParser';
import AddSubtitlesFragmentButton from '../AddSubtitlesFragmentButton/AddSubtitlesFragmentButton';
import SubtitleFragmentComponent from '../SubtitleFragmentComponent/SubtitleFragmentComponent';

import type { SubtitleTrack } from 'constants/subtitles';
import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import { Spinner, SpinnerSizes } from 'views/common/components/Spinner/Spinner';
import { updateSubtitlesState } from 'views/subtitles/state/actions/subtitlesEditorActions';
import { getSubtitlesEditorState } from 'views/subtitles/state/selectors/subtitlesEditorSelectors';
import type { SubtitlesEditorState } from 'views/subtitles/state/types/subtitles';

import style from './SubtitlesBody.scss';

import type { AssetID } from 'types/assets';
import type { State as ReduxState } from 'types/rootState';

export type DisplayRange = {
  startTimeMs: number;
  endTimeMs: number;
};

type OwnProps = {
  isEditing: boolean;
  onSubtitlesChanged: (a: SubtitleFragment[]) => void;
  subtitlesTrack: SubtitleTrack | undefined | null;
  videoDuration: number;
  displayRange: DisplayRange | undefined | null;
};

type StateProps = {
  isLoadingSubtitlesTrack: boolean;
  rawSubtitles: string | undefined | null;
  subtitlesAssetId: AssetID | undefined | null;
  subtitlesEditorState: SubtitlesEditorState | undefined | null;
};

type State = {
  subtitles: SubtitleFragment[];
};

type DispatchProps = {
  loadSubtitlesTrack: typeof subtitlesActions.loadSubtitlesTrack;
  updateSubtitlesState: typeof updateSubtitlesState;
};

type Props = StateProps & DispatchProps & OwnProps;

export function mapStateToProps(state: ReduxState, props: OwnProps): StateProps {
  const subtitlesAssetId = props.subtitlesTrack ? props.subtitlesTrack.assetId : null;
  const rawSubtitles = subtitlesAssetId
    ? subtitlesSelectors.getSubtitlesTrackContentById(state)(subtitlesAssetId)
    : null;
  const isLoadingSubtitlesTrack = subtitlesAssetId
    ? subtitlesSelectors.getSubtitlesTrackLoadingById(state)(subtitlesAssetId)
    : false;
  const subtitlesEditorState = subtitlesAssetId ? getSubtitlesEditorState(state)(subtitlesAssetId) : null;
  return {
    isLoadingSubtitlesTrack,
    rawSubtitles,
    subtitlesAssetId,
    subtitlesEditorState,
  };
}

const mapDispatchToProps = {
  loadSubtitlesTrack: subtitlesActions.loadSubtitlesTrack,
  updateSubtitlesState,
};

export class SubtitlesBody extends React.PureComponent<Props, State> {
  static contextTypes = {
    intl: intlShape,
  };

  componentDidMount(): void {
    if (
      this.props.subtitlesTrack &&
      this.props.subtitlesTrack.source &&
      !this.props.isLoadingSubtitlesTrack &&
      !this.props.rawSubtitles
    ) {
      this.props.loadSubtitlesTrack(this.props.subtitlesTrack);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { rawSubtitles, subtitlesAssetId, subtitlesEditorState } = this.props;

    if (
      subtitlesAssetId &&
      rawSubtitles &&
      rawSubtitles !== prevProps.rawSubtitles &&
      subtitlesEditorState?.subtitleFragments[0]?.subtitles !== ''
    ) {
      this.props.updateSubtitlesState(subtitlesAssetId, {
        subtitleFragments: parseSubtitles(rawSubtitles),
      });
    }
  }

  updateSubtitles = (newSubtitles: SubtitleFragment[]) => {
    const { subtitlesAssetId } = this.props;
    invariant(subtitlesAssetId, 'Subtitles asset id must be defined');
    this.props.updateSubtitlesState(subtitlesAssetId, {
      subtitleFragments: newSubtitles,
    });
  };

  getSubtitlesFragments() {
    const { subtitlesEditorState } = this.props;
    invariant(subtitlesEditorState, 'Subtitles editor state should be defined.');
    return subtitlesEditorState.subtitleFragments;
  }

  handleSubtitleChange = (newLine: string, fragmentIndex: number) => {
    const updatedSubtitles = _.cloneDeep(this.getSubtitlesFragments());
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    updatedSubtitles[fragmentIndex].subtitles = newLine;

    this.updateSubtitles(updatedSubtitles);
    grapheneUtils.incrementCounter('subtitles', { input: 'line_edited' });
  };

  handleSubtitleTimestampChange = (timestamp: SubtitleTimestamp, fragmentIndex: number) => {
    const updatedSubtitles = _.cloneDeep(this.getSubtitlesFragments());
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    updatedSubtitles[fragmentIndex].timestamp = timestamp;

    this.updateSubtitles(updatedSubtitles);
    grapheneUtils.incrementCounter('subtitles', { input: 'timestamp_edited' });
  };

  handleSubtitlePositionChange = (fragmentIndex: number) => {
    const subtitleFragments = this.getSubtitlesFragments();
    const updatedSubtitles = _.cloneDeep(subtitleFragments);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    updatedSubtitles[fragmentIndex].position =
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      subtitleFragments[fragmentIndex].position === SubtitlePosition.BOTTOM
        ? SubtitlePosition.TOP
        : SubtitlePosition.BOTTOM;

    this.updateSubtitles(updatedSubtitles);
    grapheneUtils.incrementCounter('subtitles', { input: 'position_edited' });
  };

  handleOnAddSubtitles = (index: number) => {
    let timestamp;
    const { displayRange } = this.props;

    if (displayRange) {
      const { startTimeMs } = displayRange;
      const prevTimestamp = this.getTimestampAtIndex(index);
      const prevStartTimeMs =
        prevTimestamp && prevTimestamp.endTimeMs >= startTimeMs ? prevTimestamp.endTimeMs : startTimeMs;

      timestamp = {
        startTimeMs: prevStartTimeMs,
        endTimeMs: prevStartTimeMs,
      };
    } else {
      const prevTimestamp = this.getTimestampAtIndex(index);
      const nextTimestamp = this.getTimestampAtIndex(index + 1);

      const startTimeMs = (prevTimestamp && prevTimestamp.endTimeMs) || 0;
      const endTimeMs = (nextTimestamp && nextTimestamp.startTimeMs) || startTimeMs;

      timestamp = {
        startTimeMs,
        endTimeMs,
      };
    }

    const updatedSubtitles = _.cloneDeep(this.getSubtitlesFragments());
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ timestamp: { startTimeMs: numb... Remove this comment to see the full error message
    updatedSubtitles.splice(index + 1, 0, {
      timestamp,
      subtitles: '',
    });

    this.updateSubtitles(updatedSubtitles);
    grapheneUtils.incrementCounter('subtitles', { click: 'add_fragment' });
  };

  getTimestampAtIndex(index: number): SubtitleTimestamp | undefined | null {
    const fragment = this.getSubtitlesFragments()[index];
    return fragment && fragment.timestamp;
  }

  renderSubtitleFragment = (fragmentIndex: number, subtitleFragment: SubtitleFragment) => {
    let showAddButton = true;

    // Filter out fragments which are out of range
    if (this.props.displayRange) {
      const { startTimeMs, endTimeMs } = this.props.displayRange;

      if (subtitleFragment.timestamp.startTimeMs > endTimeMs || subtitleFragment.timestamp.endTimeMs < startTimeMs) {
        return null;
      }

      // check if last fragment continues into next snap
      showAddButton = subtitleFragment.timestamp.endTimeMs < endTimeMs;
    }

    return (
      <React.Fragment key={`subtitle_fragment_${fragmentIndex}`}>
        <SubtitleFragmentComponent
          fragmentIndex={fragmentIndex}
          fragmentData={subtitleFragment}
          disabled={!this.props.isEditing}
          onSubtitleChange={this.handleSubtitleChange}
          onTimestampChange={this.handleSubtitleTimestampChange}
          onPositionChange={this.handleSubtitlePositionChange}
          prevTimestamp={this.getTimestampAtIndex(fragmentIndex - 1)}
          nextTimestamp={this.getTimestampAtIndex(fragmentIndex + 1)}
          videoDuration={this.props.videoDuration}
          data-test="SubtitlesBody.SubtitleFragmentComponent"
        />
        {showAddButton && this.renderAddButton(fragmentIndex)}
      </React.Fragment>
    );
  };

  renderAddButton(index: number) {
    if (!this.props.isEditing) {
      return <div className={style.splitter} />;
    }
    return (
      <AddSubtitlesFragmentButton
        key={`subtitle_add_button_${index}`}
        onClick={this.handleOnAddSubtitles}
        index={index}
        data-test={`SubtitlesBody.AddSubtitlesFragmentButton.${index}`}
      />
    );
  }

  renderSubtitles = () => {
    return _.map(this.getSubtitlesFragments(), (subtitleFragment, fragmentIndex) =>
      this.renderSubtitleFragment(fragmentIndex, subtitleFragment)
    );
  };

  getFirstIndexForSnap = () => {
    if (!this.props.displayRange) {
      return -1;
    }

    const { startTimeMs, endTimeMs } = this.props.displayRange;
    const allSubtitles = this.getSubtitlesFragments();

    if (!allSubtitles.length) {
      return -1;
    }

    for (let i = 0; i < allSubtitles.length; i++) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (allSubtitles[i].timestamp.startTimeMs <= endTimeMs && allSubtitles[i].timestamp.endTimeMs >= startTimeMs) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        return allSubtitles[i].timestamp.startTimeMs <= startTimeMs ? null : i - 1;
      }

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (allSubtitles[i].timestamp.startTimeMs > endTimeMs) {
        return i - 1;
      }
    }

    return allSubtitles.length - 1;
  };

  render() {
    if (!this.props.subtitlesEditorState) {
      return <Spinner loading size={SpinnerSizes.MEDIUM} />;
    }

    const addButtonIndex = this.getFirstIndexForSnap();

    return (
      <div>
        {addButtonIndex !== null && this.renderAddButton(addButtonIndex)}
        {this.renderSubtitles()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SubtitlesBody);
