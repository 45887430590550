import React from 'react';

import { makeStoriesUnavailable } from 'state/editions/actions/editionsActions';
import {
  getActiveEdition,
  shouldUseSingleAssetEditor,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import style from '../StoryBars.scss';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { isStoryPromoted } from 'utils/payToPromoteUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import MutePreviewButton from 'views/common/containers/MutePreviewButton/MutePreviewButton';
import PayToPromoteButton from 'views/payToPromote/components/PayToPromoteButton/PayToPromoteButton';
import withProfileAdAccountsQuery from 'views/payToPromote/hocs/withProfileAdAccountsQuery/withProfileAdAccountsQuery';
import { ProfileAdAccountsQueryResult } from 'views/payToPromote/hooks/useProfileAdAccountsQuery/useProfileAdAccountsQuery';
import SnapcodePreviewButton from 'views/publisherStoryEditor/components/SnapcodePreviewButton/SnapcodePreviewButton';
import StoryStateOverview from 'views/publisherStoryEditor/components/StoryStateOverview/StoryStateOverview';
import StoryOptionsButton from 'views/publisherStoryEditor/containers/StoryOptionsButton/StoryOptionsButton';
import StorySubtitleButton from 'views/publisherStoryEditor/containers/StorySubtitleButton/StorySubtitleButton';

import { StoryState } from 'types/editions';
import type { Edition } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';

type StateProps = {
  activeStory: Edition;
  activePublisherId: PublisherID;
  isSingleAssetStoryEditor: boolean;
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

const mapStateToProps = (state: State) => {
  const activeStory = getActiveEdition(state);

  return {
    activePublisherId: getActivePublisherId(state),
    activeStory,
    isSingleAssetStoryEditor: activeStory?.id ? shouldUseSingleAssetEditor(state)(activeStory?.id) : false,
  };
};

const mapDispatchToProps = {
  makeStoriesUnavailable,
};

type OwnProps = ProfileAdAccountsQueryResult;

type Props = OwnProps & StateProps & DispatchProps;

export class ScheduledStoryBar extends React.PureComponent<Props> {
  onMakeUnAvailableClick = () => this.props.makeStoriesUnavailable([this.props.activeStory.id]);

  render() {
    // Hide if there is no active story selected
    if (!this.props.activeStory) {
      return null;
    }

    return (
      <div className={style.root}>
        <div className={style.leftBar}>
          <StoryStateOverview
            storyState={StoryState.SCHEDULED}
            isStoryPromoted={isStoryPromoted(this.props.activeStory.id, this.props.promotedStoriesIds)}
          />
        </div>
        <div className={style.rightBar}>
          <PayToPromoteButton
            buttonType={ButtonType.WHITE_ON_GREY}
            storyId={this.props.activeStory.id}
            error={this.props.profileAdAccountsError}
            adAccountId={this.props.adAccountId}
          />
          {!this.props.isSingleAssetStoryEditor && (
            <StorySubtitleButton disabled editionId={this.props.activeStory.id} />
          )}
          <SDSButton
            type={ButtonType.WHITE_ON_GREY}
            data-test="storyBuilderScheduledStoryEditButton"
            onClick={this.onMakeUnAvailableClick}
          >
            {getMessageFromId('edit-story-button')}
          </SDSButton>
          <SnapcodePreviewButton />
          <MutePreviewButton />
          <StoryOptionsButton />
        </div>
      </div>
    );
  }
}

export default withProfileAdAccountsQuery(intlConnect(mapStateToProps, mapDispatchToProps)(ScheduledStoryBar));
