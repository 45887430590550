// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';

import SDSInput from 'views/common/components/SDSInput/SDSInput';

import { SnapId } from 'types/common';

export const mapStateToProps = (state: State, props: any) => {
  const snapId = extractSnapIdFromComponentId(props.component.componentId);
  const remoteVideo = stagesSelectors.getData(state)(snapId);
  return {
    snapId,
    isReadOnly: editorSelectors.isReadOnly(state),
    remoteVideo,
  };
};
const mapDispatchToProps = {
  loadRemoteVideoId: stagesActions.stageData,
  updateRemoteVideoId: stagesActions.updateProperties,
  saveRemoteVideoId: stagesActions.commitData,
};

type OwnBitmojiRemoteVideoPanelProps = {
  snapId?: SnapId;
  isReadOnly?: boolean;
  title?: React.ReactElement;
  remoteVideo?: {
    remoteVideoId?: string;
  };
};
type OwnState = {
  remoteVideoId: string;
};

type BitmojiRemoteVideoPanelProps = OwnBitmojiRemoteVideoPanelProps & typeof BitmojiRemoteVideoPanel.defaultProps;
export class BitmojiRemoteVideoPanel extends React.Component<BitmojiRemoteVideoPanelProps, OwnState> {
  static defaultProps = {
    isReadOnly: false,
    title: <FormattedMessage id="remotevideo-general-panel-title" description="General" defaultMessage="General" />,
  };

  static getDerivedStateFromProps(props: any, state: OwnState) {
    if (props.remoteVideo !== null && props.remoteVideo.remoteVideoId !== null && state.remoteVideoId === '') {
      return {
        remoteVideoId: props.remoteVideo.remoteVideoId,
      };
    }
    return {};
  }

  state = {
    remoteVideoId: this.props.remoteVideo?.remoteVideoId ? this.props.remoteVideo.remoteVideoId : '',
  };

  componentDidMount() {
    if (this.props.remoteVideo === null) {
      (this.props as any).loadRemoteVideoId(this.getId());
    }
  }

  getId() {
    return this.props.snapId;
  }

  getFields() {
    return [
      {
        key: 'remoteVideoId',
        control: (
          <SDSInput
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            inputLabel={
              <FormattedMessage
                id="bitmoji-remote-video-id"
                description="Bitmoji Remote Video Id"
                defaultMessage="Remote Video ID"
              />
            }
            data-test="editor.bitmojiRemoteVideoPanel.remoteVideoId.input"
            value={this.state.remoteVideoId}
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
            placeholder="20005424"
          />
        ),
      },
    ];
  }

  handleOnBlur = (event: any) => {
    const remoteVideoId = event && event.target && event.target.value;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (remoteVideoId !== this.props.remoteVideo.remoteVideoId) {
      (this.props as any).updateRemoteVideoId(this.getId(), {
        remoteVideoId,
      });
      (this.props as any).saveRemoteVideoId(this.getId());
    }
  };

  handleOnChange = (event: any) => {
    const remoteVideoId = event && event.target && event.target.value;
    this.setState({
      remoteVideoId,
    });
  };

  render() {
    const fields = this.getFields();
    if (!fields || fields.length === 0) {
      return null;
    }
    return <PropertyPanel title={this.props.title} fields={fields} isReadOnly={this.props.isReadOnly} showBottomLine />;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(BitmojiRemoteVideoPanel);
