import React from 'react';
import { DragLayer } from 'react-dnd';
import type { DragLayerMonitor } from 'react-dnd';

import style from './MediaViewDragLayer.scss';

import type { MediaLibrarySourceItems } from 'types/mediaLibraryDrawer';
import { DragAndDropTypes } from 'types/mediaLibraryDrawer';

const SNAP_IMAGE_HEIGHT = 64;
const SNAP_IMAGE_WIDTH = 36;
type Props = {
  item: MediaLibrarySourceItems | undefined | null;
  isDragging: boolean;
  itemType: any;
};
let dragPreviewRef: any = null;
let subscribedToOffsetChange = false;
const getItemStyles = (currentOffset: any) => {
  if (!currentOffset) {
    return {};
  }
  let { x, y } = currentOffset;
  // offset width and height of the drag layer
  x -= SNAP_IMAGE_WIDTH / 2;
  y -= SNAP_IMAGE_HEIGHT / 2;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
  };
};
export class MediaViewDragLayer extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    dragPreviewRef = this.snapNode;
  }

  snapNode = null;

  assignSnapRef = (node: any) => {
    this.snapNode = node;
  };

  render() {
    if (!this.props.item || !this.props.isDragging || this.props.itemType !== DragAndDropTypes.MEDIA_LIBRARY_ITEM) {
      return null;
    }
    return (
      <div className={style.container}>
        <div ref={this.assignSnapRef}>
          <div className={style.snap} />
          <div className={style.badge}>
            <div className={style.badgeText}>{this.props.item.mediaLibraryItems.length}</div>
          </div>
        </div>
      </div>
    );
  }
}
const onOffsetChange = (monitor: any) => () => {
  if (!dragPreviewRef) {
    return;
  }
  const offset = monitor.getClientOffset();
  if (!offset) {
    return;
  }
  dragPreviewRef.style.transform = getItemStyles(offset).transform;
};
const collect = (monitor: DragLayerMonitor) => {
  if (!subscribedToOffsetChange) {
    (monitor as any).subscribeToOffsetChange(onOffsetChange(monitor));
    subscribedToOffsetChange = true;
  }
  return {
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  };
};
export default DragLayer(collect)(MediaViewDragLayer);
