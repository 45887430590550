import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorType } from 'config/constants';

export const TICKET_NODE = (
  <a href="https://publish-snapchat.zendesk.com/hc/en-us/requests/new" target="_blank">
    <FormattedMessage id="discover-ticket" description="Discover Ticket" defaultMessage="here" />
  </a>
);

export const TRY_AGAIN_OR_FILE_TICKET = 'Please try again, or click {here} to file a ticket.';

export const ERROR_MESSAGE = {
  [ErrorType.UPLOAD]: (
    <FormattedMessage
      id="upload-error-message"
      description="Upload error message"
      defaultMessage={`Failed to upload media! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.SAVE_SNAP_MEDIA]: (
    <FormattedMessage
      id="topsnap-media-save-error-message"
      description="Topsnap media save error message"
      defaultMessage={`Failed to save topsnap media! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.SAVE_TILE_MEDIA]: (
    <FormattedMessage
      id="tile-media-save-error-message"
      description="Tile media save error message"
      defaultMessage={`Failed to save tile media! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.ARTICLE_SAVE]: (
    <FormattedMessage
      id="article-save-error-message"
      description="Article save error message"
      defaultMessage="Failed to save article!"
    />
  ),
  [ErrorType.SET_SNAP_PROPERTY]: (
    <FormattedMessage
      id="topsnap-property-save-error-message"
      description="Topnap property save error message"
      defaultMessage={`Failed to save topsnap property! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.DELETE]: (
    <FormattedMessage
      id="delete-error-message"
      description="Delete error message"
      defaultMessage={`Delete failed! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.ADD_INTERACTION]: (
    <FormattedMessage
      id="add-interaction-error-message"
      description="Add interaction error message"
      defaultMessage={`Failed to create! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.SAVE_LONGFORM_VIDEO]: (
    <FormattedMessage
      id="save-longform-video-error-message"
      description="Save longform error message"
      defaultMessage={`Failed to save! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.SAVE_TOPSNAP_VIDEO]: (
    <FormattedMessage
      id="save-topsnap-video-error-message"
      description="Save topsnap error message"
      defaultMessage={`Failed to save! ${TRY_AGAIN_OR_FILE_TICKET}`}
      values={{ here: TICKET_NODE }}
    />
  ),
  [ErrorType.LOAD]: (
    <FormattedMessage
      id="aload-error-message"
      description="Load error message"
      defaultMessage="Failed to load, please refresh the page!"
    />
  ),
  [ErrorType.CONCURRENT_EDIT]: (
    <FormattedMessage
      id="concurrent-edit-message"
      description="Concurrent edit error message"
      defaultMessage={
        'Someone else appears to be making changes at the same time as you. Please try again. ' +
        'If the problem persists, please refresh the page.'
      }
    />
  ),
};

export const INFO_MESSAGE = {};
