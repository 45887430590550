import React from 'react';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import StoryLevelAdvancedMessage from 'views/publisherStoryEditor/containers/StoryLevelAdvancedMessage/StoryLevelAdvancedMessage';

const mapStateToProps = (state: State, ownProps: any) => {
  const storyId = publisherStoryEditorSelectors.getActiveEditionId(state);
  return {
    storyId,
    storyStatusMessage: publisherStoryEditorSelectors.getStoryStatusMessageForStoryId(state)({ storyId }),
    publisherStatusMessage: publisherStoryEditorSelectors.getPublisherStatusMessageForStoryId(state)({ storyId }),
  };
};

type StoryLevelMessageProps = {
  storyId?: number;
  storyStatusMessage?: React.ReactNode;
  publisherStatusMessage?: React.ReactNode;
};

export class StoryLevelMessage extends React.Component<StoryLevelMessageProps> {
  shouldComponentUpdate(nextProps: StoryLevelMessageProps) {
    const { props } = this;
    return (
      props.storyId !== nextProps.storyId ||
      props.storyStatusMessage !== nextProps.storyStatusMessage ||
      props.publisherStatusMessage !== nextProps.publisherStatusMessage
    );
  }

  render() {
    if (!this.props.storyStatusMessage && !this.props.publisherStatusMessage) {
      return null;
    }

    return <StoryLevelAdvancedMessage />;
  }
}

export default intlConnect(mapStateToProps, null)(StoryLevelMessage);
