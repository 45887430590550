// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import * as adMarkersSelectors from 'state/adMarkers/selectors/adMarkersSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { isAdvertisingEnabled } from 'state/publishers/selectors/publishersSelectors';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import ListItemWithIcon from '../../../common/components/ListItem/ListItemWithIcon';
import SnapItemMenuButtonStyle from '../../styles/SnapItemMenuButtonRow.scss';
import { SnapMenuItemConfig, SnapMenuItemIds } from '../../utils/SnapMenuItemConfig';
import SnapItemMenuAdControls from '../SnapItemMenuAdControls/SnapItemMenuAdControls';
import SnapItemOptionsPopover from '../SnapItemOptionsPopover/SnapItemOptionsPopover';

import { intlConnect } from 'utils/connectUtils';

import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './SnapItemMenu.scss';

import { Claim } from 'types/permissions';
import { SnapType, Snap } from 'types/snaps';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => ({
  shouldShowAdMarkers: adMarkersSelectors.getShouldShowAdMarkers(state),
  isAdvertisingEnabled: isAdvertisingEnabled(state),
  activeSnapHasActionsInProgress: editorSelectors.wholeSnapHasTransactionOrUploading(state)(ownProps.snap.id),

  isStoryContentEditorForAtLeastOnePublisher: userSelectors.hasClaimForAtLeastOnePublisher(state)(
    Claim.STORY_CONTENT_EDITOR
  ),
});

type SnapItemMenuProps = {
  snap: Snap;
  shouldShowAdMarkers: boolean;
  isAdvertisingEnabled: boolean;
  activeSnapHasActionsInProgress: boolean;
  isStoryContentEditorForAtLeastOnePublisher: boolean;
  isLastNonSubscribeSnapInEdition?: boolean;
};

type SnapItemMenuState = any;

export class SnapItemMenu extends React.Component<SnapItemMenuProps, SnapItemMenuState> {
  shouldComponentUpdate(nextProps: SnapItemMenuProps, nextState: SnapItemMenuState) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  // If none of the options is clickable, disable the whole menu. This can happen in two cases:
  // 1. When user is a viewer (does not have write rights) for all editions he has access to
  // 2. When we are saving/updating an active snap and user has disabled sharing
  updateOptionsRowStyle = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return classNames(SnapMenuItemConfig[SnapMenuItemIds.OPTIONS].className, {
      [SnapItemMenuButtonStyle.disabled]:
        !this.props.isStoryContentEditorForAtLeastOnePublisher ||
        (!snapEntityHelpers.isShareable(this.props.snap) && this.props.activeSnapHasActionsInProgress),
    });
  };

  shouldRenderAdOptionsSection = () => this.props.shouldShowAdMarkers && this.props.isAdvertisingEnabled;

  renderAdOptionsSection() {
    if (this.shouldRenderAdOptionsSection()) {
      return (
        <div>
          <div className={style.lineSeparator} />
          <SnapItemMenuAdControls snap={this.props.snap} disabled={this.props.isLastNonSubscribeSnapInEdition} />
        </div>
      );
    }
    return null;
  }

  renderBottomSnapType(snap: any) {
    const snapType = _.get(snap, 'type');
    const pollType = _.get(snap, 'pollType');
    switch (snapType) {
      case SnapType.ARTICLE:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.ARTICLE_ATTACHED]} />;
      case SnapType.LONGFORM_VIDEO:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.VIDEO_ATTACHED]} />;
      case SnapType.POLL:
        return this.renderBottomSnapPollType(pollType);
      case SnapType.REMOTE_WEB:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.REMOTE_WEB_ATTACHED]} />;
      case SnapType.CAMERA_ATTACHMENT:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.CAMERA_ATTACHED]} />;
      default:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.NONE_ATTACHED]} />;
    }
  }

  renderBottomSnapPollType(pollType: any) {
    switch (pollType) {
      case POLL_TYPE.POLL:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.POLL_ATTACHED]} />;
      case POLL_TYPE.VOTE:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.VOTE_ATTACHED]} />;
      case POLL_TYPE.OPEN_QUESTION:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.OPEN_QUIZ_QUESTION_ATTACHED]} />;
      case POLL_TYPE.FACTUAL_QUESTION:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.FACTUAL_QUIZ_QUESTION_ATTACHED]} />;
      default:
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.NONE_ATTACHED]} />;
    }
  }

  renderMenuForSnapType() {
    const isSubscribeSnap = snapEntityHelpers.isSubscribeSnap(this.props.snap);
    const optionsMenu = (
      <div className={style.menu}>
        <SnapItemOptionsPopover snap={this.props.snap} className={style.optionsMenu}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <ListItemWithIcon {...SnapMenuItemConfig[SnapMenuItemIds.OPTIONS]} className={this.updateOptionsRowStyle()} />
        </SnapItemOptionsPopover>
      </div>
    );
    const fullMenu = (
      <div className={style.menu}>
        {this.renderBottomSnapType(_.get(this.props.snap, ['relatedSnaps', 'BOTTOM']))}
        <div className={style.optionsMenu}>
          {optionsMenu}
          {this.renderAdOptionsSection()}
        </div>
      </div>
    );

    return <div className={style.menuContainer}>{isSubscribeSnap ? optionsMenu : fullMenu}</div>;
  }

  render() {
    return this.renderMenuForSnapType();
  }
}

export default intlConnect(mapStateToProps, null)(SnapItemMenu);
