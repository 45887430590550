import { Promise } from 'core-js';

import { canAppealModeration } from 'state/buildStatus/schema/moderationHelpers';
import { getTileBuildStatuses } from 'state/buildStatus/selectors/buildStatusSelectors';
import { getComponentsOfTypeTile } from 'state/editor/selectors/componentsSelectors';
import { getActiveEditionId, getActiveWholeSnapId } from 'state/editor/selectors/editorSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { getTileId } from 'state/tiles/schema/tilesIdUtils';

import { ModalType } from 'utils/modalConfig';

import { Dispatch, GetState } from 'types/redux';

export const tileViolationAppealPrompt = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const storyId = getActiveEditionId(getState());
    const snapId = getActiveWholeSnapId(getState());
    if (!storyId || !snapId) {
      return Promise.resolve();
    }
    const tileComponents = getComponentsOfTypeTile(getState());
    const tileBuildStatuses = getTileBuildStatuses(getState());

    // Using array as a substitute to Option monad to ensure strong typing when filtering.
    const tilesWithModeration = tileComponents.flatMap(tileComponent => {
      const tileId = getTileId(tileComponent.tile);
      if (!tileId) {
        return [];
      }
      const tileBuildStatus = tileBuildStatuses[tileId];
      if (!tileBuildStatus || !canAppealModeration(tileBuildStatus)) {
        return [];
      }
      return [
        {
          tile: tileComponent.tile,
          moderationViolations: tileBuildStatus.moderationViolations,
        },
      ];
    });

    return dispatch(
      showModal(ModalType.TILE_VIOLATION_APPEAL, 'tileViolationAppealPrompt', {
        storyId,
        tilesWithModeration,
      })
    );
  };
};
