import { isEmpty, take } from 'lodash';
import * as React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import { createEmptyQuestion } from 'state/poll/stages/pollStageConfig';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';
import { createAssetUrl } from 'utils/media/assetUtils';

import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import OutcomePageSelector from 'views/editor/components/outcomes/OutcomePageSelector';

import style from './PollPanel.scss';
import { formattedMessages, POLL_DURATION_OPTIONS, PollPanelIds } from './PollPanelConfig';
import PollQuestionPanel from './PollQuestionPanel';

import type { SnapId } from 'types/common';
import { RichSnapComponentId } from 'types/components';
import type { EditionID } from 'types/editions';
import type {
  PollID,
  PollProperties as Poll,
  PollEditorProperties,
  PollEditorState,
  Question,
  Option,
} from 'types/polls';

type IncomingProps = {
  component: {
    componentId: RichSnapComponentId;
  };
};

export const mapStateToProps = (state: State, props: IncomingProps) => {
  const editionId = editorSelectors.getActiveEditionId(state);
  const snapId = extractSnapIdFromComponentId(props.component.componentId);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ snapId: any; editionId: number... Remove this comment to see the full error message
  const isReadOnly = editorSelectors.isLocked(state)({ snapId, editionId });
  const poll: Poll | undefined | null = stagesSelectors.getData(state)(snapId);
  const wideLogoMedia = publishersSelectors.getActivePublisherLogoId(state);
  const publisherLogo = wideLogoMedia ? createAssetUrl(wideLogoMedia) : '';
  const getPrimaryLanguageMessage = publishersSelectors.getPrimaryLanguageMessage(state);
  const pollEditorState = editorSelectors.getSnapEditorState(state)(snapId);
  const isPollsTitleEditable = featuresSelectors.isPollsTitleEditable(state);

  return {
    isReadOnly,
    snapId,
    editionId,
    poll,
    publisherLogo,
    wideLogoMedia,
    getPrimaryLanguageMessage,
    pollEditorState,
    isPollsTitleEditable,
  };
};

const mapDispatchToProps = {
  updatePoll: stagesActions.updateProperties,
  updateEditorState: editorActions.updateSnapEditorState,
};

type Props = {
  activeTopsnap?: {};
  editionId: EditionID;
  getPrimaryLanguageMessage(name: string | {}, values: void | {}): Promise<string>;
  isReadOnly?: boolean;
  poll: Poll | undefined | null;
  pollEditorState: PollEditorState;
  wideLogoMedia: string;
  snapId: SnapId;
  isPollsTitleEditable: boolean;
  updateEditorState: (snapId: SnapId, editorState: PollEditorState) => void;
  updatePoll(id: PollID, properties: Partial<Poll>): void;
};

/* eslint-disable react/prop-types */
export class PollPanel extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { poll } = nextProps;
    if (!poll) {
      return;
    }

    const editorProperties = poll.editorHtml || {};

    if (!editorProperties.updateAppHeadline || !editorProperties.updateAppButton) {
      this.setEditorProperties({
        updateAppHeadline: await this.props.getPrimaryLanguageMessage(formattedMessages.updateAppHeadline),
        updateAppButton: await this.props.getPrimaryLanguageMessage(formattedMessages.updateAppButton),
      });
    }
  }

  getAdditionalGeneralItems() {
    const { poll } = this.props;
    if (!poll || !poll.questions) {
      return [];
    }

    const options: any[] = Array(20)
      .fill(0)
      .map((_, i) => ({
        value: i + 1,
        label: i + 1,
      }));

    return [
      {
        key: PollPanelIds.QUESTION_PICKER,
        label: <FormattedMessage {...formattedMessages.questionPickerLabel} />,
        multiline: true,
        control: (
          <SDSDropdown
            disableClear
            value={String(poll.questions.length)}
            onChange={this.handleQuestionsCountChange}
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
            data-test="questionPicker"
          >
            {createSDSDropdownOptions(options)}
          </SDSDropdown>
        ),
      },
      this.getDurationItem(),
    ].filter(Boolean);
  }

  getId() {
    return this.props.snapId;
  }

  getSelectedQuestionIndex() {
    if (this.isOutcomeSelected()) {
      return -1;
    }

    const { pollEditorState } = this.props;
    return (pollEditorState && pollEditorState.selectedQuestionIndex) || 0;
  }

  getSelectedQuestion() {
    const { poll } = this.props;
    if (!poll) {
      return null;
    }

    if (this.isOutcomeSelected()) {
      return poll.outcomes;
    }

    return poll.questions[this.getSelectedQuestionIndex()];
  }

  getSelectedOutcomeIndex(): number {
    const { pollEditorState } = this.props;

    if (!pollEditorState || typeof pollEditorState.selectedOutcomeIndex !== 'number') {
      return -1;
    }

    return pollEditorState.selectedOutcomeIndex;
  }

  getSelectedOutcome(): Option | undefined | null {
    const { poll } = this.props;

    if (!poll || !poll.outcomes) {
      return null;
    }

    const question: Question = poll.outcomes;

    return question.options[this.getSelectedOutcomeIndex()] || null;
  }

  getDurationItem() {
    const { poll } = this.props;
    if (!poll) {
      return null;
    }
    const options = POLL_DURATION_OPTIONS.map(opt => ({ value: opt.value.toString(), label: opt.label }));
    return {
      label: (
        <FormattedMessage
          id="polls-expires"
          description="Defines the maximum time the poll can be open."
          defaultMessage="Expires"
        />
      ),
      key: PollPanelIds.POLL_MAX_DURATION,
      className: style.headline,
      control: (
        <SDSDropdown
          disableClear
          value={String(poll.durationInDays)}
          onChange={this.handleDurationChanged}
          type={DropdownType.GREY}
          size={DropdownSize.MEDIUM}
          data-test="pollDuration"
        >
          {createSDSDropdownOptions(options)}
        </SDSDropdown>
      ),
    };
  }

  setEditorProperties = (editorHtml: Partial<PollEditorProperties>) => {
    if (!isEmpty(editorHtml)) {
      this.updateProperties({ editorHtml });
    }
  };

  handleQuestionsCountChange = (newQuestionsCount: number) => {
    const { poll } = this.props;
    if (!poll) {
      return;
    }

    const { questions } = poll;

    if (newQuestionsCount > questions.length) {
      const countDifference = newQuestionsCount - questions.length;
      const empties = [];
      for (let i = 0; i < countDifference; i++) {
        empties.push(createEmptyQuestion(poll.pollType));
      }
      this.updateProperties({ questions: [...questions, ...empties] });
    }

    if (newQuestionsCount < questions.length) {
      this.updateProperties({ questions: take(questions, newQuestionsCount) });
      this.updatePollEditorState({ selectedQuestionIndex: newQuestionsCount - 1 });
    }
  };

  formatMessage = (message: string | {}) => {
    return this.context.intl.formatMessage(message);
  };

  updateProperties = (properties: Partial<Poll>) => {
    this.props.updatePoll(this.getId(), properties);
  };

  isOutcomeSelected() {
    return Boolean(this.getSelectedOutcome());
  }

  updatePollEditorState = (changes: Partial<PollEditorState>) => {
    this.props.updateEditorState(this.getId(), changes);
  };

  handleDurationChanged = (durationInDaysStr: string) => {
    const durationInDays = parseInt(durationInDaysStr, 10);
    this.updateProperties({ durationInDays });
  };

  updateQuestion = (changes: Partial<Question>) => {
    const { poll } = this.props;
    if (!poll) {
      return;
    }

    let questionChanges;
    if (this.isOutcomeSelected()) {
      questionChanges = {
        outcomes: changes,
      };
    } else {
      const index = this.getSelectedQuestionIndex();
      questionChanges = {
        questions: {
          [index]: changes,
        },
      };
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ outcomes: Partial<Question>; }... Remove this comment to see the full error message
    this.updateProperties(questionChanges);
  };

  renderOutcomePageSelector() {
    const { poll, isReadOnly, pollEditorState } = this.props;

    if (!poll || !poll.outcomes) {
      return null;
    }

    return (
      <OutcomePageSelector
        poll={poll}
        pollEditorState={pollEditorState}
        isReadOnly={isReadOnly}
        updatePollProperties={this.updateProperties}
        updatePollEditorState={this.updatePollEditorState}
      />
    );
  }

  render() {
    if (!this.getSelectedQuestion()) {
      return null;
    }

    const { poll } = this.props;

    if (!poll) {
      return null;
    }

    return (
      <div style={{ width: '100%' }}>
        {this.renderOutcomePageSelector()}
        <PollQuestionPanel
          key={String(this.getSelectedQuestionIndex())}
          {...this.props}
          isOutcome={this.isOutcomeSelected()}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          additionalGeneralItems={this.getAdditionalGeneralItems()}
          updateQuestion={this.updateQuestion}
          formatMessage={this.formatMessage}
          question={this.getSelectedQuestion()}
          updateEditorState={this.props.updateEditorState}
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(PollPanel);
