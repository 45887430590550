import log from 'loglevel';
import * as React from 'react';
import { DragSource } from 'react-dnd';
import type { DragSourceMonitor, ConnectDragSource, ConnectDragPreview } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';

import SnapCarouselItem from '../SnapCarouselItem/SnapCarouselItem';
import type { SnapNode, DomElement, SourceItem } from '../StoryCarousel/StoryCarouselTypes';
import { DragIndex } from '../StoryCarousel/StoryCarouselTypes';

import { DragTypes } from 'config/constants';
import * as browserUtils from 'utils/browserUtils';

import type { BuildStatusType } from 'types/build';
import type { TopSnap } from 'types/snaps';

type Props = {
  connectDragSource: ConnectDragSource;

  connectDragPreview: ConnectDragPreview;
  snapsInSegment: number;
  segmentIndex: number;
  endDrag: () => void;
  beginDrag: () => void;
  canDrag: () => boolean;
  isPlaceholder: boolean;
  isAnySnapBeingDragged: boolean;
  saveSnapDomElement: (b: SnapNode, a: DomElement) => void;
  style: {};
  snapNode: SnapNode;
  snap: TopSnap;
  className: string | undefined | null;
  buildStatus: BuildStatusType;
};

export const snapSource = {
  beginDrag(props: Props): SourceItem {
    // Errors can't propagate or drag-and-drop will be broken forever
    try {
      props.beginDrag();
    } catch (e) {
      log.error('DraggableSnapCarouselItem error while begin dragging:', e);
    }

    return {
      snap: props.snapNode,
      index: new DragIndex(props.segmentIndex, props.snapNode.index),
      lastClientXOffset: null,
    };
  },

  canDrag(props: Props): boolean {
    // Dont drag if only one snap in segment, leave that to the draggable segment item
    return !isSubscribeSnap(props.snap) && props.canDrag() && props.snapsInSegment > 1 && !props.snap.isContentDeleted;
  },

  // We consider the item to be the dragged one if their ids match.
  // This is handy because the original dragged element may have been remounted
  // in another place and we need to know whether to render it as placeholder

  isDragging(props: Props, monitor: DragSourceMonitor<SourceItem>): boolean {
    return (monitor.getItem().snap && monitor.getItem().snap?.id === props.snap.id) || false;
  },

  endDrag(props: Props): void {
    // Scheduling to next frame to free the event handler and improve responsiveness
    browserUtils.requestAnimationFrame(() => {
      // Errors can't propagate or drag-and-drop will be broken forever
      try {
        props.endDrag();
      } catch (e) {
        log.error('DraggableSnapCarouselItem error while end dragging:', e);
      }
    });
  },
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
});

export class DraggableSnapCarouselItem extends React.PureComponent<Props> {
  componentDidMount() {
    // Disabling the preview since it is handled by StoryCarouselDragLayer
    this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  render() {
    return (
      <SnapCarouselItem
        saveSnapDomElement={this.props.saveSnapDomElement}
        style={this.props.style}
        className={this.props.className}
        snap={this.props.snap}
        snapNode={this.props.snapNode}
        isPlaceholder={this.props.isPlaceholder}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        connectDragSource={this.props.connectDragSource}
        isAnySnapBeingDragged={this.props.isAnySnapBeingDragged}
        buildStatus={this.props.buildStatus}
      />
    );
  }
}

export default DragSource(DragTypes.STORY_SNAP, snapSource, collectSource)(DraggableSnapCarouselItem);
