import type { Dimensions } from '@snapchat/graphene';
import classNames from 'classnames';
import HlsPlayer from 'hls.js';
// @ts-expect-error ts-migrate(2616) FIXME: 'Hls' can only be imported by using 'import Hls = ... Remove this comment to see the full error message
import type { Hls, K_ERROR } from 'hls.js';
import { noop } from 'lodash';
import log from 'loglevel';
import React from 'react';

import { CrossOrigin } from 'config/constants';
import type { SubtitleTrack } from 'constants/subtitles';
import { createTimer, incrementCounter } from 'utils/grapheneUtils';

import { MimeType } from 'types/assets';

const VIDEO_LOADED_METADATA = 'loadedmetadata';

type OwnProps = {
  isPlaying: boolean;
  src: string;

  createHlsPlayer: (a: void) => Hls;
  mimeType: string;
  autoplay: boolean;
  className: string;
  controls: boolean;
  'data-test': string;
  isMuted: boolean;
  onPause: () => void;
  onPlay: () => void;
  onTimeUpdate: (currentTime: number) => void;
  volume?: number;
  poster?: string;
  subtitleTrack: SubtitleTrack | undefined | null;
  subtitlesPreviewEnabled?: boolean;
};

type StateProps = {};

type OwnHLSVideoPlayerProps = StateProps & OwnProps;

function createHlsPlayer() {
  return new HlsPlayer();
}

type Props = OwnHLSVideoPlayerProps & typeof HLSVideoPlayer.defaultProps;

export class HLSVideoPlayer extends React.PureComponent<Props> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'hasLoadedMetadata' has no initializer an... Remove this comment to see the full error message
  hasLoadedMetadata: boolean;

  // If we try to set currentTime, before player has loaded, it will get lost
  // Keep track of desired currentTime until metadata has loaded
  tempCurrentTime: number | undefined | null;

  hls: Hls;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'videoNode' has no initializer and is not... Remove this comment to see the full error message
  videoNode: HTMLVideoElement;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'reportInitPlayerTimer' has no initialize... Remove this comment to see the full error message
  reportInitPlayerTimer: (metricsName: string, dimensions: Dimensions) => number;

  static defaultProps = {
    createHlsPlayer,
    autoplay: true,
    controls: true,
    onTimeUpdate: noop,
    onPlay: noop,
    onPause: noop,
    isMuted: false,
    subtitleTrack: null,
  };

  componentDidMount() {
    this.initPlayer();

    if (this.props.volume) {
      this.setVolume(this.props.volume);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src || this.props.mimeType !== prevProps.mimeType) {
      this.initPlayer();
    }

    if (this.props.isPlaying !== prevProps.isPlaying) {
      this.onPlayStateUpdated();
    }

    if (this.props.subtitleTrack && !prevProps.subtitleTrack) {
      if (this.videoNode.textTracks.length === 1) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        this.videoNode.textTracks[0].mode = 'showing';
      }
    }

    if (this.props.volume && this.props.volume !== prevProps.volume) {
      this.setVolume(this.props.volume);
    }
  }

  componentWillUnmount() {
    this.desctructPlayer();
  }

  initPlayer = () => {
    this.reportInitPlayerTimer = createTimer();
    this.hasLoadedMetadata = false;

    if (this.isHls() && this.props.src) {
      this.hls = this.props.createHlsPlayer();
      this.hls.attachMedia(this.videoNode);

      this.hls.on(HlsPlayer.Events.MEDIA_ATTACHED, this.hlsOnPlayerReady);
      this.hls.on(HlsPlayer.Events.ERROR, this.hlsErrorHandler);
    }
    this.videoNode.addEventListener(VIDEO_LOADED_METADATA, this.hlsOnLoadedMetadata);
  };

  desctructPlayer = () => {
    if (this.isHls()) {
      this.hls.off(HlsPlayer.Events.MEDIA_ATTACHED, this.hlsOnPlayerReady);
      this.hls.off(HlsPlayer.Events.ERROR, this.hlsErrorHandler);
    }
    this.videoNode.removeEventListener(VIDEO_LOADED_METADATA, this.hlsOnLoadedMetadata);
  };

  hlsOnPlayerReady = () => {
    this.hls.loadSource(this.props.src);
  };

  hlsOnLoadedMetadata = () => {
    this.hasLoadedMetadata = true;

    // This may get called multiple times
    if (!Number.isNaN(this.videoNode.duration)) {
      if (this.tempCurrentTime) {
        this.videoNode.currentTime = this.tempCurrentTime;
        this.tempCurrentTime = null;
      }
      const currentTime = this.videoNode.currentTime || 0;
      this.props.onTimeUpdate(currentTime);
      this.reportInitPlayerTimer('HLSPlayer.initPlayer', { mimeType: this.props.mimeType });
    }

    if (this.videoNode.textTracks.length > 0) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.videoNode.textTracks[0].mode = 'showing';
    }
  };

  hlsErrorHandler = (event: K_ERROR, data: Hls.errorData) => {
    log.warn(`ErrorType: ${data.type}. ErrorDetails: ${data.details}. isFatal: ${data.fatal}`);

    if (data.fatal) {
      switch (data.type) {
        case HlsPlayer.ErrorTypes.NETWORK_ERROR:
          // try to recover network error
          log.info('fatal network error encountered, try to recover');
          this.hls.startLoad();
          break;
        case HlsPlayer.ErrorTypes.MEDIA_ERROR:
          log.info('fatal media error encountered, try to recover');
          this.hls.recoverMediaError();
          break;
        default:
          // cannot recover
          this.hls.destroy();
          break;
      }
    }

    incrementCounter('HLSPlayback.Error', {
      code: data.type,
      message: data.details,
      src: this.props.src,
    });
  };

  isHls = () => {
    return this.props.mimeType === MimeType.HLS;
  };

  onPlay = () => {
    this.props.onPlay();
  };

  onPause = () => {
    this.props.onPause();
  };

  onPlayStateUpdated = () => {
    if (this.props.isPlaying) {
      this.videoNode.play();
    } else {
      this.videoNode.pause();
    }
  };

  setCurrentTime = (newCurrentTime: number) => {
    if (this.hasLoadedMetadata) {
      this.videoNode.currentTime = newCurrentTime;
      this.props.onTimeUpdate(newCurrentTime);
    } else {
      this.tempCurrentTime = newCurrentTime;
    }
  };

  onTimeUpdate = () => {
    // html5 player may tick one more time before receiving instruction to pause
    // even though this component is already aware of it
    // Can happen when switching from playing video to subscribe snap
    if (this.props.isPlaying) {
      this.props.onTimeUpdate(this.videoNode.currentTime);
    }
  };

  setVolume = (newVolume: number) => {
    this.videoNode.volume = newVolume;
  };

  asignNode = (node?: HTMLVideoElement | null) => {
    if (node) {
      this.videoNode = node;
    }
  };

  renderTracks = () => {
    const { subtitleTrack, subtitlesPreviewEnabled } = this.props;

    if (!subtitleTrack || !subtitlesPreviewEnabled) {
      return null;
    }

    return (
      <track
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        srcLang={subtitleTrack.language}
        kind="subtitles"
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        src={subtitleTrack.src}
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        key={subtitleTrack.src}
        data-test="HLSPlayer.track"
      />
    );
  };

  render() {
    const { className } = this.props;

    return (
      <video
        ref={this.asignNode}
        className={classNames(className, 'video-js')}
        muted={this.props.isMuted}
        onPlay={this.onPlay}
        onPause={this.onPause}
        onTimeUpdate={this.onTimeUpdate}
        crossOrigin={CrossOrigin.USE_CREDENTIALS}
        key={this.props.src}
      >
        {!this.isHls() && <source src={this.props.src} type={this.props.mimeType} />}
        {this.renderTracks()}
      </video>
    );
  }
}
