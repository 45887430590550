import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import ActionButtons from '../../components/ActionButtons/ActionButtons';

import { ContentStatus } from 'config/constants';

import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/white_checkmark.svg.inl... Remove this comment to see the full error message
import checkmark from 'images/white_checkmark.svg.inline';

import style from './ContentStatusPanel.scss';

type Props = {
  status: ContentStatus;
  isReadOnly: boolean;
  onSave: (...args: any[]) => any;
};

class ContentStatusPanel extends React.Component<Props> {
  shouldShowStatus = false;

  renderInnerElement() {
    // Should only show status after first change
    if (!this.shouldShowStatus && this.props.status === ContentStatus.LOADED_DIRTY) {
      this.shouldShowStatus = true;
    }

    if (!this.shouldShowStatus) {
      return null;
    }

    switch (this.props.status) {
      case ContentStatus.SAVING:
        return <Spinner loading message={SpinnerLabels.SAVING_CONTENT} className={style.savingSpinner} />;

      case ContentStatus.LOADED_DIRTY:
        return (
          <div className={style.messageContainer} key="loaded-dirty" data-test="contentStatusPanel.messageContainer">
            <div className={style.messageText}>
              <FormattedMessage
                id="unsaved-content-text"
                description="Text indicating that content has unsaved changes"
                defaultMessage="Unsaved changes"
              />
            </div>
            <ActionButtons status={this.props.status} readOnly={this.props.isReadOnly} onSave={this.props.onSave} />
          </div>
        );

      case ContentStatus.LOADED_CLEAN:
        return (
          <div className={style.messageContainer} key="loaded-clean">
            <span className={style.savedCheck}>
              <InlineSVG src={checkmark} />
            </span>
            <div className={style.messageText}>
              <FormattedMessage
                id="saved-content-text"
                description="Text indicating that content has no unsaved changes"
                defaultMessage="Changes Saved"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const rootClass = classNames(style.statusContainer);

    return <div className={rootClass}>{this.renderInnerElement()}</div>;
  }
}

export default ContentStatusPanel;
