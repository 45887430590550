import React, { memo, useCallback } from 'react';

import type { SubtitleFragment, SubtitleTimestamp } from '../../subtitleParser';
import SubtitleHeadline from '../SubtitleHeadline/SubtitleHeadline';
import SubtitleLine from '../SubtitleLine/SubtitleLine';
import SubtitleTimer from '../SubtitleTimer/SubtitleTimer';
import SubtitlesPositionToggle from '../SubtitlesPositionToggle/SubtitlesPositionToggle';

import style from './SubtitleFragmentComponent.scss';

type Props = {
  fragmentIndex: number;
  fragmentData: SubtitleFragment;
  disabled: boolean;
  videoDuration: number;
  onSubtitleChange: (b: string, a: number) => void;
  onTimestampChange: (b: SubtitleTimestamp, a: number) => void;
  onPositionChange: (a: number) => void;
  prevTimestamp: SubtitleTimestamp | undefined | null;
  nextTimestamp: SubtitleTimestamp | undefined | null;
};

function SubtitleFragmentComponent({
  fragmentIndex,
  fragmentData,
  disabled,
  videoDuration,
  onSubtitleChange,
  onTimestampChange,
  onPositionChange,
  prevTimestamp,
  nextTimestamp,
}: Props) {
  const handleTimestampChange = useCallback(
    (newTimestamp: SubtitleTimestamp) => {
      onTimestampChange(newTimestamp, fragmentIndex);
    },
    [onTimestampChange, fragmentIndex]
  );

  const handleSubtitleLineChange = useCallback(
    (newLine: string) => {
      onSubtitleChange(newLine, fragmentIndex);
    },
    [onSubtitleChange, fragmentIndex]
  );

  const handlePositionChange = useCallback(() => {
    onPositionChange(fragmentIndex);
  }, [onPositionChange, fragmentIndex]);

  const prevMax = (prevTimestamp && prevTimestamp.endTimeMs) || Number.MIN_VALUE;
  const nextMin = (nextTimestamp && nextTimestamp.startTimeMs) || videoDuration;

  return (
    <div className={style.subtitlesFragment}>
      <div className={style.fragmentTitle}>
        <SubtitleHeadline index={fragmentIndex + 1} subtitleLine={fragmentData.subtitles} />
        <div className={style.controls}>
          <SubtitleTimer
            timestamp={fragmentData.timestamp}
            disabled={disabled}
            onChange={handleTimestampChange}
            prevMax={prevMax}
            nextMin={nextMin}
          />
          <div className={style.spacer} />
          <SubtitlesPositionToggle
            onToggle={handlePositionChange}
            position={fragmentData.position}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={style.subtitleLine}>
        <SubtitleLine subtitleLine={fragmentData.subtitles} disabled={disabled} onChange={handleSubtitleLineChange} />
      </div>
    </div>
  );
}

export default memo(SubtitleFragmentComponent);
