import React, { ReactNode } from 'react';

import { externalLink } from 'icons/SDS/allIcons';

import { AlertBox, AlertTypeEnum } from 'views/common/components/AlertBox/AlertBox';
import Icon from 'views/common/components/Icon/Icon';

import style from './PanelErrorsField.scss';

interface Props {
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  warningLevel: AlertTypeEnum;
  url?: string;
}

const PanelErrorsField: React.FC<Props> = props => {
  const { primaryText, secondaryText, warningLevel, url } = props;

  function renderBody() {
    return (
      <div className={style.panelErrorsComponentBox}>
        <AlertBox className={style.alertBoxStyle} type={warningLevel}>
          <div className={style.alertBoxContent} data-test={`panelErrorsField.AlertBox${warningLevel}`}>
            <div className={style.problemsFoundTextBox}>
              <div
                className={style.problemsFoundPrimaryText}
                data-test={`panelErrorsField.AlertBox${warningLevel}PrimaryText`}
              >
                {primaryText}
              </div>
              {secondaryText && (
                <div
                  className={style.problemsFoundSecondaryText}
                  data-test={`panelErrorsField.AlertBox${warningLevel}SecondaryText`}
                >
                  {secondaryText}
                </div>
              )}
            </div>
            {url ? (
              <Icon
                data-test={`panelErrorsField.AlertBox${warningLevel}Icon`}
                inlineIcon={externalLink}
                className={style.externalLinkIcon}
              />
            ) : null}
          </div>
        </AlertBox>
      </div>
    );
  }

  // if URL is passed down, the whole box should be a link
  if (url) {
    return (
      <a href={url} target="_blank" className={style.externalLink} data-test={'panelErrorsField.Link'}>
        {renderBody()}
      </a>
    );
  }
  return <div>{renderBody()}</div>;
};

export default PanelErrorsField;
