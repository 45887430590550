import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { RemoteWeb } from 'state/remoteWeb/stages/remoteWebStageConfig';
import * as stagesActions from 'state/stages/actions/stagesActions';
import { StageId } from 'state/stages/actions/stagesActions';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';

import ContentStatusPanel from '../ContentStatusPanel/ContentStatusPanel';

import { State } from 'src/types/rootState';
import { extractSnapIdFromComponentId } from 'utils/componentUtils';
import { intlConnect } from 'utils/connectUtils';

import { REFERRER_FORWARDING_URL } from 'views/editor/containers/EditorPropertyPanels/Panels/RemoteWebPanel';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/ghost_missing.svg.inlin... Remove this comment to see the full error message
import ghostMissingSVG from 'images/ghost_missing.svg.inline';

import style from './RemoteWebEditor.scss';

import { RichSnapComponentId } from 'types/components';
import { ExtractDispatchProps } from 'types/redux';

type OwnProps = {
  snapComponentId?: RichSnapComponentId;
};

export const mapStateToProps = (state: State, props: OwnProps) => {
  const snapId: StageId | undefined = props.snapComponentId
    ? extractSnapIdFromComponentId(props.snapComponentId)
    : undefined;

  const remoteWeb: RemoteWeb | null = stagesSelectors.getData(state)(snapId);
  const isReadOnly: boolean = editorSelectors.isReadOnly(state);
  const contentStatus = stagesSelectors.getContentStatus(state)(snapId);
  return {
    snapId,
    remoteWeb,
    contentStatus,
    isReadOnly,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  loadRemoteWeb: stagesActions.stageData,
  saveRemoteWeb: stagesActions.commitData,
};
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

export class RemoteWebEditor extends React.Component<Props> {
  componentDidMount() {
    const { snapId, remoteWeb, loadRemoteWeb } = this.props;
    if (snapId && remoteWeb === null) {
      loadRemoteWeb(snapId);
    }
  }

  getCenteredLocation() {
    // TODO: sthorsen make the screen size configurable in the UI?
    const iphone7Width = 375;
    const iphone7Height = 667;
    const y = (window.top?.outerHeight || 0) / 2 + (window.top?.screenY || 0) - iphone7Height / 2;
    const x = (window.top?.outerWidth || 0) / 2 + (window.top?.screenX || 0) - iphone7Width / 2;
    return `width=${iphone7Width}, height=${iphone7Height}, top=${y}, left=${x}`;
  }

  save = () => {
    const { isReadOnly, snapId, saveRemoteWeb } = this.props;
    if (snapId && !isReadOnly) {
      saveRemoteWeb(snapId);
    }
  };

  handleRemoteUrlClick = () => {
    if (!this.props.remoteWeb) {
      return;
    }

    const { remoteUrl, useReferrer } = this.props.remoteWeb;
    const openInNewWindowOption = '_blank';
    const location = this.getCenteredLocation();

    const urlToOpen = useReferrer ? `${REFERRER_FORWARDING_URL}${remoteUrl}` : remoteUrl;
    window.open(urlToOpen, openInNewWindowOption, location);
  };

  renderRemoteWebPreview = () => {
    const { remoteWeb } = this.props;
    return (
      <div>
        {remoteWeb && remoteWeb.remoteUrl ? (
          <div className={style.setRemoteWeb}>
            <div className={style.setHeader}>
              <h4>
                <FormattedMessage
                  id="view-your-remote-web"
                  description="View remote web area"
                  defaultMessage="Web View"
                />
              </h4>
              <h5>
                <FormattedMessage
                  id="view-your-remote-web-text"
                  description="View remote url description"
                  defaultMessage="Congrats! You included your website as an attachment!"
                />
              </h5>
            </div>
            <div className={style.setRemoteUrl} onClick={this.handleRemoteUrlClick}>
              <h4>
                <FormattedMessage
                  id="view-your-remote-web-url"
                  description="View app install IOS"
                  defaultMessage="Website"
                />
              </h4>
              <h5>
                <FormattedMessage
                  id="included-website-preview-message"
                  description="The website URL has been included text"
                  defaultMessage="Your website URL has been verified and included, click to view in a new window."
                />
                <div className={style.previewWarning}>
                  <FormattedMessage
                    id="preview-website-warning"
                    description="Preview webview warning text"
                    defaultMessage="Please note that the preview is likely to not be indicative of how the website would render on an actual mobile device." // eslint-disable-line max-len
                  />
                </div>
              </h5>
            </div>
          </div>
        ) : (
          <div className={style.emptyRemoteWeb}>
            <div className={style.emptyHeader}>
              <h4>
                <FormattedMessage
                  id="create-your-remote-web"
                  description="Create remote web area"
                  defaultMessage="Create your Web View attachment"
                />
              </h4>
              <h5>
                <FormattedMessage
                  id="create-your-remote-web-text"
                  description="Create remote web description"
                  defaultMessage="Paste your website url in the panel to the right."
                />
              </h5>
            </div>
            <div className={style.emptyAttachment}>
              <FormattedMessage
                id="empty-remote-web"
                description="Create remote web area"
                defaultMessage="No attachment yet"
              />
            </div>
            <InlineSVG src={ghostMissingSVG} className={style.ghostMissing} />
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className={style.editor}>
        <ContentStatusPanel status={this.props.contentStatus} isReadOnly={this.props.isReadOnly} onSave={this.save} />
        <div className={style.previewWrapper}>{this.renderRemoteWebPreview()}</div>
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(RemoteWebEditor);
