// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import { memoize } from 'lodash';
import { defineMessages } from 'react-intl';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import style from './PollPanel.scss';

import type { PollType } from 'types/polls';

export const correctEmojisConfig = {
  className: style.emojiPickerHeaderRemover,
  customEmojis: ['grinning', 'smiley', 'smile', 'sunglasses', 'blush', '+1', 'clap', 'trophy', 'white_check_mark'],
};

export const incorrectEmojisConfig = {
  className: style.emojiPickerHeaderRemover,
  customEmojis: ['cry', 'sob', 'anguished', 'weary', 'white_frowning_face', 'scream', 'hankey', '-1', 'x'],
};

export const formattedMessages = defineMessages({
  correctEmojis: {
    id: 'emoji-picker-headline-correct-answer',
    description: 'Label for emojis used in emoji picker for correct answer',
    defaultMessage: 'Correct',
  },
  inCorrectEmojis: {
    id: 'emoji-picker-headline-incorrect-answer',
    description: 'Label for emojis used in emoji picker for incorrect answer',
    defaultMessage: 'Incorrect',
  },
  backgroundColorLabel: {
    id: 'poll-panel-bg-color-label',
    description: 'Label for the background color picker in the poll options panel',
    defaultMessage: 'BG Color',
  },
  headingColorLabel: {
    id: 'poll-panel-text-color-label',
    description: 'Label for the heading color picker in the poll options panel',
    defaultMessage: 'Text Color',
  },
  votesCount: {
    id: 'obfuscated-total-votes',
    defaultMessage: '{votesCount} votes',
    description: 'This text is displayed in Poll results. Behind the votes count. Example: "10K+ votes"',
  },
  fontLabel: {
    id: 'poll-panel-font-label',
    description: 'Label for the header font picker in the poll options panel',
    defaultMessage: 'Font',
  },
  choicePickerLabel: {
    id: 'poll-panel-choice-picker-label',
    description: 'Label for the header choice picker in the poll options panel',
    defaultMessage: '# of choices',
  },
  defaultNotVotedHeadline: {
    id: 'poll-panel-default-not-voted-header',
    description: 'The default value for the headline to display to a user when they have not voted',
    defaultMessage: 'Tap below to make your vote!',
  },
  defaultNotAnsweredHeadline: {
    id: 'poll-panel-default-quiz-not-answered-header',
    description: 'The default value for the headline to display to a user when they have not yet taken a quiz',
    defaultMessage: 'Which one is it?',
  },
  defaultVotedHeadline: {
    id: 'poll-panel-default-voted-header',
    description: 'The default value for the headline to display to a user when they have voted',
    defaultMessage: 'Thanks for voting!',
  },
  defaultAnsweredHeadline: {
    id: 'poll-panel-default-quiz-answer-header',
    description: 'The default value for the headline to display to a user after they have taken a quiz',
    defaultMessage: 'The right answer was...',
  },
  notVotedHeadline: {
    id: 'poll-panel-headline',
    description: 'Label for the input box in which the user enters the not voted headline',
    defaultMessage: 'Headline',
  },
  votedHeadline: {
    id: 'poll-panel-voted-headline',
    description: 'Label for the input box in which the user enters the voted headline',
    defaultMessage: 'Voted Headline',
  },
  clientAnswerHeadline: {
    id: 'poll-panel-answer-headline',
    description: 'This is a label for additional explanation text of the quiz.',
    defaultMessage: 'Answered Headline',
  },
  closedHeadline: {
    id: 'poll-panel-closed-headline',
    description: 'Label for the input box in which the user enters the closed poll headline',
    defaultMessage: 'Closed headline',
  },
  defaultClosedHeadline: {
    id: 'poll-panel-default-closed-headline',
    description: 'The default message to show the user when they open a poll that has closed',
    defaultMessage: 'Voting has ended.',
  },
  defaultClosedQuizHeadline: {
    id: 'poll-panel-default-closed-quiz-headline',
    description: 'The default message to show the user when they open a quiz that has finished',
    defaultMessage: 'Quiz no longer available',
  },
  questionPickerLabel: {
    id: 'poll-panel-question-picker-label',
    description: 'Label for the header question picker in the poll options panel',
    defaultMessage: '# of questions',
  },
  updateAppHeadline: {
    id: 'poll-panel-update-app-headline',
    description: "The text seen by users when they open a version of the app that doesn't support the bottom snap",
    defaultMessage: 'Please update Snapchat to view this attachment',
  },
  updateAppButton: {
    id: 'poll-panel-update-app-button',
    description: 'Label for the button to update the app when the update screen is displayed',
    defaultMessage: 'update',
  },
});

export const POLL_DURATION_OPTIONS = [
  {
    value: 1,
    label: getMessageFromId('poll-expiration-after-1-day'),
  },
  {
    value: 2,
    label: getMessageFromId('poll-expiration-after-x-day', { days: 2 }),
  },
  {
    value: 3,
    label: getMessageFromId('poll-expiration-after-x-day', { days: 3 }),
  },
  {
    value: 7,
    label: getMessageFromId('poll-expiration-after-x-day', { days: 7 }),
  },
  {
    value: 14,
    label: getMessageFromId('poll-expiration-after-x-day', { days: 14 }),
  },
  {
    value: 30,
    label: getMessageFromId('poll-expiration-after-x-day', { days: 30 }),
  },
];

export const PollPanelIds = {
  BACKGROUND_COLOR: 'backgroundColor',
  HEADLINE_COLOR: 'headlineColor',
  FONT_FAMILY: 'fontFamily',
  BACKGROUND_IMAGE: 'backgroundImage',

  SHOW_VOTES_COUNT: 'show-votes-count',
  SHOW_EXPLANATION_SCREEN: 'show-explanation-screen',
  NOT_VOTED_HEADLINE: 'notVotedHeadline',
  VOTED_HEADLINE: 'votedHeadline',
  CLOSED_HEADLINE: 'closedHeadline',

  QUESTION_PICKER: 'questionPicker',
  CHOICE_PICKER: 'choicePicker',
  SHOW_LABELS: 'showLabels',
  CORRECT_ANSWER_EMOJI: 'correctAnswerEmoji',
  INCORRECT_ANSWER_EMOJI: 'incorrectAnswerEmoji',
  POLL_MAX_DURATION: 'pollMaxDuration',
};

export type PollPanelIdsEnum = typeof PollPanelIds[keyof typeof PollPanelIds];

const ALLOWED_FOR_OUTCOMES = Object.freeze([
  PollPanelIds.BACKGROUND_COLOR,
  PollPanelIds.HEADLINE_COLOR,
  PollPanelIds.FONT_FAMILY,
]);

const ALLOWED_COMMON = Object.freeze([
  PollPanelIds.BACKGROUND_COLOR,
  PollPanelIds.HEADLINE_COLOR,
  PollPanelIds.FONT_FAMILY,
  PollPanelIds.BACKGROUND_IMAGE,

  PollPanelIds.NOT_VOTED_HEADLINE,
  PollPanelIds.CHOICE_PICKER,
  PollPanelIds.SHOW_LABELS,
  PollPanelIds.POLL_MAX_DURATION,
]);

const ALLOWED_BY_POLL_TYPE = Object.freeze({
  [POLL_TYPE.VOTE]: [...ALLOWED_COMMON, PollPanelIds.VOTED_HEADLINE, PollPanelIds.CLOSED_HEADLINE],
  [POLL_TYPE.POLL]: [
    ...ALLOWED_COMMON,
    PollPanelIds.VOTED_HEADLINE,
    PollPanelIds.CLOSED_HEADLINE,
    PollPanelIds.SHOW_VOTES_COUNT,
  ],
  [POLL_TYPE.FACTUAL_QUESTION]: [
    ...ALLOWED_COMMON,
    PollPanelIds.VOTED_HEADLINE,
    PollPanelIds.SHOW_EXPLANATION_SCREEN,
    PollPanelIds.CORRECT_ANSWER_EMOJI,
    PollPanelIds.INCORRECT_ANSWER_EMOJI,
  ],
  [POLL_TYPE.OPEN_QUESTION]: [...ALLOWED_COMMON, PollPanelIds.QUESTION_PICKER],
});

const isAllowedForOutcome = memoize((key: PollPanelIdsEnum) => {
  return ALLOWED_FOR_OUTCOMES.indexOf(key) !== -1;
});

function isAllowedForPollType(pollType: PollType, key: PollPanelIdsEnum) {
  const allowedByPollType = ALLOWED_BY_POLL_TYPE[pollType] || [];
  return allowedByPollType.indexOf(key) !== -1;
}

type IsAllowedOptions = {
  isOutcome: boolean;
  pollType: PollType;
};

export function isFieldAllowed(key: PollPanelIdsEnum, { pollType, isOutcome }: IsAllowedOptions) {
  if (isOutcome) {
    return isAllowedForOutcome(key);
  }

  return isAllowedForPollType(pollType, key);
}
