// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from './PlaceholderPanel.scss';

type OwnProps = {
  isSingleAssetStoryEditor: boolean;
  isOnAttachmentTab: boolean;
  isActiveComponent: boolean;
};

// Just renders an empty PropertPanel, so that the right hand side of the page doesn't
// look wonky when we're showing the new snap type menu.
function PlaceholderPanel(props: OwnProps) {
  let text;

  if (props.isOnAttachmentTab && props.isActiveComponent) {
    text = (
      <FormattedMessage
        id="single-asset-attachment-default-panel-message"
        description="Message explaining the purpose of attachments"
        defaultMessage="Users can swipe up on this Snap to interact with your attachment"
      />
    );
  } else if (props.isSingleAssetStoryEditor) {
    text = (
      <FormattedMessage
        id="single-asset-default-panel-generating-message"
        description="Message explaining that shot generation is not complete for the video"
        defaultMessage="We are generating snaps from your video"
      />
    );
  } else {
    text = (
      <FormattedMessage
        id="options-unavailable"
        description="Empty state message or options unavaiable"
        defaultMessage="Options are unavailable."
      />
    );
  }

  return (
    <div className={style.placeholderPanel} data-test="test">
      <PropertyPanel data-test="PlaceholderPanel.PropertyPanel" fields={[]} isReadOnly={false} title={text} />
    </div>
  );
}

export default PlaceholderPanel;
