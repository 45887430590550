import React from 'react';

import * as attachmentsSelectors from 'state/attachments/selectors/attachmentsSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { ExtractDispatchProps } from 'src/types/redux';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import * as gaUtils from 'utils/gaUtils';

import { Spinner, SpinnerLabels } from 'views/common/components/Spinner/Spinner';
import { getAllAttachmentOptions } from 'views/editor/richEditorConfig';

import AttachmentSelection from './AttachmentSelection';
import style from './NewInteractionEditor.scss';

export const mapStateToProps = (state: State) => {
  const topsnapId = editorSelectors.getActiveWholeSnapId(state);
  const attachmentOptions = attachmentsSelectors.getEnabledAttachmentOptions(state)(getAllAttachmentOptions());
  return {
    topsnapId,
    attachmentOptions,
    publisher: publishersSelectors.getActivePublisherDetails(state),
    edition: editorSelectors.getActiveEdition(state),
  };
};
const mapDispatchToProps = {
  addBottomSnapAndMakeActive: editorActions.addBottomSnapAndMakeActive,
  hideNewInteractionPlaceholder: editorActions.hideNewInteractionPlaceholder,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

type OwnState = {
  isAddingInteraction: boolean;
};
export class NewInteractionEditor extends React.Component<Props, OwnState> {
  static mapStateToProps = mapStateToProps;

  state = {
    isAddingInteraction: false,
  };

  componentWillUnmount() {
    (this.props as any).hideNewInteractionPlaceholder();
  }

  addInteraction = (type: any, snapProperties: any) => () => {
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'component-add', { type });
    const interactionRouteParams = {
      publisherId: (this.props as any).publisher.id,
      editionId: (this.props as any).edition.id,
      snapId: this.props.topsnapId,
      overwriteHistory: true,
    };
    this.setState({ isAddingInteraction: true });
    (this.props as any).addBottomSnapAndMakeActive({
      topsnapId: this.props.topsnapId,
      type,
      snapProperties,
      interactionRouteParams,
    });
  };

  renderSeparator(key: any) {
    return <div className={(style as any).separator} key={key} />;
  }

  render() {
    if (this.state.isAddingInteraction) {
      return (
        <div className={style.spinnerContainer}>
          <Spinner withBox loading={(this.props as any).editionListLoading} message={SpinnerLabels.LOADING} />
        </div>
      );
    }
    return (
      <div data-test="NewInteractionEditor" className={style.newInteractionsContainer}>
        {this.props.attachmentOptions.map((option, index) => {
          return [
            <AttachmentSelection
              index={index}
              key={option.title}
              name={option.name}
              icon={option.icon}
              onClick={this.addInteraction(option.snapType, option.snapProperties)}
            />,
            this.renderSeparator(`${option.title}-separator`),
          ];
        })}
      </div>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(NewInteractionEditor);
