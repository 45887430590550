import classNames from 'classnames';
import invariant from 'invariant';
import is from 'is_js';
import { findIndex, get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import { getTileBuildStatuses } from 'state/buildStatus/selectors/buildStatusSelectors';
import {
  isFirstSnapInEdition as isFirstSnapInEditionHelper,
  isFirstSnapInSegment as isFirstSnapInSegmentHelper,
} from 'state/editions/schema/editionEntityHelpers';
import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { shouldUseSingleAssetEditor } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getTileId } from 'state/tiles/schema/tilesIdUtils';

import { EMPTY_ARRAY, MAXIMUM_TILE_COUNT, MINIMUM_TILE_COUNT, RichSnapComponentType } from 'config/constants';
import { plus } from 'icons/SDS/allIcons';
import { asset } from 'utils/apis/mediaLibraryAPI';
import { intlConnect } from 'utils/connectUtils';
import { bulkDownloadFiles, FileInput } from 'utils/files/downloadFilesUtil';
import * as gaUtils from 'utils/gaUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import * as assetUtils from 'utils/media/assetUtils';
import { createAssetUrl } from 'utils/media/assetUtils';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import ItemSelectPanel from 'views/editor/components/ItemSelectPanel/ItemSelectPanel';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './TileSelectorControl.scss';

import { TileProblem } from 'types/build';
import type { RichSnapComponentTile, RichSnapComponentTilePlaceholder } from 'types/components';
import { isComponentTile } from 'types/components';
import type { AudienceEnum } from 'types/moderation';
import { ExtractDispatchProps } from 'types/redux';
import type { State as RootState } from 'types/rootState';
import { TileFlavor } from 'types/tiles';

type State = {
  duplicatingTile: boolean;
};

export const mapStateToProps = (state: RootState) => {
  const topsnapId = editorSelectors.getActiveWholeSnapId(state);
  const edition = editorSelectors.getActiveEdition(state);
  invariant(edition, 'Edition must be defined.');
  const isFirstSnapInEdition = Boolean(topsnapId && isFirstSnapInEditionHelper(edition, topsnapId));
  const isFirstSnapInSegment = topsnapId ? isFirstSnapInSegmentHelper(edition, topsnapId) : false;

  return {
    isFirstSnapInEdition,
    isFirstSnapInSegment,
    isReadOnly: editorSelectors.isWholeSnapOrEditionLocked(state)({ editionId: edition.id, snapId: topsnapId }),
    activeComponent: componentsSelectors.getActiveComponent(state),
    activeComponentId: componentsSelectors.getActiveComponentId(state),
    componentsOfTypeTile: componentsSelectors.getComponentsOfTypeTile(state),
    componentsOfTypeTilePlaceholder: componentsSelectors.getComponentsOfTypeTilePlaceholder(state),
    tileBuildStatuses: getTileBuildStatuses(state),
    duplicateTile: editorActions.duplicateTile,
    isInitialTilesOnly: shouldUseSingleAssetEditor(state)(edition.id),
  };
};

const mapDispatchToProps = {
  editTileById: editorActions.editTileById,
  addNewTile: editorActions.addNewTile,
  deleteTileById: editorActions.deleteTileById,
  duplicateTile: editorActions.duplicateTile,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

export class TileSelectorControl extends React.Component<Props, State> {
  state = {
    duplicatingTile: false,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  getAllTileComponents(): (RichSnapComponentTile | RichSnapComponentTilePlaceholder)[] {
    const { componentsOfTypeTile, componentsOfTypeTilePlaceholder } = this.props;
    const tilePlaceholders: RichSnapComponentTilePlaceholder[] = this.isPlaceholderActive()
      ? componentsOfTypeTilePlaceholder
      : [];

    return [...componentsOfTypeTile, ...tilePlaceholders];
  }

  handleTileSelected = (index: number) => {
    const tileComponent = this.getAllTileComponents()[index];
    if (tileComponent) {
      this.props.editTileById(tileComponent.componentId);
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-select');
    }
  };

  handleTileDeleted = (index: number) => {
    const tileComponent = this.getAllTileComponents()[index];
    if (tileComponent && isComponentTile(tileComponent)) {
      this.props.deleteTileById(tileComponent.tile.id);
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-delete');
    }
  };

  handleTileDownload = async (index: number) => {
    const tileComponent = this.getAllTileComponents()[index];
    if (tileComponent && isComponentTile(tileComponent)) {
      const tile = tileComponent.tile;
      const assetId = tile.baseImageAssetId;
      const fileName = `Tile_${assetId}.jpg`;
      const file: FileInput = {
        href: createAssetUrl(assetId, { downloadFilename: fileName }, asset.forceDownload),
        filename: fileName,
      };
      await bulkDownloadFiles([file]);
    }
  };

  handleTileDuplicated = async (index: number) => {
    const tileComponent = this.getAllTileComponents()[index];

    if (tileComponent && isComponentTile(tileComponent)) {
      this.setState({ duplicatingTile: true });

      await this.props.duplicateTile(tileComponent.tile);
      gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-duplicate');

      this.setState({ duplicatingTile: false });
    }
  };

  handleAddTile = () => {
    this.props.addNewTile();
    gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'tile-add');
  };

  canAddTile() {
    const { isFirstSnapInEdition, isFirstSnapInSegment } = this.props;
    const maxTilesCount = isFirstSnapInEdition || isFirstSnapInSegment ? MAXIMUM_TILE_COUNT : 1;
    const isValidCount = this.getAllTileComponents().length < maxTilesCount;
    return isValidCount && !this.isPlaceholderActive();
  }

  canRenderSelectPanel() {
    const { componentsOfTypeTile, isFirstSnapInEdition, isInitialTilesOnly } = this.props;
    if (!isInitialTilesOnly) {
      return true;
    }
    return isFirstSnapInEdition || componentsOfTypeTile.length > 0;
  }

  isPlaceholderActive() {
    const { activeComponent, componentsOfTypeTile } = this.props;
    return (
      activeComponent &&
      (activeComponent.componentType === RichSnapComponentType.TILE_PLACEHOLDER ||
        activeComponent.componentType === RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER ||
        componentsOfTypeTile.length < MINIMUM_TILE_COUNT)
    );
  }

  addDuplicateSpinner() {
    return this.state.duplicatingTile && <SpinnerIcon className={style.spinnerIcon} />;
  }

  render() {
    if (!this.canRenderSelectPanel()) {
      return (
        <FormattedMessage
          id="initial-tiles-only"
          description="Message shown on the tiles tab when non-initial snap selected"
          defaultMessage="Please select the first snap to edit tiles"
        />
      );
    }

    const { activeComponentId, isReadOnly, tileBuildStatuses } = this.props;
    const tileComponents = this.getAllTileComponents();

    const selectedIndex = findIndex(tileComponents, tileComponent => {
      return tileComponent.componentId === activeComponentId;
    });

    const items = tileComponents
      .filter(component => !isComponentTile(component) || component.tile.tileFlavor !== TileFlavor.HN_SQUARE)
      .map((component, index) => {
        const tile = isComponentTile(component) ? component.tile : null;
        const tileId = tile ? getTileId(tile) : null;
        const tileAudience: Array<AudienceEnum> = tileId
          ? get(tileBuildStatuses[tileId], 'audience', EMPTY_ARRAY)
          : EMPTY_ARRAY;
        const tileStatus = tileId ? get(tileBuildStatuses[tileId], 'status', '') : TileProblem.PUBLISHABLE;

        const isFlaggedByModeration = shouldShowModeration(tileAudience);
        const isTileIncomplete =
          Boolean(
            !tile || !tile.baseImageAssetId || (tile.isHeadlineEnabled && (!tile.headline || is.empty(tile.headline)))
          ) || tileStatus === TileProblem.INCOMPLETE;

        const isTileBuildError = tileStatus === TileProblem.BUILD_ERROR;

        const tileClassName = classNames(style.tile, {
          [style.firstTile]: index === 0,
          [style.selected]: index === selectedIndex,
        });
        return {
          imageUrl: (tile && assetUtils.getImagePreviewUrl(tile.baseImageAssetId)) || null,
          description: (tile && tile.headline) || null,
          className: tileClassName,
          isFlagged: isTileIncomplete || isFlaggedByModeration || isTileBuildError,
          hasDotStatus: true,
        };
      });

    const addItemButton = (
      <div className={style.addItemButtonContainer}>
        <SDSButton
          type={ButtonType.WHITE}
          onClick={this.handleAddTile}
          inlineIcon={plus}
          data-test="editor.tileSelectorControl.addNewTile.button"
        >
          <span>{getMessageFromId('add-new-tile-button-label')}</span>
        </SDSButton>
      </div>
    );

    return (
      <div>
        <ItemSelectPanel
          items={items}
          isReadOnly={isReadOnly}
          selectedIndex={selectedIndex}
          emptyDescriptionMessageId="tile-default-text"
          deleteMessageId="confirm-delete-tile"
          downloadMessageId="download-tile"
          enableDoneEditing={false}
          enableDelete
          enableDuplicate={this.canAddTile()}
          enableAdd={this.canAddTile()}
          onItemSelected={this.handleTileSelected}
          onAddItem={this.handleAddTile}
          onDeleteItem={this.handleTileDeleted}
          onDuplicateItem={this.handleTileDuplicated}
          onDownloadItem={this.handleTileDownload}
          isDuplicating={this.state.duplicatingTile}
          addItemButton={addItemButton}
          title={
            <div className={style.withHelpCenterLink}>
              <FormattedMessage
                id="tiles-headline"
                defaultMessage="Tiles"
                description="Right panel headline for Tiles"
              />
              <HelpCenterLink data-test="TileSelectorControl.title" destination={HelpCenterDestination.TILES} />
            </div>
          }
        />
        {this.addDuplicateSpinner()}
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TileSelectorControl);
