import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { externalLink } from 'icons/SDS/allIcons';
import { openInWebPlayer } from 'utils/apis/storiesEverywhereAPI';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import type { VideoSnap } from 'types/snaps';

type Props = {
  snap: VideoSnap;
};

export default class CuratedPublicSnapButton extends React.PureComponent<Props> {
  openSnapInWebPlayer = () => {
    openInWebPlayer(get(this.props.snap, 'originalSnapId'));
  };

  render() {
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        onClick={this.openSnapInWebPlayer}
        data-test="curatedPublicSnapButton.button"
        inlineIcon={externalLink}
      >
        <FormattedMessage
          id="curated-public-snap"
          description="Label for a button that describing that the snap is public and comes from (or is curated from) story.snapchat.com"
          defaultMessage="Curated public Snap"
        />
      </SDSButton>
    );
  }
}
