import 'react-select/scss/default.scss';
import classNames from 'classnames';
import invariant from 'invariant';
import { merge, noop } from 'lodash';
import log from 'loglevel';
import moment from 'moment-timezone';
import React from 'react';
import type { ReactNode } from 'react';
// @ts-expect-error ts-migrate(2497) FIXME: This module can only be referenced with ECMAScript... Remove this comment to see the full error message
import DayPicker, { DateUtils } from 'react-day-picker';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import { isShareable } from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getPresentationalTileForEdition } from 'state/publisherTools/selectors/publisherToolsSelectors';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';

import { chevronLeft, check } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import datePickerStyle from 'styles/datePicker.scss';
import { stopEventPropagation } from 'utils/browserUtils';
import { intlConnect } from 'utils/connectUtils';
import * as dateUtils from 'utils/dateUtils';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { getTimezoneList, guessTimezone } from 'utils/timezoneUtils';

import SDSButton, { ButtonType, ButtonShape } from 'views/common/components/SDSButton/SDSButton';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import ScheduleStoryTooltip from 'views/publisherStoryEditor/containers/ScheduleStoryTooltip/ScheduleStoryTooltip';

import style from './ScheduleStoryModal.scss';
import SchedulingTilePreview from './SchedulingTilePreview';
import SharingModal from './SharingModal';

import type { SnapId } from 'types/common';
import type { EditionID, LiveEdition, ScheduledEdition, Edition } from 'types/editions';
import { ExtractDispatchProps } from 'types/redux';
import type { Tile } from 'types/tiles';

type OwnProps = {
  modalId: string;
  hideModal: (modalId: string, results?: {}) => void;
  options: {
    storyIds: EditionID[];
    makeUnavailable: boolean;
    isFromHomepage: boolean | undefined | null;
  };
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const firstTiles = ownProps.options.storyIds.map(storyId => {
    return getPresentationalTileForEdition(state)(storyId);
  });
  const publisherId = publishersSelectors.getActivePublisherId(state);

  return {
    firstTile: firstTiles[0],
    publisherScheduledAndLiveEditions: publisherId
      ? editionsSelectors.getLiveAndScheduledStories(state)(publisherId)
      : [],
    publisherId,
    activeEdition: publisherStoryEditorSelectors.getActiveEdition(state),
  };
};

const mapDispatchToProps = {
  getScheduledAndLiveStoriesForPublisher: publishersActions.getScheduledAndLiveStoriesForPublisher,
  activateFirstTileByStoryId: editorActions.activateFirstTileByStoryId,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DispatchProps &
  OwnProps & {
    firstTile: Tile;
    firstSnapId: SnapId;
    publisherScheduledAndLiveEditions: (LiveEdition | ScheduledEdition)[];
    publisherId: number;
    activeEdition: Edition;
  };

type TimezoneOption = {
  value: string;
  prettyName: string;
  label: ReactNode;
  disabled?: boolean;
};

const PeriodName = {
  START: 'START',
  END: 'END',
};

type PeriodEnum = typeof PeriodName[keyof typeof PeriodName];

type TimeOption = {
  value: number;
  label: ReactNode;
  disabled?: boolean;
};

type PeriodState = {
  showingDayPicker: boolean;
  options: TimeOption[];
  date: string;
  time: number;
};

type SelectedState = {
  [k in PeriodEnum]: PeriodState;
};

const Step = enumObject({
  IMMEDIATE_SCHEDULE_OPTIONS: 'IMMEDIATE_SCHEDULE_OPTIONS',
  SCHEDULE: 'SCHEDULE',
  SHARING: 'SHARING',
});

type StepType = Enum<typeof Step>;

type OwnState = {
  fromMonth: Date;
  toMonth: Date;
  currentStep: StepType;
  lastStep?: StepType;
  timezoneOptions: TimezoneOption[];
  selectedTimezone: TimezoneOption;
  selected: SelectedState;
  showEndDateTime: boolean;
  activeEdition: Edition | undefined | null;
};

export class ScheduleStoryModal extends React.Component<Props, OwnState> {
  static dateFormat = 'MM/DD/YYYY';

  static schedulingInitialGracePeriodMinutes = 30;

  UNSAFE_componentWillMount() {
    const selectedTimezone = {
      value: guessTimezone().name,
      prettyName: guessTimezone().prettyName,
      label: <div className={style.value}>{guessTimezone().prettyName}</div>,
    };

    this.setState({
      timezoneOptions: this.generateTimezoneOptions(),
      selectedTimezone,
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      fromMonth: new Date(moment.tz(guessTimezone().name)),
      currentStep: Step.IMMEDIATE_SCHEDULE_OPTIONS,
      showEndDateTime: this.props.options.makeUnavailable || false,
    });

    // We want to load results from midnight last night.
    const startDate = new Date().setHours(0, 0, 0, 0);
    this.props.getScheduledAndLiveStoriesForPublisher({ publisherId: this.props.publisherId, startDate });
  }

  setTimezone = (option: TimezoneOption) => {
    const {
      selected: { [PeriodName.START]: start, [PeriodName.END]: end },
    } = this.state;

    const period = this.props.options.makeUnavailable ? end : start;

    if (!period) {
      log.warn('Attempting to set timezone with undefined period');
      return;
    }

    const date = this.convertStringDateToMoment(period.date, option.value).startOf('day').add(period.time, 'minutes');

    const isBefore = date.isSameOrBefore(moment.tz(option.value));

    if (isBefore) {
      this.resetScheduleDetails(option);
    } else {
      this.setState({ selectedTimezone: option }, this.updateTimeAndTimezoneOptions);
    }
  };

  setSelectedState = (newState: {}, callback?: () => void) => {
    this.setState({ selected: merge(this.state.selected, newState) }, callback);
  };

  setEndDateDetails = () => {
    // Getting next half hour based on now (e.g., if it's 2.12 AM it will return 150)

    const nextAvailableTime = moment
      .tz(this.state.selectedTimezone.value)
      .add(ScheduleStoryModal.schedulingInitialGracePeriodMinutes, 'minutes');
    const nextMinutes =
      (Math.ceil(nextAvailableTime.diff(moment.tz(this.state.selectedTimezone.value).startOf('day'), 'minutes') / 30) *
        30) %
      (24 * 60);

    const unavailableTime = this.props.options.makeUnavailable ? nextAvailableTime : nextAvailableTime.add(30, 'days');

    this.setSelectedState(
      {
        [PeriodName.END]: {
          time: nextMinutes,
          date: unavailableTime.format(ScheduleStoryModal.dateFormat),
        },
      },
      this.updateTimeAndTimezoneOptions
    );
  };

  generateTimezoneOptions() {
    return getTimezoneList().map(timezone => {
      return {
        prettyName: timezone.prettyName,
        offset: timezone.offset,
        value: timezone.name,
        label: <div className={style.value}>{timezone.prettyName}</div>,
      };
    });
  }

  generateTimeOptions = (): TimeOption[] => {
    return dateUtils.generateSelectorTimeOptions().map(option => {
      return {
        value: option.value,
        label: <div className={style.value}>{option.label}</div>,
      };
    });
  };

  updateTimeAndTimezoneOptions = () => {
    const {
      selectedTimezone,
      selected: { [PeriodName.START]: start, [PeriodName.END]: end },
    } = this.state;

    // We have to have some options disabled for the select components
    // so the user can't select values in the past. So we disable the options that would
    // make the current selection in the past, both for the timezone and for the times selectors.
    // Day picker is treated separately.

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const endTime = this.convertStringDateToMoment(end.date, selectedTimezone.value)
      .startOf('day')
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .add(end.time, 'minutes');

    // Validate all start time combinations with current end date/time and timezone
    // to ensure it's after now() and before end date/time.
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const startTimeOptions = start.options.map(time => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const date = this.convertStringDateToMoment(start.date, selectedTimezone.value)
        .startOf('day')
        .add(time.value, 'minutes');

      const isBefore = date.isSameOrBefore(moment.tz(selectedTimezone.value));
      const isAfter = date.isSameOrAfter(endTime);

      return { ...time, disabled: isBefore || isAfter };
    });

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const startTime = this.convertStringDateToMoment(start.date, selectedTimezone.value)
      .startOf('day')
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .add(start.time, 'minutes');

    // When making unavailable, end time is not constrained by start time but by current time
    const lowBound = this.props.options.makeUnavailable ? moment.tz(selectedTimezone.value) : startTime;

    // Validate all end time combinations with current start date/time and timezone
    // to ensure it's after start date/time
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const endTimeOptions = end.options.map(time => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const date = this.convertStringDateToMoment(end.date, selectedTimezone.value)
        .startOf('day')
        .add(time.value, 'minutes');

      const isBefore = date.isSameOrBefore(lowBound);

      return { ...time, disabled: isBefore };
    });

    this.setState(
      merge(this.state, {
        selected: {
          [PeriodName.START]: { options: startTimeOptions },
          [PeriodName.END]: { options: endTimeOptions },
        },
      })
    );
  };

  onSelectedDateChange = (period: PeriodEnum) => (day: Date) => {
    // eslint-disable-line react/sort-comp
    const stringDay = moment(day).format(ScheduleStoryModal.dateFormat);

    const selectedDate = moment.tz(stringDay, ScheduleStoryModal.dateFormat, true, this.state.selectedTimezone.value);

    if (selectedDate.isValid() && !this.dayIsInvalid(period)(day)) {
      this.setSelectedState(
        { [period]: { date: stringDay, showingDayPicker: false } },
        this.updateTimeAndTimezoneOptions
      );
    }
  };

  onHide = () => {
    this.props.hideModal(this.props.modalId);
  };

  dayHasScheduledStory = (period: PeriodEnum) => (day: Date) => {
    if (period === PeriodName.END) {
      return false;
    }

    return (
      this.props.publisherScheduledAndLiveEditions.findIndex((story: LiveEdition | ScheduledEdition) => {
        return !!story.startDate && DateUtils.isSameDay(day, new Date(story.startDate));
      }) > -1
    );
  };

  dayIsInvalid = (period: PeriodEnum) => (day: Date) => {
    const {
      selectedTimezone,
      selected: { [PeriodName.START]: start, [PeriodName.END]: end },
    } = this.state;

    // Getting day information and throwing away time and timezone

    const baseDate = moment.tz([day.getFullYear(), day.getMonth(), day.getDate()], selectedTimezone.value);

    let dayToCompare;
    let lowBound;
    let highBound;
    if (period === PeriodName.START) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      dayToCompare = baseDate.startOf('day').add(start.time, 'minutes');

      lowBound = moment.tz(selectedTimezone.value);
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      highBound = this.convertStringDateToMoment(end.date, selectedTimezone.value)
        .startOf('day')
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        .add(end.time, 'minutes');
    } else {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      dayToCompare = baseDate.startOf('day').add(end.time, 'minutes');

      // When making unavailable, end date is not constrained by start date
      if (this.props.options.makeUnavailable) {
        lowBound = moment.tz(selectedTimezone.value);
      } else {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        lowBound = this.convertStringDateToMoment(start.date, selectedTimezone.value)
          .startOf('day')
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          .add(start.time, 'minutes');
      }
    }

    return (
      dayToCompare.isBefore(lowBound) ||
      dayToCompare.isSame(lowBound) ||
      (highBound && (dayToCompare.isAfter(highBound) || dayToCompare.isSame(highBound)))
    );
  };

  convertStringDateToMoment = (stringDate: string, timezone: string) => {
    return moment.tz(stringDate, ScheduleStoryModal.dateFormat, true, timezone);
  };

  convertTimePeriodToISO = (dateTime: PeriodState) => {
    const dateTimeMoment = this.convertStringDateToMoment(dateTime.date, this.state.selectedTimezone.value);
    dateTimeMoment.minute(dateTime.time);
    return dateTimeMoment.toISOString();
  };

  isSelected = (period: PeriodEnum) => (day: Date): boolean => {
    const selectedDate = this.convertStringDateToMoment(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.state.selected[period].date,
      this.state.selectedTimezone.value
    ).startOf('day');

    const dayInCalendar = moment.tz(day, this.state.selectedTimezone.value).startOf('day');

    return selectedDate.isSame(dayInCalendar);
  };

  resetScheduleDetails = (selectedTimezone?: TimezoneOption) => {
    const timezoneOption: TimezoneOption = selectedTimezone || this.state.selectedTimezone;
    // Getting next half hour based on now (e.g., if it's 2.12 AM it will return 150)

    const nextAvailableTime = moment
      .tz(timezoneOption.value)
      .add(ScheduleStoryModal.schedulingInitialGracePeriodMinutes, 'minutes');
    const nextMinutes =
      (Math.ceil(nextAvailableTime.diff(moment.tz(timezoneOption.value).startOf('day'), 'minutes') / 30) * 30) %
      (24 * 60);

    this.setState(
      {
        selectedTimezone: timezoneOption,
        selected: merge(this.state.selected, {
          [PeriodName.START]: {
            options: this.generateTimeOptions(),
            showingDayPicker: false,
            time: nextMinutes,
            date: nextAvailableTime.format(ScheduleStoryModal.dateFormat),
          },
          [PeriodName.END]: {
            options: this.generateTimeOptions(),
            showingDayPicker: false,
            time: null,
            date: null,
          },
        }),
        showEndDateTime: this.props.options.makeUnavailable || false,
      },
      () => {
        this.updateTimeAndTimezoneOptions();
        if (this.state.showEndDateTime) {
          this.setEndDateDetails();
        }
      }
    );
  };

  onImmediateScheduleClick = () => {
    const period = this.props.options.makeUnavailable ? PeriodName.END : PeriodName.START;
    const notPeriod = !this.props.options.makeUnavailable ? PeriodName.END : PeriodName.START;

    const date = moment.tz(this.state.selectedTimezone.value);
    const stringDay = date.format(ScheduleStoryModal.dateFormat);

    const dateCopy = moment(date);
    const nextMinutes = date.diff(dateCopy.startOf('day'), 'minutes');

    // Go to sharing modal
    this.setState(
      {
        selected: merge(this.state.selected, {
          [period]: {
            date: stringDay,
            time: nextMinutes,
          },

          [notPeriod]: {
            date: null,
            time: null,
          },
        }),
        lastStep: this.state.currentStep,
      },
      () => {
        this.onConfirmationNextClick();
      }
    );
  };

  onScheduleClick = () => {
    this.resetScheduleDetails();
    // Go to schedule modal
    this.setState({
      lastStep: this.state.currentStep,
      currentStep: Step.SCHEDULE,
    });
  };

  clearDayPickers = () => {
    this.setSelectedState({
      [PeriodName.START]: { showingDayPicker: false },
      [PeriodName.END]: { showingDayPicker: false },
    });
  };

  onDateClick = (period: PeriodEnum) => (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setSelectedState(
      merge(
        {
          [PeriodName.START]: {
            showingDayPicker: false,
          },
          [PeriodName.END]: {
            showingDayPicker: false,
          },
        },
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        { [period]: { showingDayPicker: !this.state.selected[period].showingDayPicker } }
      )
    );
  };

  renderDay = (day: Date) => {
    return <ScheduleStoryTooltip date={day} liveAndScheduledStories={this.props.publisherScheduledAndLiveEditions} />;
  };

  renderCalendarPicker = (period: PeriodEnum) => {
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event?: Event | null | undefined) => void' ... Remove this comment to see the full error message
      <div onClick={stopEventPropagation}>
        <DayPicker
          initialMonth={
            new Date(
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              this.convertStringDateToMoment(this.state.selected[period].date, this.state.selectedTimezone.value)
            )
          }
          onDayClick={this.onSelectedDateChange(period)}
          selectedDays={this.isSelected(period)}
          className={`pt-datepicker ${datePickerStyle.datePicker}`}
          modifiers={{
            scheduled: this.dayHasScheduledStory(period),
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            historical: this.dayIsInvalid(period),
          }}
          renderDay={this.renderDay}
          fromMonth={this.state.fromMonth}
          toMonth={this.state.toMonth}
        />
      </div>
    );
  };

  renderUnavailableText() {
    const noOfStories = this.props.options.storyIds.length;
    let headerText = getMessageFromId('schedule-modal-header-unavailable');
    if (noOfStories > 1) {
      headerText = getMessageFromId('schedule-modal-header-unavailable-multiple', { noOfStories });
    }

    return headerText;
  }

  renderAvailableText() {
    const noOfStories = this.props.options.storyIds.length;
    if (noOfStories > 1) {
      return getMessageFromId('schedule-modal-header-available-multiple', { noOfStories });
    }
    return getMessageFromId('schedule-modal-header-available');
  }

  renderImmediateScheduleOptionsModal() {
    const scheduleText = this.props.options.makeUnavailable
      ? getMessageFromId('schedule-unavailable-immediately')
      : getMessageFromId('schedule-available-immediately');

    return (
      <SDSCustomModal
        title={this.props.options.makeUnavailable ? this.renderUnavailableText() : this.renderAvailableText()}
        visible
        onClose={this.onHide}
        footer={
          <>
            <SDSButton
              type={ButtonType.PRIMARY}
              onClick={this.onScheduleClick}
              data-test="publisherStoryEditor.scheduleStoryModal.schedule.button"
            >
              {getMessageFromId('schedule-button-label')}
            </SDSButton>
            <SDSButton
              type={ButtonType.PRIMARY}
              onClick={this.onImmediateScheduleClick}
              data-test="publisherStoryEditor.scheduleStoryModal.immediatePublish.button"
            >
              {scheduleText}
            </SDSButton>
          </>
        }
      >
        <span className={style.verticalLayout}>
          {!this.props.options.makeUnavailable ? (
            <SchedulingTilePreview
              tile={this.props.firstTile}
              onTileClick={this.onTileClick}
              showEditText={!this.props.options.isFromHomepage}
            />
          ) : (
            <FormattedMessage
              id="schedule-modal-subheader-unavailable"
              defaultMessage="This will hide content in the Discover feed and Search and stop Users from accessing the content from links in chat messages and snapcodes."
              description="Second part of the modal header asking the user when he wants the story to become unavailable"
            />
          )}
        </span>
      </SDSCustomModal>
    );
  }

  renderTimezoneSelectOption = (label: ReactNode) => {
    return (
      <div data-test="select.timezone">
        <div className={classNames(style.label, style.timeLabel)}>{label}</div>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Select
          options={this.state.timezoneOptions}
          value={this.state.selectedTimezone.value}
          className={style.select}
          onChange={this.setTimezone}
          noResultsText={false}
          clearable={false}
          arrowRenderer={noop}
          searchable={false}
          closeOnSelect
        />
      </div>
    );
  };

  renderDateSelectOption = (period: PeriodEnum) => (label: ReactNode) => {
    return (
      <div
        className={style.dateSelect}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: Event) => void' is not assignable to typ... Remove this comment to see the full error message
        onClick={this.onDateClick(period)}
        key={`${period}-date`}
        data-test={`${period}-date`}
      >
        <div className={style.label}>{label}</div>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {this.state.selected[period].showingDayPicker && this.renderCalendarPicker(period)}
        <div className={style.value}>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {this.convertStringDateToMoment(this.state.selected[period].date, this.state.selectedTimezone.value).format(
            'MMMM D, YYYY'
          )}
        </div>
      </div>
    );
  };

  onSelectTime = (period: PeriodEnum) => (option: TimeOption) => {
    this.setSelectedState({ [period]: { time: option.value } }, this.updateTimeAndTimezoneOptions);
  };

  renderTimeSelectOption = (period: PeriodEnum) => (label: ReactNode) => {
    return (
      <div key={`${period}-time`} data-test={`${period}-time`}>
        <div className={classNames(style.label, style.timeLabel)}>{label}</div>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Select
          data-test="select.time"
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          options={this.state.selected[period].options}
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          value={this.state.selected[period].time}
          className={style.select}
          onChange={this.onSelectTime(period)}
          noResultsText={false}
          clearable={false}
          arrowRenderer={noop}
          searchable={false}
          closeOnSelect
        />
      </div>
    );
  };

  onEndDateTimeLinkClick = () => {
    this.setEndDateDetails();
    this.setState({ showEndDateTime: true });
  };

  renderEndDateTime() {
    if (this.state.showEndDateTime) {
      return [
        this.renderDateSelectOption(PeriodName.END)(getMessageFromId('schedule-modal-end-date')),
        this.renderTimeSelectOption(PeriodName.END)(getMessageFromId('schedule-modal-end-time')),
      ];
    }

    return (
      <div className={style.endTimeLink} onClick={this.onEndDateTimeLinkClick}>
        {getMessageFromId('schedule-modal-show-end-date-time')}
      </div>
    );
  }

  renderEditTileButton() {
    if (this.props.options.isFromHomepage) {
      return null;
    }

    return (
      <div className={style.editButtonContainer}>
        <SDSButton
          type={ButtonType.PRIMARY}
          onClick={this.onTileClick}
          data-test="publishStoryEditor.sheduleStoryModal.edit.button"
        >
          {getMessageFromId('schedule-modal-edit-tile')}
        </SDSButton>
      </div>
    );
  }

  renderScheduleModal() {
    return (
      <SDSCustomModal
        visible
        width={600}
        onClose={this.onHide}
        footer={
          <>
            <SDSButton
              type={ButtonType.PRIMARY}
              shape={ButtonShape.CIRCLE}
              onClick={this.onScheduleBackClick}
              inlineIcon={chevronLeft}
              data-test="publisherStoryEditor.scheduleStoryModal.first.back.button"
            />
            <SDSButton
              type={ButtonType.PRIMARY}
              shape={ButtonShape.CIRCLE}
              onClick={this.onConfirmationNextClick}
              inlineIcon={check}
              data-test="publisherStoryEditor.scheduleStoryModal.next.button"
            />
          </>
        }
        title={
          this.props.options.makeUnavailable
            ? getMessageFromId('make-unavailable-label-schedule-modal')
            : getMessageFromId('make-available-label-schedule-modal')
        }
      >
        <div onClick={this.clearDayPickers}>
          {this.props.options.makeUnavailable
            ? getMessageFromId('make-unavailable-instruction-schedule-modal')
            : getMessageFromId('make-available-instruction-schedule-modal')}
          {this.renderTimezoneSelectOption(getMessageFromId('schedule-modal-timezone'))}
          {this.props.options.makeUnavailable ||
            this.renderDateSelectOption(PeriodName.START)(getMessageFromId('schedule-modal-start-date'))}
          {this.props.options.makeUnavailable ||
            this.renderTimeSelectOption(PeriodName.START)(getMessageFromId('schedule-modal-start-time'))}
          {this.renderEndDateTime()}
        </div>
      </SDSCustomModal>
    );
  }

  onScheduleBackClick = () => {
    this.setState({ currentStep: Step.IMMEDIATE_SCHEDULE_OPTIONS });
  };

  onTileClick = () => {
    if (!this.props.options.isFromHomepage) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      this.props.activateFirstTileByStoryId(this.props.options.storyIds[0]);
      this.onHide();
    }
  };

  onConfirmationBackClick = () => {
    // Saving previous step so we can navigate back
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.setState({ currentStep: this.state.lastStep });
  };

  onConfirmationNextClick = () => {
    if (this.props.options.makeUnavailable || !this.props.activeEdition || !isShareable(this.props.activeEdition)) {
      this.onScheduleComplete();
    } else {
      this.setState({ currentStep: Step.SHARING });
    }
  };

  onScheduleComplete = () => {
    const { makeUnavailable, storyIds } = this.props.options;
    if (storyIds.length === 0) {
      return;
    }
    const endObj = this.state.selected[PeriodName.END];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const endDate = endObj.date && endObj.time !== undefined ? this.convertTimePeriodToISO(endObj) : null;

    if (makeUnavailable) {
      this.props.hideModal(this.props.modalId, { endDate });
    } else {
      const startObj = this.state.selected[PeriodName.START];
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PeriodState | undefined' is not ... Remove this comment to see the full error message
      const startDate = this.convertTimePeriodToISO(startObj);

      this.props.hideModal(this.props.modalId, { startDate, endDate });
    }
  };

  renderSubheaderText(): ReactNode {
    return getMessageFromId('confirmation-instruction-schedule-modal-available');
  }

  renderSharingModal() {
    const { activeEdition } = this.props;
    invariant(activeEdition, 'expected an active edition');

    return <SharingModal onDismiss={this.onScheduleComplete} edition={this.props.activeEdition} />;
  }

  render() {
    switch (this.state.currentStep) {
      case Step.IMMEDIATE_SCHEDULE_OPTIONS:
        return this.renderImmediateScheduleOptionsModal();
      case Step.SCHEDULE:
        return this.renderScheduleModal();
      case Step.SHARING:
        return this.renderSharingModal();
      default:
        return null;
    }
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(ScheduleStoryModal);
