import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'views/common/components/Icon/Icon';
import LineView from 'views/common/components/LineView/LineView';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/Warning.svg.inline' or i... Remove this comment to see the full error message
import warning from 'icons/Warning.svg.inline';

import style from './CurationPanel.scss';

const storyNeedsToBeRevised = (
  <FormattedMessage
    id="curation-headline"
    description="Informs user the story has been flagged by curators"
    defaultMessage="This story needs to be revised"
  />
);

const makeChangesAndResubmit = (
  <FormattedMessage
    id="curation-make-changes"
    description="Informs user the story has been flagged by curators and needs to be revised"
    defaultMessage="Please make the desired changes and re-submit the story for review"
  />
);

type Props = {
  curationComment: string;
};

const CurationPanel = (props: Props) => {
  return (
    <div>
      <div className={style.title}>
        <Icon inlineIcon={warning} />
        {storyNeedsToBeRevised}
      </div>
      <div className={style.body}>
        {props.curationComment}
        <div />
        {makeChangesAndResubmit}
      </div>
      {/* @ts-expect-error ts-migrate(2786) FIXME: 'LineView' cannot be used as a JSX component. */}
      <LineView className={style.divider} />
    </div>
  );
};

export default React.memo(CurationPanel);
