// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PropertyPanel } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import {
  getLayoutType,
  getChoiceCountForLayout,
  PollLayoutTypes,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayoutsFactory';
import { take, isEmpty } from 'lodash';
import * as React from 'react';
import type { ChangeEvent } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import { UploadPurpose, DropzoneType, PollScreen } from 'config/constants';
import { isPollQuestion, isTriviaQuestion } from 'utils/polls/pollUtils';

import ColorPicker from 'views/common/components/ColorPicker/ColorPicker';
import EmojiPicker from 'views/common/components/EmojiPicker/EmojiPicker';
import { mergeConfigAndHeadline } from 'views/common/components/EmojiPicker/customEmojisUtils';
import FileUploadDeleteButton from 'views/common/components/FileUploadDeleteButton/FileUploadDeleteButton';
import FontPicker, { DEFAULT_FONT } from 'views/common/components/FontPicker/FontPicker';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import YesNoDropDown from 'views/common/components/YesNoDropDown/YesNoDropDown';
import PollScreensPanel from 'views/editor/components/PollScreensPanel/PollScreensPanel';
import { getPollScreens } from 'views/editor/containers/PollEditor/states/PollScreensConfig';

import style from './PollPanel.scss';
import {
  correctEmojisConfig,
  incorrectEmojisConfig,
  formattedMessages,
  PollPanelIds,
  isFieldAllowed,
} from './PollPanelConfig';
import type { PollPanelIdsEnum } from './PollPanelConfig';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type {
  Question,
  EditorProperties,
  PollEditorState,
  PollScreenEnum,
  EmojiType,
  PollLayoutType,
  AssetID,
} from 'types/polls';

type PollQuestionField = {
  key: PollPanelIdsEnum;
  predicate?: boolean;
};

type PollPropertyPanelProps = {
  fields: Array<PollQuestionField>;
};

type Props = {
  isReadOnly?: boolean;
  question: Question | undefined | null;
  pollEditorState?: PollEditorState;
  snapId: SnapId;
  editionId: EditionID;
  publisherLogo: string;
  wideLogoMedia: string;
  updateEditorState: (snapId: SnapId, editorState: PollEditorState) => void;
  additionalGeneralItems: Array<PollQuestionField>;
  isPollsTitleEditable: boolean | undefined | null;
  isOutcome: boolean;
  updateQuestion(a: Partial<Question>): void;
  formatMessage(name: string | {}, values: void | {}): string;
  getPrimaryLanguageMessage(name: string | {}, values: void | {}): Promise<string>;
};

type CompState = {
  initializationComplete: boolean;
};

function anyFieldVisible(fields: Array<PollQuestionField>) {
  return fields.some(field => field.predicate !== false);
}

/* eslint-disable react/prop-types */
export default class PollQuestionPanel extends React.Component<Props, CompState> {
  static contextTypes = {
    intl: intlShape,
  };

  state: CompState = {
    initializationComplete: false,
  };

  async UNSAFE_componentWillMount() {
    await this.updateProps(this.props);
  }

  async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    await this.updateProps(nextProps);
  }

  setInitialCorrectAnswer(map: EditorProperties, props: Props) {
    const { question } = props;
    if (!question) {
      return;
    }

    const options = this.getOptions(question);
    if (options.length === 0) {
      return;
    }

    const editorProps = question.editorProperties;
    const correctAnswerIndex = editorProps.correctAnswer || 0;
    const hasCorrectAnswer = typeof editorProps.correctAnswer !== 'undefined' && correctAnswerIndex < options.length;

    if (!hasCorrectAnswer) {
      // TODO: instead of updating in place, return properties to update via redux
      map.correctAnswer = 0; // eslint-disable-line no-param-reassign
    }
  }

  getSnapId() {
    return this.props.snapId;
  }

  getOptions(question: Question) {
    const layoutType = getLayoutType(question);
    const renderCount = getChoiceCountForLayout(layoutType);

    const options = take(question.options, renderCount) || [];
    return options;
  }

  setEditorProperties = (editorProperties: Partial<EditorProperties>) => {
    if (!isEmpty(editorProperties)) {
      this.updateQuestionProperties({ editorProperties });
    }
  };

  async updateProps(nextProps: Props) {
    const { question } = nextProps;
    if (!question) {
      return;
    }

    const editorProperties: Partial<EditorProperties> = {};

    if (isTriviaQuestion(nextProps.question)) {
      this.setInitialCorrectAnswer(editorProperties, nextProps);
    }

    if (this.state.initializationComplete) {
      this.setEditorProperties(editorProperties);
      return;
    }

    const { getPrimaryLanguageMessage } = this.props;

    const closedHeadline = isTriviaQuestion(nextProps.question)
      ? await getPrimaryLanguageMessage(formattedMessages.defaultClosedQuizHeadline)
      : await getPrimaryLanguageMessage(formattedMessages.defaultClosedHeadline);
    const votedHeadline = await getPrimaryLanguageMessage(formattedMessages.defaultVotedHeadline);
    const notVotedHeadline = await getPrimaryLanguageMessage(formattedMessages.defaultNotVotedHeadline);
    const closedQuizHeadline = await getPrimaryLanguageMessage(formattedMessages.defaultClosedQuizHeadline);
    const answeredHeadline = await getPrimaryLanguageMessage(formattedMessages.defaultAnsweredHeadline);
    const notAnsweredHeadline = await getPrimaryLanguageMessage(formattedMessages.defaultNotAnsweredHeadline);
    const votesCountMessage = await getPrimaryLanguageMessage(formattedMessages.votesCount, {
      votesCount: '{votesCount}',
    });

    const append = (name: string, value: unknown) => this.tryAppendProperty(editorProperties, name, value, nextProps);

    append('votesCountMessage', votesCountMessage);
    append('backgroundColor', '#000000');
    append('headingTextColor', '#FFFFFF');
    append('font', DEFAULT_FONT.output);
    append('wideLogoMedia', nextProps.wideLogoMedia);
    append('publisherLogo', nextProps.publisherLogo);
    append('layout', PollLayoutTypes.IMAGE_2X1);
    append('shouldShowVotesCount', isPollQuestion(question));
    append('shouldShowLabels', true);

    if (isTriviaQuestion(question)) {
      append('correctAnswerEmoji', { id: 'blush' });
      append('incorrectAnswerEmoji', { id: 'sob' });
      append('closedHeadline', closedQuizHeadline);
      append('votedHeadline', answeredHeadline);
      append('notVotedHeadline', notAnsweredHeadline);
    } else {
      append('closedHeadline', closedHeadline);
      append('votedHeadline', votedHeadline);
      append('notVotedHeadline', notVotedHeadline);
    }

    this.setEditorProperties(editorProperties);
    this.setState({ initializationComplete: true });
  }

  formatMessage(message: string | {}) {
    return this.props.formatMessage(message);
  }

  handleScreenSelected = (selectedScreen: PollScreenEnum) => {
    this.props.updateEditorState(this.getSnapId(), { selectedScreen });
  };

  handleShouldShowVotesCountChanged = (shouldShowVotesCount: boolean) =>
    this.setEditorProperties({ shouldShowVotesCount });

  handleInputChange = (fieldName: string) => (event: ChangeEvent<EventTarget>) => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;
    // Do not save empty string as this would be replaced by the default string in the input, instead save a space which will be trimmed
    // before being displayed in the input (resulting in an empty string)
    const finalValue = value === '' ? ' ' : value;
    this.setEditorProperties({ [fieldName]: finalValue });
  };

  handleBackgroundColorChanged = (backgroundColor: string) => this.setEditorProperties({ backgroundColor });

  handleHeadingTextColorChanged = (headingTextColor: string) => this.setEditorProperties({ headingTextColor });

  handleFontChanged = (font: string) => this.setEditorProperties({ font });

  handleLayoutChanged = (layout: PollLayoutType) => this.setEditorProperties({ layout });

  handleIncorrectAnswerEmojiChanged = (incorrectAnswerEmoji: EmojiType) =>
    this.setEditorProperties({ incorrectAnswerEmoji });

  handleCorrectAnswerEmojiChanged = (correctAnswerEmoji: EmojiType) => this.setEditorProperties({ correctAnswerEmoji });

  handleShouldShowLabelsChanged = (shouldShowLabels: boolean) => this.setEditorProperties({ shouldShowLabels });

  handleBackgroundAssetIDUploaded = ({ assetId }: { assetId: AssetID }) =>
    this.setEditorProperties({ backgroundAssetId: assetId });

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  handleBackgroundAssetDeleted = () => this.setEditorProperties({ backgroundAssetId: null });

  handleShowExplanationPageChanged = (showExplanationScreen: boolean) => {
    this.setEditorProperties({ showExplanationScreen });
    if (!showExplanationScreen) {
      this.handleScreenSelected(PollScreen.UNANSWERED);
    }
  };

  tryAppendProperty(map: Partial<EditorProperties>, property: string, defaultValue: unknown, nextProps: Props) {
    const { question } = nextProps;
    if (!question) {
      return false;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const value = question.editorProperties[property];
    if (value === null || value === undefined) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      map[property] = defaultValue; // eslint-disable-line no-param-reassign
      return true;
    }
    return false;
  }

  updateQuestionProperties = (properties: Partial<Question>) => {
    this.props.updateQuestion(properties);
  };

  updatePredicates(fields: Array<PollQuestionField>) {
    const { question, isOutcome } = this.props;
    if (!question) {
      return [];
    }

    const { pollType } = question;

    fields.forEach(field => {
      // If the field is already false, we don't modify it. (temporary solution until we release title editing
      // eslint-disable-next-line no-param-reassign
      field.predicate = field.predicate !== false && isFieldAllowed(field.key, { pollType, isOutcome });
    });

    return fields;
  }

  renderGeneralFields() {
    const { question } = this.props;
    if (!question) {
      return [];
    }
    const editorProps = question.editorProperties;
    return [
      ...this.props.additionalGeneralItems,
      {
        key: PollPanelIds.SHOW_VOTES_COUNT,
        className: style.headline,
        label: (
          <FormattedMessage
            id="show-votes-count"
            description="Label to indicate, if we should show the votes count in the top right corner of the poll"
            defaultMessage="Show approximate votes count"
          />
        ),
        multiline: true,
        control: (
          <YesNoDropDown
            name="show-votes-count"
            onChange={this.handleShouldShowVotesCountChanged}
            selectedValue={editorProps.shouldShowVotesCount}
            defaultValue
            data-test="showVoteCount"
          />
        ),
      },
      {
        key: PollPanelIds.SHOW_EXPLANATION_SCREEN,
        className: style.headline,
        label: (
          <FormattedMessage
            id="show-explanation-screen"
            description="Label to indicate, if we should show the votes count in the top right corner of the poll"
            defaultMessage="Explanation screen"
          />
        ),
        multiline: true,
        control: (
          <YesNoDropDown
            name="show-explanation-screen"
            onChange={this.handleShowExplanationPageChanged}
            selectedValue={Boolean(editorProps.showExplanationScreen)}
            defaultValue={false}
            data-test="showExplainScreen"
          />
        ),
      },
      {
        predicate: !this.props.isPollsTitleEditable,
        key: PollPanelIds.NOT_VOTED_HEADLINE,
        control: (
          <SDSInput
            labelTitle={this.formatMessage(formattedMessages.notVotedHeadline)}
            onChange={this.handleInputChange('notVotedHeadline')}
            maxLength={30}
            data-test="Headline"
            value={editorProps.notVotedHeadline ? editorProps.notVotedHeadline.trim() : ''}
          />
        ),
      },
      {
        predicate: !this.props.isPollsTitleEditable,
        key: PollPanelIds.VOTED_HEADLINE,
        control: (
          <div className={style.pollRowContainer}>
            <SDSInput
              labelTitle={
                isTriviaQuestion(question)
                  ? this.formatMessage(formattedMessages.clientAnswerHeadline)
                  : this.formatMessage(formattedMessages.votedHeadline)
              }
              onChange={this.handleInputChange('votedHeadline')}
              maxLength={30}
              data-test="VotedHeadline"
              value={editorProps.votedHeadline ? editorProps.votedHeadline.trim() : ''}
            />
          </div>
        ),
      },
      {
        predicate: !this.props.isPollsTitleEditable,
        key: PollPanelIds.CLOSED_HEADLINE,
        control: (
          <div className={style.pollRowContainer}>
            <SDSInput
              labelTitle={this.formatMessage(formattedMessages.closedHeadline)}
              onChange={this.handleInputChange('closedHeadline')}
              maxLength={30}
              data-test="CloseVoteHeadline"
              value={editorProps.closedHeadline ? editorProps.closedHeadline.trim() : ''}
            />
          </div>
        ),
      },
    ];
  }

  renderThemeFields() {
    const { question } = this.props;
    if (!question) {
      return [];
    }
    const editorProps = question.editorProperties;
    const { backgroundAssetId } = editorProps;
    return [
      {
        key: PollPanelIds.BACKGROUND_COLOR,
        control: (
          <ColorPicker
            label={this.formatMessage(formattedMessages.backgroundColorLabel)}
            onChange={this.handleBackgroundColorChanged}
            colorHex={editorProps.backgroundColor}
            data-test="backgroundColor"
            fullWidth
          />
        ),
      },
      {
        key: PollPanelIds.HEADLINE_COLOR,
        control: (
          <div className={style.pollRowContainer}>
            <ColorPicker
              label={this.formatMessage(formattedMessages.headingColorLabel)}
              onChange={this.handleHeadingTextColorChanged}
              colorHex={editorProps.headingTextColor}
              data-test="HeadingColor"
              fullWidth
            />
          </div>
        ),
      },
      {
        key: PollPanelIds.FONT_FAMILY,
        control: (
          <div className={style.pollRowContainer}>
            <FontPicker
              label={this.formatMessage(formattedMessages.fontLabel)}
              onChange={this.handleFontChanged}
              selectedFontOutput={editorProps.font}
            />
          </div>
        ),
      },
      {
        key: PollPanelIds.BACKGROUND_IMAGE,
        control: (
          <div className={style.pollRowContainer}>
            <SDSLabel
              labelTitle={
                <FormattedMessage
                  id="poll-panel-background-image"
                  description="Title for incorrect answer emoji picker"
                  defaultMessage="Background Image"
                />
              }
              className={style.backgroundImageButtonLabel}
            >
              <FileUploadDeleteButton
                publisherId={null}
                snapId={this.props.snapId}
                editionId={this.props.editionId}
                purpose={UploadPurpose.ARTICLE_IMAGE}
                dropzoneType={DropzoneType.POLL_IMAGE}
                isReadOnly={this.props.isReadOnly}
                data-test="backgroundImage"
                fileInfo={
                  backgroundAssetId
                    ? {
                        assetId: backgroundAssetId,
                      }
                    : undefined
                }
                disableClick={false}
                customValidationOptions={{
                  aspectRatio: { width: 540, height: 960, tolerance: 0 },
                }}
                isSpectaclesVideoEnabled={false}
                uploadCount={null}
                deleteAlertMessage={
                  <FormattedMessage
                    id="poll-panel-background-image-delete"
                    description="Message shown, when removing the background image"
                    defaultMessage="Are you sure, you want to remove the background image?"
                  />
                }
                onDelete={this.handleBackgroundAssetDeleted}
                onUploadComplete={this.handleBackgroundAssetIDUploaded}
              />
            </SDSLabel>
          </div>
        ),
      },
    ];
  }

  renderContentFields() {
    const { question } = this.props;
    if (!question) {
      return [];
    }
    const layoutsToDisplay = [PollLayoutTypes.IMAGE_2X1, PollLayoutTypes.IMAGE_2X2];
    const options = layoutsToDisplay.map(layout => {
      return {
        value: layout,
        label: getChoiceCountForLayout(layout),
      };
    });
    const editorProps = question.editorProperties;
    return [
      {
        key: PollPanelIds.CHOICE_PICKER,
        className: style.headline,
        label: <FormattedMessage {...formattedMessages.choicePickerLabel} />,
        multiline: true,
        control: (
          <SDSDropdown
            disableClear
            value={getLayoutType(question)}
            onChange={this.handleLayoutChanged}
            size={DropdownSize.MEDIUM}
            type={DropdownType.GREY}
            data-test="choicePickerDropdown"
          >
            {createSDSDropdownOptions(options)}
          </SDSDropdown>
        ),
      },
      {
        key: PollPanelIds.SHOW_LABELS,
        label: (
          <FormattedMessage
            id="poll-panel-options-show-labels-heading"
            description="Show labels headline"
            defaultMessage="Show labels"
          />
        ),
        className: style.headline,
        multiline: true,
        control: (
          <YesNoDropDown
            onChange={this.handleShouldShowLabelsChanged}
            selectedValue={editorProps.shouldShowLabels}
            data-test="showLabel"
          />
        ),
      },
      {
        multiline: true,
        label: (
          <FormattedMessage
            id="poll-panel-emoji-correct-answer"
            description="Title for correct answer emoji picker"
            defaultMessage="Correct Answer"
          />
        ),
        key: PollPanelIds.CORRECT_ANSWER_EMOJI,
        className: style.option,
        control: (
          <EmojiPicker
            onChange={this.handleCorrectAnswerEmojiChanged}
            selectedValue={editorProps.correctAnswerEmoji}
            customSettings={mergeConfigAndHeadline(
              correctEmojisConfig,
              this.formatMessage(formattedMessages.correctEmojis)
            )}
          />
        ),
      },
      {
        multiline: true,
        label: (
          <FormattedMessage
            id="poll-panel-emoji-incorrect-answer"
            description="Title for incorrect answer emoji picker"
            defaultMessage="Incorrect Answer"
          />
        ),
        key: PollPanelIds.INCORRECT_ANSWER_EMOJI,
        className: style.option,
        control: (
          <EmojiPicker
            onChange={this.handleIncorrectAnswerEmojiChanged}
            selectedValue={editorProps.incorrectAnswerEmoji}
            customSettings={mergeConfigAndHeadline(
              incorrectEmojisConfig,
              this.formatMessage(formattedMessages.inCorrectEmojis)
            )}
          />
        ),
      },
    ];
  }

  renderPropertyPanel(props: PollPropertyPanelProps) {
    this.updatePredicates(props.fields);

    if (!anyFieldVisible(props.fields)) {
      return null;
    }

    return <PropertyPanel {...props} isReadOnly={this.props.isReadOnly} />;
  }

  renderPollScreensPanel() {
    if (!this.props.question) {
      return null;
    }

    const { isPollsTitleEditable } = this.props;

    const pollEditorState = this.props.pollEditorState || {};
    const selectedScreen = pollEditorState.selectedScreen || PollScreen.UNANSWERED;
    const screensToDisplay = getPollScreens(this.props.question, { isPollsTitleEditable });

    if (this.props.isOutcome) {
      return null;
    }

    if (screensToDisplay.length < 2) {
      return null;
    }

    return (
      <PollScreensPanel
        key="poll-screens-panel"
        selectedScreen={selectedScreen}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        screensToDisplay={screensToDisplay}
        onScreenSelected={this.handleScreenSelected}
      />
    );
  }

  render() {
    const generalTitle = (
      <FormattedMessage id="poll-question-panel-general" description="General" defaultMessage="General" />
    );
    const themeTitle = <FormattedMessage id="theme" description="Theme" defaultMessage="Theme" />;
    const contentTitle = (
      <FormattedMessage id="poll-question-panel-content" description="Content" defaultMessage="Content" />
    );

    return (
      <div style={{ width: '100%' }}>
        {this.renderPollScreensPanel()}
        {this.renderPropertyPanel({
          fields: this.renderGeneralFields(),
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ fields: (PollQuestionField | {... Remove this comment to see the full error message
          title: generalTitle,
          showBottomLine: true,
        })}
        {this.renderPropertyPanel({
          fields: this.renderThemeFields(),
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ fields: { key: string; control... Remove this comment to see the full error message
          title: themeTitle,
          showBottomLine: true,
        })}
        {this.renderPropertyPanel({
          fields: this.renderContentFields(),
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ fields: { key: string; classNa... Remove this comment to see the full error message
          title: contentTitle,
          showBottomLine: false,
        })}
      </div>
    );
  }
}
