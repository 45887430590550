import Croppie from 'croppie';
import { debounce, zip, some } from 'lodash';
import * as React from 'react';

import 'croppie/croppie.css';
import { CoordinatesOrigin, TileCropType, TileSpecifications } from 'config/tileConfig';
import { assertArg } from 'utils/assertionUtils';
import * as mediaValidation from 'utils/media/mediaValidation';
import * as tileCropUtils from 'utils/media/tileCropUtils';
import type { TileShape } from 'utils/media/tileCropUtils';

import style from './ImageCroppie.scss';

import { AssetID } from 'types/assets';
import { PendingTile, Tile, TileID } from 'types/tiles';

export const PANEL_WIDTH = 320;
export const PANEL_HEIGHT = 320;

type Props = {
  imageId: TileID;
  imageURL: string;
  image: Tile;
  pendingImage: PendingTile | null;
  isReadOnly: boolean;
  onSave: () => void;
  onUpdate: (assetId: AssetID, param: {}) => void;
  onDiscard: (assetId: AssetID, param: string) => void;
  tileCropType: TileCropType;
  isCircle: boolean;
  fetch: typeof fetch;
  isDynamicSize: boolean;
};
export class ImageCroppie extends React.Component<Props> {
  static defaultProps = {
    fetch,
    tileCropType: TileCropType.CHEETAH,
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.pendingImage && this.props.pendingImage.initialize) {
      this.props.onDiscard(this.props.imageId, 'initialize');
      if (this.croppieInstance) {
        this.croppieInstance.destroy();
      }
      this.initializeCroppie(this.parentDiv);
    }
  }

  getCroppieOptions() {
    const tileAspectRatio = this.croppedDimensions.width / this.croppedDimensions.height;
    return {
      viewport: {
        width: tileAspectRatio * PANEL_HEIGHT,
        height: PANEL_HEIGHT,
        type: this.props.isCircle ? 'circle' : 'square',
      },
      showZoomer: false,
      mouseWheelZoom: false,
      enableZoom: false,
      boundary: {
        width: PANEL_WIDTH,
        height: PANEL_HEIGHT,
      },
      enforceBoundary: true,
      update: () => {
        // croppie returns strings, convert to integers
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const integerPoints = this.croppieInstance.get().points.map(point => parseInt(point, 10));
        if (this.shouldUpdateDimensionPoints(integerPoints) && !this.props.isReadOnly) {
          this.croppedDimensions.points = integerPoints;
          this.handleCropChange();
          this.uploadVerticalResult().then(() => this.handleSave());
        }
      },
    };
  }

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Croppie'.
  croppieInstance: Croppie = null;

  parentDiv: HTMLElement | undefined | null = null;

  croppedDimensions: any = {};

  imageDimensions: TileShape = { width: 0, height: 0 };

  handleSave = debounce(
    () => {
      this.props.onSave();
    },
    2000,
    { leading: true }
  );

  handleCropChange = () => {
    const { imageId } = this.props;
    const cropChanges = {};
    (cropChanges as any).baseHorizontalCropPosition = this.croppedDimensions.points[0];
    (cropChanges as any).baseVerticalCropPosition = this.croppedDimensions.points[1];
    this.props.onUpdate(imageId, cropChanges);
  };

  shouldUpdateDimensionPoints(points: Array<number>) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(points).is.array();
    // Only save if the points differ by more than 2 pixels in any dimension
    // This fixes some floating point rounding that is done when the browser is not at 100% zoom.
    const zippedPoints = zip(points || [], this.croppedDimensions.points || []);
    const pointChangesOutsideBoundaries = some(zippedPoints, axisPoints => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return Math.abs(axisPoints[0] - (axisPoints as any)[1]) > 2;
    });
    // Update only if the cropped points differ or in case of images that were uploaded in the correct size
    // when there is no previous cropping. 0 for cropping is a valid number
    return (
      pointChangesOutsideBoundaries ||
      this.props.image.baseHorizontalCropPosition === undefined ||
      this.props.image.baseHorizontalCropPosition === null ||
      this.props.image.croppedImageAssetId === null
    );
  }

  uploadVerticalResult() {
    return this.croppieInstance
      .result({
        type: 'blob',
        format: 'png',
        size: tileCropUtils.forceDimensionsToMatchRequiredDimensions(this.imageDimensions, this.croppedDimensions),
        circle: false,
      })
      .then(blobResult => {
        const generatedBlobURI = URL.createObjectURL(blobResult);
        this.props.onUpdate(this.props.imageId, { generatedBlobURI });
        return Promise.resolve();
      });
  }

  initializeCroppie = async (div?: HTMLElement | null) => {
    if (!div) {
      return;
    }
    const { image, imageId, imageURL, fetch } = this.props;
    this.imageDimensions = await mediaValidation.getImageInfo(imageURL);
    if (this.props.isDynamicSize) {
      const minDimension = Math.min(this.imageDimensions.width, this.imageDimensions.height);
      const dynamicSizeConfig = {
        width: minDimension,
        height: minDimension,
        fromBaseImage: true,
        coordinatesOrigin: CoordinatesOrigin.CENTER,
        defaultCoordinate: 0,
        points: [],
      };
      this.croppedDimensions = { ...dynamicSizeConfig };
    } else {
      this.croppedDimensions = { ...TileSpecifications[this.props.tileCropType] };
    }
    const location = tileCropUtils.centeredCoordinates(this.imageDimensions, this.croppedDimensions);
    // 0 gets evaluated as false but is legitimate value
    if (
      image.baseHorizontalCropPosition !== null &&
      image.baseHorizontalCropPosition !== undefined &&
      image.baseVerticalCropPosition !== null &&
      image.baseVerticalCropPosition !== undefined
    ) {
      location[0] = image.baseHorizontalCropPosition;
      location[1] = image.baseVerticalCropPosition;
      location[2] = image.baseHorizontalCropPosition + this.croppedDimensions.width;
      location[3] = image.baseVerticalCropPosition + this.croppedDimensions.height;
    }
    this.croppedDimensions.points = location;
    const opts = this.getCroppieOptions();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ viewport: { width: number; hei... Remove this comment to see the full error message
    const croppie = new Croppie(div, opts);
    this.parentDiv = div;
    this.croppieInstance = croppie;
    const response = await fetch(imageURL, { credentials: 'include' });
    const blob = await response.blob();
    await croppie.bind({
      url: URL.createObjectURL(blob),
      points: this.croppedDimensions.points,
    });
    this.props.onUpdate(imageId, { cropLoading: false });
  };

  render() {
    return <div className={style.root} ref={this.initializeCroppie} />;
  }
}
export default ImageCroppie;
