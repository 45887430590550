import { enumObject } from 'utils/enum';

import { SnapProblem, ExtendedIncompleteStatus, InfoStatus } from 'types/build';

export const TabKey = enumObject({
  TILE: 'tile',
  TOPSNAP: 'topSnap',
  ATTACHMENT: 'attachment',
  HEADLINE: 'headline',
});

export const TILE_INCOMPLETE_STATUSES = [
  ExtendedIncompleteStatus.SNAP_AND_TILE_MODERATION_FLAGGED,
  ExtendedIncompleteStatus.TILE_MODERATION_FLAGGED,
  ExtendedIncompleteStatus.UNHEALTHY_TILE_HEADLINE_TEXT,
];

export const TILE_ERROR_STATUSES = [
  ExtendedIncompleteStatus.TILE_MISSING,
  ExtendedIncompleteStatus.TILE_MISSING_HEADLINE,
  ExtendedIncompleteStatus.INVALID_TILE_HEADLINE_TEXT,
  ExtendedIncompleteStatus.TILE_NON_FORWARDS_COMPATIBLE,
  ExtendedIncompleteStatus.TILE_INCOMPLETE,
  ExtendedIncompleteStatus.TILE_MISSING_LOGO,
  ExtendedIncompleteStatus.SEGMENT_TILE_MISSING,
  ExtendedIncompleteStatus.SEGMENT_TILE_INCOMPLETE,
  ExtendedIncompleteStatus.MISSING_CATEGORY,
  ExtendedIncompleteStatus.TILE_BUILD_ERROR,
  ExtendedIncompleteStatus.TILE_LOGO_BUILD_ERROR,
];

export const SNAP_INCOMPLETE_STATUSES = [
  ExtendedIncompleteStatus.SNAP_AND_TILE_MODERATION_FLAGGED,
  ExtendedIncompleteStatus.SNAP_MODERATION_FLAGGED,
  ExtendedIncompleteStatus.SENSITIVE_CONTENT,
];

export const SNAP_ERROR_STATUSES = [
  SnapProblem.INCOMPLETE,
  ExtendedIncompleteStatus.MISSING_TAGS,
  SnapProblem.BUILD_OVERSIZED,
  SnapProblem.NO_BUILD,
  SnapProblem.BUILD_ERROR,
  SnapProblem.ILLEGAL_STATE,
  ExtendedIncompleteStatus.INCONSISTENT_DIMENSIONS,
];

export const ATTACHMENT_ERROR_STATUSES = [
  ExtendedIncompleteStatus.ATTACHMENT_INCOMPLETE,
  ExtendedIncompleteStatus.WEBVIEW_LIMIT_REACHED,
];

export const SNAP_TILE_ATTACHMENT_INCOMPLETE_STATUSES = [...SNAP_INCOMPLETE_STATUSES, ...TILE_INCOMPLETE_STATUSES];

export const SUBTITLES_INFO_STATUSES = [InfoStatus.AUTO_GENENERATED_SUBS];
