import is from 'is_js';

import type { SourceItem } from './StoryCarouselTypes';

type SizeStyleProperties = {
  maxWidth: number;
  minWidth: number;
  marginLeft: number;
  marginRight: number;
};

type BoundingRect = {
  right: number;
  left: number;
};

const snapSizeStyleProperties: SizeStyleProperties = Object.freeze({
  maxWidth: 130,
  minWidth: 130,
  marginLeft: 2,
  marginRight: 2,
});

export const getSnapSizeStyleProperties = (): SizeStyleProperties => {
  return snapSizeStyleProperties;
};

const segmentPadding = 2;

export const getSegmentSizeStyleProperties = (numberOfSnaps: number): SizeStyleProperties => {
  const {
    maxWidth: snapMaxWidth,
    minWidth: snapMinWidth,
    marginLeft: snapMarginLeft,
    marginRight: snapMarginRight,
  } = getSnapSizeStyleProperties();

  return {
    // Padding is included in the width
    maxWidth: (snapMaxWidth + snapMarginLeft + snapMarginRight) * numberOfSnaps + segmentPadding * 2,
    minWidth: (snapMinWidth + snapMarginLeft + snapMarginRight) * numberOfSnaps + segmentPadding * 2,
    marginLeft: 12,
    marginRight: 12,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ maxWidth: number; minWidth: number; margin... Remove this comment to see the full error message
    paddingLeft: segmentPadding,
    paddingRight: segmentPadding,
  };
};

/*
 * Calculates what snap index in a segment a snap drag is hovering.
 * Adjusts for the placeholder segment (it basically occupies two snap slots so the index should be adjusted by one)
 */
export const calculateSegmentHoverSnapIndex = (
  currentSnapIndex: number | undefined | null,
  numberOfSnaps: number,
  segmentBoundingRect: BoundingRect,
  mousePosition: number
): number => {
  const snapStyleProperties = getSnapSizeStyleProperties();

  const wholeSnapSize: number =
    snapStyleProperties.maxWidth + snapStyleProperties.marginLeft + snapStyleProperties.marginRight;

  const hoverClientX: number = mousePosition - segmentBoundingRect.left - segmentPadding;

  const halfSnapIndex: number = Math.floor(hoverClientX / (wholeSnapSize / 2));
  let snapIndex: number = Math.floor(halfSnapIndex / 2) + (is.odd(halfSnapIndex) ? 1 : 0);

  // Has to take the current index into account to avoid error when moving right
  snapIndex = typeof currentSnapIndex === 'number' && snapIndex > currentSnapIndex ? snapIndex - 1 : snapIndex;

  // Adjust invalid positions that may occur because mouse position may contain small errors
  snapIndex = Math.max(snapIndex, 0);
  snapIndex = Math.min(snapIndex, is.number(currentSnapIndex) ? numberOfSnaps - 1 : numberOfSnaps);

  return snapIndex;
};

export const calculatePercentOfSegmentMousePosition = (
  segmentBoundingRect: BoundingRect,
  mousePosition: number
): number => {
  let percent = (mousePosition - segmentBoundingRect.left) / (segmentBoundingRect.right - segmentBoundingRect.left);

  // Adjust invalid positions that may occur because mouse position may contain small errors
  percent = Math.max(percent, 0);
  percent = Math.min(percent, 1);
  return percent;
};

export const HoverArea = {
  START: 'START',
  MIDDLE: 'MIDDLE',
  END: 'END',
};

const hoverAreaLimit: number = 25;

export const getDragHoverArea = (
  segmentBoundingRect: BoundingRect,
  mousePosition: number
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$Enum'.
): $Enum<typeof HoverArea> => {
  // Get vertical middle
  const domElementSize: number = segmentBoundingRect.right - segmentBoundingRect.left;

  const hoverClientX: number = mousePosition - segmentBoundingRect.left;

  if (hoverClientX > domElementSize - hoverAreaLimit) {
    return HoverArea.END;
  }
  if (hoverClientX < hoverAreaLimit) {
    return HoverArea.START;
  }

  return HoverArea.MIDDLE;
};

export const MovementDirection = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const getDragMovementDirection = (
  segmentDragIndex: number,
  segmentHoverIndex: number
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$Enum'.
): $Enum<typeof MovementDirection> => {
  if (segmentDragIndex < segmentHoverIndex) {
    return MovementDirection.RIGHT;
  }
  if (segmentDragIndex > segmentHoverIndex) {
    return MovementDirection.LEFT;
  }

  return MovementDirection.LEFT;
};

export const sourceItemIsRealSegment = (sourceItem: SourceItem): boolean => {
  if (sourceItem.segment) {
    return !sourceItem.segment.isVirtualSegment;
  }
  if (sourceItem.snap) {
    return false;
  }
  throw new Error('Not a valid sourceItem');
};
