// import @flow
// discover-cms/no-snapnet
import {
  PropertyPanel,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/snapnet';
import { get } from 'lodash';
import React from 'react';

import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import { isReadOnly, getActiveTopsnap, isSaving } from 'state/editor/selectors/editorSelectors';
import { setSnapPropertiesAndSave } from 'state/snaps/actions/snapsActions';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import SnapTagInput from 'views/editor/components/SnapTagInput/SnapTagInput';
import style from 'views/editor/containers/EditorPropertyPanels/Panels/TopsnapPanel.scss';
import NotesControl from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/NotesControl/NotesControl';
import TagLabel from 'views/editor/containers/EditorPropertyPanels/SubPanelComponents/TagLabel/TagLabel';

import type { BuildStatusType } from 'types/build';
import type { Snap } from 'types/snaps';

type StateProps = {
  topsnap: Snap;
  buildStatus: BuildStatusType;
  isSaving: boolean;
  isReadOnly: boolean;
  setSnapPropertiesAndSave: typeof setSnapPropertiesAndSave;
};

const mapStateToProps = (state: State) => {
  const topsnap = getActiveTopsnap(state);
  const topsnapId = get(topsnap, 'id', null);

  return {
    buildStatus: topsnapId ? getDiscoverSnapBuildStatus(state)(topsnapId) : null,
    isReadOnly: isReadOnly(state),
    isSaving: isSaving(state),
    topsnap,
  };
};

const mapDispatchToProps = {
  setSnapPropertiesAndSave,
};

export class SingleAssetTopsnapPanel extends React.Component<StateProps> {
  renderFields = () => {
    return [
      {
        predicate: true,
        label: <TagLabel buildStatus={this.props.buildStatus} />,
        key: 'tagsInput',
        control: <SnapTagInput snapId={this.props.topsnap.id} disabled={this.props.isReadOnly} />,
      },
      {
        predicate: true,
        key: 'notesInput',
        control: (
          <NotesControl
            isReadOnly={this.props.isReadOnly}
            snapId={this.props.topsnap.id}
            notes={this.props.topsnap.name || ''}
            setSnapPropertiesAndSave={this.props.setSnapPropertiesAndSave}
          />
        ),
      },
    ];
  };

  render() {
    if (!this.props.topsnap) {
      return null;
    }

    return (
      <div className={style.topsnapPanel}>
        <PropertyPanel
          fields={this.renderFields()}
          isReadOnly={this.props.isReadOnly || this.props.isSaving || false}
          titleCaseLabels
          horizontal
        />
      </div>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(SingleAssetTopsnapPanel);
