import log from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom';

import { getAssetId } from 'state/snaps/schema/snapEntityHelpers';

import { question } from 'icons/SDS/allIcons';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';
import SpectaclesPreview from 'views/common/components/SpectaclesPreview/SpectaclesPreview';

import style from './SnapEditorStatus.scss';

import { SnapType } from 'types/snaps';
import type { TopSnap } from 'types/snaps';

type Props = {
  snap: TopSnap;
};
type State = {
  renderSpectaclesPreview: boolean;
};
export default class SpectaclesEditorStatus extends React.Component<Props, State> {
  state = {
    renderSpectaclesPreview: false,
  };

  showPopup = () => {
    this.setState({
      renderSpectaclesPreview: true,
    });
  };

  hidePopup = () => {
    this.setState({
      renderSpectaclesPreview: false,
    });
  };

  render() {
    const { snap } = this.props;

    if (snap.type !== SnapType.VIDEO || !snap.circular) {
      log.warn(
        'attempting to render editor status for a snap that is either not a video or not a spectacles video',
        snap
      );
      return null;
    }
    const assetId = getAssetId(this.props.snap);
    const src = assetId ? createAssetUrl(assetId) : null;
    return (
      <div className={style.root}>
        <div className={style.title}>
          {getMessageFromId('spectacles-video')}
          <SDSTooltip
            /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
            placement={TooltipPosition.RIGHT}
            title={getMessageFromId('spectacles-video-tooltip')}
            id="question-mark-tooltip"
          >
            <Icon inlineIcon={question} className={(style as any).questionIcon} />
          </SDSTooltip>
        </div>
        <SDSButton type={ButtonType.PRIMARY} onClick={this.showPopup}>
          {getMessageFromId('preview-button-label')}
        </SDSButton>
        {this.state.renderSpectaclesPreview &&
          src &&
          ReactDOM.createPortal(
            <div className={style.spectaclesPreviewOverlay}>
              <div className={style.backdrop} onClick={this.hidePopup} />
              <div className={style.previewModalRoot}>
                <SpectaclesPreview src={src} />
              </div>
            </div>,
            document.body
          )}
      </div>
    );
  }
}
